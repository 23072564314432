import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './loader/loader.component';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ChartsModalComponent } from './sub-component/charts-modal/charts-modal.component';
import { EnquiryDetailsModalChartComponent } from './sub-component/charts-modal/enquiry-details-modal-chart/enquiry-details-modal-chart.component';
import { ProfileComponent } from './profile/profile.component';
import { EnquiryComponent } from './sub-component/enquiry/enquiry/enquiry.component';
import { AddEnquiryComponent } from './sub-component/enquiry/add-enquiry/add-enquiry.component';
import { EditViewEnquiryComponent } from './sub-component/enquiry/edit-view-enquiry/edit-view-enquiry.component';
import { NgbModule, NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { EnquiryModalComponent } from './sub-component/enquiry/enquiry-modal/enquiry-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddCustomerComponent } from './sub-component/masters/customer/add-customer/add-customer.component';
import { EditViewCustomerComponent } from './sub-component/masters/customer/edit-view-customer/edit-view-customer.component';
import { CustomerDetailsComponent } from './sub-component/masters/customer/customer-details/customer-details.component';
import { ConfigurationDetailsComponent } from './sub-component/masters/configuration/configuration-details/configuration-details.component';
import { AddConfigurationComponent } from './sub-component/masters/configuration/add-configuration/add-configuration.component';
import { EditViewConfigurationComponent } from './sub-component/masters/configuration/edit-view-configuration/edit-view-configuration.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ActivityDetailsComponent } from './sub-component/enquiry/activity-details/activity-details.component';
import { AddProductLineComponent } from './sub-component/masters/product/product-line/add-product-line/add-product-line.component';
import { ProductLineDetailsComponent } from './sub-component/masters/product/product-line/product-line-details/product-line-details.component';
import { AddProductFamilyComponent } from './sub-component/masters/product/product-family/add-product-family/add-product-family.component';
import { ProductFamilyDetailsComponent } from './sub-component/masters/product/product-family/product-family-details/product-family-details.component';
import { ProductSeriesDetailsComponent } from './sub-component/masters/product/product-series/product-series-details/product-series-details.component';
import { AddProductSeriesComponent } from './sub-component/masters/product/product-series/add-product-series/add-product-series.component';
import { AddProductModelComponent } from './sub-component/masters/product/product-model/add-product-model/add-product-model.component';
import { ProductModelDetailsComponent } from './sub-component/masters/product/product-model/product-model-details/product-model-details.component';
import { AddOptionsComponent } from './sub-component/masters/product/options/add-options/add-options.component';
import { EditViewProductFamilyComponent } from './sub-component/masters/product/product-family/edit-view-product-family/edit-view-product-family.component';
import { EditViewProductSeriesComponent } from './sub-component/masters/product/product-series/edit-view-product-series/edit-view-product-series.component';
import { EditViewProductModelComponent } from './sub-component/masters/product/product-model/edit-view-product-model/edit-view-product-model.component';
import { EditViewProductLineComponent } from './sub-component/masters/product/product-line/edit-view-product-line/edit-view-product-line.component';
import { AddVariantLevel1Component } from './sub-component/masters/product/variant-level-1/add-variant-level1/add-variant-level1.component';
import { EditViewVariantLevel1Component } from './sub-component/masters/product/variant-level-1/edit-view-variant-level1/edit-view-variant-level1.component';
import { VariantLevel1DetailsComponent } from './sub-component/masters/product/variant-level-1/variant-level1-details/variant-level1-details.component';
import { VariantLevel2DetailsComponent } from './sub-component/masters/product/variant-level-2/variant-level2-details/variant-level2-details.component';
import { EditViewVariantLevel2Component } from './sub-component/masters/product/variant-level-2/edit-view-variant-level2/edit-view-variant-level2.component';
import { AddVariantLevel2Component } from './sub-component/masters/product/variant-level-2/add-variant-level2/add-variant-level2.component';
import { AddVariantLevel3Component } from './sub-component/masters/product/variant-level-3/add-variant-level3/add-variant-level3.component';
import { VariantLevel3DetailsComponent } from './sub-component/masters/product/variant-level-3/variant-level3-details/variant-level3-details.component';
import { VariantLevel4DetailsComponent } from './sub-component/masters/product/variant-level-4/variant-level4-details/variant-level4-details.component';
import { EditViewVariantLevel4Component } from './sub-component/masters/product/variant-level-4/edit-view-variant-level4/edit-view-variant-level4.component';
import { AddVariantLevel4Component } from './sub-component/masters/product/variant-level-4/add-variant-level4/add-variant-level4.component';
import { AvgDiscountMonthComponent } from './sub-component/dashboard/avg-discount-month-chart/avg-discount-month.component';
import { BPOrderIntakeComponent } from './sub-component/dashboard/bporder-intake-chart/bporder-intake.component';
import { QuatationOrderIntakeChartComponent } from './sub-component/dashboard/quatation-order-intake-chart/quatation-order-intake-chart.component';
import { OpportunityQuotationChartComponent } from './sub-component/dashboard/opportunity-quotation-chart/opportunity-quotation-chart.component';
import { OrderValuesChartComponent } from './sub-component/dashboard/order-values-chart/order-values-chart.component';
import { OrderWinLostChartComponent } from './sub-component/dashboard/order-win-lost-chart/order-win-lost-chart.component';
import { DashboardComponent } from './sub-component/dashboard/dashboard.component';
import { EnquiryDetailsChartComponent } from './sub-component/dashboard/enquiry-details-chart/enquiry-details-chart.component';
import { BusinessPlanChartComponent } from './sub-component/dashboard/business-plan-chart/business-plan-chart.component';
import { PendingEnquiryComponent } from './sub-component/enquiry/pending-enquiry/pending-enquiry.component';
import { ProductMatrixComponent } from './sub-component/masters/product-matrix/product-matrix.component';
import { FocusInvalidInputDirective } from './directive/focus-invalid-input.directive';
import { BusinessPlanDetailsComponent } from './sub-component/masters/BusinessPlan/business-plan-details/business-plan-details.component';
import { BusinessPlanForSalesAndOrderComponent } from './sub-component/masters/BusinessPlan/business-plan-for-sales-and-order/business-plan-for-sales-and-order.component';
import { RoleGuard } from './auth/role.guard';
import { EditBusinessPlanComponent } from './sub-component/masters/BusinessPlan/edit-business-plan/edit-business-plan.component';
import { RegionwiseReportComponent } from './sub-component/reports/regionwise-report/regionwise-report.component';
import { ProductwiseReportComponent } from './sub-component/reports/productwise-report/productwise-report.component';
import { CustomerwiseReportComponent } from './sub-component/reports/customerwise-report/customerwise-report.component';
import { SegmentwiseReportComponent } from './sub-component/reports/segmentwise-report/segmentwise-report.component';
import { ProductseriesColumnchartComponent } from './sub-component/reports/productwise-report/productseries-columnchart/productseries-columnchart.component';
import { ProductmodelColumnchartComponent } from './sub-component/reports/productwise-report/productmodel-columnchart/productmodel-columnchart.component';
import { ProductvariantColumnchartComponent } from './sub-component/reports/productwise-report/productvariant-columnchart/productvariant-columnchart.component';
import { AmChartsModule } from "@amcharts/amcharts3-angular";
import { OpportunityRfqChartComponent } from './sub-component/dashboard/opportunity-rfq-chart/opportunity-rfq-chart.component';
import { OrderbacklogSupplychainChartComponent } from './sub-component/dashboard/orderbacklog-supplychain-chart/orderbacklog-supplychain-chart.component';
import { StockInventoryDetailsComponent } from './sub-component/masters/StockInventory/stock-inventory-details/stock-inventory-details.component';
import { AddStockInventoryComponent } from './sub-component/masters/StockInventory/add-stock-inventory/add-stock-inventory.component';
import { ViewStockInventoryComponent } from './sub-component/masters/StockInventory/view-stock-inventory/view-stock-inventory.component';
import { EditViewOptionsComponent } from './sub-component/masters/product/options/edit-view-options/edit-view-options.component';
import { OptionsDetailsComponent } from './sub-component/masters/product/options/options-details/options-details.component';
import { EditViewVariantLevel3Component } from './sub-component/masters/product/variant-level-3/edit-view-variant-level3/edit-view-variant-level3.component';
import { ExportToExcelService } from './Service/exportToexcel.service';
import { PassOnComponent } from './sub-component/enquiry/pass-on/pass-on.component';
import { DynamicBGImageComponent } from './dynamic-bgimage/dynamic-bgimage.component';
import { CustomNgbDateParserFormatter } from './directive/CustomNgbDateParserFormatter';
import { IndianCurrency } from './Pipe/IndianCurrency.pipe';
import { EditViewWorkFlowScreenAccessComponent } from './sub-component/masters/WorkFlowScreenAccess/edit-view-work-flow-screen-access/edit-view-work-flow-screen-access.component';
import { EnquiryGridReportComponent } from './sub-component/reports/enquiry-grid-report/enquiry-grid-report.component';
import { PowerBIReportsComponent } from './sub-component/reports/power-bireports/power-bireports.component';
import { AlertsDetailsComponent } from './sub-component/masters/Alerts/alerts-details/alerts-details.component';
import { EditViewAlertsComponent } from './sub-component/masters/Alerts/edit-view-alerts/edit-view-alerts.component';
import { ProductSeriesUploadLogComponent } from './sub-component/masters/product/product-series/product-series-details/product-series-upload-log/product-series-upload-log.component';
import { TechnicalDiscardComponent } from './sub-component/enquiry/technical-discard/technical-discard.component';
import { AddCustomerOpportunityComponent } from './sub-component/enquiry/add-customer-opportunity/add-customer-opportunity.component';
import { CloseOppReasonComponent } from './sub-component/enquiry/close-opp-reason/close-opp-reason.component';
import { AddLoginPageDynamicImageComponent } from './sub-component/masters/login-page-dynamic-image/add-login-page-dynamic-image/add-login-page-dynamic-image.component';
import { DetailsLoginPageDynamicImageComponent } from './sub-component/masters/login-page-dynamic-image/details-login-page-dynamic-image/details-login-page-dynamic-image/details-login-page-dynamic-image.component';
import { SiteActivityComponent } from './sub-component/reports/site-activity/site-activity/site-activity.component';
import { AddLeadComponent } from './sub-component/masters/Lead/add-lead/add-lead/add-lead.component';
import { EditViewLeadComponent } from './sub-component/masters/Lead/edit-view-lead/edit-view-lead/edit-view-lead.component';
import { LeadDetailsComponent } from './sub-component/masters/Lead/lead-details/lead-details/lead-details.component';
import { PriceconfiguratorComponent } from './sub-component/masters/priceconfigurator/priceconfigurator.component';
import { RfqComponent } from './sub-component/enquiry/rfq/rfq.component';
import { ManagementApprovalComponent } from './sub-component/enquiry/management-approval/management-approval.component';
import { OaGenerationComponent } from './sub-component/enquiry/oa-generation/oa-generation.component';
import { QuotationComponent } from './sub-component/enquiry/quotation/quotation.component';
import { CeoApprovalComponent } from './sub-component/enquiry/ceo-approval/ceo-approval.component';
import { OrderConfirmationComponent } from './sub-component/enquiry/order-confirmation/order-confirmation.component';
import { SoGenerationComponent } from './sub-component/enquiry/so-generation/so-generation.component';
import { SupplyChainComponent } from './sub-component/enquiry/supply-chain/supply-chain.component';
import { ConfirmInvoiceComponent } from './sub-component/enquiry/confirm-invoice/confirm-invoice.component';
import { CloseLeadComponent } from './sub-component/masters/Lead/close-lead/close-lead.component';
import { EditViewLoginPageDynamicImageComponent } from './sub-component/masters/login-page-dynamic-image/edit-view-login-page-dynamic-image/edit-view-login-page-dynamic-image/edit-view-login-page-dynamic-image.component';
import { AddContractFrameAgreementComponent } from './sub-component/masters/configuration/ContractFrameAgreement/add-contract-frame-agreement/add-contract-frame-agreement.component';
import { EditViewContractFrameAgreementComponent } from './sub-component/masters/configuration/ContractFrameAgreement/edit-view-contract-frame-agreement/edit-view-contract-frame-agreement.component';
import { MyActivityComponent } from './sub-component/customer-engagement/my-activity/my-activity.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AddActivityComponent } from './sub-component/customer-engagement/activity/add-activity/add-activity.component';
import { EditActivityComponent } from './sub-component/customer-engagement/activity/edit-activity/edit-activity.component';
import { ListActivityComponent } from './sub-component/customer-engagement/activity/list-activity/list-activity.component';
import { ViewActivityComponent } from './sub-component/customer-engagement/activity/view-activity/view-activity.component';
import { CalenderViewComponent } from './sub-component/customer-engagement/activity/calender-view/calender-view.component';
import { ListActivityRowComponent } from './sub-component/customer-engagement/activity/list-activity-row/list-activity-row.component';
import { BulkEditActivityComponent } from './sub-component/customer-engagement/activity/bulk-edit-activity/bulk-edit-activity.component';
import { ActivityStatusByMonthComponent } from './sub-component/customer-engagement/engagement-reports/activity-status-by-month/activity-status-by-month.component';
import { ReportByActivityTypeMonthComponent } from './sub-component/customer-engagement/engagement-reports/report-by-activity-type-month/report-by-activity-type-month.component';
import { ReportByActivityTypeStatusComponent } from './sub-component/customer-engagement/engagement-reports/report-by-activity-type-status/report-by-activity-type-status.component';
import { EngagementReportsComponent } from './sub-component/customer-engagement/engagement-reports/engagement-reports.component';
import { MustMatchDirective } from './directive/MustMatch.directive';
import { GuestDetailsComponent } from './sub-component/masters/guest/guest-details/guest-details.component';
import { MaterialModule } from './material/material.module';
import { RegisterService } from './Service/register.service';
import { AddEmployeeComponent } from './sub-component/masters/employee/add-employee/add-employee.component';
import { EditViewEmployeeComponent } from './sub-component/masters/employee/edit-view-employee/edit-view-employee.component';
import { EmployeeDetailsComponent } from './sub-component/masters/employee/employee-details/employee-details.component';
import { AddGuestComponent } from './sub-component/masters/guest/add-guest/add-guest.component';
import { EditViewGuestComponent } from './sub-component/masters/guest/edit-view-guest/edit-view-guest.component';
import { CommonService } from './Service/common.service';
import { ReportByActivityStatusTypeComponent } from './sub-component/customer-engagement/engagement-reports/report-by-activity-status-type/report-by-activity-status-type.component';
import { ActivityListByEntityComponent } from './sub-component/enquiry/activity-list-by-entity/activity-list-by-entity.component';
import { CustomerFrameAgreementDetailsComponent } from './sub-component/masters/configuration/ContractFrameAgreement/customer-frame-agreement-details/customer-frame-agreement-details.component';
import { ShowPendingActivityComponent } from './sub-component/dashboard/show-pending-activity/show-pending-activity.component';
import { PlannedVsActualActivityComponent } from './sub-component/customer-engagement/planned-vs-actual-activity/planned-vs-actual-activity.component';
import { CustomerEngagementDetailsComponent } from './sub-component/customer-engagement/customer-engagement-details/customer-engagement-details.component';
import { CustomerEngagementComponent } from './sub-component/customer-engagement/customer-engagement/customer-engagement.component';
import { QuotationPreviewComponent } from './sub-component/enquiry/quotation-preview/quotation-preview.component';
import {MatTabsModule} from '@angular/material/tabs';
import { SendBackCommentComponent } from './sub-component/enquiry/send-back-comment/send-back-comment.component';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    RegisterComponent,
    MustMatchDirective,
    LoaderComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    EnquiryComponent,
    FooterComponent,
    GuestDetailsComponent,
    EditViewGuestComponent,
    AddGuestComponent,
    AddEmployeeComponent,
    EditViewEmployeeComponent,
    EmployeeDetailsComponent,
    ConfirmDialogComponent,
    ChartsModalComponent,
    EnquiryDetailsModalChartComponent,
    ProfileComponent,
    AddEnquiryComponent,
    EditViewEnquiryComponent,
    EnquiryModalComponent,
    EnquiryDetailsModalChartComponent,
    ProfileComponent,
    AddCustomerComponent,
    EditViewCustomerComponent,
    CustomerDetailsComponent,
    ConfigurationDetailsComponent,
    AddConfigurationComponent,
    EditViewConfigurationComponent,
    ActivityDetailsComponent,
    AddProductLineComponent,
    EditViewProductLineComponent,
    ProductLineDetailsComponent,
    AddProductFamilyComponent,
    EditViewProductFamilyComponent,
    ProductFamilyDetailsComponent,
    ProductSeriesDetailsComponent,
    AddProductSeriesComponent,
    EditViewProductSeriesComponent,
    EditViewProductModelComponent,
    AddProductModelComponent,
    ProductModelDetailsComponent,
    AddOptionsComponent,
    EditViewOptionsComponent,
    OptionsDetailsComponent,
    AddVariantLevel1Component,
    EditViewVariantLevel1Component,
    VariantLevel1DetailsComponent,
    VariantLevel2DetailsComponent,
    EditViewVariantLevel2Component,
    AddVariantLevel2Component,
    AddVariantLevel3Component,
    EditViewVariantLevel3Component,
    VariantLevel3DetailsComponent,
    VariantLevel4DetailsComponent,
    EditViewVariantLevel4Component,
    AddVariantLevel4Component,
    AvgDiscountMonthComponent,
    BPOrderIntakeComponent,
    QuatationOrderIntakeChartComponent,
    OpportunityQuotationChartComponent,
    OrderValuesChartComponent,
    OrderWinLostChartComponent,
    EnquiryDetailsChartComponent,
    BusinessPlanChartComponent,
    PendingEnquiryComponent,
    ProductMatrixComponent,
    FocusInvalidInputDirective,
    BusinessPlanDetailsComponent,
    BusinessPlanForSalesAndOrderComponent,
    EditBusinessPlanComponent,
    RegionwiseReportComponent,
    ProductwiseReportComponent,
    CustomerwiseReportComponent,
    SegmentwiseReportComponent,
    ProductseriesColumnchartComponent,
    ProductmodelColumnchartComponent,
    ProductvariantColumnchartComponent,
    OpportunityRfqChartComponent,
    OrderbacklogSupplychainChartComponent,
    StockInventoryDetailsComponent,
    AddStockInventoryComponent,
    ViewStockInventoryComponent,
    PassOnComponent,
    DynamicBGImageComponent,
    IndianCurrency,
    EditViewWorkFlowScreenAccessComponent,
    EnquiryGridReportComponent,
    PowerBIReportsComponent,
    AlertsDetailsComponent,
    EditViewAlertsComponent,
    ProductSeriesUploadLogComponent,
    TechnicalDiscardComponent,
    AddCustomerOpportunityComponent,
    CloseOppReasonComponent,
    PriceconfiguratorComponent,
    RfqComponent,
    AddLoginPageDynamicImageComponent,
    DetailsLoginPageDynamicImageComponent,
    SiteActivityComponent,
    AddLeadComponent,
    EditViewLeadComponent,
    LeadDetailsComponent,
    PriceconfiguratorComponent,
    ManagementApprovalComponent,
    OaGenerationComponent,
    QuotationComponent,
    CeoApprovalComponent,
    OrderConfirmationComponent,
    SoGenerationComponent,
    SupplyChainComponent,
    ConfirmInvoiceComponent,
    CloseLeadComponent,
    AddContractFrameAgreementComponent,
    EditViewContractFrameAgreementComponent,
    EditViewLoginPageDynamicImageComponent,
    MyActivityComponent,
    AddActivityComponent,
    EditActivityComponent,
    ListActivityComponent,
    ViewActivityComponent,
    CalenderViewComponent,
    ListActivityRowComponent,
    BulkEditActivityComponent,
    ActivityStatusByMonthComponent,
    ReportByActivityTypeMonthComponent,
    ReportByActivityTypeStatusComponent,
    EngagementReportsComponent,
    ReportByActivityStatusTypeComponent,
    ActivityListByEntityComponent,
    CustomerFrameAgreementDetailsComponent,
    ShowPendingActivityComponent,
    PlannedVsActualActivityComponent,
    CustomerEngagementDetailsComponent,
    CustomerEngagementComponent,
      QuotationPreviewComponent,
      SendBackCommentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatTabsModule,
    NgbModule,
    NgSelectModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    CommonModule,
    NgbModalModule,
    AmChartsModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })
  ],
  providers: [Title, Location, RegisterService, ExportToExcelService, CommonService, NgbActiveModal, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, RoleGuard,
    { provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter('longDate') }],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent]
})
export class AppModule { }
