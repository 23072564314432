<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add Product Line
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Product Line :<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productline"
                                            id="productline" [(ngModel)]="ProductLineobj.Name" #productline="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productline.invalid || productline?.touched && productline.invalid}"
                                            placeholder="Enter Product Line" (keypress)="AvoidSpace($event)"
                                            maxlength="250" (change)="ProductLineobj.Name=ProductLineobj.Name.trim()"
                                            required>

                                        <div *ngIf="myform.submitted && productline.invalid || productline?.touched && productline.invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="productline.errors?.required">Product line is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/ProductLine']" routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>