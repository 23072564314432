<!-- <loader [showLoader]="showLoader"></loader> -->
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Login Page Image
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid &&onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <!-- <label class="col-lg-2 col-form-label"> :</label> -->
                                    <div>
                                        <img src={{ImagePath}} class="my-image">
                                    </div>
                                </div>
                           
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Comment:
                                        <span style="color:#f4516c;">&nbsp;*</span>
                                    </label>
                                    <div class="col-lg-3">
                                        <!-- {{Comment}}  -->

                                        <textarea class="form-control m-input" id="comment" maxlength="1500"
                                            name="comment" rows="3" style="colour:black"
                                            [(ngModel)]="LoginPageImageObj.Comment"
                                            (keyup)="limitTextOnKeyUpDown1($event)"
                                            (keypress)="limitTextOnKeyUpDown1($event)" #comment="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && comment.invalid || comment?.touched && comment.invalid}"
                                            placeholder="Enter comment" maxlength="1500" [disabled]="isEditable"
                                            (change)="LoginPageImageObj.Comment=LoginPageImageObj.Comment.trim()" required></textarea>
                                        <span> Limit {{maxLimit}} characters, {{remLimit}}
                                            characters remaining.</span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && comment.invalid || comment?.touched && comment.invalid">
                                            <div *ngIf="myform.hasError('required', 'comment')">
                                                 comment is required</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">

                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                            [hidden]="!isLoginPageImageEditable"
                                            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                            (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a href="javascript:history.back()"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom" style="">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>