export enum UserRoles {
    //Employee
    Admin = "1",
    SalesEngineer = "2",
    RegionalSalesManager = "16",
    AreaSalesManager = "17",
    Marketing = "18",
    L1 = "3",
    L2 = "4",
    L3 = "5",
    BusinessDevelopment = "6",
    SalesDirector = "7",
    CEO = "8",
    FinanceAccounts = "9",
    SupplyChain = "10",
    CustomerService = "14",
    ProductManager = "15",
    ExecutiveBusinessAssistant = "19",
    ManagerSolutionsFactoryAndLocalization = "20",
    //Guest
    ChannelPartner = "11",
    Distributor = "12",
    Agent = "13",

}
