<loader [showLoader]="showLoader"></loader>

<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-6">
                    <div class="m-portlet m-portlet--full-height">
                        <form class="m-form m-form--fit m-form--label-align-right" name="myform" #myform="ngForm"
                            appFocusInvalidInput (ngSubmit)="myform.form.valid && updateProfile()" novalidate>

                            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg"
                                style="margin-bottom: 0rem;">
                                <div class="m-portlet__head">
                                    <div class="m-portlet__head-caption">
                                        <div class="m-portlet__head-title">
                                            <h3 class="m-portlet__head-text">
                                                Profile Settings
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="markedFields">
                                <span style="margin-left:10px;">* Marked fields are
                                    mandatory</span>
                            </div>
                            <div class="m-portlet__body">
                                <!-- <div class="form-group m-form__group row">
                                    <div class="col-sm-9 col-12 ml-auto mobcenter">
                                        <h3 class="m-form__section">Profile Settings</h3>
                                    </div>
                                </div> -->
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Username:</label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text" placeholder="Enter Username"
                                            id="username" name="username" (keypress)="AvoidSpace($event)"
                                            maxlength="100" [(ngModel)]="profileObj.Username" #username="ngModel"
                                            [ngClass]="{ 'is-invalid': myform.submitted && username.invalid }" disabled
                                            (change)="profileObj.Username=profileObj.Username.trim()" required>
                                        <div *ngIf="myform.submitted && username.invalid" class="invalid-feedback">
                                            <div *ngIf="username.errors.required">Username is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Name:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text" placeholder="Enter Name"
                                            id="name" name="name" (keypress)="AvoidSpace($event)" minlength="3"
                                            maxlength="55" [(ngModel)]="profileObj.Name" #name="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && name.invalid || name?.touched && name.invalid }"
                                            (change)="profileObj.Name=profileObj.Name.trim()"
                                            [disabled]="Role == UserRoles.Admin" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && name.invalid || name?.touched && name.invalid">
                                            <div *ngIf="myform.hasError('required', 'name')">
                                                Name is required</div>
                                            <div  *ngIf="myform.hasError('minlength', 'name')">
                                                Name should be 3-55 characters</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row"
                                    *ngIf="profileObj.RoleType != 3 && profileObj.RoleType != 1">
                                    <label class="col-lg-3 col-form-label">Employee Code:</label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text"
                                            placeholder="Enter Employee Code" id="empcode" name="empcode"
                                            [(ngModel)]="profileObj.EmpCode"
                                            (change)="profileObj.EmpCode=profileObj.EmpCode.trim()" disabled>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row" *ngIf="profileObj.RoleType == 3">
                                    <label class="col-lg-3 col-form-label">Guest Id:</label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text" placeholder="Enter Guest Id"
                                            id="guestid" name="guestid" [(ngModel)]="profileObj.GuestId"
                                            (change)="profileObj.GuestId=profileObj.GuestId.trim()" disabled>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Email Id:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text" placeholder="Enter Email"
                                            id="email" name="email" (keypress)="AvoidSpace($event)" maxlength="100"
                                            [(ngModel)]="profileObj.Email" #email="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && email.invalid || email?.touched && email.invalid }"
                                            (change)="profileObj.Email=profileObj.Email.trim()" [pattern]="emailPattern"
                                            [disabled]="Role == UserRoles.Admin" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && email.invalid || email?.touched && email.invalid">
                                            <div *ngIf="myform.hasError('required', 'email')">
                                                Email is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'email')">
                                                Should be in Email Id format (e.g. user@weiss.com)</div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Contact No.:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text" placeholder="Enter Contact No."
                                            id="contactno" name="contactno"
                                            (keypress)="AvoidSpace($event);isNumberKey($event);" minlength="10"
                                            maxlength="15" [(ngModel)]="profileObj.ContactNumber" #contactno="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && contactno.invalid || contactno?.touched && contactno.invalid}"
                                            (change)="profileObj.ContactNumber=profileObj.ContactNumber.trim()"
                                            pattern="[0-9]+" [disabled]="Role == UserRoles.Admin" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && contactno.invalid || contactno?.touched && contactno.invalid">
                                            <div *ngIf="myform.hasError('required', 'contactno')">
                                                Contact number is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'contactno')">
                                                Please enter valid contact number</div>
                                            <div *ngIf="myform.hasError('minlength', 'contactno')">
                                                Contact number should be 10-15 digits</div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Role:</label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text" placeholder="Enter Role"
                                            id="role" name="role" [(ngModel)]="profileObj.RoleName"
                                            (change)="profileObj.RoleName=profileObj.RoleName.trim()" disabled>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Country:</label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" type="text" placeholder="Enter Country"
                                            id="country" name="country" [(ngModel)]="profileObj.CountryName"
                                            (change)="profileObj.CountryName=profileObj.CountryName.trim()" disabled>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Region:</label>
                                  
                                    <div class="col-lg-9">
                                        <ul class="col-lg-9" disabled *ngFor="let reg of profileObj.ListRegion">
                                            <li> {{reg.Name}}</li>
                                          </ul>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="m-portlet__foot m-portlet__foot--fit">
                                <div class="m-form__actions">
                                    <div class="row">
                                        <div class="col-lg-3">
                                        </div>
                                        <div class="col-sm-9 col-12">
                                            <button type="submit" *ngIf="Role != UserRoles.Admin"
                                                class="btn btn-primary m-btn m-btn--air m-btn--custom">Save
                                                changes</button>&nbsp;&nbsp;
                                            <button type="button" [routerLink]="['/Dashboard']" routerLinkActive="active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>


                <div class="col-sm-6">
                    <div class="m-portlet m-portlet--full-height">
                        <form class="m-form m-form--fit m-form--label-align-right" name="myform2" #myform2="ngForm"
                            (ngSubmit)="myform2.form.valid && changePassword()" appFocusInvalidInput
                            [mustMatch]="['newpassword', 'confirmnewpassword']" novalidate>

                            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg" style="margin-bottom: 0rem;">
                                <div class="m-portlet__head">
                                    <div class="m-portlet__head-caption">
                                        <div class="m-portlet__head-title">
                                            <h3 class="m-portlet__head-text">
                                                Change Password
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="markedFields">
                                <span style="margin-left:10px;">* Marked fields are
                                    mandatory</span>
                            </div>
                            <div class="m-portlet__body">
                                <!-- <div class="form-group m-form__group row">
                                    <div class="col-sm-9 col-12 ml-auto mobcenter">
                                        <h3 class="m-form__section">Change Password</h3>
                                    </div>
                                </div> -->
                                <div class="form-group m-form__group row">
                                    <span style="font-weight: bold;font-size: 14px;">Instructions:</span><br>
                                </div>
                                <div class="form-group m-form__group row">
                                    <ol type="1">
                                        <li>New password length must be between 6 to 30 characters.</li>
                                        <li>New password must contains at least one alphanumeric characters.</li>
                                        <li>New password must contains at least one uppercase and lowercase
                                            characters.
                                        </li>
                                        <li>New password must contains at least one special characters. (Ex. @, $,
                                            #, !)
                                        </li>
                                    </ol>
                                </div>

                                <div class="form-group m-form__group row">

                                    <label class="col-lg-3 col-form-label">Old Password:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" [type]="OldPasshide ? 'password': 'text'"
                                            placeholder="Enter Old Password" id="oldpassword" name="oldpassword"
                                            [(ngModel)]="changePasswordObj.oldPassword" #oldpassword="ngModel"
                                            [ngClass]="{ 'is-invalid':myform2.submitted  && oldpassword.invalid || oldpassword?.touched && oldpassword.invalid }"
                                            required>
                                        <span class="p-viewer">
                                            <mat-icon matSuffix (click)="OldPasshide = !OldPasshide"
                                                style=" font-size: 20px;">{{OldPasshide ? 'visibility_off':
                                                'visibility'}}</mat-icon>
                                        </span>

                                        <div class="invalid-feedback"
                                            *ngIf="myform2.submitted && oldpassword.invalid || oldpassword?.touched && oldpassword.invalid">
                                            <div *ngIf="myform2.hasError('required', 'oldpassword')">
                                                Old password is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">New Password:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input" [type]="NewPasshide ? 'password': 'text'"
                                            placeholder="Enter New Password" id="newpassword" name="newpassword"
                                            minlength="6" maxlength="30" [(ngModel)]="changePasswordObj.newPassword"
                                            #newpassword="ngModel" [pattern]="passwordPattern"
                                            [ngClass]="{ 'is-invalid':myform2.submitted  && newpassword.invalid || newpassword?.touched && newpassword.invalid }"
                                            required>
                                        <span class="p-viewer">
                                            <mat-icon matSuffix (click)="NewPasshide = !NewPasshide"
                                                style=" font-size: 20px;">{{NewPasshide ? 'visibility_off':
                                                'visibility'}}</mat-icon>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform2.submitted && newpassword.invalid || newpassword?.touched && newpassword.invalid">
                                            <div *ngIf="myform2.hasError('required', 'newpassword')">
                                                New password is required</div>
                                            <div *ngIf="myform2.hasError('pattern', 'newpassword')">
                                                Password must contains minimum 6 alphanumeric with at least one
                                                uppercase, one lowercase and one special characters. (Ex. Pass@123)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-3 col-form-label">Confirm Password:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-9">
                                        <input class="form-control m-input"
                                            [type]="ConfirmPasshide ? 'password': 'text'" errors
                                            placeholder="Enter Confirm Password" id="confirmnewpassword"
                                            name="confirmnewpassword" minlength="6" maxlength="30"
                                            [(ngModel)]="changePasswordObj.confirmNewPassword"
                                            #confirmnewpassword="ngModel" [pattern]="passwordPattern"
                                            [ngClass]="{ 'is-invalid':myform2.submitted  && confirmnewpassword.invalid || confirmnewpassword?.touched && confirmnewpassword.invalid }"
                                            required>
                                        <span class="p-viewer">
                                            <mat-icon matSuffix (click)="ConfirmPasshide = !ConfirmPasshide"
                                                style=" font-size: 20px;">{{ConfirmPasshide ? 'visibility_off':
                                                'visibility'}}</mat-icon>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform2.submitted && confirmnewpassword.invalid || confirmnewpassword?.touched && confirmnewpassword.invalid">
                                            <div *ngIf="myform2.hasError('required', 'confirmnewpassword')">
                                                Confirm password is required</div>
                                            <div *ngIf="myform2.hasError('mustMatch', 'confirmnewpassword')">
                                                Confirm password must be same as password</div>
                                            <div *ngIf="myform2.hasError('pattern', 'confirmnewpassword')">
                                                Password must contains minimum 6 alphanumeric with at least one
                                                uppercase, one lowercase and one special characters. (Ex. Pass@123)
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="m-portlet__foot m-portlet__foot--fit">
                                <div class="m-form__actions">
                                    <div class="row">
                                        <div class="col-lg-3">
                                        </div>
                                        <div class="col-sm-9 col-12">
                                            <button type="submit"
                                                class="btn btn-primary m-btn m-btn--air m-btn--custom">Save
                                                changes</button>&nbsp;&nbsp;
                                            <button type="button" routerLink="/Dashboard" routerLinkActive="active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

                <div class="col-sm-6"
                *ngIf="Role != UserRoles.ChannelPartner && Role != UserRoles.Distributor && Role != UserRoles.Agent">
                    <div class="m-portlet m-portlet--full-height">
                        <form class="m-form m-form--fit m-form--label-align-right" name="myform3" #myform3="ngForm"
                            appFocusInvalidInput (ngSubmit)="myform.form.valid && updateOutOfOfficeSetting()" novalidate>

                        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg"
                            style="margin-bottom: 0rem;">
                            <div class="m-portlet__head">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Out Of Office Setting
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-3 col-form-label">Out of Office:</label>
                            <div class="col-lg-9 pt-3">
                                <mat-checkbox name="outofoffice" #outofoffice="ngModel"
                                    [(ngModel)]="outOfOfficeObj.OutofOffice"
                                    (change)="OutofOffice(outOfOfficeObj.OutofOffice)">
                                </mat-checkbox>
                            </div>
                        </div>

                        <div *ngIf="isOutOfOffice" class="form-group m-form__group row">
                            <div class="col-sm-12 form-group ml-2.9 datepicksss">
                                <div>
                                    <div class="input-group">
                                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                                            [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                            outsideDays="hidden" [startDate]="EnquiryngbDateFromFiter!" tabindex="-1" hidden [minDate]="minDate"
                                            [maxDate]="maxDate" [footerTemplate]="footerTemplate">
                                        <ng-template #t let-date let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                                [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                                (mouseleave)="hoveredDate = null">
                                                {{ date.day }}
                                            </span>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="form-group  m-form__group row pl-0">
                                    <label style="display: flex;" class="col-sm-3">Select Date:</label>
                                    <div class="col-sm-9">
                                    <div class="input-group">
                                        <input readonly #dpFromDate class="form-control" placeholder="From" name="dpFromDate"
                                            
                                            [value]="formatter.format(EnquiryngbDateFromFiter)"
                                            (input)="EnquiryngbDateFromFiter = validateInput(EnquiryngbDateFromFiter, dpFromDate.value)"
                                            (click)="datepicker.toggle()">
                                        <input readonly #dpToDate class="form-control" placeholder="To" name="dpToDate"
                                            [value]="formatter.format(EnquiryngbDateToFiter)"
                                            (input)="EnquiryngbDateToFiter = validateInput(EnquiryngbDateToFiter, dpToDate.value)"
                                            (click)="datepicker.toggle()">
                                        <div class="input-group-append" (click)="datepicker.toggle()"
                                            *ngIf="EnquiryngbDateFromFiter == null || EnquiryngbDateToFiter == null">
                                            <span class="input-group-text">
                                                <i class="la la-calendar-check-o"></i>
                                            </span>
                        
                                        </div>
                                        <div class="input-group-append"
                                            (click)="EnquiryngbDateFromFiter=null;EnquiryngbDateToFiter=null;OnFromDateFilterChange();OnToDateFilterChange()"
                                            *ngIf="EnquiryngbDateFromFiter != null && EnquiryngbDateToFiter != null">
                                            <span class="input-group-text">
                                                <i class="la la-close"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                        
                                <ng-template #footerTemplate>
                                    <hr class="my-0">
                        
                                    <button class="btn btn-primary btn-sm m-2 float-right" (click)="datepicker.toggle()">Ok</button>
                                </ng-template>
                        
                            </div>
                        </div>

                        <div *ngIf="isOutOfOffice"
                            class="m-portlet__foot m-portlet__foot--fit">
                            <div class="m-form__actions">
                                <div class="row">
                                    <div class="col-lg-3">
                                    </div>
                                    <div class="col-sm-9 col-12">
                                        <button type="submit"
                                            class="btn btn-primary m-btn m-btn--air m-btn--custom">Save
                                           </button>&nbsp;&nbsp;
                                        <button type="button" routerLink="/Dashboard" routerLinkActive="active"
                                            class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>