
import { Injectable } from '@angular/core';

import * as Excel from 'exceljs/dist/exceljs'
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})


export class ExportToExcelService {
  constructor() {
  }
 

  generateExcelwithdata(data1: any, quotationheader: any, excelFileName: string) {

    let workbook = new Excel.Workbook();

    workbook = this.getQuotationWorksheet(data1, quotationheader, workbook);
    // workbook = this.getBomworkSheet(sheet2, workbook);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.saveAsExcelFile(data, excelFileName);
    })


  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    //var currentdate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    fs.saveAs(data, fileName  + EXCEL_EXTENSION);
  }

  getQuotationWorksheet(data1: any, quotationheader: any, workbook: Excel.WorkBook) {
    
    let Quotationworksheet = workbook.addWorksheet('Region wise - Product Data',{
      pageSetup:{
        paperSize: 9,
         orientation:'landscape',
         fitToPage: true, 
         fitToHeight: 5, 
         fitToWidth: 7}
    });
    
    // const imageId1 = workbook.addImage({
    //   filename: 'https://assemblysolutionsinc.com/wp-content/uploads/2017/05/weiss-logo.png',
    //   extension: 'png',
    // });
    // Quotationworksheet.addBackgroundImage(imageId1);
    
    let QuotaionheaderRow = Quotationworksheet.addRow(quotationheader);
    QuotaionheaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      let value = cell.value;
      cell.value = value;
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    })
    for (let key of Object.keys(data1)) {
      let tmparray = [];
      let tmpobj = data1[key];

      for (let key of Object.keys(tmpobj)) {
        let tmpobj1 = tmpobj[key];

        tmparray.push(tmpobj1)
      }
      let row = Quotationworksheet.addRow(tmparray);
      row.eachCell((cell, number) => {
        // let color = cell.value.color;
        let value = cell.value;
        // cell.fill = {
        //   type: 'pattern',
        //   pattern: 'solid',
        //   fgColor: { argb: color }
        // }
        cell.value = value;
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

      })
    }

    return workbook;
  }
  getBomworkSheet(sheet2: any[], workbook: Excel.WorkBook) {

    let Bomworksheet = workbook.addWorksheet('BOM');
    let Bomheader = ['FiguerNumber', 'ItemCode', 'PartName', 'Qty', 'Weight', 'Area', 'RatePerKG', 'UnitMC', 'TotalMC', 'Machining_Cost', 'IncoTerm_value']
    let BomheaderRow = Bomworksheet.addRow(Bomheader);

    BomheaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    })
    for (let key of Object.keys(sheet2)) {
      let tmparray = [];
      let tmpobj = sheet2[key];

      for (let key of Object.keys(tmpobj)) {
        let tmpobj1 = tmpobj[key];
        tmparray.push(tmpobj1)
      }
      Bomworksheet.addRow(tmparray);
    }
    return workbook;

  }
}

