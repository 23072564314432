import { string } from "@amcharts/amcharts4/core";
import { UserRoles } from "../UserRoles";

export class WorkFlowScreenAccessModel {

    accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
    elementTypeObj:ElementTypeList[]=Array<ElementTypeList>();

    Id: number = 0;
    RoleId: string = "";
    RoleName: string = "";
    isEnquiryForm: boolean = false;
    isRFQ: boolean = false;
    isQuoteGeneration: boolean = false;
    isManagementApproval: boolean = false;
    isCEOApproval: boolean = false;
    isEnquiryConfirmation: boolean = false;
    isSOGeneration: boolean = false;
    isOrderAcknowledgement: boolean = false;
    isSupplyChain: boolean = false;
    isAccounts: boolean = false;



    //////////
    isEnquiryForm_Default: boolean = false;
    isRFQ_Default: boolean = false;
    isQuoteGeneration_Default: boolean = false;
    isManagementApproval_Default: boolean = false;
    isCEOApproval_Default: boolean = false;
    isEnquiryConfirmation_Default: boolean = false;
    isSOGeneration_Default: boolean = false;
    isOrderAcknowledgement_Default: boolean = false;
    isSupplyChain_Default: boolean = false;
    isAccounts_Default: boolean = false;
}


export class DefaultViewModel {


WorkflowDefaultViewAccessArray:any[]= [
    //Admin
    {
        role: UserRoles.Admin, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: true, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },

    // SalesEngineer 
    {
        role: UserRoles.SalesEngineer, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // RegionalSalesManager
    {
        role: UserRoles.RegionalSalesManager, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // AreaSalesManager 
    {
        role: UserRoles.AreaSalesManager, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // Marketing 
    {
        role: UserRoles.Marketing, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: false, isCEOApproval_Default: false,
        isEnquiryConfirmation_Default: false, isSOGeneration_Default: false, isOrderAcknowledgement_Default: false,
        isSupplyChain_Default: false, isAccounts_Default: false
    },
    // L1 
    {
        role: UserRoles.L1, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: true, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: false, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // L2 
    {
        role: UserRoles.L2, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: false, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // L3
    {
        role: UserRoles.L3, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: false, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // BusinessDevelopment
    {
        role: UserRoles.BusinessDevelopment, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // SalesDirector
    {
        role: UserRoles.SalesDirector, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // CEO
    {
        role: UserRoles.CEO, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    //  FinanceAccounts
    {
        role: UserRoles.FinanceAccounts, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // SupplyChain
    {
        role: UserRoles.SupplyChain, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: false, isSOGeneration_Default: false, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // CustomerService 
    {
        role: UserRoles.CustomerService, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: true, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: true, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // ProductManager 
    {
        role: UserRoles.ProductManager, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: true, isCEOApproval_Default: true,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: false, isOrderAcknowledgement_Default: true,
        isSupplyChain_Default: true, isAccounts_Default: true
    },
    // ChannelPartner 
    {
        role: UserRoles.ChannelPartner, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: false, isCEOApproval_Default: false,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: false, isOrderAcknowledgement_Default: false,
        isSupplyChain_Default: false, isAccounts_Default: false
    },
    // Distributor
    {
        role: UserRoles.Distributor, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: false, isCEOApproval_Default: false,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: false, isOrderAcknowledgement_Default: false,
        isSupplyChain_Default: false, isAccounts_Default: false
    },
    // Agent 
    {
        role: UserRoles.Agent, isEnquiryForm_Default: true, isRFQ_Default: true,
        isQuoteGeneration_Default: false, isManagementApproval_Default: false, isCEOApproval_Default: false,
        isEnquiryConfirmation_Default: true, isSOGeneration_Default: false, isOrderAcknowledgement_Default: false,
        isSupplyChain_Default: false, isAccounts_Default: false
    },
]

}

export class AccessPermissionModel
{
    Id? :number;
    Fk_RoleId? :string;
    RoleName?:string;
    Fk_ElementId? :number;
    IsVisible? :boolean;
    IsEditable? :boolean;     
    ElementName?:string;  
    ElementKey?:any;     
    ElementType?:any;   
    CanCreate?:boolean;
    CanList?:boolean;  
}

export class ElementTypeList
{
    ElementType?:string;
}
