<loader [showLoader]="showLoader"></loader>

<div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body">

        <div class="m-grid__item m-grid__item--fluid m-wrapper">
            <div class="m-content">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="m-portlet">
                            <div class="m-portlet__body">
                                <div class="multisteps-form">
                                    <div class="row mt-3">
                                        <div class="col-lg-12 mb-4 table-responsive">
                                            <form
                                                class="multisteps-form__form m-form m-form--fit m-form--label-align-right"
                                                #addActivityForm="ngForm" (ngSubmit)="onSubmit(frmData,addActivityForm)" novalidate>
                                                <div class="multisteps-form__panel shadow rounded bg-white js-active"
                                                    data-animation="fadeIn">

                                                    <div class="multisteps-form__content">

                                                        <div
                                                            class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                                            <div class="m-portlet__head">
                                                                <div class="m-portlet__head-caption">
                                                                    <div class="m-portlet__head-title">
                                                                        <h3 class="m-portlet__head-text">
                                                                            Edit Activity
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="m-portlet__body">
                                                                <div id="dvTestData">

                                                                    <div class="form-group m-form__group row">
                                                                        <label class="col-lg-2 col-form-label">Activity
                                                                            Type:</label>
                                                                        <div class="col-lg-3 col-form-label">
                                                                            <div class="col-lg-3">
                                                                                {{frmData.ActivityType}}
                                                                            </div>

                                                                        </div>

                                                                        <div class="offset-1"></div>

                                                                        <!-- <label class="col-lg-2 col-form-label"
                                                                            [ngStyle]="frmData.ActivityType == 'Meeting' ? {} : {'display': 'none'}" >Subtype
                                                                            :<span
                                                                                style="color: #f4516c">&nbsp;*</span></label>
                                                                        <div class="col-lg-3"
                                                                        [ngStyle]="frmData.ActivityType == 'Meeting' ? {} : {'display': 'none'}">

                                                                            <select class="form-control"
                                                                                id="ActivitySubType"
                                                                                name="ActivitySubType"
                                                                                [(ngModel)]="frmData.ActivitySubType"

                                                                                >
                                                                                <option *ngFor="let c of subtypeArray1"
                                                                                    [value]="c">
                                                                                    {{c}}</option>
                                                                            </select>
                                                                            <div class="invalid-feedback">
                                                                                <div>Subtype is
                                                                                    required</div>
                                                                            </div>
                                                                        </div> -->

                                                                    </div>



                                                                    <div class="form-group m-form__group row">
                                                                        <label class="col-lg-2 col-form-label">Owner:</label>
                                                                        <div class="col-lg-3">
                                                                            <select class="form-control"
                                                                                id="ActivityOwnerId"
                                                                                name="ActivityOwnerId"
                                                                                (change)="onOwnerChange(frmData,frmData.ActivityOwnerId)"
                                                                                [(ngModel)]="frmData.ActivityOwnerId"
                                                                                required>
                                                                                <option value=null selected disabled>
                                                                                    Select
                                                                                </option>

                                                                                <option *ngFor="let p of allUserMaster"
                                                                                    [value]="p.Id">
                                                                                    {{ p.Name }}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="offset-1"></div>
                                                                        <label class="col-lg-2 col-form-label">Subject:<span
                                                                          style="color: #f4516c">&nbsp;*</span></label>
                                                                        <div class="col-lg-3">

                                                                            <textarea class="form-control m-input"
                                                                                 id="Subject"
                                                                                name="Subject"  #Subject (keydown)="onKeyDown($event)"
                                                                                [(ngModel)]="frmData.Subject"
                                                                                placeholder="Enter Subject"
                                                                                rows="3" required></textarea>
                                                                                <div *ngIf="addActivityForm.submitted && !addActivityForm.controls.Subject.valid" class="text-danger">
                                                                                  <div>Subject is required</div>
                                                                                </div>
                                                                        </div>

                                                                        <div class="offset-1"></div>
                                                                    </div>

                                                                    <div class="form-group m-form__group row">
                                                                        <label class="col-lg-2 col-form-label">Status:</label>
                                                                        <div class="col-lg-3">
                                                                            <select class="form-control" id="ActivityStatusId" name="ActivityStatusId"
                                                                                [(ngModel)]="frmData.ActivityStatusId"  (change)="ChangeStatus(frmData.ActivityStatusId)">
                                                                                <option value=null selected disabled>
                                                                                    Select Status
                                                                                </option>
                                                                                <option *ngFor="let item of activityStatusByTypeMaster " value="{{ item.ActivityStatusId }}">
                                                                                    {{
                                                                                    item.ActivityStatusDesc
                                                                                    }}
                                                                                </option>
                                                                            </select>

                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group m-form__group row">


                                                                        <label class="col-lg-2 col-form-label">Purpose:</label>
                                                                        <div class="col-lg-3">
                                                                            <select class="form-control"
                                                                                id="ActivityPurposeId"
                                                                                name="ActivityPurposeId"
                                                                                [(ngModel)]="frmData.ActivityPurposeId"
                                                                                >
                                                                                <option value=null selected disabled>
                                                                                    Select Purpose
                                                                                </option>
                                                                                <option
                                                                                    *ngFor="let item of activityPurposeByTypeMaster"
                                                                                    value="{{ item.ActivityPurposeId }}">

                                                                                    {{
                                                                                    item.ActivityPurposeDesc
                                                                                    }}

                                                                                </option>

                                                                            </select>

                                                                        </div>

                                                                        <div class="offset-1"></div>

                                                                        <label class="col-lg-2 col-form-label">Purpose
                                                                            Description:</label>
                                                                        <div class="col-lg-3">
                                                                            <textarea class="form-control m-input"
                                                                                maxlength="1500" id="PurposeDesc"  #PurposeDesc (keydown)="onKeyDown($event)"
                                                                                name="PurposeDesc"
                                                                                [(ngModel)]="frmData.PurposeDesc"
                                                                                rows="3" ></textarea>


                                                                        </div>
                                                                    </div>

                                                                    <!--<Note- 001 >--Below Code For Future use Not delete -------->

                                                                    <div class="form-group m-form__group row"
                                                                        *ngIf="showOutcome">
                                                                        <label class="col-lg-2 col-form-label">Outcome
                                                                            :<span
                                                                                style="color: #f4516c">&nbsp;*</span></label>
                                                                        <div class="col-lg-3">
                                                                            <select class="form-control"
                                                                                id="ActivityOutcomeId"
                                                                                name="ActivityOutcomeId"
                                                                                [(ngModel)]="frmData.ActivityOutcomeId"
                                                                                required>
                                                                                <option value=null selected disabled>
                                                                                    Select Outcome
                                                                                </option>

                                                                                <option
                                                                                    *ngFor="let item of activityOutcomeByTypeMaster"
                                                                                    value="{{ item.ActivityOutcomeId }}">
                                                                                    {{
                                                                                    item.ActivityOutcomeDesc
                                                                                    }}
                                                                                </option>

                                                                            </select>


                                                                            <div *ngIf="addActivityForm.submitted && !addActivityForm.controls.ActivityOutcomeId.valid" class="text-danger">
                                                                              <div>Outcome is required</div>
                                                                            </div>
                                                                            <div class="invalid-feedback">
                                                                                <div>
                                                                                    Priority is
                                                                                    required
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="offset-1"></div>

                                                                        <label class="col-lg-2 col-form-label">Outcome
                                                                            Description :<span
                                                                                style="color: #f4516c">&nbsp;*</span></label>
                                                                        <div class="col-lg-3">
                                                                            <textarea class="form-control m-input"
                                                                                maxlength="1500" id="OutcomeDesc"
                                                                                name="OutcomeDesc" #OutcomeDesc (keydown)="onKeyDown($event)"
                                                                                [(ngModel)]="frmData.OutcomeDesc"
                                                                                rows="3" required></textarea>
                                                                                <div *ngIf="addActivityForm.submitted && !addActivityForm.controls.OutcomeDesc.valid" class="text-danger">
                                                                                  <div>Outcome Description is required</div>
                                                                                </div>

                                                                        </div>

                                                                    </div>
                                                                    <div class="form-group m-form__group row">
                                                                        <label class="col-lg-2 col-form-label">Due
                                                                            Date:<span
                                                                            style="color: #f4516c">&nbsp;*</span></label>

                                                                        <div class="col-lg-3">
                                                                            <div class="input-group date">
                                                                                <input type="datetime-local"
                                                                                    class="form-control"
                                                                                    id="PlannedDueDatetime"
                                                                                    [(ngModel)]="frmData.PlannedDueDatetime"
                                                                                    name="PlannedDueDatetime"  required/>
                                                                            </div>
                                                                            <div *ngIf="addActivityForm.submitted && !addActivityForm.controls.PlannedDueDatetime.valid" class="text-danger">
                                                                              <div>Due Date is required.</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="offset-1"></div>
                                                                    </div>
                                                                    <div class="form-group m-form__group row">
                                                                        <label
                                                                            class="col-lg-2 col-form-label">Description:<span
                                                                            style="color: #f4516c">&nbsp;*</span></label>
                                                                        <div class="col-lg-9">
                                                                            <textarea class="form-control m-input"
                                                                                maxlength="1500" id="Comment"
                                                                                name="Comment"
                                                                                [(ngModel)]="frmData.Comment"  #Comment (keydown)="onKeyDown($event)"
                                                                                rows="3" required></textarea>
                                                                                <div *ngIf="addActivityForm.submitted && !addActivityForm.controls.Comment.valid" class="text-danger">
                                                                                  <div>Description is required.</div>
                                                                                </div>
                                                                        </div>
                                                                    </div>



                                                                    <div class="form-group m-form__group row">

                                                                      <label class="col-lg-2 col-form-label">Customers</label>
                                                                      <div class="col-lg-10"  style="margin-left: -13px;">

                                                                          <div class="col-md-2">

                                                                          </div>

                                                                          <div class="col-md-8">
                                                                            <ng-select name="customerEntities"
                                                                              [items]="allCustomers"
                                                                              [searchable]="true" [multiple]="true" [closeOnSelect]="false"
                                                                              [placeholder]="'Select records'"
                                                                              bindLabel="EntityName" bindValue="EntityId"
                                                                              (change)="onChangeInCustomerSelection()"
                                                                              [(ngModel)]="selectedCustomers">

                                                                              <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                                                let-index="index">
                                                                                <input name="itemc-{{index}}" id="itemc-{{index}}" type="checkbox"
                                                                                  [ngModel]="item$.selected" style="margin-right: 8px;" />
                                                                                <label for="itemc-{{index}}">{{ item.EntityName }}</label>
                                                                              </ng-template>

                                                                            </ng-select>
                                                                          </div>
                                                                          <div class="offset-1"></div>

                                                                      </div>

                                                                    </div>

                                                                    <div class="form-group m-form__group row">

                                                                      <label class="col-lg-2 col-form-label">Leads</label>
                                                                      <div class="col-lg-10"  style="margin-left: -13px;">

                                                                          <div class="col-md-2">

                                                                          </div>

                                                                          <div class="col-md-8">
                                                                            <ng-select name="leadEntities"
                                                                              [items]="allLeads"
                                                                              [searchable]="true" [multiple]="true" [closeOnSelect]="false"
                                                                              [placeholder]="'Select records'"
                                                                              bindLabel="EntityName" bindValue="EntityId"

                                                                              [(ngModel)]="selectedLeads">

                                                                              <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                                                let-index="index">
                                                                                <input name="itemc-{{index}}" id="itemc-{{index}}" type="checkbox"
                                                                                  [ngModel]="item$.selected" style="margin-right: 8px;"/>
                                                                                <label for="itemc-{{index}}">{{ item.EntityName }}</label>
                                                                              </ng-template>

                                                                            </ng-select>
                                                                          </div>
                                                                          <div class="offset-1"></div>

                                                                      </div>

                                                                    </div>


                                                                    <div class="form-group m-form__group row"
                                                                     >
                                                                      <label class="col-lg-2 col-form-label">Enquiry:</label>
                                                                      <div class="col-lg-10"  style="margin-left: -13px;">


                                                                          <div class="col-md-8">
                                                                            <ng-select name="enquries"
                                                                              [items]=" allEnquiries"
                                                                              [searchable]="true" [multiple]="true" [closeOnSelect]="false"
                                                                              [placeholder]="'Select Enquiries'"
                                                                              bindLabel="EntityName" bindValue="EntityId"

                                                                              [(ngModel)]="selectedEnquries">
                                                                              <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                                                let-index="index">
                                                                                <input name="itemc-{{index}}" id="itemc-{{index}}" type="checkbox"
                                                                                  [ngModel]="item$.selected" style="margin-right: 8px;" />
                                                                                <label for="itemc-{{index}}">{{ item.EntityName }}</label>
                                                                              </ng-template>
                                                                            </ng-select>
                                                                          </div>
                                                                          <div class="offset-1"></div>

                                                                      </div>
                                                                    </div>



                                                                    <!-- =================================================== -->



                                                                    <div class="form-group m-form__group row">
                                                                      <label class="col-lg-2 col-form-label">Participant:<span
                                                                          style="color:#f4516c;">&nbsp;*</span></label>
                                                                      <div class="col-lg-3">

                                                                        <ng-select name="participants" [items]="allUserMaster" [searchable]="true"
                                                                          [multiple]="true" [closeOnSelect]="false" [placeholder]="'Select Partcipants'"
                                                                          bindLabel="Name" bindValue="Id"
                                                                          [(ngModel)]="frmData.ListActivityParticipants">
                                                                          <ng-template ng-option-tmp let-item="item" let-item$="item$"
                                                                            let-index="index">
                                                                            <input name="item-{{index}}" style="margin-right: 8px;" id="item-{{index}}" type="checkbox"
                                                                              [ngModel]="item$.selected" />
                                                                            <label  for="item-{{index}}">{{ item.Name }}</label>
                                                                          </ng-template>

                                                                        </ng-select>
                                                                      </div>
                                                                    </div>





                                                                    <div class="button-row text-left mt-4"
                                                                        style="margin: 15px">
                                                                        <button
                                                                            class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next"
                                                                            type="submit">
                                                                            Save</button>&nbsp;&nbsp;
                                                                        <button
                                                                            class="btn btn-metal m-btn m-btn--air m-btn--custom"
                                                                            type="button" routerLinkActive="active"
                                                                            (click)="gobackNavigate()">
                                                                            Cancel
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
