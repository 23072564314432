import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Level1Model, ProductSeries, VariantLevel4 } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';

@Component({
  selector: 'app-edit-view-variant-level4',
  templateUrl: './edit-view-variant-level4.component.html',
  styleUrls: ['./edit-view-variant-level4.component.scss']
})
export class EditViewVariantLevel4Component implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService) { }
  VariantLevel4obj: VariantLevel4 = new VariantLevel4();
  isEditable: boolean = true;
  FormStatus: string = "View";
  charCode: any;
  @Input()
  showLoader: boolean = false;
  queryString: string = "";
  //To bind current VariantLevel4 data
  Level4Currdata: any[] = [];
  Variant1arr: any[] = [];
  Variant2arr: any[] = [];

  ProductSeries: ProductSeries = new ProductSeries();
  Level3arr: Level1Model[] = [];
  Level4arr: Level1Model[] = [];
  UserRole: any;
  isProductVariant4Editable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  MountingPositionName:string="";
  @ViewChild ("closebutton") closebutton;
  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductVariant4Editable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    if (this.route.snapshot.params["id"] == undefined) {
      this.queryString = "ProductModel=" + this.route.snapshot.params["Model"];
      this.queryString += "&Price=" + this.route.snapshot.params["Price"];
      this.queryString += "&Status=" + this.route.snapshot.params["Status"];
      this.queryString += "&IsPriceOnRequest=" + this.route.snapshot.params["IsPriceOnRequest"];
      this.getVariantLevel4IdDetailsById('GetbyProductModelAndPrice', this.queryString);
    }
    else {
      this.queryString = "Id=" + this.route.snapshot.params["id"];
      this.getVariantLevel4IdDetailsById('GetById', this.queryString);
    }
  }

  /*******************************************************************************
    * Get data for add variant level-1
    * ******************************************************************************/
  getVariantLevel4IdDetailsById(ActionName: string, queryString: string) {
    this.showLoader = true;

    this.CommonService.GetData('ProductVariantLevel4', ActionName, queryString).subscribe((data: any) => {
      this.VariantLevel4obj = data;
      this.getAllProductSeries();
     
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.VariantLevel4obj.ProductSeries;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.VariantLevel4obj.SeriesName = data.SeriesName;
      this.VariantLevel4obj.ProductSeries = data.Id;
      this.VariantLevel4obj.IsLevel2DepOnLevel1 = data.IsLevel2DepOnLevel1;
      this.VariantLevel4obj.IsLevel3DepOnLevel2 = data.IsLevel3DepOnLevel2;
      this.VariantLevel4obj.IsLevel4DepOnLevel3 = data.IsLevel4DepOnLevel3;
      this.VariantLevel4obj.Label_Variant1 =data.Label_Variant1;
      this.VariantLevel4obj.Label_Variant2 =data.Label_Variant2;
      this.VariantLevel4obj.Label_Variant3 = data.Label_Variant3;
      this.VariantLevel4obj.Label_Variant4 = data.Label_Variant4;
      if (this.VariantLevel4obj.SeriesName == 'TC' || this.VariantLevel4obj.SeriesName == 'TR') {
        this.Level4arr = this.VariantLevel4obj.Level4;
        this.Level4Currdata = this.VariantLevel4obj.Level4.map(item => item.Level1.toString());
        this.GetAllMountingPosition(this.VariantLevel4obj.ProductModel);
      }
      else {
      if(this.VariantLevel4obj.IsLevel4DepOnLevel3 && this.VariantLevel4obj.IsLevel3DepOnLevel2 && this.VariantLevel4obj.IsLevel2DepOnLevel1)
      {
        this.GetVariant1ByModelId(this.VariantLevel4obj.ProductModel);
        if(this.VariantLevel4obj.Level1Id != null)
        {
          this.GetVariant2ByVariant1Id(this.VariantLevel4obj.Level1Id);
        }
        if(this.VariantLevel4obj.Level2Id != null)
        {
          this.GetVariant3ByVariant2Id(this.VariantLevel4obj.Level2Id);
        }
        
      }
      else if(this.VariantLevel4obj.IsLevel4DepOnLevel3 && this.VariantLevel4obj.IsLevel3DepOnLevel2 && !this.VariantLevel4obj.IsLevel2DepOnLevel1)
      {
        this.GetVariant2ByModelId(this.VariantLevel4obj.ProductModel);
        if(this.VariantLevel4obj.Level2Id!=null)
        {
          this.GetVariant3ByVariant2Id(this.VariantLevel4obj.Level2Id);
        }
       
      }
      else{
        this.GetVariant3ByModelId(this.VariantLevel4obj.ProductModel);
      }
        // if(this.VariantLevel4obj.IsLevel4DepOnLevel3)
        // {
        //   this.GetVariant1ByModelId(this.VariantLevel4obj.ProductModel);
        //   this.GetVariant2ByVariant1Id(this.VariantLevel4obj.Level1Id);
        //   this.GetVariant3ByVariant2Id(this.VariantLevel4obj.Level2Id);
        // }
        // else{
        //   this.GetVariant3ByModelId(this.VariantLevel4obj.ProductModel);
        // }
        
      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetVariant3ByModelId(ModelId: number)// other than TC and TR
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel3", "GetVariant3ByModelId", queryString).subscribe((data: any) => {
      this.Level3arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetAllMountingPosition(ModelId: number)//For TC and TC
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel4", "GetAllMountingPosition", queryString).subscribe((data: any) => {
      this.Level4arr = this.VariantLevel4obj.Level4.concat(data);
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }

  GetVariant1ByModelId(ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel1", "GetVariant1ByModelId", queryString).subscribe((data: any) => {
      this.Variant1arr = data;
      this.Variant1arr = this.Variant1arr.sort((a, b) => a.Level1.localeCompare(b.Level1));
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  
  GetVariant2ByVariant1Id(Variant1: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "Variant1=" + Variant1;
    queryString += "&ProductSeriesId=" + this.VariantLevel4obj.ProductSeries;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByVariant1Id", queryString).subscribe((data: any) => {
      this.Variant2arr = data;

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  GetVariant3ByVariant2Id(Variant2: number)
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "Variant2=" + Variant2;
    queryString += "&ProductSeriesId=" + this.VariantLevel4obj.ProductSeries;
    this.CommonService.GetData("ProductVariantLevel3", "GetVariant3ByVariant2Id", queryString).subscribe((data: any) => {
      this.Level3arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  GetVariant2ByModelId(ModelId: number)// other than TC and TR
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByModelId", queryString).subscribe((data: any) => {
      this.Variant2arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }


  /*******************************************************************************
   * End get data for add variant level-1
   * ******************************************************************************/

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    this.titleService.setTitle("Edit Product Variant Level 4 - WEISS");
  }
  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel4obj.Level4Price = 0;
    }
  }

  onVariant1Selected(event: any)
  {
    this.VariantLevel4obj.Level2Id = null;
    this.VariantLevel4obj.Level3Id = null;
    this.GetVariant2ByVariant1Id(event.target.value);
  }

  onVariant2Selected(event: any)
  {
    this.VariantLevel4obj.Level3Id = null;
    this.GetVariant3ByVariant2Id(event.target.value);
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  DeleteMP() {
    this.VariantLevel4obj.Level4 = [];
    for (var i = 0; i < this.Level4Currdata.length; i++) {
      var obj = this.Level4arr.find(x => x.Level1 == this.Level4Currdata[i])
      this.VariantLevel4obj.Level4.push(obj);
    }
    this.VariantLevel4obj.Level4 = this.VariantLevel4obj.Level4.filter(x => x.Id != null);
    if (this.VariantLevel4obj.Level4.length == 0) {
      this.toastr.warning("This " + this.VariantLevel4obj.Label_Variant4 + " does not exist for " + this.VariantLevel4obj.ModelName + " product model")
      this.VariantLevel4obj.Level4 = [];
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to delete this " + this.VariantLevel4obj.Label_Variant4 + "?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          this.showLoader = true;

          this.CommonService.PostData('ProductVariantLevel4', 'DeleteMountingPosition', this.VariantLevel4obj, null).subscribe((data: any) => {
            let result = data;

            if (result.ResponseCode == 1) {
              this.toastr.success(this.VariantLevel4obj.Label_Variant4 + ' deleted successfully!');
              this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
              this.showLoader = false;
            }
            else if (result.ResponseCode == 0) {
              this.toastr.warning("Failed to delete this " + this.VariantLevel4obj.Label_Variant4 + " because it has some dependency!");
              this.showLoader = false;
            }
            else {
              this.toastr.error('Failed to delete ' + this.VariantLevel4obj.Label_Variant4 + '!');
              this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
              this.showLoader = false;
            }


          }, error => {
            this.toastr.error('Failed to delete ' + this.VariantLevel4obj.Label_Variant4 + '!');
            this.showLoader = false;
            this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
          });
        }
        else {
          this.VariantLevel4obj.Level4 = [];
        }
      });
    }
  }

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this " + this.VariantLevel4obj.Label_Variant4 + "?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader = true;
        if (this.VariantLevel4obj.SeriesName == 'TC' || this.VariantLevel4obj.SeriesName == 'TR') {
          this.VariantLevel4obj.Level4 = [];
        }

        for (var i = 0; i < this.Level4Currdata.length; i++) {
          var obj = this.Level4arr.find(x => x.Level1 == this.Level4Currdata[i]);
          this.VariantLevel4obj.Level4.push(obj);
        }
        this.CommonService.PostData('ProductVariantLevel4', 'Update', this.VariantLevel4obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.VariantLevel4obj.Label_Variant4 + ' updated successfully!');
            this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.VariantLevel4obj.Label_Variant4 + ' already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update ' + this.VariantLevel4obj.Label_Variant4 + '!');
            this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update ' + this.VariantLevel4obj.Label_Variant4 + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
        });
      }
    });
  }
  OpenPopupAddMountingPosition()
  {
    this.MountingPositionName="";
  }
  AddMountingPosition(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this Mounting Position ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        let queryString = "";
        queryString = "MountingPositionName=" + this.MountingPositionName;
        this.CommonService.PostData('ProductVariantLevel4', 'AddMountingPosition', null, queryString).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('MountingPosition added successfully!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel4obj.ProductModel != null && this.VariantLevel4obj.ProductModel != undefined
              && this.VariantLevel4obj.ProductModel != 0)) {

              this.GetAllMountingPosition(this.VariantLevel4obj.ProductModel );
            }
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('MountingPosition already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add MountingPosition!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel4obj.ProductModel != null && this.VariantLevel4obj.ProductModel != undefined
              && this.VariantLevel4obj.ProductModel != 0) ) {

              this.GetAllMountingPosition(this.VariantLevel4obj.ProductModel );
            }
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add MountingPosition!');
          this.closebutton.nativeElement.click();
          this.showLoader = false;
          if ((this.VariantLevel4obj.ProductModel != null && this.VariantLevel4obj.ProductModel != undefined
            && this.VariantLevel4obj.ProductModel != 0)) {

            this.GetAllMountingPosition(this.VariantLevel4obj.ProductModel );
          }
        });
      }
    });


  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductVariant4Editable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}

