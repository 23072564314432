<!-- <loader [showLoader]="showLoader"></loader> -->
<div class="m-portlet m-portlet--success m-portlet--head-solid-bg ">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    Sales Funnel
                </h3>
            </div>
        </div>
        <div class="m-portlet__head-tools">
            <select class="form-control mr-1 pl-0" id="sel1" style="min-width:110px;" [(ngModel)]="drpdnOption"
                (change)="OnSelectChange()">
                <option selected disabled>Select</option>
                <option value="Units">No's</option>
                <option value="Amount">Amount</option>
            </select>
        </div>
      
    </div>
    <div class="m-portlet__body">
        <div style="display:flex;">
            <!-- class="rotateimg180" -->
            <!-- <img alt="" style="width: 60%; height: 400px;" src="assets/images/funnel.jpg" /> -->
            <!-- <div [id]="Chartidname" style="width: 100%; height: 400px; background-color: #FFFFFF;"></div> -->
            <div id="enquiryDetailsChart" style="width: 100%; height: 400px; background-color: #FFFFFF;"></div>
            <!-- <div style="margin: 40px;height: 200px;">
                <table class="table">
                    <tr style="height: 75px;">Submitted Enquiry: {{SubmittedEnquiryCount}} {{drpdnOption=='Units' ? 'No.': '₹'}}</tr>
                    <tr style="height: 75px;">RFQ: {{RFQCount}} {{drpdnOption=='Units' ? 'No.': '₹'}}</tr>
                    <tr style="height: 75px;">Propose Delivered: {{ProposeDeliveredCount}} {{drpdnOption=='Units' ? 'No.': '₹'}}</tr>
                    <tr style="height: 75px;">Order Confirmation: {{OrderConfirmationCount}} {{drpdnOption=='Units' ? 'No.': '₹'}}</tr>
                    <tr style="height: 75px;">Order Delivered: {{OrderDeliveredCount}} {{drpdnOption=='Units' ? 'No.': '₹'}}</tr>
                </table>
            </div> -->
          
        </div>
       

    </div>
</div>
<!-- <div class="modal-footer" style="height: 55px;" *ngIf="mode == 'view'">
    <button type="button" (click)="ClosePopup()" class="btn btn-primary">Close</button>
</div> -->