import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { AppComponent } from './app.component';
import { DashboardComponent } from './sub-component/dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { EnquiryComponent } from './sub-component/enquiry/enquiry/enquiry.component';
import { AddEnquiryComponent } from './sub-component/enquiry/add-enquiry/add-enquiry.component';
import { EditViewEnquiryComponent } from './sub-component/enquiry/edit-view-enquiry/edit-view-enquiry.component';
import { AddCustomerComponent } from './sub-component/masters/customer/add-customer/add-customer.component';
import { EditViewCustomerComponent } from './sub-component/masters/customer/edit-view-customer/edit-view-customer.component';
import { CustomerDetailsComponent } from './sub-component/masters/customer/customer-details/customer-details.component';
import { ConfigurationDetailsComponent } from './sub-component/masters/configuration/configuration-details/configuration-details.component';
import { AddConfigurationComponent } from './sub-component/masters/configuration/add-configuration/add-configuration.component';
import { EditViewConfigurationComponent } from './sub-component/masters/configuration/edit-view-configuration/edit-view-configuration.component';
import { AddProductLineComponent } from './sub-component/masters/product/product-line/add-product-line/add-product-line.component';
import { ProductLineDetailsComponent } from './sub-component/masters/product/product-line/product-line-details/product-line-details.component';
import { AddProductFamilyComponent } from './sub-component/masters/product/product-family/add-product-family/add-product-family.component';
import { ProductFamilyDetailsComponent } from './sub-component/masters/product/product-family/product-family-details/product-family-details.component';
import { ProductSeriesDetailsComponent } from './sub-component/masters/product/product-series/product-series-details/product-series-details.component';
import { AddProductSeriesComponent } from './sub-component/masters/product/product-series/add-product-series/add-product-series.component';
import { ProductModelDetailsComponent } from './sub-component/masters/product/product-model/product-model-details/product-model-details.component';
import { AddProductModelComponent } from './sub-component/masters/product/product-model/add-product-model/add-product-model.component';
import { OptionsDetailsComponent } from './sub-component/masters/product/options/options-details/options-details.component';
import { AddOptionsComponent } from './sub-component/masters/product/options/add-options/add-options.component';
import { EditViewProductFamilyComponent } from './sub-component/masters/product/product-family/edit-view-product-family/edit-view-product-family.component';
import { EditViewOptionsComponent } from './sub-component/masters/product/options/edit-view-options/edit-view-options.component';
import { EditViewProductSeriesComponent } from './sub-component/masters/product/product-series/edit-view-product-series/edit-view-product-series.component';
import { EditViewProductModelComponent } from './sub-component/masters/product/product-model/edit-view-product-model/edit-view-product-model.component';
import { EditViewProductLineComponent } from './sub-component/masters/product/product-line/edit-view-product-line/edit-view-product-line.component';
import { AddVariantLevel1Component } from './sub-component/masters/product/variant-level-1/add-variant-level1/add-variant-level1.component';
import { EditViewVariantLevel1Component } from './sub-component/masters/product/variant-level-1/edit-view-variant-level1/edit-view-variant-level1.component';
import { VariantLevel1DetailsComponent } from './sub-component/masters/product/variant-level-1/variant-level1-details/variant-level1-details.component';
import { AddVariantLevel2Component } from './sub-component/masters/product/variant-level-2/add-variant-level2/add-variant-level2.component';
import { EditViewVariantLevel2Component } from './sub-component/masters/product/variant-level-2/edit-view-variant-level2/edit-view-variant-level2.component';
import { VariantLevel2DetailsComponent } from './sub-component/masters/product/variant-level-2/variant-level2-details/variant-level2-details.component';
import { AddVariantLevel3Component } from './sub-component/masters/product/variant-level-3/add-variant-level3/add-variant-level3.component';
import { EditViewVariantLevel3Component } from './sub-component/masters/product/variant-level-3/edit-view-variant-level3/edit-view-variant-level3.component';
import { VariantLevel3DetailsComponent } from './sub-component/masters/product/variant-level-3/variant-level3-details/variant-level3-details.component';
import { AddVariantLevel4Component } from './sub-component/masters/product/variant-level-4/add-variant-level4/add-variant-level4.component';
import { EditViewVariantLevel4Component } from './sub-component/masters/product/variant-level-4/edit-view-variant-level4/edit-view-variant-level4.component';
import { VariantLevel4DetailsComponent } from './sub-component/masters/product/variant-level-4/variant-level4-details/variant-level4-details.component';
import { PendingEnquiryComponent } from './sub-component/enquiry/pending-enquiry/pending-enquiry.component';
import { ProductMatrixComponent } from './sub-component/masters/product-matrix/product-matrix.component';
import { BusinessPlanForSalesAndOrderComponent } from './sub-component/masters/BusinessPlan/business-plan-for-sales-and-order/business-plan-for-sales-and-order.component';
import { BusinessPlanDetailsComponent } from './sub-component/masters/BusinessPlan/business-plan-details/business-plan-details.component';
import { RoleGuard } from './auth/role.guard';
import { AppGlobals } from './app.global';
import { EditBusinessPlanComponent } from './sub-component/masters/BusinessPlan/edit-business-plan/edit-business-plan.component';
import { RegionwiseReportComponent } from './sub-component/reports/regionwise-report/regionwise-report.component';
import { ProductwiseReportComponent } from './sub-component/reports/productwise-report/productwise-report.component';
import { CustomerwiseReportComponent } from './sub-component/reports/customerwise-report/customerwise-report.component';
import { SegmentwiseReportComponent } from './sub-component/reports/segmentwise-report/segmentwise-report.component';
import { StockInventoryDetailsComponent } from './sub-component/masters/StockInventory/stock-inventory-details/stock-inventory-details.component';
import { AddStockInventoryComponent } from './sub-component/masters/StockInventory/add-stock-inventory/add-stock-inventory.component';
import { ViewStockInventoryComponent } from './sub-component/masters/StockInventory/view-stock-inventory/view-stock-inventory.component';
import { EditViewWorkFlowScreenAccessComponent } from './sub-component/masters/WorkFlowScreenAccess/edit-view-work-flow-screen-access/edit-view-work-flow-screen-access.component';
import { PowerBIReportsComponent } from './sub-component/reports/power-bireports/power-bireports.component';
import { AlertsDetailsComponent } from './sub-component/masters/Alerts/alerts-details/alerts-details.component';
import { EditViewAlertsComponent } from './sub-component/masters/Alerts/edit-view-alerts/edit-view-alerts.component';
import { AddCustomerOpportunityComponent } from './sub-component/enquiry/add-customer-opportunity/add-customer-opportunity.component';
import { AddLoginPageDynamicImageComponent } from './sub-component/masters/login-page-dynamic-image/add-login-page-dynamic-image/add-login-page-dynamic-image.component';
import { DetailsLoginPageDynamicImageComponent } from './sub-component/masters/login-page-dynamic-image/details-login-page-dynamic-image/details-login-page-dynamic-image/details-login-page-dynamic-image.component';
import { SiteActivityComponent } from './sub-component/reports/site-activity/site-activity/site-activity.component';
import { LeadDetailsComponent } from './sub-component/masters/Lead/lead-details/lead-details/lead-details.component';
import { AddLeadComponent } from './sub-component/masters/Lead/add-lead/add-lead/add-lead.component';
import { EditViewLeadComponent } from './sub-component/masters/Lead/edit-view-lead/edit-view-lead/edit-view-lead.component';
import { EditViewLoginPageDynamicImageComponent } from './sub-component/masters/login-page-dynamic-image/edit-view-login-page-dynamic-image/edit-view-login-page-dynamic-image/edit-view-login-page-dynamic-image.component';
import { PriceconfiguratorComponent } from './sub-component/masters/priceconfigurator/priceconfigurator.component';
import { MyActivityComponent } from './sub-component/customer-engagement/my-activity/my-activity.component';
import { AddActivityComponent } from './sub-component/customer-engagement/activity/add-activity/add-activity.component';
import { ViewActivityComponent } from './sub-component/customer-engagement/activity/view-activity/view-activity.component';
import { BulkEditActivityComponent } from './sub-component/customer-engagement/activity/bulk-edit-activity/bulk-edit-activity.component';
import { EditActivityComponent } from './sub-component/customer-engagement/activity/edit-activity/edit-activity.component';
import { EngagementReportsComponent } from './sub-component/customer-engagement/engagement-reports/engagement-reports.component';
import { GuestDetailsComponent } from './sub-component/masters/guest/guest-details/guest-details.component';
import { AddEmployeeComponent } from './sub-component/masters/employee/add-employee/add-employee.component';
import { EditViewEmployeeComponent } from './sub-component/masters/employee/edit-view-employee/edit-view-employee.component';
import { EmployeeDetailsComponent } from './sub-component/masters/employee/employee-details/employee-details.component';
import { AddGuestComponent } from './sub-component/masters/guest/add-guest/add-guest.component';
import { EditViewGuestComponent } from './sub-component/masters/guest/edit-view-guest/edit-view-guest.component';
import { AddContractFrameAgreementComponent } from './sub-component/masters/configuration/ContractFrameAgreement/add-contract-frame-agreement/add-contract-frame-agreement.component';
import { EditViewContractFrameAgreementComponent } from './sub-component/masters/configuration/ContractFrameAgreement/edit-view-contract-frame-agreement/edit-view-contract-frame-agreement.component';
import { CustomerFrameAgreementDetailsComponent } from './sub-component/masters/configuration/ContractFrameAgreement/customer-frame-agreement-details/customer-frame-agreement-details.component';
import { CalenderViewComponent } from './sub-component/customer-engagement/activity/calender-view/calender-view.component';
import { PlannedVsActualActivityComponent } from './sub-component/customer-engagement/planned-vs-actual-activity/planned-vs-actual-activity.component';
import { CustomerEngagementDetailsComponent } from './sub-component/customer-engagement/customer-engagement-details/customer-engagement-details.component';
import { CustomerEngagementComponent } from './sub-component/customer-engagement/customer-engagement/customer-engagement.component';



const routes: Routes = [
  {
    path: "Login",
    component: AppComponent,
    data: { title: 'Login' }
    // canActivate: [RoleGuard],
    // data: {
    //   permissions: {
    //     only: AppGlobals.AllRolesArray,
    //     redirectTo: '/Login'
    //   }
    // }
  },
  {
    path: "Register", component: RegisterComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.AllRolesArray,
        redirectTo: '/Login'
      },
      Element: 'CanAccessRegister',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'Register'
    }
  },
  {
    path: "Profile", component: ProfileComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.AllRolesArray,
        redirectTo: '/Login'
      },
      Element: 'CanAccessProfile',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'Profile'
    }
  },
  {
    path: "Dashboard", component: DashboardComponent,
    //canActivate: [RoleGuard],
    canActivateChild: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.AllRolesArray,
        except: ['GUEST'],
        redirectTo: '/Login'
      },
      Element: 'CanAccessDashboard',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Dashboard'
    }
  },
  {
    path: "Enquiry", component: EnquiryComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.AllRolesArray,
        redirectTo: '/Login'
      },
      Element: 'isAllEnquiry',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Enquiry'
    }
  },
  {
    path: "PendingEnquiry", component: PendingEnquiryComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.AllRolesArray,
        redirectTo: '/Login'
      },
      Element: 'isAllEnquiry',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'PendingEnquiry'
    }
  },
  //To merge the add and edit component
  {
    path: "AddEnquiry", component: AddEnquiryComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.AllRolesArray,
        redirectTo: '/Login'
      },
      Element: 'isAllEnquiry',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddEnquiry'
    }
  },
  //To merge the add and edit component
  {
    path: "AddEnquiryLimitedField", component: AddCustomerOpportunityComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isAllEnquiry',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddEnquiryLimitedField'
    }
  },
  //{ path: "AddEnquiry", component: EditViewEnquiryComponent },
  {
    path: "EditViewEnquiry", component: EditViewEnquiryComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.AllRolesArray,
        redirectTo: '/Login'
      },
      Element: 'isAllEnquiry',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'Edit Enquiry'

    }
  },
  {
    path: "EditViewEnquiry/:id", component: EditViewEnquiryComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isAllEnquiry',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewEnquiry'
    }
  },
  //Master
  {
    path: "Guest", component: GuestDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.GuestVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isGuest',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Guest'
    }
  },
  {
    path: "AddGuest", component: AddGuestComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.GuestEditArray,
        redirectTo: '/Login'
      },
      Element: 'isGuest',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddGuest'
    }
  },
  {
    path: "EditViewGuest/:id", component: EditViewGuestComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.GuestVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isGuest',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewGuest'
    }
  },
  {
    path: "Employee", component: EmployeeDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.EmployeeVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isEmployee',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'AddEmployee'
    }
  },
  {
    path: "AddEmployee", component: AddEmployeeComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.EmployeeEditArray,
        redirectTo: '/Login'
      },
      Element: 'isEmployee',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddEmployee'
    }
  },
  {
    path: "EditViewEmployee/:id", component: EditViewEmployeeComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.EmployeeVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isEmployee',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewEmployee'
    }
  },


  /****************************************************************** */


  {
    path: "AddCustomer/:type/:id", component: AddCustomerComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.CustomerEditArray,
        redirectTo: '/Login'
      },
      Element: 'isCustomer',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddCustomer'
    }
  },
  {
    path: "EditViewCustomer/:id", component: EditViewCustomerComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.CustomerVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isCustomer',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewCustomer'
    }
  },
  {
    path: "Customer", component: CustomerDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.CustomerVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isCustomer',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Customer'
    }
  },
  {
    path: "ConfigureMasters/:heading", component: ConfigurationDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.COnfigureVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isConfigureMasters',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ConfigureMasters'
    }
  },
  {
    path: "ConfigureMasters", component: ConfigurationDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.COnfigureVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isConfigureMasters',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ConfigureMasters'
    }
  },
  {
    path: "AddConfiguration/:heading", component: AddConfigurationComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.COnfigureEditArray,
        redirectTo: '/Login'
      },
      Element: 'isConfigureMasters',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddConfiguration'
    }
  },
  //for single case only
  {
    path: "AddConfiguration/:heading/:id", component: AddConfigurationComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isConfigureMasters',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddConfiguration'
    }
  },
  {
    path: "EditViewConfiguration/:id/:heading", component: EditViewConfigurationComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.COnfigureVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isConfigureMasters',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewConfiguration'
    }
  },

  {
    path: "AddLoginImage", component: AddLoginPageDynamicImageComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isLoginPageImage',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddLoginImage'
    }
  },
  {
    path: "LoginPageImage", component: DetailsLoginPageDynamicImageComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isLoginPageImage',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'LoginPageImage'
    }
  },

  {
    path: "ViewLoginPageImage/:id", component: EditViewLoginPageDynamicImageComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isLoginPageImage',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'ViewLoginPageImage'
    }
  },
  {
    path: "Lead", component: LeadDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isLead',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Lead'
    }
  },

  {
    path: "AddLead", component: AddLeadComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isLead',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddLead'
    }
  },
  {
    path: "EditViewLead/:id", component: EditViewLeadComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isLead',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewLead'
    }
  },

  //ProductLine Master
  {
    path: "AddProductLine", component: AddProductLineComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddProductLine'
    }
  },
  {
    path: "EditViewProductLine/:id", component: EditViewProductLineComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewProductLine'
    }
  },
  {
    path: "ProductLine", component: ProductLineDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ProductLine'
    }
  },
  {
    path: "AddProductFamily", component: AddProductFamilyComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddProductFamily'
    }
  },
  {
    path: "EditViewProductFamily/:id", component: EditViewProductFamilyComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewProductFamily'
    }
  },
  {
    path: "ProductFamily", component: ProductFamilyDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ProductFamily'
    }
  },
  {
    path: "AddProductSeries", component: AddProductSeriesComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddProductSeries'
    }
  },
  {
    path: "EditViewProductSeries/:id", component: EditViewProductSeriesComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewProductSeries'
    }
  },
  {
    path: "ProductSeries", component: ProductSeriesDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ProductSeries'
    }
  },
  {
    path: "AddProductModel/:id", component: AddProductModelComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddProductModel'
    }
  },
  {
    path: "EditViewProductModel/:id", component: EditViewProductModelComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewProductModel'
    }
  },
  {
    path: "ProductModel/:id", component: ProductModelDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ProductModel'
    }
  },
  {
    path: "AddVariantLevel1/:id", component: AddVariantLevel1Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddVariantLevel1'
    }
  },
  {
    path: "EditViewVariantLevel1/:label/:ProductModel/:Price/:Status/:IsPriceOnRequest", component: EditViewVariantLevel1Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel1'
    }
  },
  {
    path: "EditViewVariantLevel1/:label/:id", component: EditViewVariantLevel1Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel1'
    }
  },
  {
    path: "VariantLevel1/:id/:Label", component: VariantLevel1DetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'VariantLevel1'
    }
  },
  {
    path: "AddVariantLevel2/:Seriesid", component: AddVariantLevel2Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddVariantLevel2'
    }
  },
  {
    path: "EditViewVariantLevel2/:Level/:Model/:Price/:Status/:IsPriceOnRequest", component: EditViewVariantLevel2Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel2'
    }
  },
  {
    path: "EditViewVariantLevel2/:Id", component: EditViewVariantLevel2Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel'
    }
  },
  {
    path: "VariantLevel2/:Seriesid/:Variant1/:Variant2", component: VariantLevel2DetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'VariantLevel2Details'
    }
  },
  {
    path: "AddVariantLevel3/:Seriesid", component: AddVariantLevel3Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddVariantLevel3'
    }
  },
  {
    path: "EditViewVariantLevel3/:Model/:Price/:Status/:IsPriceOnRequest", component: EditViewVariantLevel3Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel3'
    }
  },
  {
    path: "EditViewVariantLevel3/:id", component: EditViewVariantLevel3Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel3'
    }
  },
  {
    path: "VariantLevel3/:Seriesid/:Variant2/:Variant3", component: VariantLevel3DetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'VariantLevel3Details'
    }
  },
  {
    path: "AddVariantLevel4/:Seriesid", component: AddVariantLevel4Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddVariantLevel4'
    }
  },
  {
    path: "EditViewVariantLevel4/:Model/:Price/:Status/:IsPriceOnRequest", component: EditViewVariantLevel4Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel4'
    }
  },
  {
    path: "EditViewVariantLevel4/:id", component: EditViewVariantLevel4Component,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewVariantLevel4'
    }
  },
  {
    path: "VariantLevel4/:Seriesid/:Variant3/:Variant4", component: VariantLevel4DetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'VariantLevel4Details'
    }
  },
  {
    path: "AddOptions/:Seriesid", component: AddOptionsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterEditArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddOptions'
    }
  },
  {
    path: "EditViewOptions/:id", component: EditViewOptionsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewOptions'
    }
  },
  {
    path: "Options/:Seriesid", component: OptionsDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'OptionsDetails'
    }
  },
  {
    path: "ProductMatrix", component: ProductMatrixComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.ProductMasterVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isProductLine',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ProductMatrix'
    }
  },
  {
    path: "AddBusinessPlan", component: BusinessPlanForSalesAndOrderComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.BusinessPlanVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isBusinessPlanforSalesandOrder',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'BusinessPlan'
    }
  },
  {
    path: "EditBusinessPlan", component: EditBusinessPlanComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.BusinessPlanVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isBusinessPlanforSalesandOrder',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditBusinessPlan'
    }
  },
  {
    path: "BusinessPlanDetails", component: BusinessPlanDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.BusinessPlanVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isBusinessPlanforSalesandOrder',
      Access: 'CanList',
      redirectTo: '/Login',
      title: ' BusinessPlanDetails'
    }
  },

  {
    path: "StockInventory", component: StockInventoryDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.StockInventoryVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isStockInventory',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'StockInventoryDetails'
    }

  },
  {
    path: "ViewStockInventory/:id", component: ViewStockInventoryComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.StockInventoryVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isStockInventory',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'ViewStockInventory'
    }

  },
  {
    path: "AddStockInventory", component: AddStockInventoryComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.StockInventoryVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isStockInventory',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddStockInventory'
    }

  },
  {
    path: "WorkFlowScreenAccess", component: EditViewWorkFlowScreenAccessComponent,
    canActivate: [RoleGuard],
    data: {
      permissions: {
        only: AppGlobals.WorkFlowScreenAccessVisibleArray,
        redirectTo: '/Login'
      },
      Element: 'isWorkflowScreenAccess',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewWorkFlowScreenAccess'
    }
  },
  {
    path: "Reminders-config", component: AlertsDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isAlertsManagement',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'AlertsDetails'
    }
  },
  {
    path: "Reminders-config/:id", component: EditViewAlertsComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isAlertsManagement',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditViewAlerts'
    }
  },
  //<REPORTS SECTION
  {
    path: "RegionWise-Reports", component: RegionwiseReportComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'CanAccessReports',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'RegionwiseReport'
    }
  },
  {
    path: "ProductWise-Reports", component: ProductwiseReportComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'CanAccessReports',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'ProductwiseReport'
    }
  },
  {
    path: "CustomerWise-Reports", component: CustomerwiseReportComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'CanAccessReports',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'CustomerwiseReport'
    }
  },
  {
    path: "SegmentWise-Reports", component: SegmentwiseReportComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'CanAccessReports',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'SegmentwiseReport'
    }
  },
  {
    path: "PriceConfigurator", component: PriceconfiguratorComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isPriceConfigurator',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Priceconfigurator'
    }
  },
  {
    path: "Site-Activity", component: SiteActivityComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isSiteActivity',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'SiteActivity'
    }
  },
  {
    path: "PowerBI-Reports", component: PowerBIReportsComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'CanAccessPowerBIReports',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'PowerBIReports'
    }
  },
  // {
  //   path: "FrameAgreementApproval", component: FrameAgreementApprovalComponent,
  //   canActivate: [RoleGuard],
  //   data: {
  //     permissions: {
  //       only: AppGlobals.AllRolesArray,
  //       redirectTo: '/Login'
  //     }, title: 'FrameAgreementApproval'}
  // },
  {
    path: "AddContractFrameAgreement/:id", component: AddContractFrameAgreementComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'IsCustomerFrameAgreement',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'CustomerFrameAgreement'
    }
  },
  {
    path: "EditViewContractFrameAgreement/:id", component: EditViewContractFrameAgreementComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'IsCustomerFrameAgreement',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'CustomerFrameAgreement'
    }
  },
  {
    path: "CustomerFrameAgreementDetails", component: CustomerFrameAgreementDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'IsCustomerFrameAgreement',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'CustomerFrameAgreement'
    }
  },


  {
    path: "Bulk-Edit-Activity", component: BulkEditActivityComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'BulkEditActivity'
    }
  },

  {
    path: "My-Activities", component: MyActivityComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'MyActivity'
    }
  }, {
    path: "Add-Activity", component: AddActivityComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanCreate',
      redirectTo: '/Login',
      title: 'AddActivity'
    }
  }, {
    path: "View-Activity/:id", component: ViewActivityComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'ViewActivity'
    }
  }, {
    path: "Edit-Activity/:id", component: EditActivityComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'IsVisible',
      redirectTo: '/Login',
      title: 'EditActivity'
    }
  },
  {
    path: "customer-reports", component: EngagementReportsComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'EngagementReports'
    }
  },
  {
    path: "Calender-View", component: CalenderViewComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Calender-View'

    }
  },
  {
    path: "planned-actual", component: PlannedVsActualActivityComponent,
    //canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'Planned-Actual'
    }
  },
  {
    path: "CustomerEngagement", component: CustomerEngagementComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'CustomerEngagement'
    }
  },
  {
    path: "CustomerEngagementDetails", component: CustomerEngagementDetailsComponent,
    canActivate: [RoleGuard],
    data: {
      Element: 'isCustomerEngagment',
      Access: 'CanList',
      redirectTo: '/Login',
      title: 'CustomerEngagement'
    }
  },

  { path: "", 
    redirectTo: "/Login", 
    pathMatch: "full", 
    data: { title: 'Login' } 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
