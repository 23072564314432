import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegisterModel } from '../Model/RegisterModel';
import { CommonService } from '../Service/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {


  forgotPassword: RegisterModel = new RegisterModel();

  constructor(
    private commonService: CommonService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>, public cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.commonService.RefreshHeaderFun();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();

  }

}
