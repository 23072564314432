import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-productmodel-columnchart',
  templateUrl: './productmodel-columnchart.component.html',
  styleUrls: ['./productmodel-columnchart.component.scss']
})
export class ProductmodelColumnchartComponent implements OnInit {
  showLoader: boolean;
  chart1Data: { category: string; value: number; }[];

  constructor(private commonService:CommonService) { }

  ngOnInit(): void {
    this.commonService.RefreshHeaderFun();
    this.ProductModelWisePieChart();
  }
  ProductModelWisePieChart() {

    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=Units";
    queryString += "&Year=2020";

    let chart1 = am4core.create("ProductModelBarChart", am4charts.XYChart);
    chart1.exporting.menu = new am4core.ExportMenu();

    let title = chart1.titles.create();
    title.text = "Product Model wise";
    title.fontSize = 15;
    title.fontWeight = "bold";
    title.marginBottom = 30;

    chart1.legend = new am4charts.Legend();
    chart1.legend.labels.template.text = "{category}: {value.value}";
    chart1.hiddenState.properties.opacity = 0;
    this.chart1Data = [{
      "category": "North",
      "value": 21
    }, {
      "category": "West",
      "value": 14
    }, {
      "category": "South East",
      "value": 19
    }, {
      "category": "South",
      "value": 11
    }];
    chart1.data = this.chart1Data;



    var categoryAxis = chart1.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    var valueAxis = chart1.yAxes.push(new am4charts.ValueAxis());

    // Create series
    var columnSeries = chart1.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueY = "value";
    columnSeries.dataFields.categoryX = "category";
    columnSeries.name = "Enquiry";
    columnSeries.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
    columnSeries.columns.template.propertyFields.stroke = "stroke";
    columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
    columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
    columnSeries.tooltip.label.textAlign = "middle";
    columnSeries.tooltip.getFillFromObject = false;
    columnSeries.tooltip.background.fill = am4core.color("#4a463c");

    columnSeries.columns.template.events.on("hit", function(ev) {
    }, this);

  }

}
