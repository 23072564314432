import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivityLinkageEntityType, ActivityOutcomeByType, ActivityPurposeByType, ActivityStatusByType, ActivityType, EntityRecord, User } from 'src/app/Model/ActivityModel';
import { ActivityService } from 'src/app/Service/activity.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-activity',
  templateUrl: './edit-activity.component.html',
  styleUrls: ['./edit-activity.component.scss']
})
export class EditActivityComponent implements OnInit {

  constructor(private cd: ChangeDetectorRef ,private activityService: ActivityService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private location: Location) { }

  ActivityId: number;
  @Input() showLoader: boolean = false;

  ENTITY_TYPE_CUSTOMER = "1";
  ENTITY_TYPE_LEAD = "2";
  ENTITY_TYPE_ENQUIRY = "3";
  activityTypeMaster: ActivityType[] = [];
  activityStatusByTypeMaster: ActivityStatusByType[] = [];
  activityOutcomeByTypeMaster: ActivityOutcomeByType[] = [];
  activityPurposeByTypeMaster: ActivityPurposeByType[] = [];
  allEntityTypesMaster: ActivityLinkageEntityType[] = [];
  allUserMaster = [];
  frmData: any = {};
  selectedEnquries=[];
  previousSelectedEnquiries = [];
  selectedCustomers=[];
  selectedCustomersOrLeads = [];
  selectedEntityRecords = [];
  selectedLeads=[];
  selectedLinkagesByType = [];
  selectedEntityTypeId:any = 0;
  entityTypeFilterArray = [];
  showOutcome: boolean = false;
  subtypeArray1 = ['Seminar', 'Webinar']
  allLeads:EntityRecord[] = [];
  allCustomers:EntityRecord[] = [];
  relatedEntityRecords:EntityRecord[] = [];
  allEnquiries:EntityRecord[] = [];
  selectedOptions: any[] = [];
  isEnquiryShow:boolean =false;




  ngOnInit() {

    this.showLoader = true;
    this.ActivityId = this.route.snapshot.params["id"];
    forkJoin([
      this.GetActivityTypeMaster(),
      this.GetEntityTypesMaster(),
      this.GetAllUserMaster()
    ]).pipe(tap(res => {

      this.getActivityById(this.ActivityId);
    })).subscribe(x=>{

    });
  }


  gobackNavigate() {


    // const parentComponent = localStorage.getItem('ViewType');;
    // if (parentComponent === 'Calender') {
    //   localStorage.removeItem('ViewType')
    //   this.router.navigate(['/Calender-View']);
    // } else if (parentComponent === 'ListView') {
    //   localStorage.removeItem('ViewType')
    //   this.router.navigate(['/My-Activities']);
    // }else {
    //   this.location.back();
    // }
    this.location.back();
  }

  convertParticipantFormatForSerchableDropdown(frmData){
    this.frmData.ListActivityParticipants = frmData.ListActivityParticipants.map(item => item.ActivityParticipant);
    this.showLoader = false;
  }

  getAllCustomers(){
    let tracker =  this.activityService.getrelatedEntityRecords(this.ENTITY_TYPE_CUSTOMER,null);
    tracker.subscribe((data: EntityRecord[]) => {
      let linkagesByEntityType = this.groupArrayByKey(data,"EntityTypeId");
      this.allCustomers = linkagesByEntityType[this.ENTITY_TYPE_CUSTOMER] as EntityRecord[];
      this.relatedEntityRecords = this.allCustomers;
     });
     return tracker;
  }

  getAllLeads(){
    let tracker =  this.activityService.getrelatedEntityRecords(this.ENTITY_TYPE_LEAD,null);
    tracker.subscribe((data: EntityRecord[]) => {
      let linkagesByEntityType = this.groupArrayByKey(data,"EntityTypeId");
      this.allLeads = linkagesByEntityType[this.ENTITY_TYPE_LEAD] as EntityRecord[];
      this.relatedEntityRecords = this.allLeads;
     });

     return tracker;
  }

  getEnquiriessByCustomers(){

    if(Array.isArray(this.selectedCustomers) && this.selectedCustomers.length>0){
      let tracker =  this.activityService.getrelatedEntityRecords(this.ENTITY_TYPE_ENQUIRY,this.selectedCustomers);
      tracker.subscribe((data: EntityRecord[]) => {
        let linkagesByEntityType = this.groupArrayByKey(data,"EntityTypeId");
        this.allEnquiries = linkagesByEntityType[this.ENTITY_TYPE_ENQUIRY] as EntityRecord[];
        this.relatedEntityRecords = this.allEnquiries;
        this.previousSelectedEnquiries = [...this.selectedEnquries];
        this.selectedEnquries = [];
        for(let enquiry of this.allEnquiries) {
          for(let prevSelEnquiryId of this.previousSelectedEnquiries){
            if(enquiry.EntityId.toString() == prevSelEnquiryId) {
              this.selectedEnquries.push(enquiry.EntityId);            
            }
          }
        }
       });
       return tracker;
    } else {
      this.selectedEnquries = [];
    }
  }

  GetEntityTypesMaster() {
    let tracker = this.activityService.getallEntityTypesMaster();
    tracker.subscribe((data: ActivityLinkageEntityType[]) => {
      this.allEntityTypesMaster = data;
    });
    return tracker;
  }

  GetActivityTypeMaster() {
    let tracker = this.activityService.getActivityTypeMaster();
    tracker.subscribe((data) => {
      this.activityTypeMaster = data;
    });
    return tracker;
  }

  GetAllUserMaster() {
    let tracker = this.activityService.getallUserMaster();
    tracker.subscribe((data: User[]) => {
      this.allUserMaster = data;
    });
    return tracker;
  }

  getChildObjects(obj: any, firstLevelKey: string | number, childKey: string|number) {
    if (!obj[firstLevelKey]) {
      return [];
    }
    return obj[firstLevelKey].map((item: any) => item[childKey]);
  }



  getActivityById(id: number) {
    this.showLoader = true;
    this.activityService.getActivityById(id).subscribe(data => {
      this.frmData = data;
      this.convertParticipantFormatForSerchableDropdown(data);
      this.getActivityPurpose(this.frmData.ActivityTypeId);
      this.getActivityStatus(this.frmData.ActivityTypeId);
      this.getActivityOutcome(this.frmData.ActivityTypeId);
      this.getAllCustomers();
      this.getAllLeads();
      this.selectedLinkagesByType = this.groupArrayByKey(this.frmData.ListActivityLinkage,"EntityTypeId");
      for(let entityTypeId in this.selectedLinkagesByType){


        if(entityTypeId == this.ENTITY_TYPE_CUSTOMER){
          this.selectedCustomers = this.getChildObjects(this.selectedLinkagesByType,this.ENTITY_TYPE_CUSTOMER,"EntityId");

          this.getEnquiriessByCustomers();
          this.selectedEnquries = this.getChildObjects(this.selectedLinkagesByType,this.ENTITY_TYPE_ENQUIRY,"EntityId");
        }
        if(entityTypeId == this.ENTITY_TYPE_LEAD){
          this.selectedLeads = this.getChildObjects(this.selectedLinkagesByType,this.ENTITY_TYPE_LEAD,"EntityId");
        }
      }
      if (this.frmData.ActivityOutcomeId == 2) {
        this.showOutcome = true;
      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to Load activity details!");
      this.showLoader = false;
    });
  }

  onOwnerChange(frmData, ActivityOwnerId) {
    if (!frmData.ListActivityParticipants || frmData.ListActivityParticipants?.length < 1) {
      frmData.ListActivityParticipants = [];
      frmData.ListActivityParticipants.push({ ActivityParticipant: ActivityOwnerId });
    } else {
      frmData.ListActivityParticipants[0].ActivityParticipant = this.frmData.ActivityOwnerId;
    }
  }

  getActivityPurpose(ActivityTypeId: any) {
    return this.activityService.getActivityPurposeMaster(ActivityTypeId).subscribe((data: ActivityPurposeByType[]) => {
      this.activityPurposeByTypeMaster = data;
    });
  }

  getActivityStatus(ActivityTypeId: number) {
    this.activityService.getActivityStatusMaster(ActivityTypeId).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
      let newObj = this.activityStatusByTypeMaster.find((arg) => {
        return arg.ActivityStatusId == this.frmData.ActivityStatusId
      })

      if (newObj.ActivityStatusCode === "COMPLETED") {
        this.showOutcome = true;
      } else {
        this.showOutcome = false;
      }
    });
  }

  getActivityOutcome(ActivityTypeId: number) {
    this.activityService.getActivityOutcomeMaster(ActivityTypeId).subscribe((data: ActivityOutcomeByType[]) => {
      this.activityOutcomeByTypeMaster = data;
    });
  }



  groupActivityLinkageByEntityTypeId(listActivityLinkage: any[]) {
    const groupedActivityLinkage = listActivityLinkage.reduce((acc, curr) => {
      const entityTypeId = curr.EntityTypeId;
      if (!acc[entityTypeId]) {
        acc[entityTypeId] = {
          EntityTypeId: entityTypeId,
          EntityTypeName: curr.EntityTypeName,
          activityLinkages: []
        };
      }
      acc[entityTypeId].activityLinkages.push(curr);
      return acc;
    }, {});
    return Object.values(groupedActivityLinkage);
  }

  groupArrayByKey(array: any[], key: string):[] | null {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});

  }

  convertSelectedEntityArrayToEntityObjCollection(entities: any[],entityTypeId:any) {

    let response:any[] = [];
    if(Array.isArray(entities) && entities.length>0){
      entities.forEach(entityId => {
        response.push({ "EntityTypeId": entityTypeId, "EntityId": entityId});
      });
      return response;
    }else{
      return null;
    }
  }




  getMasterRecordsForGivenEntityType(EntityTypeId,EntityIds:any[]|null|string){

   if(EntityTypeId == 3){
     //EntityIds = this.selectedCustomersOrLeads;//In case of enquiry, load only those which are related to selected customers

   }

   let tracker =  this.activityService.getrelatedEntityRecords(EntityTypeId,EntityIds);
   tracker.subscribe((data: EntityRecord[]) => {

      let linkagesByEntityType = this.groupArrayByKey(data,"EntityTypeId");

      if(EntityTypeId==1){
        this.allCustomers = linkagesByEntityType[EntityTypeId] as EntityRecord[];
        this.relatedEntityRecords = this.allCustomers;
      }
      if(EntityTypeId==2){
        this.allLeads =  linkagesByEntityType[EntityTypeId]  as EntityRecord[];
        this.relatedEntityRecords = this.allLeads;
      }
      if(EntityTypeId==3){
        this.allEnquiries = linkagesByEntityType[EntityTypeId] as EntityRecord[];
      }

    });

    return tracker;
  }





  onSubmit(frmData: any, addActivityForm: any) {
    if (!addActivityForm.valid) {
      return
    }
    let newObj = this.activityStatusByTypeMaster.find((arg) => {
      return arg.ActivityStatusId == frmData.ActivityStatusId
    })
    if (newObj.ActivityStatusCode === "COMPLETED") {
      this.showOutcome = true;
    } else {
      this.frmData.OutcomeDesc = null;
      this.frmData.ActivityOutcomeDesc = null;
      this.frmData.ActivityOutcomeId = null;
      this.showOutcome = false;
    }

// ***** Participant Conversion*****
     this.frmData.ListActivityParticipants = this.frmData.ListActivityParticipants.map(id => ({ ActivityParticipant: id }));
     let selectedCustomersAsObjCol = this.convertSelectedEntityArrayToEntityObjCollection(this.selectedCustomers,this.ENTITY_TYPE_CUSTOMER);
     let selectedLeadsAsObjCol = this.convertSelectedEntityArrayToEntityObjCollection(this.selectedLeads,this.ENTITY_TYPE_LEAD);
     let selectedEnquriesAsObjCol = this.convertSelectedEntityArrayToEntityObjCollection(this.selectedEnquries,this.ENTITY_TYPE_ENQUIRY);

     //Clear existing linkage object
     this.frmData.ListActivityLinkage = [];

     if(selectedCustomersAsObjCol){
      this.frmData.ListActivityLinkage = this.frmData.ListActivityLinkage.concat(selectedCustomersAsObjCol);
      }
      if(selectedLeadsAsObjCol){
        this.frmData.ListActivityLinkage = this.frmData.ListActivityLinkage.concat(selectedLeadsAsObjCol);
      }
      if(selectedEnquriesAsObjCol){
        this.frmData.ListActivityLinkage = this.frmData.ListActivityLinkage.concat(selectedEnquriesAsObjCol);
      }

    this.showLoader = true;

    this.activityService.addOrUpdateActivity(frmData).subscribe(activity => {
      this.toastr.success(activity.ResponseMessage);
      this.gobackNavigate();
      this.showLoader = false;
    });
  }

  ChangeStatus(statusId: any) {
    let newObj = this.activityStatusByTypeMaster.find((arg) => {
      return arg.ActivityStatusId == statusId
    })
    if (newObj.ActivityStatusCode === "COMPLETED") {
      this.showOutcome = true;
    } else {
      this.showOutcome = false;
    }
  }


  disableById(allUserMaster: any, ID) {
    allUserMaster.map((arg) => {
      return arg.Id == ID
    })
  }

  onKeyDown(event: KeyboardEvent) {
    // Check if the pressed key is a space and if the input field is empty
    if (event.key === ' ' && event.target['value'].trim() === '') {
      event.preventDefault(); // prevent the space from being entered
    }
  }




  onChangeInCustomerSelection(){
    this.allEnquiries = [];
    // this.selectedEnquries = [];
    this.getEnquiriessByCustomers();
  }

}
