import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-product-series-upload-log',
  templateUrl: './product-series-upload-log.component.html',
  styleUrls: ['./product-series-upload-log.component.scss']
})
export class ProductSeriesUploadLogComponent implements OnInit {

  dataSourcePrice: any;
  constructor(public dialogRef: MatDialogRef<ProductSeriesUploadLogComponent>, @Inject(MAT_DIALOG_DATA) public data: {dataSourcePrice: any}) { }

  ngOnInit(): void {
    this.dataSourcePrice = this.data;
  }
 
  onOkClick(): void {
    this.dialogRef.close();
  }
}
