<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        TC/TR Matrix
                                    </h3>
                                </div>
                            </div>
                            

                            <div class="m-portlet__head-tools">
                                <ul class="m-portlet__nav">
                                    <li class="m-portlet__nav-item">
                                        <select class="form-control " id="product" name="product"
                                            [(ngModel)]="ProductSeriesId" (change)="ProductSeriesChange($event)">
                                            <option value="undefined" selected disabled>
                                                Select Product Series
                                            </option>
                                            <option *ngFor="let item of ProductSeries" value="{{item.Id}}">
                                                {{item.SeriesName}}</option>
                                        </select>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <select class="form-control " id="productmodel" name="productmodel"
                                            [(ngModel)]="ProductModelId" (change)="getProductMatrix()">
                                            <option value="undefined" selected disabled>
                                                Select Product Model
                                            </option>
                                            <option *ngFor="let item of ProductModelList" value="{{item.Id}}">
                                                {{item.ModelName}}</option>
                                        </select>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <!-- <button type="button" class="btn btn-primary" (click)="getProductMatrix()">Show
                                            TC/TR Matrix</button> -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                       
                        
                        <ng-container *ngIf="ShowGrid && ProductMatrix.stationlist.length != 0 && ProductMatrix.stationlist.length != null && ProductMatrix.stationlist != []">
                            <form class="m-login__form m-form" name="myform" #myform="ngForm"
                                    (ngSubmit)="myform.form.valid && SaveMatrix()" novalidate>
                            <div class="m-portlet__body">
                                <div class="m-portlet__body table-responsive">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4">

                                            <mat-label style="font-weight: bold;font-size: 15px;color: black;">
                                                {{SelectedtSeriesdata.SeriesName}}</mat-label><br>
                                                <mat-form-field style="width: 50%;" class="pull-left">
                                                   
                                                <mat-select [(ngModel)]="VariantDrpdown" name="Prodvariant"
                                                    (selectionChange)="RedirectToVariant($event)">
                                                    <mat-option value="" disabled>Select Variant</mat-option>
                                                    <mat-option value="ProductModel">Product Model</mat-option>
                                                    <mat-option
                                                        *ngIf="SelectedtSeriesdata.Label_Variant1 != '' && SelectedtSeriesdata.Label_Variant1 != null"
                                                        value="Variant1">{{SelectedtSeriesdata.Label_Variant1}}
                                                    </mat-option>
                                                    <mat-option
                                                        *ngIf="SelectedtSeriesdata.Label_Variant2 != '' && SelectedtSeriesdata.Label_Variant2 != null"
                                                        value="Variant2">{{SelectedtSeriesdata.Label_Variant2}}
                                                    </mat-option>
                                                    <mat-option
                                                        *ngIf="SelectedtSeriesdata.Label_Variant3 != '' && SelectedtSeriesdata.Label_Variant3 != null"
                                                        value="Variant3">{{SelectedtSeriesdata.Label_Variant3}}
                                                    </mat-option>
                                                    <mat-option
                                                        *ngIf="SelectedtSeriesdata.Label_Variant4 != '' && SelectedtSeriesdata.Label_Variant4 != null"
                                                        value="Variant4">{{SelectedtSeriesdata.Label_Variant4}}
                                                    </mat-option>
                                                    <mat-option value="Options">Product Options</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-12 col-md-2" ></div>
                                        <div class="col-sm-12 col-md-6" >
                                            <div class="markedFields pull-right">
                                                <div class="form-group m-form__group row">
                                                    <span style="font-weight: bold;font-size: 14px;">Note:
                                                    
                                                    <span style="margin-left: 5px;">POR - Price on Request </span>
                                                    <span style="margin-left: 5px;">
                                                        NA - Not Available</span></span>
                                                </div>
                                                <!-- <div class="form-group m-form__group row">
                                                    <ul style="list-style: none;">
                                                        <li><span>
                                                            POR - Price on Request </span></li>
                                                        <li></li>
                                                        </ul>
                                                </div> -->
                                            </div>
                                        </div>
                                        
                                        <!-- <button
                                        class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next btnspc"
                                        type="button" title="Edit Matrix" (click)="EditMatrix()"
                                        [disabled]="isEditable">Edit
                                        Matrix</button>
                                    <button
                                        class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next btnspc"
                                        type="button" title="Save Matrix" (click)="SaveMatrix()"
                                        [disabled]="!isEditable">Save
                                        Matrix</button> -->
                                    </div>
                                    
                                    <fieldset [disabled]="isEditable">
                                        <table 
                                            class="table order-lists table-striped- table-bordered table-hover table-edit mobileView">
                                            <thead *ngIf="ProductMatrix.stationlist!=undefined || ProductMatrix.stationlist!=[]">
                                                <tr>
                                                    <th>Indexer/Speed Steps</th>
                                                    <ng-container
                                                        *ngFor="let step of ProductMatrix.stationlist[0].speedsteplist;">
                                                        <th>{{step.stepname}}&nbsp;(₹)</th>
                                                    </ng-container>
                                                    <th>Station Price&nbsp;(₹)</th>
                                                </tr>
                                            </thead>
                                            <tbody  *ngIf="ProductMatrix.stationlist.length != 0 || ProductMatrix.stationlist.length != null">
                                                <tr *ngFor="let item of ProductMatrix.stationlist;let i=index;">
                                                    <td style="width: 5%;">{{item.stationname}}</td>
                                                    <ng-container *ngFor="let step of item.speedsteplist; let j=index;">
                                                       
                                                        <td *ngIf="step.IsSpeedStepPOR">POR</td>
                                                        <td *ngIf="!step.IsSpeedStepPOR && step.stepid!=0">
                                                            <div *ngIf="!isEditable">
                                                                <input type="text"
                                                                name="price{{i}}{{j}}" [(ngModel)]="step.price" maxlength="7"
                                                                    pattern="[0-9]+" (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                                    (change)="SpeedStepPriceValidation(i,j)"   [ngClass]="{ 'is-invalid': step.isSpeedstepValid }"
                                                                    style="border: none;" [disabled]="step.stepid==0">
                                                                    <div>
                                                                        <div style="font-size: 85%;color: #e4bac1;" *ngIf="step.isSpeedstepValid != undefined && !step.isSpeedstepValid">
                                                                            Please enter valid price</div>
                                                                    </div>
                                                            </div>
                                                            <div *ngIf="isEditable">
                                                                {{step.price | indianCurrency}}
                                                            </div>
                                                          
                                                        </td>
                                                        <td *ngIf="!step.IsSpeedStepPOR && step.stepid==0" style="background: gainsboro;">
                                                            <input type="text" value="NA" disabled="disabled" style="border: none;" />
                                                        </td>
                                                    </ng-container>
                                                    <td *ngIf="item.IsStationPOR">POR</td>
                                                    <td *ngIf="!item.IsStationPOR && item.stationid!=0">
                                                        <div *ngIf="!isEditable">
                                                            <input type="text" maxlength="7"
                                                            pattern="[0-9]+" name="stationprice{{i}}" (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                            [(ngModel)]="item.price"   [ngClass]="{ 'is-invalid': item.isStationValid }"
                                                            (change)="StationPriceValidation(i)" style="border: none;"
                                                            [disabled]="item.stationid==0">
                                                            <div >
                                                                <div style="font-size: 85%;color: #f4516c;" *ngIf="item.isStationValid != undefined && !item.isStationValid">
                                                                    Please enter valid price</div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="isEditable">
                                                            {{item.price | indianCurrency}}
                                                        </div>
                                                        
                                                        </td>
                                                    <td *ngIf="!item.IsStationPOR && item.stationid==0" style="background: gainsboro;">
                                                        <input type="text" value="NA" disabled="disabled" style="border: none;" /></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </fieldset>
                                    
                                </div>
                            </div>
                            <!-- <div class="button-row text-right mt-4" style="margin:15px;">
                                <button *ngIf="!isEditable && isProductVariant1Editable" 
                                    class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next btnspc"
                                    type="button" title="Edit Matrix" (click)="EditMatrix()"
                                    [disabled]="isEditable">Edit
                                    Matrix</button>
                                <button *ngIf="isEditable"
                                    class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next btnspc"
                                    type="button" title="Save Matrix" (click)="SaveMatrix()"
                                    [disabled]="!isEditable">Save </button>
                                <a href="javascript:history.back()"
                                    class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                            </div> -->
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">

                                            <button *ngIf="isEditable" [hidden]="!isProductMatrixEditable"
                                                class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next btnspc"
                                                type="button" title="Edit Matrix" (click)="EditMatrix()">Edit
                                                </button>
                                                <button type="submit" id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <!-- <button *ngIf="!isEditable" [disabled]="!AlldataValid"
                                                class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next btnspc"
                                                type="button" title="Save Matrix" (click)="SaveMatrix()">Save </button> -->
                                            <a (click)="Cancel()" routerLinkActive="m-menu__item--active" style="color: white;"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </ng-container>
                        <ng-container *ngIf="!ShowGrid">
                            <div class="m-portlet__body offset-3">
                                <p
                                    style="font-size: 20px;font-weight: bold;font-family: 'Courier New', Courier, monospace;">
                                    Please select product series and product model!</p>
                            </div>

                        </ng-container>
                        <ng-container  *ngIf="ShowGrid">
                            <div class="m-portlet__body" style="text-align: center;" *ngIf="ProductMatrix.stationlist.length == 0 || ProductMatrix.stationlist.length == null || ProductMatrix.stationlist == []">
                                <p
                                    style="font-size: 20px;font-weight: bold;font-family: 'Courier New', Courier, monospace;">
                                    No records found!</p>
                            </div>

                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>