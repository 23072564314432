<div
  class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body">
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="m-portlet">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">My Activities</h3>
                </div>
              </div>
              <div class="m-portlet__head-tools">
                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <a class="btn btn-primary m-btn m-btn--air m-btn--custom" routerLink="/My-Activities">
                      List View
                    </a>
                  </li>
                </ul>
                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <a class="btn btn-primary m-btn m-btn--air m-btn--custom" routerLink="/Add-Activity">
                      Add Activity
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mat-elevation-z8">
              <div class="row">
                <div class="col-sm-12">
                  <div class="m-portlet">
                    <div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="m-portlet box">
                            <div style="padding-left: 17px; padding-right: 15px;  margin-top: 17px">
                              <form #addActivityForm="ngForm" (ngSubmit)="onSubmit(filterActivityForm)">
                                <div class="form-group m-form__group row">
                                  <div class="col">
                                    <label for="ActivityTypeId" class="lable-size">Activity Type:</label>
                                    <select class="form-control" id="ActivityTypeId" name="ActivityTypeId"
                                      [(ngModel)]="filterActivityForm.ActivityTypeId">
                                     <option [value]="null"  selected >select</option>
                                      <option *ngFor="let item of activityTypeMaster" [value]="item.ActivityTypeId">
                                        {{ item.ActivityTypeDesc }}
                                      </option>
                                    </select>
                                  </div>


                                  <div class="col">
                                    <label for="activity-status-filter" class="lable-size">Activity Status:</label>
                                    <select class="form-control" id="ActivityStatusId" name="ActivityStatusId"
                                      [(ngModel)]="filterActivityForm.ActivityStatusId" required>
                                      <option [value]="null"  selected >select</option>
                                      <option *ngFor="let item of activityStatusByTypeMaster" value="{{ item.Id }}"> {{ item.ActivityStatusDesc }}</option>
                                    </select>
                                  </div>

                                  <div class="col">
                                    <label for="priority-filter" class="lable-size">Subject:</label>
                                    <input class="form-control"  #Subject (keydown)="onKeyDown($event)" name="Subject" [(ngModel)]="filterActivityForm.Subject"
                                      placeholder="subject" type="text" />
                                  </div>

                                  <div *ngIf="canViewCustomerEngagementReportOfOthers" Class="col" >
                                    <label for="participant" class="lable-size">Participant:</label>
                                    <select name="participant" class="form-control" [(ngModel)]="filterActivityForm.ParticipantId" required>
                                      <option  [value]="null" selected>select</option>
                                      <option *ngFor="let p of allUserMaster" [value]="p.Id"> {{ p.Name }} </option>
                                    </select>
                                  </div>

                                  <div class="col">
                                    <label class="lable-size">Related To:</label>
                                    <select class="form-control" name="EntityTypeId"
                                      [(ngModel)]="filterActivityForm.EntityTypeId"
                                      (ngModelChange)="onEntityTypeChange(filterActivityForm.EntityTypeId)" >
                                      <option [value]="null" selected>select</option>
                                      <option *ngFor=" let s of allEntityTypesMaster " [value]="s.Id">
                                        {{ s.EntityType }}
                                      </option>
                                    </select>
                                  </div>

                                  <div class="col" style="margin-top: 25px;">


                                    <ng-select name="participants" [items]="entityTypeFilterArray" [searchable]="true" [multiple]="false" [closeOnSelect]="true"
                                    [placeholder]="'Select '" bindLabel="EntityName" bindValue="EntityId" [(ngModel)]="filterActivityForm.EntityId">
                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                      <div>{{ item.EntityName }}</div>
                                    </ng-template>
                                  </ng-select>


                                    <!-- <select class="form-control" name="EntityId"
                                      [(ngModel)]="filterActivityForm.EntityId" >
                                      <option value="null" selected>select</option>
                                      <option *ngFor=" let c of entityTypeFilterArray" [value]="c.EntityId">
                                        {{ c.EntityName }}
                                      </option>
                                    </select> -->


                                  </div>

                                  <div class="col">
                                    <br>
                                    <mat-checkbox id="isOwner" [(ngModel)]="filterActivityForm.IsOwner" name="isOwner">
                                      <label for="isOwner" class="lable-size">I am Owner</label>
                                    </mat-checkbox>
                                  </div>
                                  <div class="col">
                                    <br>
                                    <button
                                      class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next">Filter
                                    </button>
                                  </div>

                                  <div class="col">
                                    <br>
                                    <button type="button" (click)="clearFilter()"
                                      class="btn btn-metal m-btn m-btn--air m-btn--custom">Clear </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="row text-center">
                        <div class="col-md-4">
                          <div class="btn-group">
                            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                              (viewDateChange)="closeOpenMonthViewDay()">
                              Previous
                            </div>
                            <div class="btn btn-primary" mwlCalendarToday [(viewDate)]="viewDate"  (viewDateChange)="activityByViewTypeOfCalender()" (viewDateChange)="closeOpenMonthViewDay()" >
                              Today
                            </div>
                            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                              (viewDateChange)="closeOpenMonthViewDay()">
                              Next
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                        </div>
                        <div class="col-md-4">
                          <div class="btn-group">
                            <div class="btn btn-primary" (click)="setView(CalendarView.Month)">
                              Month
                            </div>
                            <div class="btn btn-primary" (click)="setView(CalendarView.Week)">
                              Week
                            </div>
                            <div class="btn btn-primary" (click)="setView(CalendarView.Day)">
                              Day
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>

                      <div [ngSwitch]="view">
                        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
                          [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                          (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
                          (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate">
                        </mwl-calendar-month-view>

                        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate"
                          [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                          (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-week-view>

                        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
                          [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                          (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-day-view>

                      </div>
                      <br />
                      <ng-template #customCellTemplate let-day="day" let-locale="locale" class="back-color">
                        <div class="cal-cell-top">
                          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                          <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                        </div>
                        <small style="margin: 5px" *ngIf="day.events.length > 0">There are {{ day.events.length }}
                          events on this day</small>
                      </ng-template>

                      <ng-template #modalContent let-close="close">
                        <div class="modal-header">
                          <h5 class="modal-title">Plan Details</h5>
                          <button type="button" class="close" (click)="close()">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <h1>Title : -{{ modalData?.event.title }} </h1>
                        <div class="modal-body">
                          <div>
                            Action:
                            <pre>{{ modalData?.action }}</pre>
                          </div>
                          <div>
                            Event:
                            <pre>{{ modalData?.event | json }}</pre>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-secondary" (click)="close()">
                            OK
                          </button>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<loader [showLoader]="showLoader"></loader>
