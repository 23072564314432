import { Component, OnInit, Input } from '@angular/core';
import { EntityRecord } from 'src/app/Model/ActivityModel';
import { ActivityService } from 'src/app/Service/activity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-activity',
  templateUrl: './view-activity.component.html',
  styleUrls: ['./view-activity.component.scss']
})
export class ViewActivityComponent implements OnInit {
  canEditCustomerEngagementReportOfOthers: any;
  canViewCustomerEngagementReportOfOthers: any;

  constructor(
    private location: Location,
    private router: Router,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal) { }

  // relatedEntityRecords: EntityRecord[] = [];
  frmData: any = [];
  ActivityId: number;

  @Input() Activity;
  @Input() showLoader: boolean = false;
  @Input() showEditAndCancelBtn = true;
  inPopUpMode: boolean;

  ngOnInit() {
    this.inPopUpMode = this.Activity?.inPopUpMode ? this.Activity?.inPopUpMode : false;
    this.canEditCustomerEngagementReportOfOthers = this.Activity?.canEditCustomerEngagementReportOfOthers;
    this.canViewCustomerEngagementReportOfOthers = this.Activity?.canViewCustomerEngagementReportOfOthers;
    this.showLoader =true;
    this.ActivityId = this.Activity?.activityId ? this.Activity?.activityId : this.route.snapshot.params["id"];
    this.getActivityById(this.ActivityId);
    this.showLoader =false;
  }

  getActivityById(id: number) {
    this.showLoader = true;
    this.activityService.getActivityById(id).subscribe(data => {
      this.frmData = data;     
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to Load activity details!");
      this.showLoader = false;
    });
  }

  goToEdit(ActivityId) {
    this.router.navigate(['/Edit-Activity', ActivityId]);
    this.activeModal.close();
  }

  goBack() {
    this.location.back();
  }

  closePopup() {
    this.activeModal.close('Close Click');
  }

  
  checkAccess()
  {
      const participantsIdArray = this.frmData.ParticipantsID.split(',');
      console.log(localStorage.getItem('UserID'));
      
      if((participantsIdArray.filter(x=>x==localStorage.getItem('UserID')).length>0 ||this.frmData.ActivityOwner==localStorage.getItem('UserID')))
      {
        this.frmData.canEditCustomerEngagementReportOfOthers=true;
        this.frmData.canViewCustomerEngagementReportOfOthers=true;
      }
      else
      {
        this.frmData.canEditCustomerEngagementReportOfOthers=this.canEditCustomerEngagementReportOfOthers;
        this.frmData.canViewCustomerEngagementReportOfOthers=this.canViewCustomerEngagementReportOfOthers;
      }     
  }
}
