

<div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform4"
        #myform4="ngForm" appFocusInvalidInput novalidate>
        <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
            <div class="multisteps-form__content">
                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <div
                                    *ngIf="!(AppGlobals.ManagementApproval_Order_RejectBySalesDirector ||
                                                    AppGlobals.ManagementApproval_Order_ApproveBySalesDirector ||
                                                    AppGlobals.ManagementApproval_SendOrderforApprovalBySalesDirector) && AppGlobals.isManagementApprovalEditable">
                                    <h3 class="m-portlet__head-text">
                                        Management Approval - Quotation
                                    </h3>
                                </div>
                                <div
                                    *ngIf="(AppGlobals.ManagementApproval_Order_RejectBySalesDirector ||
                                                    AppGlobals.ManagementApproval_Order_ApproveBySalesDirector ||
                                                    AppGlobals.ManagementApproval_SendOrderforApprovalBySalesDirector) && AppGlobals.isManagementApprovalEditable">
                                    <h3 class="m-portlet__head-text">
                                        Management Approval - Order
                                    </h3>
                                </div>

                                <div *ngIf="!AppGlobals.isManagementApprovalEditable">
                                    <h3 class="m-portlet__head-text">
                                        Management Approval
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="m-portlet__head-tools">
                            <button *ngIf="this.UserRole == roleOfAdmin"
                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                (click)="SendBack(myform4)">Send Back</button>
                        </div>
                        <!-- <div class="m-portlet__head-caption" >
                                            <span *ngFor="let item of workflowActionList;let i = index;">
                                                <button class="btn btn-primary ml-auto js-btn-next btnspc" type="{{item.workflowActionType}}"
                                                    (click)="OnWorkflowAction(myform4,item.workflowActionId)">
                                                    {{item.workflowActionName}}
                                                </button> &nbsp; &nbsp;
                                            </span>
                                        </div> -->
                    </div>


                    <div class="markedFields">
                        <span style="margin-left:10px;">* Marked fields are
                            mandatory</span>
                    </div>
                    <div class="m-portlet__body">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Customer
                                Name:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.CustomerName}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Segment:</label>
                            <div class="col-lg-4" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.IndustrySegment}}
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Type of
                                Customer:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.CustomerType}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Region:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.Region}}
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Terms and
                                Conditions:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnquiryDetails.RFQTermsAndCondition}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Quotation
                                Amount:</label>
                            <div class="col-lg-4" style="padding-top:9px;">
                                ₹
                                {{EnquiryObj.EnquiryDetails.FinalAmount |
                                indianCurrency}}/-
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Contact
                                Person:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnquiryDetails.KindAttention}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">
                                BOM (Bill Of Materials):</label>
                            <div class="col-lg-3 pt-2 filenamemobile">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.BOM_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.BOM_filename}}</a>
                            </div>
                        </div>
                        <div class="offset-1"></div>
                        <div *ngIf="!(EnquiryObj.EnquiryDetails.PO_filename == null ||
                                        EnquiryObj.EnquiryDetails.PO_filename == undefined||
                                        EnquiryObj.EnquiryDetails.PO_filename == '')"
                            class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">PO
                                Value:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                ₹ {{EnquiryObj.EnquiryDetails.POValue |
                                indianCurrency}}/-
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">PO
                                Attachment:</label>
                            <div class="col-lg-3 pt-2 filenamemobile">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.PO_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.PO_filename}}</a>
                            </div>

                        </div>
                        <div class="form-group m-form__group row">

                            <label class="col-lg-2 col-form-label">View
                                Quotation:</label>
                            <div class="col-lg-3 pt-2 filenamemobile" *ngIf="!showPreviewLink">
                                <a target="_blank"
                                    href="{{QuotationDownloadPath}}{{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}">
                                    {{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}
                                </a>
                            </div>
                            <div class="col-lg-3 pt-2 filenamemobile" *ngIf="showPreviewLink">
                                <a style="cursor: pointer;color: blue;" (click)="OpenPreview()">Click to
                                    preview quotation</a>
                            </div>
                            <div class="offset-1"></div>
                            <label *ngIf="AppGlobals.isManagementApprovalEditable"
                                class="col-lg-2 col-form-label">Attachment(s):</label>
                            <div *ngIf="AppGlobals.isManagementApprovalEditable" class="col-lg-4 pt-1">
                                <div class="file btn btn-sm btn-warning">
                                    Upload
                                    <input type="file" class="form-control-file" id="mafile"
                                        name="mafile" #myInput (change)="getMultipleFileDetails($event)"
                                        multiple />
                                </div>
                                <br /><span class="fileext">
                                    {{AppGlobals.supportedFileFormatsForOtherMultipleAttachments}}</span>
                                <span
                                    *ngIf="EnquiryObj.EnquiryDetails.QuoteGenAttachments != undefined && EnquiryObj.EnquiryDetails.QuoteGenAttachments.length > 0">
                                    <ul>
                                        <ng-container
                                            *ngFor="let item of EnquiryObj.EnquiryDetails.QuoteGenAttachments;let i=index;">
                                            <li class="filenamemobile">
                                                {{item.name}}&nbsp;&nbsp;<i class="flaticon-delete"
                                                    style="color:#f4516c"
                                                    (click)="removeQuoteGenAttachments(i)"
                                                    title="Remove"></i></li>
                                        </ng-container>
                                    </ul>

                                </span>
                            </div>
                        </div>


                        <div class="form-group m-form__group row">

                            <label class="col-lg-2 col-form-label">Comments:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" id="macomments" name="macomments"
                                    rows="3" maxlength="1500"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                    (keyup)="limitTextOnKeyUpDown1($event)"
                                    (keypress)="limitTextOnKeyUpDown1($event)" #macomments="ngModel"
                                    [ngClass]="{ 'is-invalid': (myform4.submitted && macomments.invalid) || (macomments?.touched && macomments.invalid) }"
                                    required>
                                                </textarea>
                                <span> Limit {{maxLimit}} characters,
                                    {{remLimit}} characters
                                    remaining.</span>
                                <div *ngIf="(myform4.submitted && macomments.invalid) || (macomments?.touched && macomments.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="macomments.errors.required">
                                        Comment is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="button-row text-right mt-4" style="margin:15px;">
                    <div>
                        <div class="m-portlet__head-caption">
                            <span *ngFor="let item of workflowActionList;let i = index;">
                                <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                    type="{{item.workflowActionType}}"
                                    (click)="OnWorkflowAction(myform4,item.workflowActionId)">
                                    {{item.workflowActionName}}
                                </button> &nbsp; &nbsp;
                            </span>
                            <!-- <button *ngIf = "this.UserRole == roleOfAdmin"
                                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                            (click)="SendBack(myform4)">Send Back</button> &nbsp; &nbsp; -->
                            <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                                (click)="backtoList()" routerLinkActive="active">Cancel</button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </form>
</div>


