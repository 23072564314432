import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { BasicCustomerInformationModel } from 'src/app/Model/CustomerModel';
import { EnquiryModel, CustomerDrpdnDetails, CustomerDetails, ProductDrpdnDetails, EnquiryDetailsModel, ProductDetails } from 'src/app/Model/EnquiryModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { EnquiryStatus } from "src/app/EnquiryStatus";
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-enquiry',
  templateUrl: './add-enquiry.component.html',
  styleUrls: ['./add-enquiry.component.scss']
})
export class AddEnquiryComponent implements OnInit {

  userInfo: EmployeeGuestModel = new EmployeeGuestModel();
  CustomerList: BasicCustomerInformationModel[] = [];
  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  newRFQDetails: any = {};
  customerDrpdnDetails: CustomerDrpdnDetails = new CustomerDrpdnDetails();
  productDrpdnDetails: ProductDrpdnDetails = new ProductDrpdnDetails();
  customerData: CustomerDetails;
  allowedFileType: string[] = ['image/bmp', 'image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'text/csv', 'application/xml', 'text/xml', 'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip', 'application/vnd.rar'];

  @Input()
  showLoader: boolean = false;
  charCode: number;
  enquiryStatus = EnquiryStatus;
  enquirySource: SingleFieldModel[] = [];
  product: any = {};
  isEnquirySubmit: boolean = false;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  minDate: NgbDate;
  maxDate: NgbDate;
  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private router: Router, private titleService: Title, private route: ActivatedRoute) {
      this.minDate = new NgbDate(2018, 1, 1);
    this.maxDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  }

  ngOnInit() {
    this.commonService.RefreshHeaderFun();
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.getUserProfileInfo();
    this.getCustomerList();
    this.getEnquirySourceDetails();
  }
  getCustomerList() {
    this.showLoader = true;
    this.commonService.GetData("Customer", "GetAll", null).subscribe((data: any) => {
      this.CustomerList = data;
      this.showLoader = false;
    });
  }
  getEnquirySourceDetails() {
    this.showLoader = true;
    this.commonService.GetData("Enquiry", "GetEnquiryDropdownData", null).subscribe((data: any) => {
      this.enquirySource = data.EnquirySource;
      this.showLoader = false;
    });
  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }
  GetCustomerDetails(data: any) {
    if (this.customerData == null) {
      this.EnquiryObj.CustomerDetails.CustomerCode = '';
      this.customerDrpdnDetails.IndustrySegment = '';
      this.customerDrpdnDetails.CustomerType = '';
      this.customerDrpdnDetails.CustomerCategory = '';
      this.customerDrpdnDetails.Region = '';
      this.customerDrpdnDetails.CustomerClassification = '';
      return;
    }
    this.showLoader = true;
    this.EnquiryObj.CustomerDetails = new CustomerDetails();
    let queryString = "";
    queryString = "id=" + this.customerData;
    this.commonService.GetData("Customer", "GetById", queryString).subscribe((result: any) => {
      let res = result;
      this.commonService.GetData("Customer", "GetCustomerDropdownData", queryString).subscribe((data: any) => {
        this.customerDrpdnDetails = data;
        this.EnquiryObj.CustomerDetails.CustomerId = res.BasicCustomerInformationModel.Id;
        this.EnquiryObj.CustomerDetails.CustomerCode = res.BasicCustomerInformationModel.CustomerCode;
        this.EnquiryObj.CustomerDetails.RegionId= this.customerDrpdnDetails.RegionId;
        this.showLoader = false;
      });
    });
  }
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }
  getUserProfileInfo() {
    this.showLoader = true;
    this.commonService.GetUserProfileData('Account', 'GetUserProfileInfo').subscribe((data: any) => {
      this.userInfo = data;
      this.EnquiryObj.EnquiryDetails.UserId = this.userInfo.Id;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  SetStatus(status) {    
    this.EnquiryObj.EnquiryDetails.Status = status;
  }

  AddEnquiry() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this enquiry?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        const frmData = new FormData();
        this.EnquiryObj.RFQObject.forEach(item => {
          item.IsRowDeleted = false;
        });
        // if (this.newRFQDetails != {}) {
        //   this.isEnquirySubmit = true;
        //   this.AddRFQDetails();
        // }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day)));
        }
        let EnquiryObj = JSON.stringify(this.EnquiryObj);
        frmData.append("EnquiryModel", EnquiryObj);

        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length; i++) {
          frmData.append("QuoteGenAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.QuoteGenAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.MngOrderApprovalAttachments.length; i++) {
          frmData.append("MngOrderApprovalAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.MngOrderApprovalAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.MngApprovalAttachments.length; i++) {
          frmData.append("MngApprovalAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.MngApprovalAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.EnquiryDetails.CEOApprovalAttachments.length; i++) {
          frmData.append("CEOApprovalAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.CEOApprovalAttachments[i]);
        }
        for (let i = 0; i < this.EnquiryObj.RFQObject.length; i++) {
          if (this.EnquiryObj.RFQObject[i].MDSAttachment != undefined) {
            frmData.append("MDSAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].MDSAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].ITCAttachment != undefined) {
            frmData.append("ITCAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].ITCAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].CustomerAttachment != undefined) {
            frmData.append("CustomerAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].CustomerAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].WeissAttachment != undefined) {
            frmData.append("WeissAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].WeissAttachment[0]);
          }
        }
        frmData.append("BOMAttachment", this.EnquiryObj.EnquiryDetails.BOMAttachment[0]);
        frmData.append("POAttchments", this.EnquiryObj.EnquiryDetails.POAttchments[0]);

        this.commonService.PostData("Enquiry", "Add", frmData, null).subscribe((data: any) => {
          this.customerDrpdnDetails = data;
          this.EnquiryObj.EnquiryDetails.EnquiryId = data;
          this.toastr.success("Enquiry details added successfully!");
          this.showLoader = false;
          //this.router.navigate(['/Enquiry']);
          this.router.navigate(['/EditViewEnquiry/' + this.EnquiryObj.EnquiryDetails.EnquiryId]);
        }, error => {
          this.toastr.error("Failed to add enquiry details!");
          this.showLoader = false;
          //this.router.navigate(['/Enquiry']);
        });
      }
    });
  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }
}
