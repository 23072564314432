import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SingleFieldModel } from './SingleFieldModel';
import { string } from '@amcharts/amcharts4/core';

export class QuotationDetailsModel {
    QuotationInfo: QuotationInfo = new QuotationInfo();
    QuotationObj: ProductDetailsModel[] = Array<ProductDetailsModel>();
    allseriesmodel:any[];
}

export class QuotationInfo {
    EnquiryId: any;
    QuotationNumber: any;
    QuotationDate:any;
    EnquiryStatus: any;
    KindAttention:any;
    KindAttEmailId:string = "";
    KindAttContactNo:string = "";
    PerUnitAmount :any = 0;
    TotalAmount :any = 0;
    Discount :any = 0;
    FinalAmount :any = 0;
    YourReference:any;
    ReferenceDate:any;
    ReferenceDateISO:any;
    IsPriceOnRequest:boolean = false;
    InitiatorName :string ="";
    InitiatorContactNo :string ="";
    FinalDiscountres:any=0;
    QuotationComments:string = "";
    DiscountforOneOrAll: string = "";
    SalesContactPerson:string="";
    ContactNumber:string="";
    SalesContactPersonName:string="";
}

export class ProductDetailsModel {
    
    ProductId: any;
    ProductFamily: any;
    ProductSeries: any = undefined;
    ProductModel: any = undefined;
    ProductModelPrice: any = 0;
    Variant1Label: any;
    Variant1: any;
    Variant1Price: any = 0;
    Variant2Label: any;
    Variant2: any;
    Variant2Price: any = 0;
    Variant3Label: any;
    Variant3: any;
    Variant3Price: any = 0;
    Variant4Label: any;
    Variant4: any;
    Variant4Price: any = 0;
    Options: OptionsModel[] = new Array<OptionsModel>();
    OptionSelected:any[]=[];
    OptionsPrice:any = 0;


    //for varient and option  discount fields
    Varient1_Discount:any =0;
    Varient2_Discount:any =0;
    Varient3_Discount:any =0;
    Varient4_Discount:any =0;
    Model_Discount:any=0;
    Varient1_FinalPrice:any=0;
    Varient2_FinalPrice:any=0;
    Varient3_FinalPrice:any=0;
    Varient4_FinalPrice:any=0;
    Model_FinalPrice:any=0;
    
    Option_Discount:any=0;
    Option_FinalDiscount:any=0;

    TotalAmountBySpecialDiscount:any=0;

    Special_pkg_Discount:any=0;

    //for check box functionality

    showVariantOptionPrice:boolean=false;
    showVariantOptionDiscount:boolean=false;
    showSpecialPrice:boolean=false;

    Quantity :any = 1;
    PerUnitAmount :any=0;
    TotalforRfqdiscount:any=0;
    TotalforIndividualdiscount:any=0;
    TotalAfterDiscount:any=0;
    TotalAmount: any = 0;
    Discount: any = 0;
    
    FinalAmount: any = 0;
    RevisionNo: any;
    SubmittedDate: Date;
    showTechnicalSpecifications:boolean = false;
    TechnicalSpecifications:TechnicalSpecifications[] = new Array<TechnicalSpecifications>();
    separatedTechSpecs:any[]=[];

    IsPriceOnRequest_Variant1:boolean; 
    IsPriceOnRequest_Variant2:boolean;
    IsPriceOnRequest_Variant3:boolean;
    IsPriceOnRequest_Variant4:boolean;
    IsUnits_Variant1:boolean; 
    IsUnits_Variant2:boolean;
    IsUnits_Variant3:boolean;
    IsUnits_Variant4:boolean;
    Units_Variant1:any = 1; 
    Units_Variant2:any = 1;
    Units_Variant3:any = 1;
    Units_Variant4:any = 1;

    IsLevel2DepOnLevel1: boolean;
    IsLevel3DepOnLevel2: boolean;
    IsLevel4DepOnLevel3: boolean;

    colspan1:any;
    colspan2:any;
    colspan3:any;
    colspan4:any;

    //validationVariables
    ProductFamilyValidation:boolean = false;
    ProductSeriesValidation:boolean = false;
    ProductModelValidation:boolean = false;
    ProductModelPriceValidation:boolean = false;
    Variant1Validation:boolean = false;
    Variant1PriceValidation:boolean = false;
    Variant2Validation:boolean = false;
    Variant2PriceValidation:boolean = false;
    Variant3Validation:boolean = false;
    Variant3PriceValidation:boolean = false;
    Variant4Validation:boolean = false;
    Variant4PriceValidation:boolean = false;
    OptionsValidation:boolean = false;
    OptionsPriceValidation:boolean = false;
    QuantityValidation:boolean = false;
    QuantityRangeValidation:boolean = false;
    DiscountValidation:boolean = false;
   
    var1_POR_Validation:boolean = false;
    var2_POR_Validation:boolean = false;
    var3_POR_Validation:boolean = false;
    var4_POR_Validation:boolean = false;

    var1_Unit_Validation:boolean = false;
    var2_Unit_Validation:boolean = false;
    var3_Unit_Validation:boolean = false;
    var4_Unit_Validation:boolean = false;

    productmodeldata:any;
    selectedProductSeries:any[]=[];
    selectedProductModel:any[]=[];
    selectedVariant1:any[]=[];
    selectedVariant2:any[]=[];
    selectedVariant3:any[]=[];
    selectedVariant4:any[]=[];
    selectedOptions:any[]=[];

    IsPriceOnRequest_Option:boolean;
    Options_POR_Validation:boolean = false;
    ShowOptionPriceOnRequestGrid:boolean = false;


    ///Array for checkbox
    checkboxactive: any[] = [1,2,3];
}

export class TechnicalSpecifications {
    Id:Number;
    ProductId:Number;
    value:string;
    uniqueid:string;
}

export class OptionsModel {
    ProductOptionId :any; //Quotation table primary key
    OptionId :any; // Options table primary key
    Name:any;
    Price:any;
    IsPriceOnRequest: boolean = false;
    optionValid: boolean = false;
    Option_Discount:any=0;
    Option_FinalDiscount:any=0;
}

export class QuotationCustomerDetails{
    CustomerId:any;
    CustomerName:string = "";
    City:string="";
    State:string="";
    KindAttention:string="";
    YourReference:any;
    ReferenceDate:any;
}

export class ProductDrpdnDetails {
    ProductFamily: any[] = [];
    ProductSeries: any[] = [];
    ProductModel: any[] = [];
    Variant1: any[] = [];
    Variant2: any[] = [];
    Variant3: any[] = [];
    Variant4: any[] = [];
    Options: any[] = [];
    IsLevel2DepOnLevel1: boolean;
    IsLevel3DepOnLevel2: boolean;
    IsLevel4DepOnLevel3: boolean;

}


export class QuotationProductLineViewModel {
    public ProductLine: any;
    ProductModels: QuotationProductViewModel[] = Array<QuotationProductViewModel>();
}
export class QuotationProductViewModel {
    public Id: any;
    public Name: any;
    public Price: any;
    Variant1: VariantViewModel[] = Array<VariantViewModel>();
    Variant2: VariantViewModel[] = Array<VariantViewModel>();
    Variant3: VariantViewModel[] = Array<VariantViewModel>();
    Variant4: VariantViewModel[] = Array<VariantViewModel>();
}
export class VariantViewModel {
    public Id: any;
    public Name: any;
    public Price: any;
    public LabelName: any;
    public IsPriceOnRequest: any;
    public IsUnits: any;
}
