<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add Customer Opportunity
                                    </h3>
                                </div>
                            </div>

                        </div>
                        
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid &&onSubmit(myform)" novalidate>
                            <div class="m-portlet__body p-5">
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer Name:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="customername"
                                                    id="customername"
                                                    [(ngModel)]="AddCustomerOpportunityModel.CustomerName"
                                                    #customername="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customername.invalid || customername?.touched && customername.invalid}"
                                                    placeholder="Enter Customer Name" (keypress)="AvoidSpace($event)"
                                                    maxlength="400"
                                                    (change)="AddCustomerOpportunityModel.CustomerName=AddCustomerOpportunityModel.CustomerName.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && customername.invalid || customername?.touched && customername.invalid">
                                                    <div *ngIf="myform.hasError('required', 'customername')">
                                                        Customer Name is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Customer Address:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="customeraddress"
                                                    id="customeraddress"
                                                    [(ngModel)]="AddCustomerOpportunityModel.CustomerAddress"
                                                    #customeraddress="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customername.invalid || customername?.touched && customername.invalid}"
                                                    placeholder="Enter Customer Address" (keypress)="AvoidSpace($event)"
                                                    maxlength="400"
                                                    (change)="AddCustomerOpportunityModel.CustomerAddress=AddCustomerOpportunityModel.CustomerAddress.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && customeraddress.invalid || customeraddress?.touched && customeraddress.invalid">
                                                    <div *ngIf="myform.hasError('required', 'customeraddress')">
                                                        Customer Address is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Key Person Name:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="keypersonname"
                                                    id="keypersonname"
                                                    [(ngModel)]="AddCustomerOpportunityModel.KeyPersonName"
                                                    #keypersonname="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keypersonname.invalid || keypersonname?.touched && keypersonname.invalid}"
                                                    placeholder="Enter KeyPerson Name" (keypress)="AvoidSpace($event)"
                                                    maxlength="400"
                                                    (change)="AddCustomerOpportunityModel.KeyPersonName=AddCustomerOpportunityModel.KeyPersonName.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && keypersonname.invalid || keypersonname?.touched && keypersonname.invalid">
                                                    <div *ngIf="myform.hasError('required', 'keypersonname')">
                                                        KeyPerson Name is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Industry Segment:</label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="industrysegment"
                                                    name="industrysegment"
                                                    [(ngModel)]="AddCustomerOpportunityModel.IndustrySegment"
                                                    #industrysegment="ngModel">
                                                    <option value="null">Select Industry Segment</option>
                                                    <option *ngFor="let item of IndustrySegment" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="IndustrySegment.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Customer Type:</label>
                                            <div class="col-lg-3">
                                                <select class="form-control" id="customertype"
                                                name="customertype"
                                                [(ngModel)]="AddCustomerOpportunityModel.CustomerType"
                                                #customertype="ngModel">
                                                <option value="null">Select Type of customer</option>
                                                    <option *ngFor="let item of Typeofcustomer" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="Typeofcustomer.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Region:</label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="region"
                                                    name="region"
                                                    [(ngModel)]="AddCustomerOpportunityModel.Region"
                                                    #region="ngModel">
                                                    <option value="null">Select Region</option>
                                                    <option *ngFor="let item of Region" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="Region.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Opportunity Date:</label>
                                            <div class="col-lg-3">
                                                <div class="input-group date">
                                                    <input readonly class="form-control"
                                                        placeholder="DD-MMM-YYYY" id="opportunitydate"
                                                        [minDate]="minDate"
                                                        [maxDate]="maxDate"
                                                        name="opportunitydate" ngbDatepicker
                                                        #ed="ngbDatepicker"
                                                        [(ngModel)]="AddCustomerOpportunityModel.OpportunityDate"
                                                        #enquirydate="ngModel" (click)="ed.toggle()">
                                                    <div class="input-group-append">
                                                        <span (click)="ed.toggle()"
                                                            class="input-group-text">
                                                            <i class="la la-calendar-check-o"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Description:</label>
                                            <div class="col-lg-3">
                                                <textarea class="form-control m-input"
                                                    id="description" maxlength="1500"
                                                    name="description" rows="3"
                                                    [(ngModel)]="AddCustomerOpportunityModel.Description"
                                                    (keyup)="limitTextOnKeyUpDown1($event)"
                                                    (keypress)="limitTextOnKeyUpDown1($event)"
                                                    #description="ngModel"></textarea>
                                                <span> Limit {{maxLimit}} characters, {{remLimit}}
                                                    characters remaining.</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save And Close</button>&nbsp;&nbsp;
                                                <button id="m_login_signin_submit"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Convert to enquiry</button>&nbsp;&nbsp;
                                            <a href="javascript:history.back()"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>