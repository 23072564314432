import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AddCustomerOpportunityModel } from 'src/app/Model/AddCustomerOpportunityModel';
import { EnquiryDetailsModel } from 'src/app/Model/EnquiryModel';
import { LeadModel } from 'src/app/Model/LeadModel';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {

  AddCustomerOpportunityModel = new AddCustomerOpportunityModel();
  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  Country: any[] = [];
  Region: any[] = [];
  IndustrySegment: any[] = [];
  // Typeofcustomer: any[] = [];
  enquirySource: SingleFieldModel[] = [];
  CustomerType: any[] = [];
  showLoader: boolean = false;
  minDate: NgbDate;
  maxDate: NgbDate;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;

  LeadObj: LeadModel = new LeadModel();
  Id: any;
  @Input()
  EnquiryngbDateToFiter: NgbDate | null;
  EnquiryngbDateFromFiter: NgbDate | null;

  constructor(private router: Router,
    private toastr: ToastrService,
    private Service: CommonService,
    public dialog: MatDialog,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private CommonService: CommonService) {
    this.minDate = new NgbDate(2018, 1, 1);
    this.maxDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  }

  ngOnInit(): void {
    this.GetCustomerDropdownData();
    this.getEnquirySourceDetails();
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }
  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to add this Lead?"
      }

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        const frmData = new FormData();

        if (this.LeadObj.LeadDate != undefined) {
          this.LeadObj.LeadDate = new Date(this.convertDate(new NgbDate(this.LeadObj.LeadDate.year, this.LeadObj.LeadDate.month, this.LeadObj.LeadDate.day))).toDateString();
        }

        let LeadDatails = JSON.stringify(this.LeadObj);
        frmData.append("LeadModel", LeadDatails);
        this.CommonService.PostData('Lead', 'Add', frmData, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Lead added successfully!');
            this.CommonService.RefreshHeaderFun();
            this.router.navigate(['/Lead']);
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add Lead!');
            this.router.navigate(['/Lead']);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add Lead!');
          this.showLoader = false;
          this.router.navigate(['/Lead']);
        });
      }


    });
  }
  GetCustomerDropdownData() {
    this.showLoader = true;
    this.Service.GetData("Customer", "GetCustomerDropdownDetails", null).subscribe((data: any) => {
      //this.Country = data.Country;
      this.Region = data.Region;
      this.IndustrySegment = data.IndustrySegment;
      this.CustomerType = data.CustomerType;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null { const parsed = this.formatter.parse(input); return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue; }

  getEnquirySourceDetails() {
    this.showLoader = true;
    this.CommonService.GetData("Enquiry", "GetEnquiryDropdownData", null).subscribe((data: any) => {
      this.enquirySource = data.EnquirySource;
      this.showLoader = false;
    });
  }
}
