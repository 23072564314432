import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { EmployeeGuestModel } from '../Model/EmployeeGuestModel';
import { CommonService } from '../Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ProductSeries } from '../Model/ProductModel';
import { UserRoles } from "../UserRoles";
import { AppGlobals } from '../app.global';
import { DynamicBGImageComponent } from '../dynamic-bgimage/dynamic-bgimage.component';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessPermissionModel } from '../Model/WorkFlowScreenAccessModel';
import { HeaderMenuModel, Menuelement } from '../Model/HeaderMenuModel';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  AppRoles = UserRoles;
  profileObj: EmployeeGuestModel = new EmployeeGuestModel();
  SeriesSubmenu: ProductSeries[] = [];
  role: any;
  showOnlyProductMaster: boolean;

  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
  menuaccessinfo:Menuelement[]=Array<Menuelement>();
  map = new Map<string, boolean>();

  isProductLine: boolean = false;
  isCustomer: boolean = false;
  isGuest: boolean = false;
  isEmployee: boolean = false;
  isConfigure: boolean = false;
  isBusinessPlan: boolean = false;
  isStockInventory: boolean = false;
  isWorkFlowScreenAccess: boolean = false;
  isAlerts: boolean = false;
  isOpenProductLineMaster: boolean;
  closeResult: string;
  isPriceConfigurator: boolean = false;
  isSiteActivity: boolean = false;
  isLeadEditable: boolean=false;

  constructor(private router: Router, private location: Location, private commonService: CommonService,
    private toastr: ToastrService, private eRef: ElementRef,public dialog: MatDialog,private modalService: NgbModal) { }

  ngOnInit() {

    this.showLoader = true;
    this.role = localStorage.getItem("UserRole");
    this.commonService.RefreshHeaderFun();
    this.VisibleMasters();
    

    if (this.role == this.AppRoles.Admin || this.role == this.AppRoles.SalesDirector || this.role == this.AppRoles.CEO) {
      this.showOnlyProductMaster = false;
    }
    if (!(this.role == this.AppRoles.Admin || this.role == this.AppRoles.SalesDirector || this.role == this.AppRoles.CEO)) {
      this.showOnlyProductMaster = true;
    }
    document.getElementById("enquiryDiv").style.display = "none";
    document.getElementById("manageMasterDiv").style.display = "none";
    document.getElementById("reportDiv").style.display = "none";

    if (this.commonService.subsVar == undefined) {
      this.commonService.subsVar = this.commonService.
        RefreshHeader.subscribe((name: string) => {
          this.getUserProfileInfo();
          this.GetProductSeriesWithLabels();
          
        });
      
    }
  }

  OpenProductLineMaster() {
    this.isOpenProductLineMaster = !this.isOpenProductLineMaster;
  }

  VisibleMasters() {
    this.showLoader = true;
    this.commonService.GetAllAccesspermission().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.AccesspermissionForRole = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.role);
     
      this.AccesspermissionForRole.forEach(ele=>
        {
          var obj = new HeaderMenuModel();
          obj.ElementKey=ele.ElementKey;
          obj.ElementValue=ele.CanList;
          this.menuaccessinfo.push(obj);
        
        });
          this.showLoader = true;
          this.menuaccessinfo.forEach(element => {
          this.map.set(element.ElementKey,element.ElementValue);
        
        });
    });
    this.showLoader = false;
    // this.isProductLine = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isCustomer = AppGlobals.CustomerMastersEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isEmployee = AppGlobals.EmployeeMastersEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isGuest = AppGlobals.GuestMastersEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isConfigure = AppGlobals.ConfigureMastersEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isBusinessPlan = AppGlobals.BusinessPlanEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isStockInventory = AppGlobals.StockInventoryEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isWorkFlowScreenAccess = AppGlobals.WorkFlowScreenAccessEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isAlerts = AppGlobals.AlertsAccessEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isPriceConfigurator = AppGlobals.PriceConfiguratorEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
    // this.isSiteActivity = AppGlobals.SiteActivityEditableAccessArray.filter(x => x.role == this.role)[0]?.isVisible;
  }

  onLogoClick() {
    this.router.navigateByUrl('/Dashboard');
  }

  getUserProfileInfo() {
    this.showLoader = true;

    this.commonService.GetUserProfileData('Account', 'GetUserProfileInfo').subscribe((data: any) => {
      this.profileObj = data;
      localStorage.setItem("RegionID", data.RegionId);
    }, error => {
      this.toastr.error("Failed to load data!");

    });
    this.showLoader = false;
  }
 

  GetProductSeriesWithLabels() {
    this.showLoader = true;
    this.commonService.GetData('ProductSeries', 'GetAll', null).subscribe((data: any) => {

      this.SeriesSubmenu = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });
  }

  logout() {
    this.showLoader = true;
    this.commonService.PostData('Account', 'Logout', null, null).subscribe((data: any) => {
      if (data.ResponseCode == 1) {
        this.toastr.success("Logout successfully!");
        localStorage.removeItem('userToken');
        localStorage.clear();
        this.location.replaceState('Login');
        this.showLoader = false;
        window.location.reload();
      }
      else {
        this.toastr.error("Failed to logout!");
        this.showLoader = false;
      }
    }, error => {
      this.toastr.error("Failed to logout!!");
      this.showLoader = false;
    });
  }

  

  myFunction() {
    let x = document.getElementById("m_header_menu");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  myFunctions() {
    let x = document.getElementById("m_header_topbar");
    if (x.style.display === "table") {
      x.style.display = "none";
    } else {
      x.style.display = "table";
    }
  }


  openDialog() {
    
    const modalRef = this.modalService.open(DynamicBGImageComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

   
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
      }
    });
  }

  
  getValue(key: string): boolean {
    return this.map.get(key);
 }

  AccessPermission(key: string): boolean {
    return this.map.get(key);
 }
}
