import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { el } from 'date-fns/locale';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SingleFieldComponent, SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-edit-view-contract-frame-agreement',
  templateUrl: './edit-view-contract-frame-agreement.component.html',
  styleUrls: ['./edit-view-contract-frame-agreement.component.scss']
})
export class EditViewContractFrameAgreementComponent implements OnInit {
  @Input()
  showLoader: boolean = false;
  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  editFormValues: boolean = false;
  IsNotApproveRejectButton: boolean = false;
  editFormStatus: string = "";
  UserRole: any;
  isConfigureEditable: boolean = false;
  IndustrySegment: any[] = [];
  SubSegment: any[] = [];
  Roles: any[] = [];
  TermsAndConditions: any[] = [];
  Customer:any[] =[];
  CustomerNameDetails:any[] =[];
  isotheroute:boolean;
  closeResult: string;

  ProductModel:any[]=[];
  ProductModelDiscount:any[]=[];
  tempProductModel: any[] = [];
  ProductModelDiscountArr:SingleFieldModel[]=[];
  productModelrequired: boolean=false;
  workflowActionList:any[] = [];
  workflowActionListforApproval:any[] = [];
  workflowActions:any[] = [];
  WorkflowStatus:number;
  dataList: any[] = [];
  currentstateid:number;
  currentWorkFlowStatus:number;
  FK_Workflow_Status:any;
  CompanyName:any;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  data: any[] = [];
  displayedColumns: string[] = ['Action By', 'Action By Role', 'action_name', 'Action On', 'Comment']
  dataSource: MatTableDataSource<SingleFieldModel>;
  historydata: any;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
  isCustomerFrameAgreementEditable:boolean;
  action_id: number;
  charCode: any;


  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private router: Router, private route: ActivatedRoute, private titleService: Title, private modalService: NgbModal,private accessservice: AccessPermissionService) { }

  ngOnInit(): void {
    //this.AddNewRow(0);
   
    this.getAllTransitionrules();
    let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    this.UserRole = localStorage.getItem("UserRole");
    this.isConfigureEditable = AppGlobals.CustomerFrameAgreementWorkFlowEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.singleFieldObj.Id = this.route.snapshot.params["id"];
    
    this.getAccess();

    this.GetTransitionRuleForCurrentStateById(this.singleFieldObj.Id);
    this.getDetailsById(this.singleFieldObj.Id);
    this.getWorkFlowActionHistoryById(this.singleFieldObj.Id);
   // this.getaccesinfotoCancel(this.UserRole);
    this.getAllProductModel("ProductModel", "GetAllModel");
  }

  getWorkFlowActionHistoryById(CustId:number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + CustId;

    this.commonService.GetData('WorkflowActionHistory', 'GetById', queryString).subscribe((data: any) => {
     
     //this.data=data;
     this.historydata= data;
     
     
    }, error => {
      this.toastr.error("Failed to load data!");

    });
    this.showLoader = false;
  }

  GetTransitionRuleForCurrentStateById(customerid:number)
  { 
    let queryString = "";
     queryString="customerid="+customerid;
    this.commonService.GetData('CustomerSpecialDiscount','GetTransitionRuleForCurrentStateById',queryString).subscribe((data:any)=>
    {
      this.singleFieldObj.tranisitionrulesobj=data;
      this.workflowActionListforApproval.push(this.singleFieldObj.tranisitionrulesobj);
      this.GetAll(customerid);
    });
  }

  GetAll(customerid:number)
  {
    let queryString = "";
    queryString="customerid="+customerid;
    this.commonService.GetData('CustomerSpecialDiscount', 'GetAll', queryString).subscribe((data: any) => {
      this.dataList = data;
      this.showLoader = true;

     var record=this.dataList.filter(x=>x.FK_CustomerId==customerid);
     //this.commonService.GetData('')
     this.currentWorkFlowStatus=record[0]?.FK_Workflow_Status;

     var actionlist=this.singleFieldObj.tranisitionrulesobj.filter(x=>x.current_state_id==this.currentWorkFlowStatus&&x.action_by_role_id==this.UserRole);

        actionlist.forEach(ele=>
        {
           if(ele.condition_code!="DiscountSaveAsDraft")
          {
            if(ele.actionname!="Edit")
            {
              this.workflowActions.push(ele);
              
            }
          }
          
         this.showLoader=true;
        });

        this.getAllCustomer("Customer", "GetAll"); 
        this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  
  Update(myform: any) {

    this.productModelrequired=false;
    if (this.singleFieldComponent.ismultiplerecords) {
      if (this.singleFieldObj.customerSpecialDiscountInfo.length == 0) {
        this.singleFieldObj.customerSpecialDiscountInfo[0].ProductModelRequired = true;
        this.showLoader = false;
        return;
      }
      else {
        this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
          if (element.Fk_ProductModel == null || element.Fk_ProductModel == undefined || element.Fk_ProductModel == 0 ) {
            element.ProductModelRequired = true;
          }
          else {
            element.ProductModelRequired = false;
          }          
        });
      }
    }
    this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
      if (element.ProductModelRequired) {
        this.productModelrequired=true;
        this.showLoader = false;
        return;
      }
    });

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to update this Customer Frame Agreement ?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult) {
        this.showLoader = true;
        
          if(this.productModelrequired==false){
            let queryString = "";
            this.singleFieldObj.customerSpecialDiscountInfo.forEach(element=>
              {
                element.Fk_Customer=this.singleFieldObj.Id 
                element.Comment=this.singleFieldObj.Comment
              });    
              queryString = "id=" +this.WorkflowStatus;
             
              this.commonService.PostData('CustomerSpecialDiscount', 'Add', this.singleFieldObj.customerSpecialDiscountInfo, queryString,null).subscribe((data: any) => {
              let result = data;

            this.showLoader = false;
            //if(this.UserRole=="1"){
              if (result.ResponseCode == 1) {
                this.toastr.success('Customer Frame Agreement Updated successfully!');
                this.router.navigate(['/CustomerFrameAgreementDetails']);
               
              }
              else if (data.ResponseCode == 0) {
                // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
                this.toastr.warning('Customer Frame Agreement already exists!');
                this.showLoader = false;
              }
              else {
                this.toastr.error('Failed to Update Customer Frame Agreement!');
                this.router.navigate(['/CustomerFrameAgreementDetails']);
              }
           
          }, error => {
            this.toastr.error('Failed to Update Customer Frame Agreement!');
            this.showLoader = false;
            this.router.navigate(['/Dashboard']);
          });
          }
      }
    });

  }

  getDetailsById(Id: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + Id;

    this.commonService.GetData('CustomerSpecialDiscount', 'GetById', queryString).subscribe((data: any) => {
      //this.ProductModelDiscount = data;
     this.singleFieldObj.customerSpecialDiscountInfo=data;
     this.editFormValues=false;
     
    }, error => {
      this.toastr.error("Failed to load data!");

    });
    this.showLoader = false;
  }

  editForm() {
    if (this.editFormValues == false) {
      this.editFormValues = true;
      this.editFormStatus = "Edit";
      this.IsNotApproveRejectButton=true;
      this.titleService.setTitle(this.editFormStatus + " " + "Customer Frame Agreement"+ " - WEISS");
    }
    else {
      this.editFormValues = false;
      this.editFormStatus = "View";
      this.IsNotApproveRejectButton=false;
      this.titleService.setTitle(this.editFormStatus + " " + "Customer Frame Agreement"+ " - WEISS");
    }
  }

  OnDiscountChange(index)
  {
   
    if (this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount < 0) {
      this.toastr.info('Sales Discount cannot be negative.');
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount = null;
      return;
    }
    else if (this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount > 100) {
      this.toastr.info('Discount cannot be greater than 100.');
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductDiscount= null;
      return;
    }
  }

  OnProductModelChange(index)
  {
    if(this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=0 || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=null)
    {
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=false;
    }
    else{
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=true;
    }

  }

  testing: any[] = [];

  showItems: Array<{ id: number, value: any[] }>;
  OnSelectProductModel(selectedValue, index) {
    var selectedItem = this.tempProductModel.filter(x => x.Id != selectedValue);
    this.testing.push(selectedItem);
    this.showItems[index].value = this.tempProductModel.filter(x => x.Id == selectedValue);

  }

  getAllCustomer(ControllerName: string, ActionName: string) {
    this.showLoader = true;
    this.commonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      this.Customer = data;
      this.CustomerNameDetails=this.Customer.filter(x=>x.Id==this.singleFieldObj.Id);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllProductModel(ControllerName: string, ActionName: string) {
    this.showLoader = true;
    this.commonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      this.ProductModel = data;
      this.tempProductModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  AddNewRow(index) {
    if (this.singleFieldObj.Fk_ProductModel != undefined
      && this.singleFieldObj.UnapprovedProductDiscount != undefined) {

      this.singleFieldObj.IsRowDeleted = true;
      var obj = new SingleFieldModel();
      obj.Fk_ProductModel = this.singleFieldObj.Fk_ProductModel;
      obj.UnapprovedProductDiscount = this.singleFieldObj.UnapprovedProductDiscount

      this.singleFieldObj.UnapprovedProductDiscount = 0;
      this.singleFieldObj.Fk_ProductModel = 0;      
      this.singleFieldObj.customerSpecialDiscountInfo.push(obj);
    }
    else{
      if(this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=0 || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=null)
      {
        this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=false;
      }
      
    }
  }

  deleterow(index: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to delete this row?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (index != null) {
          this.singleFieldObj.customerSpecialDiscountInfo.splice(index, 1);
        } else {
          this.singleFieldObj.customerSpecialDiscountInfo.pop();
        }
      }
    });
  }

  getAllTransitionrules()
  {
    this.showLoader = true;
    this.commonService.GetData('WorkFlowTransitionRule', 'GetAll', null).subscribe((data: any) => {
      this.singleFieldObj.tranisitionrulesobj = data;
      this.singleFieldObj.tranisitionrulesobj.forEach(element=>
      {
        // if(element.condition_code=='DiscountSaveAsDraftAfterEdit')
        // {
        //   this.workflowActionList.push(element); 
        // }
        if(element.actionname=='Edit'&& element.action_by_role_id==this.UserRole)
        {
          this.workflowActionList.push(element); 
        }
        
      });
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  

  getActionListbyId()
  {
    this.showLoader = true;
    this.commonService.GetData('WorkFlowTransitionRule', 'GetActionsbyId', null).subscribe((data: any) => {
      this.singleFieldObj.tranisitionrulesobj = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  OnWorkflowAction(myform:NgForm,nextstateid:number){  
    
    var rule= this.workflowActionList.filter(x=>x.next_state_id==nextstateid && x.action_by_role_id==this.UserRole);
    this.WorkflowStatus=rule[0].next_state_id;
    // this.EnquiryObj.EnquiryDetails.FK_transitionrule_id=rule[0].transition_rule_id;
    // this.SetStatus(rule[0].next_state_id,myform2);  
    this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
      element.action_id=rule[0].action_id;
    }); 
      this.Update(myform);
  } 

  OnWorkflowActionForApproval(myform:NgForm,nextstateid:number)
  {
    var rule= this.workflowActions.filter(x=>x.next_state_id==nextstateid);
    this.WorkflowStatus=rule[0].next_state_id;
    this.action_id=rule[0].action_id;
    
    this.UpdateStatusForWorkFlow(this.singleFieldObj.Id ,this.WorkflowStatus); 
   }

  UpdateStatusForWorkFlow(Customerid:any,WorkflowStatus:any)
  {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You want to Continue with this Action ?"
      }
    });

   dialogRef.afterClosed().subscribe(dialogResult => {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult) {
        this.showLoader = true;
        
          let queryString="";
          queryString = "WorkflowStatus=" +this.WorkflowStatus + "&action_id="+ this.action_id + "&Comment="+ this.singleFieldObj.Comment;
          this.commonService.PostStatus('CustomerSpecialDiscount', 'UpdateStatusForWorkFlow', queryString,this.singleFieldObj.Id).subscribe((data: any) => {
          let result = data;

            this.showLoader = false;
            //if(this.UserRole=="1"){
              if (result.ResponseCode == 1) {
                this.toastr.success('Customer Frame Agreement Updated successfully!');
                this.router.navigate(['/CustomerFrameAgreementDetails']);
               
              }
              
              else {
                this.toastr.error('Failed to Update Customer Frame Agreement!');
                this.router.navigate(['/CustomerFrameAgreementDetails']);
              }
           
          }, error => {
            this.toastr.error('Failed to Update Customer Frame Agreement!');
            this.showLoader = false;
            this.router.navigate(['/Dashboard']);
          });
          
      }
    });

  }

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isCustomerFrameAgreementEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='IsCustomerFrameAgreement')[0]?.IsEditable;     
    }); 
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if
      (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;

    }
    else {
      return true;
    }
  }
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
}
