import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ProductFamily } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

@Component({
  selector: 'app-product-family-details',
  templateUrl: './product-family-details.component.html',
  styleUrls: ['./product-family-details.component.scss']
})
export class ProductFamilyDetailsComponent implements OnInit {
  displayedColumns: string[] = ['ProductLineName', 'ProductFamilyName', 'Action'];
  dataSource: MatTableDataSource<ProductFamily>;
  disabled: boolean = false;
  UserRole: any;
  isProductFamilyEditable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*************************************************************************
     End here
  *************************************************************************/

  showLoader: boolean;
  data: any[] = [];
  constructor(private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title, private route: ActivatedRoute,private accessservice: AccessPermissionService) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.GetAllProductFamilyDetails();
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductFamilyEditable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
  }

  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllProductFamilyDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllProductFamilyDetails() {
    this.showLoader = true;
    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.CommonService.GetData('ProductFamily', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.data = data.Data;
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllProductFamilyDetails();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllProductFamilyDetails();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllProductFamilyDetails();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  /*******************************************************************************
  * Form validation method
  * ******************************************************************************/

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  UpdateProductFamilyStatusById(Id: number, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this product family?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData('ProductFamily', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Product family deactivated successfully!");
            }
            else if (data.ResponseCode == 0) {
              this.toastr.warning("Failed to deactivate this product family because it has some dependency!");
            }
            else {
              this.toastr.info("Failed to deactivate product family!");
            }
            this.GetAllProductFamilyDetails();
          }, error => {
            this.toastr.error("Failed to deactivate product family!");
            this.GetAllProductFamilyDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllProductFamilyDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this product family?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData('ProductFamily', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Product family activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate product family!");
            }
            this.GetAllProductFamilyDetails();
          }, error => {
            this.toastr.error("Failed to activate product family!");
            this.GetAllProductFamilyDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllProductFamilyDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductFamilyEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}

