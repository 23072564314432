<loader [showLoader]="showLoader"></loader>





<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
    id="m_login" *ngIf="!isHeaderShowed">

    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content hidsmobs loginBgImage"
    [ngStyle]="{'background-image': 'url(' + BackroundImagePath + ')'}">
    </div>

    <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
        <div class="m-stack m-stack--hor m-stack--desktop">
            <div class="m-stack__item m-stack__item--fluid">
                <div class="m-login__wrapper">
                    <div class="m-login__logo">
                        <a href="#">
                            <!-- <img src="assets/images/logo.png" width="150"> -->
                            <img src="assets/images/weiss-logo.png" width="210" height="147.48px">
                        </a>
                    </div>
                    <div class="m-login__signin">
                        <div class="m-login__head">
                            <h3 class="m-login__title">Login</h3>
                        </div>

                        <form class="m-login__form m-form" name="form" (ngSubmit)="myform.form.valid && onSubmit()"
                            #myform="ngForm" novalidate>
                            <div class="form-group m-form__group" style="margin-bottom:1rem;">

                                <mat-form-field>
                                    <mat-label>Username</mat-label>
                                    <input type="text" name="email" [(ngModel)]="login.Email" #email="ngModel"
                                        [ngClass]="{ 'is-invalid':myform.submitted  && email.invalid || email?.touched && email.invalid}" required
                                        (change)="login.Email=login.Email.trim()" matInput>
                                        <mat-error  *ngIf="myform.hasError('required', 'email')">
                                           Username is required
                                        </mat-error>
                                       
                                </mat-form-field>
                               
                            </div>
                            <div class="form-group m-form__group" style="margin-bottom:1rem;">

                                <mat-form-field>
                                    <mat-label>Password</mat-label>
                                    <input  [type]="hide ? 'password': 'text'" name="password" [(ngModel)]="login.Password"
                                        #password="ngModel" required
                                        [ngClass]="{ 'is-invalid':myform.submitted  && password.invalid || password?.touched && password.invalid}"
                                        (change)="login.Password=login.Password.trim()" matInput>
                                            <mat-icon matSuffix (click)="hide = !hide" class="passwordVisisbility">{{hide ? 'visibility_off': 'visibility'}}</mat-icon>
                                        
                                        <mat-error  *ngIf="myform.hasError('required', 'password')">
                                            Password is required
                                        </mat-error>
                                </mat-form-field>
                                
                            </div>
                            
                            
                            <div class="row m-login__form-sub">
                                <div class="m-login__form-action col m--align-left" style="margin-top: auto;">
                                    <button id="m_login_signin_submit"
                                        class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Log In</button>
                                </div>
                                <div class="col m--align-right">

                                    <button mat-button (click)="openDialog()" type="button"><u>Forgot Password?</u></button>
                                </div>
                            </div>
                        </form>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>


<span *ngIf="isHeaderShowed">
    <app-header></app-header>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <app-footer></app-footer>
</span>