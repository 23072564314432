import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';

export class BusinessPlanModel {
    Id: number;
    UserName: string = "";
    EmployeeId: string = "";
    RoleName: string = "";
    RoleId: number = null;
    TargetYear: string = "";
    TargetFor: string = "";
    RegionName: string = "";
    PlanList:PlanModel[] = [
    {Id:0, Month: 1, MonthName: 'January', Units: 0, Amount: 0, FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 2, MonthName: 'February', Units: 0, Amount: 0, FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 3, MonthName: 'March', Units: 0, Amount: 0, FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 4, MonthName: 'April', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 5, MonthName: 'May', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 6, MonthName: 'June', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false,  UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 7, MonthName: 'July', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 8, MonthName: 'August', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 9, MonthName: 'September', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 10, MonthName: 'October', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 11, MonthName: 'November', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false },
    {Id:0, Month: 12, MonthName: 'December', Units: 0, Amount: 0 , FC1Amount:0,FC2Amount:0,FC3Amount:0,FC1Validation:false,FC2Validation:false,FC3Validation:false, UnitsValidation: false, AmountValidation: false }
];

}
export class PlanModel{
    Id: number = 0;
    Month:number = null; //Month Number
    MonthName: string = "null";
    Units: number = 0;
    Amount: number = 0;
    FC1Amount: number = 0;
    FC2Amount: number = 0;
    FC3Amount: number = 0;
    FC1Validation: boolean = false;
    FC2Validation: boolean = false;
    FC3Validation: boolean = false;
    UnitsValidation: boolean = false;
    AmountValidation: boolean = false;
}
