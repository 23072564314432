import { Component, OnInit, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Options, ProductModel } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';

@Component({
  selector: 'app-edit-view-options',
  templateUrl: './edit-view-options.component.html',
  styleUrls: ['./edit-view-options.component.scss']
})
export class EditViewOptionsComponent implements OnInit {
  isEditable: boolean = true;
  FormStatus: string = "View";
  Optionsobj: Options = new Options();
  charCode: any;
  ProductModelarr: ProductModel[] = [];
  UserRole: any;
  isProductOptionEditable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  @Input()
  showLoader: boolean = false;
  SelectedModel: any[] = [];
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isProductOptionEditable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.Optionsobj.Id = this.route.snapshot.params["id"];
    this.getProductOptionsIdDetailsById(this.Optionsobj.Id);

  }
  /*******************************************************************************
     * Get data for add product model
     * ******************************************************************************/
  getProductOptionsIdDetailsById(ProductOptionsId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + ProductOptionsId;

    this.CommonService.GetData('ProductOptions', 'GetById', queryString).subscribe((data: any) => {
      this.Optionsobj = data;

      if (this.Optionsobj.ProductModelArr != null) {
        this.SelectedModel = this.Optionsobj.ProductModelArr.map(item => item.toString());
      }

      this.GetProductModelById(this.Optionsobj.ProductSeries);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  OnModelselected($event) {
    this.Optionsobj.ProductModelArr = [];
  }

  GetProductModelById(SeriesId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "SeriesId=" + SeriesId;
    this.CommonService.GetData("ProductModel", "GetProductModelById", queryString).subscribe((data: any) => {
      this.ProductModelarr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  /*******************************************************************************
   * End get data for add product model
   * ******************************************************************************/

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    this.titleService.setTitle("Edit Product Option - WEISS");
  }

  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.Optionsobj.Price = 0;
    }
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this product option?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {


      if (dialogResult) {
        this.showLoader = true;
        this.Optionsobj.ProductModelArr = [];
        for (var i = 0; i < this.SelectedModel.length; i++) {
          this.Optionsobj.ProductModelArr.push(this.SelectedModel[i]);
        }
        this.CommonService.PostData('ProductOptions', 'Update', this.Optionsobj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Product option updated successfully!');
            this.router.navigate(['/Options', this.Optionsobj.ProductSeries]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product option already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update product option!');
            this.router.navigate(['/Options', this.Optionsobj.ProductSeries]);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update product model!');
          this.showLoader = false;
          this.router.navigate(['/Options', this.Optionsobj.ProductSeries]);
        });
      }
    });
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductOptionEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}

