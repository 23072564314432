<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Enquiry Details - {{region.Region}}</h5>
        <button type="button" class="btn btn-primary" (click)="closePopup()">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="enquiryDetailsChartModal" style="width: 95%; height: 400px; background-color: #FFFFFF;"></div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="closePopup()" class="btn btn-primary">Close</button>
    </div>
</div>