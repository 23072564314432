import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/Service/common.service';
import { ActivityModel } from 'src/app/Model/EnquiryModel';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals } from 'src/app/app.global';
import { UserRoles } from 'src/app/UserRoles';
import { EnquiryStatus } from 'src/app/EnquiryStatus';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent implements OnInit {
  @Input()
  EnquiryId: any;

  @Input()
  showLoader: boolean = false;
  charCode: number;

  AppGlobals = AppGlobals;
  fileDownloadPath = this.AppGlobals.baseFileDownloadUrl;
  allowedFileType: string[] = ['image/bmp', 'image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'text/csv', 'application/xml', 'text/xml', 'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip', 'application/vnd.rar'];
  newActivityDetails: any = {};
  activityDetails: ActivityModel[] = [];
  isActivityeditable: boolean;
  userid: string;
  RoleId: string;
  isShowEdit: boolean;
  IsActivityDataValid: boolean;
  minDate: NgbDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  StartDate:NgbDate;
  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private router: Router,
    private activeModal: NgbActiveModal) {
      this.StartDate = new NgbDate(2018, 1, 1);
  }


  ngOnInit(): void {
    this.userid = localStorage.getItem("UserID");
    this.RoleId = localStorage.getItem('UserRole');
    this.isShowEdit = this.RoleId == this.EnquiryId.PendingAtRoleId ? true : false;
    if(this.EnquiryId.PendingAtRoleId == null)
    {
     this.isShowEdit = this.userid == this.EnquiryId.initID ? true : false;
    }
    if(this.RoleId==UserRoles.Admin)
    {
      if(this.EnquiryId.status == EnquiryStatus.OrdersentforMngmtApproval_CEO 
        || this.EnquiryId.status == EnquiryStatus.OANoGenerated)
      {
        this.isShowEdit = false;
      }
      else{
        this.isShowEdit = true;
      }

    }
    if (this.EnquiryId.status == EnquiryStatus.AccountsConfirmInvoiceNo) {
      this.isShowEdit = false;
    }

    this.getActivityDetails();
  }

  ngOnChanges() {

  }
  closePopup(val) {
    if(val) this.activeModal.close('Close Click');
  }
  editActivity() {
    this.isActivityeditable = true;
  }
  addActivityDetails(index) {
    if (!(this.activityDetails[index].Activity == undefined || this.activityDetails[index].Activity == null || this.activityDetails[index].Activity == "")
      &&
      !(this.activityDetails[index].ActivityDate == undefined || this.activityDetails[index].ActivityDate == null || this.activityDetails[index].ActivityDate == "")) {
      var obj = new ActivityModel();
      obj.ISNewActivityAdded = true;
      obj.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
      obj.uniqueid = this.makeRandom();
      this.activityDetails.push(obj);
    }
  }

  deleteActivityAttachments(index) {
    this.activityDetails[index].Attachment = [];
    this.activityDetails[index].isActivityAttachment = false;
  }

  deleteActivityAttachmentsFromDB(index) {
    this.activityDetails[index].fileName = null;
    this.activityDetails[index].filePath = null;
    //this.EnquiryObj.RemoveFiles.push(this.activityDetails[index].filePath);
  }
  editFileDetails(event, index) {
    let file = event.target.files[0];
    let isValidFile = true;
    if (((file.size) / 1000000) <= 5) {
      isValidFile = this.allowedFileType.includes(file.type);
      if (!isValidFile) {
        this.toastr.warning("Invalid file attached!");
        switch (event.target.name) {

          case "activityfile":
            this.activityDetails[index].Attachment = [];
            this.activityDetails[index].isActivityAttachment = false;
            break;
        }
        event.target.value = null;
        isValidFile = false;
      }
    }
    else {
      this.toastr.warning("File size must be less than or equal to 5MB!");
      switch (event.target.name) {

        case "activityfile":
          this.activityDetails[index].Attachment = [];
          this.activityDetails[index].isActivityAttachment = false;
          break;
      }
      event.target.value = null;
      isValidFile = false;
    }
    if (isValidFile) {
      //CHECK IF THE FILE NAME IS NOT TOO BIG

      switch (event.target.name) {

        case "activityfile":
          this.activityDetails[index].Attachment = [];
          this.activityDetails[index].Attachment.push(file);
          this.activityDetails[index].isActivityAttachment = true;
          break;
      }
    }
  }
  onActivityChange(i) {
    if (this.activityDetails[i].Activity == undefined || this.activityDetails[i].Activity == null || this.activityDetails[i].Activity == "") {
      this.activityDetails[i].ActivityValidation = true;
      this.IsActivityDataValid = false;
    } else {
      this.activityDetails[i].ActivityValidation = false;
    }
  }
  onActivityDateChange(i) {
    if (this.activityDetails[i].ActivityDate == undefined || this.activityDetails[i].ActivityDate == null || this.activityDetails[i].ActivityDate == "") {
      this.activityDetails[i].ActivityDateValidation = true;
      this.IsActivityDataValid = false;
    } else {
      this.activityDetails[i].ActivityDateValidation = false;
    }
  }
  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }
  checkActivityValidations() {
    this.IsActivityDataValid = true;
    this.activityDetails.forEach(element => {
      if (element.Activity == undefined || element.Activity == null || element.Activity == "") {
        element.ActivityValidation = true;
        this.IsActivityDataValid = false;
      }
      if (element.ActivityDate == undefined || element.ActivityDate == null || element.ActivityDate == "") {
        element.ActivityDateValidation = true;
        this.IsActivityDataValid = false;
      }
    });
  }
  SaveActivityDetails() {
    this.checkActivityValidations();
    if (!this.IsActivityDataValid) {
      return;
    }
    this.showLoader = true;
    this.activityDetails.forEach(element => {
      if (element.ActivityDate != undefined) {
        element.ActivityDateISO = new Date(this.convertDate(new NgbDate(element.ActivityDate.year, element.ActivityDate.month, element.ActivityDate.day))).toDateString();
      }
      if (element.ActivityDate != undefined) {
        element.ActivityDate = new Date(this.convertDate(new NgbDate(element.ActivityDate.year, element.ActivityDate.month, element.ActivityDate.day)));
      }
      element.EnquiryId = this.EnquiryId.enqid;
    });

    let activityDetails = JSON.stringify(this.activityDetails);
    const frmData = new FormData();
    frmData.append("ActivityObj", activityDetails);
    var i = 0;
    this.activityDetails.forEach(element => {
      if (element.Attachment != undefined) {
        frmData.append("Attachment[" + i + "]", this.activityDetails[i].Attachment[0]);
      }
      i++;
    });

    this.commonService.PostData("Enquiry", "AddActivity", frmData, null).subscribe((data: any) => {
      this.toastr.success("Activity details added successfully!");
      this.activeModal.close('Close Click');
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to add activity details!");
      this.showLoader = false;
    });

  }
  getActivityDetails() {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + this.EnquiryId.enqid;
    this.commonService.GetData('Enquiry', 'GetActivityByEnquiryId', queryString).subscribe((data: any) => {
      this.activityDetails = data;

      this.activityDetails.forEach(element => {
        element.ActivityDate = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());
      });
      if (this.isShowEdit && this.activityDetails?.length == 0) {
        var obj = new ActivityModel();
        obj.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
        obj.uniqueid = this.makeRandom();
        this.activityDetails.push(obj);
      }
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }
  makeRandom() {
    let text = "";
    var possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~";
    const lengthOfCode = 40;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  getFileDetails(event) {
    let file = event.target.files[0];
    let isValidFile = true;
    if (((file.size) / 1000000) <= 5) {
      isValidFile = this.allowedFileType.includes(file.type);
      if (!isValidFile) {
        this.toastr.warning("Invalid file attached!");
        switch (event.target.name) {
          case "activityfile":
            this.newActivityDetails.Attachment = [];
            break;
        }
        event.target.value = null;
        isValidFile = false;
      }
    }
    else {
      this.toastr.warning("File size must be less than or equal to 5MB!");
      switch (event.target.name) {
        case "activityfile":
          this.newActivityDetails.Attachment = [];
          break;
      }
      event.target.value = null;
      isValidFile = false;
    }
    if (isValidFile) {
      switch (event.target.name) {
        case "activityfile":
          this.newActivityDetails.Attachment = [];
          this.newActivityDetails.Attachment.push(file);
          break;
      }
    }
  }


  deleteActivityDetails(index: any) {
    if (index != null) {
      this.activityDetails.splice(index, 1);
    } else {
      this.activityDetails.pop();
    }
  }

  AddActivity() {
    this.showLoader = true;
    const frmData = new FormData();
    this.activityDetails.forEach(item => {
      item.IsRowDeleted = false;
    });
    let activityDetails = JSON.stringify(this.activityDetails);
    frmData.append("activityDetails", activityDetails);
    this.commonService.PostData("Activity", "Add", frmData, null).subscribe((data: any) => {
      if (data.ResponseCode == 1) {
        this.toastr.success("Activity added successfully!");
        this.showLoader = false;
      }
      else {
        this.toastr.error("Failed to add activity!");
        this.showLoader = false;
      }
      this.router.navigate(['/Enquiry']);
    }, error => {
      this.toastr.error("Failed to add activity!");
      this.showLoader = false;
      this.router.navigate(['/Enquiry']);
    });
  }
}
