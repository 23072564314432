import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-values-chart',
  templateUrl: './order-values-chart.component.html',
  styleUrls: ['./order-values-chart.component.scss']
})
export class OrderValuesChartComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  drpdnOption: string = "Amount";

  @Input() Year: number;
  @Input() region: number;
  @Input() currency: number;
  @Input() filterChanged: boolean;
  // @Input() mode: string = "";
  // Chartidname: string = "";
  constructor(private toastr: ToastrService, private commonService: CommonService
    ,private modalService: NgbModal,public modal: NgbActiveModal) { }

  ngOnInit(): void {
    // if(this.mode == "view")
    // {
    //   this.Chartidname="OrderValuesChartView";
    // }else{
    //   this.Chartidname = "OrderValuesChart";
    // }
   // am4core.options.onlyShowOnViewport = true;
    this.OrderValuesChart();

  }
  ngOnChanges(): void {
    if (this.filterChanged) this.OrderValuesChart();
  }

  OnSelectChange() {
    this.OrderValuesChart();
  }

  // OrderValuesChart1() {
  //   this.showLoader = true;
  //   let queryString = "";
  //   queryString += "drpdnOption=" + this.drpdnOption;
  //   queryString += "&Year=" + this.Year;
  //   queryString += "&regionid=" + this.region;
  //   queryString += "&currency=" + this.currency;

  //   this.commonService.GetData("Dashboard", "OrderValuesChart", queryString).subscribe((data: any) => {

  //     var chart = am4core.create("OrderValuesChart", am4charts.XYChart);
  //     // Add data
  //     chart.scrollbarX = new am4core.Scrollbar();
  //     chart.cursor = new am4charts.XYCursor();
  //     chart.scrollbarX.exportable = false;
  //     chart.data = data;

  //     chart.exporting.menu = new am4core.ExportMenu();
  //     // Create axes
  //     var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //     categoryAxis.dataFields.category = "category";
  //     categoryAxis.renderer.minGridDistance = 30;
  //     categoryAxis.renderer.grid.template.location = 0;

  //     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //     valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
  //       var val = text == "0.00" ? "0" : text;
  //       return val;
  //     });
  //     if (this.drpdnOption == 'Units') {
  //       valueAxis.title.text = "No.";
  //       chart.numberFormatter.numberFormat = "0";
  //       valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
  //         var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
  //         return val;
  //       });
  //     }
  //     else {
  //       if (this.currency == 1) valueAxis.title.text = "MUSD";
  //       if (this.currency == 2) valueAxis.title.text = "MEURO";
  //       if (this.currency == 3) valueAxis.title.text = "MINR";
  //       chart.numberFormatter.numberFormat = "0.00";
  //     }

  //     valueAxis.title.fontWeight = "bold";
  //     valueAxis.title.fontSize = "16px";
  //     valueAxis.min = 0;
  //     valueAxis.extraMax = 0.5;

  //     //valueAxis.extraMax = 0.1;
  //     valueAxis.calculateTotals = true;

  //     //#region "LineSeries1"
  //     let lineSeries = chart.series.push(new am4charts.LineSeries());
  //     lineSeries.name = "Won";
  //     lineSeries.dataFields.valueY = "valueLine1";
  //     lineSeries.dataFields.categoryX = "category";
  //     lineSeries.tooltip.getFillFromObject = false;
  //     lineSeries.tooltip.background.fill = am4core.color("#4a463c");
  //     lineSeries.stroke = am4core.color("#80ff80");   //For line or column color

  //     let bullet = lineSeries.bullets.push(new am4charts.Bullet());
  //     bullet.fill = am4core.color("#80ff80"); // tooltips grab fill from parent by default
  //     bullet.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
  //     let circle = bullet.createChild(am4core.Circle);
  //     circle.radius = 4;
  //     circle.fill = am4core.color("#fff");
  //     circle.strokeWidth = 3;

  //     let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
  //     labelBullet.label.text = "{valueL1}";
  //     labelBullet.label.verticalCenter = "bottom";
  //     //#endregion

  //     //#region "LineSeries2"
  //     let lineSeries2 = chart.series.push(new am4charts.LineSeries());
  //     lineSeries2.name = "Lost";
  //     lineSeries2.dataFields.valueY = "valueLine2";
  //     lineSeries2.dataFields.categoryX = "category";
  //     lineSeries2.tooltip.getFillFromObject = false;
  //     lineSeries2.tooltip.background.fill = am4core.color("#4a463c");
  //     lineSeries2.stroke = am4core.color("#ff4d4d"); //For line or column color

  //     let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
  //     bullet2.fill = am4core.color("#ff4d4d"); // tooltips grab fill from parent by default
  //     bullet2.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
  //     let circle2 = bullet2.createChild(am4core.Circle);
  //     circle2.radius = 4;
  //     circle2.fill = am4core.color("#fff");
  //     circle2.strokeWidth = 3;

  //     let labelBullet2 = lineSeries2.bullets.push(new am4charts.LabelBullet());
  //     labelBullet2.label.text = "{valueL2}";
  //     labelBullet2.label.verticalCenter = "bottom";
  //     //#endregion

  //     //#region "LineSeries3"
  //     let lineSeries3 = chart.series.push(new am4charts.LineSeries());
  //     lineSeries3.name = "Open";
  //     lineSeries3.dataFields.valueY = "valueLine3";
  //     lineSeries3.dataFields.categoryX = "category";
  //     lineSeries3.tooltip.getFillFromObject = false;
  //     lineSeries3.tooltip.background.fill = am4core.color("#4a463c");
  //     //lineSeries2.stroke = am4core.color("#80ff80"); //For line or column color

  //     let bullet3 = lineSeries3.bullets.push(new am4charts.Bullet());
  //     bullet3.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
  //     bullet3.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
  //     let circle3 = bullet3.createChild(am4core.Circle);
  //     circle3.radius = 4;
  //     circle3.fill = am4core.color("#fff");
  //     circle3.strokeWidth = 3;

  //     let labelBullet3 = lineSeries3.bullets.push(new am4charts.LabelBullet());
  //     labelBullet3.label.text = "{valueL2}";
  //     labelBullet3.label.verticalCenter = "bottom";
  //     //#endregion

  //     // Create series
  //     function createSeries(field, name, color) {

  //       // Set up series
  //       var series = chart.series.push(new am4charts.ColumnSeries());
  //       series.name = name;
  //       series.dataFields.valueY = field;
  //       series.dataFields.categoryX = "category";
  //       series.sequencedInterpolation = true;

  //       // Make it stacked
  //       series.stacked = true;

  //       // Configure columns
  //       series.columns.template.width = am4core.percent(60);
  //       series.fill = am4core.color(color);
  //       series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
  //       series.tooltip.background.fill = am4core.color("#4a463c");

  //       // Add label
  //       var labelBullet = series.bullets.push(new am4charts.LabelBullet());
  //       labelBullet.label.text = "{valueY}";
  //       labelBullet.locationY = 0.5;
  //       labelBullet.label.hideOversized = true;

  //       return series;
  //     }

  //     createSeries("valueBar1", "Won", "#80ff80");
  //     createSeries("valueBar2", "Lost", "#ff4d4d");
  //     createSeries("valueBar3", "Open", "#6771dc");

  //     // Legend
  //     chart.legend = new am4charts.Legend();

  //   })
  // }

  OrderValuesChart() {
    const that = this;
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&regionid=" + this.region;
    queryString += "&currency=" + this.currency;

    this.commonService.GetData("Dashboard", "OrderValuesChart", queryString).subscribe((data: any) => {

      var chart = am4core.create("OrderValuesChart", am4charts.XYChart);
      // Add data
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      // chart.cursor = new am4charts.XYCursor();
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;
      chart.data = data;
      chart.exporting.menu = new am4core.ExportMenu();
      let options = chart.exporting.getFormatOptions("pdf");
      options.addURL = false;
      chart.exporting.setFormatOptions("pdf", options);
      chart.exporting.filePrefix = "Enquiry_Status";
      /**************Custom Export**************** */
      chart.exporting.menu.items = [
        {
          "label": "...",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                // { "type": "svg", "label": "SVG" },
                { "type": "pdf", "label": "PDF" }
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "xlsx", "label": "XLSX" },
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }
      ];
      let CurrencyLabel = "";
      if (this.drpdnOption == "Amount") {
        if (this.currency == 1) CurrencyLabel = "(MUSD)";
        if (this.currency == 2) CurrencyLabel = "(MEURO)";
        if (this.currency == 3) CurrencyLabel = "(MINR)";
      }
      else {
        CurrencyLabel = "(No's)";
      }
      chart.exporting.dataFields = {
        "category": "Month",
        "valueBar1": "Won_Individual" + CurrencyLabel,
        "valueLine1": "Won_Cumulative" + CurrencyLabel,
        "valueBar2": "Lost_Individual" + CurrencyLabel,
        "valueLine2": "Lost_Cumulative" + CurrencyLabel,
        "valueBar3": "Open_Individual" + CurrencyLabel,
        "valueLine3": "Open_Cumulative" + CurrencyLabel
      }
      /**************End Custom Export**************** */

      let title = chart.titles.create();
      title.text = "Enquiry Status";
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.grid.template.location = 0;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.00" ? "0" : text;
        return val;
      });
      /**************Scrolling Start End Point**************** */
      chart.events.on("beforedatavalidated", function(ev) {
        categoryAxis.start = 0;
        categoryAxis.end = 0.5;
        valueAxis.start = 0;
        valueAxis.end = 1;
        });
        /**************End Scrolling Start End Point**************** */
      if (this.drpdnOption == 'Units') {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        if (this.currency == 1) valueAxis.title.text = "MUSD";
        if (this.currency == 2) valueAxis.title.text = "MEURO";
        if (this.currency == 3) valueAxis.title.text = "MINR";
       // chart.numberFormatter.numberFormat = "0.00";
      }

      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;
      valueAxis.events.on("ready", function (ev) {
        ev.target.min = ev.target.min;
        ev.target.max = ev.target.max;
        if (ev.target.max < 0.1) valueAxis.extraMax = 10;
        if (ev.target.max > 0.1 && ev.target.max < 1) valueAxis.extraMax = 5;
        if (ev.target.max > 1) valueAxis.extraMax = 0.2;
        if (ev.target.max == undefined) valueAxis.extraMax = 0.2;

      })


      valueAxis.calculateTotals = true;

      //#region "LineSeries1"
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = "Won";
      lineSeries.dataFields.valueY = "valueLine1";
      lineSeries.dataFields.categoryX = "category";
      lineSeries.tooltip.getFillFromObject = false;
      lineSeries.tooltip.background.fill = am4core.color("#4a463c");
      lineSeries.stroke = am4core.color("#80ff80");   //For line or column color

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#80ff80"); // tooltips grab fill from parent by default
      bullet.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color("#fff");
      circle.strokeWidth = 3;

      let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueL1}";
      labelBullet.label.verticalCenter = "bottom";
      //#endregion

      //#region "LineSeries2"
      let lineSeries2 = chart.series.push(new am4charts.LineSeries());
      lineSeries2.name = "Lost";
      lineSeries2.dataFields.valueY = "valueLine2";
      lineSeries2.dataFields.categoryX = "category";
      lineSeries2.tooltip.getFillFromObject = false;
      lineSeries2.tooltip.background.fill = am4core.color("#4a463c");
      lineSeries2.stroke = am4core.color("#ff4d4d"); //For line or column color

      let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#ff4d4d"); // tooltips grab fill from parent by default
      bullet2.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle2 = bullet2.createChild(am4core.Circle);
      circle2.radius = 4;
      circle2.fill = am4core.color("#fff");
      circle2.strokeWidth = 3;

      let labelBullet2 = lineSeries2.bullets.push(new am4charts.LabelBullet());
      labelBullet2.label.text = "{valueL2}";
      labelBullet2.label.verticalCenter = "bottom";
      //#endregion

      //#region "LineSeries3"
      let lineSeries3 = chart.series.push(new am4charts.LineSeries());
      lineSeries3.name = "Open";
      lineSeries3.dataFields.valueY = "valueLine3";
      lineSeries3.dataFields.categoryX = "category";
      lineSeries3.tooltip.getFillFromObject = false;
      lineSeries3.tooltip.background.fill = am4core.color("#4a463c");
      //lineSeries2.stroke = am4core.color("#80ff80"); //For line or column color

      let bullet3 = lineSeries3.bullets.push(new am4charts.Bullet());
      bullet3.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
      bullet3.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle3 = bullet3.createChild(am4core.Circle);
      circle3.radius = 4;
      circle3.fill = am4core.color("#fff");
      circle3.strokeWidth = 3;

      let labelBullet3 = lineSeries3.bullets.push(new am4charts.LabelBullet());
      labelBullet3.label.text = "{valueL2}";
      labelBullet3.label.verticalCenter = "bottom";
      //#endregion

      // Create series
      function createSeries(field, name, color) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "category";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.fill = am4core.color(color);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
        series.tooltip.background.fill = am4core.color("#4a463c");

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;

        return series;
      }

      createSeries("valueBar1", "Won", "#80ff80");
      createSeries("valueBar2", "Lost", "#ff4d4d");
      createSeries("valueBar3", "Open", "#6771dc");

      // Legend
      chart.legend = new am4charts.Legend();

    })
  }
  showIndicator(chart, indicator) {
    if (indicator) {
      indicator.show();
    }
    else {
      indicator = chart.tooltipContainer.createChild(am4core.Container);
      indicator.background.fill = am4core.color("#fff");
      indicator.background.fillOpacity = 0.8;
      indicator.width = am4core.percent(100);
      indicator.height = am4core.percent(100);

      var indicatorLabel = indicator.createChild(am4core.Label);
      indicatorLabel.text = "No data available for the selection...";
      indicatorLabel.align = "center";
      indicatorLabel.valign = "middle";
      indicatorLabel.fontSize = 20;
    }
  }

  hideIndicator(indicator) {
    indicator.hide();
  }
  // OpenChartPopup()
  // {
  //   const modalRef = this.modalService.open(OrderValuesChartComponent, { size: 'lg' });
  //   modalRef.componentInstance.Year = this.Year;
  //   modalRef.componentInstance.region = this.region;
  //   modalRef.componentInstance.currency = this.currency;
  //   modalRef.componentInstance.filterChanged = this.filterChanged;
  //   modalRef.componentInstance.mode = "view";

  // }
  // ClosePopup()
  // {
  //   this.modal.close();
  // }
}
