import { Component, OnInit, Input } from '@angular/core';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-guest',
  templateUrl: './add-guest.component.html',
  styleUrls: ['./add-guest.component.scss']
})
export class AddGuestComponent implements OnInit {
  GuestDetails: EmployeeGuestModel = new EmployeeGuestModel();
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  //passPattern: any = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,30}$/;
  passPattern: any = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,30}';
  isValidEmail: boolean = false;
  Country: any[] = [];
  Roles: any[] = [];
  Region: any[] = [];
  UserId: number;
  charCode: any;

  @Input()
  showLoader: boolean = false;

  ConfirmPasshide: boolean = true;
  Passhide: boolean = true;

  constructor(private router: Router, private toastr: ToastrService, private Service: CommonService, public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.getAllCountry();
    this.getAllRoles();
    this.getAllRegion();
  }

  /*******************************************************************************
   * Get data for add employee
   * ******************************************************************************/
  getAllCountry() {
    this.showLoader = true;
    this.Service.GetData("Country", "GetAll", null).subscribe((data: any) => {
      this.Country = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  getAllRoles() {
    this.showLoader = true;
    this.Service.GetData('Account', 'GetAllGuestRoles', null).subscribe((data: any) => {
      this.Roles = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  getAllRegion() {
    this.showLoader = true;
    this.Service.GetData("Region", "GetAll", null).subscribe((data: any) => {
      this.Region = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }


  /*******************************************************************************
   * End Get data for add employee
   * ******************************************************************************/


  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/


  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this guest?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.GuestDetails.RoleType = 3;
        this.showLoader = true;
        this.Service.PostData('Account', 'Register', this.GuestDetails, null).subscribe((data: any) => {
          let result = data;
          this.showLoader = false;
          if (result.ResponseCode == 1) {
            this.toastr.success('Guest added successfully!');
            this.router.navigate(['/Guest']);
          }
          if (result.ResponseCode == 0) {
            this.toastr.warning('Username already exists!');
            myform.controls['username'].setErrors({ AlreadyPresent: true });
          }
          // chk = 2 Guest-id/ EmpCode already exists
          // chk=3 ContactNo. already exists
          // chk=4 ContactNo and Guest-id/ EmpCode already exists
          if (result.ResponseCode == 2) {
            this.toastr.warning('Guest id already exists!');
            myform.controls['guest_id'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 3) {
            this.toastr.warning('Contact number already exists!');
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }
          if (result.ResponseCode == 4) {
            this.toastr.warning('Guest id and contact number already exists!');
            myform.controls['guest_id'].setErrors({ AlreadyPresent: true });
            myform.controls['contactnumber'].setErrors({ AlreadyPresent: true });
          }
        }, error => {
          this.toastr.error('Falied to add guest details!');
          this.showLoader = false;
          this.router.navigate(['/Guest']);
        });
      }
    });

  }



}
