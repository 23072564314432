<div class="row">
  <div class="col-sm-12">
    <div class="m-portlet">
      <div style="padding-left: 17px; padding-right: 15px;  margin-top: 17px">

        <form #addActivityForm="ngForm" (ngSubmit)="onSubmit(filterActivityForm)" novalidate>
          <div class="form-group m-form__group row">
            <div class="col">
              <label class="form-control-label" class="lable-size">Activity Type:</label>
              <select class="form-control" id="ActivityTypeId" name="ActivityTypeId"
                [(ngModel)]="filterActivityForm.ActivityTypeId" required>
                <option [value]="null" selected>select</option>
                <option *ngFor="let item of activityTypeMaster" [value]="item.ActivityTypeId">
                  {{ item.ActivityTypeDesc }}
                </option>
              </select>
            </div>


            <div class="col">
              <label for="activity-status-filter" class="lable-size">Status:</label>
              <select class="form-control" id="ActivityStatusId" name="ActivityStatusId"
                [(ngModel)]="filterActivityForm.ActivityStatusId" required>
                <option [value]="null" selected>select</option>
                <option *ngFor="let item of activityStatusByTypeMaster " value="{{ item.Id }}">
                  {{ item.ActivityStatusDesc }}
                </option>
              </select>
            </div>

            <div class="col">
              <label for="priority-filter" class="lable-size">Subject:</label>
              <input class="form-control" name="Subject" #Subject (keydown)="onKeyDown($event)"
                [(ngModel)]="filterActivityForm.Subject" placeholder="subject" type="text" />
            </div>

            <div  *ngIf="canViewCustomerEngagementReportOfOthers" Class="col">
              <label for="participant" class="lable-size">Participant:</label>
              <select name="ParticipantId" id="ParticipantId" class="form-control" [(ngModel)]="filterActivityForm.ParticipantId" required>
                <option [value]="null" selected>select</option>
                <option *ngFor="let p of allUserMaster" [value]="p.Id"> {{ p.Name }} </option>
              </select>
            </div>

            <div class="datepickclear">
              <div class="input-group">
                <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                  [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                  outsideDays="hidden" [startDate]="EnquiryngbDateFromFiter!" tabindex="-1" hidden [minDate]="minDate"
                  [maxDate]="maxDate" [footerTemplate]="footerTemplate">

                <ng-template #t let-date let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>


            <div class="col-md-2">
              <label class="form-control-label" class="lable-size">Due Date:</label>
              <div class="form-group  m-form__group ">
                <div class="input-group">
                  <input readonly #dpFromDate class="form-control" placeholder="From" name="dpFromDate"
                    [value]="formatter.format(EnquiryngbDateFromFiter)"
                    (input)="EnquiryngbDateFromFiter = validateInput(EnquiryngbDateFromFiter, dpFromDate.value)"
                    (click)="datepicker.toggle()">
                  <input readonly #dpToDate class="form-control" placeholder="To" name="dpToDate"
                    [value]="formatter.format(EnquiryngbDateToFiter)"
                    (input)="EnquiryngbDateToFiter = validateInput(EnquiryngbDateToFiter, dpToDate.value)"
                    (click)="datepicker.toggle()">
                </div>
              </div>
            </div>

            <ng-template #footerTemplate>
              <hr class="my-0">
              <button class="btn btn-primary btn-sm m-2 float-right" (click)="datepicker.toggle()">Ok</button>
            </ng-template>




            <div class="col">
              <br>
              <mat-checkbox id="isOwner" [(ngModel)]="filterActivityForm.IsOwner" name="isOwner">
                <label for="isOwner" class="lable-size">I am Owner</label>
              </mat-checkbox>
            </div>

            <div class="col">
              <br>
              <button class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next">Filter </button>
            </div>

            <div class="col">
              <br>
              <button type="button" (click)="removeFilter()" class="btn btn-metal m-btn m-btn--air m-btn--custom">Clear
              </button>
            </div>

          </div>
          <div class="form-group m-form__group row">

            <div class="col-md-2">
              <label class="lable-size">Related To:</label>
              <select class="form-control" name="EntityTypeId" [(ngModel)]="filterActivityForm.EntityTypeId"
                (ngModelChange)="onEntityTypeChange(filterActivityForm.EntityTypeId)" required>
                <option [value]="null" selected>select</option>
                <option *ngFor=" let s of allEntityTypesMaster " [value]="s.Id">
                  {{ s.EntityType }}
                </option>
              </select>
            </div>

            <div class="col-md-5" style="margin-top: 28px;">
              <ng-select name="participants" [items]="entityTypeFilterArray" [searchable]="true" [multiple]="false" [closeOnSelect]="true"
                [placeholder]="'Select '"   bindLabel="EntityName" bindValue="EntityId" [(ngModel)]="filterActivityForm.EntityId">
              <ng-template ng-option-tmp class="             ng-dropdown-panel .scroll-host " let-item="item" let-item$="item$" let-index="index">
                <div>{{ item.EntityName }}</div>
              </ng-template>
              </ng-select>
            </div>

          </div>
        </form>
        <br>

<!-- <span>Selected Id----{{filterActivityForm.EntityId}}</span>
        <div class="form-group m-form__group row">
          <label class="col-lg-2 col-form-label">Participant:<span style="color:#f4516c;">&nbsp;*</span></label>
          <div class="col-lg-3">
            <ng-select name="participants" [items]="entityTypeFilterArray" [searchable]="true" [multiple]="false" [closeOnSelect]="true"
              [placeholder]="'Select '" bindLabel="EntityName" bindValue="EntityId" [(ngModel)]="filterActivityForm.EntityId">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div>{{ item.EntityName }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div> -->


           <b style="padding-left: 13px;margin-right: 15px;">Bulk Edit</b>
              <mat-slide-toggle class="example-margin" [(ngModel)]="isbulkEdit" (change)="bulkEdit()">
              </mat-slide-toggle>&nbsp;
               <a class="btn btn-primary m-btn m-btn--air m-btn--custom" (click)="addRow()">Add
                Activities
              </a>


        <table *ngIf="filteredActivities?.length" class=" table-striped- dataTable no-footer table" role="grid">
          <thead>
            <tr>
              <th style="width:21%;">Customer</th>
              <th style="width:12%;">Activity Type</th>
              <th  style="width:12%;">Participants</th>
              <th style="width:15%;">Subject</th>
              <th style="width:11%;">Due Date</th>
              <th style="width:12%;">Activity Status</th>
              <th style="width:8%;">Due By </th>
              <th style="width: 11%;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr list-activity-row (refreshGridParent)="refreshGrid(filterActivityForm)"   (initialActivityToCompairParent)="initialActivityToCompair($event)"
              *ngFor="let activityRow of filteredActivities; let i = index" [activity]="activityRow" [index]="i" (dataEvent)="removeByIndex($event)"></tr>
          </tbody>
        </table>

        <div *ngIf="filteredActivities?.length <= 0">
          <h5 style="padding: 50px 20px;">No Records found</h5>
        </div>
      </div>

    </div>
  </div>
</div>


<mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="pageEvent($event)"
  [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>


