import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { EnquiryStatus } from 'src/app/EnquiryStatus';
import { EnquiryDetailsModel } from 'src/app/Model/EnquiryModel';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { CommonService } from 'src/app/Service/common.service';
import { UserRoles } from 'src/app/UserRoles';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-technical-discard',
  templateUrl: './technical-discard.component.html',
  styleUrls: ['./technical-discard.component.scss']
})
export class TechnicalDiscardComponent implements OnInit {

  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();

  @Input() showLoader: boolean = false;
  @Input() enquiryDataObj;
  //technicalDiscardReasonList: any;
  technicalDiscardReasonList: SingleFieldModel[] = [];
  enqId: any;
  selectedTCReason: any = 0;
  technicalDiscardReasonValidation: boolean;
  role: string;
  enquiryStatus = EnquiryStatus;
  btnforL1: boolean = false;
  btnforL2: boolean = false;
  btnforL3: boolean = false;
  enqNextStatusId:any;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;

  constructor(private toastr: ToastrService, private activeModal: NgbActiveModal,
    private commonService: CommonService, private modalService: NgbModal, public dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
    this.role = localStorage.getItem("UserRole");
   // console.log("openTechnicalDiscardPopup data --->", this.enquiryDataObj);
    this.enqId = this.enquiryDataObj.EnquiryId;
    this.enqNextStatusId= this.enquiryDataObj.EnquiryStatusId;
    this.getAllTechnicalDiscardReason();
    this.getEnquiryDetailsById(this.enqId);
    this.ButtonsAccess();
  }


  // getAllTechnicalDiscardReason() {
  //   this.showLoader = true;

  //   this.commonService.GetData('Enquiry', 'GetTechnicalDiscardDropdownData', null).subscribe((data: any) => {
  //     this.technicalDiscardReasonList = data.TechnicalDiscard;
  //     // console.log("technicalDiscardReasonList", this.technicalDiscardReasonList);
  //     this.showLoader = false;
  //   }, error => {
  //     this.toastr.error("Failed to load data!");
  //     this.showLoader = false;
  //   });

  // }

  getAllTechnicalDiscardReason() {
    this.showLoader = true;
    let reasoncode = 'TECH_CAT'
    let queryString = "";
    queryString = "reasonCatCode=" + reasoncode;
    this.commonService.GetData("ReasonEnquiryWinLoss", "GetAllReason", queryString).subscribe((data: any) => {
      this.technicalDiscardReasonList = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  getEnquiryDetailsById(id) {
    let queryString = "";
    queryString = "id=" + id;
    this.commonService.GetData('Enquiry', 'GetById', queryString).subscribe((data: any) => {
      this.EnquiryObj.EnquiryDetails = data.EnquiryDetails;
      if(this.EnquiryObj.EnquiryDetails.TechnicalDiscardId==null)
      {
        this.EnquiryObj.EnquiryDetails.TechnicalDiscardId=undefined;
      }
    },error => {
      this.showLoader = false;
    });
  }

  onTCReasonChange(id) {
    this.technicalDiscardReasonValidation = false;
    this.selectedTCReason = id;
    console.log("id of selected tc--->", this.selectedTCReason);

  }

  closePopup() {
    this.activeModal.close('Close Click');
  }

  ButtonsAccess() {
    //this.isQuotationEditable = true;
    /**************************Admin Access******************************* */
    if (this.role == UserRoles.Admin) {
      if (this.enquiryDataObj.PendingAtRoleId == UserRoles.L1) {
        this.btnforL1 = true;
      }
      if (this.enquiryDataObj.PendingAtRoleId == UserRoles.L2) {
        this.btnforL2 = true;
      }
      if (this.enquiryDataObj.PendingAtRoleId == UserRoles.L3) {
        this.btnforL3 = true;
      }

    }
    /**************************End Admin Access******************************* */
    if (this.role == UserRoles.L1) {
      this.btnforL1 = true;
    }
    if (this.role == UserRoles.L2) {
      this.btnforL2 = true;
    }
    if (this.role == UserRoles.L3) {
      this.btnforL3 = true;
    }
  }

  EnquiryTechnicalDiscard(technicalDiscardForm : NgForm) {
   
    // if (this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason == undefined || this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason == null ||
    //   this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason == 0) {
    //   return;
    // }

    technicalDiscardForm.control.markAllAsTouched();
  if (technicalDiscardForm.invalid) {
    this.toastr.error("Please fill required information");
    return;
  }

    var technicaldiscardclosereasondetails = this.technicalDiscardReasonList.filter(x => x.Id == this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason)
   this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus = technicaldiscardclosereasondetails[0].Name; 
   this.EnquiryObj.EnquiryDetails.ReasonCategory = technicaldiscardclosereasondetails[0].ReasonCategoryId;

    this.EnquiryObj.EnquiryDetails.Status = this.enqNextStatusId;
    var confimrmsg = AppGlobals.successMessages.filter(x => x.id == this.enqNextStatusId)[0];
    this.showLoader = true;
    let enquiryDetails = JSON.stringify(this.EnquiryObj);
        const frmData = new FormData();
        frmData.append("enquiryDetails", enquiryDetails);

        this.commonService.PostData("Enquiry", "TechnicalDiscardUpdate", frmData, null).subscribe((data: any) => {
          this.EnquiryObj.EnquiryDetails.EnquiryId = data;

          this.showLoader = false;
          this.toastr.success(confimrmsg.successmsg);
          this.router.navigate(['/Enquiry']);
          this.closePopup();

        }, error => {
          this.toastr.error(confimrmsg.errormsg);
          this.showLoader = false;
        });

    // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //   maxWidth: "400px",
    //   data: {
    //     title: "Are you sure?",
    //     message: confimrmsg.confirmmsg
    //   }
    // });

    // dialogRef.afterClosed().subscribe(dialogResult => {
    //   if (dialogResult) {
    //     this.showLoader = true;

    //     let enquiryDetails = JSON.stringify(this.EnquiryObj);
    //     const frmData = new FormData();
    //     frmData.append("enquiryDetails", enquiryDetails);

    //     this.commonService.PostData("Enquiry", "TechnicalDiscardUpdate", frmData, null).subscribe((data: any) => {
    //       this.EnquiryObj.EnquiryDetails.EnquiryId = data;

    //       this.showLoader = false;
    //       this.toastr.success(confimrmsg.successmsg);
    //       this.router.navigate(['/Enquiry']);
    //       this.closePopup();

    //     }, error => {
    //       this.toastr.error(confimrmsg.errormsg);
    //       this.showLoader = false;
    //     });
    //   }
    // });
  }

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

}
