import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Activity } from 'src/app/Model/ActivityModel';
import { ActivityService } from 'src/app/Service/activity.service';
import { ViewActivityComponent } from '../../customer-engagement/activity/view-activity/view-activity.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-activity-list-by-entity',
  templateUrl: './activity-list-by-entity.component.html',
  styleUrls: ['./activity-list-by-entity.component.scss']
})
export class ActivityListByEntityComponent implements OnInit {
  filterActivityForm: any = {}
  activities: Activity[];
  HideId: Boolean = true;

  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
  canViewCustomerEngagementReportOfOthers:boolean=false;
  Role: any;
  partid: string = null;  

  @Input() entityTypeId: number;

  // enquiryId this variable can be used as entityId 
  @Input() enquiryId: any;
  @Output() closePopupEvent = new EventEmitter<boolean>();

  dataSource: MatTableDataSource<any>;
  @ViewChild('paginator') paginator: MatPaginator;

  displayedColumns: string[] = ['ActivityType', 'Subject', 'DueDate', 'ActivityStatus', 'DelayedBy', 'Actions'];

  id: number;
  constructor(private activityService: ActivityService, private route: ActivatedRoute, private modalService: NgbModal, private accessservice: AccessPermissionService) { }

  ngOnInit(): void {
    this.id = this.enquiryId ? this.enquiryId : this.route.snapshot.params["id"];
    this.filterActivityForm.ActivityTypeId = null;
    this.filterActivityForm.ActivityStatusId = null;
    this.filterActivityForm.Subject = null;
    this.filterActivityForm.StartDate = null;
    this.filterActivityForm.EntityTypeId = this.entityTypeId;
    this.filterActivityForm.EntityId = this.id;
    this.filterActivityForm.PageNumber = 1;
    this.filterActivityForm.PageSize = 2000;
    this.filterActivityForm.IsOwner = false;
    this.filterActivityForm.ParticipantId = this.partid;
    this.refreshGrid(this.filterActivityForm);
    this.Role = localStorage.getItem("UserRole");

    this.getAccess().subscribe(x=>{
      this.refreshGrid(this.filterActivityForm);
   });
  }
  refreshGrid(filterActivityForm) {
    //alert('Update')

    this.activityService.getActivityByFilter(filterActivityForm).subscribe((data: any) => {
      this.activities = data.Data;
      this.dataSource = new MatTableDataSource(this.activities);
      this.dataSource.paginator = this.paginator;
    });
  }
  openViewPopup(activityId) {
    const modalRef = this.modalService.open(ViewActivityComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });

    var Activity = {
      activityId: activityId,
      inPopUpMode: true
    };

    modalRef.componentInstance.Activity = Activity;
  }

  // this function is used to close the popup in parent compenent ( Enquiry listing > Actions > Activity list )
  closePopup() {
    this.closePopupEvent.emit(true);
  }
  getAccess(): Observable<any> {
    let tracker = this.accessservice.GetAll();

    tracker.subscribe((data: any) => {
     // console.log(data);
      this.accesspermissionobj = data;
      this.canViewCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsVisible;
      
      if(!this.canViewCustomerEngagementReportOfOthers){
        
        this.partid = this.filterActivityForm.ParticipantId;
      }
    });

    return tracker;
  }
}
