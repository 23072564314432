import { Component, OnInit } from '@angular/core';
import { RegisterModel } from '../Model/RegisterModel';
import { CommonService } from '../Service/common.service';
import { RegisterService } from '../Service/register.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  register: RegisterModel = new RegisterModel();
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";

  constructor(private registerService: RegisterService,private commonService:CommonService) { }

  ngOnInit(): void {
    this.commonService.RefreshHeaderFun();
  }

  onSubmit() {
    let data1 = {
      FirstName: this.register.FirstName,
      LastName: this.register.LastName,
      Email: this.register.Email,
      Password: this.register.Password
    }


    // this.registerService.postRegister(this.register).subscribe(data => {
    //   let result = data;
    //   alert("Registered Successfully!");
    // });

  }
}
