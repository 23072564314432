import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Level1Model, ProductSeries, Station, VariantLevel3 } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-variant-level3',
  templateUrl: './add-variant-level3.component.html',
  styleUrls: ['./add-variant-level3.component.scss']
})
export class AddVariantLevel3Component implements OnInit {
  ProductSeriesVariant2Label: string = '';
  VariantLevel3obj: VariantLevel3 = new VariantLevel3();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductSeries: ProductSeries = new ProductSeries();
  ProductModel: any[] = [];
  //To Stored Api data of VariantLevel1 and VariantLevel2 
  Level2arr: Level1Model[] = [];
  Level3arr: Level1Model[] = []; // only for TC and TR
  Variant1Arr: any[] = [];

  //To bind current VariantLevel3 data
  Level3Currdata: any[] = [];
  PositionName: string="";
  @ViewChild('closebutton') closebutton;
 
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.VariantLevel3obj.ProductSeries = this.route.snapshot.params["Seriesid"];
    this.getAllProductSeries();
    this.GetProductModelById(this.VariantLevel3obj.ProductSeries);
  }

  /*******************************************************************************
   * Get data for Variant Level 3
   * ******************************************************************************/
  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.VariantLevel3obj.ProductSeries;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.VariantLevel3obj.SeriesName = data.SeriesName;
      this.VariantLevel3obj.ProductSeries = data.Id;
      this.VariantLevel3obj.IsLevel3DepOnLevel2 = data.IsLevel3DepOnLevel2;
      this.VariantLevel3obj.IsLevel2DepOnLevel1 = data.IsLevel2DepOnLevel1;
      this.VariantLevel3obj.Label_Variant1 = data.Label_Variant1;
      this.VariantLevel3obj.Label_Variant2 = data.Label_Variant2;
      this.VariantLevel3obj.Label_Variant3 = data.Label_Variant3;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetProductModelById(SeriesId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "SeriesId=" + SeriesId;
    this.CommonService.GetData("ProductModel", "GetProductModelById", queryString).subscribe((data: any) => {
      this.ProductModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetAllPosition(ModelId: number)//For TC and TC
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel3", "GetAllPosition", queryString).subscribe((data: any) => {
      this.Level3arr = data;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }

  GetVariant2ByModelId(ModelId: number)// other than TC and TR
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByModelId", queryString).subscribe((data: any) => {
      this.Level2arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetVariant1ByModelId(ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel1", "GetVariant1ByModelId", queryString).subscribe((data: any) => {
      this.Variant1Arr = data;
      

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  GetVariant2ByVariant1Id(Variant1: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "Variant1=" + Variant1;
    queryString += "&ProductSeriesId=" + this.VariantLevel3obj.ProductSeries;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByVariant1Id", queryString).subscribe((data: any) => {
      this.Level2arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  /*******************************************************************************
  * End get data for Variant Level 3
  * ******************************************************************************/

  /*******************************************************************************
  * Form validation method
  * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onModelSelected(event: any) {
    if (this.VariantLevel3obj.SeriesName == 'TC' || this.VariantLevel3obj.SeriesName == 'TR') {
      this.Level3Currdata = [];
      this.Level3arr = [];
      this.GetAllPosition(event.target.value);
    }
    else {
      this.VariantLevel3obj.Level2Id = null;
      this.VariantLevel3obj.Level1Id = null;
      this.Level2arr = [];
      if(this.VariantLevel3obj.IsLevel2DepOnLevel1 && this.VariantLevel3obj.IsLevel3DepOnLevel2)
      {
          this.GetVariant1ByModelId(event.target.value)
      }
      else if(this.VariantLevel3obj.IsLevel2DepOnLevel1 && !this.VariantLevel3obj.IsLevel3DepOnLevel2)
      {
        this.GetVariant1ByModelId(event.target.value)
      
      }
      else if(this.VariantLevel3obj.IsLevel3DepOnLevel2 && !this.VariantLevel3obj.IsLevel2DepOnLevel1){
        this.GetVariant2ByModelId(event.target.value);
      }
      
      
    }

  }

  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel3obj.Level3Price = 0;
    }
  }

  onVariant1Selected(event: any)
  {
    this.VariantLevel3obj.Level2Id = null;
    this.GetVariant2ByVariant1Id(event.target.value);
  }


  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this " + this.VariantLevel3obj.Label_Variant3 + " ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        for (var i = 0; i < this.Level3Currdata.length; i++) {
          var obj = { Id: null, Level1: this.Level3Currdata[i] }
          this.VariantLevel3obj.Level3.push(obj);
        }
        this.CommonService.PostData('ProductVariantLevel3', 'Add', this.VariantLevel3obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.VariantLevel3obj.Label_Variant3 + ' added successfully!');
            this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.VariantLevel3obj.Label_Variant3 + ' already exists!');
            this.VariantLevel3obj.Level3 = [];
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to ' + this.VariantLevel3obj.Label_Variant3 + '!');
            this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add ' + this.VariantLevel3obj.Label_Variant3 + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
        });
      }
    });

  }

  OpenPopupAddPosition()
  {
    this.PositionName = "";
  }

  AddPosition(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this Position ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        let queryString = "";
        queryString = "PositionName=" + this.PositionName;
        this.CommonService.PostData('ProductVariantLevel3', 'AddPosition', null, queryString).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('SpeedStep added successfully!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel3obj.ProductModel != null && this.VariantLevel3obj.ProductModel != undefined
              && this.VariantLevel3obj.ProductModel != 0)) {

              this.GetAllPosition(this.VariantLevel3obj.ProductModel);
            }
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Position already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add Position!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel3obj.ProductModel != null && this.VariantLevel3obj.ProductModel != undefined
              && this.VariantLevel3obj.ProductModel != 0)) {

              this.GetAllPosition(this.VariantLevel3obj.ProductModel);
            }
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add Position!');
          this.closebutton.nativeElement.click();
          this.showLoader = false;
          if ((this.VariantLevel3obj.ProductModel != null && this.VariantLevel3obj.ProductModel != undefined
            && this.VariantLevel3obj.ProductModel != 0)) {

            this.GetAllPosition(this.VariantLevel3obj.ProductModel);
          }
        });
      }
    });


  }

}
