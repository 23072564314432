import { Component, OnInit, Input } from '@angular/core';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AlertsModel } from 'src/app/Model/AlertsModel';
import { first } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { EnquiryStatus } from 'src/app/EnquiryStatus';
import { UserRoles } from 'src/app/UserRoles';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

@Component({
  selector: 'app-edit-view-alerts',
  templateUrl: './edit-view-alerts.component.html',
  styleUrls: ['./edit-view-alerts.component.scss']
})
export class EditViewAlertsComponent implements OnInit {
  isEditable: boolean = true;
  FormStatus: string = "View";
  AlertsObject: AlertsModel = new AlertsModel();
  charCode: any;
  UserRole: any;
  isAlertsEditable: boolean = false;
  @Input()
  showLoader: boolean = false;
  Roles: any[] = [];
  enquiryStatus = EnquiryStatus;
  AppRoles = UserRoles;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isAlertsEditable = AppGlobals.AlertsAccessEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.AlertsObject.Pk_Id = this.route.snapshot.params["id"];
    this.getAlertsIdDetailsById(this.AlertsObject.Pk_Id);

  }

  getAlertsIdDetailsById(AlertsId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "id=" + AlertsId;

    this.CommonService.GetData('Alerts', 'GetById', queryString).subscribe((data: any) => {
      this.AlertsObject = data;


      this.Roles = data.Roles;
      this.AlertsObject.First_Rem_ToWhomList.forEach(towhom => {
        if (towhom != null) {
          this.Roles.forEach(el => {
            if (el.Id == towhom) {
              this.AlertsObject.First_Rem_ToWhomList = el.Name;
            }
          });
        }
      });
      this.AlertsObject.Second_Rem_ToWhomList.forEach(towhom => {
        if (towhom != null) {
          this.Roles.forEach(el => {
            if (el.Id == towhom) {
              this.AlertsObject.Second_Rem_ToWhomList = el.Name;
            }
          });
        }
      });
      this.AlertsObject.Third_Rem_ToWhomList.forEach(towhom => {
        if (towhom != null) {
          this.Roles.forEach(el => {
            if (el.Id == towhom) {
              this.AlertsObject.Third_Rem_ToWhomList = el.Name;
            }
          });
        }
      });
      this.AlertsObject.Escalation_ToWhomList.forEach(towhom => {
        if (towhom != null) {
          this.Roles.forEach(el => {
            if (el.Id == towhom) {
              this.AlertsObject.Escalation_ToWhomList = el.Name;
            }
          });
        }
      });

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  // getAllRoles() {
  //   this.showLoader = true;
  //   this.CommonService.GetData('Account', 'GetAllRoles', null).subscribe((data: any) => {
  //     this.Roles = data;
  //     this.showLoader = false;
  //   }, error => {
  //     this.toastr.error("Failed to load data!");
  //     this.showLoader = false;
  //   });

  // }

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    this.titleService.setTitle("Edit Alerts - WEISS");
  }


  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  OnReminderDaysChange(frm: any, fieldname: string) {
    var First_Rem = Number(this.AlertsObject.First_Rem);
    var Second_Rem = Number(this.AlertsObject.Second_Rem);
    var Third_Rem = Number(this.AlertsObject.Third_Rem);
    var Escalation = Number(this.AlertsObject.Escalation);

    var firstfield_seterror: boolean = false;
    var secondfield_seterror: boolean = false;
    var thirdfield_seterror: boolean = false;
    var escalationfield_seterror: boolean = false;

    if (First_Rem > 0) {

      if (First_Rem >= Second_Rem || First_Rem >= Third_Rem || First_Rem >= Escalation) {
        firstfield_seterror = true;
      }

      if (firstfield_seterror == true) {
        frm.controls['first_rem_nm'].setErrors({ RemValidation: true });
      }
      else {
        frm.controls['first_rem_nm'].setErrors(null);
      }
    }

    if (Second_Rem > 0) {

      if (First_Rem >= Second_Rem || Second_Rem >= Third_Rem || Second_Rem >= Escalation) {
        secondfield_seterror = true;
      }

      if (secondfield_seterror == true) {
        frm.controls['second_rem_nm'].setErrors({ RemValidation: true });
      }
      else {
        frm.controls['second_rem_nm'].setErrors(null);
      }
    }

    if (Third_Rem > 0) {

      if (First_Rem >= Third_Rem || Second_Rem >= Third_Rem ||Third_Rem >= Escalation) {
        thirdfield_seterror = true;
      }

      if (thirdfield_seterror == true) {
        frm.controls['third_rem_nm'].setErrors({ RemValidation: true });
      }
      else {
        frm.controls['third_rem_nm'].setErrors(null);
      }
    }

    if (Escalation > 0) {

      if (Escalation <= First_Rem || Escalation <= Second_Rem || Escalation <= Third_Rem) {
        escalationfield_seterror = true;
      }
      if (escalationfield_seterror == true) {
        frm.controls['escalation'].setErrors({ RemValidation: true });
      }
      else {
        frm.controls['escalation'].setErrors(null);
      }
    }

  }
  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this alerts?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader = true;
        this.CommonService.PostData('Alerts', 'Update', this.AlertsObject, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Alert updated successfully!');
            this.router.navigate(['/Reminders-config']);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Alert already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update alerts!');
            this.router.navigate(['/Reminders-config']);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update alerts!');
          this.showLoader = false;
          this.router.navigate(['/Reminders-config']);
        });
      }
    });
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isAlertsEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isAlertsManagement')[0]?.IsEditable;
      
    });
 
  }


}
