import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-orderbacklog-supplychain-chart',
  templateUrl: './orderbacklog-supplychain-chart.component.html',
  styleUrls: ['./orderbacklog-supplychain-chart.component.scss']
})
export class OrderbacklogSupplychainChartComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  drpdnOption: string = "Amount";

  @Input() Year: number;
  @Input() region: number;
  @Input() currency: number;
  @Input() filterChanged: boolean;
  constructor(private toastr: ToastrService, private commonService: CommonService) { }

  ngOnInit() {
   // am4core.options.onlyShowOnViewport = true;
    this.QuotationOrdIntake();

  }
  ngOnChanges(): void {
    if (this.filterChanged) this.QuotationOrdIntake();
  }

  OnSelectChange() {
    this.QuotationOrdIntake();
  }

  QuotationOrdIntake() {
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&regionid=" + this.region;
    queryString += "&currency=" + this.currency;
    this.commonService.GetData("Dashboard", "OrderBacklogChart", queryString).subscribe((data: any) => {
      let chart = am4core.create("OrderBacklogSupplyChainChart", am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      // chart.cursor = new am4charts.XYCursor();
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;
      chart.exporting.menu = new am4core.ExportMenu();
      let options = chart.exporting.getFormatOptions("pdf");
      options.addURL = false;
      chart.exporting.setFormatOptions("pdf", options);
      chart.exporting.filePrefix = "OrderBacklog";
      /**************Custom Export**************** */
      chart.exporting.menu.items = [
        {
          "label": "...",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                // { "type": "svg", "label": "SVG" },
                { "type": "pdf", "label": "PDF" }
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "xlsx", "label": "XLSX" },
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }
      ];
      let CurrencyLabel = "";
      if (this.currency == 1) CurrencyLabel = "(MUSD)";
      if (this.currency == 2) CurrencyLabel = "(MEURO)";
      if (this.currency == 3) CurrencyLabel = "(MINR)";

      chart.exporting.dataFields = {
        "category": "Month",
        "value": "OrderBacklog" + CurrencyLabel,
      }
      /**************End Custom Export**************** */

      let title = chart.titles.create();
      title.text = "Order Backlog";
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;
      chart.data = data;
      /* Create axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;

      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.00" ? "0" : text;
        return val;
      });
      /**************Scrolling Start End Point**************** */
      chart.events.on("beforedatavalidated", function(ev) {
        categoryAxis.start = 0;
        categoryAxis.end = 0.5;
        valueAxis.start = 0;
        valueAxis.end = 1;
        });
        /**************End Scrolling Start End Point**************** */
      if (this.drpdnOption == 'Units') {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        if (this.currency == 1) valueAxis.title.text = "MUSD";
        if (this.currency == 2) valueAxis.title.text = "MEURO";
        if (this.currency == 3) valueAxis.title.text = "MINR";
      //  chart.numberFormatter.numberFormat = "0.00";
      }
      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;

      valueAxis.events.on("ready", function (ev) {
        ev.target.min = ev.target.min;
        ev.target.max = ev.target.max;
        if (ev.target.max < 0.1) valueAxis.extraMax = 10;
        if (ev.target.max > 0.1 && ev.target.max < 1) valueAxis.extraMax = 5;
        if (ev.target.max > 1) valueAxis.extraMax = 0.2;
      })
      valueAxis.calculateTotals = true;
      //#region "ColumnSeries1"
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.name = "Order Backlog";
      columnSeries.dataFields.valueY = "value";
      columnSeries.dataFields.categoryX = "category";
      columnSeries.columns.template.tooltipText = "[#fff font-size: 13px]{name} in {category}: [/][#fff font-size: 13px]{value}[/] [#fff]{additional}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.fill = am4core.color("#6794dc");
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      colLabel.label.text = "{valueY}";
      colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;
      //#endregion
      chart.legend = new am4charts.Legend();
      this.showLoader = false;
    }, error => {
      this.showLoader = false;

    });

  }

}
