import { Component, OnInit, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-product-model',
  templateUrl: './add-product-model.component.html',
  styleUrls: ['./add-product-model.component.scss']
})
export class AddProductModelComponent implements OnInit {

  ProductModelobj: ProductModel = new ProductModel();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductSeries: any[] = [];
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.getAllProductSeries();
    this.ProductModelobj.ProductSeries = this.route.snapshot.params["id"];

  }

  /*******************************************************************************
   * Get data for add product model
   * ******************************************************************************/
  getAllProductSeries() {
    this.showLoader = true;
    this.CommonService.GetData("ProductSeries", "GetAll", null).subscribe((data: any) => {
      this.ProductSeries = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  /*******************************************************************************
   * End get data for add product model
   * ******************************************************************************/

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this product model?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;
        this.CommonService.PostData('ProductModel', 'Add', this.ProductModelobj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Product model added successfully!');
            this.router.navigate(['/ProductModel', this.ProductModelobj.ProductSeries]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product model already exists!');
            this.showLoader = false;
          }         
          else {
            this.toastr.error('Failed to add product model!');
            this.router.navigate(['/ProductModel', this.ProductModelobj.ProductSeries]);
            this.showLoader = false;
          }
        }, error => {
          this.toastr.error('Failed to add product model!');
          this.showLoader = false;
          this.router.navigate(['/ProductModel', this.ProductModelobj.ProductSeries]);
        });
      }
    });
  }

}
