import { Component, AfterViewInit, OnDestroy, DoCheck, Input } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { Subscription } from 'rxjs';
import { EngagementReportService } from 'src/app/Service/engagement-report.service';



@Component({
  selector: 'app-report-by-activity-type-status',
  templateUrl: './report-by-activity-type-status.component.html',
  styleUrls: ['./report-by-activity-type-status.component.scss']
})
export class ReportByActivityTypeStatusComponent implements AfterViewInit, DoCheck, OnDestroy {

  constructor(private engagementReport: EngagementReportService) { }

  subscription: Subscription;
  private chart: am4charts.XYChart;

  @Input() date: { from: any, to: any };

  prevDateFrom: any;
  prevDateTo: any;

  ngAfterViewInit(): void {
    let filters = {
      reportTypeId: "REP_BY_ACT_Status_Type",
    }
    this.getData(filters);
  }

  ngDoCheck(): void {
    if(this.date.from !== this.prevDateFrom || this.date.to !== this.prevDateTo) {

      let filters = {
        reportTypeId: "REP_BY_ACT_Status_Type",
        StartDate: this.date.from,
        EndDate: this.date.to,
      }

      this.prevDateFrom = this.date.from;
      this.prevDateTo = this.date.to;
      this.getData(filters);
    }
  }

  getData(filters) {
    this.engagementReport.getReportData(filters).subscribe(
      data => this.showChart(data),
      err => console.log(err)
    );
  }

  showChart(data: any) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("Activity-Type-Status-Chart", am4charts.XYChart);

    // transforming and providing the chart data
    chart.data = this.engagementReport.transformChartData(
      'ActivityTypeDesc',
      'ActivityCount',
      'ActivityStatusDesc',
      data
    );

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "ActivityTypeDesc";
  
    categoryAxis.title.text = "Type";    
    categoryAxis.title.fontSize = "16px";
    categoryAxis.title.fontWeight = "bold";
    categoryAxis.title.margin(10, 10, 10, 10);
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Count";
    valueAxis.title.fontSize = "16px";
    valueAxis.title.fontWeight = "bold";


    let seriesNames = this.engagementReport.getAllSeriesName('ActivityStatusDesc', data);
  
    seriesNames.forEach((seriesname, index) => {
      let seriescolor = this.engagementReport.seriesColors[index]
      createSeries(seriesname, seriescolor)
    })
    
    // create series for each series name
    function createSeries(field: any, color: any) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = field;
      series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
      series.columns.template.width = am4core.percent(80);

      series.columns.template.fill = color;
      series.columns.template.stroke = am4core.color(color);

      series.dataFields.valueY = field;
      series.dataFields.categoryX = "ActivityTypeDesc";
    }


    chart.legend = new am4charts.Legend();

    this.chart = chart;
  }
  
  ngOnDestroy(): void {
    if(this.subscription) this.subscription.unsubscribe();
    if(this.chart) this.chart.dispose();
  }
}
