<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">

                            <div class="col-lg-3" style="margin-top:13px;">
                                <select class="form-control " id="heading" name="heading"
                                    [(ngModel)]="singleFieldComponent.RoutingParam" (change)="MasterChange()">
                                    <option value="" selected disabled>
                                        Select
                                        Master
                                    </option>
                                    <optgroup label="Customer">
                                        <!-- <option *ngFor="let item of configurationArray" value="{{item}}">
                                            {{item}}
                                        </option> -->
                                        <option value="CustomerCategory">Customer Category</option>
                                        <option value="CustomerClassification">Customer Classification</option>
                                        <option value="TypeofCustomer">Type of Customer</option>
                                        <option value="CustomerGroup">Customer Group</option>
                                    </optgroup>
                                    <optgroup label="Employee Role">
                                        <option value="RoleManagement">Role Management</option>
                                    </optgroup>
                                    <optgroup label="Enquiry">
                                        <option value="EnquirySource">Enquiry Source</option>
                                        <option value="SalesPrice">Sales Price Discount</option>
                                        <option value="Application">Application</option>
                                        <option value="IndustrySegment">Industry Segment</option>
                                        <option value="SubSegment">Sub Segment</option>
                                        <option value="MDSOption">MDS Option</option>
                                    </optgroup>
                                    <optgroup label="Enquiry Closure">
                                        <option value="StandardReasonforWinningLosing">Standard Reason for
                                            Won/Lost</option>
                                        <!-- <option value="TechnicalDiscardReason">Technical Discard Reason</option>
                                        <option value="EnquiryCloseReason">Enquiry Close Reasons</option> -->
                                        
                                    </optgroup>
                                    <optgroup label="Lead">
                                        <option value="LeadCloseReason">Lead Close Reasons</option>
                                     </optgroup>
                                     <optgroup label="Other">
                                        <option value="Country">Country</option>
                                        <option value="Currency">Currency</option>
                                        <option value="Region">Region</option>
                                        
                                        <option value="EstimatedProductValue">Estimated Product Value</option>
                                        <!-- <option value="LoginPageImage" >Login Image Master</option> -->
           
                                    </optgroup>
                                    <optgroup label="Terms & Conditions">
                                        <option value="TermsConditions">Terms & Conditions</option>
                                        <option value="TermsConditions-NonStandard">Terms & Conditions-Non Standard
                                        </option>
                                    </optgroup>

                                   
                               
                                </select>

                            </div>
                        </div>
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{singleFieldComponent.Heading}} Listing
                                    </h3>
                                </div>
                            </div>
                            <div class="m-portlet__head-tools" *ngIf="singleFieldComponent.Heading !='Currency'">
                                <div class="m-portlet__head-tools" *ngIf="singleFieldComponent.Heading !='Currency'">
                                    <ul class="m-portlet__nav">
                                        <li class="m-portlet__nav-item" *ngIf="singleFieldComponent.RoutingParam!='LoginPageImage'">
                                            <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                [hidden]="!isConfigureEditable"
                                                [routerLink]="['/AddConfiguration', singleFieldComponent?.RoutingParam]"
                                                style="white-space: normal;word-wrap: break-word;">{{singleFieldComponent.ButtonText}}</a>
                                        </li>

                                        <li class="m-portlet__nav-item" *ngIf="singleFieldComponent.RoutingParam=='LoginPageImage'">
                                            <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                [hidden]="!isConfigureEditable"
                                                [routerLink]="['/AddLoginImage']"
                                                style="white-space: normal;word-wrap: break-word;">Add Image</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="table-responsive">
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 right">&nbsp;</div>
                                        <div class="col-sm-12 col-md-6 right">
                                            <mat-form-field style="width: 50%;" class="pull-right">

                                                <mat-label>Search</mat-label>
                                                <input matInput (keyup)="SearchParam($event)" name="searchtext"
                                                    [(ngModel)]="SearchText" #searchtext="ngModel"
                                                    (keypress)="AvoidSpace($event)" maxlength="50" placeholder="Search"
                                                    #input>
                                            </mat-form-field>

                                        </div>

                                    </div>
                                    <div class="mat-elevation-z8">

                                        <table mat-table [dataSource]="dataSource" matSort
                                            (matSortChange)="sortData($event)"
                                            class="table table-striped- table-bordered table-hover dataTable no-footer"
                                            role="grid">
                                            <ng-container matColumnDef="Name">
                                                <div *ngIf="!singleFieldComponent.isCompanynamerequired">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        {{singleFieldComponent.TableHeader1}} </th>
                                                    <td *matCellDef="let row"> {{row.Name}} </td>
                                                </div>
                                                <!-- ------For Customer Special Discount ------- -->

                                                <div *ngIf="singleFieldComponent.isCompanynamerequired">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        {{singleFieldComponent.TableHeader1}} </th>
                                                    <td *matCellDef="let row of dataSource"> {{row.Companyname}}
                                                    </td>

                                                </div>

                                            </ng-container>

                                            <ng-container matColumnDef="Value">
                                                <div *ngIf="!singleFieldComponent.isExtraFields">
                                                    <th [hidden]="!(singleFieldComponent.isDoubleField)" mat-header-cell
                                                        *matHeaderCellDef mat-sort-header>
                                                        {{singleFieldComponent.TableHeader2}} </th>
                                                    <td [hidden]="!(singleFieldComponent.isDoubleField)" mat-cell
                                                        *matCellDef="let row"
                                                        [ngStyle]="{'text-align':(singleFieldComponent.RoutingParam === 'Currency') ? 'right' : 'left' }">
                                                        {{row.Value}} </td>
                                                </div>

                                                <!-- ------------------------For sales Price --------------------------------->

                                                <div *ngIf="singleFieldComponent.isExtraFields">

                                                    <th [hidden]="!(singleFieldComponent.isDoubleField)" mat-header-cell
                                                        *matHeaderCellDef mat-sort-header>
                                                        {{singleFieldComponent.TableHeader2}} </th>
                                                    <td [hidden]="!(singleFieldComponent.isDoubleField)"
                                                        *matCellDef="let row"> {{row.DiscountFrom}} - {{row.DiscountTo}}
                                                    </td>

                                                </div>

                                            </ng-container>

                                            <!--For Application Master-->
                                            <ng-container matColumnDef="Application" *ngIf="someCondition">
                                                <th [hidden]="!(singleFieldComponent.isTripleField)" mat-header-cell
                                                    *matHeaderCellDef mat-sort-header>
                                                    {{singleFieldComponent.TableHeader3}} </th>
                                                <td [hidden]="!(singleFieldComponent.isTripleField)" mat-cell
                                                    *matCellDef="let row"
                                                    [ngStyle]="{'text-align':(singleFieldComponent.RoutingParam === 'Currency') ? 'right' : 'left' }">
                                                    {{row.Application}} </td>
                                            </ng-container>

                                            <!--For ReasonforWinningLosing Master-->
                                            <ng-container matColumnDef="ReasonCategory"  *ngIf="isReasonCategoryVisible">
                                                <th [hidden]="!(singleFieldComponent.isTripleField)" mat-header-cell
                                                    *matHeaderCellDef mat-sort-header>
                                                    {{singleFieldComponent.TableHeader3}} </th>
                                                <td [hidden]="!(singleFieldComponent.isTripleField)" mat-cell
                                                    *matCellDef="let row"
                                                    [ngStyle]="{'text-align':(singleFieldComponent.RoutingParam === 'Currency') ? 'right' : 'left' }">
                                                    {{row.ReasonCategory}} </td>
                                            </ng-container>
                                              
                                            <ng-container matColumnDef="Action">

                                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                                <div *ngIf="!singleFieldComponent.isCompanynamerequired ">

                                                    <td mat-cell *matCellDef="let row">
                                                        <div>

                                                            <a mat-icon-button color="accent"
                                                                [hidden]="row.Name=='Customer Frame Agreement'"
                                                                [routerLink]="['/EditViewConfiguration',row?.Id,singleFieldComponent?.RoutingParam]"
                                                                routerLinkActive="active" title="View">
                                                                <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp;

                                                            <mat-slide-toggle [hidden]="!isConfigureEditable"
                                                                [hidden]="row.Name=='Customer Frame Agreement'"
                                                                class="example-margin" [checked]="row.Status"
                                                                [disabled]="disabled"
                                                                (change)="UpdateStatusById(row.Id,row.Status)">
                                                            </mat-slide-toggle>&nbsp;
                                                            <span [hidden]="row.Name=='Customer Frame Agreement'">
                                                                {{row.Status==true ? 'Active': 'Inactive'}}</span>

                                                        </div>
                                                    </td>

                                                </div>
                                                <div
                                                    *ngIf="singleFieldComponent.isCompanynamerequired && singleFieldComponent.isDoubleField">
                                                    <td mat-cell *matCellDef="let row">
                                                        <a mat-icon-button color="accent"
                                                            [routerLink]="['/EditViewContractFrameAgreement',row?.FK_CustomerId]"
                                                            routerLinkActive="active" title="View">
                                                            <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
                                                        </a>&nbsp;&nbsp;&nbsp;&nbsp;

                                                        <mat-slide-toggle [hidden]="!isConfigureEditable"
                                                            class="example-margin" [checked]="row.Status"
                                                            [disabled]="disabled"
                                                            (change)="UpdateStatusById(row.FK_CustomerId,row.Status)">
                                                        </mat-slide-toggle>&nbsp;
                                                        {{row.Status==true ? 'Active': 'Inactive'}}
                                                    </td>
                                                </div>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                        </table>


                                        <div *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
                                            class="text-center">No records found</div>

                                        <mat-paginator [(length)]="length" [(pageIndex)]="pageIndex"
                                            [(pageSize)]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                                            (page)="pageEvent($event)">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>