import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SingleFieldComponent, SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss']
})
export class LeadDetailsComponent implements OnInit {

  displayedColumns: string[] = ['CustomerName', 'CustomerAddress', 'Region', 'LeadStatus','EnquiryNumber','CustomerCode','Action'];
  dataSource: MatTableDataSource<SingleFieldModel>;
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  Role: any;
  UserRoles: any;
  disabled: boolean = false;
  isImageEditable: boolean = false;
  /*************************************************************************
         Server side Pagination purpose
     *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  /*************************************************************************/
  constructor(
    private toastr: ToastrService,
    private Service: CommonService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }
  @Input()
  showLoader: boolean = false;
  data: any[] = [];

  ngOnInit(): void {
    this.singleFieldObj.Id = this.route.snapshot.params["id"];
    this.Role = localStorage.getItem("UserRole");
    this.GetAllLeadDetails();    
  }

  GetAllLeadDetails() {   
    this.showLoader = true;
    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.Service.GetData('Lead', 'GetAllPagination', queryString).subscribe((data: any) => {
       this.data = data.Data;
       this.length = data.Total;

      this.dataSource = new MatTableDataSource(this.data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllLeadDetails();
    }
  }
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllLeadDetails();
  }
  pageEvent(event: any) {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllLeadDetails();
  }

  UpdateLeadById(Lead_Id: number, CurrentStatus: boolean) {
    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this lead?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Lead_Id;

          this.Service.PostData('Lead', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Lead deactivated successfully!");
            }
            else {
              this.toastr.info("Failed to deactivate lead!");
            }
            this.GetAllLeadDetails();
          }, error => {
            this.toastr.error("Failed to deactivate lead!");
            this.GetAllLeadDetails();
            this.showLoader = false;
          });
        }
        else {
          this.GetAllLeadDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this lead?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Lead_Id;

          this.Service.PostData('Lead', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Lead activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate lead!");
            }
            this.GetAllLeadDetails();
          }, error => {
            this.toastr.error("Failed to activate lead!");
            this.GetAllLeadDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllLeadDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }
  }
}
