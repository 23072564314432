import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Activity } from 'src/app/Model/ActivityModel';
import { EngagementReportService } from 'src/app/Service/engagement-report.service';
import { ViewActivityComponent } from '../../customer-engagement/activity/view-activity/view-activity.component';

@Component({
  selector: 'app-show-pending-activity',
  templateUrl: './show-pending-activity.component.html',
  styleUrls: ['./show-pending-activity.component.scss']
})
export class ShowPendingActivityComponent implements OnInit {


  @Input() reportTypeId: string;
  @Input() title: string

  noDataComment: string;

  activities: Activity[];
  activityData: MatTableDataSource<any>;
  displayedColumns: any = ['ActivityType', 'Subject', 'DueDate', 'ActivityStatus', 'DelayedBy', 'Actions'];
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(private engagementReportService: EngagementReportService,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.refreshGrid();
    if(this.reportTypeId === 'rep_my_pending_activities') this.noDataComment = "There are no pending activities";
    if(this.reportTypeId === 'REP_MY_TODAYS_ACTIVITIES') this.noDataComment = "There are no activities for today";
  }

  refreshGrid() {
    this.engagementReportService.getReportData({ reportTypeId: this.reportTypeId, IsOwner: true}).subscribe((data: any) => {
      this.activities = data;
      this.activityData = new MatTableDataSource(data);
      this.activityData.paginator = this.paginator;
     });
  }

  openViewPopup(activityId) {
    const modalRef = this.modalService.open(ViewActivityComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });

    var Activity = {
      activityId: activityId,
      inPopUpMode: true
    };

    modalRef.componentInstance.Activity = Activity;
  }

}
