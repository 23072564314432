<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-12 table-responsive">
                    <!-- Region Graph -->
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Customer Data
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div>
                                <div class="form-group m-form__group row">
                                    <div [ngClass]="{ 'col-sm-4': reportFilterObj.ReportValueType!='amount',
                                    'col-sm-3': reportFilterObj.ReportValueType=='amount' }">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Year:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" #fiterElement class="form-control "
                                                id="supplychaintype" name="supplychaintype"
                                                [(ngModel)]="reportFilterObj.Year" (change)="onYearChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option *ngFor="let item of YearList" value="{{item}}">
                                                    {{item}}
                                                </option>
                                            </select><br />
                                        </div>
                                    </div>
                                    <div [ngClass]="{ 'col-sm-4': reportFilterObj.ReportValueType!='amount',
                                    'col-sm-3': reportFilterObj.ReportValueType=='amount' }">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Enquiry Order Type:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" class="form-control " id="supplychaintype"
                                                name="supplychaintype" [(ngModel)]="reportFilterObj.EnqType"
                                                (change)="onEnqTypeChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option value="quotation">Quotation</option>
                                                <option value="orderwin">Order Won</option>
                                                <option value="orderloss">Order Lost</option>
                                            </select><br />
                                        </div>
                                    </div>
                                    <div [ngClass]="{ 'col-sm-4': reportFilterObj.ReportValueType!='amount',
                                    'col-sm-3': reportFilterObj.ReportValueType=='amount' }">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Report Type:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" class="form-control " id="supplychaintype"
                                                name="supplychaintype" [(ngModel)]="reportFilterObj.ReportValueType"
                                                (change)="onReportValueTypeChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option value="units">No's</option>
                                                <option value="amount">Amount</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3" *ngIf="reportFilterObj.ReportValueType=='amount'">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Currency:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" class="form-control " id="currencytype"
                                                name="currencytype" [(ngModel)]="reportFilterObj.curency"
                                                (change)="onReportValueTypeChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option *ngFor="let item of CurrencyList;let i = index;"
                                                    value="{{item.Id}}">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Customer Classification -->
                            <div class="row">
                                <div class="col-sm-6">
                                    <mat-checkbox name="dep2" #dep2="ngModel" [(ngModel)]="isAllCustomerClasses"
                                        (change)="AllCustomerClassChange($event)">
                                        All Customer Classification
                                    </mat-checkbox>
                                </div>
                                <!-- <div class="col-sm-6">
                                    <mat-checkbox name="dep2" #dep2="ngModel" [(ngModel)]="isAllCustomerTypes"
                                        (change)="AllCustomerTypeChange($event)">
                                        All Customer Types
                                    </mat-checkbox>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                        <b>* Click on slice to see data Customer Classification wise.</b></span>
                                </div>
                                <!-- <div class="col-sm-6">
                                    <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                        <b>* Click on slice to see data Customer Type wise.</b></span>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div id="CustomerClassWisePieChart"
                                        style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                    <br />
                                    <div id="cclegenddiv"></div>
                                </div>
                                <!-- <div class="col-sm-6">
                                    <div id="CustomerTypeWisePieChart"
                                        style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                    <br />
                                    <div id="ctlegenddiv"></div>
                                </div> -->
                            </div>

                            <!-- Customer Types -->

                            <br>
                            <br>
                            <hr>
                            <br>
                            <br>
                            <div class="row">
                                
                                <div class="col-sm-6">
                                    <mat-checkbox name="dep2" #dep2="ngModel" [(ngModel)]="isAllCustomerTypes"
                                        (change)="AllCustomerTypeChange($event)">
                                        All Customer Types
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="row">
                               
                                <div class="col-sm-6">
                                    <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                        <b>* Click on slice to see data Customer Type wise.</b></span>
                                </div>
                            </div>
                            <div class="row">
                                
                                <div class="col-sm-12">
                                    <div id="CustomerTypeWisePieChart"
                                        style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                    <br />
                                    <div id="ctlegenddiv"></div>
                                </div>
                            </div>
                            
                            <!-- <div>
                                <app-customer-details [Callfrom]="Callfrom"></app-customer-details>
                            </div> -->


                        </div>
                    </div>
                    <div>
                        <!-- <div class="row">
                            <div class="col-sm-12 table-responsive">
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Customer Listing (Customer Classification:
                                                    {{selectedCClass == undefined ? 'All': selectedCClass.category}} & Customer
                                                    Type: {{selectedCType == undefined ? 'All': selectedCType.category}} )
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="m-portlet__head-tools">
                                            
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        
                                        <div class="table-responsive">
                                            <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div class="row">
                                                  <div class="col-sm-12 col-md-6"></div>
                                                  <div class="col-sm-12 col-md-6 right">
                                                    <mat-form-field style="width: 50%;" class="pull-right">
                              
                                                      <mat-label>Search</mat-label>
                                                      <input matInput (keyup)="SearchParam($event)" (keypress)="AvoidSpace($event)" maxlength="50"
                                                        placeholder="Search" #input>
                                                    </mat-form-field>
                              
                                                  </div>
                              
                                                </div>
                              
                                                <div class="mat-elevation-z8">
                                                  <table mat-table [dataSource]="dataSourceCustomer" matSort (matSortChange)="sortData($event)"
                                                    class="table table-striped- table-bordered table-hover dataTable no-footer" role="grid">
                              
                                                    <ng-container matColumnDef="CustomerCode">
                                                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Code </th>
                                                      <td mat-cell *matCellDef="let row"> {{row.CustomerCode}} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="CustomerName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                                                        <td mat-cell *matCellDef="let row"> {{row.CustomerName}} </td>
                                                      </ng-container>

                                                      <ng-container matColumnDef="EnquiryCount">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry Count </th>
                                                        <td mat-cell *matCellDef="let row"> 
                                                            <a (click)="openEnquiryPopup(row)"><u>{{row.EnquiryCount}}</u></a>
                                                     </td>
                                                      </ng-container>

                                                      <ng-container matColumnDef="QuotationAmount">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Quotation Value ({{CurrencyLabel=="No's"?"INR":CurrencyLabel}}) </th>
                                                        <td mat-cell *matCellDef="let row"> {{row.QuotationAmount}} </td>
                                                      </ng-container>

                                                      <ng-container matColumnDef="OrderIntakeAmount">
                                                        <th [hidden]="reportFilterObj.EnqType != 'orderwin'" mat-header-cell *matHeaderCellDef mat-sort-header> Order Intake Value ({{CurrencyLabel=="No's"?"INR":CurrencyLabel}}) </th>
                                                        <td [hidden]="reportFilterObj.EnqType != 'orderwin'" mat-cell *matCellDef="let row"> {{row.OrderIntakeAmount}} </td>
                                                      </ng-container>
                              
                                                   
                              
                              
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                              
                              
                                                  </table>
                                                  <div
                                                    *ngIf="dataSourceCustomer != undefined && (dataSourceCustomer.filteredData == null || dataSourceCustomer.filteredData?.length === 0)"
                                                    class="text-center">No records found</div>
                              
                                                  <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                                                    [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent($event)">
                                                  </mat-paginator>
                                                </div>
                                              </div>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!--Region Graph -->
                        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                            <div class="m-portlet__head">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Region Data
                                            (Customer Classification:
                                            {{selectedCClass == undefined ? 'All': selectedCClass.category}} & Customer
                                            Type: {{selectedCType == undefined ? 'All': selectedCType.category}} )
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="m-portlet__body">
                                <div id="RegionWisePieChart"
                                    style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;"></div>
                                <br />
                                <div id="regionlegenddiv"></div>
                            </div>
                        </div>
                        <!--Product Graph -->
                        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                            <div class="m-portlet__head" id="productlinewise">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Product Line Data
                                            (Customer Classification:
                                            {{selectedCClass == undefined ? 'All': selectedCClass.category}} & Customer
                                            Type: {{selectedCType == undefined ? 'All': selectedCType.category}} )
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="m-portlet__body">
                                <div class="row">
                                    <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                        <b>* Click on slice to see data in details.</b></span>
                                </div>
                                <!-- <div class="row"> -->
                                <div id="ProductWisePieChart"
                                    style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;">
                                </div>
                                <br />
                                <div id="pllegenddiv"></div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <!--Product Graph -->
                        <div *ngIf="isProductLineSelected"
                            class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                            <div class="m-portlet__head" id="productlinewise">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Product Series Data
                                            (Customer Classification:
                                            {{selectedCClass == undefined ? 'All': selectedCClass.category}} & Customer
                                            Type: {{selectedCType == undefined ? 'All': selectedCType.category}} )
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="m-portlet__body">
                                <div class="row">
                                    <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                        <b>* Click on bar to see data in details.</b></span>
                                </div>
                                <div class="row">

                                    <div
                                        [ngClass]="{ 'col-sm-12 table-responsive': !isProductSeriesSelected, 
                                                    'col-sm-6 table-responsive': isProductSeriesSelected && !isProductModelSelected,
                                                    'col-sm-4 table-responsive': isProductSeriesSelected && isProductModelSelected }">
                                        <div id="ProductSeriesBarChart"
                                            style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                        </div>
                                    </div>
                                    <div *ngIf="isProductSeriesSelected" [ngClass]="{ 'col-sm-6 table-responsive': isProductSeriesSelected && !isProductModelSelected,
                                    'col-sm-4 table-responsive': isProductSeriesSelected && isProductModelSelected }">
                                        <div id="ProductModelBarChart"
                                            style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                        </div>
                                    </div>
                                    <div *ngIf="isProductSeriesSelected && isProductModelSelected"
                                        class="col-sm-4 table-responsive">
                                        <div id="ProductStationBarChart"
                                            style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                      <!-- Segment Graph -->
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Segment (Customer Classification:
                                        {{selectedCClass == undefined ? 'All': selectedCClass.category}} & Customer
                                        Type: {{selectedCType == undefined ? 'All': selectedCType.category}} )
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="row">
                                <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                    <b>* Click on slice to see segment data in details.</b></span>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div id="SegmentWisePieChart"
                                        style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                    <br />
                                    <div id="segmentlegenddiv"></div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                     <!-- SubSegment Graph -->
                     <div class="m-portlet m-portlet--success m-portlet--head-solid-bg" *ngIf="isSegmentSelected">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Sub Segment (Customer Classification:
                                        {{selectedCClass == undefined ? 'All': selectedCClass.category}} & Customer
                                        Type: {{selectedCType == undefined ? 'All': selectedCType.category}} )
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            
                            <div class="row">
                                
                                <div *ngIf="isSegmentSelected" class="col-sm-12 table-responsive">
                                    <div id="SubSegmentWiseBarChart"
                                        style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                    <br />
                                    <!-- <div id="ssglegenddiv"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>



                    
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<!-- <app-charts-modal></app-charts-modal> -->
