<div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform9" #myform9="ngForm"
        appFocusInvalidInput novalidate>
        <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
            <div class="multisteps-form__content">
                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">

                                <h3 *ngIf="EnquiryObj.EnquiryDetails.Status == enquiryStatus.MaterialReadyForDispatch 
                            || EnquiryObj.EnquiryDetails.Status == enquiryStatus.AccountsConfirmInvoiceNo"
                                    class="m-portlet__head-text">
                                    Confirm Invoice
                                </h3>
                            </div>

                        </div>
                        <div class="m-portlet__head-tools">
                            <button *ngIf="this.UserRole == roleOfAdmin"
                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                (click)="SendBack()">Send Back</button>
                        </div>
                    </div>
                    <div class="markedFields">
                        <span style="margin-left:10px;">* Marked fields are
                            mandatory</span>
                    </div>
                    <div class="m-portlet__body">

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Customer
                                Name:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.CustomerName}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Segment:</label>
                            <div class="col-lg-4" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.IndustrySegment}}
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Type of
                                Customer:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.CustomerType}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Region:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.Region}}
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Terms and
                                Conditions:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnquiryDetails.RFQTermsAndCondition}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Quotation
                                Amount:</label>
                            <div class="col-lg-4" style="padding-top:9px;">
                                ₹
                                {{EnquiryObj.EnquiryDetails.FinalAmount |
                                indianCurrency}}/-
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Contact
                                Person:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnquiryDetails.KindAttention}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">View
                                Quotation:</label>
                            <div class="col-lg-3 pt-1 filenamemobile">
                                <a target="_blank"
                                    href="{{QuotationDownloadPath}}{{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}">
                                    {{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}
                                </a>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">PO
                                Value:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                ₹ {{EnquiryObj.EnquiryDetails.POValue |
                                indianCurrency}}/-
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">PO
                                Attachment:</label>
                            <div class="col-lg-3 filenamemobile" style="padding-top: 9px;">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.PO_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.PO_filename}}</a>
                            </div>

                        </div>

                        <div class="form-group m-form__group row" *ngIf="EnquiryObj.EnquiryDetails.Status == enquiryStatus.OrderConfirmedbyCustomerServiceSendSO 
                    || EnquiryObj.EnquiryDetails.Status == enquiryStatus.OANoGenerated ||
                    EnquiryObj.EnquiryDetails.Status == enquiryStatus.MaterialReadyForDispatch 
                    || EnquiryObj.EnquiryDetails.Status == enquiryStatus.AccountsConfirmInvoiceNo ||
                        EnquiryObj.EnquiryDetails.Status == enquiryStatus.SupplyChainConfirmedDeliveryDate">

                            <label class="col-lg-2 col-form-label">
                                BOM (Bill Of Materials):</label>
                            <div class="col-lg-3 pt-2 filenamemobile">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.BOM_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.BOM_filename}}</a>
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Order
                                Acknowledge No.:<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-3">
                                <input type="text" class="form-control m-input" placeholder="OA Number" id="oano"
                                    name="oano" readonly [(ngModel)]="EnquiryObj.EnquiryDetails.OrdAcknowledgeNumber"
                                    #oano="ngModel">
                            </div>


                        </div>
                        <div class="form-group m-form__group row" *ngIf="EnquiryObj.EnquiryDetails.Status == enquiryStatus.MaterialReadyForDispatch 
                    || EnquiryObj.EnquiryDetails.Status == enquiryStatus.AccountsConfirmInvoiceNo">
                            <label class="col-lg-2 col-form-label">Invoice
                                No.:<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-3">
                                <input type="text" class="form-control m-input" placeholder="Invoice Number"
                                    id="invoiceno" name="invoiceno" maxlength="20"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.InvoiceNumber" #invoiceno="ngModel"
                                    [ngClass]="{ 'is-invalid':
                                            ((myform9.submitted && invoiceno.invalid) || (invoiceno?.touched && invoiceno.invalid)) }"
                                    [required]="role==AppRoles.FinanceAccounts">
                                <div *ngIf="(myform9.submitted && invoiceno.invalid) || (invoiceno?.touched && invoiceno.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="invoiceno.errors.required">
                                        Invoice Number is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Attach
                                Invoice:<span style="color:#f4516c;">&nbsp;*</span></label>

                            <div class="col-lg-4 pt-1">
                                <div class="file btn btn-sm btn-warning">
                                    Attach Invoice
                                    <input type="file" id="accfile" name="accfile" (change)="editFileDetails($event,0)"
                                        required />
                                </div>
                                <br />
                                <span class="fileext">
                                    {{AppGlobals.supportedFileFormatsForOtherMultipleAttachments}}</span>
                                <div *ngIf="myform9.submitted &&  EnquiryObj.EnquiryDetails.InvoiceAttachment == undefined"
                                    style="color:#f4516c;font-size: 80%;">
                                    Invoice attachment is
                                    required
                                </div>
                                <div class="filenamemobile">
                                    {{EnquiryObj.EnquiryDetails.InvoiceAttachment
                                    != undefined &&
                                    EnquiryObj.EnquiryDetails.InvoiceAttachment[0]
                                    != undefined ?
                                    EnquiryObj.EnquiryDetails.InvoiceAttachment[0].name:
                                    ""}}
                                    <i class="flaticon-delete" style="color:#f4516c" *ngIf="isInvoiceAttachment"
                                        (click)="removeInvoiceAttachments()" title="Remove"><br></i>
                                </div>

                                <div style="margin-top: 10px;">
                                    <span class="filenamemobile">
                                        <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.Invoice_filepath}}"
                                            target="_blank">{{EnquiryObj.EnquiryDetails.Invoice_filename}}</a>

                                    </span>
                                </div>
                            </div>

                        </div>

                        <!-- New Fields -->
                        <div class="form-group m-form__group row" *ngIf="EnquiryObj.EnquiryDetails.Status == enquiryStatus.MaterialReadyForDispatch 
|| EnquiryObj.EnquiryDetails.Status == enquiryStatus.AccountsConfirmInvoiceNo">

                            <label class="col-lg-2 col-form-label">
                                Invoice Amount:<span style="color:#f4516c;">&nbsp;*</span>
                            </label>
                            <div class="col-lg-3">
                                     <input type="number" class="form-control m-input" placeholder="Invoice Amount"
                                        id="invoiceamount" name="invoiceamount"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.Invoice_amount" #invoiceamount="ngModel"
                                        required
                                        [ngClass]="{ 'is-invalid': (myform9.submitted && invoiceamount.invalid) || (invoiceamount.touched && invoiceamount.invalid) }">
                                <!-- </div> -->
                                <div *ngIf="(myform9.submitted && invoiceamount.invalid) || (invoiceamount.touched && invoiceamount.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="invoiceamount.errors?.required">
                                        Invoice Amount is required.
                                    </div>
                                </div>
                            </div>

                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Invoice Date:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-3">
                                <input type="date" class="form-control m-input" id="invoicedate" name="invoicedate"
                                    [(ngModel)]="EnquiryObj.EnquiryDetails.InvoiceSubmittedOn" #invoicedate="ngModel"
                                    [ngClass]="{ 'is-invalid': ((myform9.submitted && invoicedate.invalid) || (invoicedate.touched && invoicedate.invalid)) }"
                                    required>
                                <div *ngIf="(myform9.submitted && invoicedate.invalid) || (invoicedate.touched && invoicedate.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="invoicedate.errors?.required">
                                        Invoice Date is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">

                            <label class="col-lg-2 col-form-label">
                                Attach Commercial Document(s):</label>
                            <div class="col-lg-4 pt-1">
                                <div class="file btn btn-sm btn-warning">
                                    Upload
                                    <input type="file" id="quotefiles" name="quotefiles" #myInput
                                        (change)="getMultipleFileDetails($event)" multiple />
                                </div>
                                <br /><span class="fileext">
                                    {{AppGlobals.supportedFileFormatsForOtherMultipleAttachments}}</span>
                                <span>
                                    <ul>
                                        <ng-container
                                            *ngFor="let item of EnquiryObj.EnquiryDetails.QuoteGenAttachments;let i=index;">
                                            <li class="filenamemobile">
                                                {{item.name}}&nbsp;&nbsp;<i class="flaticon-delete"
                                                    style="color:#f4516c" (click)="removeQuoteGenAttachments(i)"
                                                    title="Remove"></i>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </span>
                            </div>

                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Comments:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-10">
                                <textarea class="form-control m-input" id="rfqcomments" name="rfqcomments" rows="3"
                                    maxlength="1500" [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                    (keyup)="limitTextOnKeyUpDown1($event)" (keypress)="limitTextOnKeyUpDown1($event)"
                                    #rfqcomments="ngModel"
                                    [ngClass]="{ 'is-invalid': (myform9.submitted && rfqcomments.invalid) || (rfqcomments?.touched && rfqcomments.invalid) }"
                                    required>
                        </textarea>
                                <span> Limit {{maxLimit}} characters,
                                    {{remLimit}} characters
                                    remaining.</span>
                                <div *ngIf="(myform9.submitted && rfqcomments.invalid) || (rfqcomments?.touched && rfqcomments.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="rfqcomments.errors.required">
                                        Comment is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="button-row text-right mt-4" style="margin:15px;">

                    <div class="m-portlet__head-caption">
                        <span *ngFor="let item of workflowActionList;let i = index;">
                            <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                type="{{item.workflowActionType}}"
                                (click)="OnWorkflowAction(myform9,item.workflowActionId)">
                                {{item.workflowActionName}}
                            </button> &nbsp; &nbsp;
                        </span>
                        &nbsp;&nbsp;
                        <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                            (click)="backtoList()" routerLinkActive="active">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>