<div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
    <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform9" #myform9="ngForm"
        appFocusInvalidInput novalidate>
        <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
            <div class="multisteps-form__content">
                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    Order Acknowledgement
                                </h3>

                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <button *ngIf="this.UserRole == roleOfAdmin "
                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                (click)="SendBack()">Send Back</button>
                        </div>
                    </div>
                    <div class="markedFields">
                        <span style="margin-left:10px;">* Marked fields are
                            mandatory</span>
                    </div>
                    <div class="m-portlet__body">

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Customer
                                Name:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.CustomerName}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Segment:</label>
                            <div class="col-lg-4" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.IndustrySegment}}
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Type of
                                Customer:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.CustomerType}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Region:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnqCustomerDetails.Region}}
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Terms and
                                Conditions:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnquiryDetails.RFQTermsAndCondition}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Quotation
                                Amount:</label>
                            <div class="col-lg-4" style="padding-top:9px;">
                                ₹
                                {{EnquiryObj.EnquiryDetails.FinalAmount |
                                indianCurrency}}/-
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Contact
                                Person:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                {{EnquiryObj.EnquiryDetails.KindAttention}}
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">View
                                Quotation:</label>
                            <div class="col-lg-3 pt-1 filenamemobile">
                                <a target="_blank"
                                    href="{{QuotationDownloadPath}}{{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}">
                                    {{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}
                                </a>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">PO
                                Value:</label>
                            <div class="col-lg-3" style="padding-top:9px;">
                                ₹ {{EnquiryObj.EnquiryDetails.POValue |
                                indianCurrency}}/-
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">PO
                                Attachment:</label>
                            <div class="col-lg-3 filenamemobile" style="padding-top: 9px;">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.PO_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.PO_filename}}</a>
                            </div>

                        </div>

                        <div class="form-group m-form__group row">

                            <label class="col-lg-2 col-form-label">
                                BOM (Bill Of Materials):</label>
                            <div class="col-lg-3 pt-2 filenamemobile">
                                <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.BOM_filepath}}"
                                    target="_blank">{{EnquiryObj.EnquiryDetails.BOM_filename}}</a>
                            </div>
                            <div class="offset-1"></div>
                            <label class="col-lg-2 col-form-label">Order
                                Acknowledge No.:<span style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-3">
                                <input type="text" class="form-control m-input" placeholder="OA Number" id="oano"
                                    name="oano" readonly [(ngModel)]="EnquiryObj.EnquiryDetails.OrdAcknowledgeNumber"
                                    #oano="ngModel">
                            </div>


                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">View OA
                                Document:</label>
                            <div class="col-lg-3 pt-2 filenamemobile">
                                <a (click)="DownloadPDFfromServer(EnquiryObj.EnquiryDetails.OA_filename)"
                                    [routerLink]="">
                                    {{EnquiryObj.EnquiryDetails.OA_filename}}
                                </a>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Comments:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-10">
                                <textarea class="form-control m-input" id="rfqcomments" name="rfqcomments" rows="3"
                                    maxlength="1500" [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                    (keyup)="limitTextOnKeyUpDown1($event)" (keypress)="limitTextOnKeyUpDown1($event)"
                                    #rfqcomments="ngModel"
                                    [ngClass]="{ 'is-invalid': (myform9.submitted && rfqcomments.invalid) || (rfqcomments?.touched && rfqcomments.invalid) }"
                                    required>
                                    </textarea>
                                <span> Limit {{maxLimit}} characters,
                                    {{remLimit}} characters
                                    remaining.</span>
                                <div *ngIf="(myform9.submitted && rfqcomments.invalid) || (rfqcomments?.touched && rfqcomments.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="rfqcomments.errors.required">
                                        Comment is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="button-row text-right mt-4" style="margin:15px;">
                    <div class="m-portlet__head-caption">
                        <span *ngFor="let item of workflowActionList;let i = index;">
                            <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                type="{{item.workflowActionType}}"
                                (click)="OnWorkflowAction(myform9,item.workflowActionId)">
                                {{item.workflowActionName}}
                            </button> &nbsp; &nbsp;
                        </span>
                       
                        <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                            (click)="backtoList()" routerLinkActive="active">Cancel</button>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>