
import { Component, OnInit, OnChanges, SimpleChange, SimpleChanges, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
//import { RegisterService } from './Service/register.service';
//import { RegisterModel } from './Model/RegisterModel';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
//import { CommonService } from './Service/common.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { AppGlobals } from './app.global';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { RegisterModel } from './Model/RegisterModel';
import { RegisterService } from './Service/register.service';
import { CommonService } from './Service/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  codec = new HttpUrlEncodingCodec;

  login: RegisterModel = new RegisterModel();
  forgotPassword: RegisterModel = new RegisterModel();
  hide = true;
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  BackroundImagePath: string = "";
  ImgaeExtensions: any[] = ["png", "jpg", "jpeg"];
  @Input()
  showLoader: boolean = false;
  previousUrl: string = null;
  currentUrl: string = null;

  @Input()
  isHeaderShowed: boolean = false;
  loginImagePath: string;
  constructor(private registerService: RegisterService, private commonService: CommonService, private router: Router, private toastr: ToastrService, public dialog: MatDialog, private activatedRoute: ActivatedRoute,
    private titleService: Title, private route: ActivatedRoute,public cd: ChangeDetectorRef,) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        this.titleService.setTitle("WEISS")
      });
    });

  }

  ngOnInit() {
   
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    //  this.redirectTo(this.currentUrl);
      this.commonService.setPreviousUrl(this.previousUrl);

      //this.route.url.subscribe((url: UrlSegment[])=> {
        this.redirectTo(this.currentUrl);
     // });
    });      

  
  }

  redirectTo(currentUrl){
    this.BackroundImagePath ="assets/images/login-bg.jpg";

    this.commonService.RefreshHeaderFun();
    //this.showLoader = true;
    this.isHeaderShowed = false;
    let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    let userToken = localStorage.getItem("userToken");
    this.getLoginImageDetails();
    
    let navigateTo = "";
    let currentPath = currentUrl;//this.route.url.value[0].path;

    // just the fragment of the current route. i.e. CurrentRoute
    if( currentPath == "" || currentPath == null || currentPath.toLowerCase() =="/login"){
      navigateTo = '/Dashboard';
    }else{
      navigateTo = currentPath;
      
    }

    //ensure navigateTo is decodeded first before we use router to navigate
    navigateTo = decodeURI(navigateTo);

    if (loginDetails != null && userToken != null) {
      this.isHeaderShowed = true;
      this.router.navigate([navigateTo]);
      this.commonService.RefreshHeaderFun();
    }else{
      this.isHeaderShowed = false;
      this.router.navigate(['/Login']);
    }

  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  /*  localStorage.removeItem('userToken');
        localStorage.clear();
        this.location.replaceState('Login');
        this.showLoader = false;
        window.location.reload();
  */

  onActivate(event) {
    window.scroll(0, 0);
  }

  onSubmit() {
    this.showLoader = true;
    this.registerService.login(this.login).subscribe(data => {
      let result = data;
      localStorage.setItem("loginDetails", JSON.stringify(result));
      localStorage.setItem("userToken", result.access_token);
      localStorage.setItem("UserRole", data.role);
      localStorage.setItem("UserID", data.userId);
      localStorage.setItem("WorkFlowRoleWiseScreenAccess", data.WorkFlowRoleWiseScreenAccess);
     
      this.toastr.success('Login successfully!');
      this.showLoader = false;

      let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
      let userToken = localStorage.getItem("userToken");
      if (loginDetails != null && userToken != null) {

        let ActivatedURL = localStorage.getItem("ActivatedURL");
        localStorage.removeItem("ActivatedURL");
        if (ActivatedURL != null && ActivatedURL != "" && ActivatedURL != undefined) {
          let EnquiryId = ActivatedURL.split("/").pop();
          this.router.navigate(['/EditViewEnquiry', EnquiryId]);
        }
        else {         
          this.router.navigate(['/Dashboard']);        
          this.cd.detectChanges(); 
         }
        this.isHeaderShowed = true;
      }
      else {
        this.isHeaderShowed = false;
        this.router.navigate(['/Login']);
      }

    }, error => {
      this.toastr.error(error.error.error_description);
      this.showLoader = false;
    });

  }


  openDialog() {
    // Opening the dialog component
    const dialogWithForm = this.dialog.open(ForgotPasswordComponent, {
      width: '450px',
      height: '200px'
    });

    // When user close the dialog
    dialogWithForm.afterClosed().subscribe(result => {
      if (result) {
        this.forgotPassword.Username = result.Username;
        this.showLoader = true;
        this.registerService.forgotPassword(this.forgotPassword.Username).subscribe((data: any) => {
          var res = data;
          if (res.ResponseCode == 1) {
            this.toastr.success('Password reset successfully! Please check your mail account!');
          }
          if (res.ResponseCode == 0) {
            this.toastr.warning('Please enter valid Username!');
          }
          this.showLoader = false;
        }, error => {
          this.toastr.error("Please enter valid Username!");
          this.showLoader = false;
        });

      }
    });
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any) {
    var screenWidth = window.innerWidth;
    if (screenWidth < 1024)
      if (event.target.className == "m-menu__link-text mngMaster" || event.target.className == "m-menu__hor-arrow la la-angle-down mngMasterArrow") {
        let x = document.getElementById("manageMasterDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("enquiryDiv").style.display = "none";
          document.getElementById("reportDiv").style.display = "none";
          document.getElementById("activityDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";

        } else {
          x.style.display = "none";
        }
      }
      else if (event.target.className == "m-menu__link-text ProductLineMain" || event.target.className == "m-menu__hor-arrow la la-angle-down ProductLineArrow") {
        let x = document.getElementById("ProductLineMainDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("enquiryDiv").style.display = "none";
          document.getElementById("reportDiv").style.display = "none";
          document.getElementById("manageMasterDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }
      }
      else if (event.target.className == "m-menu__link-text enqDrDn" || event.target.className == "m-menu__hor-arrow la la-angle-down enqDrDnArrow") {
        let x = document.getElementById("enquiryDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("manageMasterDiv").style.display = "none";
          document.getElementById("reportDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }
      }

      else if (event.target.className == "m-menu__link-text activityqDrDn" || event.target.className == "m-menu__hor-arrow la la-angle-down activityDrDnArrow") {
 
        let x = document.getElementById("activityDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("manageMasterDiv").style.display = "none";
          document.getElementById("reportDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";
          document.getElementById("enquiryDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }
      }
      else if (event.target.className == "m-menu__link-text reportDrDn" || event.target.className == "m-menu__hor-arrow la la-angle-down reportDrDnArrow") {
        let x = document.getElementById("reportDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("enquiryDiv").style.display = "none";
          document.getElementById("manageMasterDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }

      }
      else if (event.target.className == "flaticon-more" || event.target.className == "m-topbar__username" || event.target.className == "m-dropdown__dropoff") {
        let x = document.getElementById("m_header_topbar");
        if (event.target.className == "m-topbar__username" || event.target.className == "m-dropdown__dropoff") {
          x.style.display = "table";
        }

      }
      else if (event.target.className == "m-menu__link-text reportDrDn toggle" || event.target.className == "m-menu__link-text enqDrDn toggle") {
        let x = document.getElementById("m_header_menu");
        {
          x.style.display = "none";
          if (event.target.className == "m-menu__link-text reportDrDn toggle") {
            document.getElementById("reportDiv").style.display = "none";

          }
          else if (event.target.className == "m-menu__link-text enqDrDn toggle") {
            document.getElementById("enquiryDiv").style.display = "none";

          }

        }
      }
      else if (event.target.className == "form-control ng-untouched ng-pristine ng-valid" || event.target.className == "m-content" ||
        event.target.className == "col-sm-6" || event.target.className == "toggle" || event.target.className == "m-menu__link-text mngMaster toggle"
        || event.target.className == "m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block") {
        let x = document.getElementById("m_header_menu");
        if (event.target.className == "toggle" || event.target.className == "m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block") {
          x.style.display === "block"
          document.getElementById("ProductLineDiv").style.display = "none";
          document.getElementById("seriesDiv").style.display = "none";
          // document.getElementById("modelDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";
        }
        else {
          if (x.style.display === "block") {
            x.style.display = "none";
            document.getElementById("ProductLineDiv").style.display = "none";
            document.getElementById("seriesDiv").style.display = "none";
            document.getElementById("manageMasterDiv").style.display = "none";
            document.getElementById("ProductLineMainDiv").style.display = "none";
          }
        }
      }
      else if (event.target.className == "m-menu__link-text PL" || event.target.className == "m-menu__link-text PS" ||
        event.target.className == "m-menu__link-text mngMaster SN" || event.target.className == "m-menu__link m-menu__toggle SN") {
        if (event.target.className == "m-menu__link-text PL") {
          let x = document.getElementById("ProductLineDiv");
          if (x.style.display === "none" || x.style.display === "") {
            document.getElementById("ProductLineDiv").style.display = "block";
          }
          else {
            document.getElementById("ProductLineDiv").style.display = "none";
          }
        }
        else if (event.target.className == "m-menu__link-text PS") {
          let x = document.getElementById("seriesDiv");
          x.style.display === "block"
          if (x.style.display === "none" || x.style.display === "") {
            document.getElementById("seriesDiv").style.display = "block";
          }
          else {
            document.getElementById("seriesDiv").style.display = "none";
          }
        }
        else if (event.target.className == "m-menu__link-text mngMaster SN") {
          // let x = document.getElementById("modelDiv");
          // if(x.style.display === "block")
          // {
          //   document.getElementById("modelDiv").style.display = "none";
          // }
          // if (x.style.display === "none" || x.style.display === "") {
          //   document.getElementById("modelDiv").style.display = "block";
          // }

        }

      }

      else {
        let x = document.getElementById("manageMasterDiv");
        if (x != null && x.style.display === "block") {
          x.style.display = "none";
        }
        let y = document.getElementById("enquiryDiv");
        if (y != null && y.style.display === "block") {
          y.style.display = "none";
        }
        let z = document.getElementById("reportDiv");
        if (z != null && z.style.display === "block") {
          z.style.display = "none";
        }
        let p = document.getElementById("m_header_topbar");
        if (p != null && p.style.display === "table") {
          p.style.display = "none";
        }

        let q = document.getElementById("m_header_menu");
        if (q != null && q.style.display === "block") {
          q.style.display = "none";
        }
        let r = document.getElementById("ProductLineDiv");
        if (r != null && r.style.display === "block") {
          r.style.display = "none";
        }
        let s = document.getElementById("seriesDiv");
        if (s != null && s.style.display === "block") {
          s.style.display = "none";
        }
        // let t = document.getElementById("modelDiv");
        // if (t != null && t.style.display === "block") {
        //   t.style.display = "none";
        // }

      }

    else {
      if (event.target.className == "m-menu__link-text mngMaster" || event.target.className == "m-menu__hor-arrow la la-angle-down mngMasterArrow") {
        let x = document.getElementById("manageMasterDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("enquiryDiv").style.display = "none";
          document.getElementById("reportDiv").style.display = "none";
          document.getElementById("activityDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }


      }
      else if (event.target.className == "m-menu__link-text ProductLineMain" || event.target.className == "m-menu__hor-arrow la la-angle-down ProductLineArrow") {
        let x = document.getElementById("ProductLineMainDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("enquiryDiv").style.display = "none";
          document.getElementById("reportDiv").style.display = "none";
          document.getElementById("activityDiv").style.display = "none";
          document.getElementById("manageMasterDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }
      }

      else if (event.target.className == "m-menu__link-text enqDrDn" || event.target.className == "m-menu__hor-arrow la la-angle-down enqDrDnArrow") {
        let x = document.getElementById("enquiryDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("manageMasterDiv").style.display = "none";
          document.getElementById("reportDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";
          document.getElementById("activityDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }
      }
      else if (event.target.className == "m-menu__link-text activityqDrDn" || event.target.className == "m-menu__hor-arrow la la-angle-down activityDrDnArrow") {

        let x = document.getElementById("activityDiv");
        
        if (x.style.display === "none" || x.style.display === "") {
        
        x.style.display = "block";
        document.getElementById("manageMasterDiv").style.display = "none";
        document.getElementById("manageMasterDiv").style.display = "none";
        
        
        document.getElementById("reportDiv").style.display = "none";
        document.getElementById("ProductLineMainDiv").style.display = "none";
        document.getElementById("enquiryDiv").style.display = "none";
        
        } else {
        
        x.style.display = "none";
        
        }
      }
      else if (event.target.className == "m-menu__link-text reportDrDn" || event.target.className == "m-menu__hor-arrow la la-angle-down reportDrDnArrow") {
        let x = document.getElementById("reportDiv");
        if (x.style.display === "none" || x.style.display === "") {
          x.style.display = "block";
          document.getElementById("enquiryDiv").style.display = "none";
          document.getElementById("manageMasterDiv").style.display = "none";
          document.getElementById("ProductLineMainDiv").style.display = "none";
          document.getElementById("activityDiv").style.display = "none";
        } else {
          x.style.display = "none";
        }
      }
      else {
        let x = document.getElementById("manageMasterDiv");
        if (x != null && x.style.display === "block") {
          x.style.display = "none";
        }
        let y = document.getElementById("enquiryDiv");
        if (y != null && y.style.display === "block") {
          y.style.display = "none";
        }
        let z = document.getElementById("reportDiv");
        if (z != null && z.style.display === "block") {
          z.style.display = "none";
        }
        let p = document.getElementById("ProductLineMainDiv");
        if (p != null && p.style.display === "block") {
          p.style.display = "none";
        }
        let q = document.getElementById("activityDiv");
        if (q != null && q.style.display === "block") {
          q.style.display = "none";
        }
      }
    }
  }
  getLoginImageDetails() 
  {   
    this.showLoader=true; 
    this.registerService.getImageName('LoginPageImage', 'GetActiveImage').subscribe((data: any) => 
    { 
      if(data!=null)
      {
        this.BackroundImagePath = AppGlobals.baseAppUrl + "uploads/LoginPageImage/" + encodeURIComponent(data.ImageName); 
      }
      else{
        this.BackroundImagePath ="assets/images/login-bg.jpg";
      }    
      this.showLoader=false; 
    }, error => 
    { 
     // this.toastr.error("Failed to load data!"); 
      this.BackroundImagePath ="assets/images/login-bg.jpg";
      this.showLoader = false; 
    }); }
} 
