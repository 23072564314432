import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivityService } from '../../../../Service/activity.service';
import { Activity, ActivityType, ActivityStatus, ActivityPurpose, ActivityOutcome, ActivityStatusByType, ActivityLinkageEntityType, EntityRecord, User } from '../../../../Model/ActivityModel';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import '@angular/material/prebuilt-themes/indigo-pink.css';
import { DatePipe } from '@angular/common';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';
import { Observable, iif } from 'rxjs';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

@Component({
  selector: 'app-list-activity',
  templateUrl: './list-activity.component.html',
  styleUrls: ['./list-activity.component.scss']
})
export class ListActivityComponent implements OnInit {
  isbulkEdit: boolean;
  pageNumber: number = 1;
  pageIndex: number = this.pageNumber - 1;
  pageSize: number = 5;
  length: number;
  activities: Activity[];
  filteredActivities: Activity[];
  activityTypeMaster: ActivityType[] = [];
  activityStatusByTypeMaster: ActivityStatusByType[] = [];
  allEntityTypesMaster: ActivityLinkageEntityType[] = [];
  filterActivityForm: any = {};
  entityTypeFilterArray: any = [];
  allUserMaster: User[] = [];
  @Input()
  showLoader: boolean = false;

  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];
   canViewCustomerEngagementReportOfOthers:boolean=true;
  Role: any;
  partid: string = null;
  canEditCustomerEngagementReportOfOthers: boolean;

  constructor(private cd: ChangeDetectorRef ,private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private activityService: ActivityService,private accessservice: AccessPermissionService) { }

  ngOnInit(): void {

    this.filterActivityForm.ActivityTypeId = null;
    this.filterActivityForm.ActivityStatusId = null;
    this.filterActivityForm.Subject = null;
    this.filterActivityForm.StartDate = null;
    this.filterActivityForm.EntityTypeId = null;
    this.filterActivityForm.EntityId = null;
    this.filterActivityForm.PageNumber = 1;
    this.filterActivityForm.PageSize = 5;
    this.filterActivityForm.IsOwner = true;
    this.filterActivityForm.ParticipantId = localStorage.getItem('UserID');
    this.Role = localStorage.getItem("UserRole");
    this.partid = this.filterActivityForm.ParticipantId;

    this.GetActivityTypeMaster();
    this.GetEntityTypesMaster();
    this.getAllStatus();
    this.getallParticipant();
    this.getAccess().subscribe(x=>{
       this.refreshGrid(this.filterActivityForm);
    });

  }


  addRow() {

    let newActivity: Activity = new Activity();
    newActivity.canEditCustomerEngagementReportOfOthers=true;
    newActivity.InEditMode = true;
    this.filteredActivities.unshift(newActivity);
    this.cd.detectChanges();
  }


  bulkEdit() {
    if (this.isbulkEdit) {
      var newArr = this.filteredActivities.map(obj => {
        return { ...obj, InEditMode: true };
      });
    } else {

      var newArr = this.filteredActivities.map(obj => {
        return { ...obj, InEditMode: false };
      });
    }

    this.filteredActivities = newArr;
    this.checkAccess();
  }




  getallParticipant() {
    this.activityService.getallUserMaster().subscribe((data: User[]) => {

      this.allUserMaster = data;

    })
  }

  getAllStatus() {
    this.activityService.getActivityStatusMaster(null).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
    });
  }

  GetEntityTypesMaster() {
    this.showLoader = true;
    this.activityService.getallEntityTypesMaster().subscribe((data: ActivityLinkageEntityType[]) => {
      this.allEntityTypesMaster = data;
      this.showLoader = false;
    });
  }

  GetActivityTypeMaster() {
    this.activityService.getActivityTypeMaster().subscribe((data) => {
      this.activityTypeMaster = data;
    });
  }

  initialActivityToCompair(event: { activity: any, index: any }){

    // ***** When save/Add then compair and art specific index Save that activity****
 let reqBody = {"ActivityTypeId":null,"ActivityStatusId":null,"Subject":null,"StartDate":null,"EntityTypeId":null,"EntityId":null,"PageNumber":1,"PageSize":5,"IsOwner":true}
    this.activityService.getActivityByFilter(reqBody).subscribe((data: any) => {
     const newAddedActivityFromBulk = data.Data;

     if(event.activity.ActivityId){
      const A = newAddedActivityFromBulk.filter(((arg) => {
        return  arg.ActivityId == event.activity.ActivityId
      }));
      A[0]['InEditMode'] = false

      this.filteredActivities[event.index] = A[0];
      
    }else{

      this.filteredActivities[event.index] = newAddedActivityFromBulk[0]
    }
    this.checkAccess();
    });
  }

  refreshGrid(filterActivityForm) {
    this.activityService.getActivityByFilter(filterActivityForm).subscribe((data: any) => {
      this.activities = data.Data;
      this.filteredActivities = data.Data;
      this.checkAccess();
      this.length = data.Total;
    });
  }



  pageEvent(event) {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if(!this.canViewCustomerEngagementReportOfOthers){
      this.filterActivityForm.ParticipantId = localStorage.getItem('UserID');
    }
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    let reqBody = { ActivityTypeId: this.filterActivityForm.ActivityTypeId, ActivityStatusId:  this.filterActivityForm.ActivityStatusId,
      Subject: this.filterActivityForm.Subject, StartDate: this.filterActivityForm.StartDate,
      EntityTypeId: this.filterActivityForm.EntityTypeId, EntityId:  this.filterActivityForm.EntityId,
      PageNumber: this.pageNumber, PageSize: this.pageSize, IsOwner: this.filterActivityForm.IsOwner,
      ParticipantId: this.filterActivityForm.ParticipantId
    }

    this.activityService.getActivityByFilter(reqBody).subscribe((res: any) => {
      this.filteredActivities = res.Data;
      this.checkAccess();
      this.bulkEdit();
    })

  }

  onEntityTypeChange(EntityTypeId: any) {
    this.showLoader = true;
    this.activityService.getrelatedEntityRecords(EntityTypeId,'').subscribe((data: EntityRecord[]) => {
      this.filterActivityForm.EntityId = null;
      this.entityTypeFilterArray = data;
      this.showLoader = false;
    });
  }

  removeByIndex(index: any) {
    if ('ActivityId' in this.filteredActivities[index]) {
    } else {
      this.filteredActivities.splice(index, 1);
    }
  }

  removeFilter() {

    if(!this.canViewCustomerEngagementReportOfOthers){
      this.partid = localStorage.getItem('UserID');
    }
    let reqBody = { ActivityTypeId: null, ActivityStatusId: null, Subject: null, StartDate: null, EntityTypeId: null, EntityId: null, PageNumber: 1, PageSize: 5,
      IsOwner: false, ParticipantId: this.partid}
    this.EnquiryngbDateFromFiter = null;
    this.EnquiryngbDateToFiter = null;
    this.activityService.getActivityByFilter(reqBody).subscribe((res: any) => {
      this.filteredActivities = res.Data
      this.checkAccess();
      this.entityTypeFilterArray = [];
      this.length = res.Total;
      this.filterActivityForm = { ActivityTypeId: null, ActivityStatusId: null, Subject: null, StartDate: null, EntityTypeId: null, EntityId: null, PageNumber: 1, PageSize: 5, IsOwner: false, ParticipantId:this.partid }
      this.isbulkEdit = false;
      this.pageSize = 5;
    });
  }

  onSubmit(filterActivityForm: any) {

    
    if(!this.canViewCustomerEngagementReportOfOthers){
      this.filterActivityForm.ParticipantId = localStorage.getItem('UserID');
    }


    if (this.EnquiryngbDateFromFiter == undefined)  {
      filterActivityForm.StartDate = null;
      filterActivityForm.EndDate = null;
    } else {
      filterActivityForm.StartDate = this.convertDate(new NgbDate(this.EnquiryngbDateFromFiter.year, this.EnquiryngbDateFromFiter.month, this.EnquiryngbDateFromFiter.day))
      filterActivityForm.EndDate = this.convertDate(new NgbDate(this.EnquiryngbDateToFiter.year, this.EnquiryngbDateToFiter.month, this.EnquiryngbDateToFiter.day))
    }

    if(filterActivityForm.ActivityStatusId == "null"){
      filterActivityForm.ActivityStatusId = null
    }

    if(filterActivityForm.ActivityTypeId == "null"){
      filterActivityForm.ActivityTypeId = null
    }

    if(filterActivityForm.EntityTypeId == "null"){
      filterActivityForm.EntityTypeId = null
    }
    if(filterActivityForm.ParticipantId == "null"){
      filterActivityForm.ParticipantId = null
    }
    filterActivityForm.PageNumber = 1;
    filterActivityForm.PageSize = 5;
    this.activityService.getActivityByFilter(filterActivityForm).subscribe((res: any) => {
      this.filteredActivities = res.Data;
      this.checkAccess();
      this.length = res.Total;
      this.pageSize = 5;
      this.isbulkEdit = false;

    });

  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }




  onKeyDown(event: KeyboardEvent) {
    // Check if the pressed key is a space and if the input field is empty
    if (event.key === ' ' && event.target['value'].trim() === '') {
      event.preventDefault(); // prevent the space from being entered
    }
  }


  EnquiryngbDateFromFiter: NgbDate | null;
  EnquiryngbDateToFiter: NgbDate | null;
  hoveredDate: NgbDate | null = null;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  datePipe = new DatePipe('en-US');
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  onDateSelection(date: NgbDate) {
    if (!this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter) {
      this.EnquiryngbDateFromFiter = date;
    } else if (this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && date && date.after(this.EnquiryngbDateFromFiter)) {
      this.EnquiryngbDateToFiter = date;
    } else {
      this.EnquiryngbDateToFiter = null;
      this.EnquiryngbDateFromFiter = date;
    }
  }
  isRange(date: NgbDate) {
    return date.equals(this.EnquiryngbDateFromFiter) || (this.EnquiryngbDateToFiter && date.equals(this.EnquiryngbDateToFiter)) || this.isInside(date) || this.isHovered(date);
  }
  isHovered(date: NgbDate) {
    return this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && this.hoveredDate && date.after(this.EnquiryngbDateFromFiter) && date.before(this.hoveredDate);
  }
  isInside(date: NgbDate) {
    return this.EnquiryngbDateToFiter && date.after(this.EnquiryngbDateFromFiter) && date.before(this.EnquiryngbDateToFiter);
  }

  getAccess(): Observable<any> {
    let tracker = this.accessservice.GetAll();

    tracker.subscribe((data: any) => {
     // console.log(data);
      this.accesspermissionobj = data;
      this.canViewCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsVisible;
      this.canEditCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsEditable;

      if(!this.canViewCustomerEngagementReportOfOthers){

        this.partid = this.filterActivityForm.ParticipantId;
      }
    });

    return tracker;
  }

  OnFromDateFilterChange() {
    let EnquiryDateFromFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)
    let EnquiryDateToFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)
    if (EnquiryDateToFiter < EnquiryDateFromFiter) {
      this.EnquiryngbDateToFiter = this.EnquiryngbDateFromFiter;
    }
  }

  ConvertNgbDateToDate(ngbdate: any) {
    if (ngbdate != null) {
      return new Date(ngbdate.year, ngbdate.month - 1, ngbdate.day)
    }
  }


  OnToDateFilterChange() {
  }


  checkAccess()
  {
      this.filteredActivities.forEach(element => {
        const participantsIdArray = element.ParticipantsID.split(',');
        console.log(localStorage.getItem('UserID'));
        
        if((participantsIdArray.filter(x=>x==localStorage.getItem('UserID')).length>0 ||element.ActivityOwner==localStorage.getItem('UserID')))
        {
          element.canEditCustomerEngagementReportOfOthers=true;
          element.canViewCustomerEngagementReportOfOthers=true;
        }
        else
        {
          element.canEditCustomerEngagementReportOfOthers=this.canEditCustomerEngagementReportOfOthers;
          element.canViewCustomerEngagementReportOfOthers=this.canViewCustomerEngagementReportOfOthers;
        }
      });  
  }

}




