<div class="m-portlet m-portlet--head-solid-bg ">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    {{title}}
                </h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body">

        <table *ngIf="activities?.length" mat-table [dataSource]="activityData"
            class="table order-list table-striped table-bordered table-hover table-edit" role="grid">


            <ng-container matColumnDef="ActivityType">
                <th mat-header-cell *matHeaderCellDef>Activity Type</th>
                <td mat-cell *matCellDef="let item" [ngStyle]="item.ActivityId == null || '' ? {'display':'none'} : {}">
                    {{item.ActivityType}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Subject">
                <th mat-header-cell *matHeaderCellDef>Subject</th>
                <td mat-cell *matCellDef="let item"> {{item.Subject}} </td>
            </ng-container>

            <ng-container matColumnDef="DueDate">
                <th mat-header-cell *matHeaderCellDef>Due Date</th>
                <td mat-cell *matCellDef="let item"> {{item.PlannedDueDatetime | date}} </td>
            </ng-container>

            <ng-container matColumnDef="ActivityStatus">
                <th mat-header-cell *matHeaderCellDef>Activity Status</th>
                <td mat-cell *matCellDef="let item"> {{item.ActivityStatus}} </td>
            </ng-container>

            <ng-container matColumnDef="DelayedBy">
                <th mat-header-cell *matHeaderCellDef>Delayed By</th>
                <td mat-cell *matCellDef="let item"> {{item.DelayedDays < 0 ? 0: item.DelayedDays}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let item">
                    <button *ngIf="item.ActivityStatus !== 'Completed'" class="btn" style="width: 50px;" [routerLink]="['/Edit-Activity', item.ActivityId]">
                        <i title="Edit" class="fas fa-edit" style="color:#f4516c"></i>
                    </button>
                    <button class="btn" style="width: 50px;" (click)="openViewPopup(item.ActivityId)">
                        <i class="fa fa-eye fa-lg" title="View" style="color:#f4516c"></i>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let emprow; columns: displayedColumns;"></tr>


        </table>

        <div *ngIf="activities?.length <= 0">
            <h5 style="padding: 50px 20px;">{{noDataComment}}</h5>
        </div>

        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
    </div>

</div>