import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerModel } from 'src/app/Model/CustomerModel';
import { CommonService } from 'src/app/Service/common.service';
import { EngagementReportService } from 'src/app/Service/engagement-report.service';

@Component({
  selector: 'app-customer-engagement-details',
  templateUrl: './customer-engagement-details.component.html',
  styleUrls: ['./customer-engagement-details.component.scss']
})
export class CustomerEngagementDetailsComponent implements OnInit {
  
  constructor(private route: ActivatedRoute, private Service: CommonService, private engagementReportService: EngagementReportService, private toastr: ToastrService, private location: Location) { }
  
  showLoader: boolean;
  CustomerModel: CustomerModel = new CustomerModel();

  reportTypeId_RoleWise: string = '';
  
  customerId: any;

  customerEnquiries: any;
  roleWiseInfo: any;
  roleWiseInfoKeys: any;
  displayedColumns: string[] = ['EnquiryNo', 'EnquiryDate', 'QuotationNo', 'QuotationAmount', 'QuotationStatus', 'EngagementActivity', 'EngagementType', 'ActivityOutcome', 'ActivityOutcomeDescription', 'WeissAttendees', 'ActivityStatus', 'InitiatorName'];
  dataSource: MatTableDataSource<any>;

  @ViewChild('paginator') paginator: MatPaginator;

  ngOnInit(): void {
    this.customerId = this.route.snapshot.queryParams['CustId'];
    this.getCustomerDetailsByCustId();
    this.getCustomerEnquiryActivitiesByCustId();
    this.getRoleWiseInfoByCustId();
  }

  getCustomerDetailsByCustId() {
    this.showLoader = true

    let queryString = "";
    queryString = "id=" + this.customerId;

    this.Service.GetData('Customer', 'GetById', queryString).subscribe((data: any) => {
      this.CustomerModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getCustomerEnquiryActivitiesByCustId() {
    let body = {
      reportTypeId: 'REP_Cust_Enquiry_Activity_Engagement',
      entityId: this.customerId
    };

    this.engagementReportService.getReportData(body).subscribe((data: any) => {
      this.customerEnquiries = new MatTableDataSource(data);
      this.customerEnquiries.paginator = this.paginator;
    })
  }

  getRoleWiseInfoByCustId() {
    let body = {
      reportTypeId: 'REP_RoleWise_ActivityType_Count',
      entityTypeId: 1,
      entityId: this.customerId
    };

    this.engagementReportService.getReportData(body).subscribe((data: any) => {
      this.roleWiseInfo = data;
      if(this.roleWiseInfo.length) {
        this.roleWiseInfoKeys = Object.keys(this.roleWiseInfo[0]);
      }
     // console.log(this.roleWiseInfo);
     // console.log(this.roleWiseInfoKeys);
      
    })
  }


  gobackNavigate() {
    this.location.back();
  }

}