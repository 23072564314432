import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-enquiry-details-chart',
  templateUrl: './enquiry-details-chart.component.html',
  styleUrls: ['./enquiry-details-chart.component.scss']
})
export class EnquiryDetailsChartComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  drpdnOption: string = "Amount";

  @Input() Year: number;
  @Input() region: number;
  @Input() currency: number;
  @Input() filterChanged: boolean;
  SubmittedEnquiryCount: any;
  OrderConfirmationCount: any;
  RFQCount: any;
  OrderDeliveredCount: any;
  ProposeDeliveredCount: any;
  reportdata: reportmodel[] = Array<reportmodel>();
  cur: string;
  Labelcurr: string = "";
  // @Input() mode: string = "";
  // Chartidname: string = "";

  constructor(private toastr: ToastrService, private commonService: CommonService, private amcharts: AmChartsService
    ,private modalService: NgbModal,public modal: NgbActiveModal) 
    {
     
    }

  ngOnInit() {
    // if(this.mode == "view")
    // {
    //   this.Chartidname="enquiryDetailsChartView";
    // }else{
    //   this.Chartidname = "enquiryDetailsChart";
    // }
  //  am4core.options.onlyShowOnViewport = true;
    this.enquiryDetailsChart();
  }

  ngOnChanges(): void {
   // console.log('oninit', this.filterChanged);
    // if (!(this.IsOnInit && !this.filterChanged)) {
    //   this.enquiryDetailsChart();
    // } 

    if (this.filterChanged) {
      this.enquiryDetailsChart();
    }
  }

  OnSelectChange() {
    this.enquiryDetailsChart();
  }

  enquiryDetailsChart() {
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&regionid=" + this.region;
    queryString += "&currency=" + this.currency;
   // console.log('EnquiryDetailsChart called');
    this.commonService.GetData("Dashboard", "EnquiryDetailsChart", queryString).subscribe((data: any) => {
      this.reportdata = [];

      if (this.currency == 1) this.cur = "MUSD";
      if (this.currency == 2) this.cur = "MEURO";
      if (this.currency == 3) this.cur = "MINR";
      if (this.drpdnOption == 'Amount') {
        this.Labelcurr = "(" + this.cur + ")";
      }
      else { this.Labelcurr = "(No's)" }
      data.forEach(element => {
        // if (element.value==0) element.value=0.00001;
        var obj = new reportmodel();
        obj.Id = element.Id;
        obj.category = element.category;
        obj.actualvalue = element.value;
        obj.showvalue = 50;
        this.reportdata.push(obj);
      });
     
      var chart = this.amcharts.makeChart("enquiryDetailsChart", {
        "creditsPosition": "bottom-left",
        "type": "funnel",
        "theme": "none",
        "dataProvider": this.reportdata,
        "titleField": "category",
        "marginRight": 160,
        "hideLabelsPercent": 0,
        "precision": this.drpdnOption == 'Amount' ? 2 : 0,
        "marginLeft": 15,
        "FontWeight": "bold",
        "fontSize": 12,
        "labelPosition": "center",
        "funnelAlpha": 0.9,
        "valueField": "showvalue",
        "title": "Sales Funnel",
        "startX": 0,
        "neckWidth": "40%",
        "startAlpha": 0,
        "outlineThickness": 1,
        "neckHeight": "30%",
        "balloonText": this.drpdnOption == 'Amount' ? "[[category]]:<b> " + this.cur + " [[actualvalue]]</b>" : "[[category]]: <b> No. [[actualvalue]]</b>",
        "labelText": this.drpdnOption == 'Amount' ? "[[category]]: " + this.cur + " [[actualvalue]]" : "[[category]]: No. [[actualvalue]]",
        "responsive": {
          "enabled": true
        },
        "export": {
          "enabled": true,
          "pageOrigin": false,
          "fileName": "SalesFunnel",
          "exportTitles": true,
          "exportFields": [
            "category",
            "actualvalue"
          ],
          "columnNames": {
            "category": "Category",
            "actualvalue": "Value" + this.Labelcurr
          },
          "menu": [
            {
              "label": "<i class='fa fa-ellipsis-h' aria-hidden='true'></i>",
              "menu": [
                {
                  "format": "png",
                  "label": "PNG"
                },
                {
                  "format": "jpg",
                  "label": "JPG"
                },
                // {
                //   "format": "svg",
                //   "label": "SVG"
                // },
                {
                  "format": "pdf",
                  "label": "PDF"
                },
                { "format": "xlsx", "label": "XLSX" },
              ]
            }
          ]
        },
        

      });
      
      // chart.export.menu.items = [
      //   {
      //     "label":"...",
      //     "menu":[
      //       {
      //         "type":"png",
      //         "label":"PNG"
      //       }
      //     ]
      //   }
      // ]
    });

  }
  // OpenChartPopup()
  // {
  //   const modalRef = this.modalService.open(EnquiryDetailsChartComponent, { size: 'lg' });
  //   modalRef.componentInstance.Year = this.Year;
  //   modalRef.componentInstance.region = this.region;
  //   modalRef.componentInstance.currency = this.currency;
  //   modalRef.componentInstance.filterChanged = this.filterChanged;
  //   modalRef.componentInstance.mode = "view";

  // }
  // ClosePopup()
  // {
  //   this.modal.close();
  // }
}
export class reportmodel {
  Id: any;
  category: any;
  actualvalue; any;
  showvalue: any;
}

