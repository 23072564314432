import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/Service/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { string } from '@amcharts/amcharts4/core';
import { EnquiryExportToExcelModel, EnquiryGridFilterList, EnquiryGridModel, EnquiryModel, EnquiryPaginationGrid } from 'src/app/Model/EnquiryModel';
import { UserRoles } from 'src/app/UserRoles';
import { ActivityDetailsComponent } from '../activity-details/activity-details.component';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';
import { PassOnComponent } from '../pass-on/pass-on.component';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppGlobals } from 'src/app/app.global';
import { DatePipe } from '@angular/common';
import { AddActivityComponent } from '../../customer-engagement/activity/add-activity/add-activity.component';


@Component({
  selector: 'app-pending-enquiry',
  templateUrl: './pending-enquiry.component.html',
  styleUrls: ['./pending-enquiry.component.scss']
})
export class PendingEnquiryComponent implements OnInit {

  displayedColumns: string[] = ['EnquiryId', 'EnquiryDate', 'CustomerName', 'QuotationNumber', 'Products',
    'EnquiryValue', 'Discount', 'EnquiryWinLostOpenStatus', 'EstimatedProductvalue', 'RegionName',
    'IndustrySegment', 'SubSegment', 'CustomerClassification',
    'ActivityType', 'Activity_Subject', 'ActivityStatus', 'ActivityPurpose', 'ActivityPurposeDesc', 'Activity_Outcome', 'Activity_OutcomeDesc',
    'EnquiryStatus', 'InitiatorName', 'Action'];


  EnquiryGridFilterList: EnquiryGridFilterList = new EnquiryGridFilterList();
  minDate: NgbDate;
  maxDate: NgbDate;
  datePipe = new DatePipe('en-US');

  dataSource: MatTableDataSource<EnquiryGridModel>;
  disabled: boolean = false;
  @Input()
  EnquiryId: number;
  AppRoles = UserRoles;
  AppGlobals = AppGlobals;
  QuotationDownloadPath = this.AppGlobals.QuotationFileDownloadUrl;
  EnquiryngbDateFromFiter: NgbDate | null;
  EnquiryngbDateToFiter: NgbDate | null;
  hoveredDate: NgbDate | null = null;
  charCode: any;
  ExportToExcelData: EnquiryExportToExcelModel[] = [];
  TempEnquiryData: any[] = [];

  /*************************************************************************
       Server side Pagination purpose
   *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 100;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  SearchColname: string = "";
  SearchColKeyword: string = "";

  PaginationModel: EnquiryPaginationGrid = new EnquiryPaginationGrid();
  /*************************************************************************
     End here
  *************************************************************************/

  @Input()
  showLoader: boolean = false;
  data: any[] = [];
  alldata: any;
  role: string;
  userid: string;
  closeResult: string;
  salesEnggList: any;
  selectedRegion: any;
  seList: any;
  SelectedEnquiryID: any;
  selectedSalesEngg: any;
  salesRegionValidation: boolean;
  salesEnggValidation: boolean;
  currentSalesEngg: any;

  /************************USED FOR ROWSPAN************************/
  gridData: any[] = [];
  spans = [];
  tempRowId = null;
  tempRowCount = null;
  /************************USED FOR ROWSPAN END************************/

  constructor(private toastr: ToastrService,
    private cd: ChangeDetectorRef, private commonService: CommonService, private modalService: NgbModal, public dialog: MatDialog
    , private titleService: Title, private route: ActivatedRoute, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.minDate = new NgbDate(2018, 1, 1);
    this.maxDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  }

  ngOnInit() {
    this.commonService.RefreshHeaderFun();
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.role = localStorage.getItem("UserRole");
    this.userid = localStorage.getItem("UserID");
    this.GetAllEnquiryGridFilterList();
    this.GetAllEnquiry();
  }


  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllEmployeeDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllEnquiry() {
    // var x = document.getElementsByClassName("table-responsive");
    // var i;
    // for (i = 0; i < x.length; i++) {
    //   x[i].scrollLeft = 0;

    // }
    this.showLoader = true;

    this.PaginationModel.pageNumber = this.pageNumber;
    this.PaginationModel.pageSize = this.pageSize;
    this.PaginationModel.SearchText = this.SearchText;
    this.PaginationModel.direction = this.direction;
    this.PaginationModel.ColumnName = this.ColumnName;

    if (this.EnquiryngbDateToFiter != undefined && this.EnquiryngbDateToFiter != null) {
      this.PaginationModel.EnquiryDateToFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateToFiter = null;
    }

    if (this.EnquiryngbDateFromFiter != undefined && this.EnquiryngbDateFromFiter != null) {
      this.PaginationModel.EnquiryDateFromFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateFromFiter = null;
    }

    let QuotationValueFromFilter: number = +this.PaginationModel.QuotationValueFromFilter;
    let QuotationValueToFilter: number = +this.PaginationModel.QuotationValueToFilter;
    if (this.PaginationModel.QuotationValueFromFilter != null && QuotationValueFromFilter > 0 && QuotationValueToFilter > 0 && this.PaginationModel.QuotationValueToFilter != null) {
      if (QuotationValueToFilter < QuotationValueFromFilter) {
        alert("To Quotation value should be greater than From Quotation value.")
        this.PaginationModel.QuotationValueFromFilter = null;
      }
    }


    let DiscountFromFilter: number = +this.PaginationModel.DiscountFromFilter;
    let DiscounToFilter: number = +this.PaginationModel.DiscounToFilter;
    if (this.PaginationModel.DiscountFromFilter != null && DiscountFromFilter > 0 && DiscounToFilter > 0 && this.PaginationModel.DiscounToFilter != null) {
      if (DiscounToFilter < DiscountFromFilter) {
        alert("To Discount should be greater than From Discount.")
        this.PaginationModel.DiscountFromFilter = null;

      }
    }

    this.commonService.PostData('Enquiry', 'GetAllPagination_Pending', this.PaginationModel, null).subscribe((data: any) => {
      this.data = data.Data;
      if (data.Data != null) {
        this.BindGridData();

      }

      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.gridData);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    var x = document.getElementsByClassName("table-responsive");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].scrollLeft = 0;

    }
    this.GetAllEnquiry();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllEnquiry();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    var x = document.getElementsByClassName("table-responsive");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].scrollLeft = 0;

    }
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllEnquiry();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/
  SetEnquiryId(EnquiryId) {
    this.EnquiryId = EnquiryId;
  }

  convertDate(date: any) {
    date = date.split('T')[0];
    return date;
  }
  openQuotationPopup(enqid, enqNo, initiatorID, status, PendingAtRoleId) {

    const modalRef = this.modalService.open(ActivityDetailsComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var EnquiryId = {
      enqid: enqid,
      endNo: enqNo,
      initID: initiatorID,
      status: status,
      PendingAtRoleId: PendingAtRoleId
    };

    modalRef.componentInstance.EnquiryId = EnquiryId;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        // this.GetAllEnquiry();
        // if (flag === 1) {
        // } else {
        //   //  this.saveQuoteLimit(this.QuotationNumber, result);
        // }
      }
    });
  }
  openPassOn(rowObject) {
    const modalRef = this.modalService.open(PassOnComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var EnquiryId = rowObject;

    modalRef.componentInstance.enqObj = EnquiryId;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        this.GetAllEnquiry();
      }
    });
  }

  /************************************************************************
 * Grid RowSpan Methods  
 * **********************************************************************/

  BindGridData() {
    this.showLoader = true;
    this.gridData = [];
    this.spans = [];
    this.data.forEach(item => {
      item.EnquiryDate = this.convertDate(item.EnquiryDate);
      if (item.QuotationDetailsList != null && item.QuotationDetailsList.length > 0 && item.QuotationDetailsList.length != null && item.QuotationDetailsList.length != undefined) {
        for (let i = 0; i < item.QuotationDetailsList.length; i++) {
          var obj = {
            "Products": item.QuotationDetailsList[i].ModelName,
            "EnquiryValue": item.QuotationDetailsList[i].FinalAmount,
            "Discount": item.QuotationDetailsList[i].Discount,
            "EnquiryId": item.EnquiryId,
            "Initiator_Id": item.Initiator_Id,
            "InitiatorName": item.InitiatorName,
            "RoleName": item.RoleName,
            "CustomerId": item.CustomerId,
            "CustomerName": item.CustomerName,
            "CustomerCode": item.CustomerCode,
            "IndustrySegment": item.IndustrySegment,
            "SubSegment": item.SubSegment,
            "CustomerType": item.CustomerType,
            "CustomerClassification": item.CustomerClassification,
            "CustomerCategory": item.CustomerCategory,
            "RegionName": item.RegionName,
            "Country": item.Country,
            "EstimatedProductvalue": item.EstimatedProductvalue,
            "EnquiryNumber": item.EnquiryNumber,
            "EnquiryDate": item.EnquiryDate,
            "EnquirySourceId": item.EnquirySourceId,
            "EnquirySource": item.EnquirySource,
            "EnquiryDescription": item.EnquiryDescription,
            "Status": item.Status,
            "PendingAtRoleId": item.PendingAtRoleId,
            "EnquiryStatus": item.EnquiryStatus,
            "EnquiryWinLostOpenStatus": item.EnquiryWinLostOpenStatus,
            "CreatedOn": item.CreatedOn,
            "RFQNumber": item.RFQNumber,
            "RFQTermsAndCondition": item.RFQTermsAndCondition,
            "POValue": item.POValue,
            "EnquiryConfirmStatus": item.EnquiryConfirmStatus,
            "POStdReason": item.POStdReason,
            "OrdAcknowledgeNumber": item.OrdAcknowledgeNumber,
            "SupplyChainType": item.SupplyChainType,
            "TentativeDeliveryDate": item.TentativeDeliveryDate,
            "ModifiedOn": item.ModifiedOn,
            "EnquiryYear": item.EnquiryYear,
            "QuotationNumber": item.QuotationNumber,
            "QuotationPdf_filename": item.QuotationPdf_filename,
            // "Activity_Updates": item.Activity_Updates,
            // "Activity_ActionPlan": item.Activity_ActionPlan
            "ActivityType": item.ActivityType,
            "Activity_Subject": item.Activity_Subject,
            "ActivityStatus": item.ActivityStatus,
            "ActivityPurpose": item.ActivityPurpose,
            "ActivityPurposeDesc": item.ActivityPurposeDesc,
            "Activity_Outcome": item.Activity_Outcome,
            "Activity_OutcomeDesc": item.Activity_OutcomeDesc
          }

          this.gridData.push(obj);

        }
      }
      else {
        var obj1 = {
          "Products": null,
          "EnquiryValue": null,
          "Discount": null,
          "EnquiryId": item.EnquiryId,
          "Initiator_Id": item.Initiator_Id,
          "InitiatorName": item.InitiatorName,
          "RoleName": item.RoleName,
          "CustomerId": item.CustomerId,
          "CustomerName": item.CustomerName,
          "CustomerCode": item.CustomerCode,
          "IndustrySegment": item.IndustrySegment,
          "SubSegment": item.SubSegment,
          "CustomerType": item.CustomerType,
          "CustomerClassification": item.CustomerClassification,
          "CustomerCategory": item.CustomerCategory,
          "RegionName": item.RegionName,
          "Country": item.Country,
          "EstimatedProductvalue": item.EstimatedProductvalue,
          "EnquiryNumber": item.EnquiryNumber,
          "EnquiryDate": item.EnquiryDate,
          "EnquirySourceId": item.EnquirySourceId,
          "EnquirySource": item.EnquirySource,
          "EnquiryDescription": item.EnquiryDescription,
          "Status": item.Status,
          "PendingAtRoleId": item.PendingAtRoleId,
          "EnquiryStatus": item.EnquiryStatus,
          "EnquiryWinLostOpenStatus": item.EnquiryWinLostOpenStatus,
          "CreatedOn": item.CreatedOn,
          "RFQNumber": item.RFQNumber,
          "RFQTermsAndCondition": item.RFQTermsAndCondition,
          "POValue": item.POValue,
          "EnquiryConfirmStatus": item.EnquiryConfirmStatus,
          "POStdReason": item.POStdReason,
          "OrdAcknowledgeNumber": item.OrdAcknowledgeNumber,
          "SupplyChainType": item.SupplyChainType,
          "TentativeDeliveryDate": item.TentativeDeliveryDate,
          "ModifiedOn": item.ModifiedOn,
          "EnquiryYear": item.EnquiryYear,
          "QuotationNumber": item.QuotationNumber,
          "QuotationPdf_filename": item.QuotationPdf_filename,
          // "Activity_Updates": item.Activity_Updates,
          // "Activity_ActionPlan": item.Activity_ActionPlan
          "ActivityType": item.ActivityType,
          "Activity_Subject": item.Activity_Subject,
          "ActivityStatus": item.ActivityStatus,
          "ActivityPurpose": item.ActivityPurpose,
          "ActivityPurposeDesc": item.ActivityPurposeDesc,
          "Activity_Outcome": item.Activity_Outcome,
          "Activity_OutcomeDesc": item.Activity_OutcomeDesc
        }

        this.gridData.push(obj1);

      }


    });
    this.RowSpanData();
  }

  RowSpanData() {
    this.cacheSpan('EnquiryNo', d => d.EnquiryId);
    this.cacheSpan('EnquiryDate', d => d.EnquiryId);
    this.cacheSpan('EstimatedProductvalue', d => d.EnquiryId);
    this.cacheSpan('IndustrySegment', d => d.EnquiryId);
    this.cacheSpan('SubSegment', d => d.EnquiryId);
    this.cacheSpan('RegionName', d => d.EnquiryId);
    this.cacheSpan('CustomerClassification', d => d.EnquiryId);
    this.cacheSpan('QuotationNumber', d => d.EnquiryId);
    this.cacheSpan('CustomerName', d => d.EnquiryId);
    this.cacheSpan('EnquiryStatus', d => d.EnquiryId);
    this.cacheSpan('EnquiryWinLostOpenStatus', d => d.EnquiryId);
    this.cacheSpan('ActivityType', d => d.EnquiryId);
    this.cacheSpan('Activity_Subject', d => d.EnquiryId);
    this.cacheSpan('ActivityStatus', d => d.EnquiryId);
    this.cacheSpan('ActivityPurpose', d => d.EnquiryId);
    this.cacheSpan('ActivityPurposeDesc', d => d.EnquiryId);
    this.cacheSpan('Activity_Outcome', d => d.EnquiryId);
    this.cacheSpan('Activity_OutcomeDesc', d => d.EnquiryId);
    this.cacheSpan('InitiatorName', d => d.EnquiryId);
    this.cacheSpan('Action', d => d.EnquiryId);
    this.showLoader = false;
  }
  cacheSpan(key, accessor) {
    for (let i = 0; i < this.gridData.length;) {
      let currentValue = accessor(this.gridData[i]);
      let count = 1;

      for (let j = i + 1; j < this.gridData.length; j++) {
        if (currentValue != accessor(this.gridData[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      this.spans[i][key] = count;
      i += count;

    }
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }


  /************************************************************************
   * Grid RowSpan Methods End 
   * **********************************************************************/

  /************************************************************************
     * Grid filter
     * **********************************************************************/
  OnFromDateFilterChange() {
    let EnquiryDateFromFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)
    let EnquiryDateToFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)
    if (EnquiryDateToFiter < EnquiryDateFromFiter) {
      this.EnquiryngbDateToFiter = this.EnquiryngbDateFromFiter;
      this.cd.detectChanges();

    }
    //this.GetAllEnquiry();

  }

  OnToDateFilterChange() {

    //  this.GetAllEnquiry();
  }

  ConvertNgbDateToDate(ngbdate: any) {
    if (ngbdate != null) {
      return new Date(ngbdate.year, ngbdate.month - 1, ngbdate.day)
    }

  }

  convertDateToString(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }


  OnQuotationValueFilterChange(CallFrom: any) {
    let QuotationValueFromFilter: number = +this.PaginationModel.QuotationValueFromFilter;
    let QuotationValueToFilter: number = +this.PaginationModel.QuotationValueToFilter;
    if (CallFrom == 'From') {
      if (QuotationValueFromFilter == null || isNaN(QuotationValueFromFilter)) {
        this.PaginationModel.QuotationValueFromFilter = null;
      }
      // if (QuotationValueToFilter < QuotationValueFromFilter) {
      //   this.PaginationModel.QuotationValueToFilter = null;

      // }

    }
    else {
      if (QuotationValueToFilter == null || isNaN(QuotationValueToFilter)) {
        this.PaginationModel.QuotationValueToFilter = null;
      }
      // if (QuotationValueToFilter < QuotationValueFromFilter) {
      //   this.PaginationModel.QuotationValueFromFilter = null;

      // }

    }
    this.cd.detectChanges();
    // this.GetAllEnquiry();

  }

  onDiscountChange(callType: any) {
    let DiscountFromFilter: number = +this.PaginationModel.DiscountFromFilter;
    let DiscounToFilter: number = +this.PaginationModel.DiscounToFilter;

    if (callType == 'From') {

      if (DiscountFromFilter > 100 || isNaN(DiscountFromFilter)) {
        this.PaginationModel.DiscountFromFilter = null;
      }
      // if ( DiscounToFilter <  DiscountFromFilter) {
      //   this.PaginationModel.DiscounToFilter = null;
      // }
    }
    else {
      if (DiscounToFilter > 100 || isNaN(DiscounToFilter)) {
        this.PaginationModel.DiscounToFilter = null;
      }
      // if (DiscounToFilter < DiscountFromFilter) {
      //   this.PaginationModel.DiscountFromFilter = null;

      // }
    }
    this.cd.detectChanges();
    //  this.GetAllEnquiry();
  }


  GetAllEnquiryGridFilterList() {
    this.commonService.GetData("Enquiry", "EnquiryGridFilterList", null).subscribe((data: any) => {
      this.EnquiryGridFilterList.EstimatedEnquiryValue = data.EstimatedEnquiryValue;
      this.EnquiryGridFilterList.Segment = data.Segment;
      this.EnquiryGridFilterList.MicroSegment = data.MicroSegment;
      this.EnquiryGridFilterList.OrignaleMicroSegment = data.MicroSegment;
      this.EnquiryGridFilterList.CusomterName = data.CusomterName;
      this.EnquiryGridFilterList.CusomterClass = data.CusomterClass;
      this.EnquiryGridFilterList.ProductModels = data.ProductModels;
      this.EnquiryGridFilterList.Region = data.region;
      this.EnquiryGridFilterList.InitiatorName = data.InitiatorName;

    });

  }

  ResetAllFilters() {
    this.PaginationModel.EnquiryDateFromFiter = null;
    this.PaginationModel.EnquiryDateToFiter = null;
    this.PaginationModel.EstimatedEnquiryValueFilter = [];
    this.PaginationModel.SegmentFilter = [];
    this.PaginationModel.MicroSegmentFilter = [];
    this.PaginationModel.CusomterNameFilter = [];
    this.PaginationModel.CusomterClassFilter = [];
    this.PaginationModel.ProdcutFilter = [];
    this.PaginationModel.QuotationValueToFilter = null;
    this.PaginationModel.QuotationValueFromFilter = null;
    this.PaginationModel.DiscountFromFilter = null;
    this.PaginationModel.DiscounToFilter = null;
    this.PaginationModel.QuotationStatusFilter = [];
    this.PaginationModel.RegionFilter = [];
    this.PaginationModel.InitiatorNameFilter = [];
    this.EnquiryngbDateFromFiter = null;
    this.EnquiryngbDateToFiter = null;
    this.OnSegmentChangeGetMiCroSegment();
    this.GetAllEnquiry();

  }
  OnSegmentChangeGetMiCroSegment() {
    this.EnquiryGridFilterList.MicroSegment = [];
    if (this.PaginationModel.SegmentFilter != null && this.PaginationModel.SegmentFilter != undefined && this.PaginationModel.SegmentFilter.length > 0) {
      this.EnquiryGridFilterList.MicroSegment = this.EnquiryGridFilterList.OrignaleMicroSegment.filter((o) => this.PaginationModel.SegmentFilter.includes(o.Fk_Name));

    }
    else {
      this.EnquiryGridFilterList.MicroSegment = this.EnquiryGridFilterList.OrignaleMicroSegment;
    }


  }





  /************************Date Range*************************************** */

  onDateSelection(date: NgbDate) {
    if (!this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter) {
      this.EnquiryngbDateFromFiter = date;
    } else if (this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && date && date.after(this.EnquiryngbDateFromFiter)) {
      this.EnquiryngbDateToFiter = date;
    } else {
      this.EnquiryngbDateToFiter = null;
      this.EnquiryngbDateFromFiter = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && this.hoveredDate && date.after(this.EnquiryngbDateFromFiter) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.EnquiryngbDateToFiter && date.after(this.EnquiryngbDateFromFiter) && date.before(this.EnquiryngbDateToFiter);
  }

  isRange(date: NgbDate) {
    return date.equals(this.EnquiryngbDateFromFiter) || (this.EnquiryngbDateToFiter && date.equals(this.EnquiryngbDateToFiter)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }




  /************************End Date Range*************************************** */

  /************************************************************************
    * Grid filter
    * **********************************************************************/


  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }


  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  addEnquiryPopup(enqid, custid) {
    // this.router.navigate(['/Add-Activity/Enquiry/' + enqid]);

    const modalRef = this.modalService.open(AddActivityComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });

    var ActivityEntity = {
      enqId: enqid,
      custId: custid,
      inPopUpMode: true,
      entityType: 'Enquiry',
    };

    modalRef.componentInstance.ActivityEntity = ActivityEntity;
  }
}
