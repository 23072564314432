
export enum EnquiryStatus {
    EnquiryAdded=1,
    RFQSaveAsDraft=2,
    RFQSubmittedbySalesEngg=3,
    RFQAcceptedbyL1=4,
    RFQReworkbyL1=5,
    RFQSubmittedbyL1=6,
    RFQsentbyL1toL2=7,
    RFQSubmittedbyL2=8,
    RFQsentbyL2toL3=9,
    RFQSubmittedbyL3=10,
    QuotationSaveAsDraftbyL1=11,
    QuotationSaveAsDraftbyL2=12,
    QuotationSaveAsDraftbyL3=13,
    QuotationPriceOnRequestbyL1=14,
    QuotationPriceOnRequestbyL2=15,
    QuotationPriceOnRequestbyL3=16,
    QuotationGeneratedbyL1=17,
    QuotationGeneratedbyL2=18,
    QuotationGeneratedbyL3=19,
    QuotationsentbyL3toL2=20,
    QuotationsentbyL2toL1=21,
    QuotationSenttoCustomerService=22,
    RFQSubmittedbyCustomerService=23,
    QuotationSaveAsDraftbyCustomerService=24,
    QuotationPriceOnRequestbyCustomerService=25,
    QuotationGeneratedbyCustomerService=26,
    QuotationSentforMngtApproval_SalesDirector=27,
    QuotationRejectedbyMngmt_SalesDirector=28,
    QuotationApprovedbyMngmt_SalesDirector=29,
    QuotationOnHoldbyMngmt_SalesDirector=30,
    QuotationSentforMngmtApproval_CEO=31,
    QuotationRejectedbyMngmt_CEO=32,
    QuotationApprovedbyMngmt_CEO=33,
    QuotationOnHoldbyMngmt_CEO=34,
    SendBOMandProposal=35,
    QuotationRevision=36,
    EnquiryLost=37,
    EnquiryOrderConfirmedbySalesEngg=38,
    OrdersentforMngmtApproval_SalesDirector=39,
    OrderApprovedbyMngmt_SalesDirector=40,
    OrderRejectedbyMngmt_SalesDirector=41,
    OrdersentforMngmtApproval_CEO=42,
    OrderApprovedbyMngmt_CEO=43,
    OrderRejectedbyMngmt_CEO=44,
    OrderConfirmedbyCustomerServiceSendSO=45,
    OANoGenerated=46,
    SupplyChainConfirmedDeliveryDate=47,
    MaterialReadyForDispatch=48,
    AccountsConfirmInvoiceNo=49,
    CloseOpportunity=50,
    EnquiryTechnicalDiscardbyL1 = 51,
    EnquiryTechnicalDiscardbyL2 = 52,
    EnquiryTechnicalDiscardbyL3 = 53,
    QuotationSendtoCustomerServicebyL1=54,
    QuotationSendtoCustomerServicebyL2=55,
    QuotationSendtoCustomerServicebyL3=56,
    DeleteEnquiry=57,
    CloseLead=58,
    QuotationSentForPriceDiscountApprovalToLevel1=67,
    QuotationSentForPriceDiscountApprovalToLevel2=68,
    QuotationSentForPriceDiscountApprovalToLevel3=69,
    PriceDiscountApprovedByL1=59,
    PriceDiscountApprovedByL2=60,
    PriceDiscountApprovedByL3=61,
    PriceDiscountRejectedByL1=62,
    PriceDiscountRejectedByL2=63,
    PriceDiscountRejectedByL3=64
}



export enum EnquiryStatusCode {
    EnquiryAdded='EnquiryAdded',
    RFQSaveAsDraft='RFQSaveAsDraft',
    RFQSubmittedbySalesEngg='RFQSubmittedbySalesEngg',
    RFQAcceptedbyL1='RFQAcceptedbyL1',
    RFQSubmittedbyL1='RFQSubmittedbyL1',
    RFQsentbyL1toL2='RFQsentbyL1toL2',
    RFQSubmittedbyL2='RFQSubmittedbyL2',
    RFQsentbyL2toL3='RFQsentbyL2toL3',
    RFQSubmittedbyL3='RFQSubmittedbyL3',
    QuotationSaveAsDraftbyL1='QuotationSaveAsDraftbyL1',
    QuotationSaveAsDraftbyL2='QuotationSaveAsDraftbyL2',
    QuotationSaveAsDraftbyL3='QuotationSaveAsDraftbyL3',
    QuotationPriceOnRequestbyL1='QuotationPriceOnRequestbyL1',
    QuotationPriceOnRequestbyL2='QuotationPriceOnRequestbyL2',
    QuotationPriceOnRequestbyL3='QuotationPriceOnRequestbyL3',
    QuotationGeneratedbyL1='QuotationGeneratedbyL1',
    QuotationGeneratedbyL2='QuotationGeneratedbyL2',
    QuotationGeneratedbyL3='QuotationGeneratedbyL3',
    QuotationsentbyL3toL2='QuotationsentbyL3toL2',
    QuotationsentbyL2toL1='QuotationsentbyL2toL1',
    QuotationSenttoCustomerService='QuotationSenttoCustomerService',
    RFQSubmittedbyCustomerService='RFQSubmittedbyCustomerService',
    QuotationSaveAsDraftbyCustomerService='QuotationSaveAsDraftbyCustomerService',
    QuotationPriceOnRequestbyCustomerService='QuotationPriceOnRequestbyCustomerService',
    QuotationGeneratedbyCustomerService='QuotationGeneratedbyCustomerService',
    QuotationSentforMngtApproval_SalesDirector='QuotationSentforMngtApproval_SalesDirector',
    QuotationRejectedbyMngmt_SalesDirector='QuotationRejectedbyMngmt_SalesDirector',
    QuotationApprovedbyMngmt_SalesDirector='QuotationApprovedbyMngmt_SalesDirector',
    QuotationOnHoldbyMngmt_SalesDirector='QuotationOnHoldbyMngmt_SalesDirector',
    QuotationSentforMngmtApproval_CEO='QuotationSentforMngmtApproval_CEO',
    QuotationRejectedbyMngmt_CEO='QuotationRejectedbyMngmt_CEO',
    QuotationApprovedbyMngmt_CEO='QuotationApprovedbyMngmt_CEO',
    QuotationOnHoldbyMngmt_CEO='QuotationOnHoldbyMngmt_CEO',
    SendBOMandProposal='SendBOMandProposal',
    QuotationRevision='QuotationRevision',
    EnquiryLost='EnquiryLost',
    EnquiryOrderConfirmedbySalesEngg='EnquiryOrderConfirmedbySalesEngg',
    OrdersentforMngmtApproval_SalesDirector='OrdersentforMngmtApproval_SalesDirector',
    OrderApprovedbyMngmt_SalesDirector='OrderApprovedbyMngmt_SalesDirector',
    OrderRejectedbyMngmt_SalesDirector='OrderRejectedbyMngmt_SalesDirector',
    OrdersentforMngmtApproval_CEO='OrdersentforMngmtApproval_CEO',
    OrderApprovedbyMngmt_CEO='OrderApprovedbyMngmt_CEO',
    OrderRejectedbyMngmt_CEO='OrderRejectedbyMngmt_CEO',
    OrderConfirmedbyCustomerServiceSendSO='OrderConfirmedbyCustomerServiceSendSO',
    OANoGenerated='OANoGenerated',
    SupplyChainConfirmedDeliveryDate='SupplyChainConfirmedDeliveryDate',
    MaterialReadyForDispatch='MaterialReadyForDispatch',
    AccountsConfirmInvoiceNo='AccountsConfirmInvoiceNo',
    CloseOpportunity='CloseOpportunity',
    EnquiryTechnicalDiscardbyL1 ='EnquiryTechnicalDiscardbyL1',
    EnquiryTechnicalDiscardbyL2 ='EnquiryTechnicalDiscardbyL2',
    EnquiryTechnicalDiscardbyL3 ='EnquiryTechnicalDiscardbyL3',
    QuotationSendtoCustomerServicebyL1='QuotationSendtoCustomerServicebyL1',
    QuotationSendtoCustomerServicebyL2='QuotationSendtoCustomerServicebyL2',
    QuotationSendtoCustomerServicebyL3='QuotationSendtoCustomerServicebyL3',
    DeleteEnquiry='DeleteEnquiry',
    CloseLead='CloseLead',
    QuotationSentForPriceDiscountApprovalToLevel1='QuotationSentForPriceDiscountApprovalToLevel1',
    QuotationSentForPriceDiscountApprovalToLevel2='QuotationSentForPriceDiscountApprovalToLevel2',
    QuotationSentForPriceDiscountApprovalToLevel3='QuotationSentForPriceDiscountApprovalToLevel3',
    PriceDiscountApprovedByL1='PriceDiscountApprovedByL1',
    PriceDiscountApprovedByL2='PriceDiscountApprovedByL2',
    PriceDiscountApprovedByL3='PriceDiscountApprovedByL3',
    PriceDiscountRejectedByL1='PriceDiscountRejectedByL1',
    PriceDiscountRejectedByL2='PriceDiscountRejectedByL2',
    PriceDiscountRejectedByL3='PriceDiscountRejectedByL3',
    MaterialReadyForDistpatch='MaterialReadyForDistpatch',
    EnquiryClosePendingAtSalesDirector='EnquiryClosePendingAtSalesDirector',
    EnquiryClosePendingAtCS='EnquiryClosePendingAtCS',
    PriceOnRequestSubmitted='PriceOnRequestSubmitted'
}
