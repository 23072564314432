import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AddCustomerOpportunityModel } from 'src/app/Model/AddCustomerOpportunityModel';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-add-customer-opportunity',
  templateUrl: './add-customer-opportunity.component.html',
  styleUrls: ['./add-customer-opportunity.component.scss']
})
export class AddCustomerOpportunityComponent implements OnInit {

  AddCustomerOpportunityModel = new AddCustomerOpportunityModel();

  Country: any[] = [];
  Region: any[] = [];
  IndustrySegment: any[] = [];
  Typeofcustomer: any[] = [];
  showLoader: boolean = false;
  minDate: NgbDate;
  maxDate: NgbDate;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;

  constructor(private router: Router, private toastr: ToastrService, private Service: CommonService,
    public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title) {
      this.minDate = new NgbDate(2018, 1, 1);
    this.maxDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
     }

  ngOnInit(): void {
    this.GetCustomerDropdownData();
  }

  GetCustomerDropdownData() {
    this.showLoader = true;
    this.Service.GetData("Customer", "GetCustomerDropdownDetails", null).subscribe((data: any) => {
      //this.Country = data.Country;
      this.Region = data.Region;
      this.IndustrySegment = data.IndustrySegment;
      this.Typeofcustomer = data.CustomerType;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  onSubmit(myform: any) {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to add this customer?"
        }
      });
      
      dialogRef.afterClosed().subscribe(dialogResult => {

        const frmData = new FormData();

       // console.log(frmData);

        if (dialogResult) {

          //this.AddNewRow('Submitted');
          // this.CustomerModel.BasicCustomerInformationModel.CustomerCode = this.CustomerCode;
          const frmData = new FormData();
          let CustomerDetails = JSON.stringify(this.AddCustomerOpportunityModel);
          frmData.append("CustomerModel", CustomerDetails);
         // console.log(frmData.append);
          
          this.showLoader = true;
          this.Service.PostData('Customer', 'Add', frmData, null).subscribe((data: any) => {
            let result = data;
            this.showLoader = false;
            if (result.ResponseCode == 1) {
              this.toastr.success('Customer details added successfully!');
              this.router.navigate(['/Customer']);
            }
            else {
              this.toastr.error("Failed to add customer details!");
              this.showLoader = false;
            }

          }, error => {
            this.toastr.error('Failed to add customer details!');
            this.showLoader = false;
            this.router.navigate(['/Customer']);
          });
        }
      });
    }

}
