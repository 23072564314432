import { Component, OnInit, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductSeries } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-product-series',
  templateUrl: './add-product-series.component.html',
  styleUrls: ['./add-product-series.component.scss']
})
export class AddProductSeriesComponent implements OnInit {
  ProductSeriesobj: ProductSeries = new ProductSeries();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductFamily: any[] = [];
  ProductLine: any[] = [];
  // allowedFileType: string[] = [
  //   'image/bmp',
  //   'image/jpeg',
  //   'image/png',
  //   'application/pdf',
  //   'text/plain',
  //   'text/csv',
  //   'application/xml',
  //   'text/xml',
  //   'application/vnd.ms-excel',
  //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   'application/msword',
  //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //   'application/zip',
  //   'application/x-zip-compressed',
  // ];
  allowedFileType: string[] = [   
    'application/pdf'    
  ];
  file: any;
  constructor(private router: Router, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.getAllProductLine();
  }

  /*******************************************************************************
   * Get data for add product family
   * ******************************************************************************/
  getAllProductLine() {
    this.showLoader = true;
    this.CommonService.GetData("ProductLine", "GetAll", null).subscribe((data: any) => {
      this.ProductLine = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  getAllProductFamily(ProductLine: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "ProductLine=" + ProductLine;
    this.CommonService.GetData("ProductFamily", "GetbyProductLine", queryString).subscribe((data: any) => {
      this.ProductFamily = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  /*******************************************************************************
   * End get data for add product family
   * ******************************************************************************/
  onProductLineSelect(e: any) {
    this.getAllProductFamily(e.target.value);
  }

  OnVariantChange() {
    if (this.ProductSeriesobj.Label_Variant1 == '' || this.ProductSeriesobj.Label_Variant2 == '') {
      this.ProductSeriesobj.IsLevel2DepOnLevel1 = false;
    }
    if (this.ProductSeriesobj.Label_Variant2 == '' || this.ProductSeriesobj.Label_Variant3 == '') {
      this.ProductSeriesobj.IsLevel3DepOnLevel2 = false;
    }
    if (this.ProductSeriesobj.Label_Variant3 == '' || this.ProductSeriesobj.Label_Variant4 == '') {
      this.ProductSeriesobj.IsLevel4DepOnLevel3 = false;
    }
  }
  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this product series?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {       
        this.showLoader = true;  
        const frmData = new FormData();
        let ProductSeriesDetails = JSON.stringify(this.ProductSeriesobj);
        frmData.append("ProductSeries", ProductSeriesDetails);
        if (this.ProductSeriesobj.CatlogName != undefined) {
          frmData.append("CatalogFile", this.file);
        }

        this.CommonService.PostData('ProductSeries', 'Add', frmData, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('Product series added successfully!');
            this.CommonService.RefreshHeaderFun();
            this.router.navigate(['/ProductSeries']);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product series already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add product series!');
            this.router.navigate(['/ProductSeries']);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add product series!');
          this.showLoader = false;
          this.router.navigate(['/ProductSeries']);
        });
      }
    });

  }

  editFileDetails(event, index) {
    let file = event.target.files[0];
    let isValidFile = true;
    if (((file.size) / 1000000) <= 20) {
      isValidFile = this.allowedFileType.includes(file.type);
      if (!isValidFile) {
        this.toastr.warning("Please select valid file. Valid filetype is .pdf file");
      }
      else
      {
        this.ProductSeriesobj.CatlogName = file.name;
        this.file=file;        
      }
    
    }
    else{
      this.toastr.info("Please select file having size upto 20MB");
    }
  }
}