<!-- <loader [showLoader]="showLoader"></loader> -->
<div class="m-portlet m-portlet--success m-portlet--head-solid-bg ">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    Order Won/Lost Analysis
                </h3>
            </div>
        </div>

        <div class="m-portlet__head-tools">
            <select class="form-control mr-1 pl-0" id="sel1" style="min-width:110px;" [(ngModel)]="type"
                (change)="OnSelectChange()">
                <option selected disabled>Select</option>
                <option value="Won">Won</option>
                <option value="Lost">Lost</option>
            </select>
            <select class="form-control mr-1 pl-0" id="sel1" style="min-width:110px;" [(ngModel)]="drpdnOption"
                (change)="OnSelectChange()">
                <option selected disabled>Select</option>
                <option value="Units">No's</option>
                <option value="Amount">Amount</option>
            </select>
        </div>
    </div>
    <div class="m-portlet__body">
        <div id="OrderWinLostChart" style="width: 100%; height: 400px; background-color: #FFFFFF;"></div>
    </div>
</div>