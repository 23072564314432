import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { EnquiryStatus } from 'src/app/EnquiryStatus';
import { LeadModel } from 'src/app/Model/LeadModel';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-close-lead',
  templateUrl: './close-lead.component.html',
  styleUrls: ['./close-lead.component.scss']
})



export class CloseLeadComponent implements OnInit {
  LeadObj: LeadModel = new LeadModel();
  // EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();

  @Input() showLoader: boolean = false;
  @Input() leadDataObj;
  closeOpportunityList: SingleFieldModel[] = [];
  leadId: any;
  enquiryStatus = EnquiryStatus;

  OppCloseId: any;
  role: string;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  Comment: any;

  constructor(private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private commonService: CommonService,
    private Service: CommonService,
    public dialog: MatDialog,
    private router: Router) { }


  ngOnInit(): void {
    this.role = localStorage.getItem("UserRole");
    this.leadId = this.leadDataObj.Lead_Id;
    this.getAllCloseOpportunityReason();
    this.getLeadDetailsById(this.leadId);

  }


  getAllCloseOpportunityReason() {
    this.commonService.GetData("OpportunityCloseReason", "GetAll", null).subscribe((data: any) => {
      this.closeOpportunityList = data;
      this.OppCloseId = undefined;
    },
      error => {
        this.toastr.error("Failed to load Data!");
        this.showLoader = false;
      });
  }


  closePopup() {
    this.activeModal.close('Close Click');
  }


  // getEnquiryDetailsById(id) {
  //   let queryString = "";
  //   queryString = "id=" + id;
  //   this.commonService.GetData('Enquiry', 'GetById', queryString).subscribe((data: any) => {
  //     this.EnquiryObj.EnquiryDetails = data.EnquiryDetails;
  //   });
  // }
  getLeadDetailsById(Lead_Id: number) {
    //this.showLoader = true;

    let queryString = "";
    queryString = "Lead_Id=" + Lead_Id;
    this.Service.GetData('Lead', 'GetById', queryString).subscribe((data: any) => {
      this.LeadObj = data;

      // this.LeadObj.LeadDate = new NgbDate(new Date(this.LeadObj.LeadDate).getFullYear(), new Date(this.LeadObj.LeadDate).getMonth() + 1, new Date(this.LeadObj.LeadDate).getDate());


    });

  }

  CloseLeadReason(Status, closeLeadForm: NgForm) {

    this.LeadObj.Fk_LeadCloseReason = this.OppCloseId;


    // if (this.LeadObj.Fk_LeadCloseReason == undefined || this.LeadObj.Fk_LeadCloseReason == null ||
    //   this.LeadObj.Fk_LeadCloseReason == 0) {
    //   return;
    // }
    closeLeadForm.control.markAllAsTouched();
    if (closeLeadForm.invalid) {
      return;
    }

    var confimrmsg = AppGlobals.successMessages.filter(x => x.id == Status)[0];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: confimrmsg.confirmmsg
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;

        let leadDetails = JSON.stringify(this.LeadObj);
        const frmData = new FormData();
        frmData.append("leadDetails", leadDetails);


        this.commonService.PostData("Lead", "CloseLead", frmData, null).subscribe((data: any) => {
          this.LeadObj.Lead_Id = data;

          this.showLoader = false;
          this.toastr.success(confimrmsg.successmsg);
          this.router.navigate(['/Lead']);
          this.closePopup();


        }, error => {
          this.toastr.error(confimrmsg.errormsg);
          this.showLoader = false;
          //this.router.navigate(['/Enquiry']);
        });
      }
    });
  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }
}
