import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, Inject, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent, DialogData } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ProductSeries } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProductImportExportModel } from 'src/app/Model/CustomerModel';
import * as Excel from 'exceljs/dist/exceljs'
import * as FileSaver from 'file-saver';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductSeriesUploadLogComponent } from './product-series-upload-log/product-series-upload-log.component';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-product-series-details',
  templateUrl: './product-series-details.component.html',
  styleUrls: ['./product-series-details.component.scss']
})
export class ProductSeriesDetailsComponent implements OnInit {
  displayedColumns: string[] = ['ProductLineName', 'ProductFamilyName', 'SeriesName', 'Action'];
  dataSource: MatTableDataSource<ProductSeries>;
  disabled: boolean = false;
  UserRole: any;
  isProductSeriesEditable: boolean = false;
  isProductPriceEditable: boolean = false;
  SeriesName: string[] = [];
  ShowHide: boolean = false;
  dataSourcePrice: [] = [];
  fileType: any[] = ['text/csv', 'application/xml', 'text/xml']
  ProductPricefileType: any[] = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  ProductPrice1fileType: any[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  myfile: string;
  Attachmentobj: ProductImportExportModel = new ProductImportExportModel();
  ProductPriceData: any[] = [];
  ProductPriceLogData: any[] = [];
  ProductPriceStation: any[] = [];
  ProductPriceSpeed: any[] = [];
  ProductPriceDP: any[] = [];
  ProductPriceMP: any[] = [];
  ProductOptionPrice: any[] = [];
  ProductModelPrice: any[] = [];
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /*************************************************************************
     End here
  *************************************************************************/
  showLoader: boolean;
  data: any[] = [];
  constructor(private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,
    private route: ActivatedRoute, private activeModal: NgbActiveModal,private accessservice: AccessPermissionService) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
    //this.isProductSeriesEditable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
   // this.isProductPriceEditable = AppGlobals.ProductPriceEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.GetAllProductSeriesDetails();
  }

  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllProductSeriesDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllProductSeriesDetails() {
    this.showLoader = true;
    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.CommonService.GetData('ProductSeries', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.SeriesName = data.SeriesData;
      this.data = data.Data;
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.data);

      // seriesData.forEach(el => {
      //   this.SeriesName.push(el.SeriesName);
      // });

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllProductSeriesDetails();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllProductSeriesDetails();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllProductSeriesDetails();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  /*******************************************************************************
  * Form validation method
  * ******************************************************************************/

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  UpdateProductSeriesStatusById(Id: number, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this product series?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData('ProductSeries', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Product series deactivated successfully!");
              this.CommonService.RefreshHeaderFun();
            }
            else if (data.ResponseCode == 0) {
              this.toastr.warning("Failed to deactivate this product series because it has some dependency!");
            }
            else {
              this.toastr.info("Failed to deactivate product series!");
            }
            this.GetAllProductSeriesDetails();
          }, error => {
            this.toastr.error("Failed to deactivate product series!");
            this.GetAllProductSeriesDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllProductSeriesDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this product series?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.CommonService.PostData('ProductSeries', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Product series activated successfully!");
              this.CommonService.RefreshHeaderFun();
            }
            else {
              this.toastr.info("Failed to activate product series!");
            }
            this.GetAllProductSeriesDetails();
          }, error => {
            this.toastr.error("Failed to activate product series!");
            this.GetAllProductSeriesDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllProductSeriesDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }
  }

  /*****************************************
   * Immport and Export File
   ********************* */

  getfiledetails(e: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: {
        title: "Are you sure",
        message: "You want to upload price list?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult) {
        if (e.target.files[0].name.length > 50) {
          e.target.value = null;
          this.toastr.warning("The file name with more than 50 characters are not allowed.");
        }
        else {
          if (e.target.name == "ProductPriceData") {
            var KycResult;
            if (e.target.name == "ProductPriceData") {
              KycResult = this.CheckFileValidation(e.target.files[0], this.ProductPrice1fileType);
            }
            else {
              KycResult = this.CheckFileValidation(e.target.files[0], this.ProductPricefileType);
            }
            if (KycResult.isExtensionValid == true) {
              if (e.target.name == "ProductPriceData") {
                this.showLoader = true;
                this.myfile = e.target.files[0];
                const formData = new FormData();
                formData.append('file', this.myfile);

                this.CommonService.PostData('ProductModel', 'AddFromFile', formData, null).subscribe((response: any) => {
                  if (response == "Invalid file.") {
                    this.toastr.warning("Please select valid file.");
                  }
                  else {
                    this.ShowHide = true;
                    this.dataSourcePrice = response;
                    this.openDialog(this.dataSourcePrice);
                  }
                  this.showLoader = false;
                }, error => {
                  //this.toastr.error('Failed to load data!');
                  this.showLoader = false;
                });
              }
            }
            else {
              if (KycResult.isExtensionValid == false) {
                e.target.value = null;
                if (e.target.name == "ProductPriceData") {
                  this.toastr.warning("Please attach the file having extension :\n\n .xlsx");
                }
                else {
                  this.toastr.warning("Please attach the file having extension :\n\n .xls, .xlsx");
                }
              }
            }
          }
        }
      }
    })
  }
  CheckFileValidation(File: any, ValidFileType: any[]) {
    var Result = {
      isExtensionValid: false,
    };
    let FileType = File.type;
    for (var j = 0; j < ValidFileType.length; j++) {
      if (FileType.toLowerCase() == ValidFileType[j]) {
        Result.isExtensionValid = true;
        break;
      }
    }
    return Result;
  }

  openDialog(PriceData) {
    // Opening the dialog component
    const dialogWithForm = this.dialog.open(ProductSeriesUploadLogComponent, {
      data: PriceData
    });
  }
  ExportData(seriesId: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: {
        title: "Are you sure",
        message: "You want to download the price list?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      // if user pressed yes dialogResult will be true, 
      // if he pressed no - it will be false
      if (dialogResult) {
        this.showLoader = true;
        let queryString = "";
        queryString = "seriesId=" + seriesId;
        this.CommonService.GetData("ProductModel", "Export", queryString).subscribe((data: any) => {
          this.ProductPriceData = data;
          if (this.ProductPriceData != undefined) {
            this.exportAsXLSX(this.ProductPriceData, data.SelectedSeriesName);
          }
          else {
            this.toastr.error("Failed to load data!");
          }
        }, error => {
          this.toastr.error("Failed to load data!");
          this.showLoader = false;
        });
        this.showLoader = false;
      }
    });
  }
  public exportAsXLSX(arrayofdata, filename: string): void {
    this.exportAsExcelFile(arrayofdata, filename);
  }
  exportAsExcelFile(json: any, excelFileName: string): void {

    let workbook = new Excel.Workbook();

    // Product Price Model
    this.ProductModelPrice = json.ProductPriceModel;
    if (this.ProductModelPrice != null) {
      let header = ['Product Series Name', 'Product Model Name', 'Product Model Price'];
      workbook = this.getworkSheet(this.ProductModelPrice, workbook, 'Product Model', header);
    }

    // Product Price station List
    this.ProductPriceStation = json.ProductPriceStation;
    if (this.ProductPriceStation != null) {
      let header = ['Product Series Name', 'Product Model Name', json.LableHeader['Label1'], json.LableHeader['Label1'] + " Price",'Price On Request'];
      workbook = this.getworkSheet(this.ProductPriceStation, workbook, header[2], header);
    }

    //Product Price Speed List
    if(excelFileName=="POM"||excelFileName=="HP"||excelFileName=="EF2"){
    this.ProductPriceSpeed = json.ProductPriceSpeedPOM;
    if (this.ProductPriceSpeed != null) {
      let Speedheader = ['Product Series Name', 'Product Model Name', json.LableHeader['Label2'],json.LableHeader['Label2'] + " Price",'Price On Request'];
      workbook = this.getworkSheet(this.ProductPriceSpeed, workbook, Speedheader[2], Speedheader);
    }
  }else{
    this.ProductPriceSpeed = json.ProductPriceSpeed;
    if (this.ProductPriceSpeed != null) {
      let Speedheader = ['Product Series Name', 'Product Model Name',json.LableHeader['Label1'], json.LableHeader['Label2'],  json.LableHeader['Label2'] + " Price",'Price On Request'];
      workbook = this.getworkSheet(this.ProductPriceSpeed, workbook, Speedheader[3], Speedheader);
    }
  }

    //Product Price DP List       
    this.ProductPriceDP = json.ProductPriceDP;
    if (json.ProductPriceDP != null) {
      let DPheader = ['Product Series Name', 'Product Model Name', json.LableHeader['Label3'], json.LableHeader['Label3'] + " Price",'Price On Request'];
      workbook = this.getworkSheet(this.ProductPriceDP, workbook, DPheader[2], DPheader);
    }7
    //Product Price MP List    
    this.ProductPriceMP = json.ProductPriceMP;
    if (this.ProductPriceMP != null) {
      let MPheader = ['Product Series Name', 'Product Model Name', json.LableHeader['Label4'], json.LableHeader['Label4'] + " Price",'Price On Request'];
      workbook = this.getworkSheet(this.ProductPriceMP, workbook, MPheader[2], MPheader);
    }

    //Product Option Price List
    this.ProductOptionPrice = json.ProductOptionPrice;
    if (this.ProductOptionPrice != null) {
      let Optionheader = ['Product Series Name','Product Option','Product Model Name',  'Product Option Price','Price On Request'];
      workbook = this.getworkSheet(this.ProductOptionPrice, workbook, 'Product Option', Optionheader);
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.saveAsExcelFile(data, excelFileName);
    });
  }
  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }

  getworkSheet(sheetdata: any, workbook: any, worksheetName: string, headers: any[]) {
    let worksheet = workbook.addWorksheet(worksheetName.replace('/','_'));
    let headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '74B4FE' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10,
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    for (let key of Object.keys(sheetdata)) {
      let tmparray = [];
      let tmpobj = sheetdata[key];

      for (let key of Object.keys(tmpobj)) {
        let tmpobj1 = tmpobj[key];
        tmparray.push(tmpobj1)
      }
      worksheet.addRow(tmparray);
      worksheet.re
    }
    return workbook;
  }

  /********************************
   * Download List History
   ***********************************/
   DownloadListHistory()
   {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: {
        title: "Are you sure",
        message: "You want to download price list history?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;
        this.CommonService.GetData("ProductModel", "DownloadListHistory", null).subscribe((data: any) => {
          this.ProductPriceLogData = data;
          if (this.ProductPriceLogData != undefined) {
            this.exportAsXLSXLog(this.ProductPriceLogData,"Log File");
          }
          else {
            this.toastr.error("Failed to load data!");
          }
          this.showLoader = false;
        }, error => {
          this.toastr.error("Failed to load data!");
        });
        this.showLoader = false;
      }
    })
  }
  public exportAsXLSXLog(arrayofdata, filename: string): void {
    this.exportAsExcelFileLog(arrayofdata, filename);
  }
  exportAsExcelFileLog(json: any, excelFileName: string): void {

    let workbook = new Excel.Workbook();

    // Product Price Model
    if (json.ProductModelLog != null) {
      let header = ['Series Name', 'Model Name','Model Old Price', 'Model New Price','Date'];
      workbook = this.getworkSheetLog(json.ProductModelLog, workbook, 'Product Model', header);
    }

    // Product Price station List
    if (json.ProductPriceVariant1Log != null) {
      let header = ['Series Name', 'Model Name','Variant1','Model Old Price','Model New Price','Date'];
      workbook = this.getworkSheetLog(json.ProductPriceVariant1Log, workbook, 'Variant1', header);
    }

    //Product Price Speed List
    if (json.ProductPriceVariant2Log != null) {
      let header = ['Series Name', 'Model Name','Variant2','Model Old Price','Model New Price','Date'];
      workbook = this.getworkSheetLog(json.ProductPriceVariant2Log, workbook, 'Variant2', header);
    }

    //Product Price DP List       
    if (json.ProductPriceVariant3Log != null) {
      let header = ['Series Name', 'Model Name','Variant3','Model Old Price', 'Model New Price','Date'];
      workbook = this.getworkSheetLog(json.ProductPriceVariant3Log, workbook, 'Variant3', header);
    }
    //Product Price MP List    
    if (json.ProductPriceVariant4Log != null) {
      let header = ['Series Name', 'Model Name','Variant4','Model Old Price', 'Model New Price','Date'];
      workbook = this.getworkSheetLog(json.ProductPriceVariant4Log, workbook, 'Variant4', header);
    }

    //Product Option Price List
    if (json.ProductOptionLog != null) {
      let header = ['Series Name','Product Option','Option Old Price', 'Option New Price','Date'];
      workbook = this.getworkSheetLog(json.ProductOptionLog, workbook, 'Product Option', header);
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.saveAsExcelFileLog(data, excelFileName);
    });
  }
  public saveAsExcelFileLog(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }

  getworkSheetLog(sheetdata: any, workbook: any, worksheetName: string, headers: any[]) {
    let worksheet = workbook.addWorksheet(worksheetName.replace('/','_'));
    let headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '74B4FE' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10,
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    for (let key of Object.keys(sheetdata)) {
      let tmparray = [];
      let tmpobj = sheetdata[key];

      for (let key of Object.keys(tmpobj)) {
        let tmpobj1 = tmpobj[key];
        tmparray.push(tmpobj1)
      }
      worksheet.addRow(tmparray);
      worksheet.re
    }
    return workbook;
  }
  closePopup() {
    this.activeModal.close('Close Click');
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductSeriesEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      this.isProductPriceEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='IsProductPriceEditableAccess')[0]?.IsVisible;
    });
 
  }
}








