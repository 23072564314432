import { AfterViewInit, Component, DoCheck, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { EngagementReportService } from "src/app/Service/engagement-report.service";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: "app-activity-status-by-month",
  templateUrl: "./activity-status-by-month.component.html",
  styleUrls: ["./activity-status-by-month.component.scss"],
})
export class ActivityStatusByMonthComponent implements AfterViewInit, OnDestroy, DoCheck {
  constructor(private engagementReport: EngagementReportService) { }

  subscription: Subscription;
  private chart: am4charts.XYChart;

  @Input() date: { from: any, to: any };
  
  prevDateFrom: any = '';
  prevDateTo: any = '';

  ngAfterViewInit(): void {
    let filters = {
      reportTypeId: "REP_BY_ACT_Status_MONTH",
      // StartDate: null,
      // EndDate: null,
      // ActivityTypeId: null,
      // ActivityStatusId: null
    };
    this.getData(filters)
  }

  ngDoCheck(): void {    
      
      if(this.date.from !== this.prevDateFrom || this.date.to !== this.prevDateTo) {

        let filters = {
          reportTypeId: "REP_BY_ACT_Status_MONTH",
          StartDate: this.date.from,
          EndDate: this.date.to,
        }

        this.prevDateFrom = this.date.from;
        this.prevDateTo = this.date.to;
        this.getData(filters);
      }
  }

  getData(filters) {
    this.subscription = this.engagementReport.getReportData(filters).subscribe(
      data => this.showChart(data),
      err => console.log(err)
    );
  }

  showChart(data: any) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("Activity-Status-Month-Chart", am4charts.XYChart);

    // transforming and providing the chart data
    chart.data = this.engagementReport.transformChartData(
      'MonthYear',
      'ActivityCount',
      'ActivityStatusDesc',
      data
    );

    // creating category axis based on DueMonth
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "MonthYear";
    categoryAxis.title.text = "Month";
    categoryAxis.title.fontSize = "16px";
    categoryAxis.title.fontWeight = "bold";
    categoryAxis.title.margin(10, 10, 10, 10);
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;

    // creating value axis on count by amcharts
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Count";
    valueAxis.title.fontSize = "16px";
    valueAxis.title.fontWeight = "bold";


    let seriesNames = this.engagementReport.getAllSeriesName('ActivityStatusDesc', data);

    seriesNames.forEach((seriesname, index) => {
      let seriescolor = this.engagementReport.seriesColors[index]
      createSeries(seriesname, seriescolor)
    })
    
    // create series for each series name
    function createSeries(field: any, color: any) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = field;
      series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
      series.columns.template.width = am4core.percent(80);

      series.columns.template.fill = color;
      series.columns.template.stroke = am4core.color(color);

      series.dataFields.valueY = field;
      series.dataFields.categoryX = "MonthYear";
    }

    // Enable export
    //chart.exporting.menu = new am4core.ExportMenu();
    //chart.exporting.filePrefix = "Activity_Chart";

    chart.legend = new am4charts.Legend();

    chart.scrollbarX = new am4core.Scrollbar();

    // chart.events.on("ready", function () {
    //   categoryAxis.zoomToCategories("Jan", "Jun");
    // });

    this.chart = chart;
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.chart) this.chart.dispose();
  }
}


