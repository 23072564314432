<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Product Series
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Product Line:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="productline"
                                            [(ngModel)]="ProductSeriesobj.ProductLine" #productline="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productline.invalid || productline?.touched && productline.invalid}"
                                            (change)='onProductLineSelect($event)' [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Product Line</option>
                                            <option *ngFor="let item of ProductLine" value="{{item.Id}}">{{item.Name}}
                                            </option>
                                            <option disabled *ngIf="ProductLine.length <= 0" value="No Data Found"> No
                                                Data Found</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productline.invalid || productline?.touched && productline.invalid">
                                            <div *ngIf="myform.hasError('required', 'productline')">
                                                Product line is required
                                            </div>
                                        </div>

                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Product Family:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="productfamily"
                                            [(ngModel)]="ProductSeriesobj.ProductFamily" #productfamily="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productfamily.invalid || productfamily?.touched && productfamily.invalid}"
                                            [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Product Family</option>
                                            <option *ngFor="let item of ProductFamily" value="{{item.Id}}">
                                                {{item.ProductFamilyName}}</option>
                                            <option disabled *ngIf="ProductFamily.length <= 0" value="No Data Found"> No
                                                Data Found</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productfamily.invalid || productfamily?.touched && productfamily.invalid">
                                            <div *ngIf="myform.hasError('required', 'productfamily')">
                                                Product family is required
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2  col-form-label">Product Series:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productseriesname"
                                            [(ngModel)]="ProductSeriesobj.SeriesName" #productseriesname="ngModel"
                                            maxlength="14"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productseriesname.invalid || productseriesname?.touched && productseriesname.invalid}"
                                            placeholder="Enter Product Series" (keypress)="AvoidSpace($event)"
                                            [disabled]="isEditable"
                                            (change)="ProductSeriesobj.SeriesName=ProductSeriesobj.SeriesName.trim()"
                                            required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productseriesname.invalid || productseriesname?.touched && productseriesname.invalid">
                                            <div *ngIf="myform.hasError('required', 'productseriesname')">
                                                Product series is required</div>
                                            <div *ngIf="myform.hasError('maxlength', 'productseriesname')">
                                                Product series must not exceed 14 characters
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Variant-1:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="var1"
                                            [(ngModel)]="ProductSeriesobj.Label_Variant1" #var1="ngModel"
                                            placeholder="Enter Variant-1" (keypress)="AvoidSpace($event)"
                                            [disabled]="isEditable"
                                            (change)="ProductSeriesobj.Label_Variant1=ProductSeriesobj.Label_Variant1.trim();OnVariantChange()"
                                            maxlength="50">
                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Variant-2:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="var2"
                                            [(ngModel)]="ProductSeriesobj.Label_Variant2" #var2="ngModel"
                                            placeholder="Enter Variant-2" (keypress)="AvoidSpace($event)"
                                            [disabled]="isEditable"
                                            (change)="ProductSeriesobj.Label_Variant2=ProductSeriesobj.Label_Variant2.trim();OnVariantChange()"
                                            maxlength="50">
                                    </div>

                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Variant-3:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="var3"
                                            [(ngModel)]="ProductSeriesobj.Label_Variant3" #var3="ngModel"
                                            placeholder="Enter Variant-3" (keypress)="AvoidSpace($event)"
                                            [disabled]="isEditable"
                                            (change)="ProductSeriesobj.Label_Variant3=ProductSeriesobj.Label_Variant3.trim();OnVariantChange()"
                                            maxlength="50">
                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Variant-4:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="var4"
                                            [(ngModel)]="ProductSeriesobj.Label_Variant4" #var4="ngModel"
                                            placeholder="Enter Variant-4" (keypress)="AvoidSpace($event)"
                                            [disabled]="isEditable"
                                            (change)="ProductSeriesobj.Label_Variant4=ProductSeriesobj.Label_Variant4.trim();OnVariantChange()"
                                            maxlength="50">
                                    </div>

                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Technical Catalogue:</label>
                                    <div class="col-lg-3">
                                       
                                            <div class="file">
                                                <i class="flaticon-file-2"></i>
                                                <input type="file" class="form-control-file" id="catalog" name="catalog"   [disabled]="isEditable"
                                                (change)="editFileDetails($event,0)"/>
                                                <br />
                                            </div>

                                            <a href="{{catlogDownloadPath}}{{ProductSeriesobj.CatlogName}}"
                                            target="_blank">{{ProductSeriesobj.CatlogName}}</a>

                                    </div>
                                </div>

                                <div class="form-group m-form__group row">
                                    <div class="col-lg-3">
                                        <mat-checkbox name="dep1" #dep1="ngModel"
                                            [(ngModel)]="ProductSeriesobj.IsLevel2DepOnLevel1"
                                            [disabled]="isEditable || ProductSeriesobj.Label_Variant1 == '' || ProductSeriesobj.Label_Variant2 == ''">
                                            <label>Is Variant-2 Depends On Variant-1</label>
                                        </mat-checkbox>
                                    </div>
                                    <div class="offset-1"></div>
                                    <div class="col-lg-3">
                                        <mat-checkbox name="dep2" #dep2="ngModel"
                                            [(ngModel)]="ProductSeriesobj.IsLevel3DepOnLevel2"
                                            [disabled]="isEditable || ProductSeriesobj.Label_Variant2 == '' || ProductSeriesobj.Label_Variant3 == ''">
                                            <label>Is Variant-3 Depends On Variant-2</label>
                                        </mat-checkbox>
                                    </div>
                                    <div class="offset-1"></div>
                                    <div class="col-lg-3">
                                        <mat-checkbox name="dep3" #dep3="ngModel"
                                            [(ngModel)]="ProductSeriesobj.IsLevel4DepOnLevel3"
                                            [disabled]="isEditable || ProductSeriesobj.Label_Variant3 == '' || ProductSeriesobj.Label_Variant4 == ''">
                                            <label>Is Variant-4 Depends On Variant-3</label>
                                        </mat-checkbox>
                                    </div>

                                </div>
                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isProductSeriesEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/ProductSeries']" routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>