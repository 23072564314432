<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper ">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">

                        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg mb-3">
                            <div class="m-portlet__head">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Price Configurator
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div class="m-portlet__body">
                                <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right"
                                    name="PriceConfiguratorForm" (ngSubmit)="PriceConfiguratorForm.form.valid"
                                    #PriceConfiguratorForm="ngForm" appFocusInvalidInput novalidate>
                                    <div id="ExportContent" class="m-portlet__body pb-2 pt-2">
                                        <div class="row p-3 justify-content-end">
                                            <input type="button" class="btn btn-primary mr-3" id="addrowss"
                                                value="Add Product" (click)="AddNewProduct()">
                                        </div>
                                        <table [ngClass]=" { 'disabledStepper':  !isQuotationEditable } "
                                            class="table order-lists table-responsive table-cust bord "
                                            style="margin-bottom:-1px;">
                                            <thead class="quoteheader">
                                                <th class="text-center" style="width: 8%;">Series</th>
                                                <th class="text-center" style="width: 8%;">Model</th>
                                                <th class="text-center" style="width: 8%;"></th>
                                                <th class="text-center" style="width: 8%;"></th>
                                                <th class="text-center" style="width: 8%;"></th>
                                                <th class="text-center" style="width: 8%;"></th>
                                                <th class="text-center" style="width: 22%;">Options</th>
                                                <th class="text-center" style="width: 7%;">Qty</th>
                                                <!-- <th class="text-center" style="width: 10%;">Per Unit Amount (₹)</th> -->
                                              
                                                
                                                <th class="text-center" style="width: 10%;">Total Amount (₹)</th>
                                                <!-- <th class="text-center" style="width: 2%;">Tech Spec</th> -->
                                                <th class="text-center" style="width: 1.5%;"></th>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let field of QuotationData.QuotationObj;let i = index;">
                                                    <tr style="height: 10px;">
                                                        <td rowspan="3">
                                                            <select class="form-control"
                                                                style="border: none;padding: 10px;"
                                                                placeholder="Select Series"
                                                                [(ngModel)]="field.ProductSeries"
                                                                #ProductSeries="ngModel" name="ProductSeries{{i}}"
                                                                (change)="onProductSeriesChange(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option *ngFor="let ser of dropdownData.ProductSeries"
                                                                    value="{{ser.Id}}">
                                                                    {{ser.Name}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="(field.ProductSeriesValidation)">
                                                                <span class="validationmsg">
                                                                    ProductSeries is required</span>
                                                            </div>
                                                        </td>
                                                        <td rowspan="2">
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedProductModel.length!=0"
                                                                placeholder="Select Model"
                                                                [(ngModel)]="field.ProductModel" #ProductModel="ngModel"
                                                                name="ProductModel{{i}}"
                                                                (change)="onProductModelChange(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option *ngFor="let ser of field.selectedProductModel"
                                                                    value="{{ser.Id}}">
                                                                    {{ser.Name}}
                                                                </option>
                                                            </select>
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedProductModel.length==0"
                                                                placeholder="Select Model"
                                                                [(ngModel)]="field.ProductModel" #ProductModel="ngModel"
                                                                name="ProductModel{{i}}"
                                                                (change)="onProductModelChange(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option
                                                                    *ngFor="let ser of productDrpdnDetails.ProductModel"
                                                                    value="{{ser.Id}}">
                                                                    {{ser.Name}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="(field.ProductModelValidation)">
                                                                <span class="validationmsg">
                                                                    Product Model is required</span>
                                                            </div>
                                                        </td>
                                                        <th [attr.colspan]="field.colspan1" *ngIf="field.colspan1!=0"
                                                            class="text-center" style="background-color:#ededed">
                                                            {{field.Variant1Label}}
                                                        </th>
                                                        <th [attr.colspan]="field.colspan2" *ngIf="field.colspan2!=0"
                                                            class="text-center" style="background-color:#ededed">
                                                            {{field.Variant2Label}}
                                                        </th>
                                                        <th [attr.colspan]="field.colspan3" *ngIf="field.colspan3!=0"
                                                            class="text-center" style="background-color:#ededed">
                                                            {{field.Variant3Label}}
                                                        </th>
                                                        <th [attr.colspan]="field.colspan4" *ngIf="field.colspan4!=0"
                                                            class="text-center" style="background-color:#ededed">
                                                            {{field.Variant4Label}}
                                                        </th>
                                                        <td style="padding: 0px!important; border-radius: 0px!important;"
                                                            rowspan="2">

                                                            <mat-select class="form-control" name="opt{{i}}"
                                                                placeholder="Select Options" multiple
                                                                [(ngModel)]="field.OptionSelected"
                                                                *ngIf="field.selectedOptions.length==0"
                                                                style="border: none;" required>
                                                                <mat-option *ngFor="let item of Options"
                                                                    value="{{item.Id}}"
                                                                    (onSelectionChange)="onOptionSelect($event,i)"
                                                                    title="{{item.Name}}">
                                                                    {{item.Name}}</mat-option>

                                                            </mat-select>
                                                            <mat-select class="form-control" name="opt{{i}}"
                                                                placeholder="Select Options" multiple
                                                                [(ngModel)]="field.OptionSelected"
                                                                *ngIf="field.selectedOptions.length!=0"
                                                                style="border: none;" required>
                                                                <mat-option *ngFor="let item of field.selectedOptions"
                                                                    title="{{item.Name}}" value="{{item.Id}}"
                                                                    (onSelectionChange)="onOptionSelect($event,i)">
                                                                    {{item.Name}}</mat-option>
                                                            </mat-select>


                                                            <div *ngFor="let j of field.Options;let o = index">
                                                                &nbsp;&nbsp;{{o+1}}) {{j.Name}}(₹ {{j.Price == undefined
                                                                ? 0:
                                                                j.Price |indianCurrency}})<br />
                                                            </div>
                                                            <div *ngIf="(field.OptionsValidation)">
                                                                <span class="validationmsg">
                                                                    Options are required</span>
                                                            </div>

                                                        </td>
                                                        <td rowspan="3"
                                                            style="padding: 0px!important; border-radius: 0px!important;">
                                                            <input type="number" class="form-control m-input" min="1"
                                                                step="1" (change)="onQuatityChange(i)" value="0"
                                                                name="Quantity{{i}}" maxlength="9"
                                                                [(ngModel)]="field.Quantity"
                                                                (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                style="border:none;text-align:right;">
                                                            <div *ngIf="(field.QuantityValidation)">
                                                                <span class="validationmsg">
                                                                    Quantity is not valid</span>
                                                            </div>
                                                            <div *ngIf="(field.QuantityRangeValidation)">
                                                                <span class="validationmsg">
                                                                    Quantity should be in between 0 to 1000</span>
                                                            </div>
                                                        </td>
                                                        <td rowspan="3" class="text-center">
                                                            {{field.TotalAmount == undefined ? 0: field.TotalAmount
                                                            |indianCurrency}}
                                                          
                                                        <td rowspan="3" class="text-center"
                                                            style="padding: 10px!important; border-radius: 0px!important;cursor: pointer;">
                                                            <i class="flaticon-delete" style="color:#f4516c"
                                                                (click)="DeleteProduct(i)"
                                                                *ngIf="(QuotationData.QuotationObj.length-1) == i"
                                                                title="Remove"><br></i>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td [attr.colspan]="field.colspan1" *ngIf="field.colspan1!=0"
                                                            class="text-center">
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant1.length!=0 && !(field.Variant1Label == null || field.Variant1Label == null || field.Variant1Label == '')"
                                                                [(ngModel)]="field.Variant1" #var1="ngModel"
                                                                name="var1{{i}}" placeholder="Select.."
                                                                (change)="onVariant1Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option *ngFor="let varient1 of field.selectedVariant1"
                                                                    value="{{varient1.Id}}">
                                                                    {{varient1.Name}}
                                                                </option>
                                                            </select>
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant1.length==0 && !(field.Variant1Label == null || field.Variant1Label == null || field.Variant1Label == '')"
                                                                [(ngModel)]="field.Variant1" #var1="ngModel"
                                                                name="var1{{i}}" placeholder="Select.."
                                                                (change)="onVariant1Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option
                                                                    *ngFor="let varient1 of productDrpdnDetails.Variant1"
                                                                    value="{{varient1.Id}}">
                                                                    {{varient1.Name}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="(field.Variant1Validation)">
                                                                <span class="validationmsg">
                                                                    {{field.Variant1Label}} is required</span>
                                                            </div>
                                                            <div *ngIf="field.IsUnits_Variant1"
                                                                style="text-align: left;margin-top: -9px;">
                                                                <label style="margin-bottom: 0px;">Units:</label>
                                                                <input type="number" class="form-control m-input"
                                                                    min="1" style="text-align:right;"
                                                                    (change)="var1UnitsChange(i)" value="0"
                                                                    name="var1Units{{i}}"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var1_Unit_Validation }"
                                                                    [(ngModel)]="field.Units_Variant1">
                                                            </div>
                                                        </td>
                                                        <td [attr.colspan]="field.colspan2" *ngIf="field.colspan2!=0"
                                                            class="text-center">
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant2.length!=0 && !(field.Variant2Label == null || field.Variant2Label == null || field.Variant2Label == '')"
                                                                [(ngModel)]="field.Variant2" #var2="ngModel"
                                                                name="var2{{i}}" placeholder="Select.."
                                                                (change)="onVariant2Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option *ngFor="let varient2 of field.selectedVariant2"
                                                                    value="{{varient2.Id}}">
                                                                    {{varient2.Name}}
                                                                </option>
                                                            </select>
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant2.length==0 && !(field.Variant2Label == null || field.Variant2Label == null || field.Variant2Label == '')"
                                                                [(ngModel)]="field.Variant2" #var2="ngModel"
                                                                name="var2{{i}}" placeholder="Select.."
                                                                (change)="onVariant2Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option
                                                                    *ngFor="let varient2 of productDrpdnDetails.Variant2"
                                                                    value="{{varient2.Id}}">
                                                                    {{varient2.Name}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="(field.Variant2Validation)">
                                                                <span class="validationmsg">
                                                                    {{field.Variant2Label}} is required</span>
                                                            </div>
                                                            <div *ngIf="field.IsUnits_Variant2"
                                                                style="text-align: left;margin-top: -9px;">
                                                                <label style="margin-bottom: 0px;">Units:</label>
                                                                <input type="number" class="form-control m-input"
                                                                    min="1" style="text-align:right;"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var2_Unit_Validation }"
                                                                    (change)="var2UnitsChange(i)" value="0"
                                                                    name="var2Units{{i}}"
                                                                    [(ngModel)]="field.Units_Variant2">
                                                            </div>
                                                        </td>
                                                        <td [attr.colspan]="field.colspan3" *ngIf="field.colspan3!=0"
                                                            class="text-center">
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant3.length!=0 && !(field.Variant3Label == null || field.Variant3Label == null || field.Variant3Label == '')"
                                                                [(ngModel)]="field.Variant3" #var3="ngModel"
                                                                name="var3{{i}}" placeholder="Select.."
                                                                (change)="onVariant3Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option *ngFor="let varient3 of field.selectedVariant3"
                                                                    value="{{varient3.Id}}">
                                                                    {{varient3.Name}}
                                                                </option>
                                                            </select>
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant3.length==0 && !(field.Variant3Label == null || field.Variant3Label == null || field.Variant3Label == '')"
                                                                [(ngModel)]="field.Variant3" #var3="ngModel"
                                                                name="var3{{i}}" placeholder="Select.."
                                                                (change)="onVariant3Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option
                                                                    *ngFor="let varient3 of productDrpdnDetails.Variant3"
                                                                    value="{{varient3.Id}}">
                                                                    {{varient3.Name}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="(field.Variant3Validation)">
                                                                <span class="validationmsg">
                                                                    {{field.Variant3Label}} is required</span>
                                                            </div>
                                                            <div *ngIf="field.IsUnits_Variant3"
                                                                style="text-align: left;margin-top: -9px;">
                                                                <label style="margin-bottom: 0px;">Units:</label>
                                                                <input type="number" class="form-control m-input"
                                                                    min="1" style="text-align:right;"
                                                                    (change)="var3UnitsChange(i)" value="0"
                                                                    name="var3Units{{i}}"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var3_Unit_Validation }"
                                                                    [(ngModel)]="field.Units_Variant3">
                                                            </div>
                                                        </td>
                                                        <td [attr.colspan]="field.colspan4" *ngIf="field.colspan4!=0"
                                                            class="text-center">
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant4.length!=0 && !(field.Variant4Label == null || field.Variant4Label == null || field.Variant4Label == '')"
                                                                [(ngModel)]="field.Variant4" #var4="ngModel"
                                                                name="var4{{i}}" placeholder="Select.."
                                                                (change)="onVariant4Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option *ngFor="let varient4 of field.selectedVariant4"
                                                                    value="{{varient4.Id}}">
                                                                    {{varient4.Name}}
                                                                </option>
                                                            </select>
                                                            <select class="form-control p-0" style="border: none;"
                                                                *ngIf="field.selectedVariant4.length==0 && !(field.Variant4Label == null || field.Variant4Label == null || field.Variant4Label == '')"
                                                                [(ngModel)]="field.Variant4" #var4="ngModel"
                                                                name="var4{{i}}" placeholder="Select.."
                                                                (change)="onVariant4Change(i)">
                                                                <option value="undefined" selected disabled>Select
                                                                </option>
                                                                <option
                                                                    *ngFor="let varient4 of productDrpdnDetails.Variant4"
                                                                    value="{{varient4.Id}}">
                                                                    {{varient4.Name}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="(field.Variant4Validation)">
                                                                <span class="validationmsg">
                                                                    {{field.Variant4Label}} is required</span>
                                                            </div>
                                                            <div *ngIf="field.IsUnits_Variant4"
                                                                style="text-align: left;margin-top: -9px;">
                                                                <label style="margin-bottom: 0px;">Units:</label>
                                                                <input type="number" class="form-control m-input"
                                                                    min="1" style="text-align:right;"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var4_Unit_Validation }"
                                                                    (change)="var4UnitsChange(i)" value="0"
                                                                    name="var4Units{{i}}"
                                                                    [(ngModel)]="field.Units_Variant4">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style="background-color:#ededed">
                                                        <td class="text-center">₹ {{field.ProductModelPrice == undefined
                                                            ? 0:
                                                            field.ProductModelPrice |indianCurrency}}/-</td>
                                                        <td [attr.colspan]="field.colspan1" *ngIf="field.colspan1!=0"
                                                            class="text-center">
                                                            <div style="color: #af0a25;font-size: 7pt;"
                                                                *ngIf="field.IsPriceOnRequest_Variant1">
                                                                Price on Request
                                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                                    style="text-align:right;"
                                                                    class="form-control m-input" min="0"
                                                                    (change)="var1PORChange(i)" value="0"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var1_POR_Validation }"
                                                                    name="Variant1Price{{i}}"
                                                                    [(ngModel)]="field.Variant1Price">
                                                            </div>
                                                            <div *ngIf="!field.IsPriceOnRequest_Variant1">
                                                                ₹ {{field.Variant1Price == undefined ? 0:
                                                                field.Variant1Price |
                                                                indianCurrency}}/-
                                                            </div>
                                                        </td>
                                                        <td [attr.colspan]="field.colspan2" *ngIf="field.colspan2!=0"
                                                            class="text-center">
                                                            <div style="color: #af0a25;font-size: 7pt;"
                                                                *ngIf="field.IsPriceOnRequest_Variant2">
                                                                Price on Request
                                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                                    style="text-align:right;"
                                                                    class="form-control m-input" min="0"
                                                                    (change)="var2PORChange(i)" value="0"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var2_POR_Validation }"
                                                                    name="Variant2Price{{i}}"
                                                                    [(ngModel)]="field.Variant2Price">
                                                            </div>
                                                            <div *ngIf="!field.IsPriceOnRequest_Variant2">
                                                                ₹ {{field.Variant2Price == undefined ? 0:
                                                                field.Variant2Price |
                                                                indianCurrency}}/-
                                                            </div>
                                                        </td>
                                                        <td [attr.colspan]="field.colspan3" *ngIf="field.colspan3!=0"
                                                            class="text-center">
                                                            <div style="color: #af0a25;font-size: 7pt;"
                                                                *ngIf="field.IsPriceOnRequest_Variant3">
                                                                Price on Request
                                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                                    style="text-align:right;"
                                                                    class="form-control m-input" min="0"
                                                                    (change)="var3PORChange(i)" value="0"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var3_POR_Validation }"
                                                                    name="Variant3Price{{i}}"
                                                                    [(ngModel)]="field.Variant3Price">
                                                            </div>
                                                            <div *ngIf="!field.IsPriceOnRequest_Variant3">
                                                                ₹ {{field.Variant3Price == undefined ? 0:
                                                                field.Variant3Price |
                                                                indianCurrency}}/-
                                                            </div>
                                                        </td>
                                                        <td [attr.colspan]="field.colspan4" *ngIf="field.colspan4!=0"
                                                            class="text-center">
                                                            <div style="color: #af0a25;font-size: 7pt;"
                                                                *ngIf="field.IsPriceOnRequest_Variant4">
                                                                Price on Request
                                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                                    style="text-align:right;"
                                                                    class="form-control m-input" min="0"
                                                                    (change)="var4PORChange(i)" value="0"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': field.var4_POR_Validation }"
                                                                    name="Variant4Price{{i}}"
                                                                    [(ngModel)]="field.Variant4Price">
                                                            </div>
                                                            <div *ngIf="!field.IsPriceOnRequest_Variant4">
                                                                ₹ {{field.Variant4Price == undefined ? 0:
                                                                field.Variant4Price |
                                                                indianCurrency}}/-
                                                            </div>
                                                        </td>
                                                        <td [attr.colspan]="field.colspan4" class="text-center">
                                                            <div *ngIf="field.IsPriceOnRequest_Option">
                                                                <span style="color: #af0a25;font-size: 7pt;">Price on
                                                                    Request</span>
                                                                <div rowspan="3" class="text-center"
                                                                    *ngIf="btn_PriceOnRequest"
                                                                    style="padding: 10px!important; border-radius: 0px!important;cursor: pointer;">
                                                                    <i class="fa fa-tasks"
                                                                        (click)="ShowOptionPriceOnRequestGrid(i)"
                                                                        title="Open option price on request grid"></i>

                                                                </div>
                                                            </div>
                                                            <div *ngIf="!field.IsPriceOnRequest_Option">
                                                                ₹ {{field.OptionsPrice == undefined ? 0:
                                                                field.OptionsPrice |indianCurrency}}/-
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="field.ShowOptionPriceOnRequestGrid">
                                                        <td colspan="14">
                                                            <div style="overflow-x:auto;">
                                                                <table class="table table-bordered table-edit"
                                                                    style="width: 57%;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Product Options</th>
                                                                            <th style="width: 20%;">Price (₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let t1 of field.Options;let j = index; ">
                                                                            <td *ngIf="t1.IsPriceOnRequest">{{t1.Name}}
                                                                            </td>
                                                                            <td *ngIf="t1.IsPriceOnRequest">
                                                                                <input type="number"
                                                                                    *ngIf="btn_PriceOnRequest"
                                                                                    id="Price{{i}}{{j}}"
                                                                                    style="text-align:right;"
                                                                                    class="form-control m-input" min="0"
                                                                                    (change)="varOptionPORChange(i,j)"
                                                                                    value="0"
                                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                                    [ngClass]="{ 'is-invalid': t1.optionValid }"
                                                                                    name="Price{{i}}{{j}}"
                                                                                    [(ngModel)]="t1.Price">
                                                                                <div *ngIf="(t1.optionValid)">
                                                                                    <span class="validationmsg">
                                                                                        Please enter valid price</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="field.showTechnicalSpecifications">
                                                        <td colspan="14">
                                                            <label>Technical Specifications:</label>
                                                            <div style="display: flex;margin-bottom: 5px;"
                                                                *ngFor="let t of field.TechnicalSpecifications;let s = index;">
                                                                <textarea class="form-control m-input"
                                                                    style="width:50%;" id="techspec" maxlength="1000"
                                                                    name="{{t.uniqueid}}" rows="3"
                                                                    [(ngModel)]="t.value"></textarea>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>


                                                <tr style="background-color:#f6d7d7;width: 100%;font-weight: bold;">
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center">Discount % </td>
                                                    <td class="text-center"><input type="number" class="form-control m-input" min="0"
                                                        max="99.99" step="0.01" value="0" style="text-align:right;" 
                                                        [(ngModel)]="specialPkgDiscount" (change)="setspecialDiscountPrice()" name="specialpkgdiscount"
                                                        (keypress)="AvoidSpace($event);isNumberKey($event);allowDecimal($event);"
                                                        name="Var" ></td> 
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                         
                                                </tr>
                                                <tr style="background-color:#f6d7d7;width: 100%;font-weight: bold;">
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"> Final Amount</td>
                                                    <td class="text-center"></td>
                                                    <td class="text-center"> {{QuotationData.QuotationInfo.FinalAmount
                                                        == undefined ? 0:
                                                        QuotationData.QuotationInfo.FinalAmount |indianCurrency}}</td>
                                                    <td class="text-center"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>