import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Level1Model, ProductSeries, Station, VariantLevel2 } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-variant-level2',
  templateUrl: './add-variant-level2.component.html',
  styleUrls: ['./add-variant-level2.component.scss']
})
export class AddVariantLevel2Component implements OnInit {
  ProductSeriesVariant2Label: string = '';
  VariantLevel2obj: VariantLevel2 = new VariantLevel2();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductSeries: ProductSeries = new ProductSeries();
  ProductModel: any[] = [];
  //To Stored Api data of VariantLevel1 and VariantLevel2 
  Level1arr: Level1Model[] = [];
  Level2arr: Level1Model[] = [];
  SpeedStepName: string = "";
  @ViewChild('closebutton') closebutton;

  //To bind current VariantLevel2 data
  Level2Currdata: any[] = [];
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title, private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.VariantLevel2obj.ProductSeries = this.route.snapshot.params["Seriesid"];
    this.getAllProductSeries();
    this.GetProductModelById(this.VariantLevel2obj.ProductSeries);
  }

  /*******************************************************************************
   * Get data for Variant Level 1
   * ******************************************************************************/
  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.VariantLevel2obj.ProductSeries;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.VariantLevel2obj.SeriesName = data.SeriesName;
      this.VariantLevel2obj.ProductSeries = data.Id;
      this.VariantLevel2obj.IsLevel2DepOnLevel1 = data.IsLevel2DepOnLevel1;
      this.VariantLevel2obj.Label_Variant1 = data.Label_Variant1;
      this.VariantLevel2obj.Label_Variant2 = data.Label_Variant2;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetProductModelById(SeriesId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "SeriesId=" + SeriesId;
    this.CommonService.GetData("ProductModel", "GetProductModelById", queryString).subscribe((data: any) => {
      this.ProductModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetAllSpeedStep(StationId, ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    queryString += "&StationId=" + StationId;
    this.CommonService.GetData("ProductVariantLevel2", "GetAllSpeedStep", queryString).subscribe((data: any) => {
      this.Level2arr = data;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }

  GetVariant1ByModelId(ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel1", "GetVariant1ByModelId", queryString).subscribe((data: any) => {
      this.Level1arr = data;
      if (this.VariantLevel2obj.SeriesName == 'TC' || this.VariantLevel2obj.SeriesName == 'TR') {
        this.Level1arr = this.Level1arr.sort((a, b) => parseInt(a.Level1) - parseInt(b.Level1));
      }
      else {
        this.Level1arr = this.Level1arr.sort((a, b) => a.Level1.localeCompare(b.Level1));
      }

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  /*******************************************************************************
  * End get data for Variant Level 1
  * ******************************************************************************/

  /*******************************************************************************
  * Form validation method
  * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }


  OpenPopupAddSpeedStep() {
    this.SpeedStepName = "";

  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onModelSelected(event: any) {
    this.VariantLevel2obj.Level1Id = null;
    this.Level1arr = [];
    this.Level2Currdata = [];
    this.Level2arr = [];
    this.GetVariant1ByModelId(event.target.value);
  }

  OnStationselected(event: any) {
    if (this.VariantLevel2obj.SeriesName == 'TC' || this.VariantLevel2obj.SeriesName == 'TR') {
      this.Level2Currdata = [];
      this.Level2arr = [];
      this.GetAllSpeedStep(event.target.value, this.VariantLevel2obj.ProductModel);
    }
  }

  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel2obj.Level2Price = 0;
    }
  }


  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this " + this.VariantLevel2obj.Label_Variant2 + " ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        for (var i = 0; i < this.Level2Currdata.length; i++) {
          var obj = { Id: null, Level1: this.Level2Currdata[i] }
          this.VariantLevel2obj.Level2.push(obj);
        }
        this.CommonService.PostData('ProductVariantLevel2', 'Add', this.VariantLevel2obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.VariantLevel2obj.Label_Variant2 + ' added successfully!');
            this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.VariantLevel2obj.Label_Variant2 + ' already exists!');
            this.VariantLevel2obj.Level2 = [];
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to ' + this.VariantLevel2obj.Label_Variant2 + '!');
            this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add ' + this.VariantLevel2obj.Label_Variant2 + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel2', this.VariantLevel2obj.ProductSeries, this.VariantLevel2obj.Label_Variant1, this.VariantLevel2obj.Label_Variant2]);
        });
      }
    });

  }
  
  AddSpeedStep(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this SpeedStep ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        let queryString = "";
        queryString = "SpeedStepName=" + this.SpeedStepName;
        this.CommonService.PostData('ProductVariantLevel2', 'AddSpeedStep', null, queryString).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('SpeedStep added successfully!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel2obj.ProductModel != null && this.VariantLevel2obj.ProductModel != undefined
              && this.VariantLevel2obj.ProductModel != 0) &&
              (this.VariantLevel2obj.Level1Id != null && this.VariantLevel2obj.Level1Id != undefined && this.VariantLevel2obj.Level1Id != 0)) {

              this.GetAllSpeedStep(this.VariantLevel2obj.Level1Id, this.VariantLevel2obj.ProductModel);
            }
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('SpeedStep already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add SpeedStep!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel2obj.ProductModel != null && this.VariantLevel2obj.ProductModel != undefined
              && this.VariantLevel2obj.ProductModel != 0) &&
              (this.VariantLevel2obj.Level1Id != null && this.VariantLevel2obj.Level1Id != undefined && this.VariantLevel2obj.Level1Id != 0)) {

              this.GetAllSpeedStep(this.VariantLevel2obj.Level1Id, this.VariantLevel2obj.ProductModel);
            }
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add SpeedStep!');
          this.closebutton.nativeElement.click();
          this.showLoader = false;
          if ((this.VariantLevel2obj.ProductModel != null && this.VariantLevel2obj.ProductModel != undefined
            && this.VariantLevel2obj.ProductModel != 0) &&
            (this.VariantLevel2obj.Level1Id != null && this.VariantLevel2obj.Level1Id != undefined && this.VariantLevel2obj.Level1Id != 0)) {

            this.GetAllSpeedStep(this.VariantLevel2obj.Level1Id, this.VariantLevel2obj.ProductModel);
          }
        });
      }
    });


  }

}
