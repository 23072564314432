import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppGlobals } from '../app.global';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  RefreshHeader = new EventEmitter(); 
  subsVar: Subscription;   
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  
  constructor(private http: HttpClient) { }

  
  GetData(ControllerName: string, ActionName: string, queryString: any) {   
    let userToken = localStorage.getItem("userToken");

    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    if (queryString != null) {
      return this.http.get(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName + '?' + queryString, { headers: reqHeader })
    }
    else {
      return this.http.get(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName, { headers: reqHeader });
    }
  }

  getpdf(ControllerName: string, ActionName: string, model: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    //headers = headers.set('Accept', 'application/pdf');
    return this.http.post<any>(AppGlobals.baseAppUrl + "/api/" + ControllerName + "/" + ActionName,
      model, httpOptions);
  }

  getPrintPreview(ControllerName: string, ActionName: string, queryString: any): Observable<any> {
    let userToken = localStorage.getItem("userToken");

    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    if (queryString != null) {
      return this.http.get(AppGlobals.baseAppUrl + ControllerName + "/" + ActionName + '?' + queryString, { headers: reqHeader ,responseType:"text"})
    }else{
      return of("");
    }
 
  }


  PostData(ControllerName: string, ActionName: string, data: any, queryString: any,pk_cutomer_id:number=0) {
    let userToken = localStorage.getItem("userToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
   
    var url = AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName + (queryString != null ? "?" + queryString : "")
    if (data != null) {
      return this.http.post(url, data, { headers: reqHeader });
    }
    else {
      return this.http.post(url, "", { headers: reqHeader });
    }
  }
  GetUserProfileData(ControllerName: string, ActionName: string) {
    let userToken = localStorage.getItem("userToken");
    var reqHeader = new HttpHeaders().append("Authorization", "Bearer " + userToken);
    return this.http.get(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName, { headers: reqHeader });
  }

  RefreshHeaderFun() {    
    this.RefreshHeader.emit();    
  }

  gereatePDF(ControllerName: string, ActionName: string, filename: any) {
    let userToken = localStorage.getItem("userToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    return this.http.get(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName+"?filename="+filename, { headers: reqHeader,responseType: 'blob' });   
  }

  getImageName(ControllerName: string, ActionName: String) {

    return this.http.get(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName);   
  }


  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  PostDataForCustomerEnagement(ControllerName: string, ActionName: string, data: any, queryString: any,cutomerid:number=0) {
    let userToken = localStorage.getItem("userToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    if (data != null && queryString == null) {
      if(cutomerid!=0)
      {
        queryString="pk_cutomer_id="+cutomerid;
        return this.http.post(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName + '?' + queryString, data, { headers: reqHeader });
      }
      return this.http.post(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName, data, { headers: reqHeader });     
    
    }
    else {
      return this.http.post(AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName + '?' + queryString, "", { headers: reqHeader });
    }

  }

  PostStatus(ControllerName: string, ActionName: string, queryString: any,Customerid:number=0) {
    let userToken = localStorage.getItem("userToken");
    var reqHeader = new HttpHeaders().set("Authorization", "Bearer " + userToken);
    var url = AppGlobals.baseAppUrl + "api/" + ControllerName + "/" + ActionName + (queryString != null ? "?" + queryString : "")+(Customerid != null ? "&Customerid=" + Customerid : "");
    return this.http.post(url, "", { headers: reqHeader });
  }

  convertArrayToObject(data: []): Observable<any> {
   const result = {};
    data.forEach(accesspermission => {
    const key = `${accesspermission['RoleName']}`;
        if (!result[key]) {
         result[key] = {};
        }
        if (!result[key][accesspermission['ElementName']]) {
          if(!result[key][accesspermission['ElementType']])
          {
            result[key][accesspermission['ElementType']] = [];
          }
        }
      
     result[key][accesspermission['ElementType']].push(accesspermission);
     });
    return of(result);

  }

  
  GetAllAccesspermission(): Observable<any> {
    return  this.GetData('AccessPermission', 'GetPermissionsForCurrentUser', null);
    //return null;
  }

}