    <table *ngIf="activities?.length" mat-table [dataSource]="dataSource"
        class="table order-list table-bordered table-hover table-edit" role="grid">

        <ng-container matColumnDef="ActivityType">
            <th mat-header-cell *matHeaderCellDef>Activity Type</th>
            <td mat-cell *matCellDef="let item" [ngStyle]="item.ActivityId == null || '' ? {'display':'none'} : {}">
                {{item.ActivityType}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Subject">
            <th mat-header-cell *matHeaderCellDef>Subject</th>
            <td mat-cell *matCellDef="let item"> {{item.Subject}} </td>
        </ng-container>

        <ng-container matColumnDef="DueDate">
            <th mat-header-cell *matHeaderCellDef>Due Date</th>
            <td mat-cell *matCellDef="let item"> {{item.PlannedDueDatetime | date}} </td>
        </ng-container>

        <ng-container matColumnDef="ActivityStatus">
            <th mat-header-cell *matHeaderCellDef>Activity Status</th>
            <td mat-cell *matCellDef="let item"> {{item.ActivityStatus}} </td>
        </ng-container>

        <ng-container matColumnDef="DelayedBy">
            <th mat-header-cell *matHeaderCellDef>Delayed By</th>
            <td mat-cell *matCellDef="let item"> {{item.DueDays < 0 ? 0: item.DueDays}} </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let item">
                <button *ngIf="item.ActivityStatus != 'Completed'" class="btn" (click)="closePopup()" [routerLink]="['/Edit-Activity', item.ActivityId]"> <i
                        title="Edit" class="fas fa-edit" style="color:#f4516c"></i></button>

                <button class="btn" (click)="closePopup(); openViewPopup(item.ActivityId)">
                    <i class="fa fa-eye fa-lg" title="View" style="color:#f4516c"></i>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let emprow; columns: displayedColumns;"></tr>


    </table>
    
    <div *ngIf="activities?.length <= 0">
        <h5 style="padding: 50px 20px;">No records found</h5>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>

    <!-- 
<table class="table order-list table-striped- table-bordered table-hover table-edit" role="grid">
    <thead>
        <tr>
            <th *ngif="HideId">Activity ID</th>
            <th>Activity Type</th>
            <th>Subject</th>
            <th>Due Date</th>
            <th>Activity Status</th>
            <th>Delayed By</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let activityRow of filteredActivities">
            <td *ngif="HideId">{{activityRow.ActivityId}}</td>
            <td [ngStyle]="activityRow.ActivityId == null || '' ? {'display':'none'} : {} ">
                {{activityRow.ActivityType}}
            </td>
            <td>
                {{activityRow.Subject}}
            </td>
            <td>
                {{activityRow.PlannedDueDatetime | date}}
            </td>
            <td>
                {{activityRow.ActivityStatus}}
            </td>
            <td>
                {{ activityRow.DueDays < 0 ? 0: activityRow.DueDays}} </td>
            <td>
                <button class="btn" (click)="closePopup()" [routerLink]="['/Edit-Activity', activityRow.ActivityId]"> <i
                        title="Edit" class="fas fa-edit" style="color:#f4516c"></i></button>

                <button class="btn" (click)="closePopup()" [routerLink]="['/View-Activity', activityRow.ActivityId]">
                    <i class="fa fa-eye fa-lg" title="View" style="color:#f4516c"></i>
                </button>
            </td>
        </tr>
    </tbody>
</table> -->