import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Level1Model, ProductSeries, VariantLevel3 } from 'src/app/Model/ProductModel';
import { AppGlobals } from 'src/app/app.global';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

@Component({
  selector: 'app-edit-view-variant-level3',
  templateUrl: './edit-view-variant-level3.component.html',
  styleUrls: ['./edit-view-variant-level3.component.scss']
})
export class EditViewVariantLevel3Component implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title,private accessservice: AccessPermissionService
    ) { }
  VariantLevel3obj: VariantLevel3 = new VariantLevel3();
  isEditable: boolean = true;
  FormStatus: string = "View";
  charCode: any;
  @Input()
  showLoader: boolean = false;
  queryString: string = "";
  //To bind current VariantLevel3 data
  Level3Currdata: any[] = [];
  Variant1Arr: any[] = [];
  ProductSeries: ProductSeries = new ProductSeries();
  Level3arr: Level1Model[] = [];
  Level2arr: Level1Model[] = [];
  UserRole: any;
  isProductVariant3Editable: boolean = false;
  PositionName: string="";
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  @ViewChild('closebutton') closebutton;
  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
  //  this.isProductVariant3Editable = AppGlobals.ProductMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    if (this.route.snapshot.params["id"] == undefined) {
      this.queryString = "ProductModel=" + this.route.snapshot.params["Model"];
      this.queryString += "&Price=" + this.route.snapshot.params["Price"];
      this.queryString += "&Status=" + this.route.snapshot.params["Status"];
      this.queryString += "&IsPriceOnRequest=" + this.route.snapshot.params["IsPriceOnRequest"];
      this.getVariantLevel3IdDetailsById('GetbyProductModelAndPrice', this.queryString);
    }
    else {
      this.queryString = "Id=" + this.route.snapshot.params["id"];
      this.getVariantLevel3IdDetailsById('GetById', this.queryString);
    }
    
  }

  /*******************************************************************************
    * Get data for add variant level-1
    * ******************************************************************************/
  getVariantLevel3IdDetailsById(ActionName: string, queryString: string) {
    this.showLoader = true;

    this.CommonService.GetData('ProductVariantLevel3', ActionName, queryString).subscribe((data: any) => {
      this.VariantLevel3obj = data;
      this.getAllProductSeries();
    


      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });

  }

  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.VariantLevel3obj.ProductSeries;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      
      this.VariantLevel3obj.IsLevel3DepOnLevel2 = data.IsLevel3DepOnLevel2;      
      this.VariantLevel3obj.IsLevel2DepOnLevel1 = data.IsLevel2DepOnLevel1;
      this.VariantLevel3obj.SeriesName = data.SeriesName;
      this.VariantLevel3obj.ProductSeries = data.Id;
      this.VariantLevel3obj.Label_Variant1 = data.Label_Variant1;
      this.VariantLevel3obj.Label_Variant2 = data.Label_Variant2;
      this.VariantLevel3obj.Label_Variant3 = data.Label_Variant3;


      if (this.VariantLevel3obj.SeriesName == 'TC' || this.VariantLevel3obj.SeriesName == 'TR') {
        this.Level3arr = this.VariantLevel3obj.Level3;
        this.Level3Currdata = this.VariantLevel3obj.Level3.map(item => item.Level1.toString());
        this.GetAllPosition(this.VariantLevel3obj.ProductModel);
      }
      else {
        if(this.VariantLevel3obj.IsLevel2DepOnLevel1 && this.VariantLevel3obj.IsLevel3DepOnLevel2)
        {
          this.GetVariant1ByModelId(this.VariantLevel3obj.ProductModel);
          if(this.VariantLevel3obj.Level1Id!=null)
          {
            this.GetVariant2ByVariant1Id(this.VariantLevel3obj.Level1Id);
          }
         
        }
        else if(this.VariantLevel3obj.IsLevel2DepOnLevel1 && !this.VariantLevel3obj.IsLevel3DepOnLevel2)
        {
          this.GetVariant1ByModelId(this.VariantLevel3obj.ProductModel);
        
        }
        else if(this.VariantLevel3obj.IsLevel3DepOnLevel2 && !this.VariantLevel3obj.IsLevel2DepOnLevel1){
          this.GetVariant2ByModelId(this.VariantLevel3obj.ProductModel);
        }



        // if( this.VariantLevel3obj.IsLevel3DepOnLevel2)
        // {
        //   this.GetVariant1ByModelId(this.VariantLevel3obj.ProductModel);
        //   this.GetVariant2ByVariant1Id(this.VariantLevel3obj.Level1Id);
        // }
        // else{
        //   this.GetVariant2ByModelId(this.VariantLevel3obj.ProductModel);
        // }
        
      }
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetVariant2ByModelId(ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByModelId", queryString).subscribe((data: any) => {
      this.Level2arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetAllPosition(ModelId: number)//For TC and TC
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel3", "GetAllPosition", queryString).subscribe((data: any) => {
      this.Level3arr = this.VariantLevel3obj.Level3.concat(data);
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }


  GetVariant1ByModelId(ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel1", "GetVariant1ByModelId", queryString).subscribe((data: any) => {
      this.Variant1Arr = data;
      

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  GetVariant2ByVariant1Id(Variant1: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "Variant1=" + Variant1;
    queryString += "&ProductSeriesId=" + this.VariantLevel3obj.ProductSeries;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByVariant1Id", queryString).subscribe((data: any) => {
      this.Level2arr = data;

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }



  /*******************************************************************************
   * End get data for add variant level-1
   * ******************************************************************************/

  EditDetails() {
    this.isEditable = false;
    this.FormStatus = "Edit";
    this.titleService.setTitle("Edit Product Variant Level 3 - WEISS");
  }
  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel3obj.Level3Price = 0;
    }
  }

  onVariant1Selected(event: any)
  {
    this.VariantLevel3obj.Level2Id = null;
    this.GetVariant2ByVariant1Id(event.target.value);
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/
  DeleteDP() {
    this.VariantLevel3obj.Level3 = [];
    for (var i = 0; i < this.Level3Currdata.length; i++) {
      var obj = this.Level3arr.find(x => x.Level1 == this.Level3Currdata[i])
      this.VariantLevel3obj.Level3.push(obj);
    }
    this.VariantLevel3obj.Level3 = this.VariantLevel3obj.Level3.filter(x => x.Id != null);
    if (this.VariantLevel3obj.Level3.length == 0) {
      this.toastr.warning("This " + this.VariantLevel3obj.Label_Variant3 + " does not exist for " + this.VariantLevel3obj.ModelName + " product model")
      this.VariantLevel3obj.Level3 = [];
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to delete this " + this.VariantLevel3obj.Label_Variant3 + "?"
        }
      });

      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          this.showLoader = true;

          this.CommonService.PostData('ProductVariantLevel3', 'DeletePosition', this.VariantLevel3obj, null).subscribe((data: any) => {
            let result = data;

            if (result.ResponseCode == 1) {
              this.toastr.success(this.VariantLevel3obj.Label_Variant3 + ' deleted successfully!');
              this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
              this.showLoader = false;
            }
            else if (result.ResponseCode == 0) {
              this.toastr.warning("Failed to delete this " + this.VariantLevel3obj.Label_Variant3 + " because it has some dependency!");
              this.showLoader = false;
            }
            else {
              this.toastr.error('Failed to delete ' + this.VariantLevel3obj.Label_Variant3 + '!');
              this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
              this.showLoader = false;
            }


          }, error => {
            this.toastr.error('Failed to delete ' + this.VariantLevel3obj.Label_Variant3 + '!');
            this.showLoader = false;
            this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
          });
        }
        else {
          this.VariantLevel3obj.Level3 = [];
        }
      });
    }
  }

  onSubmit(myform: any) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update this " + this.VariantLevel3obj.Label_Variant3 + "?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.showLoader = true;
        if (this.VariantLevel3obj.SeriesName == 'TC' || this.VariantLevel3obj.SeriesName == 'TR') {
          this.VariantLevel3obj.Level3 = [];
        }

        for (var i = 0; i < this.Level3Currdata.length; i++) {
          var obj = this.Level3arr.find(x => x.Level1 == this.Level3Currdata[i]);
          this.VariantLevel3obj.Level3.push(obj);
        }
        this.CommonService.PostData('ProductVariantLevel3', 'Update', this.VariantLevel3obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.VariantLevel3obj.Label_Variant3 + ' updated successfully!');
            this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.VariantLevel3obj.Label_Variant3 + ' already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to update ' + this.VariantLevel3obj.Label_Variant3 + '!');
            this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
            this.showLoader = false;
          }


        }, error => {
          this.toastr.error('Failed to update ' + this.VariantLevel3obj.Label_Variant3 + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel3', this.VariantLevel3obj.ProductSeries, this.VariantLevel3obj.Label_Variant2, this.VariantLevel3obj.Label_Variant3]);
        });
      }
    });
  }
  OpenPopupAddPosition()
  {
    this.PositionName = "";
  }
  AddPosition(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this Position ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        let queryString = "";
        queryString = "PositionName=" + this.PositionName;
        this.CommonService.PostData('ProductVariantLevel3', 'AddPosition', null, queryString).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('SpeedStep added successfully!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel3obj.ProductModel != null && this.VariantLevel3obj.ProductModel != undefined
              && this.VariantLevel3obj.ProductModel != 0)) {

              this.GetAllPosition(this.VariantLevel3obj.ProductModel);
            }
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Position already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add Position!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel3obj.ProductModel != null && this.VariantLevel3obj.ProductModel != undefined
              && this.VariantLevel3obj.ProductModel != 0)) {

              this.GetAllPosition(this.VariantLevel3obj.ProductModel);
            }
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add Position!');
          this.closebutton.nativeElement.click();
          this.showLoader = false;
          if ((this.VariantLevel3obj.ProductModel != null && this.VariantLevel3obj.ProductModel != undefined
            && this.VariantLevel3obj.ProductModel != 0)) {

            this.GetAllPosition(this.VariantLevel3obj.ProductModel);
          }
        });
      }
    });


  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isProductVariant3Editable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isProductLine')[0]?.IsEditable;
      
    });
 
  }
}

