<div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">

                        <!-- Header -->
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">Customer Engagement Details</h3>
                                </div>
                            </div>
                            <div class="m-portlet__head-tools">

                                <!-- <ul class="m-portlet__nav">
                                    <li class="m-portlet__nav-item">
                                        <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                            routerLink="/Add-Activity">
                                            Add activity
                                        </a>
                                    </li>
                                </ul> -->

                            </div>
                        </div>
                        <!-- Header ends -->

                        <!-- body -->
                        <div class="m-portlet__body">

                            <!-- Customer basic information -->
                            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg mt-2 mb-5">
                                <div class="m-portlet__head">
                                    <div class="m-portlet__head-caption">
                                        <div class="m-portlet__head-title">
                                            <h3 class="m-portlet__head-text">
                                                Basic Customer Information
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-portlet__body" style="padding: 0.7rem 2.2rem;">

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label"><b>Customer Code:</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.CustomerCode}}
                                        </div>
                                        <div class="offset-1"></div>
                                        <label class="col-lg-2 col-form-label"><b>Company Name:</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.CompanyName}}
                                        </div>
                                    </div>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label"><b>Address Headquarters:</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.AddressHeadquaters}}
                                        </div>
                                        <div class="offset-1"></div>
                                        <label class="col-lg-2 col-form-label"><b>Address (Plant):</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.AddressPlant}}
                                        </div>
                                    </div>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label"><b>City:</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.City}}
                                        </div>
                                        <div class="offset-1"></div>
                                        <label class="col-lg-2 col-form-label"><b>State:</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.State}}
                                        </div>
                                    </div>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label"><b>Pin Code:</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.PINCode}}
                                        </div>
                                        <div class="offset-1"></div>
                                        <label class="col-lg-2 col-form-label"><b>Mail Address:</b></label>
                                        <div class="col-lg-3 col-form-label">
                                            {{CustomerModel.BasicCustomerInformationModel.MailAddress}}
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <!-- Customer basic information ends -->

                            <!-- customer engagement summary -->
                            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg mb-5">
                                <div class="m-portlet__head">
                                    <div class="m-portlet__head-caption">
                                        <div class="m-portlet__head-title">
                                            <h3 class="m-portlet__head-text">
                                                Customer Engagement Summary
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-portlet__body">
                                    <table class="table order-list table-bordered table-hover table-edit mb-5"
                                        role="grid">
                                        <thead>
                                            <tr>
                                                <th *ngFor="let data of roleWiseInfoKeys">{{data}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of roleWiseInfo">
                                                <ng-container *ngFor="let keyName of roleWiseInfoKeys; let i = index">
                                                    <td [ngStyle]="{'fontWeight': i === 0 ? 'bold' : ''}">
                                                        {{data[keyName] ? data[keyName] : 0}}
                                                    </td>  
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- customer engagement summary ends -->

                            <!-- Enquiries of a customer -->
                            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg mb-5">
                                <div class="m-portlet__head">
                                    <div class="m-portlet__head-caption">
                                        <div class="m-portlet__head-title">
                                            <h3 class="m-portlet__head-text">
                                                Enquiry Listing
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="m-portlet__head-tools">
                                        <ul class="m-portlet__nav">
                                            <li class="m-portlet__nav-item">
                                                <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                    routerLink="/AddEnquiry">Add Enquiry</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="m-portlet__body" style="overflow-x: scroll;">

                                    <div class="mat-elevation-z8">
                                        <table mat-table [dataSource]="customerEnquiries"
                                            class="table order-list table-bordered table-hover table-edit" role="grid">

                                            <!-- Enquiry No. Column -->
                                            <ng-container matColumnDef="EnquiryNo">
                                                <th mat-header-cell *matHeaderCellDef>Enquiry No.</th>
                                                <td mat-cell *matCellDef="let enquiry">
                                                    <a target="_blank"
                                                        [routerLink]="['/EditViewEnquiry', enquiry.EnquiryId]"
                                                        routerLinkActive="active">{{enquiry.EnquiryNumber}}</a>
                                                </td>
                                            </ng-container>

                                            <!-- Enquiry Date Column -->
                                            <ng-container matColumnDef="EnquiryDate">
                                                <th mat-header-cell *matHeaderCellDef>Enquiry Date</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.EnquiryDate | date}}
                                                </td>
                                            </ng-container>

                                            <!-- Quotation No. Column -->
                                            <ng-container matColumnDef="QuotationNo">
                                                <th mat-header-cell *matHeaderCellDef>Quotation No.</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.QuotationNumber}}</td>
                                            </ng-container>

                                            <!-- Products Column -->
                                            <!-- <ng-container matColumnDef="Products">
                                                <th mat-header-cell *matHeaderCellDef>Products</th>
                                                <td mat-cell *matCellDef="let enquiry">
                                                    <ul style="padding-left: 5px;">
                                                        <li *ngFor="let product of enquiry.Products">{{product}}</li>
                                                    </ul>
                                                </td>
                                            </ng-container> -->

                                            <!-- Quotation Amount (Rs) Column -->
                                            <ng-container matColumnDef="QuotationAmount">
                                                <th mat-header-cell *matHeaderCellDef>Quotation Amount (Rs)
                                                </th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.QuotationFinalAmt}}
                                                </td>
                                            </ng-container>

                                            <!-- Discount (%) Column -->
                                            <!-- <ng-container matColumnDef="Discount">
                                                <th mat-header-cell *matHeaderCellDef>Discount (%)</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.Discount}}</td>
                                            </ng-container> -->

                                            <!-- Quotation Status Column -->
                                            <ng-container matColumnDef="QuotationStatus">
                                                <th mat-header-cell *matHeaderCellDef>Quotation Status</th>
                                                <td mat-cell *matCellDef="let enquiry">
                                                    {{enquiry.EnquiryConfirmationStatus}}</td>
                                            </ng-container>

                                            <!-- Engagement Activity Column -->
                                            <ng-container matColumnDef="EngagementActivity">
                                                <th mat-header-cell *matHeaderCellDef>Engagement Activity
                                                </th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.LastActivitySubject}}
                                                </td>
                                            </ng-container>

                                            <!-- Engagement Type Column -->
                                            <ng-container matColumnDef="EngagementType">
                                                <th mat-header-cell *matHeaderCellDef>Engagement Type</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.LastActivityType}}</td>
                                            </ng-container>

                                            <!-- Last Activity Column -->
                                            <ng-container matColumnDef="ActivityOutcome">
                                                <th mat-header-cell *matHeaderCellDef>Activity Outcome</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.LastActivityOutCome}}
                                                </td>
                                            </ng-container>

                                            <!-- Last Activity outcome Column -->
                                            <ng-container matColumnDef="ActivityOutcomeDescription">
                                                <th mat-header-cell *matHeaderCellDef>Activity Outcome Description</th>
                                                <td mat-cell *matCellDef="let enquiry">
                                                    {{enquiry.LastActivityOutComeDescription}}</td>
                                            </ng-container>

                                            <!-- Weiss Attendees Column -->
                                            <ng-container matColumnDef="WeissAttendees">
                                                <th mat-header-cell *matHeaderCellDef>Weiss Attendees</th>
                                                <td mat-cell *matCellDef="let enquiry">
                                                    {{enquiry.LastActivityParticipants}}
                                                </td>
                                            </ng-container>

                                            <!-- Activity Status Column -->
                                            <ng-container matColumnDef="ActivityStatus">
                                                <th mat-header-cell *matHeaderCellDef>Activity Status</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.LastActivityStatus}}
                                                </td>
                                            </ng-container>

                                            <!-- Comment Column -->
                                            <!-- <ng-container matColumnDef="Comment">
                                                <th mat-header-cell *matHeaderCellDef>Comment</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.Comment}}</td>
                                            </ng-container> -->

                                            <!-- Attachments Column -->
                                            <!-- <ng-container matColumnDef="Attachments">
                                                <th mat-header-cell *matHeaderCellDef>Attachments</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.Attachments}}</td>
                                            </ng-container> -->

                                            <!-- CRM Updates Column -->
                                            <!-- <ng-container matColumnDef="CRMUpdates">
                                                <th mat-header-cell *matHeaderCellDef>CRM Updates</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.CRMUpdates}}</td>
                                            </ng-container> -->

                                            <!-- Initiator Name Column -->
                                            <ng-container matColumnDef="InitiatorName">
                                                <th mat-header-cell *matHeaderCellDef>Initiator Name</th>
                                                <td mat-cell *matCellDef="let enquiry">{{enquiry.EnquiryInitiator}}</td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                        </table>

                                        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
                                    </div>

                                </div>
                            </div>
                            <!-- Enquiries of a customer ends -->


                            <!-- Activities of a customer -->
                            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                <div class="m-portlet__head">
                                    <div class="m-portlet__head-caption">
                                        <div class="m-portlet__head-title">
                                            <h3 class="m-portlet__head-text">
                                                Engagement Activities
                                            </h3>
                                        </div>
                                    </div>
                                    <div _ngcontent-yjk-c387="" class="m-portlet__head-tools">
                                        <ul _ngcontent-yjk-c387="" class="m-portlet__nav">
                                            <li _ngcontent-yjk-c387="" class="m-portlet__nav-item"><a
                                                    _ngcontent-yjk-c387=""
                                                    class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                    [routerLink]="['/Add-Activity']"
                                                    [queryParams]="{EntityType: 'Customer', CustId: customerId}">
                                                    Add Activity </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="m-portlet__body">
                                    <div class="table-responsive">
                                        <app-activity-list-by-entity [enquiryId]="customerId"
                                            [entityTypeId]="1"></app-activity-list-by-entity>
                                    </div>
                                </div>
                            </div>
                            <!-- Activities of a customer ends -->

                            <!-- Planned vs Actual -->
                            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                <div class="m-portlet__head">
                                    <div class="m-portlet__head-caption">
                                        <div class="m-portlet__head-title">
                                            <h3 class="m-portlet__head-text">
                                                Planned vs Actual
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-portlet__body">
                                    <app-planned-vs-actual-activity
                                        [customerId]="customerId" [formDetails]="'Customer_Engagement'"></app-planned-vs-actual-activity>
                                </div>
                            </div>
                            <!-- Planned vs Actual ends -->



                            <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                                routerLinkActive="active" (click)="gobackNavigate()">
                                Cancel
                            </button>

                        </div>
                        <!-- body ends -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>