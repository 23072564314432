import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/Service/common.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";
import { AppGlobals } from 'src/app/app.global';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quotation-preview',
  templateUrl: './quotation-preview.component.html',
  styleUrls: ['./quotation-preview.component.scss']
})
export class QuotationPreviewComponent implements OnInit {

  previewHtml:SafeHtml;
  quotationPdfPreviewUrl:SafeResourceUrl;
  @Input() enquiryObj;
  enqId: any;
  constructor(private activeModal: NgbActiveModal,private commonService: CommonService,private route: ActivatedRoute,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {    
    this.enqId = this.enquiryObj.EnquiryId; 
   // this.quotationPdfPreviewUrl =  this.sanitizer.bypassSecurityTrustHtml("<h3>Please wait while we generate Quotation Preview...</h3>");
    this.loadPreview();
  }

  loadPreview(){  
    let queryString = "enquiryId=" + this.enqId;
    this.quotationPdfPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(AppGlobals.baseAppUrl+"PrintPdf/GetQuotationPrintPreviewAsPdf?"+queryString);
    
    // this.commonService.getPrintPreview("PrintPdf", "GetQuotationPrintPreviewAsHtml", queryString).subscribe((data: any) => {      
    //  // console.log(data);
    //   this.previewHtml = this.sanitizer.bypassSecurityTrustHtml(data);

    // },(error: any) =>{
    //  // console.log(error);
    // });
  }

  closePopup() {
    this.activeModal.close('Close Click');
  }


}
