import { NumberValueAccessor } from '@angular/forms'

/****
 This Model class use to update status, Activate = 0,Deactivate = 1, Delete = 2  
 *****/
export class UpdateStatus 
{
    RegisterId: number;
    RoleType : number;
    Status: number;
}