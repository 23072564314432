import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private location: Location) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.headers.get('No-Auth') == "True")
            return next.handle(request.clone());

        if (localStorage.getItem('userToken') != null) {
            const clonedreq = request.clone({
                headers: request.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
            });
            return next.handle(request)
                .pipe(
                    retry(1),
                    catchError((error: HttpErrorResponse) => {
                        let errorMessage = '';
                        if (error.status === 401) {
                            localStorage.removeItem('userToken');
                            localStorage.clear();
                            this.location.replaceState('Login');
                            window.location.reload();
                        }
                        return throwError(errorMessage);
                    })
                )
        }
        else {
            localStorage.removeItem('userToken');
            localStorage.clear();
            this.location.replaceState('Login');
            window.location.reload();
        }
    }
}