<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <!-- BEGIN: Subheader -->
        <!-- <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title ">Customer</h3>
                </div>

            </div>
        </div> -->

        <!-- END: Subheader -->
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Customer
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid &&onSubmit(myform)" novalidate>
                            <div class="m-portlet__body p-5">
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Customer Details
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body" style="padding: 0.7rem 2.2rem;">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer Code:</label>
                                            <div class="col-lg-3 pt-3">
                                                {{CustomerModel.BasicCustomerInformationModel.CustomerCode}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Basic Customer Information
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Company Name:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="companyname"
                                                    id="companyname"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.CompanyName"
                                                    #companyname="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && companyname.invalid || companyname?.touched && companyname.invalid}"
                                                    placeholder="Enter Company Name" (keypress)="AvoidSpace($event)"
                                                    maxlength="400" [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.CompanyName=CustomerModel.BasicCustomerInformationModel.CompanyName.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && companyname.invalid || companyname?.touched && companyname.invalid">
                                                    <div *ngIf="myform.hasError('required', 'companyname')">
                                                        Company name is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Address Headquarters:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="addressheadquaters" id="addressheadquaters"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.AddressHeadquaters"
                                                    #addressheadquaters="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && addressheadquaters.invalid || addressheadquaters?.touched && addressheadquaters.invalid}"
                                                    placeholder="Enter Address Headquarters"
                                                    (keypress)="AvoidSpace($event)" maxlength="1000"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.AddressHeadquaters=CustomerModel.BasicCustomerInformationModel.AddressHeadquaters.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && addressheadquaters.invalid || addressheadquaters?.touched && addressheadquaters.invalid">
                                                    <div *ngIf="myform.hasError('required', 'addressheadquaters')">
                                                        Address Headquarters is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Address (Plant):<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="addressplant"
                                                    id="addressplant"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.AddressPlant"
                                                    #addressplant="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && addressplant.invalid || addressplant?.touched && addressplant.invalid}"
                                                    placeholder="Enter Address (Plant)" (keypress)="AvoidSpace($event)"
                                                    maxlength="2000" [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.AddressPlant=CustomerModel.BasicCustomerInformationModel.AddressPlant.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && addressplant.invalid || addressplant?.touched && addressplant.invalid">
                                                    <div *ngIf="myform.hasError('required', 'addressplant')">
                                                        Address (Plant) is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Address (Engineering/Design):</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="addressengineeringdesign" id="addressengineeringdesign"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.AddressEngineeringDesign"
                                                    #addressengineeringdesign="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && addressengineeringdesign.invalid || addressengineeringdesign?.touched && addressengineeringdesign.invalid}"
                                                    placeholder="Enter Address (Engineering/Design)"
                                                    (keypress)="AvoidSpace($event)" maxlength="2000"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.AddressEngineeringDesign=CustomerModel.BasicCustomerInformationModel.AddressEngineeringDesign.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">City:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="city" id="city"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.City"
                                                    #city="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && city.invalid || city?.touched && city.invalid}"
                                                    placeholder="Enter City" (keypress)="AvoidSpace($event)"
                                                    maxlength="100" required [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.City=CustomerModel.BasicCustomerInformationModel.City.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && city.invalid || city?.touched && city.invalid">
                                                    <div *ngIf="myform.hasError('required', 'city')">
                                                        City is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">State:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="state" id="state"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.State"
                                                    #state="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && state.invalid || state?.touched && state.invalid}"
                                                    placeholder="Enter State" (keypress)="AvoidSpace($event)"
                                                    maxlength="50" required [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.State=CustomerModel.BasicCustomerInformationModel.State.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && state.invalid || state?.touched && state.invalid">
                                                    <div *ngIf="myform.hasError('required', 'state')">
                                                        State is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Pin Code:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="pincode"
                                                    id="pincode"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.PINCode"
                                                    #pincode="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && pincode.invalid || pincode?.touched && pincode.invalid}"
                                                    placeholder="Enter Pin Code" maxlength="6" pattern="^[a-zA-Z0-9]*$"
                                                    (keypress)="AvoidSpace($event)" required [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.PINCode=CustomerModel.BasicCustomerInformationModel.PINCode.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && pincode.invalid || pincode?.touched && pincode.invalid">
                                                    <div *ngIf="myform.hasError('required', 'pincode')">
                                                        Pin code is required</div>
                                                    <div *ngIf="myform.hasError('pattern', 'pincode')">
                                                        Please enter alphanumeric values ​​only</div>
                                                    <div *ngIf="myform.hasError('maxlength', 'pincode')">
                                                        Pin code must not exceed 6 characters
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Country:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="country" name="country"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.Country"
                                                    #country="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && country.invalid || country?.touched && country.invalid}"
                                                    required [disabled]="isEditable">
                                                    <option value="null" disabled>Select Country</option>
                                                    <option *ngFor="let item of Country" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="Country?.length <= 0" value="No Data Found">
                                                        No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && country.invalid || country?.touched && country.invalid">
                                                    <div *ngIf="myform.hasError('required', 'country')">
                                                        Country is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Mail Address:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="mailaddress"
                                                    maxlength="100"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.MailAddress"
                                                    #mailaddress="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && mailaddress.invalid || mailaddress?.touched && mailaddress.invalid}"
                                                    placeholder="Enter Mail Address" [pattern]="emailPattern"
                                                    (keypress)="AvoidSpace($event)" required [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.MailAddress=CustomerModel.BasicCustomerInformationModel.MailAddress.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && mailaddress.invalid || mailaddress?.touched && mailaddress.invalid">
                                                    <div *ngIf="myform.hasError('required', 'mailaddress')">
                                                        Mail address is required</div>
                                                    <div *ngIf="myform.hasError('pattern', 'mailaddress')">
                                                        Should be in Email Id format (e.g. user@weiss.com)</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Website:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="website"
                                                    id="website"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.WebSite"
                                                    #website="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && website.invalid || website?.touched && website.invalid}"
                                                    placeholder="Enter Website" (keypress)="AvoidSpace($event)"
                                                    maxlength="250"
                                                    pattern="(https?://)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*/?"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.WebSite=CustomerModel.BasicCustomerInformationModel.WebSite.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && website.invalid || website?.touched && website.invalid">
                                                    <div *ngIf="myform.hasError('required', 'website')">
                                                        Website is required</div>
                                                    <div *ngIf="myform.hasError('pattern', 'website')">
                                                        Please enter valid website url (Ex.: https://www.google.com)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Global Parent:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="globalparent"
                                                    id="globalparent" #globalparent="ngModel" maxlength="300"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.GlobalParent"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && globalparent.invalid || globalparent?.touched && globalparent.invalid}"
                                                    placeholder="Enter Global Parent" (keypress)="AvoidSpace($event)"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.GlobalParent=CustomerModel.BasicCustomerInformationModel.GlobalParent.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Headquarter Info:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="headquarterinfo"
                                                    id="headquarterinfo" maxlength="300"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.HeadquarterInfo"
                                                    #headquarterinfo="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && headquarterinfo.invalid || headquarterinfo?.touched && headquarterinfo.invalid}"
                                                    placeholder="Enter Headquarter Info" (keypress)="AvoidSpace($event)"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.HeadquarterInfo=CustomerModel.BasicCustomerInformationModel.HeadquarterInfo.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && headquarterinfo.invalid || headquarterinfo?.touched && headquarterinfo.invalid">
                                                    <div *ngIf="myform.hasError('required', 'headquarterinfo')">
                                                        Headquarter info is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--for Customer Group & Code-->
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer Group:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="custgrpname" name="custgrpname"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.CustomergroupId"
                                                    #custgrpname="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && custgrpname.invalid || custgrpname?.touched && custgrpname.invalid}"
                                                    (change)="OnselectCustomerGroupName($event)" required
                                                    [disabled]="isEditable">
                                                    <option value="null" disabled>Select Customer Group </option>

                                                    <option *ngFor="let item of CustomerGroup" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="CustomerGroup?.length <= 0"
                                                        value="No Data Found"> No Data Found</option>

                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && custgrpname.invalid || custgrpname?.touched && custgrpname.invalid">
                                                    <div *ngIf="myform.hasError('required', 'custgrpname')">
                                                        Customer Group is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Customer Group Code:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">

                                                <select class="form-control " id="custgrpcode" name="custgrpcode"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.CustomergroupId"
                                                    #custgrpcode="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && custgrpcode.invalid || custgrpcode?.touched && custgrpcode.invalid}"
                                                    required [disabled]="isEditable">
                                                    <option value="null" disabled>Select Customer Group Code</option>

                                                    <option *ngFor="let item of CustomerGroupCode" value="{{item.Id}}">
                                                        {{item.Value}}
                                                    </option>
                                                    <option disabled *ngIf="CustomerGroupCode?.length <= 0"
                                                        value="No Data Found"> No Data Found</option>

                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && custgrpcode.invalid || custgrpcode?.touched && custgrpcode.invalid">
                                                    <div *ngIf="myform.hasError('required', 'subsegment')">
                                                        Customer Group Code is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                        <div class="form-group m-form__group row">
                                            <h5
                                                style="border-bottom: 1px solid #ccc; padding-bottom: 10px; width: 100%;">
                                                Key Management Person Details</h5>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Name:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="keymanagementname"
                                                    id="keymanagementname"
                                                    [(ngModel)]="CustomerModel.KeyManagementPersonDetails.Name"
                                                    #keymanagementname="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keymanagementname.invalid || keymanagementname?.touched && keymanagementname.invalid}"
                                                    placeholder="Enter Name" (keypress)="AvoidSpace($event)"
                                                    minlength="3" maxlength="100" required [disabled]="isEditable"
                                                    (change)="CustomerModel.KeyManagementPersonDetails.Name=CustomerModel.KeyManagementPersonDetails.Name.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && keymanagementname.invalid || keymanagementname?.touched && keymanagementname.invalid">
                                                    <div *ngIf="myform.hasError('required', 'keymanagementname')">
                                                        Name is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Contact No.:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="keymanagementcontactno"
                                                    [(ngModel)]="CustomerModel.KeyManagementPersonDetails.ContactNo"
                                                    #keymanagementcontactno="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keymanagementcontactno.invalid || keymanagementcontactno?.touched && keymanagementcontactno.invalid}"
                                                    placeholder="Enter Contact No." minlength="10" maxlength="15"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event);" required
                                                    [disabled]="isEditable" pattern="[0-9]+"
                                                    (change)="CustomerModel.KeyManagementPersonDetails.ContactNo=CustomerModel.KeyManagementPersonDetails.ContactNo.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && keymanagementcontactno.invalid || keymanagementcontactno?.touched && keymanagementcontactno.invalid">
                                                    <div *ngIf="myform.hasError('required', 'keymanagementcontactno')">
                                                        Contact number is required</div>
                                                    <div *ngIf="myform.hasError('pattern', 'keymanagementcontactno')">
                                                        Please enter valid contact number</div>
                                                    <div 
                                                        *ngIf="myform.hasError('minlength', 'keymanagementcontactno')">
                                                        Contact number should be 10-15 digits</div>
                                                    <div *ngIf="myform.hasError('maxlength', 'keymanagementcontactno')">
                                                        Contact number should be 10-15 digits
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Mail Address:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="keymanagementmailaddress" maxlength="100"
                                                    [(ngModel)]="CustomerModel.KeyManagementPersonDetails.MailAddress"
                                                    #keymanagementmailaddress="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keymanagementmailaddress.invalid || keymanagementmailaddress?.touched && keymanagementmailaddress.invalid}"
                                                    placeholder="Enter Mail Address" [pattern]="emailPattern"
                                                    (keypress)="AvoidSpace($event)" required [disabled]="isEditable"
                                                    (change)="CustomerModel.KeyManagementPersonDetails.MailAddress=CustomerModel.KeyManagementPersonDetails.MailAddress.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && keymanagementmailaddress.invalid || keymanagementmailaddress?.touched && keymanagementmailaddress.invalid">
                                                    <div
                                                        *ngIf="myform.hasError('required', 'keymanagementmailaddress')">
                                                        Mail address is required</div>
                                                    <div *ngIf="myform.hasError('pattern', 'keymanagementmailaddress')">
                                                        Should be in Email Id format (e.g. user@weiss.com)</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Direct line/Extension
                                                No.:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="keymanagementdirectlineno" id="keymanagementdirectlineno"
                                                    [(ngModel)]="CustomerModel.KeyManagementPersonDetails.DirectlineNo"
                                                    #keymanagementdirectlineno="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keymanagementdirectlineno.invalid || keymanagementdirectlineno?.touched && keymanagementdirectlineno.invalid}"
                                                    placeholder="Enter Direct line/Extension"
                                                    (keypress)="AvoidSpace($event)" maxlength="100"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.KeyManagementPersonDetails.DirectlineNo=CustomerModel.KeyManagementPersonDetails.DirectlineNo.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Digital ID- Skype ID:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="keymanagementdigitalskypeid" id="keymanagementdigitalskypeid"
                                                    [(ngModel)]="CustomerModel.KeyManagementPersonDetails.DigitalSkypeID"
                                                    #keymanagementdigitalskypeid="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keymanagementdigitalskypeid.invalid || keymanagementdigitalskypeid?.touched && keymanagementdigitalskypeid.invalid}"
                                                    placeholder="Enter Digital ID- Skype ID"
                                                    (keypress)="AvoidSpace($event)" maxlength="200"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.KeyManagementPersonDetails.DigitalSkypeID=CustomerModel.KeyManagementPersonDetails.DigitalSkypeID.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Digital ID- Other:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="keymanagementdigitalotherid" id="keymanagementdigitalotherid"
                                                    [(ngModel)]="CustomerModel.KeyManagementPersonDetails.DigitalOtherID"
                                                    #keymanagementdigitalotherid="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keymanagementdigitalotherid.invalid || keymanagementdigitalotherid?.touched && keymanagementdigitalotherid.invalid}"
                                                    placeholder="Enter Digital ID- Other"
                                                    (keypress)="AvoidSpace($event)" maxlength="200"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.KeyManagementPersonDetails.DigitalOtherID=CustomerModel.KeyManagementPersonDetails.DigitalOtherID.trim()">
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <h5
                                                style="border-bottom: 1px solid #ccc; padding-bottom: 10px; width: 100%;">
                                                First Contact Technical Person Details</h5>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Name:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontacttechnicalname" id="firstcontacttechnicalname"
                                                    [(ngModel)]="CustomerModel.FirstContactTechnicalPersonDetails.Name"
                                                    #firstcontacttechnicalname="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontacttechnicalname.invalid || firstcontacttechnicalname?.touched && firstcontacttechnicalname.invalid}"
                                                    placeholder="Enter Name" (keypress)="AvoidSpace($event)"
                                                    maxlength="100" [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactTechnicalPersonDetails.Name=CustomerModel.FirstContactTechnicalPersonDetails.Name.trim()">

                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Contact No.:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontacttechnicalcontactno"
                                                    [(ngModel)]="CustomerModel.FirstContactTechnicalPersonDetails.ContactNo"
                                                    #firstcontacttechnicalcontactno="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontacttechnicalcontactno.invalid || firstcontacttechnicalcontactno?.touched && firstcontacttechnicalcontactno.invalid}"
                                                    placeholder="Enter Contact No." minlength="10" maxlength="15"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                    [disabled]="isEditable" pattern="[0-9]+"
                                                    (change)="CustomerModel.FirstContactTechnicalPersonDetails.ContactNo=CustomerModel.FirstContactTechnicalPersonDetails.ContactNo.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && firstcontacttechnicalcontactno.invalid || firstcontacttechnicalcontactno?.touched && firstcontacttechnicalcontactno.invalid">
                                                    <div
                                                        *ngIf="myform.hasError('pattern', 'firstcontacttechnicalcontactno')">
                                                        Please enter valid contact number</div>
                                                    <div 
                                                        *ngIf="myform.hasError('minlength', 'firstcontacttechnicalcontactno')">
                                                        Contact number should be 10-15 digits</div>
                                                    <div
                                                        *ngIf="myform.hasError('maxlength', 'firstcontacttechnicalcontactno')">
                                                        Contact number should be 10-15 digits
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Mail Address:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontacttechnicalmailaddress" maxlength="100"
                                                    [(ngModel)]="CustomerModel.FirstContactTechnicalPersonDetails.MailAddress"
                                                    #firstcontacttechnicalmailaddress="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontacttechnicalmailaddress.invalid || firstcontacttechnicalmailaddress?.touched && firstcontacttechnicalmailaddress.invalid}"
                                                    placeholder="Enter Mail Address" [pattern]="emailPattern"
                                                    (keypress)="AvoidSpace($event)" [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactTechnicalPersonDetails.MailAddress=CustomerModel.FirstContactTechnicalPersonDetails.MailAddress.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && firstcontacttechnicalmailaddress.invalid || firstcontacttechnicalmailaddress?.touched && firstcontacttechnicalmailaddress.invalid">

                                                    <div
                                                        *ngIf="myform.hasError('pattern', 'firstcontacttechnicalmailaddress')">
                                                        Should be in Email Id format (e.g. user@weiss.com)</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Direct line/Extension
                                                No.:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontacttechnicaldirectlineno"
                                                    id="firstcontacttechnicaldirectlineno"
                                                    [(ngModel)]="CustomerModel.FirstContactTechnicalPersonDetails.DirectlineNo"
                                                    #firstcontacttechnicaldirectlineno="ngModel"
                                                    [ngClass]="{ 'is-invalid':firstcontacttechnicaldirectlineno.touched &&  firstcontacttechnicaldirectlineno.invalid }"
                                                    placeholder="Enter Direct line/Extension"
                                                    (keypress)="AvoidSpace($event)" maxlength="100"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactTechnicalPersonDetails.DirectlineNo=CustomerModel.FirstContactTechnicalPersonDetails.DirectlineNo.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Digital ID- Skype ID:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontacttechnicaldigitalskypeid"
                                                    id="firstcontacttechnicaldigitalskypeid"
                                                    [(ngModel)]="CustomerModel.FirstContactTechnicalPersonDetails.DigitalSkypeID"
                                                    #firstcontacttechnicaldigitalskypeid="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontacttechnicaldigitalskypeid.invalid || firstcontacttechnicaldigitalskypeid?.touched && firstcontacttechnicaldigitalskypeid.invalid}"
                                                    placeholder="Enter Digital ID- Skype ID"
                                                    (keypress)="AvoidSpace($event)" maxlength="200"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactTechnicalPersonDetails.DigitalSkypeID=CustomerModel.FirstContactTechnicalPersonDetails.DigitalSkypeID.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Digital ID- Other:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontacttechnicaldigitalotherid"
                                                    id="firstcontacttechnicaldigitalotherid"
                                                    [(ngModel)]="CustomerModel.FirstContactTechnicalPersonDetails.DigitalOtherID"
                                                    #firstcontacttechnicaldigitalotherid="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontacttechnicaldigitalotherid.invalid || firstcontacttechnicaldigitalotherid?.touched && firstcontacttechnicaldigitalotherid.invalid}"
                                                    placeholder="Enter Digital ID- Other"
                                                    (keypress)="AvoidSpace($event)" maxlength="200"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactTechnicalPersonDetails.DigitalOtherID=CustomerModel.FirstContactTechnicalPersonDetails.DigitalOtherID.trim()">
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <h5
                                                style="border-bottom: 1px solid #ccc; padding-bottom: 10px; width: 100%;">
                                                First Contact Commercial Person Details</h5>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Name:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontactcommercialname" id="firstcontactcommercialname"
                                                    [(ngModel)]="CustomerModel.FirstContactCommercialPersonDetails.Name"
                                                    #firstcontactcommercialname="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontactcommercialname.invalid || firstcontactcommercialname?.touched && firstcontactcommercialname.invalid}"
                                                    placeholder="Enter Name" (keypress)="AvoidSpace($event)"
                                                    maxlength="100" [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactCommercialPersonDetails.Name=CustomerModel.FirstContactCommercialPersonDetails.Name.trim()">

                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Contact No.:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontactcommercialcontactno"
                                                    [(ngModel)]="CustomerModel.FirstContactCommercialPersonDetails.ContactNo"
                                                    #firstcontactcommercialcontactno="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontactcommercialcontactno.invalid || firstcontactcommercialcontactno?.touched && firstcontactcommercialcontactno.invalid}"
                                                    placeholder="Enter Contact No." minlength="10" maxlength="15"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                    [disabled]="isEditable" pattern="[0-9]+"
                                                    (change)="CustomerModel.FirstContactCommercialPersonDetails.ContactNo=CustomerModel.FirstContactCommercialPersonDetails.ContactNo.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && firstcontactcommercialcontactno.invalid || firstcontactcommercialcontactno?.touched && firstcontactcommercialcontactno.invalid">
                                                    <div
                                                        *ngIf="myform.hasError('pattern', 'firstcontactcommercialcontactno')">
                                                        Please enter valid contact number</div>
                                                    <div 
                                                        *ngIf="myform.hasError('minlength', 'firstcontactcommercialcontactno')">
                                                        Contact number should be 10-15 digits</div>
                                                    <div
                                                        *ngIf="myform.hasError('maxlength', 'firstcontactcommercialcontactno')">
                                                        Contact number should be 10-15 digits
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Mail Address:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontactcommercialmailaddress" maxlength="100"
                                                    [(ngModel)]="CustomerModel.FirstContactCommercialPersonDetails.MailAddress"
                                                    #firstcontactcommercialmailaddress="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontactcommercialmailaddress.invalid || firstcontactcommercialmailaddress?.touched && firstcontactcommercialmailaddress.invalid}"
                                                    placeholder="Enter Mail Address" [pattern]="emailPattern"
                                                    (keypress)="AvoidSpace($event)" [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactCommercialPersonDetails.MailAddress=CustomerModel.FirstContactCommercialPersonDetails.MailAddress.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && firstcontactcommercialmailaddress.invalid || firstcontactcommercialmailaddress?.touched && firstcontactcommercialmailaddress.invalid">

                                                    <div
                                                        *ngIf="myform.hasError('pattern', 'firstcontactcommercialmailaddress')">
                                                        Should be in Email Id format (e.g. user@weiss.com)</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Direct line/Extension
                                                No.:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontactcommercialdirectlineno"
                                                    id="firstcontactcommercialdirectlineno"
                                                    [(ngModel)]="CustomerModel.FirstContactCommercialPersonDetails.DirectlineNo"
                                                    #firstcontactcommercialdirectlineno="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontactcommercialdirectlineno.invalid || firstcontactcommercialdirectlineno?.touched && firstcontactcommercialdirectlineno.invalid}"
                                                    placeholder="Enter Direct line/Extension"
                                                    (keypress)="AvoidSpace($event)" maxlength="100"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactCommercialPersonDetails.DirectlineNo=CustomerModel.FirstContactCommercialPersonDetails.DirectlineNo.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Digital ID- Skype ID:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontactcommercialdigitalskypeid"
                                                    id="firstcontactcommercialdigitalskypeid"
                                                    [(ngModel)]="CustomerModel.FirstContactCommercialPersonDetails.DigitalSkypeID"
                                                    #firstcontactcommercialdigitalskypeid="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontactcommercialdigitalskypeid.invalid || firstcontactcommercialdigitalskypeid?.touched && firstcontactcommercialdigitalskypeid.invalid}"
                                                    placeholder="Enter Digital ID- Skype ID"
                                                    (keypress)="AvoidSpace($event)" maxlength="200"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactCommercialPersonDetails.DigitalSkypeID=CustomerModel.FirstContactCommercialPersonDetails.DigitalSkypeID.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Digital ID- Other:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="firstcontactcommercialdigitalotherid"
                                                    id="firstcontactcommercialdigitalotherid"
                                                    [(ngModel)]="CustomerModel.FirstContactCommercialPersonDetails.DigitalOtherID"
                                                    #firstcontactcommercialdigitalotherid="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && firstcontactcommercialdigitalotherid.invalid || firstcontactcommercialdigitalotherid?.touched && firstcontactcommercialdigitalotherid.invalid}"
                                                    placeholder="Enter Digital ID- Other"
                                                    (keypress)="AvoidSpace($event)" maxlength="200"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FirstContactCommercialPersonDetails.DigitalOtherID=CustomerModel.FirstContactCommercialPersonDetails.DigitalOtherID.trim()">
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <h5
                                                style="border-bottom: 1px solid #ccc; padding-bottom: 10px; width: 100%;">
                                                Financial Details</h5>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Authorised Share Capital:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="authorisedsharecapital" id="authorisedsharecapital"
                                                    [(ngModel)]="CustomerModel.FinancialDetails.AuthorisedShareCapital"
                                                    #authorisedsharecapital="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && authorisedsharecapital.invalid || authorisedsharecapital?.touched && authorisedsharecapital.invalid}"
                                                    placeholder="Enter Authorised Share Capital"
                                                    (keypress)="AvoidSpace($event)" maxlength="100"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FinancialDetails.AuthorisedShareCapital=CustomerModel.FinancialDetails.AuthorisedShareCapital.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Bankers Details:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="bankersdetails"
                                                    id="bankersdetails"
                                                    [(ngModel)]="CustomerModel.FinancialDetails.BankersDetails"
                                                    #bankersdetails="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && bankersdetails.invalid || bankersdetails?.touched && bankersdetails.invalid}"
                                                    placeholder="Enter Bankers Details" (keypress)="AvoidSpace($event)"
                                                    maxlength="100" [disabled]="isEditable"
                                                    (change)="CustomerModel.FinancialDetails.BankersDetails=CustomerModel.FinancialDetails.BankersDetails.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Ultimate Shareholder:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="ultimateshareholder" id="ultimateshareholder"
                                                    [(ngModel)]="CustomerModel.FinancialDetails.UltimateShareholder"
                                                    #ultimateshareholder="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && ultimateshareholder.invalid || ultimateshareholder?.touched && ultimateshareholder.invalid}"
                                                    placeholder="Enter Ultimate Shareholder"
                                                    (keypress)="AvoidSpace($event)" maxlength="100"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.FinancialDetails.UltimateShareholder=CustomerModel.FinancialDetails.UltimateShareholder.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Percentage of Share(%):</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="percentageshare"
                                                    id="percentageshare"
                                                    [(ngModel)]="CustomerModel.FinancialDetails.PercentageShare"
                                                    #percentageshare="ngModel" pattern="[0-9]+(\.[0-9]{1,2})?%?"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && percentageshare.invalid || percentageshare?.touched && percentageshare.invalid}"
                                                    placeholder="Enter Percentage of Share"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event);" maxlength="3"
                                                    [disabled]="isEditable"
                                                    (change)="PercentageShareValidation(myform)">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && percentageshare.invalid || percentageshare?.touched && percentageshare.invalid">

                                                    <div *ngIf="myform.hasError('pattern', 'percentageshare')">
                                                        Please enter valid percentage of share</div>
                                                    <div
                                                        *ngIf="myform.hasError('PercentageGreater', 'percentageshare')">
                                                        Percentage of Share must be less than or equal to 100</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    WIN/WSEA Contact Details
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">WIN Account Manager:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="winaccountmanager"
                                                    id="winaccountmanager"
                                                    [(ngModel)]="CustomerModel.WINWSEAContactDetails.WINAccountManager"
                                                    #winaccountmanager="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && winaccountmanager.invalid || winaccountmanager?.touched && winaccountmanager.invalid}"
                                                    placeholder="Enter WIN Account Manager"
                                                    (keypress)="AvoidSpace($event)" maxlength="100"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.WINWSEAContactDetails.WINAccountManager=CustomerModel.WINWSEAContactDetails.WINAccountManager.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && winaccountmanager.invalid || winaccountmanager?.touched && winaccountmanager.invalid">
                                                    <div *ngIf="myform.hasError('required', 'winaccountmanager')">
                                                        WIN account manager is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Region:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="sel1" name="regionid"
                                                    [(ngModel)]="CustomerModel.WINWSEAContactDetails.Region"
                                                    #regionid="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && regionid.invalid || regionid?.touched && regionid.invalid}"
                                                    required [disabled]="isEditable">
                                                    <option value="null" disabled>Select Region</option>
                                                    <option *ngFor="let item of Region" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="Region?.length <= 0" value="No Data Found">
                                                        No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && regionid.invalid || regionid?.touched && regionid.invalid">
                                                    <div *ngIf="myform.hasError('required', 'regionid')">
                                                        Region is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">WIN Technical Contact:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="wintechnicalcontact" id="wintechnicalcontact"
                                                    [(ngModel)]="CustomerModel.WINWSEAContactDetails.WINTechnicalContact"
                                                    #wintechnicalcontact="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && wintechnicalcontact.invalid || wintechnicalcontact?.touched && wintechnicalcontact.invalid}"
                                                    placeholder="Enter WIN Technical Contact"
                                                    (keypress)="AvoidSpace($event)" maxlength="100"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.WINWSEAContactDetails.WINTechnicalContact=CustomerModel.WINWSEAContactDetails.WINTechnicalContact.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">WIN Global Contact:</label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="winglobalcontact"
                                                    name="winglobalcontact"
                                                    [(ngModel)]="CustomerModel.WINWSEAContactDetails.WINGlobalContact"
                                                    #winglobalcontact="ngModel" maxlength="50"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && winglobalcontact.invalid || winglobalcontact?.touched && winglobalcontact.invalid}"
                                                    [disabled]="isEditable">
                                                    <option value="" disabled>Select WIN Global Contact</option>
                                                    <option value="Application Manager-HQ">Application Manager-HQ
                                                    </option>
                                                    <option value="Product Manager-HQ">Product Manager-HQ</option>

                                                    <option value="Sales Manager-HQ">Sales Manager-HQ</option>
                                                    <option value="Segment Manager- BIW">Segment Manager- BIW</option>
                                                    <option value="Segment Manager- Life science">Segment Manager- Life
                                                        science</option>
                                                    <option value="Segment Manager- Machine">Segment Manager- Machine
                                                    </option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Customer Profile
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Company Information/History:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-8">
                                                <textarea class="form-control m-input" type="text"
                                                    name="companyinformation" id="companyinformation"
                                                    [(ngModel)]="CustomerModel.CustomerProfile.CompanyInformation"
                                                    #companyinformation="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && companyinformation.invalid || companyinformation?.touched && companyinformation.invalid}"
                                                    placeholder="Enter Company Information/History"
                                                    (keypress)="AvoidSpace($event)" rows="3" [disabled]="isEditable"
                                                    required maxlength="2000"
                                                    (change)="CustomerModel.CustomerProfile.CompanyInformation=CustomerModel.CustomerProfile.CompanyInformation.trim()"></textarea>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && companyinformation.invalid || companyinformation?.touched && companyinformation.invalid">
                                                    <div *ngIf="myform.hasError('required', 'companyinformation')">
                                                        Company information/history is required</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Brands/Products Range:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="brandsproducts"
                                                    id="brandsproducts"
                                                    [(ngModel)]="CustomerModel.CustomerProfile.BrandsProducts"
                                                    #brandsproducts="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && brandsproducts.invalid || brandsproducts?.touched && brandsproducts.invalid}"
                                                    placeholder="Enter Brands/Products Range"
                                                    (keypress)="AvoidSpace($event)" maxlength="2000"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CustomerProfile.BrandsProducts=CustomerModel.CustomerProfile.BrandsProducts.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && brandsproducts.invalid || brandsproducts?.touched && brandsproducts.invalid">
                                                    <div *ngIf="myform.hasError('required', 'brandsproducts')">
                                                        Brands/Products range is required</div>
                                                </div>

                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Corporate Presentation:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                <div class="file">
                                                    <i class="flaticon-file-2"></i><br />

                                                    <input type="file" class="form-control-file" name="corppresentation"
                                                        (change)="getfiledetails($event)" id="corppresentation"
                                                        [(ngModel)]="tempCorporatePresentation"
                                                        #corppresentation="ngModel"
                                                        [ngClass]="{ 'is-invalid':myform.submitted  && corppresentation.invalid || corppresentation?.touched && corppresentation.invalid}"
                                                        [disabled]="isEditable">
                                                </div>
                                                <span
                                                    *ngIf="!isEditable && ((CustomerModel.CustomerProfile.CorporatePresentationFileName == ''
                                                    || CustomerModel.CustomerProfile.CorporatePresentationFileName == undefined
                                                    || CustomerModel.CustomerProfile.CorporatePresentationFileName == null) && (Attachmentobj.CorporatePresentationAttachment == undefined))"
                                                    class="fileext">
                                                    [File Format: .bmp, .jpeg, .jpg, .png, .pdf, .xml, .txt, .doc,
                                                    .docx, .zip, .ppt, .pptx] [Max 5 MB]</span>
                                                {{Attachmentobj.CorporatePresentationAttachment != undefined ?
                                                Attachmentobj.CorporatePresentationAttachment.name : ""}}
                                                &nbsp;&nbsp;
                                                <i *ngIf="Attachmentobj.CorporatePresentationAttachment != undefined && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('corppresentation','')"
                                                    title="Remove"><br></i>
                                                <a href="#"
                                                    (click)="DownloadFile(CustomerModel.CustomerProfile.CorporatePresentationFilePath,CustomerModel.CustomerProfile.CorporatePresentationFileName)">
                                                    {{CustomerModel.CustomerProfile.CorporatePresentationFileName}}</a>&nbsp;&nbsp;
                                                <i *ngIf="CustomerModel.CustomerProfile.CorporatePresentationFileName != '' && CustomerModel.CustomerProfile.CorporatePresentationFileName != undefined && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('corppresentation',CustomerModel.CustomerProfile.CorporatePresentationFilePath)"
                                                    title="Remove"></i>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Industry Segment:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="industrysegment"
                                                    name="industrysegment"
                                                    [(ngModel)]="CustomerModel.CustomerProfile.IndustrySegment"
                                                    #industrysegment="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid}"
                                                    (change)="OnselectIndustrySegment($event)" required
                                                    [disabled]="isEditable">
                                                    <option value="null" disabled>Select Industry Segment</option>
                                                    <option *ngFor="let item of IndustrySegment" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="IndustrySegment?.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid">
                                                    <div *ngIf="myform.hasError('required', 'industrysegment')">
                                                        Industry segment is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Sub Segment:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="subsegment" name="subsegment"
                                                    [(ngModel)]="CustomerModel.CustomerProfile.SubSegment"
                                                    #subsegment="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && subsegment.invalid || subsegment?.touched && subsegment.invalid}"
                                                    required [disabled]="isEditable">
                                                    <option value="null" disabled>Select Sub Segment</option>
                                                    <option *ngFor="let item of SubSegment" value="{{item.Id}}">
                                                        {{item.SubSegmentName}}
                                                    </option>
                                                    <option disabled *ngIf="SubSegment?.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && subsegment.invalid || subsegment?.touched && subsegment.invalid">
                                                    <div *ngIf="myform.hasError('required', 'subsegment')">
                                                        Sub segment is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer Classification:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="customerclassification"
                                                    name="customerclassification"
                                                    [(ngModel)]="CustomerModel.CustomerProfile.CustomerClassification"
                                                    #customerclassification="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customerclassification.invalid || customerclassification?.touched && customerclassification.invalid}"
                                                    required [disabled]="isEditable">
                                                    <option value="null" disabled>Select Customer
                                                        Classification</option>
                                                    <option *ngFor="let item of CustomerClassification"
                                                        value="{{item.Id}}">{{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="CustomerClassification?.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && customerclassification.invalid || customerclassification?.touched && customerclassification.invalid">
                                                    <div *ngIf="myform.hasError('required', 'customerclassification')">
                                                        Customer classification is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Type of Customer:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="typeofcustomer" name="typeofcustomer"
                                                    [(ngModel)]="CustomerModel.CustomerProfile.Typeofcustomer"
                                                    #typeofcustomer="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && typeofcustomer.invalid || typeofcustomer?.touched && typeofcustomer.invalid}"
                                                    required [disabled]="isEditable">
                                                    <option value="null" disabled>Select Type of customer</option>
                                                    <option *ngFor="let item of Typeofcustomer" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="Typeofcustomer?.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && typeofcustomer.invalid || typeofcustomer?.touched && typeofcustomer.invalid">
                                                    <div *ngIf="myform.hasError('required', 'typeofcustomer')">
                                                        Type of customer is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer Category:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="customercategory"
                                                    name="customercategory"
                                                    [(ngModel)]="CustomerModel.CustomerProfile.CustomerCategory"
                                                    #customercategory="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customercategory.invalid || customercategory?.touched && customercategory.invalid}"
                                                    required [disabled]="isEditable">
                                                    <option value="null" disabled>Select Customer Category</option>
                                                    <option *ngFor="let item of CustomerCategory" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="CustomerCategory?.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && customercategory.invalid || customercategory?.touched && customercategory.invalid">
                                                    <div *ngIf="myform.hasError('required', 'customercategory')">
                                                        Customer category is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <div class="col-lg-12">
                                                <h5
                                                    style="border-bottom: 1px solid #ccc; padding-bottom: 10px; width: 100%;">
                                                    Company Turnover</h5>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Group Turnover:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="groupturnover"
                                                    id="groupturnover"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.GroupTurnover"
                                                    #groupturnover="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && groupturnover.invalid || groupturnover?.touched && groupturnover.invalid}"
                                                    placeholder="Enter Group Turnover" (keypress)="AvoidSpace($event)"
                                                    maxlength="50" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.GroupTurnover=CustomerModel.CompanyTurnover.GroupTurnover.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Year:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="groupturnoveryear"
                                                    id="groupturnoveryear"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.GroupTurnoverYear"
                                                    #groupturnoveryear="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && groupturnoveryear.invalid || groupturnoveryear?.touched && groupturnoveryear.invalid}"
                                                    placeholder="Enter Year" pattern="[0-9]+"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event)" minlength="4"
                                                    maxlength="4" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.GroupTurnoverYear=CustomerModel.CompanyTurnover.GroupTurnoverYear.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && groupturnoveryear.invalid || groupturnoveryear?.touched && groupturnoveryear.invalid">

                                                    <div *ngIf="myform.hasError('pattern', 'groupturnoveryear')">
                                                        Please enter only numbers</div>
                                                    <div *ngIf="myform.hasError('minlength', 'groupturnoveryear')">
                                                        Year should be 4 digits</div>
                                                    <div *ngIf="myform.hasError('maxlength', 'groupturnoveryear')">
                                                        Year should be 4 digits</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">India unit Turnover:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="indiaunitturnover"
                                                    id="indiaunitturnover"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.IndiaunitTurnover"
                                                    #indiaunitturnover="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && indiaunitturnover.invalid || indiaunitturnover?.touched && indiaunitturnover.invalid}"
                                                    placeholder="Enter India unit Turnover"
                                                    (keypress)="AvoidSpace($event)" maxlength="50"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.IndiaunitTurnover=CustomerModel.CompanyTurnover.IndiaunitTurnover.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Year:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="indiaunitturnoveryear" id="indiaunitturnoveryear"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.IndiaunitTurnoverYear"
                                                    #indiaunitturnoveryear="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && indiaunitturnoveryear.invalid || indiaunitturnoveryear?.touched && indiaunitturnoveryear.invalid}"
                                                    placeholder="Enter Year" pattern="[0-9]+"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event)" minlength="4"
                                                    maxlength="4" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.IndiaunitTurnoverYear=CustomerModel.CompanyTurnover.IndiaunitTurnoverYear.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && groupturnoveryear.invalid || groupturnoveryear?.touched && groupturnoveryear.invalid">

                                                    <div *ngIf="myform.hasError('pattern', 'indiaunitturnoveryear')">
                                                        Please enter only numbers</div>
                                                    <div *ngIf="myform.hasError('minlength', 'indiaunitturnoveryear')">
                                                        Year should be 4 digits</div>
                                                    <div *ngIf="myform.hasError('maxlength', 'indiaunitturnoveryear')">
                                                        Year should be 4 digits</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Domestic Turnover:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="domesticturnover"
                                                    id="domesticturnover"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.DomesticTurnover"
                                                    #domesticturnover="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && domesticturnover.invalid || domesticturnover?.touched && domesticturnover.invalid}"
                                                    placeholder="Enter Domestic Turnover"
                                                    (keypress)="AvoidSpace($event)" maxlength="50"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.DomesticTurnover=CustomerModel.CompanyTurnover.DomesticTurnover.trim()">

                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Year:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="domesticturnoveryear" id="domesticturnoveryear"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.DomesticTurnoverYear"
                                                    #domesticturnoveryear="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && domesticturnoveryear.invalid || domesticturnoveryear?.touched && domesticturnoveryear.invalid}"
                                                    placeholder="Enter Year" pattern="[0-9]+"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event)" minlength="4"
                                                    maxlength="4" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.DomesticTurnoverYear=CustomerModel.CompanyTurnover.DomesticTurnoverYear.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && domesticturnoveryear.invalid || domesticturnoveryear?.touched && domesticturnoveryear.invalid">

                                                    <div *ngIf="myform.hasError('pattern', 'domesticturnoveryear')">
                                                        Please enter only numbers</div>
                                                    <div *ngIf="myform.hasError('minlength', 'domesticturnoveryear')">
                                                        Year should be 4 digits</div>
                                                    <div *ngIf="myform.hasError('maxlength', 'domesticturnoveryear')">
                                                        Year should be 4 digits</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Export Turnover:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="exportturnover"
                                                    id="exportturnover"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.ExportTurnover"
                                                    #exportturnover="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && exportturnover.invalid || exportturnover?.touched && exportturnover.invalid}"
                                                    placeholder="Enter Export Turnover" (keypress)="AvoidSpace($event)"
                                                    maxlength="50" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.ExportTurnover=CustomerModel.CompanyTurnover.ExportTurnover.trim()">

                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Year:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="exportturnoveryear" id="exportturnoveryear"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.ExportTurnoverYear"
                                                    #exportturnoveryear="ngModel" pattern="[0-9]+"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && exportturnoveryear.invalid || exportturnoveryear?.touched && exportturnoveryear.invalid}"
                                                    placeholder="Enter Year"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event)" minlength="4"
                                                    maxlength="4" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.ExportTurnoverYear=CustomerModel.CompanyTurnover.ExportTurnoverYear.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && exportturnoveryear.invalid || exportturnoveryear?.touched && exportturnoveryear.invalid">

                                                    <div *ngIf="myform.hasError('pattern', 'exportturnoveryear')">
                                                        Please enter only numbers</div>
                                                    <div *ngIf="myform.hasError('minlength', 'exportturnoveryear')">
                                                        Year should be 4 digits</div>
                                                    <div *ngIf="myform.hasError('maxlength', 'exportturnoveryear')">
                                                        Year should be 4 digits</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Projected Turnover:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="projectedturnover"
                                                    id="projectedturnover"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.ProjectedTurnover"
                                                    #projectedturnover="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && projectedturnover.invalid || projectedturnover?.touched && projectedturnover.invalid}"
                                                    placeholder="Enter Projected Turnover"
                                                    (keypress)="AvoidSpace($event)" maxlength="50"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.ProjectedTurnover=CustomerModel.CompanyTurnover.ProjectedTurnover.trim()">

                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Year:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="projectedturnoveryear" id="projectedturnoveryear"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.ProjectedTurnoverYear"
                                                    #projectedturnoveryear="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && projectedturnoveryear.invalid || projectedturnoveryear?.touched && projectedturnoveryear.invalid}"
                                                    placeholder="Enter Year" pattern="[0-9]+"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event)" minlength="4"
                                                    maxlength="4" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.ProjectedTurnoverYear=CustomerModel.CompanyTurnover.ProjectedTurnoverYear.trim()">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && projectedturnoveryear.invalid || projectedturnoveryear?.touched && projectedturnoveryear.invalid">

                                                    <div *ngIf="myform.hasError('pattern', 'projectedturnoveryear')">
                                                        Please enter only numbers</div>
                                                    <div *ngIf="myform.hasError('minlength', 'projectedturnoveryear')">
                                                        Year should be 4 digits</div>
                                                    <div *ngIf="myform.hasError('maxlength', 'projectedturnoveryear')">
                                                        Year should be 4 digits</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">No. of Employees:</label>
                                            <div class="col-lg-3">

                                                <input class="form-control m-input" type="text" name="noofemployees"
                                                    id="noofemployees" pattern="[0-9]+"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.Noofemployees"
                                                    #noofemployees="ngModel" (change)="NoOfEmp(myform)"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && noofemployees.invalid || noofemployees?.touched && noofemployees.invalid}"
                                                    placeholder="Enter No. of Employees"
                                                    (keypress)="AvoidSpace($event);isNumberKey($event)" maxlength="7"
                                                    [disabled]="isEditable">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && noofemployees.invalid || noofemployees?.touched && noofemployees.invalid">

                                                    <div *ngIf="myform.hasError('pattern', 'noofemployees')">
                                                        Please enter only numbers</div>
                                                    <div *ngIf="myform.hasError('NoGreater', 'noofemployees')">
                                                        No. of Employees must be greater than 0 </div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Global Parents/JV
                                                Partners:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="globalparentsJVpartners" id="globalparentsJVpartners"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.GlobalParentsJVPartners"
                                                    #globalparentsJVpartners="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && globalparentsJVpartners.invalid || globalparentsJVpartners?.touched && globalparentsJVpartners.invalid}"
                                                    placeholder="Enter Global Parents/JV Partners"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.GlobalParentsJVPartners=CustomerModel.CompanyTurnover.GlobalParentsJVPartners.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Tech. Collaboration:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="techcollaboration"
                                                    id="techcollaboration"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.TechCollaboration"
                                                    #techcollaboration="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && techcollaboration.invalid || techcollaboration?.touched && techcollaboration.invalid}"
                                                    placeholder="Enter Tech. Collaboration"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.TechCollaboration=CustomerModel.CompanyTurnover.TechCollaboration.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Customer's Customer:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="customerscustomer"
                                                    id="customerscustomer"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.CustomersCustomer"
                                                    #customerscustomer="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customerscustomer.invalid || customerscustomer?.touched && customerscustomer.invalid}"
                                                    placeholder="Enter Customer's Customer"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.CustomersCustomer=CustomerModel.CompanyTurnover.CustomersCustomer.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && customerscustomer.invalid || customerscustomer?.touched && customerscustomer.invalid">
                                                    <div *ngIf="myform.hasError('required', 'customerscustomer')">
                                                        Customer's customer is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer's Key Competitors:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="customerskeycompetitors" id="customerskeycompetitors"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.CustomersKeyCompetitors"
                                                    #customerskeycompetitors="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customerskeycompetitors.invalid || customerskeycompetitors?.touched && customerskeycompetitors.invalid}"
                                                    placeholder="Enter Customer's Key Competitors"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.CustomersKeyCompetitors=CustomerModel.CompanyTurnover.CustomersKeyCompetitors.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Focus Segment:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="focussegment"
                                                    id="focussegment"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.FocusSegment"
                                                    #focussegment="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && focussegment.invalid || focussegment?.touched && focussegment.invalid}"
                                                    placeholder="Enter Focus Segment" (keypress)="AvoidSpace($event)"
                                                    maxlength="500" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.FocusSegment=CustomerModel.CompanyTurnover.FocusSegment.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Company Strategy: </label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="companystrategy"
                                                    id="companystrategy"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.CompanyStrategy"
                                                    #companystrategy="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && companystrategy.invalid || companystrategy?.touched && companystrategy.invalid}"
                                                    placeholder="Enter Company Strategy" (keypress)="AvoidSpace($event)"
                                                    maxlength="2000" [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.CompanyStrategy=CustomerModel.CompanyTurnover.CompanyStrategy.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Sales Office/ Branch
                                                Office:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="salesofficebranchoffice" id="salesofficebranchoffice"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.SalesofficeBranchoffice"
                                                    #salesofficebranchoffice="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && salesofficebranchoffice.invalid || salesofficebranchoffice?.touched && salesofficebranchoffice.invalid}"
                                                    placeholder="Enter Sales office/ Branch office"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.SalesofficeBranchoffice=CustomerModel.CompanyTurnover.SalesofficeBranchoffice.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Value Chain Information:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="valuechaininformation" id="valuechaininformation"
                                                    [(ngModel)]="CustomerModel.CompanyTurnover.ValueChainInformation"
                                                    #valuechaininformation="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && valuechaininformation.invalid || valuechaininformation?.touched && valuechaininformation.invalid}"
                                                    placeholder="Enter Value Chain Information"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.CompanyTurnover.ValueChainInformation=CustomerModel.CompanyTurnover.ValueChainInformation.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <h5
                                                style="border-bottom: 1px solid #ccc; padding-bottom: 10px; width: 100%;">
                                                Plant Information With GST No.</h5>
                                        </div>
                                        <div class="pull-right mb-3"></div>
                                        <div class="table-responsive">
                                            <table
                                                class="table order-list table-striped- table-bordered table-hover table-edit"
                                                id="myTable">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Company Name
                                                        </th>
                                                        <th>
                                                            Region
                                                        </th>
                                                        <th>
                                                            Address
                                                        </th>
                                                        <th>
                                                            GST No.
                                                        </th>
                                                        <th>
                                                            Key Account Name
                                                        </th>
                                                        <th>
                                                            Contact No.
                                                        </th>
                                                        <th>
                                                            Email
                                                        </th>

                                                        <th style="width:5%;" *ngIf="FormStatus == 'Edit'">
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let plant of CustomerModel.PlantInformationWithGSTNo; let i = index;">
                                                        <tr *ngIf="plant.CompanyName!=null && plant.CompanyName!=''">
                                                            <td>
                                                                <input type="text" name="{{plant.CompanyName}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="plant.CompanyName"
                                                                    (change)="plant.CompanyName=plant.CompanyName.trim()"
                                                                    disabled>
                                                            </td>
                                                            <td>
                                                                <select class="form-control" type="text"
                                                                    name="{{plant.Region}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="plant.Region" disabled>
                                                                    <option value="" disabled>Select Region</option>
                                                                    <option *ngFor="let item of Region"
                                                                        value="{{item.Id}}">
                                                                        {{item.Name}}
                                                                    </option>
                                                                    <option disabled *ngIf="Region?.length <= 0"
                                                                        value="No Data Found"> No Data Found</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <textarea type="text" name="{{plant.Address}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="plant.Address"
                                                                    (change)="plant.Address=plant.Address.trim()"
                                                                    disabled></textarea>
                                                            </td>

                                                            <td>
                                                                <input type="text" name="{{plant.GSTNo}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="plant.GSTNo"
                                                                    (change)="plant.GSTNo=plant.GSTNo.trim()" disabled>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="{{plant.KeyAccountNo}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="plant.KeyAccountNo"
                                                                    (change)="plant.KeyAccountNo=plant.KeyAccountNo.trim()"
                                                                    disabled>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="{{plant.ContactNo}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="plant.ContactNo"
                                                                    (change)="plant.ContactNo=plant.ContactNo.trim()"
                                                                    disabled>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="{{plant.Email}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="plant.Email"
                                                                    (change)="plant.Email=plant.Email.trim()" disabled>
                                                            </td>
                                                            <td *ngIf="FormStatus == 'Edit'">
                                                                <button type="button"
                                                                    class="ibtnDel btn btn-sm btn-danger m-btn--pill"
                                                                    value="Delete"
                                                                    (click)="deleteField(i,'Plant',plant.Id)">Delete</button>
                                                            </td>
                                                        </tr>
                                                    </ng-container>

                                                    <tr *ngIf="FormStatus == 'Edit'">
                                                        <td>
                                                            <input class="form-control input-sm responsiveinput"
                                                                type="text" name="plantCompanyName"
                                                                id="plantCompanyName" placeholder="Enter Company Name"
                                                                [(ngModel)]="PlantInformationWithGSTNoobj.CompanyName"
                                                                #plantCompanyName="ngModel"
                                                                (keypress)="AvoidSpace($event)"
                                                                [ngClass]="{ 'is-invalid':myform.submitted  && plantCompanyName.invalid || plantCompanyName?.touched && plantCompanyName.invalid}"
                                                                maxlength="150" [disabled]="isEditable"
                                                                (change)="PlantInformationWithGSTNoobj.CompanyName=PlantInformationWithGSTNoobj.CompanyName.trim()">

                                                        </td>
                                                        <td>
                                                            <select class="form-control" type="text" name="plantRegion"
                                                                id="plant.Region" #plantRegion="ngModel"
                                                                class="form-control input-sm responsiveinput"
                                                                [(ngModel)]="PlantInformationWithGSTNoobj.Region"
                                                                [ngClass]="{ 'is-invalid':myform.submitted  && plantRegion.invalid || plantRegion?.touched && plantRegion.invalid}"
                                                                [disabled]="isEditable">
                                                                <option value="null" disabled>Select Region</option>
                                                                <option *ngFor="let item of Region" value="{{item.Id}}">
                                                                    {{item.Name}}
                                                                </option>
                                                                <option disabled *ngIf="Region?.length <= 0"
                                                                    value="No Data Found"> No Data Found</option>
                                                            </select>

                                                        </td>
                                                        <td>

                                                            <textarea class="form-control input-sm responsiveinput"
                                                                type="text" name="plantAddress" id="plantAddress"
                                                                placeholder="Enter Address"
                                                                [(ngModel)]="PlantInformationWithGSTNoobj.Address"
                                                                #plantAddress="ngModel" (keypress)="AvoidSpace($event)"
                                                                [disabled]="isEditable" maxlength="2000"
                                                                (change)="PlantInformationWithGSTNoobj.Address=PlantInformationWithGSTNoobj.Address.trim()"></textarea>
                                                        </td>
                                                        <td>
                                                            <input class="form-control input-sm responsiveinput"
                                                                type="text" name="plantGSTNo" id="plantGSTNo"
                                                                [(ngModel)]="PlantInformationWithGSTNoobj.GSTNo"
                                                                #plantGSTNo="ngModel" (keypress)="AvoidSpace($event)"
                                                                maxlength="150" placeholder="Enter GST No."
                                                                [disabled]="isEditable"
                                                                (change)="PlantInformationWithGSTNoobj.GSTNo=PlantInformationWithGSTNoobj.GSTNo.trim()">

                                                        </td>
                                                        <td>
                                                            <input class="form-control input-sm responsiveinput"
                                                                type="text" name="plantKeyAccountNo"
                                                                id="plantKeyAccountNo"
                                                                [(ngModel)]="PlantInformationWithGSTNoobj.KeyAccountNo"
                                                                #plantKeyAccountNo="ngModel"
                                                                (keypress)="AvoidSpace($event)" maxlength="300"
                                                                placeholder="Enter Key Account Name"
                                                                [disabled]="isEditable"
                                                                (change)="PlantInformationWithGSTNoobj.KeyAccountNo=PlantInformationWithGSTNoobj.KeyAccountNo.trim()">

                                                        </td>
                                                        <td>
                                                            <input class="form-control input-sm responsiveinput"
                                                                type="text" name="plantContactNo" id="plantContactNo"
                                                                [(ngModel)]="PlantInformationWithGSTNoobj.ContactNo"
                                                                #plantContactNo="ngModel"
                                                                placeholder="Enter Contact No."
                                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                                minlength="10" maxlength="15"
                                                                [ngClass]="{ 'is-invalid':myform.submitted  && plantContactNo.invalid || plantContactNo?.touched && plantContactNo.invalid}"
                                                                [disabled]="isEditable" pattern="[0-9]+"
                                                                (change)="PlantInformationWithGSTNoobj.ContactNo=PlantInformationWithGSTNoobj.ContactNo.trim()">
                                                            <div class="invalid-feedback"
                                                                *ngIf="myform.submitted && plantContactNo.invalid || plantContactNo?.touched && plantContactNo.invalid">
                                                                <div
                                                                    *ngIf="myform.hasError('pattern', 'plantContactNo')">
                                                                    Please enter valid contact number</div>
                                                                <div
                                                                    *ngIf="myform.hasError('minlength', 'plantContactNo')">
                                                                    Contact number should be 10-15 digits</div>
                                                                <div
                                                                    *ngIf="myform.hasError('maxlength', 'plantContactNo')">
                                                                    Contact number should be 10-15 digits
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input class="form-control input-sm responsiveinput"
                                                                type="text" name="plantEmail" id="plantEmail"
                                                                [(ngModel)]="PlantInformationWithGSTNoobj.Email"
                                                                #plantEmail="ngModel" (keypress)="AvoidSpace($event)"
                                                                [ngClass]="{ 'is-invalid':myform.submitted  && plantEmail.invalid || plantEmail?.touched && plantEmail.invalid}"
                                                                [pattern]="emailPattern" maxlength="100"
                                                                placeholder="Enter Email" [disabled]="isEditable"
                                                                (change)="PlantInformationWithGSTNoobj.Email=PlantInformationWithGSTNoobj.Email.trim()">
                                                            <div class="invalid-feedback"
                                                                *ngIf="myform.submitted && plantEmail.invalid || plantEmail?.touched && plantEmail.invalid">

                                                                <div *ngIf="myform.hasError('pattern', 'plantEmail')">
                                                                    Should be in Email Id format (e.g. user@weiss.com)
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="button"
                                                                class="ibtnDel btn btn-sm btn-primary m-btn--pill"
                                                                id="addrow" (click)="AddNewRow('Plant')"
                                                                value="Add Row">
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>

                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Top Management - Board of Directors, Key Contacts
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-edit">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Designation/ Dept.</th>
                                                        <th>Contact No.</th>
                                                        <th>Email</th>
                                                        <th *ngIf="FormStatus == 'Edit'">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let top of CustomerModel.TopManagement; let i = index;">
                                                        <tr *ngIf="top.Name!=null && top.Name!=''">
                                                            <td>
                                                                <input type="text" name="{{top.Name}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="top.Name"
                                                                    (change)="top.Name=top.Name.trim()" disabled>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="{{top.Designation}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="top.Designation"
                                                                    (change)="top.Designation=top.Designation.trim()"
                                                                    disabled>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="{{top.ContactNo}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="top.ContactNo"
                                                                    (change)="top.ContactNo=top.ContactNo.trim()"
                                                                    disabled>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="{{top.Email}}_{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="top.Email"
                                                                    (change)="top.Email=top.Email.trim()" disabled>
                                                            </td>
                                                            <td *ngIf="FormStatus == 'Edit'">
                                                                <button type="button"
                                                                    class="ibtnDel btn btn-sm btn-danger m-btn--pill"
                                                                    value="Delete"
                                                                    (click)="deleteField(i,'TopManagement',top.Id)">Delete</button>
                                                            </td>
                                                        </tr>
                                                    </ng-container>

                                                    <tr *ngIf="FormStatus == 'Edit'">

                                                        <td>
                                                            <input class="form-control input-sm" type="text"
                                                                name="Topmanagementname" id="Topmanagementname"
                                                                placeholder="Enter Name"
                                                                [(ngModel)]="TopmanagementObj.Name"
                                                                #Topmanagementname="ngModel"
                                                                (keypress)="AvoidSpace($event)"
                                                                [ngClass]="{ 'is-invalid':myform.submitted  && Topmanagementname.invalid || Topmanagementname?.touched && Topmanagementname.invalid}"
                                                                maxlength="150" [disabled]="isEditable"
                                                                (change)="TopmanagementObj.Name=TopmanagementObj.Name.trim()">

                                                        </td>
                                                        <td>
                                                            <input class="form-control input-sm" type="text"
                                                                name="topmanagementDes" id="topmanagementDes"
                                                                [(ngModel)]="TopmanagementObj.Designation"
                                                                #topmanagementDes="ngModel"
                                                                (keypress)="AvoidSpace($event)" maxlength="150"
                                                                placeholder="Enter Designation" [disabled]="isEditable"
                                                                (change)="TopmanagementObj.Designation=TopmanagementObj.Designation.trim()">

                                                        </td>

                                                        <td>
                                                            <input class="form-control input-sm" type="text"
                                                                name="topmanagementContactNo"
                                                                id="topmanagementContactNo"
                                                                [(ngModel)]="TopmanagementObj.ContactNo"
                                                                #topmanagementContactNo="ngModel"
                                                                placeholder="Enter Contact No."
                                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                                minlength="10" maxlength="15"
                                                                [ngClass]="{ 'is-invalid':myform.submitted  && topmanagementContactNo.invalid || topmanagementContactNo?.touched && topmanagementContactNo.invalid}"
                                                                [disabled]="isEditable" pattern="[0-9]+"
                                                                (change)="TopmanagementObj.ContactNo=TopmanagementObj.ContactNo.trim()">
                                                            <div class="invalid-feedback"
                                                                *ngIf="myform.submitted && topmanagementContactNo.invalid || topmanagementContactNo?.touched && topmanagementContactNo.invalid">
                                                                <div
                                                                    *ngIf="myform.hasError('pattern', 'topmanagementContactNo')">
                                                                    Please enter valid contact number</div>
                                                                <div
                                                                    *ngIf="myform.hasError('minlength', 'topmanagementContactNo')">
                                                                    Contact number should be 10-15 digits</div>
                                                                <div
                                                                    *ngIf="myform.hasError('maxlength', 'topmanagementContactNo')">
                                                                    Contact number should be 10-15 digits
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input class="form-control input-sm" type="text"
                                                                name="topmanagementEmail" id="topmanagementEmail"
                                                                [(ngModel)]="TopmanagementObj.Email"
                                                                #topmanagementEmail="ngModel"
                                                                (keypress)="AvoidSpace($event)"
                                                                [ngClass]="{ 'is-invalid':myform.submitted  && topmanagementEmail.invalid || topmanagementEmail?.touched && topmanagementEmail.invalid}"
                                                                [pattern]="emailPattern" maxlength="100"
                                                                placeholder="Enter Email" [disabled]="isEditable"
                                                                (change)="TopmanagementObj.Email=TopmanagementObj.Email.trim()">
                                                            <div class="invalid-feedback"
                                                                *ngIf="myform.submitted && topmanagementEmail.invalid || topmanagementEmail?.touched && topmanagementEmail.invalid">

                                                                <div
                                                                    *ngIf="myform.hasError('pattern', 'topmanagementEmail')">
                                                                    Should be in Email Id format (e.g. user@weiss.com)
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="button"
                                                                class="ibtnDel btn btn-sm btn-primary m-btn--pill"
                                                                id="addrow" (click)="AddNewRow('TopManagement')"
                                                                value="Add Row">
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="form-group m-form__group row">

                                            <label class="col-lg-2 col-form-label">Organogram:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                <div class="file">
                                                    <i class="flaticon-file-2"></i><br />
                                                    <input type="file" class="form-control-file" name="organogram"
                                                        (change)="getfiledetails($event)" id="organogram"
                                                        [(ngModel)]="tempOrganogram" #organogram="ngModel"
                                                        [ngClass]="{ 'is-invalid':myform.submitted  && organogram.invalid || organogram?.touched && organogram.invalid}"
                                                        [disabled]="isEditable">
                                                </div>
                                                <span
                                                    *ngIf="!isEditable && ((CustomerModel.BasicCustomerInformationModel.OrganogramFileName == ''
                                            || CustomerModel.BasicCustomerInformationModel.OrganogramFileName == undefined ||
                                            CustomerModel.BasicCustomerInformationModel.OrganogramFileName == null) && ( Attachmentobj.OrganogramAttachment == undefined))"
                                                    class="fileext">
                                                    [File Format: .bmp, .jpeg, .jpg, .png, .pdf, .xml, .txt, .doc,
                                                    .docx, .zip, .ppt, .pptx] [Max 5 MB]</span>
                                                {{Attachmentobj.OrganogramAttachment != undefined ?
                                                Attachmentobj.OrganogramAttachment.name : ""}}
                                                &nbsp;&nbsp;
                                                <i *ngIf="Attachmentobj.OrganogramAttachment != undefined && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('organogram','')" title="Remove"><br></i>
                                                <a href="#"
                                                    (click)="DownloadFile(CustomerModel.BasicCustomerInformationModel.OrganogramFilePath,CustomerModel.BasicCustomerInformationModel.OrganogramFileName)">
                                                    {{CustomerModel.BasicCustomerInformationModel.OrganogramFileName}}</a>&nbsp;&nbsp;
                                                <i *ngIf="CustomerModel.BasicCustomerInformationModel.OrganogramFileName != '' && CustomerModel.BasicCustomerInformationModel.OrganogramFileName != undefined && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('organogram',CustomerModel.BasicCustomerInformationModel.OrganogramFilePath)"
                                                    title="Remove"></i>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Weiss Business
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Business with WIN/WSEA:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="businesswithWINWSEA" id="businesswithWINWSEA"
                                                    [(ngModel)]="CustomerModel.WeissBusiness.BusinesswithWINWSEA"
                                                    #businesswithWINWSEA="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && businesswithWINWSEA.invalid || businesswithWINWSEA?.touched && businesswithWINWSEA.invalid}"
                                                    placeholder="Enter Business with WIN/WSEA"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.WeissBusiness.BusinesswithWINWSEA=CustomerModel.WeissBusiness.BusinesswithWINWSEA.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Business with WNA:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="businesswithWNA"
                                                    id="businesswithWNA"
                                                    [(ngModel)]="CustomerModel.WeissBusiness.BusinesswithWNA"
                                                    #businesswithWNA="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && businesswithWNA.invalid || businesswithWNA?.touched && businesswithWNA.invalid}"
                                                    placeholder="Enter Business with WNA"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.WeissBusiness.BusinesswithWNA=CustomerModel.WeissBusiness.BusinesswithWNA.trim()">
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Business with China:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="businesswithchina"
                                                    id="businesswithchina"
                                                    [(ngModel)]="CustomerModel.WeissBusiness.BusinesswithChina"
                                                    #businesswithchina="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && businesswithchina.invalid || businesswithchina?.touched && businesswithchina.invalid}"
                                                    placeholder="Enter Business with China"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.WeissBusiness.BusinesswithChina=CustomerModel.WeissBusiness.BusinesswithChina.trim()">
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Business with EMEA:</label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="businesswithEMEA"
                                                    id="businesswithEMEA"
                                                    [(ngModel)]="CustomerModel.WeissBusiness.BusinesswithEMEA"
                                                    #businesswithEMEA="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && businesswithEMEA.invalid || businesswithEMEA?.touched && businesswithEMEA.invalid}"
                                                    placeholder="Enter Business with EMEA"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.WeissBusiness.BusinesswithEMEA=CustomerModel.WeissBusiness.BusinesswithEMEA.trim()">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Opportunities for Weiss
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Indexer:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="indexer"
                                                    id="indexer"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.Indexer"
                                                    #indexer="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && indexer.invalid || indexer?.touched && indexer.invalid}"
                                                    placeholder="Enter Indexer" (keypress)="AvoidSpace($event)"
                                                    maxlength="500" [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.Indexer=CustomerModel.OpportunitiesforWeiss.Indexer.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && indexer.invalid || indexer?.touched && indexer.invalid">
                                                    <div *ngIf="myform.hasError('required', 'indexer')">
                                                        Indexer is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Heavy Duty Indexer:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="heavydutyindexer"
                                                    id="heavydutyindexer"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.HeavyDutyIndexer"
                                                    #heavydutyindexer="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && heavydutyindexer.invalid || heavydutyindexer?.touched && heavydutyindexer.invalid}"
                                                    placeholder="Enter Heavy Duty Indexer"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.HeavyDutyIndexer=CustomerModel.OpportunitiesforWeiss.HeavyDutyIndexer.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && heavydutyindexer.invalid || heavydutyindexer?.touched && heavydutyindexer.invalid">
                                                    <div *ngIf="myform.hasError('required', 'heavydutyindexer')">
                                                        Heavy duty indexer is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Linear Transfer System:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="lineartransfersystem" id="lineartransfersystem"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.LinearTransferSystem"
                                                    #lineartransfersystem="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && lineartransfersystem.invalid || lineartransfersystem?.touched && lineartransfersystem.invalid}"
                                                    placeholder="Enter Linear Transfer System"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.LinearTransferSystem=CustomerModel.OpportunitiesforWeiss.LinearTransferSystem.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && lineartransfersystem.invalid || lineartransfersystem?.touched && lineartransfersystem.invalid">
                                                    <div *ngIf="myform.hasError('required', 'lineartransfersystem')">
                                                        Linear transfer system is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Pick and Place System:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="pickandplacesystem" id="pickandplacesystem"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.PickandPlaceSystem"
                                                    #pickandplacesystem="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && pickandplacesystem.invalid || pickandplacesystem?.touched && pickandplacesystem.invalid}"
                                                    placeholder="Enter Pick and place system"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.PickandPlaceSystem=CustomerModel.OpportunitiesforWeiss.PickandPlaceSystem.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && pickandplacesystem.invalid || pickandplacesystem?.touched && pickandplacesystem.invalid">
                                                    <div *ngIf="myform.hasError('required', 'pickandplacesystem')">
                                                        Pick and place system is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Linear System:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="linearsystem"
                                                    id="linearsystem"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.LinearSystem"
                                                    #linearsystem="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && linearsystem.invalid || linearsystem?.touched && linearsystem.invalid}"
                                                    placeholder="Enter Linear System" (keypress)="AvoidSpace($event)"
                                                    maxlength="500" [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.LinearSystem=CustomerModel.OpportunitiesforWeiss.LinearSystem.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && linearsystem.invalid || linearsystem?.touched && linearsystem.invalid">
                                                    <div *ngIf="myform.hasError('required', 'linearsystem')">
                                                        Linear system is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Direct Drives:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="directdrives"
                                                    id="directdrives"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.Directdrives"
                                                    #directdrives="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && directdrives.invalid || directdrives?.touched && directdrives.invalid}"
                                                    placeholder="Enter Direct drives" (keypress)="AvoidSpace($event)"
                                                    maxlength="500" [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.Directdrives=CustomerModel.OpportunitiesforWeiss.Directdrives.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && directdrives.invalid || directdrives?.touched && directdrives.invalid">
                                                    <div *ngIf="myform.hasError('required', 'directdrives')">
                                                        Direct drives is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Delta Robot:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="deltarobot"
                                                    id="deltarobot"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.DeltaRobot"
                                                    #deltarobot="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && deltarobot.invalid || deltarobot?.touched && deltarobot.invalid}"
                                                    placeholder="Enter Delta Robot" (keypress)="AvoidSpace($event)"
                                                    maxlength="500" [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.DeltaRobot=CustomerModel.OpportunitiesforWeiss.DeltaRobot.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && deltarobot.invalid || deltarobot?.touched && deltarobot.invalid">
                                                    <div *ngIf="myform.hasError('required', 'deltarobot')">
                                                        Delta robot is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Value Added Solution:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="Valueaddedsolution" id="deltarobot"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.Valueaddedsolution"
                                                    #Valueaddedsolution="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && Valueaddedsolution.invalid || Valueaddedsolution?.touched && Valueaddedsolution.invalid}"
                                                    placeholder="Enter Value added solution"
                                                    (keypress)="AvoidSpace($event)" maxlength="500"
                                                    [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.Valueaddedsolution=CustomerModel.OpportunitiesforWeiss.Valueaddedsolution.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && Valueaddedsolution.invalid || Valueaddedsolution?.touched && Valueaddedsolution.invalid">
                                                    <div *ngIf="myform.hasError('required', 'Valueaddedsolution')">
                                                        Value added solution is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Accessories & Spare Parts:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text"
                                                    name="accessoriesspareparts" id="accessoriesspareparts"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.AccessoriesSpareParts"
                                                    #accessoriesspareparts="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && accessoriesspareparts.invalid || accessoriesspareparts?.touched && accessoriesspareparts.invalid}"
                                                    placeholder="Enter Accessories &amp; Spare Parts"
                                                    (keypress)="AvoidSpace($event)" [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.AccessoriesSpareParts=CustomerModel.OpportunitiesforWeiss.AccessoriesSpareParts.trim()"
                                                    required maxlength="500">
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && accessoriesspareparts.invalid || accessoriesspareparts?.touched && accessoriesspareparts.invalid">
                                                    <div *ngIf="myform.hasError('required', 'accessoriesspareparts')">
                                                        Accessories & spare parts is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Comments:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <textarea class="form-control m-input" type="text" name="comments"
                                                    id="comments"
                                                    [(ngModel)]="CustomerModel.OpportunitiesforWeiss.Comments"
                                                    #comments="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && comments.invalid || comments?.touched && comments.invalid}"
                                                    placeholder="Enter Comments" (keypress)="AvoidSpace($event)"
                                                    rows="3" [disabled]="isEditable"
                                                    (change)="CustomerModel.OpportunitiesforWeiss.Comments=CustomerModel.OpportunitiesforWeiss.Comments.trim()"
                                                    required maxlength="1500" (keyup)="limitTextOnKeyUpDown1($event)"
                                                    (keypress)="limitTextOnKeyUpDown1($event)"></textarea>
                                                <span> Limit {{maxLimit}} characters, {{remLimit}} characters
                                                    remaining.</span>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && comments.invalid || comments?.touched && comments.invalid">
                                                    <div *ngIf="myform.hasError('required', 'comments')">
                                                        Comments is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg"
                                    *ngIf="specialDiscountEnrtiesVisible">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Discount Structure <span style="color:#f4516c;">&nbsp;*</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <Label>Special Discount:</Label>
                                        <div class="table-responsive">

                                            <table
                                                class="table order-list table-striped- table-bordered table-hover table-edit"
                                                id="myTable">

                                                <thead>
                                                    <tr>

                                                        <th>
                                                            Product Model
                                                        </th>
                                                        <th>
                                                            Discount (%)
                                                        </th>

                                                    </tr>
                                                </thead>

                                                <tbody>


                                                    <tr *ngFor="let item of ProductModelDiscount">
                                                        <!-- <td>test</td> -->
                                                        <td>{{item.ModelName}}</td>
                                                        <td>{{item.ProductDiscount}}</td>

                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>

                                    </div>
                                </div>

                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Current Visible Competitors Name with USP<span
                                                        style="color:#f4516c;">&nbsp;*</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <div class="col-lg-10">
                                                <textarea class="form-control m-input" type="text"
                                                    name="currentvisiblecompetitorsnamewithUSP"
                                                    id="currentvisiblecompetitorsnamewithUSP" maxlength="3000"
                                                    [(ngModel)]="CustomerModel.BasicCustomerInformationModel.CurrentVisibleCompetitorsNamewithUSP"
                                                    #currentvisiblecompetitorsnamewithUSP="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && currentvisiblecompetitorsnamewithUSP.invalid || currentvisiblecompetitorsnamewithUSP?.touched && currentvisiblecompetitorsnamewithUSP.invalid}"
                                                    (keypress)="AvoidSpace($event)" rows="3" [disabled]="isEditable"
                                                    (change)="CustomerModel.BasicCustomerInformationModel.CurrentVisibleCompetitorsNamewithUSP=CustomerModel.BasicCustomerInformationModel.CurrentVisibleCompetitorsNamewithUSP.trim()"
                                                    required></textarea>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && currentvisiblecompetitorsnamewithUSP.invalid || currentvisiblecompetitorsnamewithUSP?.touched && currentvisiblecompetitorsnamewithUSP.invalid">
                                                    <div
                                                        *ngIf="myform.hasError('required', 'currentvisiblecompetitorsnamewithUSP')">
                                                        Current visible competitors name with USP is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    KYC Attachment
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">

                                            <label class="col-lg-2 col-form-label"> KYC 2 Attachment:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                <div class="file">
                                                    <i class="flaticon-file-2"></i><br />

                                                    <input type="file" class="form-control-file" name="KYC2attachment"
                                                        (change)="getfiledetails($event)" id="KYC2attachment"
                                                        [(ngModel)]="tempKYC2" #KYC2attachment="ngModel"
                                                        [ngClass]="{ 'is-invalid':myform.submitted  && KYC2attachment.invalid || KYC2attachment?.touched && KYC2attachment.invalid}"
                                                        [disabled]="isEditable">
                                                </div>
                                                <span
                                                    *ngIf="!isEditable && ((CustomerModel.BasicCustomerInformationModel.KYC2FileName == ''
                                            || CustomerModel.BasicCustomerInformationModel.KYC2FileName == undefined
                                            || CustomerModel.BasicCustomerInformationModel.KYC2FileName == null) && (Attachmentobj.KYC2Attachment == undefined))"
                                                    class="fileext">
                                                    [File Format: .xls, .xlsx] [Max 5 MB]</span>
                                                {{Attachmentobj.KYC2Attachment != undefined ?
                                                Attachmentobj.KYC2Attachment.name : ""}}
                                                &nbsp;&nbsp;
                                                <i *ngIf="Attachmentobj.KYC2Attachment != undefined && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('KYC2attachment','')"
                                                    title="Remove"><br></i>
                                                <a href="#"
                                                    (click)="DownloadFile(CustomerModel.BasicCustomerInformationModel.KYC2FilePath,CustomerModel.BasicCustomerInformationModel.KYC2FileName)">
                                                    {{CustomerModel.BasicCustomerInformationModel.KYC2FileName}}</a>&nbsp;&nbsp;
                                                <i *ngIf="CustomerModel.BasicCustomerInformationModel.KYC2FileName != '' && CustomerModel.BasicCustomerInformationModel.KYC2FileName != undefined  && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('KYC2attachment',CustomerModel.BasicCustomerInformationModel.KYC2FilePath)"
                                                    title="Remove"></i>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2  col-form-label"> KYC 3 Attachment:</label>
                                            <div class="col-lg-3" style="padding-top:9px;">
                                                <div class="file">
                                                    <i class="flaticon-file-2"></i><br />

                                                    <input type="file" class="form-control-file" name="KYC3attachment"
                                                        (change)="getfiledetails($event)" id="KYC3attachment"
                                                        [(ngModel)]="tempKYC3" #KYC3attachment="ngModel"
                                                        [ngClass]="{ 'is-invalid':myform.submitted  && KYC3attachment.invalid || KYC3attachment?.touched && KYC3attachment.invalid}"
                                                        [disabled]="isEditable">
                                                </div>
                                                <span
                                                    *ngIf="!isEditable && ((CustomerModel.BasicCustomerInformationModel.KYC3FileName == ''
                                            || CustomerModel.BasicCustomerInformationModel.KYC3FileName == undefined ||
                                             CustomerModel.BasicCustomerInformationModel.KYC3FileName == null) && (Attachmentobj.KYC3Attachment == undefined ))"
                                                    class="fileext">
                                                    [File Format: .xls, .xlsx] [Max 5 MB]</span>
                                                {{Attachmentobj.KYC3Attachment != undefined ?
                                                Attachmentobj.KYC3Attachment.name : ""}}
                                                &nbsp;&nbsp;
                                                <i *ngIf="Attachmentobj.KYC3Attachment != undefined && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('KYC3attachment','')"
                                                    title="Remove"><br></i>
                                                <a href="#"
                                                    (click)="DownloadFile(CustomerModel.BasicCustomerInformationModel.KYC3FilePath,CustomerModel.BasicCustomerInformationModel.KYC3FileName)">
                                                    {{CustomerModel.BasicCustomerInformationModel.KYC3FileName}}</a>&nbsp;&nbsp;
                                                <i *ngIf="CustomerModel.BasicCustomerInformationModel.KYC3FileName != '' && CustomerModel.BasicCustomerInformationModel.KYC3FileName != undefined  && !isEditable"
                                                    class="flaticon-delete" style="color:#f4516c"
                                                    (click)="DeleteAttachments('KYC3attachment',CustomerModel.BasicCustomerInformationModel.KYC3FilePath)"
                                                    title="Remove"></i>

                                            </div>
                                        </div>
                                        <!-- <div class="form-group m-form__group row">

                                        </div> -->
                                    </div>
                                </div>
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Engagement Activities
                                                </h3>
                                            </div>
                                        </div>
                                        <div _ngcontent-yjk-c387="" class="m-portlet__head-tools">
                                            <ul _ngcontent-yjk-c387="" class="m-portlet__nav">
                                                <li _ngcontent-yjk-c387="" class="m-portlet__nav-item"><a
                                                        _ngcontent-yjk-c387=""
                                                        class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                        [routerLink]="['/Add-Activity']"
                                                        [queryParams]="{EntityType: 'Customer', CustId: Id}"
                                                        >
                                                        Add Activity </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="table-responsive">
                                            <app-activity-list-by-entity
                                                [entityTypeId]="1"></app-activity-list-by-entity>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isCustomerEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a href="javascript:history.back()" routerLinkActive="active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
