<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Product Family
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Product Line:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="productline"
                                            [(ngModel)]="ProductFamilyobj.ProductLine" #productline="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productline.invalid || productline?.touched && productline.invalid}"
                                            [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Product Line</option>
                                            <option *ngFor="let item of ProductLine" value="{{item.Id}}">{{item.Name}}
                                            </option>
                                            <option disabled *ngIf="ProductLine.length <= 0" value="No Data Found"> No
                                                Data Found</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productline.invalid || productline?.touched && productline.invalid">
                                            <div *ngIf="myform.hasError('required', 'productline')">
                                                Product line is required
                                            </div>
                                        </div>

                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Product Family:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="productfamilyname"
                                            [(ngModel)]="ProductFamilyobj.ProductFamilyName"
                                            #productfamilyname="ngModel" maxlength="150"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && productfamilyname.invalid || productfamilyname?.touched && productfamilyname.invalid}"
                                            placeholder="Enter Product Family" (keypress)="AvoidSpace($event)"
                                            [disabled]="isEditable"
                                            (change)="ProductFamilyobj.ProductFamilyName=ProductFamilyobj.ProductFamilyName.trim()"
                                            required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && productfamilyname.invalid || productfamilyname?.touched && productfamilyname.invalid">
                                            <div *ngIf="myform.hasError('required', 'productfamilyname')">
                                                Product family is required</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isProductFamilyEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/ProductFamily']" routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>