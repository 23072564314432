<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add Enquiry
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="m-portlet__body">
                            <div class="multisteps-form">
                                <div class="row mt-3">
                                    <div class="col-lg-12 mb-4 table-responsive">

                                        <ng-template matStepLabel><span data-toggle="m-tooltip" data-placement="bottom"
                                                title="" data-original-title="Enquiry">Enquiry</span>
                                        </ng-template>
                                        <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right"
                                            name="myform1" (ngSubmit)="myform1.form.valid && AddEnquiry()"
                                            #myform1="ngForm" appFocusInvalidInput novalidate>
                                            <div class="multisteps-form__panel shadow rounded bg-white js-active"
                                                data-animation="fadeIn">
                                                <div class="multisteps-form__content">

                                                    <div class="markedFields">
                                                        <span style="margin-left:10px;">* Marked fields are
                                                            mandatory</span>
                                                    </div>
                                                    <div>
                                                        <div class="form-group m-form__group row">
                                                            <label class="col-lg-2 col-form-label">
                                                                <h5>User Name:</h5>
                                                            </label>
                                                            <div class="col-lg-3 pt-3">
                                                                <h6> {{userInfo.Name}} </h6>
                                                            </div>
                                                            <div class="offset-1"></div>
                                                            <label class="col-lg-2 col-form-label">
                                                                <h5>Role:</h5>
                                                            </label>
                                                            <div class="col-lg-3 pt-3">
                                                                <h6>{{userInfo.RoleName}}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- </div> -->

                                                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                                        <div class="m-portlet__head">
                                                            <div class="m-portlet__head-caption">
                                                                <div class="m-portlet__head-title">
                                                                    <h3 class="m-portlet__head-text">
                                                                        Customer Details
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div class="m-portlet__head-tools">
                                                                <ul class="m-portlet__nav">
                                                                    <li class="m-portlet__nav-item">
                                                                        <a class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                                            routerLink="/AddCustomer/customer/0"
                                                                            routerLinkActive="m-menu__item--active">Add
                                                                            Customer</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="m-portlet__body">

                                                            <div id="dvTestData">

                                                                <div class="form-group m-form__group row">
                                                                    <label class="col-lg-2 col-form-label">Customer
                                                                        Name:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>
                                                                    <div class="col-lg-3">
                                                                        <ng-select [items]="CustomerList"
                                                                            bindLabel="CompanyName" bindValue="Id"
                                                                            name="Customername" appendTo="body"
                                                                            [ngClass]="{ 'is-invalid': myform1.submitted && customerData===undefined }"
                                                                            (change)="GetCustomerDetails($event)"
                                                                            [(ngModel)]="customerData"
                                                                            placeholder="Select Customer" required>
                                                                        </ng-select>
                                                                        <div
                                                                            *ngIf="myform1.submitted && customerData==undefined">
                                                                            <span
                                                                                style="color:#f4516c;font-size: 80%;">Customer
                                                                                name is required</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="offset-1"></div>
                                                                    <label class="col-lg-2 col-form-label">Customer
                                                                        Code:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>

                                                                    <div class="col-lg-3 pt-3">
                                                                        {{EnquiryObj.CustomerDetails.CustomerCode}}
                                                                    </div>
                                                                </div>
                                                                <div class="form-group m-form__group row">
                                                                    <label class="col-lg-2 col-form-label">Industry
                                                                        Segment:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>
                                                                    <div class="col-lg-3 pt-3">
                                                                        {{customerDrpdnDetails.IndustrySegment}}
                                                                    </div>
                                                                    <div class="offset-1"></div>
                                                                    <label class="col-lg-2 col-form-label">Type
                                                                        of Customer:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>
                                                                    <div class="col-lg-3 pt-3">
                                                                        {{customerDrpdnDetails.CustomerType}}
                                                                    </div>

                                                                </div>
                                                                <div class="form-group m-form__group row">
                                                                    <label class="col-lg-2 col-form-label">Customer
                                                                        Category:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>
                                                                    <div class="col-lg-3 pt-3">
                                                                        {{customerDrpdnDetails.CustomerCategory}}
                                                                    </div>
                                                                    <div class="offset-1"></div>
                                                                    <label
                                                                        class="col-lg-2 col-form-label">Customer&nbsp;Classification:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>
                                                                    <div class="col-lg-3 pt-3">
                                                                        {{customerDrpdnDetails.CustomerClassification}}
                                                                    </div>
                                                                </div>
                                                                <div class="form-group m-form__group row">

                                                                    <label class="col-lg-2 col-form-label">Region:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>
                                                                    <div class="col-lg-3 pt-3">
                                                                        {{customerDrpdnDetails.Region}}
                                                                    </div>
                                                                    <div class="offset-1"></div>
                                                                    <label class="col-lg-2 col-form-label">Enquiry
                                                                        Source:<span
                                                                            style="color:#f4516c;">&nbsp;*</span></label>
                                                                    <div class="col-lg-3">
                                                                        <select class="form-control " id="enquirysource"
                                                                            name="enquirysource"
                                                                            [(ngModel)]="EnquiryObj.EnquiryDetails.EnquirySourceId"
                                                                            #enquirysource="ngModel"
                                                                            [ngClass]="{ 'is-invalid': (myform1.submitted && enquirysource.invalid) || (enquirysource?.touched && enquirysource.invalid) }"
                                                                            required>
                                                                            <option value="undefined" disabled selected >
                                                                                Select
                                                                                Enquiry Source
                                                                            </option>
                                                                            <option *ngFor="let item of enquirySource"
                                                                                value="{{item.Id}}">
                                                                                {{item.Name}}
                                                                            </option>
                                                                        </select>
                                                                        <div *ngIf="(myform1.submitted && enquirysource.invalid) || (enquirysource?.touched && enquirysource.invalid)"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="enquirysource.errors.required">
                                                                                Enquiry source is required
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                                        <div class="m-portlet__head">
                                                            <div class="m-portlet__head-caption">
                                                                <div class="m-portlet__head-title">
                                                                    <h3 class="m-portlet__head-text">
                                                                        Enquiry Details
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="m-portlet__body">

                                                            <div class="form-group m-form__group row">
                                                                <label class="col-lg-2 col-form-label">Enquiry
                                                                    Date:<span
                                                                        style="color:#f4516c;">&nbsp;*</span></label>
                                                                <div class="col-lg-3">
                                                                    <div class="input-group date">
                                                                        <input readonly class="form-control"
                                                                            placeholder="DD-MMM-YYYY" id="enquirydate"
                                                                            [minDate]="minDate"
                                                                            [maxDate]="maxDate"
                                                                            name="enquirydate" ngbDatepicker
                                                                            #ed="ngbDatepicker"
                                                                            [(ngModel)]="EnquiryObj.EnquiryDetails.EnquiryDate"
                                                                            #enquirydate="ngModel" (click)="ed.toggle()"
                                                                            [ngClass]="{ 'is-invalid': (myform1.submitted && enquirydate.invalid) || (enquirydate?.touched && enquirydate.invalid) }"
                                                                            required>
                                                                        <div class="input-group-append">
                                                                            <span (click)="ed.toggle()"
                                                                                class="input-group-text">
                                                                                <i class="la la-calendar-check-o"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="(myform1.submitted && enquirydate.invalid) || (enquirydate?.touched && enquirydate.invalid)"
                                                                        class="invalid-feedback"
                                                                        style="display: block;">
                                                                        Date is
                                                                        required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group m-form__group row">
                                                                <label class="col-lg-2 col-form-label">Enquiry
                                                                    Description:<span
                                                                        style="color:#f4516c;">&nbsp;*</span></label>
                                                                <div class="col-lg-9">
                                                                    <textarea class="form-control m-input"
                                                                        id="enquirydescription" maxlength="1500"
                                                                        name="enquirydescription" rows="3"
                                                                        [(ngModel)]="EnquiryObj.EnquiryDetails.EnquiryDescription"
                                                                        (keyup)="limitTextOnKeyUpDown1($event)"
                                                                        (keypress)="limitTextOnKeyUpDown1($event)"
                                                                        #enquirydescription="ngModel"
                                                                        [ngClass]="{ 'is-invalid': (myform1.submitted && enquirydescription.invalid) || (enquirydescription?.touched && enquirydescription.invalid) }"
                                                                        required></textarea>
                                                                    <span> Limit {{maxLimit}} characters, {{remLimit}}
                                                                        characters remaining.</span>
                                                                    <div *ngIf="(myform1.submitted && enquirydescription.invalid) || (enquirydescription?.touched && enquirydescription.invalid)"
                                                                        class="invalid-feedback">
                                                                        <div *ngIf="enquirydescription.errors.required">
                                                                            Enquiry description is required
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="button-row text-right mt-4" style="margin:15px;">
                                                        <button
                                                            class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next"
                                                            type="submit"
                                                            (click)="SetStatus(enquiryStatus.EnquiryAdded)">Save
                                                            Enquiry</button>&nbsp;&nbsp;
                                                        <button class="btn btn-metal m-btn m-btn--air m-btn--custom"
                                                            type="button" routerLink="/Enquiry"
                                                            routerLinkActive="active">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="clearfix" style="margin-top: 20px;"></div>

                                        <!-- </mat-step> -->


                                        <!-- </mat-horizontal-stepper> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-enquiry-modal></app-enquiry-modal> -->
