import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { CommonService } from 'src/app/Service/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { string } from '@amcharts/amcharts4/core';
//import { EnquiryDetailsModel, EnquiryExportToExcelModel, EnquiryGridFilterList, EnquiryGridModel, EnquiryModel, EnquiryPaginationGrid } from 'src/app/Model/EnquiryModel';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityDetailsComponent } from '../activity-details/activity-details.component';
import { UserRoles } from "src/app/UserRoles";
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { PassOnComponent } from '../pass-on/pass-on.component';
import { isNgContent } from '@angular/compiler';
import { AppGlobals } from 'src/app/app.global';
import { DatePipe } from '@angular/common';

import * as Excel from 'exceljs/dist/exceljs'
//import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { EnquiryStatus } from 'src/app/EnquiryStatus';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnquiryGridFilterList, EnquiryGridModel, EnquiryDetailsModel, EnquiryPaginationGrid, EnquiryExportToExcelModel } from 'src/app/Model/EnquiryModel';
import { AddActivityComponent } from '../../customer-engagement/activity/add-activity/add-activity.component';
import { Activity } from 'src/app/Model/ActivityModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';



@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {

  AppRoles = UserRoles;

  displayedColumns: string[] = ['EnquiryId', 'EnquiryDate', 'CustomerName', 'QuotationNumber', 'Products',
    'EnquiryValue', 'Discount', 'EnquiryWinLostOpenStatus', 'EstimatedProductvalue', 'RegionName',
    'IndustrySegment', 'SubSegment', 'CustomerClassification',    'ActivityType', 'Activity_Subject', 'ActivityStatus', 'ActivityPurpose', 'ActivityPurposeDesc', 'Activity_Outcome', 'Activity_OutcomeDesc',    'EnquiryStatus', 'InitiatorName', 'Action'];

  EnquiryGridFilterList: EnquiryGridFilterList = new EnquiryGridFilterList();
  minDate: NgbDate;
  maxDate: NgbDate;
  datePipe = new DatePipe('en-US');
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  dataSource: MatTableDataSource<EnquiryGridModel>;
  disabled: boolean = false;
  @Input()
  EnquiryId: number;
  AppGlobals = AppGlobals;
  QuotationDownloadPath = this.AppGlobals.QuotationFileDownloadUrl;
  EnquiryngbDateFromFiter: NgbDate | null;
  EnquiryngbDateToFiter: NgbDate | null;
  hoveredDate: NgbDate | null = null;
  charCode: any;

  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  enquiryStatus = EnquiryStatus;
  /*************************************************************************
      Server side Pagination purpose
  *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 100;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  SearchColname: string = "";
  SearchColKeyword: string = "";

  PaginationModel: EnquiryPaginationGrid = new EnquiryPaginationGrid();
  /*************************************************************************
     End here
  *************************************************************************/

  @Input()
  showLoader: boolean = false;
  data: any[] = [];
  closeResult: string;
  salesEnggList: any[] = [];
  selectedRegion: any = 0;
  seList: any[] = [];
  selectedSalesEngg: any = 0;
  SelectedEnquiryID: any;
  role: string;
  userid: string;
  salesEnggValidation: boolean;
  salesRegionValidation: boolean;
  currentSalesEngg: any;
  ExportToExcelData: EnquiryExportToExcelModel[] = [];
  TempEnquiryData: any[] = [];
  isEnquiryDataExport: boolean = false;

  /************************USED FOR ROWSPAN************************/
  gridData: any[] = [];
  spans = [];
  tempRowId = null;
  tempRowCount = null;
  /************************USED FOR ROWSPAN END************************/


  constructor(private toastr: ToastrService,
    private accessservice: AccessPermissionService,
    private cd: ChangeDetectorRef, private commonService: CommonService, private modalService: NgbModal, public dialog: MatDialog, private titleService: Title, private route: ActivatedRoute,
    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private activeModal: NgbActiveModal, private router: Router) {
    this.minDate = new NgbDate(2018, 1, 1);
    this.maxDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  }

  ngOnInit() {

    this.commonService.RefreshHeaderFun();
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.role = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isEnquiryDataExport = AppGlobals.EnquiryDataExportAccessArray.filter(x => x.role == this.role)[0]?.isEditable;

    this.userid = localStorage.getItem("UserID");
    this.GetAllEnquiryGridFilterList();
    this.GetAllEnquiry();
  }


  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllEmployeeDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllEnquiry() {
    // var x = document.getElementsByClassName("table-responsive");
    // var i;
    // for (i = 0; i < x.length; i++) {
    //   x[i].scrollLeft = 0;

    // }
    this.showLoader = true;

    this.PaginationModel.pageNumber = this.pageNumber;
    this.PaginationModel.pageSize = this.pageSize;
    this.PaginationModel.SearchText = this.SearchText;
    this.PaginationModel.direction = this.direction;
    this.PaginationModel.ColumnName = this.ColumnName;

    if (this.EnquiryngbDateToFiter != undefined && this.EnquiryngbDateToFiter != null) {
      this.PaginationModel.EnquiryDateToFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateToFiter = null;
    }

    if (this.EnquiryngbDateFromFiter != undefined && this.EnquiryngbDateFromFiter != null) {
      this.PaginationModel.EnquiryDateFromFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateFromFiter = null;
    }

    let QuotationValueFromFilter: number = +this.PaginationModel.QuotationValueFromFilter;
    let QuotationValueToFilter: number = +this.PaginationModel.QuotationValueToFilter;
    if (this.PaginationModel.QuotationValueFromFilter != null && QuotationValueFromFilter > 0 && QuotationValueToFilter > 0 && this.PaginationModel.QuotationValueToFilter != null) {
      if (QuotationValueToFilter < QuotationValueFromFilter) {
        alert("To Quotation value should be greater than From Quotation value.")
        this.PaginationModel.QuotationValueFromFilter = null;
      }
    }


    let DiscountFromFilter: number = +this.PaginationModel.DiscountFromFilter;
    let DiscounToFilter: number = +this.PaginationModel.DiscounToFilter;
    if (this.PaginationModel.DiscountFromFilter != null && DiscountFromFilter > 0 && DiscounToFilter > 0 && this.PaginationModel.DiscounToFilter != null) {
      if (DiscounToFilter < DiscountFromFilter) {
        alert("To Discount should be greater than From Discount.")
        this.PaginationModel.DiscountFromFilter = null;

      }
    }

    this.commonService.PostData('Enquiry', 'GetAllPagination', this.PaginationModel, null).subscribe((data: any) => {
      this.data = data.Data;
      if (data.Data != null) {
        this.BindGridData();
      }
      this.length = data.Total;

      this.dataSource = new MatTableDataSource(this.gridData);
      this.showLoader = false;

    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;

    var x = document.getElementsByClassName("table-responsive");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].scrollLeft = 0;
    }
    this.GetAllEnquiry();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllEnquiry();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    var x = document.getElementsByClassName("table-responsive");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].scrollLeft = 0;

    }

    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllEnquiry();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  SetEnquiryId(EnquiryId) {
    this.EnquiryId = EnquiryId;
  }

  convertDate(date: any) {
    date = date.split('T')[0];
    return date;
  }

  openPassOn(rowObject) {
    const modalRef = this.modalService.open(PassOnComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var EnquiryId = rowObject;

    modalRef.componentInstance.enqObj = EnquiryId;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        this.GetAllEnquiry();
      }
    });
  }

  openQuotationPopup(enqid, enqNo, initiatorID, status, PendingAtRoleId) {
    const modalRef = this.modalService.open(ActivityDetailsComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var EnquiryId = {
      enqid: enqid,
      endNo: enqNo,
      initID: initiatorID,
      status: status,
      PendingAtRoleId: PendingAtRoleId
    };

    modalRef.componentInstance.EnquiryId = EnquiryId;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        // this.GetAllEnquiry();
        // if (flag === 1) {
        // } else {
        //   //  this.saveQuoteLimit(this.QuotationNumber, result);
        // }
      }
    });
  }

  // openPassOnPopup(enqid) {

  //   const modalRef = this.modalService.open(PassonEnquiryComponent, {
  //     size: 'lg', backdrop: 'static', keyboard: false
  //   });

  //    var EnquiryId= enqid;      

  //   modalRef.componentInstance.EnquiryId = EnquiryId;
  //   modalRef.result.then((result) => {
  //     this.closeResult = `Closed with:${result}`;
  //     if (this.closeResult !== 'Closed with:Close click') {
  //       // if (flag === 1) {
  //       // } else {
  //       //   //  this.saveQuoteLimit(this.QuotationNumber, result);
  //       // }
  //     }
  //   });
  // }


  /************************************************************************
   * Grid RowSpan Methods  
   * **********************************************************************/

  BindGridData() {
    this.showLoader = true;
    this.gridData = [];
    this.spans = [];
    this.data.forEach(item => {
      item.EnquiryDate = this.convertDate(item.EnquiryDate);
      if (item.QuotationDetailsList != null && item.QuotationDetailsList.length > 0 && item.QuotationDetailsList.length != null && item.QuotationDetailsList.length != undefined) {
        for (let i = 0; i < item.QuotationDetailsList.length; i++) {
          var obj = {
            "Products": item.QuotationDetailsList[i].ModelName,
            "EnquiryValue": item.QuotationDetailsList[i].FinalAmount,
            "Discount": item.QuotationDetailsList[i].Discount,
            "EnquiryId": item.EnquiryId,
            "Initiator_Id": item.Initiator_Id,
            "InitiatorName": item.InitiatorName,
            "RoleName": item.RoleName,
            "CustomerId": item.CustomerId,
            "CustomerName": item.CustomerName,
            "CustomerCode": item.CustomerCode,
            "IndustrySegment": item.IndustrySegment,
            "SubSegment": item.SubSegment,
            "CustomerType": item.CustomerType,
            "CustomerClassification": item.CustomerClassification,
            "CustomerCategory": item.CustomerCategory,
            "RegionName": item.RegionName,
            "Country": item.Country,
            "EstimatedProductvalue": item.EstimatedProductvalue,
            "EnquiryNumber": item.EnquiryNumber,
            "EnquiryDate": item.EnquiryDate,
            "EnquirySourceId": item.EnquirySourceId,
            "EnquirySource": item.EnquirySource,
            "EnquiryDescription": item.EnquiryDescription,
            "Status": item.Status,
            "PendingAtRoleId": item.PendingAtRoleId,
            "EnquiryStatus": item.EnquiryStatus,
            "EnquiryWinLostOpenStatus": item.EnquiryWinLostOpenStatus,
            "CreatedOn": item.CreatedOn,
            "RFQNumber": item.RFQNumber,
            "RFQTermsAndCondition": item.RFQTermsAndCondition,
            "POValue": item.POValue,
            "EnquiryConfirmStatus": item.EnquiryConfirmStatus,
            "POStdReason": item.POStdReason,
            "OrdAcknowledgeNumber": item.OrdAcknowledgeNumber,
            "SupplyChainType": item.SupplyChainType,
            "TentativeDeliveryDate": item.TentativeDeliveryDate,
            "ModifiedOn": item.ModifiedOn,
            "EnquiryYear": item.EnquiryYear,
            "QuotationNumber": item.QuotationNumber,
            "QuotationPdf_filename": item.QuotationPdf_filename,
            "ActivityType": item.ActivityType,
            "Activity_Subject": item.Activity_Subject,
            "ActivityStatus": item.ActivityStatus,
            "ActivityPurpose": item.ActivityPurpose,
            "ActivityPurposeDesc": item.ActivityPurposeDesc,
            "Activity_Outcome": item.Activity_Outcome,
            "Activity_OutcomeDesc": item.Activity_OutcomeDesc,
            //"Activity_ActionPlan": item.Activity_ActionPlan
          }

          this.gridData.push(obj);

        }
      }
      else {
        var obj1 = {
          "Products": null,
          "EnquiryValue": null,
          "Discount": null,
          "EnquiryId": item.EnquiryId,
          "Initiator_Id": item.Initiator_Id,
          "InitiatorName": item.InitiatorName,
          "RoleName": item.RoleName,
          "CustomerId": item.CustomerId,
          "CustomerName": item.CustomerName,
          "CustomerCode": item.CustomerCode,
          "IndustrySegment": item.IndustrySegment,
          "SubSegment": item.SubSegment,
          "CustomerType": item.CustomerType,
          "CustomerClassification": item.CustomerClassification,
          "CustomerCategory": item.CustomerCategory,
          "RegionName": item.RegionName,
          "Country": item.Country,
          "EstimatedProductvalue": item.EstimatedProductvalue,
          "EnquiryNumber": item.EnquiryNumber,
          "EnquiryDate": item.EnquiryDate,
          "EnquirySourceId": item.EnquirySourceId,
          "EnquirySource": item.EnquirySource,
          "EnquiryDescription": item.EnquiryDescription,
          "Status": item.Status,
          "PendingAtRoleId": item.PendingAtRoleId,
          "EnquiryStatus": item.EnquiryStatus,
          "EnquiryWinLostOpenStatus": item.EnquiryWinLostOpenStatus,
          "CreatedOn": item.CreatedOn,
          "RFQNumber": item.RFQNumber,
          "RFQTermsAndCondition": item.RFQTermsAndCondition,
          "POValue": item.POValue,
          "EnquiryConfirmStatus": item.EnquiryConfirmStatus,
          "POStdReason": item.POStdReason,
          "OrdAcknowledgeNumber": item.OrdAcknowledgeNumber,
          "SupplyChainType": item.SupplyChainType,
          "TentativeDeliveryDate": item.TentativeDeliveryDate,
          "ModifiedOn": item.ModifiedOn,
          "EnquiryYear": item.EnquiryYear,
          "QuotationNumber": item.QuotationNumber,
          "QuotationPdf_filename": item.QuotationPdf_filename,
          "ActivityType": item.ActivityType,
          "Activity_Subject": item.Activity_Subject,
          "ActivityStatus": item.ActivityStatus,
          "ActivityPurpose": item.ActivityPurpose,
          "ActivityPurposeDesc": item.ActivityPurposeDesc,
          "Activity_Outcome": item.Activity_Outcome,
          "Activity_OutcomeDesc": item.Activity_OutcomeDesc,
          //"Activity_ActionPlan": item.Activity_ActionPlan
        }

        this.gridData.push(obj1);

      }


    });
    this.RowSpanData();
  }

  RowSpanData() {
    this.cacheSpan('EnquiryNo', d => d.EnquiryId);
    this.cacheSpan('EnquiryDate', d => d.EnquiryId);
    this.cacheSpan('RegionName', d => d.EnquiryId);
    this.cacheSpan('EstimatedProductvalue', d => d.EnquiryId);
    this.cacheSpan('IndustrySegment', d => d.EnquiryId);
    this.cacheSpan('SubSegment', d => d.EnquiryId);
    this.cacheSpan('CustomerClassification', d => d.EnquiryId);
    this.cacheSpan('QuotationNumber', d => d.EnquiryId);
    this.cacheSpan('CustomerName', d => d.EnquiryId);
    this.cacheSpan('EnquiryStatus', d => d.EnquiryId);
    this.cacheSpan('EnquiryWinLostOpenStatus', d => d.EnquiryId);
    this.cacheSpan('ActivityType', d => d.EnquiryId);
    this.cacheSpan('Activity_Subject', d => d.EnquiryId);
    this.cacheSpan('ActivityStatus', d => d.EnquiryId);
    this.cacheSpan('ActivityPurpose', d => d.EnquiryId);
    this.cacheSpan('ActivityPurposeDesc', d => d.EnquiryId);
    this.cacheSpan('Activity_Outcome', d => d.EnquiryId);
    this.cacheSpan('Activity_OutcomeDesc', d => d.EnquiryId);

    //this.cacheSpan('Activity_ActionPlan', d => d.EnquiryId);
    this.cacheSpan('InitiatorName', d => d.EnquiryId);
    this.cacheSpan('Action', d => d.EnquiryId);
    this.showLoader = false;
  }
  cacheSpan(key, accessor) {
    for (let i = 0; i < this.gridData.length;) {
      let currentValue = accessor(this.gridData[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.gridData.length; j++) {
        if (currentValue != accessor(this.gridData[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;

    }
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }


  /************************************************************************
   * Grid RowSpan Methods End 
   * **********************************************************************/


  /************************************************************************
    * Grid filter
    * **********************************************************************/
  OnFromDateFilterChange() {
    let EnquiryDateFromFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)
    let EnquiryDateToFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)
    if (EnquiryDateToFiter < EnquiryDateFromFiter) {
      this.EnquiryngbDateToFiter = this.EnquiryngbDateFromFiter;
      this.cd.detectChanges();

    }
    //this.GetAllEnquiry();

  }

  OnToDateFilterChange() {

    //  this.GetAllEnquiry();
  }

  ConvertNgbDateToDate(ngbdate: any) {
    if (ngbdate != null) {
      return new Date(ngbdate.year, ngbdate.month - 1, ngbdate.day)
    }

  }

  convertDateToString(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }


  OnQuotationValueFilterChange(CallFrom: any) {
    let QuotationValueFromFilter: number = +this.PaginationModel.QuotationValueFromFilter;
    let QuotationValueToFilter: number = +this.PaginationModel.QuotationValueToFilter;
    if (CallFrom == 'From') {
      if (QuotationValueFromFilter == null || isNaN(QuotationValueFromFilter)) {
        this.PaginationModel.QuotationValueFromFilter = null;
      }
      // if (QuotationValueToFilter < QuotationValueFromFilter) {
      //   this.PaginationModel.QuotationValueToFilter = null;

      // }

    }
    else {
      if (QuotationValueToFilter == null || isNaN(QuotationValueToFilter)) {
        this.PaginationModel.QuotationValueToFilter = null;
      }
      // if (QuotationValueToFilter < QuotationValueFromFilter) {
      //   this.PaginationModel.QuotationValueFromFilter = null;

      // }

    }
    this.cd.detectChanges();
    // this.GetAllEnquiry();

  }

  onDiscountChange(callType: any) {
    let DiscountFromFilter: number = +this.PaginationModel.DiscountFromFilter;
    let DiscounToFilter: number = +this.PaginationModel.DiscounToFilter;

    if (callType == 'From') {

      if (DiscountFromFilter > 100 || isNaN(DiscountFromFilter)) {
        this.PaginationModel.DiscountFromFilter = null;
      }
      // if ( DiscounToFilter <  DiscountFromFilter) {
      //   this.PaginationModel.DiscounToFilter = null;
      // }
    }
    else {
      if (DiscounToFilter > 100 || isNaN(DiscounToFilter)) {
        this.PaginationModel.DiscounToFilter = null;
      }
      // if (DiscounToFilter < DiscountFromFilter) {
      //   this.PaginationModel.DiscountFromFilter = null;

      // }
    }
    this.cd.detectChanges();
    //  this.GetAllEnquiry();
  }


  GetAllEnquiryGridFilterList() {
    this.commonService.GetData("Enquiry", "EnquiryGridFilterList", null).subscribe((data: any) => {
      this.EnquiryGridFilterList.EstimatedEnquiryValue = data.EstimatedEnquiryValue;
      this.EnquiryGridFilterList.Segment = data.Segment;
      this.EnquiryGridFilterList.MicroSegment = data.MicroSegment;
      this.EnquiryGridFilterList.OrignaleMicroSegment = data.MicroSegment;
      this.EnquiryGridFilterList.CusomterName = data.CusomterName;
      this.EnquiryGridFilterList.CusomterClass = data.CusomterClass;
      this.EnquiryGridFilterList.ProductModels = data.ProductModels;
      this.EnquiryGridFilterList.Region = data.region;
      this.EnquiryGridFilterList.InitiatorName = data.InitiatorName;

    });

  }

  ResetAllFilters() {
    this.PaginationModel.EnquiryDateFromFiter = null;
    this.PaginationModel.EnquiryDateToFiter = null;
    this.PaginationModel.EstimatedEnquiryValueFilter = [];
    this.PaginationModel.SegmentFilter = [];
    this.PaginationModel.MicroSegmentFilter = [];
    this.PaginationModel.CusomterNameFilter = [];
    this.PaginationModel.CusomterClassFilter = [];
    this.PaginationModel.ProdcutFilter = [];
    this.PaginationModel.QuotationValueToFilter = null;
    this.PaginationModel.QuotationValueFromFilter = null;
    this.PaginationModel.DiscountFromFilter = null;
    this.PaginationModel.DiscounToFilter = null;
    this.PaginationModel.QuotationStatusFilter = [];
    this.PaginationModel.RegionFilter = [];
    this.PaginationModel.InitiatorNameFilter = [];
    this.EnquiryngbDateFromFiter = null;
    this.EnquiryngbDateToFiter = null;
    this.OnSegmentChangeGetMiCroSegment();
    this.GetAllEnquiry();

  }

  OnSegmentChangeGetMiCroSegment() {
    this.EnquiryGridFilterList.MicroSegment = [];
    if (this.PaginationModel.SegmentFilter != null && this.PaginationModel.SegmentFilter != undefined && this.PaginationModel.SegmentFilter.length > 0) {
      this.EnquiryGridFilterList.MicroSegment = this.EnquiryGridFilterList.OrignaleMicroSegment.filter((o) => this.PaginationModel.SegmentFilter.includes(o.Fk_Name));

    }
    else {
      this.EnquiryGridFilterList.MicroSegment = this.EnquiryGridFilterList.OrignaleMicroSegment;
    }
  }

  /************************Date Range*************************************** */

  onDateSelection(date: NgbDate) {
    if (!this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter) {
      this.EnquiryngbDateFromFiter = date;
    } else if (this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && date && date.after(this.EnquiryngbDateFromFiter)) {
      this.EnquiryngbDateToFiter = date;
    } else {
      this.EnquiryngbDateToFiter = null;
      this.EnquiryngbDateFromFiter = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && this.hoveredDate && date.after(this.EnquiryngbDateFromFiter) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.EnquiryngbDateToFiter && date.after(this.EnquiryngbDateFromFiter) && date.before(this.EnquiryngbDateToFiter);
  }

  isRange(date: NgbDate) {
    return date.equals(this.EnquiryngbDateFromFiter) || (this.EnquiryngbDateToFiter && date.equals(this.EnquiryngbDateToFiter)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }




  /************************End Date Range*************************************** */

  /************************************************************************
    * Grid filter
    * **********************************************************************/


  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }


  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  /*******************************************************************************
  * Export to Excel
  * ******************************************************************************/

  ExportData() {
    this.showLoader = true;
    if (this.EnquiryngbDateToFiter != undefined && this.EnquiryngbDateToFiter != null) {
      this.PaginationModel.EnquiryDateToFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateToFiter = null;
    }

    if (this.EnquiryngbDateFromFiter != undefined && this.EnquiryngbDateFromFiter != null) {
      this.PaginationModel.EnquiryDateFromFiter = (this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)).toDateString();
    }
    else {
      this.PaginationModel.EnquiryDateFromFiter = null;
    }
    this.ExportToExcelData = [];
    this.TempEnquiryData = [];
    this.commonService.PostData("Enquiry", "EnquiryExportToExcel", this.PaginationModel, null).subscribe((data: any) => {
      this.TempEnquiryData = data;
      if (this.TempEnquiryData.length > 0) {
        this.TempEnquiryData.forEach(item => {
          if (item.EnquiryDate != null && item.EnquiryDate != "" && item.EnquiryDate != undefined) {
            item.EnquiryDate = this.convertDate(item.EnquiryDate);
          }
          if (item.RequestedDeliveryDate != null && item.RequestedDeliveryDate != "" && item.RequestedDeliveryDate != undefined) {
            item.RequestedDeliveryDate = this.convertDate(item.RequestedDeliveryDate);
          }
          if (item.RefDate != null && item.RefDate != "" && item.RefDate != undefined) {
            item.RefDate = this.convertDate(item.RefDate);
          }
          if (item.POSubmittedOn != null && item.POSubmittedOn != "" && item.POSubmittedOn != undefined) {
            item.POSubmittedOn = this.convertDate(item.POSubmittedOn);
          }
          if (item.QuotationSubmittedDate != null && item.QuotationSubmittedDate != "" && item.QuotationSubmittedDate != undefined) {
            item.QuotationSubmittedDate = this.convertDate(item.QuotationSubmittedDate);
          }
          if (item.SupplyChain_DeliveryDate != null && item.SupplyChain_DeliveryDate != "" && item.SupplyChain_DeliveryDate != undefined) {
            item.SupplyChain_DeliveryDate = this.convertDate(item.SupplyChain_DeliveryDate);
          }
          if (item.InvoiceSubmittedOn != null && item.InvoiceSubmittedOn != "" && item.InvoiceSubmittedOn != undefined) {
            item.InvoiceSubmittedOn = this.convertDate(item.InvoiceSubmittedOn);
          }
          if (item.EnquiryLostDate != null && item.EnquiryLostDate != "" && item.EnquiryLostDate != undefined) {
            item.EnquiryLostDate = this.convertDate(item.EnquiryLostDate);
          }
          // item.EnquiryDate = this.datePipe.transform(new Date(item.EnquiryDate), 'dd-MMM-yyyy h:mm a');
          if (item.QuotationDetailsList != null && item.QuotationDetailsList.length > 0 && item.QuotationDetailsList.length != null && item.QuotationDetailsList.length != undefined) {
            for (let i = 0; i < item.QuotationDetailsList.length; i++) {
              var obj = {
                "EnquiryNumber": item.EnquiryNumber,
                "EnquiryDate": item.EnquiryDate,
                "InitiatorName": item.InitiatorName,
                "CustomerName": item.CustomerName,
                "CustomerCode": item.CustomerCode,
                "IndustrySegment": item.IndustrySegment,
                "SubSegment": item.SubSegment,
                "CustomerType": item.CustomerType,
                "CustomerCategory": item.CustomerCategory,
                "CustomerClassification": item.CustomerClassification,
                "Country": item.Country,
                "Region": item.Region,
                "EnquirySource": item.EnquirySource,
                "EnquiryDescription": item.EnquiryDescription,
                "RFQNumber": item.RFQNumber,
                "RequestedDeliveryDate": item.RequestedDeliveryDate,
                "KindAttentionPerName": item.KindAttentionPerName,
                "YourRefPerName": item.YourRefPerName,
                "RefDate": item.RefDate,
                "RFQRevisionNo": item.RFQRevisionNo,
                "EstimatedProductvalue": item.EstimatedProductvalue,
                "RFQTermsAndCondition": item.RFQTermsAndCondition,
                "KindAttContactNo": item.KindAttContactNo,
                "KindAttEmailId": item.KindAttEmailId,
                "QuotationNumber": item.QuotationNumber,
                "QuotationRevisionNo": item.QuotationRevisionNo,
                "QuotationAmount": item.QuotationAmount,
                "QuotationDiscount": item.QuotationDiscount,
                "QuotationFinalAmount": item.QuotationFinalAmount,
                "QuotationSubmittedDate": item.QuotationSubmittedDate,
                "POValue": item.POValue,
                "Quotation_Status": item.EnquiryConfirmStatus,
                "POSubmittedOn": item.POSubmittedOn,
                "OrdAcknowledgeNumber": item.OrdAcknowledgeNumber,
                "SupplyChain_Type_Domestic_Export": item.SupplyChainType,
                "TentativeDateOfDelivery": item.SupplyChain_DeliveryDate,
                "InvoiceNo": item.InvoiceNo,
                "InvoiceSubmittedOn": item.InvoiceSubmittedOn,
                "EnquiryLostDate": item.EnquiryConfirmStatus == 'Lost' ? item.EnquiryLostDate : '',
                "WinLostReason": item.WinLostReason,
                "EnquiryStatus": item.EnquiryStatus,
                "ActivityType": item.ActivityType,
                "Activity_Subject": item.Activity_Subject,
                "ActivityStatus": item.ActivityStatus,
                "ActivityPurpose": item.ActivityPurpose,
                "ActivityPurposeDesc": item.ActivityPurposeDesc,
                "Activity_Outcome": item.Activity_Outcome,
                "Activity_OutcomeDesc": item.Activity_OutcomeDesc,
                "ProductLine": item.QuotationDetailsList[i].ProductLine,
                "ProductFamily": item.QuotationDetailsList[i].ProductFamily,
                "ProductSeries": item.QuotationDetailsList[i].ProductSeries,
                "ProductModel": item.QuotationDetailsList[i].ModelName,
                "ProductModelPrice": item.QuotationDetailsList[i].ProductModelPrice,
                "ProductVariant_1": item.QuotationDetailsList[i].ProductVariant_1,
                "ProductVariant_1Price": item.QuotationDetailsList[i].ProductVariant_1Price,
                "ProductVariant_2": item.QuotationDetailsList[i].ProductVariant_2,
                "ProductVariant_2Price": item.QuotationDetailsList[i].ProductVariant_2Price,
                "ProductVariant_3": item.QuotationDetailsList[i].ProductVariant_3,
                "ProductVariant_3Price": item.QuotationDetailsList[i].ProductVariant_3Price,
                "ProductVariant_4": item.QuotationDetailsList[i].ProductVariant_4,
                "ProductVariant_4Price": item.QuotationDetailsList[i].ProductVariant_4Price,
                "Quantity": item.QuotationDetailsList[i].Quantity,
                "TotalAmount": item.QuotationDetailsList[i].TotalAmount,
                "Discount": item.QuotationDetailsList[i].Discount,
                "FinalAmount": item.QuotationDetailsList[i].FinalAmount,
                "POAmount": item.QuotationDetailsList[i].POAmount,

              }

              this.ExportToExcelData.push(obj);

            }
          }
          else {
            var obj1 = {
              "EnquiryNumber": item.EnquiryNumber,
              "EnquiryDate": item.EnquiryDate,
              "InitiatorName": item.InitiatorName,
              "CustomerName": item.CustomerName,
              "CustomerCode": item.CustomerCode,
              "IndustrySegment": item.IndustrySegment,
              "SubSegment": item.SubSegment,
              "CustomerType": item.CustomerType,
              "CustomerCategory": item.CustomerCategory,
              "CustomerClassification": item.CustomerClassification,
              "Country": item.Country,
              "Region": item.Region,
              "EnquirySource": item.EnquirySource,
              "EnquiryDescription": item.EnquiryDescription,
              "RFQNumber": item.RFQNumber,
              "RequestedDeliveryDate": item.RequestedDeliveryDate,
              "KindAttentionPerName": item.KindAttentionPerName,
              "YourRefPerName": item.YourRefPerName,
              "RefDate": item.RefDate,
              "RFQRevisionNo": item.RFQRevisionNo,
              "EstimatedProductvalue": item.EstimatedProductvalue,
              "RFQTermsAndCondition": item.RFQTermsAndCondition,
              "KindAttContactNo": item.KindAttContactNo,
              "KindAttEmailId": item.KindAttEmailId,
              "QuotationNumber": item.QuotationNumber,
              "QuotationRevisionNo": item.QuotationRevisionNo,
              "QuotationAmount": item.QuotationAmount,
              "QuotationDiscount": item.QuotationDiscount,
              "QuotationFinalAmount": item.QuotationFinalAmount,
              "QuotationSubmittedDate": item.QuotationSubmittedDate,
              "POValue": item.POValue,
              "Quotation_Status": item.EnquiryConfirmStatus,
              "POSubmittedOn": item.POSubmittedOn,
              "OrdAcknowledgeNumber": item.OrdAcknowledgeNumber,
              "SupplyChain_Type_Domestic_Export": item.SupplyChainType,
              "TentativeDateOfDelivery": item.SupplyChain_DeliveryDate,
              "InvoiceNo": item.InvoiceNo,
              "InvoiceSubmittedOn": item.InvoiceSubmittedOn,
              "EnquiryLostDate": item.EnquiryConfirmStatus == 'Lost' ? item.EnquiryLostDate : '',
              "WinLostReason": item.WinLostReason,
              "EnquiryStatus": item.EnquiryStatus,
              "ActivityType": item.ActivityType,
              "Activity_Subject": item.Activity_Subject,
              "ActivityStatus": item.ActivityStatus,
              "ActivityPurpose": item.ActivityPurpose,
              "ActivityPurposeDesc": item.ActivityPurposeDesc,
              "Activity_Outcome": item.Activity_Outcome,
              "Activity_OutcomeDesc": item.Activity_OutcomeDesc,
              "ProductLine": '',
              "ProductFamily": '',
              "ProductSeries": '',
              "ProductModel": '',
              "ProductModelPrice": 0,
              "ProductVariant_1": '',
              "ProductVariant_1Price": 0,
              "ProductVariant_2": '',
              "ProductVariant_2Price": 0,
              "ProductVariant_3": '',
              "ProductVariant_3Price": 0,
              "ProductVariant_4": '',
              "ProductVariant_4Price": 0,
              "Quantity": 0,
              "TotalAmount": 0,
              "Discount": 0,
              "FinalAmount": 0,
              "POAmount": 0,

            }

            this.ExportToExcelData.push(obj1);

          }


        });
        if (this.ExportToExcelData.length > 0) {
          this.exportAsXLSX(this.ExportToExcelData, 'EnquiryData.xlsx');
        }
        this.showLoader = false;
      }
      else {
        // this.toastr.error("No data available!");
        this.showLoader = false;
      }
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const workbook: XLSX.WorkBook = { Sheets: { 'EnquiryData': worksheet }, SheetNames: ['EnquiryData'] };
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // this.saveAsExcelFile(excelBuffer, excelFileName);

    let workbook = new Excel.Workbook();
    workbook = this.getworkSheet(json, workbook);
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.saveAsExcelFile(data, excelFileName);
    });
  }

  getworkSheet(sheet2: any[], workbook: any) {

    let worksheet = workbook.addWorksheet('EnquiryData');
    let header = ['EnquiryNumber', 'EnquiryDate', 'InitiatorName', 'CustomerName', 'CustomerCode',
      'IndustrySegment', 'SubSegment', 'CustomerType', 'CustomerCategory', 'CustomerClassification', 'Country',
      'Region', 'EnquirySource', 'EnquiryDescription', 'RFQNumber', 'RequestedDeliveryDate', 'KindAttentionPerName',
      'YourRefPerName', 'RefDate', 'RFQRevisionNo', 'EstimatedProductvalue', 'RFQTermsAndCondition', 'KindAttContactNo',
      'KindAttEmailId', 'QuotationNumber', 'QuotationRevisionNo', 'QuotationAmount', 'QuotationDiscount',
      'QuotationFinalAmount', 'QuotationSubmittedDate', 'POValue', 'Quotation_Status', 'POSubmittedOn',
      'OrdAcknowledgeNumber', 'SupplyChain_Type_Domestic_Export', 'TentativeDateOfDelivery', 'InvoiceNo',
      'InvoiceSubmittedOn', 'EnquiryLostDate', 'WinLostReason', 'EnquiryStatus',
      'ActivityType', 'Activity_Subject', 'ActivityStatus', 'ActivityPurpose', 'ActivityPurposeDesc', 'Activity_Outcome', 'Activity_OutcomeDesc',
      'ProductLine', 'ProductFamily', 'ProductSeries', 'ProductModel', 'ProductModelPrice', 'ProductVariant_1', 'ProductVariant_1Price',
      'ProductVariant_2', 'ProductVariant_2Price', 'ProductVariant_3', 'ProductVariant_3Price', 'ProductVariant_4', 'ProductVariant_4Price',
      'Quantity', 'TotalAmount', 'Discount', 'FinalAmount', 'POAmount'];

    let headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '74B4FE' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10,
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    })
    for (let key of Object.keys(sheet2)) {
      let tmparray = [];
      let tmpobj = sheet2[key];

      for (let key of Object.keys(tmpobj)) {
        let tmpobj1 = tmpobj[key];
        tmparray.push(tmpobj1)
      }
      worksheet.addRow(tmparray);
    }
    return workbook;

  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }

  public exportAsXLSX(arrayofdata, filename: string): void {
    //const data = this.getFormatedData(arrayofdata);
    this.exportAsExcelFile(arrayofdata, filename);
  }


  /*******************************************************************************
  * Export to Excel
  * ******************************************************************************/
  /************* Delete Enquiry */
  deleteEnquiry(enqid, enqNo, initiatorID, status, PendingAtRoleId, Status) {
    this.EnquiryObj.EnquiryDetails.EnquiryId = enqid;
    this.EnquiryObj.EnquiryDetails.Status = Status;
    var confimrmsg = AppGlobals.successMessages.filter(x => x.id == Status)[0];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: confimrmsg.confirmmsg
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showLoader = true;

        let enquiryDetails = JSON.stringify(this.EnquiryObj);
        const frmData = new FormData();
        frmData.append("enquiryDetails", enquiryDetails);

        this.commonService.PostData("Enquiry", "DeleteEnquiry", frmData, null).subscribe((data: any) => {
          this.EnquiryObj.EnquiryDetails.EnquiryId = data;

          this.showLoader = false;
          this.toastr.success(confimrmsg.successmsg);
          window.location.reload();
          //this.router.navigate(['/Enquiry']);
          this.closePopup();

        }, error => {
          this.toastr.error(confimrmsg.errormsg);
          this.showLoader = false;
          //this.router.navigate(['/Enquiry']);
        });
      }
    });
  }

  addEnquiryPopup(enqid, custid) {
    // this.router.navigate(['/Add-Activity/Enquiry/' + enqid]);

    const modalRef = this.modalService.open(AddActivityComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });

    var ActivityEntity = {
      enqId: enqid,
      custId: custid,
      inPopUpMode: true,
      entityType: 'Enquiry',
    };

    modalRef.componentInstance.ActivityEntity = ActivityEntity;
    // modalRef.result.then((result) => {
    //   this.closeResult = `Closed with:${result}`;
    //   if (this.closeResult !== 'Closed with:Close click') {
    //     this.GetAllEnquiry();
    //   }
    // });
  }

  closePopup() {
    this.activeModal.close('Close Click');
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isEnquiryDataExport=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.role && x.ElementKey=='IsEnquiryDataExportAccessArray')[0]?.IsVisible;
      
    });
 
  }

}
