<loader [showLoader]="showLoader"></loader>
<div class="custsize">
    <!-- <div class="modal fade" id="ActivityModal" tabindex="-1" role="dialog"> -->

    <div class="modal-content custContent">

        <div class="modal-body">
            <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="activityForm"
                (ngSubmit)="activityForm.form.valid" #activityForm="ngForm" novalidate>
                <div class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="fadeIn">
                    <!-- <h3 class="multisteps-form__title">Enquiry </h3> -->
                    <div class="multisteps-form__content">
                        <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                            <div class="m-portlet__head">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <h3 class="m-portlet__head-text">
                                            Activity Details for Enq No.: {{EnquiryId.endNo}}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="m-portlet__body">
                                <div class="m-portlet__body">
                                    <div class="table-responsive">
                                        <app-activity-list-by-entity (closePopupEvent)="closePopup($event)" [enquiryId]="EnquiryId.enqid" [entityTypeId]="3"></app-activity-list-by-entity>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <div class="button-row text-right mt-4" style="margin:15px;">
                        <!-- <button class="btn btn-primary m-btn m-btn--air m-btn--custom ml-auto js-btn-next"
                                type="submit" title="Save Activity">Save
                                Activity</button>&nbsp;&nbsp; -->
                        <!-- <button type="button" *ngIf="!isActivityeditable && isShowEdit && (activityDetails?.length>0)"
                            class="btn btn-primary" (click)="editActivity()"
                            data-dismiss="modal">Edit</button>&nbsp;&nbsp;
                        <button type="button" *ngIf="isActivityeditable" class="btn btn-primary"
                            (click)="SaveActivityDetails()" data-dismiss="modal">Save Activity</button>&nbsp;&nbsp; -->
                        <button type="button" class="btn btn-metal" (click)="closePopup(true)"
                            data-dismiss="modal">Close</button>
                        <!-- <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button> -->
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- </div> -->