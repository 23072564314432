<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Alert
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                            <!-- <span class="pull-right"
                                style="font-weight: bold;font-size: 14px;padding-right: 10px;">Note:
                                <span style="margin-left: 5px;">For 0 Reminder (Days) - No alert will be fired
                                </span></span> -->

                        </div>


                        <form class="m-login__form m-form" name="myform" #myform="ngForm"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Enquiry Status:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="enqstatus"
                                            [(ngModel)]="AlertsObject.EnquiryStatus" #enqstatus="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && enqstatus.invalid || enqstatus?.touched && enqstatus.invalid}"
                                            placeholder="Enter Enquiry Status" (keypress)="AvoidSpace($event)"
                                            maxlength="250" required
                                            (change)="AlertsObject.EnquiryStatus=AlertsObject.EnquiryStatus.trim()"
                                            disabled>
                                        <div *ngIf="myform.submitted && enqstatus.invalid || enqstatus?.touched && enqstatus.invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="enqstatus.errors?.required">Product line is required</div>
                                        </div>
                                    </div>
                                </div>

                                <mat-card class="cardBorder">
                                    <h5> First Reminder</h5>
                                    <mat-divider></mat-divider>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Reminder (In Days):</label>
                                        <div class="col-lg-3">
                                            <input class="form-control m-input" type="text" name="first_rem_nm"
                                                [(ngModel)]="AlertsObject.First_Rem" #first_rem_nm="ngModel"
                                                [ngClass]="{ 'is-invalid':(myform.submitted  && first_rem_nm.invalid) || (first_rem_nm?.touched && first_rem_nm.invalid)}"
                                                placeholder="Enter 1st Reminder (In Days)"
                                                (keypress)="AvoidSpace($event);isNumberKey($event)" maxlength="4"
                                                [disabled]="isEditable"
                                                (change)="OnReminderDaysChange(myform,'First_Rem')" pattern="[1-9]+">
                                            <div *ngIf="(myform.submitted && first_rem_nm.invalid) || (first_rem_nm?.touched && first_rem_nm.invalid)"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="first_rem_nm.errors?.required">First reminder is required</div> -->
                                                <div *ngIf="myform.hasError('RemValidation', 'first_rem_nm')">First
                                                    reminder should be Less than second and third Reminder</div>
                                                <div *ngIf="myform.hasError('pattern', 'first_rem_nm')">
                                                    Reminder must be an integer or greater than zero</div>
                                            </div>
                                        </div>

                                        <div class="offset-1"></div>

                                        <label class="col-lg-2 col-form-label">To Whom:</label>
                                        <div class="col-lg-3" style="text-align: center;"> {{AlertsObject.First_Rem_ToWhomList}}</div>
                                       
                                        <!-- <div class="col-lg-3">
                                            <mat-select class="form-control" placeholder="Select 1st reminder to whom"
                                                [(ngModel)]="AlertsObject.First_Rem_ToWhomList"
                                                [ngClass]="{ 'is-invalid':myform.submitted  && firstTowhom.invalid || firstTowhom?.touched && firstTowhom.invalid}"
                                                #firstTowhom="ngModel" multiple name="firstTowhom" disabled>
                                                <mat-option disabled value="Enquiry Initiator">Enquiry Initiator
                                                </mat-option>
                                                <mat-option *ngFor="let item of Roles" value="{{item.Id}}">
                                                    {{item.Name}}</mat-option>
                                                <mat-option disabled *ngIf="Roles.length <= 0" value="No Data Found"> No
                                                    Data Found </mat-option>
                                            </mat-select>

                                            <div class="invalid-feedback"
                                                *ngIf="myform.submitted && firstTowhom.invalid || firstTowhom?.touched && firstTowhom.invalid">
                                                <div *ngIf="myform.hasError('required', 'firstTowhom')">
                                                    First reminder to whom is required
                                                </div>
                                            </div>
                                        </div> -->

                                    </div>
                                </mat-card>
                                <br>
                                <mat-card class="cardBorder">
                                    <h5>Second Reminder</h5>
                                    <mat-divider></mat-divider>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Reminder (In Days):</label>
                                        <div class="col-lg-3">
                                            <input class="form-control m-input" type="text" name="second_rem_nm"
                                                [(ngModel)]="AlertsObject.Second_Rem" #second_rem_nm="ngModel"
                                                [ngClass]="{ 'is-invalid':(myform.submitted  && second_rem_nm.invalid) || (second_rem_nm?.touched && second_rem_nm.invalid)}"
                                                placeholder="Enter 2nd Reminder (In Days)"
                                                (keypress)="AvoidSpace($event);isNumberKey($event)" maxlength="4"
                                                [disabled]="isEditable"
                                                (change)="OnReminderDaysChange(myform,'Second_Rem')" pattern="[1-9]+">
                                            <div *ngIf="(myform.submitted && second_rem_nm.invalid) || (second_rem_nm?.touched && second_rem_nm.invalid)"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="second_rem_nm.errors?.required">Second reminder is required</div> -->
                                                <div *ngIf="myform.hasError('RemValidation', 'second_rem_nm')">
                                                    Second reminder should be greater than first reminder and less than
                                                    third reminder
                                                </div>
                                                <div *ngIf="myform.hasError('pattern', 'second_rem_nm')">
                                                    Reminder must be an integer or greater than zero</div>
                                            </div>
                                        </div>

                                        <div class="offset-1"></div>

                                        <label class="col-lg-2 col-form-label">To Whom:</label>
                                        <div class="col-lg-3" style="text-align: center;"> {{AlertsObject.Second_Rem_ToWhomList}}</div>
                                        <!-- <div class="col-lg-3">
                                            <mat-select class="form-control" placeholder="Select 2nd reminder to whom"
                                                [(ngModel)]="AlertsObject.Second_Rem_ToWhomList"
                                                [ngClass]="{ 'is-invalid':myform.submitted  && secondTowhom.invalid || secondTowhom?.touched && secondTowhom.invalid}"
                                                #secondTowhom="ngModel" multiple name="secondTowhom" disabled>
                                                <mat-option disabled value="Enquiry Initiator">Enquiry Initiator
                                                </mat-option>
                                                <mat-option *ngFor="let item of Roles" value="{{item.Id}}">
                                                    {{item.Name}}</mat-option>
                                                <mat-option disabled *ngIf="Roles.length <= 0" value="No Data Found"> No
                                                    Data Found </mat-option>
                                            </mat-select>

                                            <div class="invalid-feedback"
                                                *ngIf="myform.submitted && secondTowhom.invalid || secondTowhom?.touched && secondTowhom.invalid">
                                                <div *ngIf="myform.hasError('required', 'secondTowhom')">
                                                    Second reminder to whom is required
                                                </div>

                                            </div>

                                        </div> -->

                                    </div>
                                </mat-card>
                                <br>

                                <mat-card class="cardBorder">
                                    <h5>Third Reminder</h5>
                                    <mat-divider></mat-divider>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Reminder (In Days):</label>
                                        <div class="col-lg-3">
                                            <input class="form-control m-input" type="text" name="third_rem_nm"
                                                [(ngModel)]="AlertsObject.Third_Rem" #third_rem_nm="ngModel"
                                                [ngClass]="{ 'is-invalid':(myform.submitted  && third_rem_nm.invalid) || (third_rem_nm?.touched && third_rem_nm.invalid)}"
                                                placeholder="Enter 3rd Reminder (In Days)"
                                                (keypress)="AvoidSpace($event);isNumberKey($event)" maxlength="4"
                                                [disabled]="isEditable"
                                                (change)="OnReminderDaysChange(myform,'Third_Rem')" pattern="[1-9]+">
                                            <div *ngIf="myform.submitted && third_rem_nm.invalid || third_rem_nm?.touched && third_rem_nm.invalid"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="third_rem_nm.errors?.required">Third reminder is required</div> -->
                                                <div *ngIf="myform.hasError('RemValidation', 'third_rem_nm')">
                                                    Third reminder should be greater than first and second reminder
                                                </div>
                                                <div *ngIf="myform.hasError('pattern', 'third_rem_nm')">
                                                    Reminder must be an integer or greater than zero</div>
                                            </div>
                                        </div>

                                        <div class="offset-1"></div>

                                        <label class="col-lg-2 col-form-label">To Whom:</label>
                                        <div class="col-lg-3" style="text-align: center;"> {{AlertsObject.Third_Rem_ToWhomList}}</div>
                                        <!-- <div class="col-lg-3">
                                            <mat-select class="form-control" placeholder="Select 3rd reminder to whom"
                                                [(ngModel)]="AlertsObject.Third_Rem_ToWhomList"
                                                [ngClass]="{ 'is-invalid':myform.submitted  && thirdTowhom.invalid || thirdTowhom?.touched && thirdTowhom.invalid}"
                                                #thirdTowhom="ngModel" multiple name="thirdTowhom" disabled>
                                                <mat-option disabled value="Enquiry Initiator">Enquiry Initiator
                                                </mat-option>
                                                <mat-option *ngFor="let item of Roles" value="{{item.Id}}">
                                                    {{item.Name}}</mat-option>
                                                <mat-option disabled *ngIf="Roles.length <= 0" value="No Data Found"> No
                                                    Data Found </mat-option>
                                            </mat-select>

                                            <div class="invalid-feedback"
                                                *ngIf="myform.submitted && thirdTowhom.invalid || thirdTowhom?.touched && thirdTowhom.invalid">
                                                <div *ngIf="myform.hasError('required', 'thirdTowhom')">
                                                    Third reminder to whom is required
                                                </div>
                                            </div>

                                        </div> -->

                                    </div>



                                    <!-- <div class="form-group m-form__group row">

                                        <label class="col-lg-2 col-form-label">CC:</label>
                                        <div class="col-lg-3">
                                            <mat-select class="form-control" placeholder="Select CC"
                                                [(ngModel)]="AlertsObject.CC_Rem_toWhomList"
                                                [ngClass]="{ 'is-invalid':myform.submitted  && ccwhom.invalid || ccwhom?.touched && ccwhom.invalid}"
                                                #ccwhom="ngModel" multiple name="ccwhom" [disabled]="isEditable"
                                                [ngStyle]="{'background-color': isEditable ? '#f4f5f8' : '#fff' }">
                                                <mat-option *ngFor="let item of Roles" value="{{item.Id}}" 
                                                [disabled]="AlertsObject.Third_Rem_ToWhomList[0] == item.Id">
                                                    {{item.Name}}</mat-option>
                                                <mat-option disabled *ngIf="Roles.length <= 0" value="No Data Found"> No
                                                    Data Found </mat-option>
                                            </mat-select>

                                            <div class="invalid-feedback"
                                                *ngIf="myform.submitted && ccwhom.invalid || ccwhom?.touched && ccwhom.invalid">
                                                <div *ngIf="myform.hasError('required', 'ccwhom')">
                                                    CC is required
                                                </div>
                                            </div>

                                        </div>
                                        <div class="offset-1"></div>

                                    </div> -->
                                </mat-card>
                                <br>


                                <mat-card class="cardBorder">
                                    <h5>Escalation</h5>
                                    <mat-divider></mat-divider>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Reminder (In Days):</label>
                                        <div class="col-lg-3">
                                            <input class="form-control m-input" type="text" name="escalation"
                                                [(ngModel)]="AlertsObject.Escalation" #escalation="ngModel"
                                                [ngClass]="{ 'is-invalid':(myform.submitted  && escalation.invalid) || (escalation?.touched && escalation.invalid)}"
                                                placeholder="Enter Escalation Reminder (In Days)"
                                                (keypress)="AvoidSpace($event);isNumberKey($event)" maxlength="4"
                                                [disabled]="isEditable"
                                                (change)="OnReminderDaysChange(myform,'Escalation')" pattern="[1-9]+">
                                            <div *ngIf="(myform.submitted && escalation.invalid) || (escalation?.touched && escalation.invalid)"
                                                class="invalid-feedback">
                                                <!-- <div *ngIf="escalation.errors?.required">Third reminder is required</div> -->
                                                <div *ngIf="myform.hasError('RemValidation', 'escalation')">
                                                    Escalation reminder should be greater than first,second and third reminder
                                                </div>
                                                <div *ngIf="myform.hasError('pattern', 'escalation')">
                                                    Reminder must be an integer or greater than zero</div>
                                            </div>
                                        </div>

                                        <div class="offset-1"></div>

                                        <label class="col-lg-2 col-form-label">To Whom:</label>
                                        <div class="col-lg-3" style="text-align: center;"> {{AlertsObject.Escalation_ToWhomList}}</div>
                                        <!-- <div class="col-lg-3">
                                            <mat-select class="form-control" placeholder="Select escalation reminder to whom"
                                                [(ngModel)]="AlertsObject.Escalation_ToWhomList"
                                                [ngClass]="{ 'is-invalid':myform.submitted  && escalationTowhom.invalid || escalationTowhom?.touched && escalationTowhom.invalid}"
                                                #escalationTowhom="ngModel" multiple name="escalationTowhom" disabled>
                                                <mat-option disabled value="Enquiry Initiator">Enquiry Initiator
                                                </mat-option>
                                                <mat-option *ngFor="let item of Roles" value="{{item.Id}}">
                                                    {{item.Name}}</mat-option>
                                                <mat-option disabled *ngIf="Roles.length <= 0" value="No Data Found"> No
                                                    Data Found </mat-option>
                                            </mat-select>

                                            <div class="invalid-feedback"
                                                *ngIf="myform.submitted && escalationTowhom.invalid || escalationTowhom?.touched && escalationTowhom.invalid">
                                                <div *ngIf="myform.hasError('required', 'escalationTowhom')">
                                                    Escalation reminder to whom is required
                                                </div>
                                            </div>

                                        </div> -->

                                    </div>



                                    <div class="form-group m-form__group row">

                                        <label class="col-lg-2 col-form-label">CC:</label>
                                        <div class="col-lg-3">
                                            <mat-select class="form-control" placeholder="Select CC"
                                                [(ngModel)]="AlertsObject.CC_Rem_toWhomList"
                                                [ngClass]="{ 'is-invalid':myform.submitted  && ccwhom.invalid || ccwhom?.touched && ccwhom.invalid}"
                                                #ccwhom="ngModel" multiple name="ccwhom" [disabled]="isEditable"
                                                [ngStyle]="{'background-color': isEditable ? '#f4f5f8' : '#fff' }">
                                                <mat-option *ngFor="let item of Roles" value="{{item.Id}}" 
                                                [disabled]="AlertsObject.Third_Rem_ToWhomList[0] == item.Id">
                                                    {{item.Name}}</mat-option>
                                                <mat-option disabled *ngIf="Roles.length <= 0" value="No Data Found"> No
                                                    Data Found </mat-option>
                                            </mat-select>

                                            <div class="invalid-feedback"
                                                *ngIf="myform.submitted && ccwhom.invalid || ccwhom?.touched && ccwhom.invalid">
                                                <div *ngIf="myform.hasError('required', 'ccwhom')">
                                                    CC is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="offset-1"></div>
                                    </div>
                                </mat-card>
                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isAlertsEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/Reminders-config']" routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>