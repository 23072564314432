import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EngagementReportService } from 'src/app/Service/engagement-report.service';

@Component({
  selector: 'app-customer-engagement',
  templateUrl: './customer-engagement.component.html',
  styleUrls: ['./customer-engagement.component.scss']
})
export class CustomerEngagementComponent implements OnInit {

  constructor(private engagementReportService: EngagementReportService, private location: Location) { }

  displayedColumns: string[] = ['companyName', 'customerCode', 'regionName'];
  dataSource: MatTableDataSource<any>;

  @ViewChild('paginator') paginator: MatPaginator;

  ngOnInit() {
    //this.getCustomerInfo();
  }

  getCustomerInfo() {
    let data = {
      reportTypeId: 'REP_Customer_Details',
    };
    this.engagementReportService.getReportData(data).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    })
  }
}
