import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';


@Component({
  selector: 'app-opportunity-rfq-chart',
  templateUrl: './opportunity-rfq-chart.component.html',
  styleUrls: ['./opportunity-rfq-chart.component.scss']
})
export class OpportunityRfqChartComponent implements OnInit {

  @Input()
  showLoader: boolean = false;
  drpdnOption: string = "Units";

  @Input() Year: number;
  @Input() region: number;
  @Input() currency: number;
  @Input() filterChanged: boolean;
  constructor(private toastr: ToastrService, private commonService: CommonService) { }

  ngOnInit() {
  //  am4core.options.onlyShowOnViewport = true;
    this.OpportunityRFQ();

  }
  ngOnChanges(): void {
    if (this.filterChanged) this.OpportunityRFQ();
  }

  OnSelectChange() {
    this.OpportunityRFQ();
  }

  OpportunityRFQ() {
    this.showLoader = true;
    let queryString = "";
    queryString += "drpdnOption=" + this.drpdnOption;
    queryString += "&Year=" + this.Year;
    queryString += "&regionid=" + this.region;
    queryString += "&currency=" + this.currency;

    this.commonService.GetData("Dashboard", "OpportunityRFQChart", queryString).subscribe((data: any) => {
      // Create chart instance
      let chart = am4core.create("OpportunityRFQChart", am4charts.XYChart);

      // Export
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      // chart.cursor = new am4charts.XYCursor();
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;
      chart.exporting.menu = new am4core.ExportMenu();
      let options = chart.exporting.getFormatOptions("pdf");
      options.addURL = false;
      chart.exporting.setFormatOptions("pdf", options);
      chart.exporting.filePrefix = "Opportunity_Enquiry/RFQ";
      /**************Custom Export**************** */
      chart.exporting.menu.items = [
        {
          "label": "...",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                // { "type": "svg", "label": "SVG" },
                { "type": "pdf", "label": "PDF" }
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "xlsx", "label": "XLSX" },
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }
      ];
      let CurrencyLabel = "";
      if (this.drpdnOption == "Amount") {
      if (this.currency == 1) CurrencyLabel = "(MUSD)";
      if (this.currency == 2) CurrencyLabel = "(MEURO)";
      if (this.currency == 3) CurrencyLabel = "(MINR)";
      }
      else {
        CurrencyLabel = "(No's)";
      }

      chart.exporting.dataFields = {
        "category": "Month",
        "valueBar1": "Lead/Enquiry_Individual" + CurrencyLabel,
        "valueLine1": "Lead/Enquiry_Cumulative " + CurrencyLabel,
        "valueBar2": "RFQ_Individual" + CurrencyLabel,
        "valueLine2": "RFQ_Cumulative " + CurrencyLabel,

      }
      /**************End Custom Export**************** */
      let title = chart.titles.create();
      title.text = "Lead/Enquiry vs RFQ";
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;

      chart.data = data;

      /* Create axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;

      
      /* Create value axis */
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.00" ? "0" : text;
        return val;
      });

      /*********Change by ashwini*******/
      /**************Scrolling Start End Point**************** */
      chart.events.on("beforedatavalidated", function(ev) {
        categoryAxis.start = 0;
        categoryAxis.end = 0.5;
        valueAxis.start = 0;
        valueAxis.end = 1;
        });
        /**************End Scrolling Start End Point**************** */
      if (this.drpdnOption == 'Units') {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }

      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;
      valueAxis.events.on("ready", function (ev) {
        ev.target.min = ev.target.min;
        ev.target.max = ev.target.max;
        if (ev.target.max < 0.1) valueAxis.extraMax = 10;
        if (ev.target.max > 0.1 && ev.target.max < 1) valueAxis.extraMax = 5;
        if (ev.target.max > 1) valueAxis.extraMax = 0.2;
      })

      valueAxis.calculateTotals = true;
      /*********End***********/

      chart.numberFormatter.numberFormat = "0";
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
        return val;
      });
      valueAxis.title.fontWeight = "bold";
      valueAxis.title.fontSize = "16px";
      valueAxis.min = 0;
      valueAxis.extraMax = 0.5;


      //#region "LineSeries1"
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = "Lead/Enquiry";
      lineSeries.dataFields.valueY = "valueLine1";
      lineSeries.dataFields.categoryX = "category";
      lineSeries.tooltip.getFillFromObject = false;
      lineSeries.tooltip.background.fill = am4core.color("#4a463c");
      lineSeries.stroke = am4core.color("#ff4d4d");   //For line or column color

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#ff4d4d"); // tooltips grab fill from parent by default
      bullet.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color("#fff");
      circle.strokeWidth = 3;

      let labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueL1}";
      labelBullet.label.verticalCenter = "bottom";
      //#endregion

      //#region "LineSeries2"
      let lineSeries2 = chart.series.push(new am4charts.LineSeries());
      lineSeries2.name = "RFQ";
      lineSeries2.dataFields.valueY = "valueLine2";
      lineSeries2.dataFields.categoryX = "category";
      lineSeries2.tooltip.getFillFromObject = false;
      lineSeries2.tooltip.background.fill = am4core.color("#4a463c");
      //lineSeries2.stroke = am4core.color("#4a463c"); //For line or column color

      let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
      bullet2.fill = am4core.color("#4a463c"); // tooltips grab fill from parent by default
      bullet2.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]"
      let circle2 = bullet2.createChild(am4core.Circle);
      circle2.radius = 4;
      circle2.fill = am4core.color("#fff");
      circle2.strokeWidth = 3;

      let labelBullet2 = lineSeries2.bullets.push(new am4charts.LabelBullet());
      labelBullet2.label.text = "{valueL2}";
      labelBullet2.label.verticalCenter = "bottom";
      //#endregion

      //#region "ColumnSeries1"
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.name = "Lead/Enquiry";
      columnSeries.dataFields.valueY = "valueBar1";
      columnSeries.dataFields.categoryX = "category";

      columnSeries.columns.template.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.fill = am4core.color("#ff4d4d");
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      colLabel.label.text = "{valueY}";
      colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;
      //#endregion

      //#region "ColumnSeries2"
      /* Create series */
      let column2Series = chart.series.push(new am4charts.ColumnSeries());
      column2Series.name = "RFQ";
      column2Series.dataFields.valueY = "valueBar2";
      column2Series.dataFields.categoryX = "category";

      column2Series.columns.template.tooltipText = "[#fff font-size: 13px]{name} in {categoryX}: [/][#fff font-size: 13px]{valueY}[/] [#fff]{additional}[/]";
      column2Series.columns.template.propertyFields.fillOpacity = "fillOpacity";
      column2Series.columns.template.propertyFields.stroke = "stroke";
      column2Series.columns.template.propertyFields.strokeWidth = "strokeWidth";
      column2Series.columns.template.propertyFields.strokeDasharray = "columnDash";
      column2Series.tooltip.label.textAlign = "middle";
      column2Series.tooltip.getFillFromObject = false;
      column2Series.fill = am4core.color("#6794dc");
      column2Series.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel2 = column2Series.bullets.push(new am4charts.LabelBullet);
      colLabel2.label.text = "{valueY}";
      colLabel2.label.rotation = 0;
      colLabel2.label.fontSize = 9;
      colLabel2.label.padding(0, 0, 20, 0);
      colLabel2.label.truncate = false;
      colLabel2.label.hideOversized = false;
      //#endregion

      chart.legend = new am4charts.Legend();

      this.showLoader = false;
    }, error => {
      this.showLoader = false;

    });

  }

}
