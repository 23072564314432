import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivityLinkageEntityType, ActivityStatusByType, ActivityType, EntityRecord, User } from 'src/app/Model/ActivityModel';
import { ActivityService } from 'src/app/Service/activity.service';
import { EngagementReportService } from 'src/app/Service/engagement-report.service';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { Observable } from 'rxjs';
import { ViewActivityComponent } from '../activity/view-activity/view-activity.component';
@Component({
  selector: 'app-planned-vs-actual-activity',
  templateUrl: './planned-vs-actual-activity.component.html',
  styleUrls: ['./planned-vs-actual-activity.component.scss']
})
export class PlannedVsActualActivityComponent implements OnInit {
  subscription: any;
  resp: any;
  hide: boolean = false;
  activities: any; // Array to store the activity data
  day: string = '';
  datetime: string = '';
  activityStatusFilter: number;
  activityTypeFilter: number;
  activityTypeMaster: ActivityType[] = [];
  activityStatusByTypeMaster: ActivityStatusByType[] = [];
  allEntityTypesMaster: ActivityLinkageEntityType[] = [];
  filterActivityForm: any = {};
  entityTypeFilterArray: any = [];
  FromDateNgb: NgbDate | null;
  FromDate: string | null;
  ToDateNgb: NgbDate | null;
  hoveredDate: NgbDate | null = null;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  datePipe = new DatePipe('en-US');
  allUserMaster: User[] = [];
  partid: string = null;

  accesspermissionobj: AccessPermissionModel[] = Array<AccessPermissionModel>();
  AccesspermissionForRole: any[] = [];
   canViewCustomerEngagementReportOfOthers: boolean = true;
  Role: any;

  @Input() customerId: number;
  @Input() formDetails: string;

  constructor(private toastr: ToastrService, private reportService: EngagementReportService, private activityService: ActivityService,
    public formatter: NgbDateParserFormatter, private cd: ChangeDetectorRef, private calendar: NgbCalendar, private accessservice: AccessPermissionService, private modalService: NgbModal) {
    const current = new Date();
    this.minDate = { year: current.getFullYear(), month: current.getMonth(), day: current.getDate() };
    this.maxDate = { year: current.getFullYear() + 1, month: current.getMonth() + 5, day: current.getDate() };
  }
  @Input()
  showLoader: boolean = false;
  ngOnInit(): void {
    
    this.filterActivityForm.ActivityTypeId = null;
    this.filterActivityForm.ActivityStatusId = null;
    this.filterActivityForm.EntityTypeId = this.customerId ? 1 : null;
    this.filterActivityForm.EntityId = this.customerId ? this.customerId : null;
    this.filterActivityForm.ActivityOwnerId = localStorage.getItem('UserID');
    this.Role = localStorage.getItem("UserRole");
    this.partid = this.filterActivityForm.ActivityOwnerId;
    this.filterActivityForm.IsOwner = true;
    const today = this.calendar.getToday();
    const currentYear = today.year;
    const currentMonth = today.month;
    this.FromDateNgb = new NgbDate(currentYear, currentMonth, 1);
    const lastDay = this.getLastDayOfMonth(currentYear, currentMonth);
    this.ToDateNgb = new NgbDate(currentYear, currentMonth, lastDay);

    this.GetActivityTypeMaster();
    this.GetActivityStatusMaster();
    this.GetEntityTypesMaster();
    
    this.activityService.getallUserMaster().subscribe((data: User[]) => {
      this.allUserMaster = data;
    });
   
    this.getAccess().subscribe(x=>{
      
      let filters = {}
      if(this.formDetails == "Customer_Engagement"){
        filters = {
          reportTypeId: "REP_BY_ACTUAL_VS_PLANNED",
          StartDate: null,
          EndDate: null,
          IsOwner: false,
          EntityTypeId: this.filterActivityForm.EntityTypeId, 
          EntityId: this.filterActivityForm.EntityId,
          ParticipantId: this.partid,
        };
      }
      else{
        filters = {
          reportTypeId: "REP_BY_ACTUAL_VS_PLANNED",
          StartDate: this.convertDate(this.FromDateNgb),
          EndDate: this.convertDate(this.ToDateNgb),
          IsOwner: this.filterActivityForm.IsOwner,
          EntityTypeId: this.filterActivityForm.EntityTypeId, 
          EntityId: this.filterActivityForm.EntityId,
          ParticipantId: this.partid
        };
      }
      this.getData(filters);  
  
    });
  }

  getLastDayOfMonth(year: number, month: number): number {
    // Create a new date for the first day of the next month
    const nextMonth = month === 12 ? 1 : month + 1;
    const nextYear = month === 12 ? year + 1 : year;
    const nextMonthDate = new Date(nextYear, nextMonth - 1, 1);

    // Subtract one day from the first day of the next month to get the last day of the current month
    const lastDayOfMonth = new Date(nextMonthDate.getTime() - 86400000).getDate();
    return lastDayOfMonth;
  }


  getData(filters) {
    this.showLoader = true;
    this.subscription = this.reportService.getReportData(filters).subscribe((data: any) => {
      this.showData(data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to activity list!");
      this.showLoader = false;
    });
  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }

  onSubmit(filterActivityForm: any) {


    if (Object.keys(filterActivityForm).length == 0 && (this.FromDateNgb == null && this.ToDateNgb == null)) {
      this.toastr.error("Please select filter!");
      return
    }
    if (this.FromDateNgb == null || this.ToDateNgb == null) {
      this.toastr.error("Please select date range!");
      return
    }

    this.showLoader = true;
    var frmdate: string;
    var ToDateNgb: string;
    if (this.FromDateNgb != null) {
      frmdate = this.convertDate(new NgbDate(this.FromDateNgb.year, this.FromDateNgb.month, this.FromDateNgb.day))
    }

    if (this.ToDateNgb != null) {
      ToDateNgb = this.convertDate(new NgbDate(this.ToDateNgb.year, this.ToDateNgb.month, this.ToDateNgb.day))
    }
    let filters = {
      reportTypeId: "REP_BY_ACTUAL_VS_PLANNED",
      StartDate: frmdate ?? null,
      EndDate: ToDateNgb ?? null,
      ActivityTypeId: filterActivityForm.ActivityTypeId ?? null,
      ActivityStatusId: filterActivityForm.ActivityStatusId ?? null,
      subject: filterActivityForm.subject ?? null,
      ParticipantId: filterActivityForm.ActivityOwnerId ?? null,
      EntityTypeId: filterActivityForm.EntityTypeId ?? null,
      EntityId: filterActivityForm.EntityId ?? null,
      IsOwner: this.filterActivityForm.IsOwner
    };
    this.reportService.getReportData(filters).subscribe((res: any) => {
      this.showData(res)
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to activity list!");
      this.showLoader = false;
    });
  }

  showData(data: any) {
    this.activities = this.reportService.convertArrayToObject(data);
  }
  getDay(dateString) {
    const dateObject = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.day = days[dateObject.getDay()];
    return this.day;
  }
  getDateDetails(dateString) {
    const dateObject = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    this.day = days[dateObject.getDay()];
    this.datetime = dateObject.getDate().toString() + '-' + Months[dateObject.getMonth().toString()] + '-' + dateObject.getFullYear().toString();
    return this.datetime;
  }
  onActivityTypeChange(ActivityTypeId: number,) {
    this.activityService.getActivityStatusMaster(ActivityTypeId).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
    });
  }

  onEntityTypeChange(EntityTypeId: any) {

    this.activityService.getrelatedEntityRecords(EntityTypeId, '').subscribe((data: EntityRecord[]) => {
      this.entityTypeFilterArray = data;
    });
  }
  GetEntityTypesMaster() {
    this.activityService.getallEntityTypesMaster().subscribe((data: ActivityLinkageEntityType[]) => {
      this.allEntityTypesMaster = data;
    });
  }
  GetActivityStatusMaster() {
    this.activityService.getActivityStatusMaster(null).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
    });
  }
  GetActivityTypeMaster() {
    this.activityService.getActivityTypeMaster().subscribe((data) => {
      this.activityTypeMaster = data;
    });
  }

  applyFilters() {
    this.activities = this.activities.filter(activity =>
      (this.activityStatusFilter ? activity.ActivityStatusId === this.activityStatusFilter : true) &&
      (this.activityTypeFilter ? activity.ActivityTypeId === this.activityTypeFilter : true)
    );
  }
  removeFilter() {
    const today = this.calendar.getToday();
    const currentYear = today.year;
    const currentMonth = today.month;
debugger;
    const lastDay = this.getLastDayOfMonth(currentYear, currentMonth);
    this.FromDateNgb = new NgbDate(currentYear, currentMonth, 1);
    this.ToDateNgb = new NgbDate(currentYear, currentMonth, lastDay);
    if (!this.canViewCustomerEngagementReportOfOthers) {
      this.partid = this.filterActivityForm.ActivityOwnerId;
    }
    let reqBody = {
      reportTypeId: "REP_BY_ACTUAL_VS_PLANNED", ActivityTypeId: null, ActivityStatusId: null, Subject: null, StartDate: this.convertDate(this.FromDateNgb),
      EndDate: this.convertDate(this.ToDateNgb), EntityTypeId: null, EntityId: null, ParticipantId: this.partid, IsOwner: false
    }
    this.reportService.getReportData(reqBody).subscribe((res: any) => {
      this.activities = res;
      this.showData(res);
      this.filterActivityForm = {}
      this.activityStatusByTypeMaster = [];
      this.entityTypeFilterArray = [];
      this.FromDateNgb = new NgbDate(currentYear, currentMonth, 1);
      this.ToDateNgb = new NgbDate(currentYear, currentMonth, lastDay);
      this.filterActivityForm.ActivityTypeId = null;
      this.filterActivityForm.ActivityStatusId = null;
      this.filterActivityForm.EntityTypeId = null;
      this.filterActivityForm.EntityId = null;
      this.filterActivityForm.ActivityOwnerId = this.partid;
    });
  }


  //Date picker
  onDateSelection(date: NgbDate) {
    if (!this.FromDateNgb && !this.ToDateNgb) {
      this.FromDateNgb = date;
    } else if (this.FromDateNgb && !this.ToDateNgb && date && date.after(this.FromDateNgb)) {
      this.ToDateNgb = date;
    } else {
      this.ToDateNgb = null;
      this.FromDateNgb = date;
    }
  }
  OnFromDateFilterChange() {
    let EnquiryDateFromFiter = this.ConvertNgbDateToDate(this.FromDateNgb)
    let EnquiryDateToFiter = this.ConvertNgbDateToDate(this.ToDateNgb)
    if (EnquiryDateToFiter < EnquiryDateFromFiter) {
      this.ToDateNgb = this.FromDateNgb;
      this.cd.detectChanges();

    }
  }
  ConvertNgbDateToDate(ngbdate: any) {

    if (ngbdate != null) {
      //return new Date(ngbdate.year, ngbdate.month - 1, ngbdate.day)
    }
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  isHovered(date: NgbDate) {

    return this.FromDateNgb && !this.ToDateNgb && this.hoveredDate && date.after(this.FromDateNgb) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {

    return this.ToDateNgb && date.after(this.FromDateNgb) && date.before(this.ToDateNgb);
  }

  isRange(date: NgbDate) {

    return date.equals(this.FromDateNgb) || (this.ToDateNgb && date.equals(this.ToDateNgb)) || this.isInside(date) || this.isHovered(date);
  }

  getAccess(): Observable<any> {
    let tracker = this.accessservice.GetAll();

    tracker.subscribe((data: any) => {
      this.accesspermissionobj = data;
      this.canViewCustomerEngagementReportOfOthers = this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey == 'canViewCustomerEngagementReportOfOthers')[0]?.IsVisible;
      if (!this.canViewCustomerEngagementReportOfOthers) {
        this.partid = this.filterActivityForm.ActivityOwnerId;
      }
    });

    return tracker;
  }

  openViewPopup(activityId) {
    const modalRef = this.modalService.open(ViewActivityComponent, {
      size: 'xl', backdrop: 'static', keyboard: false
    });

    var Activity = {
      activityId: activityId,
      inPopUpMode: true
    };

    modalRef.componentInstance.Activity = Activity;
  }

}
