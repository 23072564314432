<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Employee
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid && onSubmit(myform)"
                            [mustMatch]="['password', 'confirmpassword']" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Username:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="username"
                                            [(ngModel)]="EmployeeDetails.Username" #username="ngModel"
                                            [ngClass]="{  'is-invalid':myform.submitted  && username.invalid || username?.touched && username.invalid}"
                                            placeholder="Enter Username" (keypress)="AvoidSpace($event)" minlength="3"
                                            maxlength="55" pattern="^[^\n ]*$" [disabled]="isEditable"
                                            (change)="EmployeeDetails.Username=EmployeeDetails.Username.trim()"
                                            required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && username.invalid || username?.touched && username.invalid">
                                            <div *ngIf="myform.hasError('required', 'username')">
                                                Username is required</div>
                                            <div  *ngIf="myform.hasError('minlength', 'username')">
                                                Username should be 3-55 characters</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'username')">
                                                Username already exists!
                                            </div>
                                            <div *ngIf="myform.hasError('pattern', 'username')">
                                                Username should not contain spaces</div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Name:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="employeename"
                                            [(ngModel)]="EmployeeDetails.Name" #employeename="ngModel"
                                            [ngClass]="{  'is-invalid':myform.submitted  && employeename.invalid || employeename?.touched && employeename.invalid}"
                                            placeholder="Enter Name" (keypress)="AvoidSpace($event)" minlength="3"
                                            maxlength="50" [disabled]="isEditable"
                                            (change)="EmployeeDetails.Name=EmployeeDetails.Name.trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && employeename.invalid || employeename?.touched && employeename.invalid">
                                            <div *ngIf="myform.hasError('required', 'employeename')">
                                                Employee Name is required</div>
                                            <div  *ngIf="myform.hasError('minlength', 'employeename')">
                                                Employee name should be 3-50 characters</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Email:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="email" maxlength="255"
                                            [(ngModel)]="EmployeeDetails.Email" #email="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && email.invalid || email?.touched && email.invalid}"
                                            placeholder="Enter Email" [pattern]="emailPattern"
                                            (keypress)="AvoidSpace($event)" [disabled]="isEditable"
                                            (change)="EmployeeDetails.Email=EmployeeDetails.Email.trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && email.invalid || email?.touched && email.invalid">
                                            <div *ngIf="myform.hasError('required', 'email')">
                                                Email is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'email')">
                                                Should be in Email Id format (e.g. user@weiss.com)</div>

                                        </div>

                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Employee Code:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="empcode"
                                            [(ngModel)]="EmployeeDetails.EmpCode" #empcode="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && empcode.invalid || empcode?.touched && empcode.invalid}"
                                            placeholder="Enter Employee Code" (keypress)="AvoidSpace($event)"
                                            maxlength="50" [disabled]="isEditable"
                                            (change)="EmployeeDetails.EmpCode=EmployeeDetails.EmpCode.trim()" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && empcode.invalid || empcode?.touched && empcode.invalid">
                                            <div *ngIf="myform.hasError('required', 'empcode')">
                                                Employee code is required</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'empcode')">
                                                Employee code already exists!
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Contact Number:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="contactnumber"
                                            [(ngModel)]="EmployeeDetails.ContactNumber" #contactnumber="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && contactnumber.invalid || contactnumber?.touched && contactnumber.invalid}"
                                            placeholder="Enter Contact Number" minlength="10" maxlength="15"
                                            (keypress)="AvoidSpace($event);isNumberKey($event);" [disabled]="isEditable"
                                            (change)="EmployeeDetails.ContactNumber=EmployeeDetails.ContactNumber.trim()"
                                            pattern="[0-9]+" required>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && contactnumber.invalid || contactnumber?.touched && contactnumber.invalid">
                                            <div *ngIf="myform.hasError('required', 'contactnumber')">
                                                Contact number is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'contactnumber')">
                                                Please enter valid contact number</div>
                                            <div  *ngIf="myform.hasError('minlength', 'contactnumber')">
                                                Contact number should be 10-15 digits</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'contactnumber')">
                                                Contact number already exists!
                                            </div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Role:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="roleid"
                                            [(ngModel)]="EmployeeDetails.Role" #roleid="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && roleid.invalid || roleid?.touched && roleid.invalid}"
                                            [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Role</option>
                                            <option *ngFor="let item of Roles" value="{{item.Id}}">{{item.Name}}
                                            </option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && roleid.invalid || roleid?.touched && roleid.invalid">
                                            <div *ngIf="myform.hasError('required', 'roleid')">
                                                Role is required</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Country:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel2" name="country"
                                            [(ngModel)]="EmployeeDetails.Country" #country="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && country.invalid || country?.touched && country.invalid}"
                                            [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Country</option>
                                            <option *ngFor="let item of Country" value="{{item.Id}}">{{item.Name}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && country.invalid || country?.touched && country.invalid">
                                            <div *ngIf="myform.hasError('required', 'country')">
                                                Country is required</div>
                                        </div>

                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Region:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                      <!--  <select class="form-control " id="sel3" name="regionid"
                                            [(ngModel)]="EmployeeDetails.Region" #regionid="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && regionid.invalid || regionid?.touched && regionid.invalid}"
                                            [disabled]="isEditable" required>
                                            <option value="null" disabled>Select Region</option>
                                            <option *ngFor="let item of Region" value="{{item.Id}}">{{item.Name}}
                                            </option>
                                        </select>-->
					
					   <mat-select class="form-control " id="sel3" name="regionid"
                                            placeholder="Select Region"
                                            [(ngModel)]="EmployeeDetails.Region" #regionid="ngModel" multiple
                                            [ngClass]="{ 'is-invalid':myform.submitted  && regionid.invalid || regionid?.touched && regionid.invalid}"
                                             required [disabled]="isEditable">
                                            <mat-option *ngFor="let item of Region" value="{{item.Id}}">{{item.Name}}                                   
                                            </mat-option>                                      
                                        </mat-select>     
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && regionid.invalid || regionid?.touched && regionid.invalid">
                                            <div *ngIf="myform.hasError('required', 'regionid')">
                                                Region is required</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Reporting Manager<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel4" name="reportingmanager"
                                            [(ngModel)]="EmployeeDetails.ReportingManager" #reportingmanager="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && reportingmanager.invalid || reportingmanager?.touched && regionid.invalid}"
                                            required (change)="ChangeEmployee(EmployeeDetails.ReportingManager)" [disabled]="isEditable">
                                            <option value="" disabled>Select Reporting Manager</option>
                                            <option *ngFor="let item of EmployeeData" value="{{item.Fk_User_Id}}">{{item.Name}}
                                            </option>                                          
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && reportingmanager.invalid || reportingmanager?.touched && reportingmanager.invalid">
                                            <div *ngIf="myform.hasError('required', 'reportingmanager')">
                                                Reporting manager is required</div>
                                        </div>
                                    </div>

                                    <div class="offset-1"></div>
                                    <label class="col-lg-2 col-form-label">Reporting Manager Email:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="repomanageremail"
                                            maxlength="255" [(ngModel)]="EmployeeDetails.ReportingManagerEmail"
                                            #repomanageremail="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && repomanageremail.invalid || repomanageremail?.touched && repomanageremail.invalid}"
                                            placeholder="Reporting Manager Email" [pattern]="emailPattern"
                                            (keypress)="AvoidSpace($event)"
                                            required disabled>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && repomanageremail.invalid || repomanageremail.touched && repomanageremail.invalid">
                                            <div *ngIf="myform.hasError('required', 'repomanageremail')">
                                                Reporting Manager Email is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'repomanageremail')">
                                                Should be in Email Id format (e.g. user@weiss.com)</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group m-form__group row" *ngIf="!isEditable">
                                    <label class="col-lg-2 col-form-label">New Password:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" [type]="Passhide ? 'password': 'text'"
                                            name="password" [(ngModel)]="EmployeeDetails.Password" #password="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && password.invalid || password?.touched && password.invalid}"
                                            placeholder="Enter new password" [pattern]="passPattern" minlength="6"
                                            maxlength="30" (keypress)="AvoidSpace($event)"
                                            (change)="EmployeeDetails.Password=EmployeeDetails.Password.trim()"
                                            [disabled]="isEditable">
                                        <span class="p-viewer">
                                            <mat-icon matSuffix (click)="Passhide = !Passhide"
                                                style=" font-size: 20px;">{{Passhide ? 'visibility_off': 'visibility'}}
                                            </mat-icon>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && password.invalid || password?.touched && password.invalid">
                                            <div *ngIf="myform.hasError('required', 'password')">
                                                Password is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'password')">
                                                Password must contains minimum 6 alphanumeric with at least one
                                                uppercase, one lowercase and one special characters. (Ex. Pass@123)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label class="col-lg-2  col-form-label">Confirm Password:</label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input"
                                            [type]="ConfirmPasshide ? 'password': 'text'" name="confirmpassword"
                                            [(ngModel)]="EmployeeDetails.ConfirmPassword" #confirmpassword="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && confirmpassword.invalid || confirmpassword?.touched && confirmpassword.invalid}"
                                            placeholder="Enter Confirm Password" (keypress)="AvoidSpace($event)"
                                            (change)="EmployeeDetails.ConfirmPassword=EmployeeDetails.ConfirmPassword.trim()"
                                            [disabled]="isEditable" [required]="EmployeeDetails.Password != ''">
                                        <span class="p-viewer">
                                            <mat-icon matSuffix (click)="ConfirmPasshide = !ConfirmPasshide"
                                                style=" font-size: 20px;">{{ConfirmPasshide ? 'visibility_off':
                                                'visibility'}}</mat-icon>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && confirmpassword.invalid || confirmpassword?.touched && confirmpassword.invalid">
                                            <div *ngIf="myform.hasError('required', 'confirmpassword')">
                                                Confirm password is required</div>
                                            <div *ngIf="myform.hasError('mustMatch', 'confirmpassword')">
                                                Confirm password must be same as password</div>
                                        </div>
                                    </div>

                                </div>
                               
                                <div 
                                    class="form-group m-form__group row">
                                    <h5
                                        style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc;margin-top: 15px;padding-bottom: 10px;padding-top: 10px;padding-left: 10px; width: 100%;">
                                        Request For Delegation</h5>
                                </div>
                                <div 
                                    class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Out Of Office From:</label>
                                    <div class="col-lg-3 dateSize">{{EmployeeLeaveDetails.StartDate ? (EmployeeLeaveDetails.StartDate | date) : 'Date not available'}}   
                                    </div>

                                    <div class="offset-1"></div>
                                    
                                    <label class="col-lg-2  col-form-label">Out Of Office To:</label>
                                    <div class="col-lg-3 dateSize">{{EmployeeLeaveDetails.EndDate ? (EmployeeLeaveDetails.EndDate | date) : 'Date not available'}}
                                    </div>
                                </div>
                                
                                <div *ngIf = "this.UserRole == 1"
                                    class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Delegate To:</label>
                                <div class="col-lg-3">
                                    <select class="form-control " id="sel5" name="alternateemployee"
                                        [(ngModel)]="EmployeeDetails.AlternateEmployee" #alternateemployee="ngModel"
                                        [ngClass]="{ 'is-invalid':myform.submitted  && alternateemployee.invalid || alternateemployee?.touched && alternateemployee.invalid}"
                                        (change)="OnChangeAlternateEmployee(EmployeeDetails.AlternateEmployee)" [disabled]="isEditable">
                                        <option value="" disabled selected>Select</option>
                                        <option *ngFor="let item of AlternateEmployeeData" value="{{item.Fk_User_Id}}">{{item.Name}}
                                        </option>             
                                        <option disabled *ngIf="AlternateEmployeeData.length <= 0" value="No Data Found">
                                            No Data Found</option>                             
                                    </select>

                                    <div class="invalid-feedback"
                                        *ngIf="myform.submitted && alternateemployee.invalid || alternateemployee?.touched && alternateemployee.invalid">
                                        <div *ngIf="myform.hasError('required', 'alternateemployee')">
                                            Alternate Employee is required</div>
                                    </div>
                                    <br />
                                        <span style="color:#f4516c;">(You can delegate to users with same role & region.)</span>
                                </div>
                                </div>


                            </div>

                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit" *ngIf="isEditable"
                                                [hidden]="!isEmployeeEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                                (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                            <button id="m_login_signin_submit" *ngIf="!isEditable"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/Employee']" routerLinkActive="active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>