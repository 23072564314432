
import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { QuotationDetailsModel, ProductDetailsModel, ProductDrpdnDetails, OptionsModel, QuotationCustomerDetails, TechnicalSpecifications } from 'src/app/Model/QuotationModel';
import { number } from '@amcharts/amcharts4/core';
import { MatDialog } from '@angular/material/dialog';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { NgbActiveModal, NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/Service/common.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Form, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.global';
import { EnquiryStatus } from "src/app/EnquiryStatus";
import { UserRoles } from "src/app/UserRoles";
import { forkJoin, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-priceconfigurator',
  templateUrl: './priceconfigurator.component.html',
  styleUrls: ['./priceconfigurator.component.scss']
})
export class PriceconfiguratorComponent implements OnInit {

  dropdownData: any;
  QuotationData: QuotationDetailsModel = new QuotationDetailsModel();
  productDrpdnDetails: ProductDrpdnDetails = new ProductDrpdnDetails();
  @Output() updateLoaderEvent = new EventEmitter<boolean>();
  IsQuotationDataValid: boolean = true;
  AppRoles: UserRoles;
  WeissLogoPath = "SITELOGO";


  @ViewChild('QuotationForm') QuotationForm: NgForm;
  @ViewChild('refDateElement') refDateElement: ElementRef;
  @ViewChild('yourRefElement') yourRefElement: ElementRef;
  @ViewChild('keyPerElement') keyPerElement: ElementRef;
  @ViewChild('techspec') techspec: ElementRef;
  @ViewChild('keyPerEmail') keyPerEmail: ElementRef;
  @ViewChild('keyPerCNo') keyPerCNo: ElementRef;
  @ViewChild('QuoteComm') QuoteComm: ElementRef;

  @Input()
  showLoader: boolean = false;
  @Input() enquiObj;
  productseriesDropdownData: any;
  productmdodelDropdownData: any;
  variantDropdownData: any;
  variant1Data: any[];
  variant2Data: any[];
  variant3Data: any[];
  variant4Data: any[];
  variantLabl1: any;
  variantLabl2: any;
  variantLabl3: any;
  variantLabl4: any;
  Options: any[] = [];
  optionsList: any[];
  selectedOptions: any[] = [];
  OptionsNameList: any[] = [];
  CustomerDetailsObj: QuotationCustomerDetails = new QuotationCustomerDetails();
  enquiryStatusPassed: any;
  OptionsPrice: number;
  IsQuotationPriceOnRequest: any;
  optionsSelected: any[] = [];
  btnforL1: boolean = false;
  btnforL2: boolean = false;
  btnforL3: boolean = false;
  btn_PriceOnRequest: boolean = false;
  isPriceOnRequestVisible: boolean;
  user: string;
  isQuotationEditable: boolean;
  isPricesRequestedBeforeSubmit: boolean;
  TermsandConditions: any;
  YourRefValidation: boolean;
  RefDateValidation: boolean;
  KindAttValidation: boolean;
  isPriceOnRequestValid: boolean;
  columnCount: any = 0;
  showTechnicalSpecs: TechnicalSpecifications[] = new Array<TechnicalSpecifications>();
  role: string;
  btnforCS: boolean;
  charCode: any;
  KindAttContactNoValidilation: boolean;
  KingAttConactNumberValidationMsg: string = "";
  KindAttEmailValidilation: boolean;
  KingAttEmailValidationMsg: string = "";
  minDate: NgbDate;
  maxDate: NgbDate;


  remLimit: any = 250;
  maxLimit: any = 250;
  varLongTermGoal: any;
  QuoteCommentsValidation: Boolean;
  specialPkgDiscount: number = 0;
  finalpricewithoutspecialdiscount: any = 0;
  constructor(
    private modalService: NgbModal
    , private commonService: CommonService
    , private activeModal: NgbActiveModal
    , public dialog: MatDialog
    , private toastr: ToastrService
    , private router: Router) {
  }

  ngOnInit(): void {
    this.showLoader = true;
    this.role = localStorage.getItem("UserRole");

    this.GetAllSeriesData();

  }

  GetAllSeriesData() {
    this.showLoader = true;
    this.commonService.GetData("Enquiry", "GetAllSeriesData", null).subscribe((data: any) => {
      if (data != null) {
        this.dropdownData = data.allseriesmodel;
        //this.AllData.push(this.AllseriesData);
        // this.QuotationData.QuotationInfo = data.QuotationInfo;

        if (this.QuotationData.QuotationObj.length == 0) {
          var obj = new ProductDetailsModel();
          this.QuotationData.QuotationObj.push(obj);
        }
        //all series data         
        this.productDrpdnDetails.ProductSeries = this.dropdownData.ProductSeries;
        this.CustomerDetailsObj = data.allseriesmodel.CustomerDetails;
        var inde = 0;
        this.QuotationData.QuotationObj.forEach(element => {
          this.calculateTotalPricesForProduct(inde, 0);
          inde++;
        });
      }

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data.");
      this.showLoader = false;
    });
  }

  showTechnicalSpecifications(i) {
    if (this.QuotationData.QuotationObj[i].ProductModel == undefined) {
      this.QuotationData.QuotationObj[i].ProductModelValidation = true;
      this.QuotationData.QuotationObj[i].ProductSeriesValidation = true;
      return;
    }
    this.QuotationData.QuotationObj[i].showTechnicalSpecifications = !this.QuotationData.QuotationObj[i].showTechnicalSpecifications;
    if (this.QuotationData.QuotationObj[i].showTechnicalSpecifications
      &&
      (this.QuotationData.QuotationObj[i].TechnicalSpecifications == undefined || this.QuotationData.QuotationObj[i].TechnicalSpecifications?.length == 0)) {


      var temp = [];
      this.QuotationData.QuotationObj[i].TechnicalSpecifications = [];
      var obj = new TechnicalSpecifications();
      obj.ProductId = this.QuotationData.QuotationObj[i].ProductId;
      obj.uniqueid = this.makeRandom();
      temp.push(obj);
      this.QuotationData.QuotationObj[i].TechnicalSpecifications = temp;
    }
  }

  ShowOptionPriceOnRequestGrid(i) 
  {
    if (this.QuotationData.QuotationObj[i].ShowOptionPriceOnRequestGrid) 
    {
      this.QuotationData.QuotationObj[i].ShowOptionPriceOnRequestGrid = false;
    }
    else {
      this.QuotationData.QuotationObj[i].ShowOptionPriceOnRequestGrid = true;
    }
  }

  makeRandom() {
    let text = "";
    var possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~";
    const lengthOfCode = 40;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  clearRow(index) {
    this.productDrpdnDetails.ProductModel = [];
    this.QuotationData.QuotationObj[index].ProductModel = undefined;
    this.QuotationData.QuotationObj[index].Variant1Label = null;
    this.QuotationData.QuotationObj[index].Variant2Label = null;
    this.QuotationData.QuotationObj[index].Variant3Label = null;
    this.QuotationData.QuotationObj[index].Variant4Label = null;
    this.QuotationData.QuotationObj[index].Variant1 = undefined;
    this.QuotationData.QuotationObj[index].Variant2 = undefined;
    this.QuotationData.QuotationObj[index].Variant3 = undefined;
    this.QuotationData.QuotationObj[index].Variant4 = undefined;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = false;
    this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = false;
    this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
    this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
    this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
    this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
    this.QuotationData.QuotationObj[index].Variant1Price = 0;
    this.QuotationData.QuotationObj[index].Variant2Price = 0;
    this.QuotationData.QuotationObj[index].Variant3Price = 0;
    this.QuotationData.QuotationObj[index].Variant4Price = 0;
    this.QuotationData.QuotationObj[index].OptionsPrice = 0;
    this.QuotationData.QuotationObj[index].Options = [];
    this.QuotationData.QuotationObj[index].OptionSelected = [];
    this.QuotationData.QuotationObj[index].selectedOptions = [];
    this.QuotationData.QuotationObj[index].selectedVariant1 = [];
    this.QuotationData.QuotationObj[index].selectedVariant2 = [];
    this.QuotationData.QuotationObj[index].selectedVariant3 = [];
    this.QuotationData.QuotationObj[index].selectedVariant4 = [];
    this.Options = [];

    this
    this.QuotationData.QuotationObj[index].Variant1Validation = false;
    this.QuotationData.QuotationObj[index].Variant2Validation = false;
    this.QuotationData.QuotationObj[index].Variant3Validation = false;
    this.QuotationData.QuotationObj[index].Variant4Validation = false;

    this.QuotationData.QuotationObj[index].Options_POR_Validation = false;
  }

  onProductSeriesChange(index) {
    this.showLoader = true;
    this.clearRow(index);
    this.QuotationData.QuotationObj[index].ProductSeriesValidation = false;
    var selectedProductSeries = this.QuotationData.QuotationObj[index].ProductSeries;
    this.QuotationData.QuotationObj[index].selectedProductModel = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.ProductModels;
    //currentProduct.selectedProductModel = this.productDrpdnDetails.ProductModel;

    this.QuotationData.QuotationObj[index].Variant1Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant1Label;
    this.QuotationData.QuotationObj[index].Variant2Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant2Label;
    this.QuotationData.QuotationObj[index].Variant3Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant3Label;
    this.QuotationData.QuotationObj[index].Variant4Label = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Variant4Label;
    this.setColspan(index);

    this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1 = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.IsLevel2DepOnLevel1;
    this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2 = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.IsLevel3DepOnLevel2;
    this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3 = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.IsLevel4DepOnLevel3;

    if (this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Name == 'HN' ||
      this.productDrpdnDetails.ProductSeries.filter(x => x.Id == selectedProductSeries)[0]?.Name == 'HG') {
      this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
      this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
      this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
      this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
    } else {
      this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
      this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
      this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
      this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
    }

    this.showLoader = false;

  }

  onProductModelChange(index) {
    this.showLoader = true;
    this.QuotationData.QuotationObj[index].ProductModelValidation = false;
    let queryString = "";
    queryString = "ProductModelId=" + this.QuotationData.QuotationObj[index].ProductModel;
    this.commonService.GetData("Enquiry", "GetQuotationVariantDropdownData", queryString).subscribe((data: any) => {

      this.variantDropdownData = data;
      // this.productDrpdnDetails.Variant1 = this.variantDropdownData.Variant1;
      this.QuotationData.QuotationObj[index].productmodeldata = undefined;
      this.QuotationData.QuotationObj[index].selectedVariant1 = this.variantDropdownData.Variant1;

      if (!this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1) {
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2) {
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3) {
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4;

      }
      this.QuotationData.QuotationObj[index].Variant1 = undefined;
      this.QuotationData.QuotationObj[index].Variant2 = undefined;
      this.QuotationData.QuotationObj[index].Variant3 = undefined;
      this.QuotationData.QuotationObj[index].Variant4 = undefined;
      this.QuotationData.QuotationObj[index].OptionSelected = [];
      this.QuotationData.QuotationObj[index].selectedOptions = [];
      this.QuotationData.QuotationObj[index].Options = [];
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = false;
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = false;
      if (this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name == 'HN' ||
        this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name == 'HG') {
        this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
        this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
        this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
        this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
      } else {
        this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
        this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
        this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
        this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
      }
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = false;
      this.QuotationData.QuotationObj[index].Variant1Price = 0;
      this.QuotationData.QuotationObj[index].Variant2Price = 0;
      this.QuotationData.QuotationObj[index].Variant3Price = 0;
      this.QuotationData.QuotationObj[index].Variant4Price = 0;
      this.QuotationData.QuotationObj[index].OptionsPrice = 0;
      this.QuotationData.QuotationObj[index].selectedOptions = this.variantDropdownData.Options;
      // this.Options = this.variantDropdownData.Options;
      // this.QuotationData.QuotationObj[index].Options = [];
      //this.QuotationData.QuotationObj[index].selectedOptions = [];
      this.QuotationData.QuotationObj[index].OptionSelected = [];
      this.QuotationData.QuotationObj[index].ProductModelPrice = this.variantDropdownData.Price;
      this.showLoader = false;

      this.calculateTotalPricesForProduct(index, 1);
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }

  onOptionSelect(item, index) {
    if (!item.isUserInput) {
      return;
    }
    var optionname;
    // this.QuotationData.QuotationObj[index].selectedOptions = [];
    // this.QuotationData.QuotationObj[index].selectedOptions = this.QuotationData.QuotationObj[index].productmodeldata.Options;
    if (this.Options.length == 0) {
      optionname = this.QuotationData.QuotationObj[index].selectedOptions.filter(x => x.Id == item.source.value)[0];
    } else {
      optionname = this.Options.filter(x => x.Id == item.source.value)[0];
    }
    if (item.source._selected) {
      var opObj = new OptionsModel();
      opObj.OptionId = optionname.Id;
      opObj.ProductOptionId = optionname.ProductOptionId;
      opObj.Price = optionname.Price;
      opObj.Name = optionname.Name;
      opObj.IsPriceOnRequest = optionname.IsPriceOnRequest;
      opObj.optionValid = false;
      this.QuotationData.QuotationObj[index].Options.push(opObj);
    } else {
      this.QuotationData.QuotationObj[index].Options = this.QuotationData.QuotationObj[index].Options.filter(x => x.OptionId != item.source.value);
    }
    let anyOptionPOR = this.QuotationData.QuotationObj[index].Options.filter(x => x.IsPriceOnRequest == true);
    if (anyOptionPOR.length > 0 && anyOptionPOR.length != null && anyOptionPOR.length != undefined) {
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = true;
    }
    else {
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Option = false;
    }
    // if (this.QuotationData.QuotationObj[index].Options.length == 0) {
    //   this.QuotationData.QuotationObj[index].OptionsValidation = true;
    // } else {
    //   this.QuotationData.QuotationObj[index].OptionsValidation = false;
    // }

    this.calculateTotalPricesForProduct(index, 1);
  }

  onVariant1Change(index) {
    this.showLoader = true;
    this.QuotationData.QuotationObj[index].Variant1Validation = false;
    this.productDrpdnDetails.IsLevel2DepOnLevel1 = this.dropdownData.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0].IsLevel2DepOnLevel1;

    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
      var var1Obj = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = var1Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant1) {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant1;
      } else {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = var1Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel2DepOnLevel1) {
        this.QuotationData.QuotationObj[index].Variant2 = undefined;
        this.QuotationData.QuotationObj[index].Variant2Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
        this.productDrpdnDetails.Variant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
      }
    } else {
      var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
      var var1Obj = this.QuotationData.QuotationObj[index].selectedVariant1.filter(x => x.Id == selectedVariant1)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant1 = var1Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant1) {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant1;
      } else {
        this.QuotationData.QuotationObj[index].Variant1Price = var1Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant1 = var1Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel2DepOnLevel1) {
        this.QuotationData.QuotationObj[index].Variant2 = undefined;
        this.QuotationData.QuotationObj[index].Variant2Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
        this.productDrpdnDetails.Variant2 = this.variantDropdownData.Variant2.filter(x => x.Fk_Id == selectedVariant1);
      }
    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader = false;
  }

  onVariant2Change(index) {
    this.showLoader = true;
    this.QuotationData.QuotationObj[index].Variant2Validation = false;
    this.productDrpdnDetails.IsLevel3DepOnLevel2 = this.dropdownData.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0].IsLevel3DepOnLevel2;

    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      this.QuotationData.QuotationObj[index].IsUnits_Variant2 = var2Obj?.IsUnits;
      var var2Obj = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0];
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant2) {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant2;
      } else {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = var2Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel3DepOnLevel2) {
        this.QuotationData.QuotationObj[index].Variant3 = undefined;
        this.QuotationData.QuotationObj[index].Variant3Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
        this.productDrpdnDetails.Variant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
      }
    } else {
      var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      var var2Obj = this.QuotationData.QuotationObj[index].selectedVariant2.filter(x => x.Id == selectedVariant2)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant2 = var2Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant2) {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant2;
      } else {
        this.QuotationData.QuotationObj[index].Variant2Price = var2Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant2 = var2Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel3DepOnLevel2) {
        this.QuotationData.QuotationObj[index].Variant3 = undefined;
        this.QuotationData.QuotationObj[index].Variant3Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
        this.productDrpdnDetails.Variant3 = this.variantDropdownData.Variant3.filter(x => x.Fk_Id == selectedVariant2);
      }
    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader = false;
  }

  onVariant3Change(index) {
    this.showLoader = true;
    this.QuotationData.QuotationObj[index].Variant3Validation = false;
    this.productDrpdnDetails.IsLevel4DepOnLevel3 = this.dropdownData.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0].IsLevel4DepOnLevel3;

    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      var var3Obj = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant3 = var3Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant3) {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant3;
      } else {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = var3Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel4DepOnLevel3) {
        this.QuotationData.QuotationObj[index].Variant4 = undefined;
        this.QuotationData.QuotationObj[index].Variant4Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
        this.productDrpdnDetails.Variant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
      }
    } else {
      var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      var var3Obj = this.QuotationData.QuotationObj[index].selectedVariant3.filter(x => x.Id == selectedVariant3)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant3 = var3Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant3) {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant3;
      } else {
        this.QuotationData.QuotationObj[index].Variant3Price = var3Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant3 = var3Obj?.IsPriceOnRequest;
      if (this.productDrpdnDetails.IsLevel4DepOnLevel3) {
        this.QuotationData.QuotationObj[index].Variant4 = undefined;
        this.QuotationData.QuotationObj[index].Variant4Price = 0;
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
        this.productDrpdnDetails.Variant4 = this.variantDropdownData.Variant4.filter(x => x.Fk_Id == selectedVariant3);
      }
    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader = false;
  }

  onVariant4Change(index) {
    this.showLoader = true;
    this.QuotationData.QuotationObj[index].Variant4Validation = false;
    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
      var var4Obj = this.QuotationData.QuotationObj[index].selectedVariant4.filter(x => x.Id == selectedVariant4)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant4 = var4Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant4) {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant4;
      } else {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = var4Obj?.IsPriceOnRequest;
    } else {
      var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
      // this.QuotationData.QuotationObj[index].Variant4Price = this.QuotationData.QuotationObj[index].selectedVariant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
      // this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = this.QuotationData.QuotationObj[index].selectedVariant4.filter(x => x.Id == selectedVariant4)[0]?.IsPriceOnRequest;
      // this.QuotationData.QuotationObj[index].IsUnits_Variant4 = this.QuotationData.QuotationObj[index].selectedVariant4.filter(x => x.Id == selectedVariant4)[0]?.IsUnits;
      /***************************************************************************************************** */
      var var4Obj = this.QuotationData.QuotationObj[index].selectedVariant4.filter(x => x.Id == selectedVariant4)[0];
      this.QuotationData.QuotationObj[index].IsUnits_Variant4 = var4Obj?.IsUnits;
      if (this.QuotationData.QuotationObj[index].IsUnits_Variant4) {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price * this.QuotationData.QuotationObj[index].Units_Variant4;
      } else {
        this.QuotationData.QuotationObj[index].Variant4Price = var4Obj?.Price;
      }
      this.QuotationData.QuotationObj[index].IsPriceOnRequest_Variant4 = var4Obj?.IsPriceOnRequest;

      /*************************************************************************************************** */

    }
    this.calculateTotalPricesForProduct(index, 1);
    this.showLoader = false;
  }

      // var1UnitsChange(index) {
      //   this.GetVarientDropdowndataforModel(index);
      //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
      //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant1.toString();
      //   if (this.QuotationData.QuotationObj[index].Units_Variant1 == undefined || this.QuotationData.QuotationObj[index].Units_Variant1 == null) {
      //     this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant1 < 0) {
      //     this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant1 % 1 != 0) {
      //     alert('Please enter only integer value');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false;
      //   }else if(strunits.includes("."))
      //   {
      //     alert('Please enter valid units');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false; 
      //   } 
        
      //   else {
    
      //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant1 % 100 != 0)) {
      //       alert('Please enter the units in multiple of 100.');
      //       this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
      //       this.IsQuotationDataValid = false;
      //     } else {
      //       this.QuotationData.QuotationObj[index].var1_Unit_Validation = false;
      //     }
      //   }
      //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      //     var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
      //     var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
      //     this.QuotationData.QuotationObj[index].Variant1Price = price * units;
      //   } else {
      //     var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
      //     var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
      //     this.QuotationData.QuotationObj[index].Variant1Price = price * units;
      //     //this.QuotationData.QuotationObj[index].Variant1Price = this.QuotationData.QuotationObj[index].selectedVariant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
      //   }
      //   this.calculateTotalPricesForProduct(index, 1);
      // }
      
      // var2UnitsChange(index) {
      //   this.GetVarientDropdowndataforModel(index);
      //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
      //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant2.toString();
      //   if (this.QuotationData.QuotationObj[index].Units_Variant2 == undefined || this.QuotationData.QuotationObj[index].Units_Variant2 == null) {
      //     this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant2 < 0) {
      //     this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant2 % 1 != 0) {
      //     alert('Please enter only integer value');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false;
      //   }
      //   else if(strunits.includes("."))
      //   {
      //     alert('Please enter valid units');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false;
      //   }
      //   else {
    
      //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant2 % 100 != 0)) {
      //       alert('Please enter the units in multiple of 100.');
      //       this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
      //       this.IsQuotationDataValid = false;
      //     } else {
      //       this.QuotationData.QuotationObj[index].var2_Unit_Validation = false;
      //     }
      //   }
      //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      //     var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      //     var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
      //     this.QuotationData.QuotationObj[index].Variant2Price = price * units;
      //   } else {
      //     var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      //     var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
      //     this.QuotationData.QuotationObj[index].Variant2Price = price * units;
      //     // this.QuotationData.QuotationObj[index].Variant2Price = this.productDrpdnDetails.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
      //   }
      //   this.calculateTotalPricesForProduct(index, 1);
      // }

      // var3UnitsChange(index) {
      //   this.GetVarientDropdowndataforModel(index);
      //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
      //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant3.toString();
      //   if (this.QuotationData.QuotationObj[index].Units_Variant3 == undefined || this.QuotationData.QuotationObj[index].Units_Variant3 == null) {
      //     this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant3 < 0) {
      //     this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant3 % 1 != 0) {
      //     alert('Please enter only integer value');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false;
      //   }
      //   else if(strunits.includes("."))
      //   {
      //     alert('Please enter valid units');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false;
      //   }
      //   else {
    
      //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant3 % 100 != 0)) {
      //       alert('Please enter the units in multiple of 100.');
      //       this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
      //       this.IsQuotationDataValid = false;
      //     } else {
      //       this.QuotationData.QuotationObj[index].var3_Unit_Validation = false;
      //     }
      //   }
      //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      //     var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      //     var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
      //     this.QuotationData.QuotationObj[index].Variant3Price = price * units;
      //   } else {
      //     var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      //     var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
      //     this.QuotationData.QuotationObj[index].Variant3Price = price * units;
      //     //this.QuotationData.QuotationObj[index].Variant3Price = this.productDrpdnDetails.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
      //   }
      //   this.calculateTotalPricesForProduct(index, 1);
      // }

      // var4UnitsChange(index) {
      //   this.GetVarientDropdowndataforModel(index);
      //   var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
      //   let strunits = this.QuotationData.QuotationObj[index].Units_Variant4.toString();
      //   if (this.QuotationData.QuotationObj[index].Units_Variant4 == undefined || this.QuotationData.QuotationObj[index].Units_Variant4 == null) {
      //     this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant4 < 0) {
      //     this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
      //     this.IsQuotationDataValid = false;
      //   } else if (this.QuotationData.QuotationObj[index].Units_Variant4 % 1 != 0) {
      //     alert('Please enter only integer value');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false;
      //   }
      //   else if(strunits.includes("."))
      //   {
      //     alert('Please enter valid units');
      //     if (seriesname == 'HG' || seriesname == 'HN') {
      //       this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
      //     }
      //     else {
      //       this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
      //     }
    
      //     this.IsQuotationDataValid = false;
      //   }
      //   else {
    
      //     if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant4 % 100 != 0)) {
      //       alert('Please enter the units in multiple of 100.');
      //       this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
      //       this.IsQuotationDataValid = false;
      //     } else {
      //       this.QuotationData.QuotationObj[index].var4_Unit_Validation = false;
      //     }
      //   }
      //   if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      //     this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      //     var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
      //     var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
      //     this.QuotationData.QuotationObj[index].Variant4Price = price * units;
      //   } else {
      //     var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
      //     var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
      //     var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
      //     this.QuotationData.QuotationObj[index].Variant4Price = price * units;
      //     // this.QuotationData.QuotationObj[index].Variant4Price = this.productDrpdnDetails.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
      //   }
      //   this.calculateTotalPricesForProduct(index, 1);
      // }
    
      var1PORChange(index) {
        var element = this.QuotationData.QuotationObj[index];
        let strprice = element.Variant1Price.toString();
        if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant1 && (element.Variant1Price == undefined || element.Variant1Price == null || (element.Variant1Price == 0 && element.Units_Variant1 != 0) || (element.Variant1Price != 0 && element.Units_Variant1 == 0))) {
          element.var1_POR_Validation = true;
          this.IsQuotationDataValid = false;
        } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant1 && (element.Variant1Price < 0)) {
          element.var1_POR_Validation = true;
          this.IsQuotationDataValid = false;
        } else if (element.Variant1Price % 1 != 0) {
          alert('Please enter only integer value');
          element.Variant1Price = 0;
          this.IsQuotationDataValid = false;
        } else if(strprice.includes("."))
        {
          alert('Please enter valid price');
          element.Variant1Price = 0;
          this.IsQuotationDataValid = false;
        }
        else {
          element.var1_POR_Validation = false;
        }
        this.calculateTotalPricesForProduct(index, 1);
      }

  var2PORChange(index) {
    var element = this.QuotationData.QuotationObj[index];
    let strprice = element.Variant2Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant2 && (element.Variant2Price == undefined || element.Variant2Price == null || (element.Variant2Price == 0 && element.Units_Variant2 != 0) || (element.Variant2Price != 0 && element.Units_Variant2 == 0))) {
      element.var2_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant2 && (element.Variant2Price < 0)) {
      element.var2_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (element.Variant2Price % 1 != 0) {
      alert('Please enter only integer value');
      element.Variant2Price = 0;
      this.IsQuotationDataValid = false;
    } else if (strprice.includes(".")) {
      alert('Please enter valid price');
      element.Variant2Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.var2_POR_Validation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }

  var3PORChange(index) {
    var element = this.QuotationData.QuotationObj[index];
    let strprice = element.Variant3Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant3 && (element.Variant3Price == undefined || element.Variant3Price == null || (element.Variant3Price == 0 && element.Units_Variant3 != 0) || (element.Variant3Price != 0 && element.Units_Variant3 == 0))) {
      element.var3_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant3 && (element.Variant3Price < 0)) {
      element.var3_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (element.Variant3Price % 1 != 0) {
      alert('Please enter only integer value');
      element.Variant3Price = 0;
      this.IsQuotationDataValid = false;
    } else if (strprice.includes(".")) {
      alert('Please enter valid price');
      element.Variant3Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.var3_POR_Validation = false;
      this.IsQuotationDataValid = true;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }

  var4PORChange(index) {
    var element = this.QuotationData.QuotationObj[index];
    let strprice = element.Variant4Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant4 && (element.Variant4Price == undefined || element.Variant4Price == null || (element.Variant4Price == 0 && element.Units_Variant4 != 0) || (element.Variant4Price != 0 && element.Units_Variant4 == 0))) {
      element.var4_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant4 && (element.Variant4Price < 0)) {
      element.var4_POR_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (element.Variant4Price % 1 != 0) {
      alert('Please enter only integer value');
      element.Variant4Price = 0;
      this.IsQuotationDataValid = false;
    }
    else if (strprice.includes(".")) {
      alert('Please enter valid price');
      element.Variant4Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.var4_POR_Validation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }

  varOptionPORChange(i, j) {
    var element = this.QuotationData.QuotationObj[i];
    let strprice = element.Options[j].Price.toString();
    if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Option && (element.Options[j].Price == undefined || element.Options[j].Price == null || element.Options[j].Price == 0)) {
      element.Options[j].optionValid = true;
      this.IsQuotationDataValid = false;
    } else if (this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Option && element.Options[j].Price < 0) {
      element.Options[j].optionValid = true;
      this.IsQuotationDataValid = false;
    } else if (element.Options[j].Price % 1 != 0) {
      alert('Please enter only integer value');
      element.Options[j].Price = 0;
      this.IsQuotationDataValid = false;
    }
    else if (strprice.includes(".")) {
      alert('Please enter valid price');
      element.Options[j].Price = 0;
      this.IsQuotationDataValid = false;
    }
    else {
      element.Options[j].optionValid = false;
      this.IsQuotationDataValid = true;
    }
    this.calculateTotalPricesForProduct(i, 1);
  }

  onQuatityChange(index) {
    let strquantity = this.QuotationData.QuotationObj[index].Quantity.toString();
    if ((this.QuotationData.QuotationObj[index].Quantity == 0 || this.QuotationData.QuotationObj[index].Quantity == null || this.QuotationData.QuotationObj[index].Quantity == undefined)) {
      this.QuotationData.QuotationObj[index].Quantity = 1;
    } else if (this.QuotationData.QuotationObj[index].Quantity % 1 != 0) {
      this.QuotationData.QuotationObj[index].QuantityValidation = true;
      return;
    } else {
      this.QuotationData.QuotationObj[index].QuantityValidation = false;
    }
    if (this.QuotationData.QuotationObj[index].Quantity > 1000) {
      this.QuotationData.QuotationObj[index].QuantityRangeValidation = true;
      return;
    } else if (this.QuotationData.QuotationObj[index].Quantity < 1) {
      this.QuotationData.QuotationObj[index].QuantityValidation = true;
      return;
    } else if (strquantity.includes(".")) {
      alert('Please enter valid Quantity.');
      this.QuotationData.QuotationObj[index].Quantity = null;
      return;
    }
    else {
      this.QuotationData.QuotationObj[index].QuantityRangeValidation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }

  onDiscountChange(index) {
    let strdis = this.QuotationData.QuotationObj[index].Discount.toString();
    if ((this.QuotationData.QuotationObj[index].Discount < 0)) {
      this.QuotationData.QuotationObj[index].DiscountValidation = true;
      alert("Discount cannot be negative.");
      this.QuotationData.QuotationObj[index].Discount = 0;

      return;
    } else if ((this.QuotationData.QuotationObj[index].Discount > 100)) {
      this.QuotationData.QuotationObj[index].DiscountValidation = true;
      return;
    } else if (this.QuotationData.QuotationObj[index].Discount % 1 != 0) {
      if (this.QuotationData.QuotationObj[index].Discount.toString().split(".")[1].length > 2 || this.QuotationData.QuotationObj[index].Discount.toString().split(".")[0].length > 2) {
        alert("Only two numbers are allowed after decimal point");
        this.QuotationData.QuotationObj[index].Discount = 0;
        //this.QuotationData.QuotationObj[index].Discount = Math.round((this.QuotationData.QuotationObj[index].Discount + Number.EPSILON) * 100) / 100;

      }
      this.QuotationData.QuotationObj[index].DiscountValidation = false;
    } else if ((strdis.charAt(strdis.length - 1) == ".")) {
      alert("Please enter valid discount");
      this.QuotationData.QuotationObj[index].Discount = 0;
      return;
    }
    else {
      this.QuotationData.QuotationObj[index].DiscountValidation = false;
    }
    this.calculateTotalPricesForProduct(index, 1);
  }

  setspecialDiscountPrice() {
    //let specdisc=this.specialPkgDiscount.toString();
    if ((this.specialPkgDiscount < 0)) {
      // alert("Discount cannot be negative.");
      this.specialPkgDiscount = 0;
      return;
    }

    else if ((this.specialPkgDiscount > 100)) {
      //alert("Discount cannot be Greater than 100");
      this.specialPkgDiscount = 0;
      return;
    }
    // else if (this.specialPkgDiscount % 1 != 0) {
    //   //alert("Discount must be a integer");
    //   this.specialPkgDiscount = 0;
    //   return;
    // }

    else if (this.specialPkgDiscount != 0) {

      var percent = 100 - this.specialPkgDiscount;

      var calculate = this.QuotationData.QuotationInfo.TotalAmount * (percent / 100);
      this.QuotationData.QuotationInfo.FinalAmount = Math.round(calculate);
      this.specialPkgDiscount = this.specialPkgDiscount;
      // this.isfinalamount=false;
    }
    else if (this.specialPkgDiscount == 0) {
      this.QuotationData.QuotationInfo.FinalAmount = Math.round(this.QuotationData.QuotationInfo.TotalAmount);
    }


  }

  checkQuotationGridValidation(flag) {
    var inde = 0;

    this.QuotationData.QuotationObj.forEach(element => {
      if (element.ProductSeries == undefined || element.ProductSeries == null || element.ProductSeries == 0) {
        element.ProductSeriesValidation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.ProductModel == undefined || element.ProductModel == null || element.ProductModel == 0) {
        element.ProductModelValidation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant1Label && (element.Variant1 == undefined || element.Variant1 == null || element.Variant1 == 0)) {
        element.Variant1Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant2Label && (element.Variant2 == undefined || element.Variant2 == null || element.Variant2 == 0)) {
        element.Variant2Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant3Label && (element.Variant3 == undefined || element.Variant3 == null || element.Variant3 == 0)) {
        element.Variant3Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (!element.ProductModelValidation && element.Variant4Label && (element.Variant4 == undefined || element.Variant4 == null || element.Variant4 == 0)) {
        element.Variant4Validation = true;
        this.IsQuotationDataValid = false;
      }
      // if (element.Options == undefined || element.Options == null || element.Options.length == 0) {
      //   element.OptionsValidation = true;
      //   this.IsQuotationDataValid = false;
      // }
      if (element.Quantity == undefined || element.Quantity == null || element.Quantity == 0) {
        element.QuantityValidation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.Discount < 0) {
        element.DiscountValidation = true;
        this.IsQuotationDataValid = false;
      }

      if (element.Discount > 100) {
        element.DiscountValidation = true;
        this.IsQuotationDataValid = false;
      }

      //<PRICE ON REQUEST VALIDATION
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant1 && (element.Variant1Price == undefined || element.Variant1Price == null || (element.Variant1Price == 0 && element.Units_Variant1 != 0) || (element.Variant1Price != 0 && element.Units_Variant1 == 0))) {
        element.var1_POR_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant2 && (element.Variant2Price == undefined || element.Variant2Price == null || (element.Variant2Price == 0 && element.Units_Variant2 != 0) || (element.Variant2Price != 0 && element.Units_Variant2 == 0))) {
        element.var2_POR_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant3 && (element.Variant3Price == undefined || element.Variant3Price == null || (element.Variant3Price == 0 && element.Units_Variant3 != 0) || (element.Variant3Price != 0 && element.Units_Variant3 == 0))) {
        element.var3_POR_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Variant4 && (element.Variant4Price == undefined || element.Variant4Price == null || (element.Variant4Price == 0 && element.Units_Variant4 != 0) || (element.Variant4Price != 0 && element.Units_Variant4 == 0))) {
        element.var4_POR_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Option) {

        element.Options.forEach(x => {

          if (this.IsQuotationPriceOnRequest && flag == 1 && element.IsPriceOnRequest_Option && x.IsPriceOnRequest && (x.Price == undefined || x.Price == null || x.Price == 0)) {
            element.ShowOptionPriceOnRequestGrid = true;
            x.optionValid = true;
            this.IsQuotationDataValid = false;
          }

        });

      }
      //>
      //<UNITS VALIDATION
      if (element.IsUnits_Variant1 && (element.Units_Variant1 == undefined || element.Units_Variant1 == null)) {
        element.var1_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.IsUnits_Variant2 && (element.Units_Variant2 == undefined || element.Units_Variant2 == null)) {
        element.var2_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.IsUnits_Variant3 && (element.Units_Variant3 == undefined || element.Units_Variant3 == null)) {
        element.var3_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      if (element.IsUnits_Variant4 && (element.Units_Variant4 == undefined || element.Units_Variant4 == null)) {
        element.var4_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      }
      //>
      var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == element.ProductSeries)[0]?.Name;
      if ((seriesname == 'HG' || seriesname == 'HN')) {
        if (element.IsUnits_Variant1 && element.Units_Variant1 % 100 != 0) {
          element.var1_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
        if (element.IsUnits_Variant2 && element.Units_Variant2 % 100 != 0) {
          element.var2_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
        if (element.IsUnits_Variant3 && element.Units_Variant3 % 100 != 0) {
          element.var3_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
        if (element.IsUnits_Variant4 && element.Units_Variant4 % 100 != 0) {
          element.var4_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        }
      }
      inde++;
    });
  }

  checkPriceOnRequestCostsAndUnits() {
    var inde = 0;
    this.isPricesRequestedBeforeSubmit = true;
    this.QuotationData.QuotationObj.forEach(element => {
      //<CHECK IF THE PRICES ARE PRESENT BEFORE SUBMITTING THE QUOTATION
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant1 && element.Units_Variant1 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant2 && element.Units_Variant2 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant3 && element.Units_Variant3 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Variant4 && element.Units_Variant4 != 0) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      if (!this.IsQuotationPriceOnRequest && element.IsPriceOnRequest_Option) {
        this.isPricesRequestedBeforeSubmit = false;
        this.IsQuotationDataValid = false;
      }
      //>
      inde++;
    });
  }

  checkQuotationHeaderValidation() {
    this.IsQuotationDataValid = true;
    if (this.QuotationData.QuotationInfo.YourReference == "" || this.QuotationData.QuotationInfo.YourReference == undefined || this.QuotationData.QuotationInfo.YourReference == null) {
      this.YourRefValidation = true;
      this.IsQuotationDataValid = false;
      setTimeout(() => {
        this.yourRefElement.nativeElement.focus();
      }, 0);
    } else {
      this.YourRefValidation = false;
    }
    if (this.QuotationData.QuotationInfo.ReferenceDateISO == "" || this.QuotationData.QuotationInfo.ReferenceDateISO == undefined || this.QuotationData.QuotationInfo.ReferenceDateISO == null) {
      this.IsQuotationDataValid = false;
      this.RefDateValidation = true;
      setTimeout(() => {
        this.refDateElement.nativeElement.focus();
      }, 0);
    } else {
      this.RefDateValidation = false;
    }
    if (this.QuotationData.QuotationInfo.KindAttention == "" || this.QuotationData.QuotationInfo.KindAttention == undefined || this.QuotationData.QuotationInfo.KindAttention == null) {
      this.IsQuotationDataValid = false;
      this.KindAttValidation = true;
      setTimeout(() => {
        this.keyPerElement.nativeElement.focus();
      }, 0);
    } else {
      this.KindAttValidation = false;
    }
    if (this.QuotationData.QuotationInfo.KindAttEmailId == "" || this.QuotationData.QuotationInfo.KindAttEmailId == undefined || this.QuotationData.QuotationInfo.KindAttEmailId == null) {
      this.IsQuotationDataValid = false;
      this.KindAttEmailValidilation = true;
      this.KingAttEmailValidationMsg = "Email is required"
      setTimeout(() => {
        this.keyPerEmail.nativeElement.focus();
      }, 0);
    }
    else {
      this.KindAttEmailValidilation = false;
    }
    if (this.QuotationData.QuotationInfo.KindAttContactNo == "" || this.QuotationData.QuotationInfo.KindAttContactNo == undefined || this.QuotationData.QuotationInfo.KindAttContactNo == null) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Contact Number is required"
      setTimeout(() => {
        this.keyPerCNo.nativeElement.focus();
      }, 0);
    }
    else if (this.QuotationData.QuotationInfo.KindAttContactNo.match(/^[0-9]+$/) == null) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Please enter valid contact number";
      setTimeout(() => {
        this.keyPerCNo.nativeElement.focus();
      }, 0);
    }
    else if (this.QuotationData.QuotationInfo.KindAttContactNo.length < 10) {
      this.IsQuotationDataValid = false;
      this.KindAttContactNoValidilation = true;
      this.KingAttConactNumberValidationMsg = "Contact number should be 10-15 digits";
      setTimeout(() => {
        this.keyPerCNo.nativeElement.focus();
      }, 0);
    }
    else {
      this.KindAttContactNoValidilation = false;
    }
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.QuotationData.QuotationInfo.KindAttEmailId))) {
      this.IsQuotationDataValid = false;
      this.KindAttEmailValidilation = true;
      this.KingAttEmailValidationMsg = "Should be in Email Id format (e.g. user@weiss.com)"
      setTimeout(() => {
        this.keyPerEmail.nativeElement.focus();
      }, 0);
    }
    else {
      this.KindAttEmailValidilation = false;
    }

    if (this.QuotationData.QuotationInfo.QuotationComments == "" || this.QuotationData.QuotationInfo.QuotationComments == undefined || this.QuotationData.QuotationInfo.QuotationComments == null) {
      this.IsQuotationDataValid = false;
      this.QuoteCommentsValidation = true;
      setTimeout(() => {
        this.QuoteComm.nativeElement.focus();
      }, 0);
    }
    else {
      this.QuoteCommentsValidation = false;
    }
  }

  closePopup() {
    this.activeModal.close('Close Click');
  }

  AddNewProduct() {
    var obj = new ProductDetailsModel();
    this.QuotationData.QuotationObj.push(obj);
  }

  DeleteProduct(index: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to delete this row?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (index != null) {
          this.QuotationData.QuotationObj.splice(index, 1);
          this.specialPkgDiscount = 0;
        } else {
          this.QuotationData.QuotationObj.pop();
        }
        this.calculateTotalQuotationValues();
      }
    });
  }

  setColspan(index) {
    this.columnCount = 0;
    if (this.QuotationData.QuotationObj[index].Variant1Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.QuotationData.QuotationObj[index].Variant2Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.QuotationData.QuotationObj[index].Variant3Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.QuotationData.QuotationObj[index].Variant4Label) {
      this.columnCount = this.columnCount + 1;
    }
    if (this.columnCount == 4) {
      this.QuotationData.QuotationObj[index].colspan1 = 1;
      this.QuotationData.QuotationObj[index].colspan2 = 1;
      this.QuotationData.QuotationObj[index].colspan3 = 1;
      this.QuotationData.QuotationObj[index].colspan4 = 1;
    } else if (this.columnCount == 3) {
      this.QuotationData.QuotationObj[index].colspan1 = 1;
      this.QuotationData.QuotationObj[index].colspan2 = 1;
      this.QuotationData.QuotationObj[index].colspan3 = 2;
      this.QuotationData.QuotationObj[index].colspan4 = 0;
    } else if (this.columnCount == 2) {
      this.QuotationData.QuotationObj[index].colspan1 = 2;
      this.QuotationData.QuotationObj[index].colspan2 = 2;
      this.QuotationData.QuotationObj[index].colspan3 = 0;
      this.QuotationData.QuotationObj[index].colspan4 = 0;
    } else if (this.columnCount == 1) {
      this.QuotationData.QuotationObj[index].colspan1 = 4;
      this.QuotationData.QuotationObj[index].colspan2 = 0;
      this.QuotationData.QuotationObj[index].colspan3 = 0;
      this.QuotationData.QuotationObj[index].colspan4 = 0;
    }
  }

 
 calculateTotalPricesForProduct(index, flag) {
    var modelprice = 0;
    var var1price = 0;
    var var2price = 0;
    var var3price = 0;
    var var4price = 0;
    var optionprice = 0;
    var QuotationObj = this.QuotationData.QuotationObj[index];

    modelprice = QuotationObj?.ProductModelPrice == undefined ? 0 : QuotationObj.ProductModelPrice;
    var1price = QuotationObj?.Variant1Price == undefined ? 0 : QuotationObj.Variant1Price;
    var2price = QuotationObj?.Variant2Price == undefined ? 0 : QuotationObj.Variant2Price;
    var3price = QuotationObj?.Variant3Price == undefined ? 0 : QuotationObj.Variant3Price;
    var4price = QuotationObj?.Variant4Price == undefined ? 0 : QuotationObj.Variant4Price;

    this.setColspan(index);

    if (flag == 0) {
      if (QuotationObj?.Units_Variant1 >= 0 && QuotationObj?.IsUnits_Variant1) {
        QuotationObj.Variant1Price = QuotationObj.Variant1Price * QuotationObj.Units_Variant1;
        var1price = QuotationObj.Variant1Price;
      }
      if (QuotationObj?.Units_Variant2 >= 0 && QuotationObj?.IsUnits_Variant2) {
        QuotationObj.Variant2Price = QuotationObj.Variant2Price * QuotationObj.Units_Variant2;
        var2price = QuotationObj.Variant2Price;
      }
      if (QuotationObj?.Units_Variant3 >= 0 && QuotationObj?.IsUnits_Variant3) {
        QuotationObj.Variant3Price = QuotationObj.Variant3Price * QuotationObj.Units_Variant3;
        var3price = QuotationObj.Variant3Price;
      }
      if (QuotationObj?.Units_Variant4 >= 0 && QuotationObj?.IsUnits_Variant4) {
        QuotationObj.Variant4Price = QuotationObj.Variant4Price * QuotationObj.Units_Variant4;
        var4price = QuotationObj.Variant4Price;
      }
    }

    QuotationObj.PerUnitAmount = QuotationObj?.PerUnitAmount == undefined ? 0 : QuotationObj.PerUnitAmount;
    QuotationObj.TotalAmount = QuotationObj?.TotalAmount == undefined ? 0 : QuotationObj.TotalAmount;
    QuotationObj.FinalAmount = QuotationObj?.FinalAmount == undefined ? 0 : QuotationObj.FinalAmount;
    QuotationObj.OptionsPrice = 0;
    QuotationObj.Options?.forEach(element => {
      QuotationObj.OptionsPrice = QuotationObj?.OptionsPrice + element.Price;
      optionprice = optionprice + element.Price;
    });

    QuotationObj.PerUnitAmount = modelprice + var1price + var2price + var3price + var4price + optionprice;
    QuotationObj.Variant1 = (QuotationObj.Variant1 == null || QuotationObj.Variant1 == 0) ? undefined : QuotationObj.Variant1;
    QuotationObj.Variant2 = (QuotationObj.Variant2 == null || QuotationObj.Variant2 == 0) ? undefined : QuotationObj.Variant2;
    QuotationObj.Variant3 = (QuotationObj.Variant3 == null || QuotationObj.Variant3 == 0) ? undefined : QuotationObj.Variant3;
    QuotationObj.Variant4 = (QuotationObj.Variant4 == null || QuotationObj.Variant4 == 0) ? undefined : QuotationObj.Variant4;
    QuotationObj.Discount = QuotationObj.Discount == undefined ? 0 : QuotationObj.Discount;

    //var percent = 100 - QuotationObj.Discount;
    QuotationObj.TotalAmount = QuotationObj.PerUnitAmount * QuotationObj.Quantity;
    // var final = QuotationObj.TotalAmount * (percent / 100);
    //QuotationObj.FinalAmount = parseInt(final.toString());
    // QuotationObj.FinalAmount =Math.round((final + Number.EPSILON) * 100) / 100);
    QuotationObj.FinalAmount = Math.round(QuotationObj.TotalAmount);
    this.calculateTotalQuotationValues();
  }


  calculateTotalQuotationValues() {
    this.QuotationData.QuotationInfo.PerUnitAmount = 0;
    this.QuotationData.QuotationInfo.TotalAmount = 0;
    this.QuotationData.QuotationInfo.FinalAmount = 0;
    var final = 0;
    this.QuotationData.QuotationObj.forEach(element => {
      this.QuotationData.QuotationInfo.PerUnitAmount = this.QuotationData.QuotationInfo.PerUnitAmount + element.PerUnitAmount;
      this.QuotationData.QuotationInfo.TotalAmount = this.QuotationData.QuotationInfo.TotalAmount + element.TotalAmount;
      final = parseFloat(final.toString()) + parseFloat(element.FinalAmount);
    });

    var percent = 100 - this.specialPkgDiscount;
    var finals = final * (percent / 100);
    this.QuotationData.QuotationInfo.FinalAmount = Math.round(finals);
  }

  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }

    if (this.QuotationData.QuotationInfo.QuotationComments == "" || this.QuotationData.QuotationInfo.QuotationComments == undefined || this.QuotationData.QuotationInfo.QuotationComments == null) {
      this.IsQuotationDataValid = false;
      this.QuoteCommentsValidation = true;
    } else {
      this.QuoteCommentsValidation = false;
    }
  }
  allowDecimal(event: any) {

    // Allow decimal point and only two decimal places
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!regex.test(event.target.value + inputChar)) {
      event.preventDefault();
    }
    if ((this.specialPkgDiscount > 100)) {
      //alert("Discount cannot be Greater than 100");
      this.specialPkgDiscount = 0;
      return;
    }
  }

  
  GetVarientDropdowndataforModel(index) {
    
    let queryString = "";
    queryString = "ProductModelId=" + this.QuotationData.QuotationObj[index].ProductModel;
    // this.commonService.GetData("Enquiry", "GetQuotationVariantDropdownData", queryString).subscribe((data: any) => {

    //   this.variantDropdownData = data;
    //   // this.productDrpdnDetails.Variant1 = this.variantDropdownData.Variant1;
    //   this.QuotationData.QuotationObj[index].productmodeldata = undefined;
    //   this.QuotationData.QuotationObj[index].selectedVariant1 = this.variantDropdownData.Variant1;

    //   if (!this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1) {
    //     this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2;
    //   }
    //   if (!this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2) {
    //     this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3;
    //   }
    //   if (!this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3) {
    //     this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4;

    //   }
     
    // });

    let tracker =  this.commonService.GetData("Enquiry", "GetQuotationVariantDropdownData", queryString);

    tracker.subscribe((data: any) => {
      this.variantDropdownData = data;
      this.QuotationData.QuotationObj[index].productmodeldata = undefined;
      this.QuotationData.QuotationObj[index].selectedVariant1 = this.variantDropdownData.Variant1;

      if (!this.QuotationData.QuotationObj[index].IsLevel2DepOnLevel1) {
        this.QuotationData.QuotationObj[index].selectedVariant2 = this.variantDropdownData.Variant2;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel3DepOnLevel2) {
        this.QuotationData.QuotationObj[index].selectedVariant3 = this.variantDropdownData.Variant3;
      }
      if (!this.QuotationData.QuotationObj[index].IsLevel4DepOnLevel3) {
        this.QuotationData.QuotationObj[index].selectedVariant4 = this.variantDropdownData.Variant4;
       }
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });

    return tracker;
  }

    var1UnitsChange(index) {
      forkJoin([
        this.GetVarientDropdowndataforModel(index)
      ]).pipe(tap(res => {           
        var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
        let strunits = this.QuotationData.QuotationObj[index].Units_Variant1.toString();
        if (this.QuotationData.QuotationObj[index].Units_Variant1 == undefined || this.QuotationData.QuotationObj[index].Units_Variant1 == null) {
          this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        } else if (this.QuotationData.QuotationObj[index].Units_Variant1 < 0) {
          this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
          this.IsQuotationDataValid = false;
        } else if (this.QuotationData.QuotationObj[index].Units_Variant1 % 1 != 0) {
          alert('Please enter only integer value');
          if (seriesname == 'HG' || seriesname == 'HN') {
            this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
          }
          else {
            this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
          }
    
          this.IsQuotationDataValid = false;
        }else if(strunits.includes("."))
        {
          alert('Please enter valid units');
          if (seriesname == 'HG' || seriesname == 'HN') {
            this.QuotationData.QuotationObj[index].Units_Variant1 = 100;
          }
          else {
            this.QuotationData.QuotationObj[index].Units_Variant1 = 1;
          }
    
          this.IsQuotationDataValid = false; 
        } 
        
        else {
    
          if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant1 % 100 != 0)) {
            alert('Please enter the units in multiple of 100.');
            this.QuotationData.QuotationObj[index].var1_Unit_Validation = true;
            this.IsQuotationDataValid = false;
          } else {
            this.QuotationData.QuotationObj[index].var1_Unit_Validation = false;
          }
        }
        if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
          this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
          var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
          var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
          var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
          this.QuotationData.QuotationObj[index].Variant1Price = price * units;
        } else {
          var selectedVariant1 = this.QuotationData.QuotationObj[index].Variant1;
          var price: number = this.variantDropdownData.Variant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
          var units: number = this.QuotationData.QuotationObj[index].Units_Variant1;
          this.QuotationData.QuotationObj[index].Variant1Price = price * units;
          //this.QuotationData.QuotationObj[index].Variant1Price = this.QuotationData.QuotationObj[index].selectedVariant1.filter(x => x.Id == selectedVariant1)[0]?.Price;
        }
        this.calculateTotalPricesForProduct(index, 1);
      
      })).subscribe(x=>{
       // this.updateLoaderEvent.emit(false);
    });
     
  }
     
  var2UnitsChange(index) {
    forkJoin([
      this.GetVarientDropdowndataforModel(index)
    ]).pipe(tap(res => {   
    var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
    let strunits = this.QuotationData.QuotationObj[index].Units_Variant2.toString();
    if (this.QuotationData.QuotationObj[index].Units_Variant2 == undefined || this.QuotationData.QuotationObj[index].Units_Variant2 == null) {
      this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.QuotationData.QuotationObj[index].Units_Variant2 < 0) {
      this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.QuotationData.QuotationObj[index].Units_Variant2 % 1 != 0) {
      alert('Please enter only integer value');
      if (seriesname == 'HG' || seriesname == 'HN') {
        this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
      }
      else {
        this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
      }

      this.IsQuotationDataValid = false;
    }
    else if(strunits.includes("."))
    {
      alert('Please enter valid units');
      if (seriesname == 'HG' || seriesname == 'HN') {
        this.QuotationData.QuotationObj[index].Units_Variant2 = 100;
      }
      else {
        this.QuotationData.QuotationObj[index].Units_Variant2 = 1;
      }

      this.IsQuotationDataValid = false;
    }
    else {

      if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant2 % 100 != 0)) {
        alert('Please enter the units in multiple of 100.');
        this.QuotationData.QuotationObj[index].var2_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      } else {
        this.QuotationData.QuotationObj[index].var2_Unit_Validation = false;
      }
    }
    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
      var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
      this.QuotationData.QuotationObj[index].Variant2Price = price * units;
    } else {
      var selectedVariant2 = this.QuotationData.QuotationObj[index].Variant2;
      var price: number = this.variantDropdownData.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
      var units: number = this.QuotationData.QuotationObj[index].Units_Variant2;
      this.QuotationData.QuotationObj[index].Variant2Price = price * units;
      // this.QuotationData.QuotationObj[index].Variant2Price = this.productDrpdnDetails.Variant2.filter(x => x.Id == selectedVariant2)[0]?.Price;
    }
    this.calculateTotalPricesForProduct(index, 1);
      })).subscribe(x=>{
        // this.updateLoaderEvent.emit(false);
    });
  }

  var3UnitsChange(index) {
    forkJoin([
      this.GetVarientDropdowndataforModel(index)
    ]).pipe(tap(res => {
    var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
    let strunits = this.QuotationData.QuotationObj[index].Units_Variant3.toString();
    if (this.QuotationData.QuotationObj[index].Units_Variant3 == undefined || this.QuotationData.QuotationObj[index].Units_Variant3 == null) {
      this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.QuotationData.QuotationObj[index].Units_Variant3 < 0) {
      this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.QuotationData.QuotationObj[index].Units_Variant3 % 1 != 0) {
      alert('Please enter only integer value');
      if (seriesname == 'HG' || seriesname == 'HN') {
        this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
      }
      else {
        this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
      }

      this.IsQuotationDataValid = false;
    }
    else if(strunits.includes("."))
    {
      alert('Please enter valid units');
      if (seriesname == 'HG' || seriesname == 'HN') {
        this.QuotationData.QuotationObj[index].Units_Variant3 = 100;
      }
      else {
        this.QuotationData.QuotationObj[index].Units_Variant3 = 1;
      }

      this.IsQuotationDataValid = false;
    }
    else {

      if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant3 % 100 != 0)) {
        alert('Please enter the units in multiple of 100.');
        this.QuotationData.QuotationObj[index].var3_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      } else {
        this.QuotationData.QuotationObj[index].var3_Unit_Validation = false;
      }
    }
    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
      var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
      this.QuotationData.QuotationObj[index].Variant3Price = price * units;
    } else {
      var selectedVariant3 = this.QuotationData.QuotationObj[index].Variant3;
      var price: number = this.variantDropdownData.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
      var units: number = this.QuotationData.QuotationObj[index].Units_Variant3;
      this.QuotationData.QuotationObj[index].Variant3Price = price * units;
      //this.QuotationData.QuotationObj[index].Variant3Price = this.productDrpdnDetails.Variant3.filter(x => x.Id == selectedVariant3)[0]?.Price;
    }
    this.calculateTotalPricesForProduct(index, 1);
      })).subscribe(x=>{
        // this.updateLoaderEvent.emit(false);
    });
  }

  var4UnitsChange(index) {
    forkJoin([
      this.GetVarientDropdowndataforModel(index)
    ]).pipe(tap(res => {
    var seriesname = this.productDrpdnDetails.ProductSeries.filter(x => x.Id == this.QuotationData.QuotationObj[index].ProductSeries)[0]?.Name;
    let strunits = this.QuotationData.QuotationObj[index].Units_Variant4.toString();
    if (this.QuotationData.QuotationObj[index].Units_Variant4 == undefined || this.QuotationData.QuotationObj[index].Units_Variant4 == null) {
      this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.QuotationData.QuotationObj[index].Units_Variant4 < 0) {
      this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
      this.IsQuotationDataValid = false;
    } else if (this.QuotationData.QuotationObj[index].Units_Variant4 % 1 != 0) {
      alert('Please enter only integer value');
      if (seriesname == 'HG' || seriesname == 'HN') {
        this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
      }
      else {
        this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
      }

      this.IsQuotationDataValid = false;
    }
    else if(strunits.includes("."))
    {
      alert('Please enter valid units');
      if (seriesname == 'HG' || seriesname == 'HN') {
        this.QuotationData.QuotationObj[index].Units_Variant4 = 100;
      }
      else {
        this.QuotationData.QuotationObj[index].Units_Variant4 = 1;
      }

      this.IsQuotationDataValid = false;
    }
    else {

      if ((seriesname == 'HG' || seriesname == 'HN') && (this.QuotationData.QuotationObj[index].Units_Variant4 % 100 != 0)) {
        alert('Please enter the units in multiple of 100.');
        this.QuotationData.QuotationObj[index].var4_Unit_Validation = true;
        this.IsQuotationDataValid = false;
      } else {
        this.QuotationData.QuotationObj[index].var4_Unit_Validation = false;
      }
    }
    if (!(this.QuotationData.QuotationObj[index].productmodeldata == undefined || this.QuotationData.QuotationObj[index].productmodeldata == null)) {
      this.variantDropdownData = this.QuotationData.QuotationObj[index].productmodeldata;
      var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
      var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
      var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
      this.QuotationData.QuotationObj[index].Variant4Price = price * units;
    } else {
      var selectedVariant4 = this.QuotationData.QuotationObj[index].Variant4;
      var price: number = this.variantDropdownData.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
      var units: number = this.QuotationData.QuotationObj[index].Units_Variant4;
      this.QuotationData.QuotationObj[index].Variant4Price = price * units;
      // this.QuotationData.QuotationObj[index].Variant4Price = this.productDrpdnDetails.Variant4.filter(x => x.Id == selectedVariant4)[0]?.Price;
    }
    this.calculateTotalPricesForProduct(index, 1);
    })).subscribe(x=>{
      // this.updateLoaderEvent.emit(false);
  });
  }

}
