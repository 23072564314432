import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { EnquiryStatus } from 'src/app/EnquiryStatus';
import { EnquiryDetailsModel } from 'src/app/Model/EnquiryModel';
import { CommonService } from 'src/app/Service/common.service';
import { Router } from '@angular/router';
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-close-opp-reason',
  templateUrl: './close-opp-reason.component.html',
  styleUrls: ['./close-opp-reason.component.scss']
})
export class CloseOppReasonComponent implements OnInit {

  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();

  @Input() showLoader: boolean = false;
  @Input() enquiryDataObj;
  closeOpportunityList: SingleFieldModel[] = [];
  enqId: any;
  enquiryStatus = EnquiryStatus;
 // closeOpportunityList: any;
  OppCloseId: any;
  role: string;
  enqNextStatusId:any;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;

  constructor(private toastr: ToastrService, private activeModal: NgbActiveModal,
    private commonService: CommonService,  public dialog: MatDialog,
    private router: Router) { }
  // ngOnDestroy(): void {
  //   this.showLoader=false;
  // }

  ngOnInit(): void {
    this.role = localStorage.getItem("UserRole");
    this.enqId = this.enquiryDataObj.EnquiryId;
    //this.getAllCloseOpportunityReason();
    this.getAllCloseEnquiryReason();
    this.getEnquiryDetailsById(this.enqId);
    this.enqNextStatusId= this.enquiryDataObj.EnquiryStatusId;
    
  }
  

  getAllCloseOpportunityReason() {
    this.commonService.GetData("OpportunityCloseReason", "GetAll", null).subscribe((data: any) => {
      this.closeOpportunityList = data;
      this.OppCloseId=undefined;
    },
      error => {
        this.toastr.error("Failed to load Data!");
         this.showLoader = false;
      });
  }

  
  getAllCloseEnquiryReason() {
    let reasoncode = 'ENQ_CAT'
    let queryString = "";
    queryString = "reasonCatCode=" + reasoncode;
    this.commonService.GetData("ReasonEnquiryWinLoss", "GetAllReason", queryString).subscribe((data: any) => {
      this.closeOpportunityList = data;
      //this.OppCloseId=undefined;
    },
      error => {
        this.toastr.error("Failed to load Data!");
         this.showLoader = false;
      });
  }

  
  closePopup() {
    this.activeModal.close('Close Click');
  }


  getEnquiryDetailsById(id) {
    let queryString = "";
    queryString = "id=" + id;
    this.commonService.GetData('Enquiry', 'GetById', queryString).subscribe((data: any) => {
      this.EnquiryObj.EnquiryDetails = data.EnquiryDetails;
    });
  }

  CloseOpportunityReason(closeOpportunityForm : NgForm){

  closeOpportunityForm.control.markAllAsTouched();
  if (closeOpportunityForm.invalid) {
    this.toastr.error("Please fill required information");
    return;
  }
   var closereasondetails = this.closeOpportunityList.filter(x => x.Id == this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason)
   //this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus = closereasondetails[0].Name; 
   this.EnquiryObj.EnquiryDetails.ReasonCategory = closereasondetails[0].ReasonCategoryId;
   this.EnquiryObj.EnquiryDetails.Status = this.enqNextStatusId;
   
    let enquiryDetails = JSON.stringify(this.EnquiryObj);
    const frmData = new FormData();
    frmData.append("enquiryDetails", enquiryDetails);

   this.commonService.PostData("Enquiry", "CloseEnquiry", frmData, null).subscribe((data: any) => {    
      this.showLoader = false;
      this.toastr.success("Enquiry close approval sent to Sales Director."); 
      this.router.navigate(['/Enquiry']);
      this.closePopup();
    
    }, error => {
      this.toastr.error('Failed to send approval to Sales Director');
      this.showLoader = false;
    });
  }

  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  
}