import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';

@Component({
  selector: 'app-pass-on',
  templateUrl: './pass-on.component.html',
  styleUrls: ['./pass-on.component.scss']
})
export class PassOnComponent implements OnInit {
  @Input() showLoader: boolean = false;
  @Input() enqObj: any;
  userid: any;
  salesEnggList: any;
  selectedSalesEngg: any;
  seList: any[];
  currentSalesEngg: any;
  selectedRegion: any = 0;
  SelectedEnquiryID: any;
  salesRegionValidation: boolean;
  salesEnggValidation: boolean;
   constructor(private toastr: ToastrService,private activeModal: NgbActiveModal,
    private commonService: CommonService,private modalService: NgbModal,  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllSalesEngg();
  }
  getAllSalesEngg() {
    this.showLoader = true;

    this.commonService.GetData('Enquiry', 'GetSalesEnggList', null).subscribe((data: any) => {
      this.salesEnggList = data;
      
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  onRegionChange() {
    this.seList = [];
    this.selectedSalesEngg = undefined;
    this.salesRegionValidation = false;
    this.seList = this.salesEnggList.filter(x=>x.Region.Id==this.selectedRegion)[0]?.SalesEnggList;
    if (!(this.seList==null || this.seList==undefined)) {
      this.seList = this.seList.filter(x=>x.Id!=this.enqObj.Initiator_Id);
    }
    
  }
  onEnggChange() {
    this.salesEnggValidation= false;
  }

  PassonToPerson() {
    if (this.selectedRegion==undefined || this.selectedRegion==null || this.selectedRegion==0) {
      this.salesRegionValidation = true;
      this.salesEnggValidation = true;
      return;
    }
    if (this.selectedSalesEngg==undefined || this.selectedSalesEngg==null || this.selectedSalesEngg==0) {
      this.salesEnggValidation = true;
      return;
    }
    var PassOnViewModel = {
      EnquiryId:this.enqObj.EnquiryId,
      User: this.selectedSalesEngg
    }
    this.showLoader = true;
    this.commonService.PostData("Enquiry","PassOn",PassOnViewModel,null).subscribe(res =>{
      this.toastr.success('Enquiry passed on successfully!');
      this.showLoader = false;
      this.activeModal.close('Close Click');
    },error=>{
      this.toastr.error('Failed to Pass on the enquiry.');
      this.showLoader = false;
    })
  }
  closePopup() {
    this.activeModal.close('Close Click');
  }


}
