<loader [showLoader]="showLoader"></loader>
<div
  class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="m-portlet">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    Reminder Configuration
                  </h3>
                </div>
              </div>
              <div class="m-portlet__head-tools">
                <ul class="m-portlet__nav">
                  
                </ul>
              </div>
            </div>
            <div class="m-portlet__body">
              
                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6"></div>
                    <div class="col-sm-12 col-md-6 right">
                      <mat-form-field style="width: 50%;" class="pull-right">

                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="SearchParam($event)" (keypress)="AvoidSpace($event)" maxlength="50"
                          placeholder="Search" #input>
                      </mat-form-field>

                    </div>

                  </div>

                  <div class="mat-elevation-z8">
                    <div class="table-responsive">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                      class="table table-striped- table-bordered table-hover dataTable no-footer" role="grid">

                      <ng-container matColumnDef="EnquiryStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  [ngStyle]="{'display': 'none'}"> Enquiry Status </th>
                        <td mat-cell *matCellDef="let row"> {{row.EnquiryStatus}} </td>
                      </ng-container>

                      <ng-container matColumnDef="EnquiryStatus1">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="2" style=" text-align: center !important;">Enquiry Status</th>
                      </ng-container>

                      <ng-container matColumnDef="First_Rem">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reminder (In Days)</th>
                        <td mat-cell *matCellDef="let row"> {{row.First_Rem}} </td>
                      </ng-container>

                      <ng-container matColumnDef="First_Rem_ToWhom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> To Whom </th>
                        <td mat-cell *matCellDef="let row"> {{row.First_Rem_ToWhom}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Second_Rem">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reminder (In Days)</th>
                        <td mat-cell *matCellDef="let row"> {{row.Second_Rem}} </td>
                      </ng-container>
                     
                      <ng-container matColumnDef="Second_Rem_ToWhom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> To Whom</th>
                        <td mat-cell *matCellDef="let row"> {{row.Second_Rem_ToWhom}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Third_Rem">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reminder (In Days)</th>
                        <td mat-cell *matCellDef="let row"> {{row.Third_Rem}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Third_Rem_ToWhom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> To Whom </th>
                        <td mat-cell *matCellDef="let row"> {{row.Third_Rem_ToWhom}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Escalation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reminder (In Days)</th>
                        <td mat-cell *matCellDef="let row"> {{row.Escalation}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Escalation_ToWhom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> To Whom </th>
                        <td mat-cell *matCellDef="let row"> {{row.Escalation_ToWhom}} </td>
                      </ng-container>

                      <ng-container matColumnDef="CC_Rem_toWhom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CC </th>
                        <td mat-cell *matCellDef="let row"> {{row.CC_Rem_toWhom}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> Action </th>
                        <td mat-cell *matCellDef="let row">
                          <a mat-icon-button color="accent" [routerLink]="['/Reminders-config',row?.Pk_Id]"
                            routerLinkActive="active" title="View">
                            <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
                          </a>&nbsp;&nbsp;&nbsp;&nbsp;

                          <mat-slide-toggle [hidden]="!isAlertsEditable" class="example-margin"
                            [checked]="row.Status" [disabled]="disabled"
                            (change)="UpdateAlertStatusById(row.Pk_Id,row.Status)">
                          </mat-slide-toggle>&nbsp; {{row.Status==true ? 'Active': 'Inactive'}}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="Action1">
                        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style=" text-align: center !important;">Action</th>
                      </ng-container>


                      <ng-container matColumnDef="header-row-second-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style=" text-align: center !important;"> First Reminder </th>
                      </ng-container>

                      <ng-container matColumnDef="header-row-third-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style=" text-align: center !important;"> Second Reminder </th>
                      </ng-container>

                      <ng-container matColumnDef="header-row-fourth-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style=" text-align: center !important;"> Third Reminder </th>
                      </ng-container>

                      <ng-container matColumnDef="header-row-five-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style=" text-align: center !important;"> Escalation </th>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="['EnquiryStatus1','header-row-second-group', 'header-row-third-group','header-row-fourth-group','header-row-five-group','Action1']"></tr>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    </table>
                    <div
                      *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
                      class="text-center">No records found</div>
                    </div>
                    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                      [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent($event)">
                    </mat-paginator>
                  
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>