
import { Component, OnInit, ViewChild, Input, ɵclearResolutionOfComponentResourcesQueue, ElementRef, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { BasicCustomerInformationModel } from 'src/app/Model/CustomerModel';
import {  CustomerDrpdnDetails, CustomerDetails, ProductDrpdnDetails, EnquiryDetailsModel, ProductDetails, Comments, removefiles, TermsAndCondition, ActivityModel, ProductModelPOValues, CustomTermsAndCondition } from 'src/app/Model/EnquiryModel';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { NgbDate, NgbModal, NgbActiveModal, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals } from 'src/app/app.global';

import { UserRoles } from "src/app/UserRoles";
import { SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { MatTableDataSource } from '@angular/material/table';
import { MatStepper } from '@angular/material/stepper';
import { FormControl, NgForm } from '@angular/forms';
import { QuotationDetailsModel, QuotationCustomerDetails } from 'src/app/Model/QuotationModel';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import {
  saveAs as importedSaveAs
} from "file-saver";

import { EnquiryStatus } from "src/app/EnquiryStatus";
import { EnquiryStatusList } from 'src/app/Model/EnquiryStatus';
import { EnquirySendBack } from 'src/app/Model/EnquirySendBack';
import { SendBackCommentComponent } from '../send-back-comment/send-back-comment.component';

@Component({
  selector: 'app-ceo-approval',
  templateUrl: './ceo-approval.component.html',
  styleUrls: ['./ceo-approval.component.scss']
})
export class CeoApprovalComponent implements OnInit {

  @ViewChild('myform3') myform3: NgForm;
  @ViewChild('myform5') myform5: NgForm;
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild('editpovalue', { static: false }) editpovalue: ElementRef;
  standardTC:any[] = [];
  customTCValues:any[] =[];

  userInfo: EmployeeGuestModel = new EmployeeGuestModel();
  CustomerList: BasicCustomerInformationModel[] = [];
  EnquiryObj: EnquiryDetailsModel = new EnquiryDetailsModel();
  newRFQDetails: any = {};
  productListdropdownData = [];
  customerDrpdnDetails: CustomerDrpdnDetails = new CustomerDrpdnDetails();
  productDrpdnDetails: ProductDrpdnDetails = new ProductDrpdnDetails();
  customerData: CustomerDetails;
  activityDetails: ActivityModel[] = Array<ActivityModel>();
  CustomerDetailsObj: QuotationCustomerDetails = new QuotationCustomerDetails();
  QuotationData: QuotationDetailsModel = new QuotationDetailsModel();
  allowedFileType: string[] = [
    'image/bmp',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'text/plain',
    'text/csv',
    'application/xml',
    'text/xml',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip',
    'application/x-zip-compressed',
  ];

  showEditRFQForInitiator: boolean;
  UserRole: any;
  isTechnicalDiscardVisible: boolean =false;
  isEnquiryFormVisible: boolean = false;
  isRFQVisible: boolean = false;
  isQuoteGenerationVisible: boolean = false;
  isManagementApprovalVisible: boolean = false;
  isSOVisible: boolean = false;
  isOrderAcknowledgementVisible: boolean = false;
  EnquiryConfirmationVisible: boolean = false;
  isSupplychainVisible: boolean = false;
  isAccountsVisible: boolean = false;
  iseditable:boolean=false;
  isOAGenerationVisible: boolean = false;
  isBOMAttachment: boolean;
  isInvoiceAttachment: boolean;
  isPOAttachment: boolean;
  IsRFQDataValid: boolean = true;
  selectedIndexForStatus: any = 1;
  remLimit: any = 1500;
  maxLimit: any = 1500;
  varLongTermGoal: any;
  DiscountFromrange:any;
  Discounttorange:any;

  @Input()
  showLoader: boolean = false;
  charCode: number;
  enquiryStatus = EnquiryStatus;
  AppRoles = UserRoles;
  enquirySource: SingleFieldModel[] = [];
  TnCStandardFromDropDown: any[] = [];
  TnCCustomizedFromDropDown: any[] = [];
  TnCCustomizedDropDownForEditRfq1: any[] = [];
  TnCCustomizedDropDownForEditRfq2: any[] = [];
  newCustomTc: any;
  product: any = {};
  isEnquirySubmit: boolean = false;
  charCode1: any;
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  dataSource: MatTableDataSource<Comments>;

  @ViewChild('stepper') stepper: MatStepper;
  AppGlobals = AppGlobals;
  fileDownloadPath = this.AppGlobals.baseFileDownloadUrl;
  QuotationDownloadPath = this.AppGlobals.QuotationFileDownloadUrl;
  isEnquiryLoaded: boolean;
  role: string;
  isStandardCondition: boolean;
  closeResult: string;
  isCEOApprovalVisible: boolean;
  demoObject: any;
  demoCTCObject: any;
  WinLossDropdownData: any[] = [];
  WinLossDropdownDataForStatus: any;
  isPoValueDisable: boolean;
  isCustomerCommentVisible: any;
  isOAGenerated: boolean;
  isAcceptClick: boolean;
  dataPassed: any;
  dropdownData: any;
  showQuotationLink: boolean;
  userid: string;
  isprocessing: boolean = true;
  rfqattpresent: boolean;
  QuotationDataForPO: any[] = [];
  chosenItem: string = "1";
  quoterevcomments: any;
  QGRFQEditable: boolean = false;
  IsActivityDataValid: boolean;
  today: Date;
  fc = new FormControl();
  minDate: NgbDate;
  showEditRFQ: boolean;
  isTantativeDateEditable: boolean;
  tempRFQ: ProductDetails[];
  IsPODataValid: boolean;
  isEditactivity: boolean = true;
  PreviousStatus: number = 0;
  tempfile: any;
  ngbDateParserFormatter: any;
  UpdatePO: boolean = false;
  isPOAttacmentDelete: boolean = false;
  RevisedQuotationFileNameList: string[] = [];
  emailPattern: string = "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  StartDate: NgbDate;
  EndDate: NgbDate;
  RoleWiseScreenDisplay: any;
  previousUrl: string = null;
  IndustrySegment: any[] = [];
  SubSegment: any[] = [];
  Application: any[] = [];

  FinalIndustrySegment: any[] = [];
  FinalSubSegment: any[] = [];
  FinalApplication: any[] = [];

  workflowActionList:any[] = [];
  enquiryStatusList: EnquiryStatusList[]=[];
  workflowId:number;
  sendbackRules:EnquirySendBack[]=[];
  previousEnqStatus : any;
  roleOfAdmin : any;

  @Output() updateLoaderEvent = new EventEmitter<boolean>();
  UserRolesData:any[]=[];

  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog,
    private _location: Location,
    private config: NgbDatepickerConfig,
    private modalService: NgbModal, private activeModel: NgbActiveModal,
    private router: Router, private route: ActivatedRoute, private titleService: Title,private cd: ChangeDetectorRef) {
    this.today = new Date();
    this.minDate = new NgbDate(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
    this.StartDate = new NgbDate(2018, 1, 1);
    this.EndDate = new NgbDate(this.today.getFullYear() + 1, 12, 31);


  }
  ngAfterViewInit() {
   this.updateLoaderEvent.emit(true);
    setTimeout(() => {
      this.isprocessing = false;
      this.chosenItem = "1";
     this.updateLoaderEvent.emit(false);
    }, 2000);
  }
  ngOnInit() {
    this.commonService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });

    let tempRoleWiseScreenDisplay = localStorage.getItem("WorkFlowRoleWiseScreenAccess");
    this.RoleWiseScreenDisplay = tempRoleWiseScreenDisplay.split(",");
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.commonService.RefreshHeaderFun();
    this.EnquiryObj.EnquiryDetails.EnquiryId = this.route.snapshot.params["id"];
    this.EnquiryObj.EnquiryDetails.EstimatedProductValue = null;
    this.EnquiryObj.EnquiryDetails.InvoiceAttachment = undefined;
    this.getAllWorkFlow();
    this.getRFQProduct();
    this.getEnquirySourceDetails();
    this.getCustomerList();
    this.getEnquiryDetailsById();
    this.getEnquiryStatus();
    this.getUserRoles()
    this.isEnquiryLoaded = false;
    this.getSendbackWorkfow();

    this.UserRole = localStorage.getItem("UserRole");
    this.isTechnicalDiscardVisible = AppGlobals.QuoteGenearation_Enquiry_TechnicalDiscardArray.filter(x => x.role
      == this.UserRole)[0]?.isEditable;
    
    this.roleOfAdmin = UserRoles.Admin;
  }
  limitTextOnKeyUpDown1(event: any) {
    let goalText = event.target.value;
    if (goalText.length > this.maxLimit) {
      this.varLongTermGoal = goalText.substring(0, this.maxLimit);
    } else {
      this.remLimit = this.maxLimit - goalText.length;
    }
  }

  ScreenAccessSpecifier_ALL() {
    this.isEnquiryFormVisible = true;
    this.isRFQVisible = true;
    this.isQuoteGenerationVisible = true;
    this.isManagementApprovalVisible = true;
    this.isCEOApprovalVisible = true;
    this.EnquiryConfirmationVisible = true;
    this.isSOVisible = true;
    this.isOrderAcknowledgementVisible = true;
    this.isSupplychainVisible = true;
    this.isAccountsVisible = true;
    this.isOAGenerationVisible = true;

  }

  ScreenAccessSpecifier(role, enquirystatus) {
    //MAKE SCREENS VISIBLE ACCORDING TO LOGGED IN USER
    this.isEnquiryFormVisible = true;
    this.isRFQVisible = true;
    this.userid = localStorage.getItem("UserID");

    //ADD CONDITION TO CHECK IF THE LOGGED IN USER IS INITIATOR OF THE ENQUIRY
    // SCREENS OPRN IN EDITABLE MODE TO INITIATOR OF THE ENQUIRY AND AMIN
    if ((enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='EnquiryAdded')[0]?.Pk_Id || enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='RFQSaveAsDraft')[0]?.Pk_Id ||
      enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='RFQReworkbyL1')[0]?.Pk_Id || enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='QuotationRejectedbyMngmt_SalesDirector')[0]?.Pk_Id ||
      enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='QuotationRejectedbyMngmt_CEO')[0]?.Pk_Id || enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='QuotationRevision')[0]?.Pk_Id
      || enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='EnquiryTechnicalDiscardbyL1')[0]?.Pk_Id || enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='EnquiryTechnicalDiscardbyL2')[0]?.Pk_Id
      || enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='EnquiryTechnicalDiscardbyL3')[0]?.Pk_Id) &&
      (this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id)) {
      this.AppGlobals.RFQ_SaveasDraftBySalesEngg = true;
      this.AppGlobals.RFQ_SubmitBySalesEngg = true;
      this.AppGlobals.isRFQEditable = true;
      this.AppGlobals.disable_RFQ_SaveasDraftBySalesEngg = true;
      this.AppGlobals.disable_RFQ_SubmitBySalesEngg = true;
      this.selectedIndexForStatus = 1;
    } else {
      this.AppGlobals.isRFQEditable = false;
      this.AppGlobals.RFQ_SaveasDraftBySalesEngg = false;
      this.AppGlobals.RFQ_SubmitBySalesEngg = false;
      this.AppGlobals.disable_RFQ_SaveasDraftBySalesEngg = false;
      this.AppGlobals.disable_RFQ_SubmitBySalesEngg = false;
      this.selectedIndexForStatus = 0;
      if ((this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id) && this.EnquiryObj.EnquiryDetails.Status >= this.enquiryStatusList.filter(x=>x.Status=='EnquiryOrderConfirmedbySalesEngg')[0]?.Pk_Id) {
        this.EnquiryConfirmationVisible = true;
        this.AppGlobals.isEnquiryConfirmationEditable = false;
        if ((enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='OrderRejectedbyMngmt_SalesDirector')[0]?.Pk_Id || enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='OrderRejectedbyMngmt_CEO')[0]?.Pk_Id) &&
          (this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id)) {
          this.AppGlobals.isEnquiryConfirmationEditable = true;
          this.AppGlobals.EnquiryConfirmation_ConfimrOrderBySalesEngg = true;
        } else {
          this.AppGlobals.EnquiryConfirmation_ConfimrOrderBySalesEngg = false;
        }


        if (!this.AppGlobals.isEnquiryConfirmationEditable) {
          if (!this.RoleWiseScreenDisplay.some(x => x == 'Enquiry Confirmation')) {
            this.EnquiryConfirmationVisible = false;
            this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

          }
        }
      } else if ((this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id) && this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='SendBOMandProposal')[0]?.Pk_Id) {
        this.EnquiryConfirmationVisible = true;
        AppGlobals.EnquiryConfirmation_ConfimrOrderBySalesEngg = true;
        this.AppGlobals.isEnquiryConfirmationEditable = true;
      }
    }

    if (!(this.EnquiryObj.EnquiryDetails.QuotationPdf_filename == null ||
      this.EnquiryObj.EnquiryDetails.QuotationPdf_filename == undefined ||
      this.EnquiryObj.EnquiryDetails.QuotationPdf_filename == "")) {
      this.showQuotationLink = true;
    } else {
      this.showQuotationLink = false;
    }
    if (this.role == this.EnquiryObj.EnquiryDetails.PendingAtRoleId || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id) {
      this.showEditRFQ = true;
      this.isEditactivity = true;
    } else {
      this.showEditRFQ = false;
      this.isEditactivity = false;
    }
    if (this.EnquiryObj.EnquiryDetails.PendingAtRoleId == null) {
      this.isEditactivity = this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id ? true : false;
     
    }
    if (this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id) {
      if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_CEO')[0]?.Pk_Id ||
        this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OANoGenerated')[0]?.Pk_Id) {
        this.isEditactivity = false;
      }
      else {
        this.isEditactivity = true;
      }

    }
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='AccountsConfirmInvoiceNo')[0]?.Pk_Id) {
      this.isEditactivity = false;
    }
    var screensRolewise = this.AppGlobals.screensVisibleAccordingtoEnquiryStatus.filter(x => x.role == role)[0]?.screensForStatus;
    if (screensRolewise != undefined) {
      var filteredscreens = screensRolewise.filter(x => x.status == enquirystatus)[0];
      if (filteredscreens != undefined) {
        this.selectedIndexForStatus = filteredscreens.selectedIndex;

        if (this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id) {

        }

        this.isQuoteGenerationVisible = filteredscreens.screens.indexOf('Quote Generation') > -1 ? true : false;
        if (this.isQuoteGenerationVisible) {
          this.AppGlobals.isQuoteGenerationEditable = filteredscreens.disableScreen.indexOf('Quote Generation') > -1 ? false : true;
          //  #region VisibleButtons
          if (!this.AppGlobals.isQuoteGenerationEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'Quote Generation')) {
              this.isQuoteGenerationVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }
          this.AppGlobals.QuoteGenearationAcceptByL1 = filteredscreens.visibleButtons.indexOf('QuoteGenearationAcceptByL1') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_RemorkByL1 = filteredscreens.visibleButtons.indexOf('QuoteGenearation_RemorkByL1') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_ForwardtoL2ByL1 = filteredscreens.visibleButtons.indexOf('QuoteGenearation_ForwardtoL2ByL1') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_ForwardtoL3ByL2 = filteredscreens.visibleButtons.indexOf('QuoteGenearation_ForwardtoL3ByL2') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_SendtoL2ByL3 = filteredscreens.visibleButtons.indexOf('QuoteGenearation_SendtoL2ByL3') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_SendtoL1ByL2 = filteredscreens.visibleButtons.indexOf('QuoteGenearation_SendtoL1ByL2') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_SendtoCustomerServiceByL1 = filteredscreens.visibleButtons.indexOf('QuoteGenearation_SendtoCustomerServiceByL1') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_SendforApprovalByCustomerService = filteredscreens.visibleButtons.indexOf('QuoteGenearation_SendforApprovalByCustomerService') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_SendBOMProposalByL1 = filteredscreens.visibleButtons.indexOf('QuoteGenearation_SendBOMProposalByL1') > -1 ? true : false;
          this.AppGlobals.QuoteGenearation_GenerateQuote = filteredscreens.visibleButtons.indexOf('QuoteGenearation_GenerateQuote') > -1
            && (this.role == this.EnquiryObj.EnquiryDetails.PendingAtRoleId || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id) ? true : false;
          this.AppGlobals.QuoteGenearation_EditRFQ = filteredscreens.visibleButtons.indexOf('QuoteGenearation_EditRFQ') > -1
            && (this.role == this.EnquiryObj.EnquiryDetails.PendingAtRoleId || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id) ? true : false;
          this.AppGlobals.QuoteGenearation_Enquiry_TechnicalDiscard = filteredscreens.visibleButtons.indexOf('QuoteGenearation_Enquiry_TechnicalDiscard') > -1 
          && (this.role == this.EnquiryObj.EnquiryDetails.PendingAtRoleId || this.role == this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id ) ? true : false;
          //#endregion
          // #region enableButtons
          this.AppGlobals.disable_QuoteGenearationAcceptByL1 = filteredscreens.buttons.indexOf('QuoteGenearationAcceptByL1') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_RemorkByL1 = filteredscreens.buttons.indexOf('QuoteGenearation_RemorkByL1') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_ForwardtoL2ByL1 = filteredscreens.buttons.indexOf('QuoteGenearation_ForwardtoL2ByL1') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_ForwardtoL3ByL2 = filteredscreens.buttons.indexOf('QuoteGenearation_ForwardtoL3ByL2') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_SendtoL2ByL3 = filteredscreens.buttons.indexOf('QuoteGenearation_SendtoL2ByL3') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_SendtoL1ByL2 = filteredscreens.buttons.indexOf('QuoteGenearation_SendtoL1ByL2') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_SendtoCustomerServiceByL1 = filteredscreens.buttons.indexOf('QuoteGenearation_SendtoCustomerServiceByL1') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_SendforApprovalByCustomerService = filteredscreens.buttons.indexOf('QuoteGenearation_SendforApprovalByCustomerService') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_SendBOMProposalByL1 = filteredscreens.buttons.indexOf('QuoteGenearation_SendBOMProposalByL1') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_GenerateQuote = filteredscreens.buttons.indexOf('QuoteGenearation_GenerateQuote') > -1 ? true : false;
          this.AppGlobals.disable_QuoteGenearation_EditRFQ = filteredscreens.buttons.indexOf('QuoteGenearation_EditRFQ') > -1 ? true : false;
          //this.AppGlobals.disable_QuoteGenearation_Enquiry_TechnicalDiscard = filteredscreens.buttons.indexOf('QuoteGenearation_Enquiry_TechnicalDiscard') > -1 ? true : false;
          
          //#endregion

          //CHECK IF QUOTATION TO BE SENT TO CUSTOMER SERVICE
          if (this.AppGlobals.disable_QuoteGenearation_SendBOMProposalByL1) {
            var isSpecialPrice = false;
            this.EnquiryObj.RFQObject.forEach(element => {
              if (element.SalesPrice == 3) {
                isSpecialPrice = true;
              }
            });
            if (enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='QuotationApprovedbyMngmt_SalesDirector')[0]?.Pk_Id ||
              enquirystatus == this.enquiryStatusList.filter(x=>x.Status=='QuotationApprovedbyMngmt_CEO')[0]?.Pk_Id) {
              this.AppGlobals.disable_QuoteGenearation_SendBOMProposalByL1 = true;
              this.AppGlobals.disable_QuoteGenearation_SendforApprovalByCustomerService = false;
            } else {
              if (isSpecialPrice || this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == "Customized") {
                this.AppGlobals.disable_QuoteGenearation_SendBOMProposalByL1 = false;
                this.AppGlobals.disable_QuoteGenearation_SendforApprovalByCustomerService = true;
              } else {
                this.AppGlobals.disable_QuoteGenearation_SendBOMProposalByL1 = true;
                this.AppGlobals.disable_QuoteGenearation_SendforApprovalByCustomerService = false;
              }
            }

          }
        }
        this.isManagementApprovalVisible = filteredscreens.screens.indexOf('Management Approval') > -1 ? true : false;
        if (this.isManagementApprovalVisible) {
          this.AppGlobals.isManagementApprovalEditable = filteredscreens.disableScreen.indexOf('Management Approval') > -1 ? false : true;
          //#region VisibleButtons
          if (!this.AppGlobals.isManagementApprovalEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'Management Approval')) {
              this.isManagementApprovalVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }
          this.AppGlobals.ManagementApproval_RejectBySalesDirector = filteredscreens.visibleButtons.indexOf('ManagementApproval_RejectBySalesDirector') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_ApproveBySalesDirector = filteredscreens.visibleButtons.indexOf('ManagementApproval_ApproveBySalesDirector') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_HoldBySalesDirector = filteredscreens.visibleButtons.indexOf('ManagementApproval_HoldBySalesDirector') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_SendQuotationforApprovalBySalesDirector = filteredscreens.visibleButtons.indexOf('ManagementApproval_SendQuotationforApprovalBySalesDirector') > -1 ? true : false;

          this.AppGlobals.ManagementApproval_Order_RejectBySalesDirector = filteredscreens.visibleButtons.indexOf('ManagementApproval_Order_RejectBySalesDirector') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_Order_ApproveBySalesDirector = filteredscreens.visibleButtons.indexOf('ManagementApproval_Order_ApproveBySalesDirector') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_SendOrderforApprovalBySalesDirector = filteredscreens.visibleButtons.indexOf('ManagementApproval_SendOrderforApprovalBySalesDirector') > -1 ? true : false;
          //#endregion
          // #region enableButtons
          this.AppGlobals.disable_ManagementApproval_RejectBySalesDirector = filteredscreens.buttons.indexOf('ManagementApproval_RejectBySalesDirector') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_ApproveBySalesDirector = filteredscreens.buttons.indexOf('ManagementApproval_ApproveBySalesDirector') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_HoldBySalesDirector = filteredscreens.buttons.indexOf('ManagementApproval_HoldBySalesDirector') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_SendQuotationforApprovalBySalesDirector = filteredscreens.buttons.indexOf('ManagementApproval_SendQuotationforApprovalBySalesDirector') > -1 ? true : false;

          this.AppGlobals.disable_ManagementApproval_Order_RejectBySalesDirector = filteredscreens.buttons.indexOf('ManagementApproval_Order_RejectBySalesDirector') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_Order_ApproveBySalesDirector = filteredscreens.buttons.indexOf('ManagementApproval_Order_ApproveBySalesDirector') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_SendOrderforApprovalBySalesDirector = filteredscreens.buttons.indexOf('ManagementApproval_SendOrderforApprovalBySalesDirector') > -1 ? true : false;
          //#endregion
        }
        this.isCEOApprovalVisible = filteredscreens.screens.indexOf('CEO Approval') > -1 ? true : false;
        if (this.isCEOApprovalVisible) {
          this.AppGlobals.isCEOApprovalEditable = filteredscreens.disableScreen.indexOf('CEO Approval') > -1 ? false : true;
          //#region VisibleButtons
          if (!this.AppGlobals.isCEOApprovalEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'CEO Approval')) {
              this.isCEOApprovalVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }
          this.AppGlobals.ManagementApproval_RejectByCEO = filteredscreens.visibleButtons.indexOf('ManagementApproval_RejectByCEO') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_HoldByCEO = filteredscreens.visibleButtons.indexOf('ManagementApproval_HoldByCEO') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_ApproveByCEO = filteredscreens.visibleButtons.indexOf('ManagementApproval_ApproveByCEO') > -1 ? true : false;

          this.AppGlobals.ManagementApproval_Order_ApproveByCEO = filteredscreens.visibleButtons.indexOf('ManagementApproval_Order_ApproveByCEO') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_Order_RejectByCEO = filteredscreens.visibleButtons.indexOf('ManagementApproval_Order_RejectByCEO') > -1 ? true : false;
          //#endregion
          //#region enableButtns
          this.AppGlobals.disable_ManagementApproval_RejectByCEO = filteredscreens.buttons.indexOf('ManagementApproval_RejectByCEO') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_HoldByCEO = filteredscreens.buttons.indexOf('ManagementApproval_HoldByCEO') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_ApproveByCEO = filteredscreens.buttons.indexOf('ManagementApproval_ApproveByCEO') > -1 ? true : false;

          this.AppGlobals.disable_ManagementApproval_Order_ApproveByCEO = filteredscreens.buttons.indexOf('ManagementApproval_Order_ApproveByCEO') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_Order_RejectByCEO = filteredscreens.buttons.indexOf('ManagementApproval_Order_RejectByCEO') > -1 ? true : false;
          //#endregion
        }
        this.EnquiryConfirmationVisible = filteredscreens.screens.indexOf('Enquiry Confirmation') > -1 ? true : false;

        if (this.EnquiryConfirmationVisible) {
          this.AppGlobals.isEnquiryConfirmationEditable = filteredscreens.disableScreen.indexOf('Enquiry Confirmation') > -1 ? false : true;
          if (this.userid != this.EnquiryObj.EnquiryDetails.Initiator_Id && this.role != this.UserRolesData.filter(x=>x.Name=='Admin')[0]?.Id && this.AppGlobals.isEnquiryConfirmationEditable) {
            this.EnquiryConfirmationVisible = false;
            this.selectedIndexForStatus = 0;
          }
          //#region VisibleButtons
          if (!this.AppGlobals.isEnquiryConfirmationEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'Enquiry Confirmation')) {
              this.EnquiryConfirmationVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }
          this.AppGlobals.EnquiryConfirmation_ConfimrOrderBySalesEngg = filteredscreens.visibleButtons.indexOf('EnquiryConfirmation_ConfimrOrderBySalesEngg') > -1 ? true : false;
          this.AppGlobals.EnquiryConfirmation_QuoteRevision = filteredscreens.visibleButtons.indexOf('EnquiryConfirmation_QuoteRevision') > -1 ? true : false;
          //#endregion
          //#region enableButtns
          this.AppGlobals.disable_EnquiryConfirmation_QuoteRevision = filteredscreens.buttons.indexOf('EnquiryConfirmation_QuoteRevision') > -1 ? true : false;
          //#endregion

        }
        this.isSOVisible = filteredscreens.screens.indexOf('Sales Order') > -1 ? true : false;
        if (this.isSOVisible) {
          if (!(this.EnquiryObj.POValues == null || this.EnquiryObj.POValues?.length == 0)) {
          } else {
            this.getQuotationData();
          }

          //#region VisibleButtons

          this.AppGlobals.isSOEditable = filteredscreens.disableScreen.indexOf('Sales Order') > -1 ? false : true;
          if (!this.AppGlobals.isSOEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'Sales Order')) {
              this.isSOVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }
          this.AppGlobals.SO_SendtoSupplyChainByCustomerService = filteredscreens.visibleButtons.indexOf('SO_SendtoSupplyChainByCustomerService') > -1 ? true : false;
          this.AppGlobals.ManagementApproval_SendOrderforApprovalByCustomerService = filteredscreens.visibleButtons.indexOf('ManagementApproval_SendOrderforApprovalByCustomerService') > -1 ? true : false;
          //#endregion
          //#region enableButtns
          this.AppGlobals.disable_SO_SendtoSupplyChainByCustomerService = filteredscreens.buttons.indexOf('SO_SendtoSupplyChainByCustomerService') > -1 ? true : false;
          this.AppGlobals.disable_ManagementApproval_SendOrderforApprovalByCustomerService = filteredscreens.buttons.indexOf('ManagementApproval_SendOrderforApprovalByCustomerService') > -1 ? true : false;
          //#endregion
        }
        this.isSupplychainVisible = filteredscreens.screens.indexOf('Supply Chain') > -1 ? true : false;
        if (this.isSupplychainVisible) {
          this.AppGlobals.isSupplychainEditable = filteredscreens.disableScreen.indexOf('Supply Chain') > -1 ? false : true;
          //#region VisibleButtons
          if (!this.AppGlobals.isSupplychainEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'Supply Chain')) {
              this.isSupplychainVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }
          this.AppGlobals.SupplyChain_SubmitBySupplyChain = filteredscreens.visibleButtons.indexOf('SupplyChain_SubmitBySupplyChain') > -1 ? true : false;
          // this.AppGlobals.OrderAcknowledmentNo = filteredscreens.visibleButtons.indexOf('OrderAcknowledmentNo') > -1 ? true: false;
          this.AppGlobals.MaterialReadyForDispatch = filteredscreens.visibleButtons.indexOf('MaterialReadyForDispatch') > -1 ? true : false;
          //#endregion
          //#region enableButtns
          this.AppGlobals.disable_SupplyChain_SubmitBySupplyChain = filteredscreens.buttons.indexOf('SupplyChain_SubmitBySupplyChain') > -1 ? true : false;
          // this.AppGlobals.disable_OrderAcknowledmentNo = filteredscreens.buttons.indexOf('OrderAcknowledmentNo') > -1 ? true: false;

          //#endregion
        }
        this.isAccountsVisible = filteredscreens.screens.indexOf('Accounts') > -1 ? true : false;
        if (this.isAccountsVisible) {
          this.AppGlobals.isAccountsEditable = filteredscreens.disableScreen.indexOf('Accounts') > -1 ? false : true;
          //#region VisibleButtons
          if (!this.AppGlobals.isAccountsEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'Accounts')) {
              this.isAccountsVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }

          this.AppGlobals.Accounts_ConfimrOrderByAccounts = filteredscreens.visibleButtons.indexOf('Accounts_ConfimrOrderByAccounts') > -1 ? true : false;
          //#endregion
          //#region enableButtns
          this.AppGlobals.disable_Accounts_ConfimrOrderByAccounts = filteredscreens.buttons.indexOf('Accounts_ConfimrOrderByAccounts') > -1 ? true : false;
          //#endregion
        }
        this.isOAGenerationVisible = filteredscreens.screens.indexOf('OAGenerate') > -1 ? true : false;
        if (this.isOAGenerationVisible) {
          this.AppGlobals.isOAGenerateEditable = filteredscreens.disableScreen.indexOf('OAGenerate') > -1 ? false : true;
          //#region VisibleButtons
          if (!this.AppGlobals.isOAGenerateEditable) {
            if (!this.RoleWiseScreenDisplay.some(x => x == 'OAGenerate')) {
              this.isOAGenerationVisible = false;
              this.selectedIndexForStatus = this.selectedIndexForStatus - 1;

            }
          }
          this.AppGlobals.OrderAcknowledmentNo = filteredscreens.visibleButtons.indexOf('OrderAcknowledmentNo') > -1 ? true : false;

          //#endregion
          //#region enableButtns

          //#endregion
        }
      }
      //TO SHOW THE SCREENS WHICH ARE PASSED BY CURRENT ROLE
      else {
        this.selectedIndexForStatus = 0;
        var screensVisibleAfterPassedArray = this.AppGlobals.screensVisibleAfterPassed.filter(x => x.role == role)[0]?.screens;
        var filterScreenArray = screensVisibleAfterPassedArray.filter(o1 => this.RoleWiseScreenDisplay.some(o2 => o1.screenName === o2));

        if (filterScreenArray != undefined) {
          var qgArray: any = filterScreenArray.filter(x => x.screenName == 'Quote Generation')[0]?.status;
          if (qgArray != undefined) {
            this.isQuoteGenerationVisible = qgArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isQuoteGenerationEditable = false;
            this.AppGlobals.QuoteGenearationAcceptByL1 = false;
            this.AppGlobals.QuoteGenearation_RemorkByL1 = false;
            this.AppGlobals.QuoteGenearation_ForwardtoL2ByL1 = false;
            this.AppGlobals.QuoteGenearation_ForwardtoL3ByL2 = false;
            this.AppGlobals.QuoteGenearation_SendtoL2ByL3 = false;
            this.AppGlobals.QuoteGenearation_SendtoL1ByL2 = false;
            this.AppGlobals.QuoteGenearation_SendtoCustomerServiceByL1 = false;
            this.AppGlobals.QuoteGenearation_SendforApprovalByCustomerService = false;
            this.AppGlobals.QuoteGenearation_SendBOMProposalByL1 = false;
            this.AppGlobals.QuoteGenearation_Enquiry_TechnicalDiscard = false;
          }


          var maArray = filterScreenArray.filter(x => x.screenName == 'Management Approval')[0]?.status;
          if (maArray != undefined) {
            this.isManagementApprovalVisible = maArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isManagementApprovalEditable = false;
            this.AppGlobals.ManagementApproval_RejectBySalesDirector = false;
            this.AppGlobals.ManagementApproval_ApproveBySalesDirector = false;
            this.AppGlobals.ManagementApproval_HoldBySalesDirector = false;
            this.AppGlobals.ManagementApproval_SendQuotationforApprovalBySalesDirector = false;
            this.AppGlobals.ManagementApproval_Order_RejectBySalesDirector = false;
            this.AppGlobals.ManagementApproval_Order_ApproveBySalesDirector = false;
            this.AppGlobals.ManagementApproval_SendOrderforApprovalBySalesDirector = false;
          }

          var caArray = filterScreenArray.filter(x => x.screenName == 'CEO Approval')[0]?.status;
          if (caArray != undefined) {
            this.isCEOApprovalVisible = caArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isCEOApprovalEditable = false;
            this.AppGlobals.ManagementApproval_RejectByCEO = false;
            this.AppGlobals.ManagementApproval_HoldByCEO = false;
            this.AppGlobals.ManagementApproval_ApproveByCEO = false;

            this.AppGlobals.ManagementApproval_Order_ApproveByCEO = false;
            this.AppGlobals.ManagementApproval_Order_RejectByCEO = false;
          }


          var ecaArray = filterScreenArray.filter(x => x.screenName == 'Enquiry Confirmation')[0]?.status;
          if (ecaArray != undefined) {
            this.EnquiryConfirmationVisible = ecaArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isEnquiryConfirmationEditable = false;
            this.AppGlobals.EnquiryConfirmation_ConfimrOrderBySalesEngg = false;
          }

          var soArray = filterScreenArray.filter(x => x.screenName == 'Sales Order')[0]?.status;
          if (soArray != undefined) {
            this.isSOVisible = soArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isSOEditable = false;
            this.AppGlobals.SO_SendtoSupplyChainByCustomerService = false;
            this.AppGlobals.ManagementApproval_SendOrderforApprovalByCustomerService = false;
          }


          var scArray = filterScreenArray.filter(x => x.screenName == 'Supply Chain')[0]?.status;
          if (scArray != undefined) {
            this.isSupplychainVisible = scArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isSupplychainEditable = false;
            this.AppGlobals.SupplyChain_SubmitBySupplyChain = false;
            this.AppGlobals.MaterialReadyForDispatch = false;
          }

          var acArray = filterScreenArray.filter(x => x.screenName == 'Accounts')[0]?.status;
          if (acArray != undefined) {
            this.isAccountsVisible = acArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isAccountsEditable = false;
            this.AppGlobals.Accounts_ConfimrOrderByAccounts = false;
          }

          var OAArray = filterScreenArray.filter(x => x.screenName == 'OAGenerate')[0]?.status;
          if (OAArray != undefined) {
            this.isOAGenerationVisible = OAArray.indexOf(enquirystatus) > -1 ? true : false;
            this.AppGlobals.isOAGenerateEditable = false;
            this.AppGlobals.OrderAcknowledmentNo = false;
          }
        }
      }

    }
  }

  toggleAttchments(i) {

    this.EnquiryObj.Comments[i].showAttachments = !this.EnquiryObj.Comments[i].showAttachments;
  }
   
  getQuotationData() {
   this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'GetQuotationByEnquiryId', queryString).subscribe((data: any) => {
      this.QuotationDataForPO = data.QuotationObj;
      this.QuotationDataForPO.forEach(element => {
        var obj = new ProductModelPOValues();
        obj.ProductId = element.ProductId;
        obj.ProductModelName = element.ProductModelName;
        if (element.Variant1Name != undefined) obj.ProductModelName = obj.ProductModelName + ' (' + element.Variant1Name;
        if (element.Variant2Name != undefined) obj.ProductModelName = obj.ProductModelName + ', ' + element.Variant2Name;
        if (element.Variant3Name != undefined) obj.ProductModelName = obj.ProductModelName + ', ' + element.Variant3Name;
        if (element.Variant4Name != undefined) obj.ProductModelName = obj.ProductModelName + ', ' + element.Variant4Name;
        obj.ProductModelName = obj.ProductModelName + ')'
        obj.QUotationValue = element.FinalAmount;
        obj.ProductModelPOValue = "";
        this.EnquiryObj.POValues.push(obj);
      });
    });
  }

  getEnquiryDetailsById() {
   this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'GetById', queryString).subscribe((data: any) => {
      this.EnquiryObj.EnquiryDetails = data.EnquiryDetails;
      if (this.EnquiryObj.EnquiryDetails.QuotationRevisionNo != null && this.EnquiryObj.EnquiryDetails.QuotationRevisionNo > 0) {
        this.getRevisedQuotationFileNamelist(this.EnquiryObj.EnquiryDetails.QuotationNumber, this.EnquiryObj.EnquiryDetails.QuotationRevisionNo);
      }

      this.EnquiryObj.EnquiryDetails.EnquiryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.EnquiryDate).getDate());
      if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate).getDate());
      }
      if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != null) {
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new NgbDate(new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getFullYear(), new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getMonth() + 1, new Date(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate).getDate());
      }
      this.EnquiryObj.EnqCustomerDetails = data.CustomerDetails;
      this.EnquiryObj.RFQObject = data.RFQObject;
      this.EnquiryObj.CustomerServiceComments_Quotation = data.CustomerServiceComments_Quotation;
      this.EnquiryObj.CustomerServiceComments_Order = data.CustomerServiceComments_Order;
      this.EnquiryObj.SalesDirectorComments_Quotation = data.SalesDirectorComments_Quotation;

      this.EnquiryObj.SalesDirectorComments_Order = data.SalesDirectorComments_Order;
      this.EnquiryObj.Comments = data.Comments == undefined ? [] : data.Comments;
      this.EnquiryObj.POValues = data.POValues;

      this.WinLossDropdownData = data.ReasonEnquiryWinLossObj;
      this.WinLossDropdownDataForStatus = this.WinLossDropdownData.filter(x => x.Value == this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus);
      
      if (data.EnquiryDetails.RFQTermsAndCondition == "Standard" || data.EnquiryDetails.RFQTermsAndCondition == "") {

        if (data.TermsAndConditions.length == 0) {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Standard";
          this.EnquiryObj.TermsAndConditions = this.TnCStandardFromDropDown;
        } else {
          this.EnquiryObj.TermsAndConditions = data.TermsAndConditions;

        }
        this.isStandardCondition = true;
      } else if (data.EnquiryDetails.RFQTermsAndCondition == "Customized") {

        if (data.CustomTermsAndConditions.length == 0) {
          this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition = "Customized";
          this.EnquiryObj.CustomTermsAndConditions = this.TnCCustomizedFromDropDown;
          this.TnCCustomizedDropDownForEditRfq1 = this.TnCCustomizedFromDropDown;
        } else {
          this.EnquiryObj.CustomTermsAndConditions = data.CustomTermsAndConditions;
          this.TnCCustomizedDropDownForEditRfq2 = data.CustomTermsAndConditions;
        }

        this.isStandardCondition = false;
      }

      this.activityDetails = data.ActivityDetails;
      this.activityDetails.forEach(element => {
        element.ActivityDate = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());

        //element.ActivityDateISO = new NgbDate(new Date(element.ActivityDate).getFullYear(), new Date(element.ActivityDate).getMonth() + 1, new Date(element.ActivityDate).getDate());
      });

      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.EnquiryObj.RFQObject.length == 0) {
        var obj = new ProductDetails();
        this.EnquiryObj.RFQObject.push(obj);
      }

      //TO POPULATE A SINGLE ROW ON PAGE INIT
      if (this.activityDetails.length == 0) {
        var obj1 = new ActivityModel();
        obj1.ActivityDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
        obj1.uniqueid = this.makeRandom();
        this.activityDetails.push(obj1);
      }

      this.role = localStorage.getItem('UserRole');
      this.ScreenAccessSpecifier(localStorage.getItem('UserRole'), this.EnquiryObj.EnquiryDetails.Status);
      if(this.EnquiryObj.EnquiryDetails.segment!=undefined)
      {
       this.SubSegment = this.FinalSubSegment.filter(x=>x.SegmentId==this.EnquiryObj.EnquiryDetails.segment);
       this.Application= this.FinalApplication.filter(x=>x.SubSegmentId==this.EnquiryObj.EnquiryDetails.subSegment);
      }
     if(this.EnquiryObj.EnquiryDetails.segment==null|| this.EnquiryObj.EnquiryDetails.subSegment==null || this.EnquiryObj.EnquiryDetails.application==null)
      {
        this.EnquiryObj.EnquiryDetails.segment=undefined;
        this.EnquiryObj.EnquiryDetails.subSegment=undefined;
        this.EnquiryObj.EnquiryDetails.application=undefined;
      }

      this.EnquiryObj.EnquiryDetails.IsFetchFromKYC=data.EnquiryDetails.IsFetchFromKYC;
      this.previousEnqStatus = this.EnquiryObj.EnquiryDetails.Status;
      
    }, error => {
     this.updateLoaderEvent.emit(false);
    });

  }

  getRevisedQuotationFileNamelist(QuotationNumber: string, QuotationRevision: number) {
    let QTNNumber = QuotationNumber.replace("QTN/", "");
    QTNNumber = QTNNumber.split("/").join("_");
    let filename = "Quotation_" + QTNNumber;
    let pdffilename = filename.split('_R')[0];
    for (let i = 0; i < QuotationRevision; i++) {
      if (i == 0) {
        this.RevisedQuotationFileNameList.push(pdffilename + ".pdf")
      }
      else {
        this.RevisedQuotationFileNameList.push(pdffilename + "_R" + i + ".pdf")
      }
    }

  }

  makeRandom() {
    let text = "";
    var possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~";
    const lengthOfCode = 40;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getRFQProduct() {
   this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Enquiry", "GetEnquiryRFQDropdownData", null).subscribe((data: any) => {
      this.product = data;
      this.productDrpdnDetails.MDSOptions = data.MDS;
      this.productDrpdnDetails.SalesPrice = data.Salesprice;
      this.productDrpdnDetails.EstimatedProductValue = data.EstimatedProductValue;
      this.IndustrySegment = data.segment;     
      this.SubSegment = [];
      this.Application= [];   
      this.FinalIndustrySegment = data.segment;
      this.FinalSubSegment = data.subSegment;
      this.FinalApplication= data.application;
        data.TermsAndConditions.forEach(element => {
        var obj = new TermsAndCondition();
        obj.TnCId = element.Id;
        obj.TnCName = element.Name;
        obj.TnCValue = element.Value;
        this.TnCStandardFromDropDown.push(obj);
      });

      this.demoObject = data.TermsAndConditions;

      this.demoCTCObject = data.CustomTermsAndCondition;
     
      for (let i = 0; i < data.CustomTermsAndCondition.length; i++) {
        var obj1 = new CustomTermsAndCondition();
        obj1.StandardTC = data.CustomTermsAndCondition[i].StandardTC.Id;
        obj1.StandardTCName = data.CustomTermsAndCondition[i].StandardTC.Name;
        // this.standardTC.push(data.CustomTermsAndCondition[i].StandardTC);

        for (let j = 0; j < data.CustomTermsAndCondition[i].CustomValues.length; j++) {
          obj1.CustomValues.push(data.CustomTermsAndCondition[i].CustomValues[j]);
        }
        this.TnCCustomizedFromDropDown.push(obj1);
      }

      this.demoCTCObject = data.CustomTermsAndCondition;
      
      //this.showLoader = false;
    });
  }

  getProductModel(Id, ind) {
    this.EnquiryObj.RFQObject[ind].ProductLineValidation = false;
    this.product.Product.forEach((element, index) => {
      if (element.ProductLine.Id == Id) {
        this.productDrpdnDetails.ProductModel = element.ProductModels;
        this.newRFQDetails.ProductModelList = element.ProductModels;
        this.EnquiryObj.RFQObject[ind].isProductLineChangeClick = true;
        this.EnquiryObj.RFQObject[ind].ProductModelList = this.productDrpdnDetails.ProductModel;
      }
    });
  }
  OnProductModelChange(index) {
    this.EnquiryObj.RFQObject[index].ProductModelValidation = false;
  }
  OnQuantityChange(index) {
    if (this.EnquiryObj.RFQObject[index].Quantity < 1) {
      //alert('Quantity cannot be 0 or negative.');
      this.toastr.info('Quantity cannot be 0 or negative.');
      this.EnquiryObj.RFQObject[index].Quantity = null;
      return;
    } else if (this.EnquiryObj.RFQObject[index].Quantity % 1 != 0) {
     // alert('Please enter the valid Quantity');
     this.toastr.info('Please enter the valid Quantity');

      this.EnquiryObj.RFQObject[index].Quantity = null;
      return;
    }
    this.EnquiryObj.RFQObject[index].QuantityValidation = false;
  }
  OnMDSOptionChange(index) {
    this.EnquiryObj.RFQObject[index].MDSOptionValidation = false;
  }

  deleteAttachDocument(index, attachemntType) {
    switch (attachemntType) {
      case "MDS":
        this.EnquiryObj.RFQObject[index].MDSAttachment = [];
        this.EnquiryObj.RFQObject[index].isMDSAttachment = false;
        break;
      case "ITC":
        this.EnquiryObj.RFQObject[index].ITCAttachment = [];
        this.EnquiryObj.RFQObject[index].isITCAttachment = false;
        break;
      case "Customer":
        this.EnquiryObj.RFQObject[index].CustomerAttachment = [];
        this.EnquiryObj.RFQObject[index].isCustomerAttachment = false;
        break;
      case "Weiss":
        this.EnquiryObj.RFQObject[index].WeissAttachment = [];
        this.EnquiryObj.RFQObject[index].isWeissAttachment = false;
        break;
    }

  }

  getCustomerList() {
   this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Customer", "GetAll", null).subscribe((data: any) => {
      this.CustomerList = data;

      this.updateLoaderEvent.emit(false);
    });
  }

  getEnquirySourceDetails() {
   this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Enquiry", "GetEnquiryDropdownData", null).subscribe((data: any) => {
      this.enquirySource = data.EnquirySource;
     this.updateLoaderEvent.emit(false);
    });
  }

  getProductDetails() {
   this.updateLoaderEvent.emit(true);
    this.commonService.GetData("Product", "GetRFQProductDetails", null).subscribe((data: any) => {
      this.CustomerList = data;
      //this.showLoader = false;
    });
  }

  AvoidSpace(event: any) {
    if ((event.which === 46 || event.which === 32) && !event.target.value.length) {
      event.preventDefault();
    }
  }

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((this.charCode == 46 || this.charCode == 45 || this.charCode == 43) && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  openCSComments() {
    this.isCustomerCommentVisible = !this.isCustomerCommentVisible;
  }

  backtoList() {
    // this._location.back();
    if (this.previousUrl == null || this.previousUrl == "" || this.previousUrl == undefined) {
      this.router.navigate(['/Enquiry']);
    }
    else if (this.previousUrl.includes("PendingEnquiry")) {
      this.router.navigate(['/PendingEnquiry']);
    }
    else {
      this.router.navigate(['/Enquiry']);
    }

  }

  onEnquiryConfirmationStatusChange() {
    this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason = null;
    this.WinLossDropdownDataForStatus = this.WinLossDropdownData.filter(x => x.Value == this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus);
    this.EnquiryObj.EnquiryDetails.EnquiryConfirmReason = this.WinLossDropdownDataForStatus[0].Id;
    if (this.EnquiryObj.EnquiryDetails.EnquiryConfirmStatus == "Lost") {
      this.isPoValueDisable = true;
      this.EnquiryObj.EnquiryDetails.POValue = "0";
    } else {
      this.isPoValueDisable = false;
      this.EnquiryObj.EnquiryDetails.POValue = undefined;
    }
  }

  getMultipleFileDetails(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      let isValidFile = true;
      if (((file.size) / 1000000) <= 5) {

        isValidFile = this.allowedFileType.includes(file.type);
        if (!isValidFile) {
          this.toastr.warning("Invalid file attached!");
          //event.target.value = null;
          isValidFile = false;
        } else if (file.name.length > 50) {
          this.toastr.warning("The file name with more than 50 characters are not allowed.");
          isValidFile = false;
        }
      }
      else {
        this.toastr.warning("File size must be less than or equal to 5MB!");
        //event.target.value = null;
        isValidFile = false;
      }
      if (isValidFile) {
        switch (event.target.name) {
          case "quotefiles":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "mafile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "ceofile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
          case "maofile":
            if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments == undefined || this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length == 0) {
              this.EnquiryObj.EnquiryDetails.QuoteGenAttachments = [];
            }
            this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.push(file);
            break;
        }
      }
    }
  }

  removeQuoteGenAttachments(index) {
    this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.splice(index, 1);
    this.myInputVariable.nativeElement.value = "";

  }
  removeQuoteGenAttachmentsDB(index) {
    this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FilePath);
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FileName = null;
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray[index].FilePath = null;
    this.EnquiryObj.EnquiryDetails.QuoteAttachmentsArray.splice(index, 1);
  }
  removeBOMAttachments(flag) {
    if (flag == 1) {
      this.EnquiryObj.RemoveFiles.push(this.EnquiryObj.EnquiryDetails.BOM_filepath);
    }
    this.isBOMAttachment = false;
    this.EnquiryObj.EnquiryDetails.BOMAttachment = [];
    this.EnquiryObj.EnquiryDetails.BOM_filename = null;
    this.EnquiryObj.EnquiryDetails.BOM_filepath = null;
  }

  removeInvoiceAttachments() {
    this.isInvoiceAttachment = false;
    this.myInputVariable.nativeElement.value = "";
    this.EnquiryObj.EnquiryDetails.InvoiceAttachment = undefined;
  }
  GenerateOANumber() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Once OA Number Generated, can't be altered. Do you want to continue?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
       this.updateLoaderEvent.emit(true);
        let queryString = "";
        queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
        this.commonService.GetData('Enquiry', 'GenerateOANo', queryString).subscribe((data: any) => {

          this.EnquiryObj.EnquiryDetails.OrdAcknowledgeNumber = data;
          this.isOAGenerated = true;
         this.updateLoaderEvent.emit(false);
          this.toastr.success('Order Acknowledgement Number generated successfully!');

        }, error => {
          this.EnquiryObj.EnquiryDetails.OrdAcknowledgeNumber = undefined;
          this.toastr.error('Failed to Order Acknowledgement Number!');
          this.isOAGenerated = false;
         this.updateLoaderEvent.emit(false);
        });
      }
    });
  }
  onStepChange(data) {

    if (this.userid == this.EnquiryObj.EnquiryDetails.Initiator_Id && data.previouslySelectedIndex == 0 && this.activityDetails[0].Activity != "") {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Please make sure that you have saved the activity details. Do you want to continue?"
        }
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
        } else {
          this.stepper.selectedIndex = data.previouslySelectedIndex;
        }
      });
    }
  }
  SetStatus(rule, myform: any) {
    this.PreviousStatus = this.EnquiryObj.EnquiryDetails.Status;
    this.EnquiryObj.EnquiryDetails.Status = rule[0].workflowNextStatusId;
    this.AddEnquiry();

  }

  AddEnquiry() {
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='EnquiryAdded')[0]?.Pk_Id
      || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSaveAsDraft')[0]?.Pk_Id
      || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbySalesEngg')[0]?.Pk_Id) {
    
      this.checkRFQGridValidation();
      if (!this.IsRFQDataValid) {
        return;
      }
      if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == 'Standard')
      {
        let tnc = this.EnquiryObj.TermsAndConditions.filter(x => (x.TnCValue == undefined || x.TnCValue == null || x.TnCValue == ''));
        if (tnc.length > 0) {
          return;
        }
        this.EnquiryObj.CustomTermsAndConditions=null;
      }
      
      if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == 'Customized')
      {
        let ctnc = this.EnquiryObj.CustomTermsAndConditions.filter(x => (x.CustomValues == undefined || x.CustomValues == null))
        if (ctnc.length > 0) {
          return;
        }   
        this.EnquiryObj.TermsAndConditions=null;
      }      
    
      
    }
    //Dont proceed if the BOM attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='SendBOMandProposal')[0]?.Pk_Id || this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='QuotationSenttoCustomerService')[0]?.Pk_Id ||
      this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQsentbyL1toL2')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.BOMAttachment == null || this.EnquiryObj.EnquiryDetails.BOMAttachment == undefined || this.EnquiryObj.EnquiryDetails.BOMAttachment.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.BOM_filename == null || this.EnquiryObj.EnquiryDetails.BOM_filename == undefined || this.EnquiryObj.EnquiryDetails.BOM_filename == "")) {
        this.toastr.info('BOM attachment is required.');
        return;
      }
    }

    // //Dont proceed if the Po attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='EnquiryOrderConfirmedbySalesEngg')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.PO_filename == null || this.EnquiryObj.EnquiryDetails.PO_filename == undefined || this.EnquiryObj.EnquiryDetails.PO_filename == "")) {
          this.toastr.info('PO attachment is required.');
        return;
      }
    }

    // //Dont proceed if the Invoice attachment is not present
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='AccountsConfirmInvoiceNo')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.InvoiceAttachment == null || this.EnquiryObj.EnquiryDetails.InvoiceAttachment == undefined || this.EnquiryObj.EnquiryDetails.InvoiceAttachment.length == 0) &&
        (this.EnquiryObj.EnquiryDetails.Invoice_filename == null || this.EnquiryObj.EnquiryDetails.Invoice_filename == undefined || this.EnquiryObj.EnquiryDetails.Invoice_filename == "")) {
        this.EnquiryObj.EnquiryDetails.Status = this.enquiryStatusList.filter(x=>x.Status=='MaterialReadyForDispatch')[0]?.Pk_Id;
        this.toastr.info('Invoice attachment is required.');
        return;
      }
    }


    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='EnquiryOrderConfirmedbySalesEngg')[0]?.Pk_Id) {
      if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length==0)) {
        return;
      }
    }


    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id) {
      if (this.isPOAttacmentDelete) {
        if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0)) {
          return;
        }
      }

    }

    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
      if (this.isPOAttacmentDelete) {
        if ((this.EnquiryObj.EnquiryDetails.POAttchments == undefined || this.EnquiryObj.EnquiryDetails.POAttchments == null || this.EnquiryObj.EnquiryDetails.POAttchments.length == 0)) {
          return;
        }
      }
    }
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='AccountsConfirmInvoiceNo')[0]?.Pk_Id) {
      if (this.EnquiryObj.EnquiryDetails.InvoiceAttachment == undefined) {
        this.EnquiryObj.EnquiryDetails.Status = this.enquiryStatusList.filter(x=>x.Status=='MaterialReadyForDispatch')[0]?.Pk_Id
        return;
      }
    }
    if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id||
      this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
      let sum: number = 0;
      this.EnquiryObj.POValues.forEach(element => {
        element.ProductModelPOValue = (element.ProductModelPOValue == null || element.ProductModelPOValue == '' || element.ProductModelPOValue == undefined)
          ? '0' : element.ProductModelPOValue;
        sum = sum + parseInt(element.ProductModelPOValue);
        element.QUotationValueValidation = false;
      });
      if (sum.toString() != this.EnquiryObj.EnquiryDetails.POValue) {
        document.getElementById("povalue0").focus();
        this.toastr.info('Total PO value and product wise PO values are not matching.');
        this.EnquiryObj.EnquiryDetails.Status = this.PreviousStatus;
        this.UpdatePO = false;
        return;
      }
    }

    setTimeout(() => this.openPopup());
  }
  openPopup() {
    var confimrmsg = this.AppGlobals.successMessages.filter(x => x.id == this.EnquiryObj.EnquiryDetails.Status)[0];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: confimrmsg.confirmmsg
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
       this.updateLoaderEvent.emit(true);
        const frmData = new FormData();
        this.EnquiryObj.RFQObject.forEach(item => {
          item.IsRowDeleted = false;
        });
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.RequestedDeliveryDateISO = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day))).toDateString();
        }
        if (this.EnquiryObj.EnquiryDetails.EnquiryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.EnquiryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.EnquiryDate.year, this.EnquiryObj.EnquiryDetails.EnquiryDate.month, this.EnquiryObj.EnquiryDetails.EnquiryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.year, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.month, this.EnquiryObj.EnquiryDetails.TentativeDeliveryDate.day)));
        }
        if (this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate != undefined) {
          this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = new Date(this.convertDate(new NgbDate(this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.year, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.month, this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate.day)));
        }

        // if (this.EnquiryObj.EnquiryDetails.RFQTermsAndCondition == "Customized") {
        //   this.EnquiryObj.TermsAndConditions = null;
  
        //   if(this.QGRFQEditable == true)
        //   {
        //     this.EnquiryObj.CustomTermsAndConditions;
            
        //   }else{
        //   this.EnquiryObj.CustomTermsAndConditions = this.custtermscond;
        //   }
        // }

        let EnquiryObj = JSON.stringify(this.EnquiryObj);
        frmData.append("EnquiryModel", EnquiryObj);


        if (this.EnquiryObj.EnquiryDetails.QuoteGenAttachments != null) {
          for (let i = 0; i < this.EnquiryObj.EnquiryDetails.QuoteGenAttachments.length; i++) {
            frmData.append("QuoteGenAttachments[" + i + "]", this.EnquiryObj.EnquiryDetails.QuoteGenAttachments[i]);
          }
        }
        for (let i = 0; i < this.EnquiryObj.RFQObject.length; i++) {
          if (this.EnquiryObj.RFQObject[i].MDSAttachment != undefined) {
            frmData.append("MDSAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].MDSAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].ITCAttachment != undefined) {
            frmData.append("ITCAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].ITCAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].CustomerAttachment != undefined) {
            frmData.append("CustomerAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].CustomerAttachment[0]);
          }
          if (this.EnquiryObj.RFQObject[i].WeissAttachment != undefined) {
            frmData.append("WeissAttachment[" + i + "]", this.EnquiryObj.RFQObject[i].WeissAttachment[0]);
          }
        }
        if (this.EnquiryObj.EnquiryDetails.BOMAttachment) {
          frmData.append("BOMAttachment", this.EnquiryObj.EnquiryDetails.BOMAttachment[0]);
        }
        if (this.EnquiryObj.EnquiryDetails.InvoiceAttachment) {
          frmData.append("InvoiceAttachment", this.EnquiryObj.EnquiryDetails.InvoiceAttachment[0]);
        }
        if (this.EnquiryObj.EnquiryDetails.POAttchments) {
          frmData.append("POAttchments", this.EnquiryObj.EnquiryDetails.POAttchments[0]);
        }
        
        this.commonService.PostData("Enquiry", "Add", frmData, null).subscribe((data: any) => {
          this.customerDrpdnDetails = data;
          this.EnquiryObj.EnquiryDetails.EnquiryId = data;
          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OANoGenerated')[0]?.Pk_Id) {
            this.toastr.success("OA Number generated successfully");
          }
          else {
            this.toastr.success(confimrmsg.successmsg);
          }

         this.updateLoaderEvent.emit(false);
          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQAcceptedbyL1')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='QuotationRevision')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyL1')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyL2')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyL3')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='RFQSubmittedbyCustomerService')[0]?.Pk_Id ||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OANoGenerated')[0]?.Pk_Id) {
            // this.router.navigate(['/EditViewEnquiry/' + this.EnquiryObj.EnquiryDetails.EnquiryId]);
            window.location.reload();
          } else {
            this.router.navigate(['/Enquiry']);
            //this.backtoList();
          }
        }, error => {
          this.toastr.error(confimrmsg.errormsg);
         this.updateLoaderEvent.emit(false);
        });
      } else {
        if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='QuotationRevision')[0]?.Pk_Id) {
          this.chosenItem = "2";
          this.quoterevcomments = this.EnquiryObj.EnquiryDetails.Comments;
          this.EnquiryObj.EnquiryDetails.Comments = "";
        }
        else {

          if (this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_SalesDirector')[0]?.Pk_Id||
            this.EnquiryObj.EnquiryDetails.Status == this.enquiryStatusList.filter(x=>x.Status=='OrderConfirmedbyCustomerServiceSendSO')[0]?.Pk_Id) {
            this.UpdatePO = false;
          }
          if (this.PreviousStatus > 0) {
            this.EnquiryObj.EnquiryDetails.Status = this.PreviousStatus;
          }

        }

      }
    });

  }
  povalueChange(i) {
    var povalue: number = parseFloat(this.EnquiryObj.POValues[i].ProductModelPOValue);
    if (povalue == undefined || povalue == null) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    } else if (povalue < 0) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    } else if (povalue % 1 != 0) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    } else if (this.EnquiryObj.POValues[i].ProductModelPOValue.toString().includes(".")) {
      this.EnquiryObj.POValues[i].QUotationValueValidation = true;
      this.IsPODataValid = false;
    }
    else {
      this.EnquiryObj.POValues[i].QUotationValueValidation = false;
    }
  }

  checkRFQGridValidation() {
    var inde = 0;
    this.IsRFQDataValid = true;
    this.EnquiryObj.RFQObject.forEach((element, index) => {
      if (element.ProductLine == undefined || element.ProductLine == null || element.ProductLine == 0) {
        document.getElementById("productline" + index).focus();
        element.ProductLineValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.ProductModel == undefined || element.ProductModel == null || element.ProductModel == 0) {
        document.getElementById("productmodel" + index).focus();
        element.ProductModelValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.Quantity == undefined || element.Quantity == null || element.Quantity == 0) {
        document.getElementById("quantity" + index).focus();
        element.QuantityValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.MDSOption == undefined || element.MDSOption == null || element.MDSOption == 0) {
        document.getElementById("mdsoption" + index).focus();
        element.MDSOptionValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.SalesPrice == undefined || element.SalesPrice == null || element.SalesPrice == 0) {
        document.getElementById("salesprice" + index).focus();
        element.SalesPriceValidation = true;
        this.IsRFQDataValid = false;
      }
      if (element.SalesDiscount == undefined || element.SalesDiscount == null) {
        document.getElementById("salesdiscount" + index).focus();
        element.SalesDiscountValidation = true;
        this.IsRFQDataValid = false;
      }

    });

  }

  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }

  downloadPDF() {
    const url = AppGlobals.baseAppUrl + 'uploads/pdf/' + this.EnquiryObj.EnquiryDetails.QuotationPdf_filename;
    window.open(url, "download");
  }

  DownloadPDFfromServer(Filename: any) {
    this.commonService.gereatePDF('Enquiry', 'DownloadPDF', Filename).subscribe((data: any) => {

      importedSaveAs(data, Filename);
    });
  }

  checkattachment() {
    this.rfqattpresent = false;
    this.EnquiryObj.RFQObject.forEach(element => {
      if (!(element.MDS_filename == null || element.MDS_filename == undefined || element.MDS_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.ITC_filename == null || element.ITC_filename == undefined || element.ITC_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Weiss_filename == null || element.Weiss_filename == undefined || element.Weiss_filename == '')) {
        this.rfqattpresent = true;
      }
      if (!(element.Customer_filename == null || element.Customer_filename == undefined || element.Customer_filename == '')) {
        this.rfqattpresent = true;
      }
    });
  }

  DownloadAllAttachments() {
    this.checkattachment();
    if (!this.rfqattpresent) {
      this.toastr.info('No attachment present.');
      return;
    }
   this.updateLoaderEvent.emit(true);
    let queryString = "";
    queryString = "id=" + this.EnquiryObj.EnquiryDetails.EnquiryId;
    this.commonService.GetData('Enquiry', 'Download', queryString).subscribe((data: any) => {
      var url = AppGlobals.ZipDownloadUrl + data + '.zip';
      window.open(url, "_blank");
     this.updateLoaderEvent.emit(false);
    }, error => {
     this.updateLoaderEvent.emit(false);
    });
  }
  
  public saveAsFile(pdfurl: any, fileName: string): void {
    //const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(this.fileDownloadPath + pdfurl, fileName);
  }

  onSelectDate(date: NgbDateStruct, dateType: string) {
    if (date != null) {
      if (dateType == 'RequestedDeliveryDate') {
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = date;
        this.EnquiryObj.EnquiryDetails.RequestedDeliveryDate = this.ngbDateParserFormatter.format(date);
      }
      else if (dateType == 'EnquiryDate') {
        this.EnquiryObj.EnquiryDetails.EnquiryDate = date;
        this.EnquiryObj.EnquiryDetails.EnquiryDate = this.ngbDateParserFormatter.format(date);
      }

    }
  }

  getAllWorkFlow() {
    this.commonService.GetData("Workflow", "GetAll", null).subscribe((data: any) => {
    
      if(data!=null){
        var workflowData = data;
        this.workflowId=workflowData.filter(x=>x.document_type_code == "CEO_APPROVAL")[0].workflow_id;
        this.getWorkflowActions(this.workflowId);        
      }
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  getWorkflowActions(workflowId)
  {    
    let queryString = "";
    queryString = "enquiryId=" + this.EnquiryObj.EnquiryDetails.EnquiryId +"&workflowId=" + workflowId ;
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("EnquiryWorkflowTransitionRules","GetTransitionRulesByStatus",queryString).subscribe((data: any) => {
      this.workflowActionList=data;
      this.cd.detectChanges();
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  getEnquiryStatus()
  {
    this.commonService.GetData("Enquiry","GetEnquiryStatus", null).subscribe((data: any) => {
      this.enquiryStatusList=data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  OnWorkflowAction(myform10:NgForm,workflowActionId:number)
    {    
    var rule= this.workflowActionList.filter(x=>x.workflowActionId==workflowActionId);
    
      myform10.control.markAllAsTouched();
      if(myform10.invalid)
      {
        this.toastr.error("Please fill required information");
        return;
      }  
      this.SetStatus(rule,myform10);  
  }

  getUserRoles(){
    this.commonService.GetData("Account", "GetAll", null).subscribe((data: any) => {
      this.UserRolesData = data;
    
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  getSendbackWorkfow()
  {
    this.updateLoaderEvent.emit(true);
    this.commonService.GetData("EnquiryWorkflowTransitionRules","GetWorkflowSendbackRule", null).subscribe((data: any) => {
      this.sendbackRules=data;
      this.updateLoaderEvent.emit(false);
    }, error => {
      this.updateLoaderEvent.emit(false);
    });
  }

  rule:EnquirySendBack;
  SendBack(myform10: NgForm)
  {      

    // myform10.control.markAllAsTouched();
    // if (myform10.invalid) {
    //   this.toastr.error("Please fill required information");
    //   return;
 // }

    var quotationRule= this.sendbackRules.filter(x=>x.WorkflowId==this.workflowId);

    if(quotationRule[0].NextStatus != null)
    {
      this.rule = quotationRule[0];
    }
    else
    {
      if(this.previousEnqStatus == this.enquiryStatusList.filter(x=>x.Status=='QuotationSentforMngmtApproval_CEO')[0].Pk_Id)
      {
        this.rule = quotationRule[0];
        this.rule.NextStatus = this.enquiryStatusList.filter(x=>x.Status=='QuotationSentforMngtApproval_SalesDirector')[0].Pk_Id
      }
      else if(this.previousEnqStatus == this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_CEO')[0].Pk_Id)
      {
        this.rule = quotationRule[0];
        this.rule.NextStatus = this.enquiryStatusList.filter(x=>x.Status=='OrdersentforMngmtApproval_SalesDirector')[0].Pk_Id;
      }
      
      
    }

    const modalRef = this.modalService.open(SendBackCommentComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });

    var sendBackObj = {
      EnquiryId: this.EnquiryObj.EnquiryDetails.EnquiryId,
      workflowId: this.workflowId,
      finalrule: this.rule
    }
    modalRef.componentInstance.sendBackObj = sendBackObj;
    modalRef.result.then((result) => {
      this.closeResult = `Closed with:${result}`;
      if (this.closeResult !== 'Closed with:Close click') {
        this.ngOnInit();
      }
    });
   // this.rule = quotationRule[0];
    // this.rule.EnquiryId=this.EnquiryObj.EnquiryDetails.EnquiryId;
    // this.rule.Comment = this.EnquiryObj.EnquiryDetails.Comments;
   
    // let rules = JSON.stringify(this.rule);
    // const frmData = new FormData();
    // frmData.append("rule", rules);
    // this.commonService.PostData("EnquiryWorkflowTransitionRules","SetStatus",frmData,"",null).subscribe(data=>{
    //     this.toastr.success("Enquiry sent one step back");
    //     window.location.reload();
    // });    
  }
  
}

