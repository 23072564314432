import { query } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ProductMatrixModel } from 'src/app/Model/ProductMatrixModel';
import { ProductSeries } from 'src/app/Model/ProductModel';
import { AccessPermissionModel, DefaultViewModel, ElementTypeList, WorkFlowScreenAccessModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { CommonService } from 'src/app/Service/common.service';
import { UserRoles } from 'src/app/UserRoles';



@Component({
  selector: 'app-edit-view-work-flow-screen-access',
  templateUrl: './edit-view-work-flow-screen-access.component.html',
  styleUrls: ['./edit-view-work-flow-screen-access.component.scss']
})
export class EditViewWorkFlowScreenAccessComponent implements OnInit {
  @Input()
  showLoader: boolean = false;
  WorkFlowScreenData:WorkFlowScreenAccessModel[]=[];
  UserRole: any;
  WorkFlowScreenAccessEditable: boolean = false;
  isEditable: boolean = true;
  FormStatus: string = "View";
  AppRoles = UserRoles;
  Roles: any[] = [];
  isMenu:boolean=true;
  isScreen:boolean=true;
  isButton:boolean=true;
  isField:boolean=true;
  accesspermissioninfo:any[]=[];
  testaccessinfo:any[]=[];
  DefaultScreenViewAccess:any[]=[];
  Defaultobj:DefaultViewModel=new DefaultViewModel();
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  elementTypeObj:ElementTypeList[]=Array<ElementTypeList>();
  ArrayofElementType:ElementTypeList[]=Array<ElementTypeList>();
  
  AccesspermissionForRole:any[]=[];

  
  constructor(private toastr: ToastrService, private commonService: CommonService, private router: Router,private cd: ChangeDetectorRef,
    public dialog: MatDialog,private route: ActivatedRoute,private titleService: Title,private accessservice: AccessPermissionService) {
  }

  ngOnInit(): void {
    this.DefaultScreenViewAccess = this.Defaultobj.WorkflowDefaultViewAccessArray;
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   // this.WorkFlowScreenAccessEditable = AppGlobals.WorkFlowScreenAccessEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
   // this.getAllWorkFlowScreen();
    this.getAllAccessPermission();
    this.GetAllElementType();
    this.getAllRoles();
  }

  getAllWorkFlowScreen()
  {
    this.showLoader = true;
    this.commonService.GetData("WorkFlowScreenAccess", "GetAll", null).subscribe((data: any) => {
    this.WorkFlowScreenData=data;


    /**********************************Add Default View Access******************************** */

    // this.WorkFlowScreenData.forEach(element => {
    
    //   let DefaultData = this.getDefaultAccessbyRoleID(element.RoleId);

    //   element.isEnquiryForm_Default = DefaultData.isEnquiryForm_Default;
    //   element.isRFQ_Default = DefaultData.isRFQ_Default;
    //   element.isQuoteGeneration_Default = DefaultData.isQuoteGeneration_Default;
    //   element.isManagementApproval_Default = DefaultData.isManagementApproval_Default;
    //   element.isCEOApproval_Default = DefaultData.isCEOApproval_Default;
    //   element.isEnquiryConfirmation_Default = DefaultData.isEnquiryConfirmation_Default;
    //   element.isSOGeneration_Default = DefaultData.isSOGeneration_Default;
    //   element.isOrderAcknowledgement_Default = DefaultData.isOrderAcknowledgement_Default;
    //   element.isSupplyChain_Default = DefaultData.isSupplyChain_Default;
    //   element.isAccounts_Default = DefaultData.isAccounts_Default;
      
    // });

    /**********************************End Add Default View Access******************************** */

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  EditDetails() {
    if(this.isEditable)
    {
      this.isEditable = false;
      // window.scroll({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });
      this.FormStatus = "Edit";
      this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    }
    else{
      this.isEditable = true;
      // window.scroll({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });
      this.FormStatus = "View";
      this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    }
  
  }

  getWorkFlowScreenAccessbyRoleId()
  {
    this.showLoader = true;
    let queryString = "";
    queryString = "RoleId=" + this.UserRole;

    this.commonService.GetData('WorkFlowScreenAccess', 'GetByRoleId', queryString).subscribe((data: any) => {
      let Workflowscreens= data.toString();
      localStorage.setItem("WorkFlowRoleWiseScreenAccess", Workflowscreens);
      
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });
  }

  getDefaultAccessbyRoleID(RoleId:any)
  {
   return  this.DefaultScreenViewAccess.find(x => x.role === RoleId);
  }

  Cancel()
  {
    this.EditDetails();
    this.getAllWorkFlowScreen();

  }

  onSubmit(myform:any)
  {
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to update Access and Permission?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.showLoader = true;
      if (dialogResult) {
        const newAcessPermission = Object.values(this.accesspermissioninfo).reduce((acc, permissions) => {
          Object.values(permissions).forEach(val => {
            acc.push(...(val as any[]));
          });
          return acc;
        }, []);
     
        this.commonService.PostData("ElementAccessPermission", "Update", newAcessPermission, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success("Access Permission updated successfully!");
            this.isEditable = true;
            this.FormStatus = "View";
            this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
            this.getAllAccessPermission();        
             this.GetAllElementType();
             this.getAllRoles();
            this.refreshAccessPermission();
            //this.isMenu=true;this.isScreen=true;this.isButton=true;this.isField=true;
            this.showLoader = false;
          }
          else {
            this.toastr.error("Failed to update Access Permission!");
            this.showLoader = false;
          }
        }, error => {
          this.toastr.error("Failed to update Access Permission!");
          this.showLoader = false;
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      else {
        this.showLoader = false;
      }
    });
  }

   getAllAccessPermission()
   {
    this.showLoader = true;
     let querstring=null;
     this.commonService.GetData("AccessPermission","GetAll",querstring).subscribe((data: any) => {
      this.convertdataintoobj(data);
      this.cd.detectChanges();
      this.showLoader = false;

     }, error => {
       this.toastr.error("Failed to load data!");
       
     });
  
   }

   convertdataintoobj(data:any)
   {
    this.showLoader = true
    this.commonService.convertArrayToObject(data).subscribe((datalist:any)=>
   {
     this.accesspermissioninfo=datalist;
     this.cd.detectChanges();
     this.testaccessinfo=this.accesspermissioninfo;
     console.log("datalist==>",this.accesspermissioninfo);
     this.showLoader = true
   });

   this.showLoader = false;
   
   }
   
  GetAllElementType()
  {
    let querstring="";
    this.commonService.GetData("ElementTypeForPermission","GetListOfElementType",querstring).subscribe((data: any) => {
      this.elementTypeObj=data;
      this.ArrayofElementType=this.elementTypeObj;
      
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;

    });
  }

  OnElementTypechange(ElementType:string)
  {

    if(ElementType=="Menu"){
      this.isMenu=true;this.isScreen=false;this.isButton=false;this.isField=false;
    }
    if(ElementType=="WorkFlowScreen"){
      this.isScreen=true; this.isMenu=false;this.isButton=false;this.isField=false;
    }
    if(ElementType=="Button"){
      this.isScreen=false; this.isMenu=false;this.isButton=true;this.isField=false;
    }
    if(ElementType=="Field"){
      this.isField=true; this.isScreen=false; this.isMenu=false;this.isButton=false;
    }
    if(ElementType==""){
      this.isField=true; this.isScreen=true; this.isMenu=true;this.isButton=true;
    }

}

  getAllRoles() {
    this.showLoader = true;
    this.commonService.GetData("Account", "GetAllRoles", null).subscribe((data: any) => {
      this.Roles = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  OnSelectRole(RoleName:any)
  {
    if(RoleName!="")
    {
      const filteredAccountObject = {};
      const filteredKeys = Object.keys(this.testaccessinfo).filter(key => key === RoleName);
    
      filteredKeys.forEach(key => {
          filteredAccountObject[key] = this.testaccessinfo[key];
          this.accesspermissioninfo=[];
          this.accesspermissioninfo[key] = filteredAccountObject[key];
          console.log("Modified one ",this.accesspermissioninfo);
      });
    }else{
      this.accesspermissioninfo=this.testaccessinfo;
    }
   
  }
  
// converttoArray()
// {
//   const newAcessPermission = Object.values(this.accesspermissioninfo).reduce((acc, permissions) => {
//     Object.values(permissions).forEach(val => {
//       acc.push(...(val as any[]));
//     });
//     return acc;
//   }, []);
  
//   console.log("------->",newAcessPermission);
// }
  
getAccess()
  {
    
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.WorkFlowScreenAccessEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isWorkflowScreenAccess')[0]?.IsEditable;
      
    });
 
  }

  refreshAccessPermission()
  {
    this.accessservice.GetAll().subscribe((data:any)=>
    {
        this.accesspermissionobj=data;
        localStorage.setItem("accesspermissionobj", JSON.stringify(this.accesspermissionobj));
        console.log("Localstorage",localStorage.getItem("accesspermissionobj"));
        
     });
  }

}
