export class CustomerModel {
  
    BasicCustomerInformationModel: BasicCustomerInformationModel = new BasicCustomerInformationModel();
    KeyManagementPersonDetails: ContactPersonDetailsModel = new ContactPersonDetailsModel();
    FirstContactTechnicalPersonDetails: ContactPersonDetailsModel = new ContactPersonDetailsModel();
    FirstContactCommercialPersonDetails: ContactPersonDetailsModel = new ContactPersonDetailsModel();
    FinancialDetails: FinancialDetailsModel = new FinancialDetailsModel();
    WINWSEAContactDetails: WINWSEAContactDetailsModel = new WINWSEAContactDetailsModel();
    CustomerProfile: CustomerProfileModel = new CustomerProfileModel();
    CompanyTurnover: CompanyTurnoverModel = new CompanyTurnoverModel();
    PlantInformationWithGSTNo: PlantInformationWithGSTNoModel [] = [];
    TopManagement: TopManagementModel [] = [];
    WeissBusiness: WeissBusinessModel = new WeissBusinessModel();
    OpportunitiesforWeiss: OpportunitiesforWeissModel = new OpportunitiesforWeissModel();
    
}

export class BasicCustomerInformationModel {
    Id: number;
    CompanyName: string = "";
    AddressHeadquaters: string = "";
    AddressPlant: string = "";
    AddressEngineeringDesign: string = "";
    City: string = "";
    State: string = "";
    PINCode: string = "";
    CountryName: string = "";
    Country: number = null;
    MailAddress: string = "";
    WebSite: string = "";
    GlobalParent: string = "";
    HeadquarterInfo: string = "";
    OrganogramFileName: string = "";
    OrganogramFilePath: string = "";
    CustomerCode:string;
    CurrentVisibleCompetitorsNamewithUSP: string = "";
    KYC2FileName: string = "";
    KYC2FilePath: string = "";
    KYC3FileName: string = "";
    KYC3FilePath: string = "";
    //for costomer group name & code
    CustomergroupId: number = null;
    CustomergroupName: string = "";
    CustomergroupCode: string = "";
    leadId:number=0;
}

export class ContactPersonDetailsModel {
    Id: number;
    Name: string = "";
    ContactNo: string = "";
    MailAddress: string = "";
    DirectlineNo: string = "";
    DigitalSkypeID: string = "";
    DigitalOtherID: string = "";

}

export class FinancialDetailsModel {
    Id: number;
    AuthorisedShareCapital: string = "";
    BankersDetails: string = "";
    UltimateShareholder: string = "";
    PercentageShare: number;
}

export class WINWSEAContactDetailsModel {
    Id: number;
    WINAccountManager: string = "";
    Region: number = null;
    RegionName: string = "";
    WINTechnicalContact: string = "";
    WINGlobalContact: string = "";

}

export class CustomerProfileModel {
    Id: number;
    Name: string = "";
    CompanyInformation: string = "";
    BrandsProducts: string = "";
    CorporatePresentationFileName: string = "";
    CorporatePresentationFilePath: string = "";
    IndustrySegment: number = null;
    IndustrySegmentName: string = "";
    SubSegment: number = null;
    SubSegmentName: string = "";
    CustomerClassification: number = null;
    CustomerClassificationName: string = "";
    Typeofcustomer: number = null;
    TypeofcustomerName: string = "";
    CustomerCategory: number = null;
    CustomerCategoryName: string = "";

}

export class CompanyTurnoverModel {
    Id: number;
    GroupTurnover: string = "";
    GroupTurnoverYear: any = "";
    IndiaunitTurnover: string = "";
    IndiaunitTurnoverYear: any = "";
    DomesticTurnover: string = "";
    DomesticTurnoverYear: any = "";
    ExportTurnover: string = "";
    ExportTurnoverYear: any = "";
    ProjectedTurnover: string = "";
    ProjectedTurnoverYear: any = "";
    Noofemployees: number;
    GlobalParentsJVPartners: string = "";
    TechCollaboration: string = "";
    CustomersCustomer: string = "";
    CustomersKeyCompetitors: string = "";
    FocusSegment: string = "";
    CompanyStrategy: string = "";
    SalesofficeBranchoffice: string = "";
    ValueChainInformation: string = "";

}

export class PlantInformationWithGSTNoModel {
    Id: number;
    CompanyName: string = "";
    Region: number = null;
    RegionName: string = "";
    Address: string = "";
    GSTNo: string = "";
    KeyAccountNo: string = "";
    ContactNo: string = "";
    Email: string = "";

}

export class TopManagementModel {
    Id: number;
    Name: string = "";
    Designation: string = "";
    ContactNo: string = "";
    Email: string = "";
    
}

export class WeissBusinessModel {
    Id: number;
    BusinesswithWINWSEA: string = "";
    BusinesswithWNA: string = "";
    BusinesswithChina: string = "";
    BusinesswithEMEA: string = "";

}

export class OpportunitiesforWeissModel {
    Id: number;
    Indexer: string = "";
    HeavyDutyIndexer: string = "";
    LinearTransferSystem: string = "";
    PickandPlaceSystem: string = "";
    LinearSystem: string = "";
    Directdrives: string = "";
    DeltaRobot: string = "";
    Valueaddedsolution: string = "";
    AccessoriesSpareParts: string = "";
    Comments: string = "";

}

export class AttachmentModel {
    
    KYC2Attachment: any;
    KYC3Attachment: any;
    OrganogramAttachment: any;
    CorporatePresentationAttachment: any;

}

export class ProductImportExportModel{
    ProductIMPEXP:any;
}



