<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add {{singleFieldComponent.Heading}}
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>

                        <form class="m-login__form m-form" name="form" #myform="ngForm"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">

                                <div *ngIf="singleFieldComponent.isthirdfielddropdown"
                                    class="form-group m-form__group row">
                                    <label
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.thirdFiledLabel}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="customer"
                                            [(ngModel)]="singleFieldObj.Fk_Customer" #customer="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && customer.invalid || customer?.touched && customer.invalid}"
                                            [disabled]="isdropdowninactive" required>
                                            <option value="null" disabled>Select
                                                {{singleFieldComponent.thirdFiledLabel}}</option>

                                            <option *ngFor="let item of Customer" value="{{item.Id}}">
                                                {{item.CompanyName}}
                                            </option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && customer.invalid || customer?.touched && customer.invalid">
                                            <div *ngIf="myform.hasError('required', 'customer')">
                                                {{singleFieldComponent.firstFieldValidationMsg}} is required</div>
                                        </div>

                                    </div>

                                    <div class="offset-1"></div>

                                    <!-- <a title="Add"
                                        (click)="AddNewRow(singleFieldObj.customerSpecialDiscountInfo.length+1)"
                                        style="color:#000000;width: 25px;height: 25px;float: right;"
                                        class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2">
                                        <i class="fa fa-plus"></i>
                                    </a> -->


                                </div>


                                <div *ngIf="!singleFieldComponent.isDropdownrequird && !singleFieldComponent.isvariablediscountfield "
                                    class="form-group m-form__group row">
                                    <label
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.firstFiledLabel}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="fieldtitle"
                                            id="fieldtitle" [(ngModel)]="singleFieldObj.Name" #fieldtitle="ngModel"
                                            [ngClass]="{ 'is-invalid': (myform.submitted  && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)}"
                                            placeholder="Enter {{singleFieldComponent.firstFiledLabel}}"
                                            (keypress)="AvoidSpace($event)"
                                            [maxlength]="singleFieldComponent.FirstFieldMaxLen"
                                            [pattern]="singleFieldComponent.FirstFiledPattern"
                                            (change)="singleFieldObj.Name=singleFieldObj.Name.trim()" required>

                                        <div *ngIf="(myform.submitted && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)"
                                            class="invalid-feedback">
                                            <div *ngIf="fieldtitle.errors?.required">
                                                {{singleFieldComponent.firstFieldValidationMsg}} is required</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'fieldtitle')">
                                                {{singleFieldComponent.firstFieldValidationMsg}} already exists!
                                            </div>
                                            <div *ngIf="myform.hasError('pattern', 'fieldtitle')">
                                                {{singleFieldComponent.FirstFiledPatternValidationMessage}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="offset-1"></div>
                                    <label *ngIf="singleFieldComponent.isDoubleField"
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.secondFiledLabel}}:<span
                                            *ngIf="singleFieldComponent.Heading != 'Estimated Product Value'"
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div *ngIf="singleFieldComponent.isDoubleField && singleFieldComponent.Heading != 'Estimated Product Value'"
                                        class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="value" id="value"
                                            [(ngModel)]="singleFieldObj.Value" #value="ngModel"
                                            [ngClass]="{ 'is-invalid': (myform.submitted  && value.invalid) || (value?.touched && value.invalid)}"
                                            placeholder="Enter {{singleFieldComponent.secondFiledLabel}}"
                                            (keypress)="AvoidSpace($event)"
                                            [ngStyle]="{'text-align':(singleFieldComponent.RoutingParam === 'Currency') ? 'right' : 'left' }"
                                            [maxlength]="singleFieldComponent.SecondFieldMaxLen"
                                            (change)="singleFieldObj.Value=singleFieldObj.Value.trim()"
                                            [pattern]="singleFieldComponent.Pattern" required>

                                        <div *ngIf="(myform.submitted && value.invalid) || (value?.touched && value.invalid)"
                                            class="invalid-feedback">
                                            <div *ngIf="value.errors?.required">
                                                {{singleFieldComponent.SecondFieldValidationMsg}} is required</div>
                                            <div *ngIf="myform.hasError('pattern', 'value')">
                                                {{singleFieldComponent.SecondFieldValidationMsg}} is invalid
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="singleFieldComponent.isDoubleField && singleFieldComponent.Heading == 'Estimated Product Value'"
                                        class="col-lg-3">
                                        <mat-select class="form-control"
                                            placeholder="Select {{singleFieldComponent.secondFiledLabel}}"
                                            [(ngModel)]="singleFieldObj.RoleArr"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && value.invalid || value?.touched && value.invalid}"
                                            #value="ngModel" multiple name="value">
                                            <mat-option *ngFor="let item of Roles" value="{{item.Id}}">
                                                {{item.Name}}</mat-option>
                                            <mat-option disabled *ngIf="Roles.length <= 0" value="No Data Found"> No
                                                Data Found </mat-option>
                                        </mat-select>

                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && value.invalid || value?.touched && value.invalid">
                                            <div *ngIf="myform.hasError('required', 'value')">
                                                {{singleFieldComponent.SecondFieldValidationMsg}} is required
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="offset-1"></div>

                                <div *ngIf="singleFieldComponent.isDropdownrequird"
                                    class="form-group m-form__group row">
                                    <label
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.firstFiledLabel}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3" *ngIf="singleFieldComponent.Heading == 'Sub Segment'">
                                        <select class="form-control " id="sel1" name="segmentid"
                                            [(ngModel)]="singleFieldObj.SegmentId" #segmentid="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && segmentid.invalid || segmentid?.touched && segmentid.invalid}"
                                            required>
                                            <option value="null" disabled>Select
                                                {{singleFieldComponent.firstFiledLabel}}</option>
                                            <option *ngFor="let item of IndustrySegment" value="{{item.Id}}">
                                                {{item.Name}}
                                            </option>
                                            <option disabled *ngIf="IndustrySegment.length <= 0" value="No Data Found">
                                                No
                                                Data Found</option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && segmentid.invalid || segmentid?.touched && segmentid.invalid">
                                            <div *ngIf="myform.hasError('required', 'segmentid')">
                                                {{singleFieldComponent.firstFieldValidationMsg}} is required</div>
                                        </div>
                                    </div>

                                    <!-- ******************** For Terms & Conditions Customized -->
                                    <div class="col-lg-3"
                                        *ngIf="singleFieldComponent.Heading == 'Terms & Conditions-Non Standard'">
                                        <select class="form-control " id="sel1" name="standardtcid"
                                            [(ngModel)]="singleFieldObj.StandardTCId" #standardtcid="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && standardtcid.invalid || standardtcid?.touched && standardtcid.invalid}"
                                            required>
                                            <option value="null" disabled>Select
                                                {{singleFieldComponent.firstFiledLabel}}</option>
                                            <option *ngFor="let item of TermsAndConditions" value="{{item.Id}}">
                                                {{item.Name}}
                                            </option>
                                            <option disabled *ngIf="TermsAndConditions.length <= 0"
                                                value="No Data Found"> No
                                                Data Found</option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && standardtcid.invalid || standardtcid?.touched && standardtcid.invalid">
                                            <div *ngIf="myform.hasError('required', 'standardtcid')">
                                                {{singleFieldComponent.firstFieldValidationMsg}} is required</div>
                                        </div>
                                    </div>
                                    <!-- ******************** -->
                                    <!-- ******************** For Application -->
                                    <div class="col-lg-3" *ngIf="singleFieldComponent.Heading == 'Application'">
                                        <select class="form-control " id="sel1" name="segmentid"
                                            [(ngModel)]="singleFieldObj.SegmentId" #segmentid="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && segmentid.invalid || segmentid?.touched && segmentid.invalid}"
                                            (change)='onIndustrySegmentSelect($event)' required>
                                            <option value="null" disabled>Select
                                                {{singleFieldComponent.firstFiledLabel}}</option>
                                            <option *ngFor="let item of IndustrySegment" value="{{item.Id}}">
                                                {{item.Name}}
                                            </option>
                                            <option disabled *ngIf="IndustrySegment.length <= 0" value="No Data Found">
                                                No
                                                Data Found</option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && segmentid.invalid || segmentid?.touched && segmentid.invalid">
                                            <div *ngIf="myform.hasError('required', 'segmentid')">
                                                {{singleFieldComponent.firstFieldValidationMsg}} is required</div>
                                        </div>
                                    </div>
                                    <!-- ******************** -->
                                    <div class="col-lg-3" *ngIf="singleFieldComponent.Heading == 'Standard Reason'">
                                        <select class="form-control " id="sel1" name="enquirystatus"
                                            [(ngModel)]="singleFieldObj.Name" #enquirystatus="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && enquirystatus.invalid || enquirystatus?.touched && enquirystatus.invalid}"
                                            required>
                                            <option value="" disabled>Select
                                                {{singleFieldComponent.firstFiledLabel}}</option>
                                            <option value="Won">Won</option>
                                            <option value="Lost">Lost</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && enquirystatus.invalid || enquirystatus?.touched && enquirystatus.invalid">
                                            <div *ngIf="myform.hasError('required', 'enquirystatus')">
                                                {{singleFieldComponent.firstFieldValidationMsg}} is required
                                            </div>
                                        </div>
                                    </div>

                                    <div class="offset-1"></div>

                                    <!-- <label *ngIf="singleFieldObj.Name !='Won'"
                                         class="col-lg-2 col-form-label">{{singleFieldComponent.secondFiledLabel}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>  -->
                                    <label 
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.secondFiledLabel}}:<span
                                               style="color:#f4516c;">&nbsp;*</span></label> 
                                    <div *ngIf="singleFieldComponent.isDoubleField && singleFieldComponent.Heading != 'Application' && singleFieldComponent.Heading != 'Standard Reason'"
                                        class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="fieldtitle"
                                            id="fieldtitle" [(ngModel)]="singleFieldObj.Value" #fieldtitle="ngModel"
                                            [ngClass]="{ 'is-invalid': (myform.submitted  && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)}"
                                            placeholder="Enter {{singleFieldComponent.secondFiledLabel}}"
                                            (keypress)="AvoidSpace($event)"
                                            [maxlength]="singleFieldComponent.SecondFieldMaxLen"
                                            (change)="singleFieldObj.Value=singleFieldObj.Value.trim()" required>

                                        <div *ngIf="(myform.submitted && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)"
                                            class="invalid-feedback">
                                            <div *ngIf="fieldtitle.errors?.required">
                                                {{singleFieldComponent.SecondFieldValidationMsg}} is required</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'fieldtitle')">
                                                {{singleFieldComponent.SecondFieldValidationMsg}} already exists!
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div *ngIf="singleFieldComponent.isDoubleField && singleFieldComponent.RoutingParam == 'StandardReasonforWinningLosing' && singleFieldObj.Name !='Won'"
                                        class="col-lg-3"> -->
                                    <div *ngIf="singleFieldComponent.isDoubleField && singleFieldComponent.RoutingParam == 'StandardReasonforWinningLosing'"
                                        class="col-lg-3">
                                        
                                        <select class="form-control " id="sel2" name="reasoncategory"
                                            [(ngModel)]="singleFieldObj.ReasonCategoryId" #reasoncategory="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && reasoncategory.invalid || reasoncategory?.touched && reasoncategory.invalid}"
                                            required>
                                            <option value="undefined" disabled>
                                                {{singleFieldComponent.secondFiledLabel}}</option>
                                            <option *ngFor="let item of CategoryRegion"
                                                value="{{item.ReasonCategoryId}}">
                                                {{item.ReasonCategory}}
                                            </option>
                                            <option disabled *ngIf="CategoryRegion.length <= 0" value="No Data Found">
                                                No
                                                Data Found
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && reasoncategory.invalid || reasoncategory?.touched && reasoncategory.invalid">
                                            <div *ngIf="myform.hasError('required', 'reasoncategory')">
                                                {{singleFieldComponent.secondFiledLabel}} is required
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf="singleFieldComponent.isDoubleField && singleFieldComponent.Heading == 'Application'"
                                        class="col-lg-3">
                                        <select class="form-control " id="sel1" name="subsegmentid"
                                            [(ngModel)]="singleFieldObj.SubSegmentId" #subsegmentid="ngModel"
                                            [ngClass]="{'is-invalid':myform.submitted  && subsegmentid.invalid || subsegmentid?.touched && subsegmentid.invalid}"
                                            required>
                                            <option value="null" disabled>Select
                                                {{singleFieldComponent.secondFiledLabel}}</option>
                                            <option *ngFor="let item of SubSegment" value="{{item.Id}}">

                                                {{item.Value}}
                                            </option>
                                            <option disabled *ngIf="SubSegment.length <= 0" value="No Data Found"> No
                                                Data Found
                                            </option>

                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && subsegmentid.invalid || subsegmentid?.touched && subsegmentid.invalid">
                                            <div *ngIf="myform.hasError('required', 'subsegmentid')">
                                                {{singleFieldComponent.SecondFieldValidationMsg}} is required</div>
                                        </div>

                                    </div>


                                </div>

                                <div *ngIf="singleFieldComponent.isvariablediscountfield"
                                    class="form-group m-form__group row">
                                    <!-- <label
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.firstFiledLabel}}:<span
                                            style="color:#f4516c;">&nbsp;</span></label> -->

                                    <div class="table-responsive">
                                        <table
                                            class="table order-list table-striped- table-bordered table-hover table-edit"
                                            id="myTable">
                                            <thead>
                                                <tr>

                                                    <th>
                                                        Product Model
                                                    </th>
                                                    <th>
                                                        Product Discount
                                                    </th>
                                                    <th>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <ng-container
                                                    *ngFor="let field of singleFieldObj.customerSpecialDiscountInfo ; let i = index;">
                                                    <tr>
                                                        <td>
                                                            <select class="form-control" id="productmodel{{i+1}}"
                                                                name="productmodel{{i+1}}"
                                                                [(ngModel)]="field.Fk_ProductModel"
                                                                #productmodel="ngModel"
                                                                (change)="OnProductModelChange(i)" required>
                                                                <option value="null" disabled>Select
                                                                    Product Model</option>

                                                                <option *ngIf="showItems!=undefined"
                                                                    value="{{showItems[i]?.value.Id}}">
                                                                    {{showItems[i]?.value.ModelName}}
                                                                </option>
                                                                <option *ngFor="let item of ProductModel"
                                                                    value="{{item.Id}}">
                                                                    {{item.ModelName}}
                                                                </option>

                                                            </select>
                                                            <div *ngIf="(field.ProductModelRequired)">
                                                                <span class="validationmsg">
                                                                    Product Model is required</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" id="from{{i+1}}" name="from{{i+1}}"
                                                                class="form-control input-sm responsiveinput"
                                                                [(ngModel)]="field.ProductDiscount" #from="ngModel"
                                                                (keypress)="OnDiscountChange(i)" 
                                                                placeholder="Enter Discount from "
                                                               required>

                                                            <!-- <div *ngIf="(field.productDiscountrequired)">
                                                                <span class="validationmsg">
                                                                    Discount is
                                                                    required</span>
                                                            </div> -->
                                                        </td>
                                                        <td>

                                                            <a title="Add"
                                                                *ngIf="(singleFieldObj.customerSpecialDiscountInfo.length>0) && ((singleFieldObj.customerSpecialDiscountInfo.length - 1 ) == i)"
                                                                (click)="AddNewRow(singleFieldObj.customerSpecialDiscountInfo.length+1)"
                                                                style="color:#000000;width: 25px;height: 25px;float: right;"
                                                                class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2">
                                                                <i class="fa fa-plus"></i>
                                                            </a>
                                                            <a title="Delete"
                                                                *ngIf="(singleFieldObj.customerSpecialDiscountInfo.length>1) && ((singleFieldObj.customerSpecialDiscountInfo.length - 1 ) == i)"
                                                                (click)="deleterow(i)"
                                                                style="color:#ffffff;width: 25px;height: 25px;"
                                                                class="btn btn-metal m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                    class="fa fa-minus"></i></a>
                                                        </td>


                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>



                                </div>

                                <!-- For Application Master-->
                                <div *ngIf="singleFieldComponent.Heading == 'Application'"
                                    class="form-group m-form__group row">
                                    <label
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.thirdFieldLabel}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="fieldtitle"
                                            id="fieldtitle" [(ngModel)]="singleFieldObj.Application"
                                            #fieldtitle="ngModel"
                                            [ngClass]="{ 'is-invalid': (myform.submitted  && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)}"
                                            placeholder="Enter {{singleFieldComponent.thirdFieldLabel}}"
                                            (keypress)="AvoidSpace($event)"
                                            [maxlength]="singleFieldComponent.ThirdFieldMaxLen"
                                            [pattern]="singleFieldComponent.ThirdFieldPattern"
                                            (change)="singleFieldObj.Application=singleFieldObj.Application.trim()"
                                            required>

                                        <div *ngIf="(myform.submitted && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)"
                                            class="invalid-feedback">
                                            <div *ngIf="fieldtitle.errors?.required">
                                                {{singleFieldComponent.thirdFieldValidationMsg}} is required</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'fieldtitle')">
                                                {{singleFieldComponent.thirdFieldValidationMsg}} already exists!
                                            </div>
                                            <div *ngIf="myform.hasError('pattern', 'fieldtitle')">
                                                {{singleFieldComponent.ThirdFieldPatternValidationMessage}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="offset-1"></div>


                                </div>

                                <!-- For StandardReasonForWinLoss Master-->
                                <div *ngIf="singleFieldComponent.Heading == 'Standard Reason'" 
                                    class="form-group m-form__group row">
                                    <!-- <label class="col-lg-2 col-form-label">{{singleFieldComponent.thirdFieldLabel}}:
                                    </label> -->
                                
                                    <!-- <input type="checkbox" class="form-control m-input" id="competitor" name="competitor" #competitor="ngModel"
                                        style="width: 2%;position: relative; margin-left: 20px;" [(ngModel)]="singleFieldObj.IsCompetitorRequired"
                                        (change)="fieldChange(this.singleFieldObj.IsCompetitorRequired)"> -->

                                    <label
                                        class="col-lg-2 col-form-label">{{singleFieldComponent.thirdFieldLabel}}:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div *ngIf="singleFieldComponent.isTripleField && singleFieldComponent.Heading == 'Standard Reason'"
                                        class="col-lg-3">
                                        <input class="form-control m-input" type="text" name="fieldtitle"
                                            id="fieldtitle" [(ngModel)]="singleFieldObj.Value" #fieldtitle="ngModel"
                                            [ngClass]="{ 'is-invalid': (myform.submitted  && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)}"
                                            placeholder="Enter {{singleFieldComponent.thirdFieldLabel}}"
                                            (keypress)="AvoidSpace($event)"
                                            [maxlength]="singleFieldComponent.ThirdFieldMaxLen"
                                            (change)="singleFieldObj.Value=singleFieldObj.Value.trim()" required>

                                        <div *ngIf="(myform.submitted && fieldtitle.invalid) || (fieldtitle?.touched && fieldtitle.invalid)"
                                            class="invalid-feedback">
                                            <div *ngIf="fieldtitle.errors?.required">
                                                {{singleFieldComponent.ThirdFieldValidationMsg}} is required</div>
                                            <div *ngIf="myform.hasError('AlreadyPresent', 'fieldtitle')">
                                                {{singleFieldComponent.ThirdFieldValidationMsg}} already exists!
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>


                                <!-- For Sales Price -->

                                <div *ngIf="singleFieldComponent.isExtraFields">

                                    <div class="form-group m-form__group row" style="margin-top: 15px;">
                                        <h5 style="padding-left: 15px;"> Discount (%)</h5>
                                    </div>
                                    <div class="form-group m-form__group row" style="margin-top: 5px;">
                                        <label class="col-lg-2 col-form-label"> From :</label>
                                        <div class="col-lg-3">
                                            <input class="form-control input-sm responsiveinput"
                                                [(ngModel)]="singleFieldObj.DiscountFrom" type="text"  maxlength="2" 
                                                name="Discountfrom" id="Discountfrom" (keypress)="AvoidSpace($event);isNumberKey($event);">

                                        </div>

                                        <div class="offset-1"></div>
                                        <label class="col-lg-2 col-form-label"> To :</label>
                                        <div class="col-lg-3">
                                            <input class="form-control input-sm responsiveinput"  maxlength="2" 
                                                [(ngModel)]="singleFieldObj.DiscountTo" type="text" name="Discountto"
                                                id="Discountto" (keypress)="AvoidSpace($event);isNumberKey($event);" >
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <h5
                                            style=" margin-top: 15px;padding-bottom: 15px; width: 100%;padding-left: 15px;">
                                            Approval Authority :</h5>

                                    </div>

                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Level 1:</label>
                                        <div class="col-lg-3">
                                            <select class="form-control " id="sel1" name="enquirystatus"
                                                [(ngModel)]="singleFieldObj.App_Level1">

                                                <option value="notRequired">Not Required</option>
                                                <option *ngFor="let item of Roles" value="{{item.Id}}">
                                                    {{item.Name}}
                                                </option>

                                            </select>
                                        </div>

                                        <div class="offset-1"></div>
                                        <label class="col-lg-2 col-form-label">Level 2:</label>
                                        <div class="col-lg-3">
                                            <select class="form-control " id="sel1" name="enquirystatus"
                                                [(ngModel)]="singleFieldObj.App_Level2">

                                        
                                                <option value="notRequired">Not Required</option>
                                                <option *ngFor="let item of Roles" value="{{item.Id}}">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Level 3:</label>
                                        <div class="col-lg-3">
                                            <select class="form-control " id="sel1" name="enquirystatus"
                                                [(ngModel)]="singleFieldObj.App_Level3">

                                                <option value="notRequired">Not Required</option>
                                                <option *ngFor="let item of Roles" value="{{item.Id}}">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/ConfigureMasters', singleFieldComponent?.RoutingParam]"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
