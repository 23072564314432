import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeeGuestModel } from 'src/app/Model/EmployeeGuestModel';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { UpdateStatus } from 'src/app/Model/CommonModel';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AppGlobals } from 'src/app/app.global';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';


@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})

export class EmployeeDetailsComponent implements OnInit {
  displayedColumns: string[] = ['Username', 'Name', 'EmpCode', 'RoleName', 'Email', 'ContactNumber','ReportingManagerName','Action'];
  dataSource: MatTableDataSource<EmployeeGuestModel>;
  UpdateStatus: UpdateStatus = new UpdateStatus();
  disabled: boolean = false;
  UserRole: any;
  isEmployeeEditable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  /*************************************************************************
      Server side Pagination purpose
  *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*************************************************************************
     End here
  *************************************************************************/

  @Input()
  showLoader: boolean = false;
  data: EmployeeGuestModel[] = [];

  constructor(private toastr: ToastrService, private Service: CommonService, public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title,private accessservice: AccessPermissionService) {

  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.UserRole = localStorage.getItem("UserRole");
    this.getAccess();
   // this.isEmployeeEditable = AppGlobals.EmployeeMastersEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.GetAllEmployeeDetails();
  }

  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllEmployeeDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllEmployeeDetails() {
    this.showLoader = true;

    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.Service.GetData('Employee', 'GetAllEmployees', queryString).subscribe((data: any) => {
      this.data = data.Data;
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllEmployeeDetails();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllEmployeeDetails();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllEmployeeDetails();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  /*******************************************************************************
  * Form validation method
  * ******************************************************************************/

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  UpdateEmployeeStatusById(RegisterId: number, RoleType: number, CurrentEmpStatus: boolean) {
    this.UpdateStatus.RegisterId = RegisterId;
    this.UpdateStatus.RoleType = RoleType;

    if (CurrentEmpStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this employee?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.UpdateStatus.Status = 1;

          this.showLoader = true;
          this.Service.PostData('Account', 'UpdateStatus', this.UpdateStatus, null).subscribe((data: any) => {

            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Employee deactivated successfully!");
            }
            else {
              this.toastr.info("Failed to deactivate employee!");
            }
            this.GetAllEmployeeDetails();
          }, error => {
            this.toastr.error("Failed to deactivate employee!");
            this.GetAllEmployeeDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllEmployeeDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }

      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this employee?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.UpdateStatus.Status = 0;

          this.showLoader = true;
          this.Service.PostData('Account', 'UpdateStatus', this.UpdateStatus, null).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("Employee activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate employee!");
            }
            this.GetAllEmployeeDetails();
          }, error => {
            this.toastr.error("Failed to activate employee!");
            this.GetAllEmployeeDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllEmployeeDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }

  }
  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isEmployeeEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isEmployee')[0]?.IsEditable;
      
    });
 
  }
}



