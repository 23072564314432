<loader [showLoader]="showLoader"></loader>
<div class="custsize">


    <div class="modal-content custContent">

        <div class="modal-body">

            <div class="m-portlet m-portlet--head-solid-bg">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Close Lead Reason
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <form class="m-form m-form--fit m-form--label-align-right" name="closeLeadForm"
                        (ngSubmit)="closeLeadForm.form.valid" #closeLeadForm="ngForm" novalidate>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-3  col-form-label">Close Lead Reason:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-7">
                                <select class="form-control " id="coreason" name="coreason" [(ngModel)]="OppCloseId"
                                    #coreason="ngModel"
                                    [ngClass]="{ 'is-invalid': (closeLeadForm.submitted && coreason.invalid) || (coreason?.touched && coreason.invalid) }"
                                    required>
                                    <option value="undefined" selected disabled>Select
                                        Close Lead Reason
                                    </option>
                                    <option *ngFor="let item of closeOpportunityList" value="{{item.Id}}">
                                        {{item.Name}}
                                    </option>
                                </select>
                                <div *ngIf="(closeLeadForm.submitted && coreason.invalid ) || (coreason?.touched && coreason.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="coreason.errors.required">
                                        Close Lead reason is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                         <div class="form-group m-form__group row">

                            <label class="col-lg-3 col-form-label">Comments:<span
                                    style="color:#f4516c;">&nbsp;*</span></label>
                            <div class="col-lg-8">
                                <textarea class="form-control m-input" id="clcomments" name="clcomments" rows="3"
                                    maxlength="1500" [(ngModel)]="LeadObj.Comments"
                                    (keyup)="limitTextOnKeyUpDown1($event)" (keypress)="limitTextOnKeyUpDown1($event)"
                                    #clcomments="ngModel"
                                    [ngClass]="{ 'is-invalid':closeLeadForm.submitted  && clcomments.invalid || clcomments?.touched && clcomments.invalid}"
                                    required>
                                </textarea>
                                <span> Limit {{maxLimit}} characters,
                                    {{remLimit}} characters
                                    remaining.</span>
                                <div *ngIf="(closeLeadForm.submitted && clcomments.invalid) || (clcomments?.touched && clcomments.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="clcomments.errors.required">
                                        Comment is required
                                    </div>
                                </div>
                            </div>
                        </div> 
                      
                    </form>
                </div>
            </div>
        </div>

        <div class="modal-footer p-2 pr-4" style="float: right;">
            <div class="button-row text-right mt-4" style="margin:15px;">

                <button id="m_login_signin_submit" class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                    (click)="CloseLeadReason(enquiryStatus.CloseLead,closeLeadForm)">Submit</button>&nbsp;&nbsp;
                <button type="button" class="btn btn-metal" (click)="closePopup()" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>