<loader [showLoader]="showLoader"></loader>
<div
  class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="m-portlet">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    Customer Listing

                  </h3>
                </div>
              </div>
              <div class="m-portlet__head-tools">
                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <a class="btn btn-primary m-btn m-btn--air m-btn--custom" [hidden]="!isCustomerEditable"
                      routerLink="/AddCustomer/customer/0">Add
                      Customer </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="m-portlet__body">
              <div class="table-responsive">
                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">

                      <button id="import" (click)="ExportData()" [hidden]="!isDownloadCustomerData"
                        class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Export Customer Data</button>

                    </div>
                    <div class="col-sm-12 col-md-6 right">
                      <a routerLink="/CustomerFrameAgreementDetails"
                            routerLinkActive="active" 
                            class="btn btn-primary m-btn m-btn--air m-btn--custom">Customer Frame Agreements
                           
                          </a>
                      <mat-form-field style="width: 50%;" class="pull-right">

                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="SearchParam($event)" (keypress)="AvoidSpace($event)" maxlength="50"
                          placeholder="Search" #input>
                      </mat-form-field>

                    </div>

                  </div>

                  <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                      class="table table-striped- table-bordered table-hover dataTable no-footer" role="grid">

                      <ng-container matColumnDef="Id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Code </th>
                        <td mat-cell *matCellDef="let row">{{row.CustomerCode}}</td>
                      </ng-container>

                      <ng-container matColumnDef="CompanyName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.CompanyName}} </td>
                      </ng-container>

                      <ng-container matColumnDef="RegionName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Region </th>
                        <td mat-cell *matCellDef="let row"> {{row.RegionName}} </td>
                      </ng-container>

                      <ng-container matColumnDef="GroupName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
                        <td mat-cell *matCellDef="let row"> {{row.GroupName}} </td>
                      </ng-container>

                      <ng-container matColumnDef="ContactPerson">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Person </th>
                        <td mat-cell *matCellDef="let row"> {{row.ContactPerson}} </td>
                      </ng-container>

                      <ng-container matColumnDef="KYCAttachment">
                        <th [hidden]="!isKYCAttchmentVisible" mat-header-cell *matHeaderCellDef> KYC Attachment </th>
                        <td [hidden]="!isKYCAttchmentVisible" mat-cell *matCellDef="let row">
                          <ul style="display:inline-flex;list-style-type: none;">
                            <li *ngIf="row.KYC2FileName != '' && row.KYC2FileName != null"><a href="#"
                                (click)="DownloadFile(row.KYC2FilePath)">KYC2</a>&nbsp;&nbsp;&nbsp;&nbsp;</li>
                            <li *ngIf="row.KYC3FileName != '' && row.KYC3FileName != null"><a href="#"
                                (click)="DownloadFile(row.KYC3FilePath)">KYC3</a></li>
                          </ul>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let row">
                          <a mat-icon-button color="accent" [routerLink]="['/EditViewCustomer',row?.Id]"
                            routerLinkActive="active" title="View">
                            <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
                          </a>&nbsp;&nbsp;&nbsp;&nbsp;

                          <mat-slide-toggle [hidden]="!isCustomerEditable" class="example-margin" [checked]="row.Status"
                            [disabled]="disabled" (change)="UpdateCustomerStatusById(row.Id,row.Status)">
                          </mat-slide-toggle>&nbsp; {{row.Status==true ? 'Active' : 'Inactive'}}
                          <!--*********************************************************************************************-->

                          &nbsp;&nbsp;&nbsp;

                          <div style="display: flex; flex-direction: column; justify-content: center; align-items: start;">
                            <a [routerLink]="['/AddContractFrameAgreement',row?.Id]"
                              routerLinkActive="active" title="Go to Agreement"
                              class="btn btn-primary m-btn m-btn--air m-btn--custom">Frame Agreement
                            </a>
                            <a [routerLink]="['/CustomerEngagementDetails']" style="width: 173px; margin-top:4px"
                            routerLinkActive="active" [queryParams]="{'CustId': row?.Id}" title="ActivityList"
                            class="btn btn-primary m-btn m-btn--air m-btn--custom">  Engagements
                          </a>
                            <!-- <a [routerLink]="['/CustomerEngagementDetails']"
                              routerLinkActive="active" [queryParams]="{'CustId': row?.Id}" title="ActivityList"
                              class="btn btn-primary m-btn m-btn--air m-btn--custom mt-1">
                              Engagements
                            </a> -->
                          </div>

                          <!--*********************************************************************************************-->
                        </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                    <div
                      *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
                      class="text-center">No records found</div>

                    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                      [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent($event)">
                    </mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>