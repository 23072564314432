import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { AppGlobals } from 'src/app/app.global';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { StockInventoryModel } from 'src/app/Model/StockInventoryModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

@Component({
  selector: 'app-stock-inventory-details',
  templateUrl: './stock-inventory-details.component.html',
  styleUrls: ['./stock-inventory-details.component.scss']
})
export class StockInventoryDetailsComponent implements OnInit {
  displayedColumns: string[] = ['StockDateISO', 'Action'];
  dataSource: MatTableDataSource<StockInventoryModel>;
  disabled: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];



  /*************************************************************************
      Server side Pagination purpose
  *************************************************************************/
  pageNumber: number = 1;
  @Input() pageSize: number = 5;
  @Input() length: number;
  @Input() pageIndex: number = this.pageNumber - 1;
  direction: string = 'desc';
  ColumnName: string = 'ModifiedOn';
  SearchText: string = "";
  @Output('matSortChange') sortChange: EventEmitter<Sort>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /*************************************************************************
     End here
  *************************************************************************/

  @Input()
  showLoader: boolean = false;
  data: any[] = [];
  UserRole: any;
  isStockInventoryEditable: boolean = false;
  constructor(private toastr: ToastrService, private commonService: CommonService, public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title,private accessservice: AccessPermissionService
    ) {
  }

  ngOnInit() {
    this.UserRole = localStorage.getItem("UserRole");
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.getAccess();
    //this.isStockInventoryEditable = AppGlobals.StockInventoryEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.GetAllStockInventory();
  }

  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllEmployeeDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

  GetAllStockInventory() {
    this.showLoader = true;

    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.commonService.GetData('StockInventory', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.data = data.Data;
      if (data.Data != null) {
        this.data.forEach(item => {
          item.StockDate = this.convertDate(item.StockDate);
        });
      }
      this.length = data.Total;
      this.dataSource = new MatTableDataSource(this.data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });

  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllStockInventory();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllStockInventory();
    }
  }

  pageEvent(event: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllStockInventory();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/
  convertDate(date: any) {
    date = date.split('T')[0];
    date = date.split('-').join('/');
    return date;
  }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isStockInventoryEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='isStockInventory')[0]?.IsEditable;
      
    });
 
  }


}
