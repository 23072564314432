
<div class="col-lg-12 mb-4 table-responsive" *ngIf="!isprocessing">
        <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="myform3"
            #myform3="ngForm" appFocusInvalidInput>
            <div class="multisteps-form__panel shadow rounded bg-white js-active" data-animation="fadeIn">
                <div class="multisteps-form__content">
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Quote Generation
                                    </h3>


                                </div>
                            </div>

                            <div class="m-portlet__head-tools" >

                                <button *ngIf="this.UserRole == roleOfAdmin"
                                    class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                    (click)="SendBack()">Send Back</button> &nbsp;&nbsp;

                            </div>
                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <div class="m-portlet__body">
                            <div class="row" style="margin-bottom: 15px;">
                                <div class="col-sm-6">
                                    <div class="col">
                                        <button class="btn btn-primary ml-auto mr-3"
                                            (click)="DownloadAllAttachments()" type="button">Download
                                            All</button>
                                    </div>
                                </div>

                            </div>

                            <div class="table-responsive">
                                <table
                                    class="table order-list table-striped- table-bordered table-hover table-edit"
                                    id="rfqtable">
                                    <thead class="quoteheader">
                                        <tr>
                                            <th rowspan="2" class="text-center">
                                                Product Line
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Product Model
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Quantity
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                MDS Option
                                            </th>
                                            <th colspan="4" class="text-center">
                                                Attachment
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Sales Price
                                            </th>
                                            <th rowspan="2" class="text-center">
                                                Sales Discount
                                            </th>


                                        </tr>
                                        <tr>
                                            <th class="text-center">
                                                MDS
                                            </th>
                                            <th class="text-center">
                                                ITC
                                            </th>
                                            <th class="text-center">
                                                Customer
                                            </th>
                                            <th class="text-center">
                                                Weiss
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let field of EnquiryObj.RFQObject; let i = index;">
                                            <tr>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select disabled class="form-control p-0"
                                                        id="productline{{i}}" name="productline{{i}}"
                                                        [(ngModel)]="field.ProductLine"
                                                        style="border: none;"
                                                        (change)="getProductModel(field.ProductLine,i)">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>
                                                        <option *ngFor="let item of product.Product"
                                                            value="{{item.ProductLine.Id}}">
                                                            {{item.ProductLine.Name}}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select disabled
                                                        *ngIf="field.ProductModelList.length!=0"
                                                        class="form-control p-0" id="productmodel{{i}}"
                                                        name="productmodel{{i}}"
                                                        [(ngModel)]="field.ProductModel"
                                                        style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>
                                                        <option *ngFor="let item of field.ProductModelList"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>

                                                    </select>
                                                    <select disabled
                                                        *ngIf="field.ProductModelList.length==0"
                                                        class="form-control p-0" id="productmodel{{i}}"
                                                        name="productmodel{{i}}"
                                                        [(ngModel)]="field.ProductModel"
                                                        style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>

                                                        <option
                                                            *ngFor="let item of productDrpdnDetails.ProductModel"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td
                                                    style="width:75px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <input type="text" class="form-control m-input"
                                                        id="quantity{{i}}" name="quantity{{i}}"
                                                        [(ngModel)]="field.Quantity" style="border:none;"
                                                        disabled>
                                                </td>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select disabled class="form-control p-0"
                                                        id="mdsoption{{i}}" name="mdsoption{{i}}"
                                                        [(ngModel)]="field.MDSOption" style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>
                                                        <option
                                                            *ngFor="let item of productDrpdnDetails.MDSOptions"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf="(field.MDS_filename==null && !field.isMDSAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="mdsfile{{i}}" name="mdsfile"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isMDSAttachment">
                                                        {{(field.MDSAttachment
                                                        != undefined &&
                                                        field.MDSAttachment[0]
                                                        != undefined) ?
                                                        field.MDSAttachment[0].name:
                                                        ""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            (click)="deleteAttachDocument(i,'MDS')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div
                                                        *ngIf="field.MDS_filename!=null && !field.isMDSAttachment">
                                                        <a href="{{fileDownloadPath}}{{field.MDS_filepath}}"
                                                            target="_blank">{{field.MDS_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            (click)="deleteAttachDocumentFromDB(i,'MDS')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf=" (field.ITC_filename==null && !field.isITCAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="itcfile{{i}}" name="itcfile"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isITCAttachment">
                                                        {{(field.ITCAttachment
                                                        != undefined &&
                                                        field.ITCAttachment[0]
                                                        != undefined) ?
                                                        field.ITCAttachment[0].name:
                                                        ""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            (click)="deleteAttachDocument(i,'ITC')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div
                                                        *ngIf="field.ITC_filename!=null && !field.isITCAttachment">
                                                        <a href="{{fileDownloadPath}}{{field.ITC_filepath}}"
                                                            target="_blank">{{field.ITC_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            (click)="deleteAttachDocumentFromDB(i,'ITC')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf="(field.Customer_filename==null && !field.isCustomerAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="customerfile{{i}}" name="customerfile"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isCustomerAttachment">
                                                        {{(field.CustomerAttachment
                                                        != undefined &&
                                                        field.CustomerAttachment[0]
                                                        != undefined) ?
                                                        field.CustomerAttachment[0].name:
                                                        ""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            (click)="deleteAttachDocument(i,'Customer')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div
                                                        *ngIf="field.Customer_filename!=null && !field.isCustomerAttachment">
                                                        <a rel="icon"
                                                            href="{{fileDownloadPath}}{{field.Customer_filepath}}"
                                                            target="_blank">{{field.Customer_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            (click)="deleteAttachDocumentFromDB(i,'Customer')"
                                                            title="Remove"><br></i>
                                                    </div>


                                                </td>
                                                <td>
                                                    <div class="file"
                                                        *ngIf=" (field.Weiss_filename==null && !field.isWeissAttachment)">
                                                        <i class="flaticon-file-2"></i>
                                                        <input type="file" class="form-control-file"
                                                            id="weissfile{{i}}" name="weissfile"
                                                            (change)="editFileDetails($event,i)" />
                                                    </div>

                                                    <div *ngIf="field.isWeissAttachment">
                                                        {{(field.WeissAttachment
                                                        != undefined &&
                                                        field.WeissAttachment[0]
                                                        != undefined) ?
                                                        field.WeissAttachment[0].name:
                                                        ""}}
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            (click)="deleteAttachDocument(i,'Weiss')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                    <div
                                                        *ngIf="field.Weiss_filename!=null && !field.isWeissAttachment">
                                                        <a href="{{fileDownloadPath}}{{field.Weiss_filepath}}"
                                                            target="_blank">{{field.Weiss_filename}}</a>
                                                        <i class="flaticon-delete" style="color:#f4516c"
                                                            *ngIf="QGRFQEditable"
                                                            (click)="deleteAttachDocumentFromDB(i,'Weiss')"
                                                            title="Remove"><br></i>
                                                    </div>
                                                </td>
                                                <td
                                                    style="vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <select disabled class="form-control p-0"
                                                        id="salesprice{{i}}" name="salesprice{{i}}"
                                                        [(ngModel)]="field.SalesPrice"
                                                        style="border: none;">
                                                        <option value="undefined" selected disabled>
                                                            Select
                                                        </option>
                                                        <option
                                                            *ngFor="let item of productDrpdnDetails.SalesPrice"
                                                            value="{{item.Id}}">
                                                            {{item.Name}}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td
                                                    style="width:75px; vertical-align:middle; padding: 5px!important; border-radius: 0px!important;">
                                                    <input type="text" disabled class="form-control m-input"
                                                        id="salesdiscount{{i}}" name="salesdiscount{{i}}"
                                                        [(ngModel)]="field.SalesDiscount"
                                                        style="border:none;">
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>

                            </div>

                            <div class="form-group m-form__group row ">

                                <div class="col-lg-6" style="padding-top:9px;">
                                </div>


                                <!-- ************************************************************* -->
                                <!-- *ngIf="!showQuotationLink" -->
                                <!-- <div *ngIf="showEditRFQ" class="col-lg-6 text-right">
                                    <input *ngIf="!QGRFQEditable && AppGlobals.QuoteGenearation_EditRFQ"
                                        type="button" class="btn btn-primary btnspc" id="addrowss"
                                        value="Edit RFQ" (click)="editRFQ()"> &nbsp;&nbsp;
                                    <input *ngIf="QGRFQEditable" type="submit"
                                        class="btn btn-primary btnspc" id="addrowss" value="Save RFQ"
                                        (click)="SetStatus('saveRFQ',myform3)">
                                    <input
                                        *ngIf="!QGRFQEditable && AppGlobals.QuoteGenearation_GenerateQuote"
                                        type="button" class="btn btn-primary btnspc" id="addrowss"
                                        value="Generate Quote" (click)="openQuotationPopup()"> &nbsp;&nbsp;
                                    <button *ngIf="QGRFQEditable" (click)="cancelEditingRFQ()"
                                        class="btn btn-metal m-btn m-btn--air m-btn--custom"
                                        type="button">Cancel</button>
                                </div> -->
                            </div>

                            <div class="form-group m-form__group row ">

                                <label class="col-lg-2 col-form-label">BOM (Bill
                                    Of Materials):</label>
                                <div class="col-lg-3" style="padding-top:9px;">
                                    <div class="file"
                                        *ngIf="((EnquiryObj.EnquiryDetails.BOM_filename==null || EnquiryObj.EnquiryDetails.BOM_filename==undefined || EnquiryObj.EnquiryDetails.BOM_filename=='') && !isBOMAttachment)">
                                        <i class="flaticon-file-2"></i>
                                        <input type="file" class="form-control-file" id="bomfile"
                                            name="bomfile" 
                                            (change)="editFileDetails($event,0)" />
                                        <br /><span class="fileext">
                                            {{AppGlobals.supportedFileFormatsForOtherAttachments}}</span>
                                    </div>
                                    <div class="filenamemobile" *ngIf="isBOMAttachment">
                                        {{(EnquiryObj.EnquiryDetails.BOMAttachment
                                        != undefined &&
                                        EnquiryObj.EnquiryDetails.BOMAttachment[0]
                                        != undefined) ?
                                        EnquiryObj.EnquiryDetails.BOMAttachment[0].name:
                                        ""}}&nbsp;&nbsp;
                                        <i class="flaticon-delete" style="color:#f4516c"
                                            *ngIf="EnquiryObj.EnquiryDetails.BOMAttachment != undefined && EnquiryObj.EnquiryDetails.BOMAttachment[0] != undefined "
                                            (click)="removeBOMAttachments(0)" title="Remove"><br></i>
                                    </div>
                                    <div class="filenamemobile"
                                        *ngIf="!(EnquiryObj.EnquiryDetails.BOM_filename==null || EnquiryObj.EnquiryDetails.BOM_filename==undefined || EnquiryObj.EnquiryDetails.BOM_filename=='') && !isBOMAttachment">
                                        <a href="{{fileDownloadPath}}{{EnquiryObj.EnquiryDetails.BOM_filepath}}"
                                            target="_blank">{{EnquiryObj.EnquiryDetails.BOM_filename}}</a>&nbsp;&nbsp;
                                        <i class="flaticon-delete" style="color:#f4516c"
                                            *ngIf=" EnquiryObj.EnquiryDetails.BOM_filename!=null"
                                            (click)="removeBOMAttachments(1)" title="Remove"><br></i>
                                    </div>
                                </div>
                                <div class="offset-1"></div>
                                <label class="col-lg-2 col-form-label"
                                    *ngIf="EnquiryObj.EnquiryDetails.QuotationRevisionNo != null && EnquiryObj.EnquiryDetails.QuotationRevisionNo > 0">Quotation
                                    History:</label>
                                <div class="col-lg-3 filenamemobile" style="padding-top: 8px;"
                                    *ngIf="EnquiryObj.EnquiryDetails.QuotationRevisionNo != null && EnquiryObj.EnquiryDetails.QuotationRevisionNo > 0">
                                    <ul>
                                        <li *ngFor="let quote of RevisedQuotationFileNameList">
                                            <a (click)="DownloadPDFfromServer(quote)" [routerLink]="">
                                                {{quote}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div  class="form-group m-form__group row">
                                <label *ngIf="showQuotationLink" class="col-lg-2  col-form-label">View
                                    Quotation:</label>
                                <div *ngIf="showQuotationLink" class="col-lg-3 filenamemobile"
                                    style="padding-top: 10px;">
                                    

                                    <a (click)="DownloadPDFfromServer(EnquiryObj.EnquiryDetails.QuotationPdf_filename)"
                                        [routerLink]="">
                                        {{EnquiryObj.EnquiryDetails.QuotationPdf_filename}}
                                    </a>
                                </div>
                                
                                
                                <label *ngIf="!showQuotationLink" class="col-lg-2  col-form-label">Quotation Preview:</label>                                          
                                <div  *ngIf="!showQuotationLink" class="col-lg-3 filenamemobile" style="padding-top: 10px;" >
                                    <div>
                                        <a style="cursor: pointer;color: blue;" (click)="OpenPreview()">Preview quotation</a>
                                    </div>
                                </div>
                                
                            </div>

                            

                            <div *ngIf="isQuoteGenerationEditable"
                                class="form-group m-form__group row">

                                <label class="col-lg-2 col-form-label">Comments:<span
                                        style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-10">
                                    <textarea class="form-control m-input" id="quotecomments"
                                        name="quotecomments" rows="3" maxlength="1500"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.Comments"
                                        (keyup)="limitTextOnKeyUpDown1($event)"
                                        (keypress)="limitTextOnKeyUpDown1($event)" #quotecomments="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform3.submitted && quotecomments.invalid) || (quotecomments?.touched && quotecomments.invalid) }"
                                        required>
                                                                </textarea>
                                    <span> Limit {{maxLimit}} characters,
                                        {{remLimit}} characters
                                        remaining.</span>
                                    <div *ngIf="(myform3.submitted && quotecomments.invalid) || (quotecomments?.touched && quotecomments.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="quotecomments.errors.required">
                                            Comment is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label *ngIf="isQuoteGenerationEditable"
                                    class="col-lg-2 col-form-label">Attachment(s):</label>
                                <div *ngIf="isQuoteGenerationEditable" class="col-lg-4 pt-1">
                                    <div class="file btn btn-sm btn-warning">
                                        Upload
                                        <input type="file" class="form-control-file" id="quotefiles"
                                            name="quotefiles" #myInput
                                            (change)="getMultipleFileDetails($event)" multiple />
                                    </div>
                                    <br /><span class="fileext">
                                        {{AppGlobals.supportedFileFormatsForOtherMultipleAttachments}}</span>
                                    <span
                                        *ngIf="EnquiryObj.EnquiryDetails.QuoteGenAttachments != undefined && EnquiryObj.EnquiryDetails.QuoteGenAttachments.length > 0">
                                        <ul>
                                            <ng-container
                                                *ngFor="let item of EnquiryObj.EnquiryDetails.QuoteGenAttachments;let i=index;">
                                                <li class="filenamemobile">
                                                    {{item.name}}&nbsp;&nbsp;<i class="flaticon-delete"
                                                        style="color:#f4516c"
                                                        (click)="removeQuoteGenAttachments(i)"
                                                        title="Remove"></i>
                                                </li>
                                            </ng-container>
                                        </ul>

                                    </span>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <h5
                                    style="border-bottom: 1px solid #ccc; margin-top: 15px;padding-bottom: 10px; width: 100%;">
                                    Terms and Condition Details</h5>
                            </div>

                            <div class="form-group m-form__group row ">

                                <label class="col-lg-2 col-form-label">Terms and
                                    Conditions:<span style="color:#f4516c;">&nbsp;*</span></label>
                                <div class="col-lg-3">
                                    <select class="form-control p-0" id="tandc1" name="tandc1"
                                        #tandc1="ngModel"
                                        [ngClass]="{ 'is-invalid': (myform3.submitted && tandc1.invalid) || (tandc1?.touched && tandc1.invalid) }"
                                        required 
                                        [disabled]="isTermsConditionsDisabled"
                                        (change)="setTermsAndConditionsEditRfq()"
                                        [(ngModel)]="EnquiryObj.EnquiryDetails.RFQTermsAndCondition">
                                        <option value="" selected disabled>
                                            Select
                                        </option>
                                        <option value="Standard">Standard
                                        </option>
                                        <option value="Non-Standard">Non-Standard
                                        </option>
                                    </select>
                                    <div *ngIf="(myform3.submitted && tandc1.invalid) || (tandc1?.touched && tandc1.invalid)"
                                        class="invalid-feedback">
                                        <div *ngIf="tandc1.errors.required">
                                            Terms & conditions is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row" style="margin-left: 15px;" *ngIf="isStandardCondition">
                                <div class="form-group m-form__group row col-sm-6"
                                    *ngFor="let item of EnquiryObj.TermsAndConditions; let i = index">
                                    <div style="display: contents;">
                                        <label class="col-form-label">{{item.TnCName}}:<span
                                                style="color:#f4516c;">&nbsp;*</span></label>
                                        <input
                                            [disabled]=" EnquiryObj.EnquiryDetails.RFQTermsAndCondition=='Standard'"
                                            [(ngModel)]="item.TnCValue" maxlength="150"
                                            [ngClass]="{ 'is-invalid': (item.TnCValue==undefined || item.TnCValue==null || item.TnCValue=='') }"
                                            type="text" name="tnc{{i}}" class="form-control m-input"><br />
                                    </div><br />
                                </div>
                            </div>
                            <!------- For Custom TC-->
                            <div class="row" style="margin-left: 5px;" *ngIf="!isStandardCondition">

                                <div class="form-group m-form__group col-sm-6"
                                    *ngFor="let item of EnquiryObj.CustomTermsAndConditions; let i = index">
                                    
                                        <label class="col-form-label">{{item.StandardTCName}}:<span
                                                style="color:#f4516c;">&nbsp;*</span></label>
                                        <select class="form-control" id="ctnc{{i}}" name="ctnc{{i}}"
                                            #ctnc="ngModel"
                                            [ngClass]="{ 'is-invalid': (myform3.submitted && ctnc.invalid) || (ctnc?.touched && ctnc.invalid) }"
                                            [disabled]="isTermsConditionsDisabled"
                                            (change)="OnCustomValueChange(i,item.CustTCId)"
                                            [(ngModel)]="item.CustTCId" 
                                            required>
                                            <option value = null selected disabled>
                                                Select
                                            </option>
                                            <option *ngFor="let custValues of item.CustomValues"
                                                value="{{custValues.Id}}">
                                                {{custValues.Name}}
                                            </option>
                                        </select>

                                        <div *ngIf="!isStandardCondition && ((myform3.submitted && ctnc.invalid) || (ctnc?.touched && ctnc.invalid))"
                                        class="invalid-feedback">
                                            <div *ngIf="ctnc.errors.required">
                                                Non standard value is required
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="button-row text-right mt-4" style="margin:15px;">
                        <div class="m-portlet__head-caption">
                            <span *ngFor="let item of workflowActionList;let i = index;">
                                <button class="btn btn-primary ml-auto js-btn-next btnspc"
                                    type="{{item.workflowActionType}}"
                                    (click)="OnWorkflowAction(myform3,item.workflowActionId)">
                                    {{item.workflowActionName}}
                                </button> &nbsp;
                            </span>
                            <!-- <button *ngIf = "this.UserRole == roleOfAdmin"
                                class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                (click)="SendBack()">Send Back</button> &nbsp;&nbsp; -->
                            <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="button"
                                (click)="backtoList()" routerLinkActive="active">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
</div>
                <!-- </mat-tab> -->
                    <!-- <mat-tab label="Preview Quotation"> 
                        <app-quotation-preview></app-quotation-preview>
                    </mat-tab>
                    <mat-tab label="Preview Quotation PDF"> 
                        <app-quotation-preview></app-quotation-preview>
                    </mat-tab> -->
                <!-- </mat-tab-group> -->
          