<!--begin::Modal-->
<!-- <div class="modal fade" id="GenerateQuote" tabindex="-1" role="dialog" aria-hidden="true"> -->
<loader [showLoader]="showLoader"></loader>
<div class="custsize">
    <div class="modal-content custContent">

        <div class="modal-body">

            <div class="m-portlet m-portlet--success m-portlet--head-solid-bg mb-3">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Quotation
                            </h3>
                        </div>
                    </div>

                </div>

                <form class="multisteps-form__form m-form m-form--fit m-form--label-align-right" name="QuotationForm"
                    #QuotationForm="ngForm" appFocusInvalidInput novalidate>
                    <div id="ExportContent" class="m-portlet__body pb-2 pt-2">


                        <table class="table table-custInfo " style="margin-bottom:-1px">
                            <tr>
                                <td
                                    style="text-align:left;border-top:1px solid #a2a2a2;border-right:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2;width:50%">
                                    <strong>Quotation No: {{QuotationData.QuotationInfo.QuotationNumber}}</strong>
                                </td>
                                <td
                                    style="text-align:left;border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2;width:50%">
                                    <strong>Quotation Date:
                                        {{(QuotationData.QuotationInfo.QuotationDate != undefined ?
                                        QuotationData.QuotationInfo.QuotationDate: currentDate) | date:
                                        "dd-MMM-yyyy"}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2;padding: 0px!important; border-radius: 0px!important;">

                                    <div>
                                        <b>&nbsp;Your Reference:<span style="color:#f4516c;">&nbsp;*</span></b>&nbsp;
                                        <input type="text" id="YourReference" name="YourReference"
                                            placeholder="Enter name..."
                                            [(ngModel)]="QuotationData.QuotationInfo.YourReference" maxlength="150"
                                            class="mobileviewrefnamefiled" autocomplete="off" #yourRefElement
                                            (change)="onYourRefChange()" value="{{CustomerDetailsObj.YourReference}}" />
                                        <div style="color: #af0a25;" *ngIf="YourRefValidation">
                                            Reference person name is required
                                        </div>
                                    </div>


                                </td>
                                <td
                                    style="border-top:1px solid #a2a2a2; border-left:1px solid #a2a2a2;border-bottom:1px solid #a2a2a2;padding: 0px!important; border-radius: 0px!important;">

                                    <div>
                                        <b>&nbsp;Reference Date:<span style="color:#f4516c;">&nbsp;*</span></b>&nbsp;
                                        <input readonly placeholder="DD-MMM-YYYY" id="ReferenceDate"
                                            name="ReferenceDate" ngbDatepicker #dp="ngbDatepicker" (click)="dp.toggle()"
                                            [minDate]="minDate" [maxDate]="maxDate" [disabled]="!isQuotationEditable"
                                            autocomplete="off" #refDateElement (change)="onRefDateChange()"
                                            [(ngModel)]="QuotationData.QuotationInfo.ReferenceDateISO"
                                            class="mobileviewrefdatefiled">
                                        <div style="color: #af0a25;" *ngIf="RefDateValidation">
                                            Reference Date is required
                                        </div>

                                    </div>

                                </td>

                            </tr>

                            <tr>
                                <td style="width:8%;text-align: left;"><b>To,</b></td>
                                <td></td>
                            </tr>
                            <tr>

                                <td colspan="2" style="text-align:left"><b>{{CustomerDetailsObj.CustomerName}}<br />
                                        {{CustomerDetailsObj.City}}, {{CustomerDetailsObj.State}}</b></td>
                            </tr>
                            <tr>
                                <td
                                    style="border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2;padding: 0px!important; border-radius: 0px!important;">
                                    <div>
                                        <b>Kind Attn:<span style="color:#f4516c;">&nbsp;*</span></b>&nbsp;
                                        <input type="text" id="KeyPersonName" name="KeyPersonName"
                                            placeholder="Enter name..." maxlength="100"
                                            [(ngModel)]="QuotationData.QuotationInfo.KindAttention"
                                            class="mobileviewkindattfiled" autocomplete="off" #keyPerElement
                                            (change)="onKindAttChange()" [disabled]="!isQuotationEditable" />
                                        <div *ngIf="KindAttValidation" style="color: #af0a25;">
                                            Key Person is required
                                        </div>
                                    </div>
                                </td>
                                <td
                                    style="border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2; border-left:1px solid #a2a2a2;padding: 0px!important; border-radius: 0px!important;">
                                    <div>
                                        <b>&nbsp;Contact Number:<span style="color:#f4516c;">&nbsp;*</span></b>&nbsp;
                                        <input type="text" id="KeyPersonCno" name="KeyPersonCno"
                                            placeholder="Enter Contact Number" minlength="10" maxlength="15"
                                            pattern="[0-9]+" [(ngModel)]="QuotationData.QuotationInfo.KindAttContactNo"
                                            (keypress)="AvoidSpace($event);isNumberKey($event);"
                                            class="mobileviewcnofiled" autocomplete="off" #keyPerCNo
                                            (change)="onKindAttContactNumberChange()"
                                            [disabled]="!isQuotationEditable" />
                                        <div *ngIf="KindAttContactNoValidilation" style="color: #af0a25;">
                                            {{KingAttConactNumberValidationMsg}}
                                        </div>

                                    </div>


                                </td>

                            </tr>
                            <tr>
                                <td colspan="2"
                                    style="border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2;padding: 0px!important; border-radius: 0px!important;">
                                    <div>
                                        <b>Email:<span style="color:#f4516c;">&nbsp;*</span></b>&nbsp;
                                        <input type="text" id="KeyPersonName" name="KeyPersonmail"
                                            placeholder="Enter Email" class="mobileviewEmailfiled"
                                            [(ngModel)]="QuotationData.QuotationInfo.KindAttEmailId" maxlength="50"
                                            autocomplete="off" #keyPerEmail (change)="onKindAttEmailChange()"
                                            [disabled]="!isQuotationEditable" />
                                        <div *ngIf="KindAttEmailValidilation" style="color: #af0a25;">
                                            {{KingAttEmailValidationMsg}}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td colspan="2">We thank you for your interest in our Products. Referring to your
                                    enquiry and according to our general terms & Conditions, We would like to send you
                                    our non-binding offer as below-</td>
                            </tr> -->
                            <tr>
                                <!-- <td
                                 style="border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2;padding: 0px!important; border-radius: 0px!important;">
                                    <div>
                                        <b>Weiss Sales Person:<span style="color:#f4516c;">&nbsp;</span></b>&nbsp;
                                        {{QuotationData.QuotationInfo.InitiatorName}}
                                    </div> 
                                </td> ---(change)="changeSalesContactPerson(QuotationData.QuotationInfo.SalesContactPerson)-->

                                <td>
                                    <!-- <b>Weiss Sales Person:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                                    <div class="form-inline" style="display: flex;"> <b>Weiss Sales Person:<span
                                                style="color:#f4516c;">&nbsp;*</span></b>&nbsp;

                                        <select class="form-control " id="sel1" name="SalesContactPerson"
                                            [(ngModel)]="QuotationData.QuotationInfo.SalesContactPerson"
                                            #SalesContactPerson="ngModel"
                                            [ngClass]="{ 'is-invalid': (SalesContactPersonValidation) }" required
                                            (change)="changeSalesContactPerson
                                            (QuotationData.QuotationInfo.SalesContactPerson)">
                                            <option value="null" disabled> Select Sales Person</option>
                                            <option *ngFor="let item of EmployeeData" value="{{item.Fk_User_Id}}">
                                                {{item.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="SalesContactPersonValidation" style="color: #af0a25;">
                                            Weiss Sales Person is required
                                        </div>

                                    </div>
                                </td>

                                <td
                                    style="border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2; border-left:1px solid #a2a2a2;padding: 0px!important; border-radius: 0px!important;">
                                    <div>
                                        <b>&nbsp;Contact Number:<span style="color:#f4516c;">&nbsp;</span></b>&nbsp;
                                        {{QuotationData.QuotationInfo.ContactNumber}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="row" style="padding: 10px;float: right;">
                            <input type="button" class="btn btn-primary mr-3" id="addrowss" value="Add Product"
                                (click)="AddNewProduct()">
                            <!-- <div *ngIf="isQuotationEditable" class="col-sm-6">
                                <span class="fileext">Note: All prices are in INR</span><br />
                            </div>
                            <div *ngIf="isQuotationEditable" class="col-sm-6" >
                            </div> -->
                        </div>
                        <table class="table order-lists table-responsive table-cust bord">
                            <thead class="quoteheader">
                                <th class="text-center" style="width:10%;">Series</th>
                                <th class="text-center" style="width: 40%;">Model</th>
                                <th class="text-center clmwidth">Unit Price</th>

                                <th class="text-center clmwidth">Qty</th>

                                <th class="text-center clmwidth">Discount (%)
                                    <select class="form-control " name="option" (change)="setDiscountType()"
                                        [(ngModel)]="DiscountforOneOrAll" [disabled]="isQtyDiscountEditable">
                                        <option value=undefined disabled>Select</option>
                                        <option value="0">Discount for Individual Products</option>
                                        <option value="1">Discount for All Products</option>
                                    </select>
                                </th>
                                <th class="text-center clmwidth"> Total Price (₹)</th>

                                <th class="text-center" style="width: 1.5%;"></th>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let field of QuotationData.QuotationObj;let i = index;">
                                    <tr>
                                        <td>
                                            <select class="form-control" style="border: none;padding: 10px;"
                                                placeholder="Select Series" [(ngModel)]="field.ProductSeries"
                                                #ProductSeries="ngModel" name="ProductSeries{{i}}"
                                                (change)="onProductSeriesChange(i)">
                                                <option value="undefined" selected disabled>Select</option>
                                                <option *ngFor="let ser of dropdownData.ProductSeries"
                                                    value="{{ser.Id}}">
                                                    {{ser.Name}}
                                                </option>
                                            </select>
                                            <div *ngIf="(field.ProductSeriesValidation)">
                                                <span class="validationmsg">
                                                    ProductSeries is required</span>
                                            </div>
                                        </td>
                                        <td>

                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedProductModel?.length!=0" placeholder="Select Model"
                                                [(ngModel)]="field.ProductModel" #ProductModel="ngModel"
                                                name="ProductModel{{i}}" (change)="onProductModelChange(i)">
                                                <option value="undefined" selected disabled>Select</option>
                                                <option *ngFor="let ser of field.selectedProductModel"
                                                    value="{{ser.Id}}">
                                                    {{ser.Name}}
                                                </option>
                                            </select>
                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedProductModel?.length==0" placeholder="Select Model"
                                                [(ngModel)]="field.ProductModel" #ProductModel="ngModel"
                                                name="ProductModel{{i}}" (change)="onProductModelChange(i)">
                                                <option value="undefined" selected disabled>Select</option>
                                                <option *ngFor="let ser of productDrpdnDetails.ProductModel"
                                                    value="{{ser.Id}}">
                                                    {{ser.Name}}
                                                </option>
                                            </select>
                                            <div *ngIf="(field.ProductModelValidation)">
                                                <span class="validationmsg">
                                                    Product Model is required</span>
                                            </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control m-input" id="productModelPrice{{i}}"
                                                name="productModelPrice{{i}}" [(ngModel)]="field.ProductModelPrice"
                                                style="border:none; text-align:right;" disabled>
                                        </td>
                                        <td>
                                            <input type="number" class="form-control m-input" min="1" step="1"
                                                (change)="onQuatityChange(i)" value="0" name="Quantity{{i}}"
                                                maxlength="9" [(ngModel)]="field.Quantity"
                                                (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                style="text-align:right;" [disabled]="isQtyDiscountEditable">
                                            <div *ngIf="(field.QuantityValidation)">
                                                <span class="validationmsg">
                                                    Quantity is not valid</span>
                                            </div>
                                            <div *ngIf="(field.QuantityRangeValidation)">
                                                <span class="validationmsg">
                                                    Quantity should be in between 0 to 1000</span>
                                            </div>
                                        </td>
                                        <td style="vertical-align: bottom;" >
                                        <input type="number" class="form-control m-input" style="text-align:right;"
                                            min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                            name="Model_Discount{{i}}" 
                                            (keypress)="AvoidSpace($event);isNumberKey($event);"
                                            [(ngModel)]="field.Model_Discount" [disabled]="isfetchfromRFQ">

                                    </td>

                                    <td style="vertical-align: bottom;" >
                                        <input type="number" class="form-control m-input" style="text-align:right;"
                                            min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                            name="Model_FinalPrice{{i}}" [(ngModel)]="field.Model_FinalPrice"
                                            disabled>
                                      
                                    </td>
                                       
                                        <td class="text-center"
                                            style="padding: 10px!important; border-radius: 0px!important;cursor: pointer;">
                                            <i class="flaticon-delete" style="color:#f4516c" (click)="DeleteProduct(i)"
                                                *ngIf="(QuotationData.QuotationObj.length-1) == i"
                                                title="Remove"><br></i>
                                        </td>
                                    </tr>
                                    <tr [attr.colspan]="field.colspan1" *ngIf="field.colspan1!=0">
                                        <td></td>

                                        <td [attr.colspan]="field.colspan1" *ngIf="field.colspan1!=0"
                                            class="text-center">

                                            <div [attr.colspan]="field.colspan1" *ngIf="field.colspan1!=0"
                                                class="text-center lblhd">{{field.Variant1Label}}
                                            </div>

                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant1?.length!=0 && !(field.Variant1Label == null || field.Variant1Label == null || field.Variant1Label == '')"
                                                [(ngModel)]="field.Variant1" #var1="ngModel" name="var1{{i}}"
                                                placeholder="Select.." (change)="onVariant1Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient1 of field.selectedVariant1"
                                                    value="{{varient1.Id}}">
                                                    {{varient1.Name}}
                                                </option>
                                            </select>
                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant1?.length==0 && !(field.Variant1Label == null || field.Variant1Label == null || field.Variant1Label == '')"
                                                [(ngModel)]="field.Variant1" #var1="ngModel" name="var1{{i}}"
                                                placeholder="Select.." (change)="onVariant1Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient1 of productDrpdnDetails.Variant1"
                                                    value="{{varient1.Id}}">
                                                    {{varient1.Name}}
                                                </option>
                                            </select>
                                            <div *ngIf="(field.Variant1Validation)">
                                                <span class="validationmsg">
                                                    {{field.Variant1Label}} is required</span>
                                            </div>
                                            <div *ngIf="field.IsUnits_Variant1"
                                                style="text-align: left;margin-top: -9px;">
                                                <label style="margin-bottom: 0px;" class="lblhd">Units:</label>
                                                <input type="number" class="form-control m-input" min="1"
                                                    style="text-align:right;" (change)="var1UnitsChange(i)" value="0"
                                                    name="var1Units{{i}}"
                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var1_Unit_Validation }"
                                                    [(ngModel)]="field.Units_Variant1">
                                            </div>
                                        </td>
                                        <td [attr.colspan]="field.colspan1" *ngIf="field.colspan1!=0" class="fldlbl">
                                            <span style="color: #af0a25;font-size: 7pt;"
                                                *ngIf="field.IsPriceOnRequest_Variant1">
                                                Price on Request
                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                    [disabled]="priceonRequestDisabled" style="text-align:right;"
                                                    class="form-control m-input" min="0" (change)="var1PORChange(i)"
                                                    value="0" (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var1_POR_Validation }"
                                                    name="Variant1Price{{i}}" [(ngModel)]="field.Variant1Price">
                                            </span>
                                            <div class="divnotEditable" *ngIf="!field.IsPriceOnRequest_Variant1" >
                                                ₹ {{field.Variant1Price == undefined ? 0: field.Variant1Price |
                                                indianCurrency}}/-
                                            </div>
                                        </td>
                                        <td></td>
                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan1"
                                            *ngIf="field.colspan1!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient1_Discount{{i}}" 
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Varient1_Discount" [disabled]="isfetchfromRFQ">

                                        </td>


                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan1"
                                            *ngIf="field.colspan1!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient1_FinalPrice{{i}}" [(ngModel)]="field.Varient1_FinalPrice"
                                                disabled>
                                            <b><span *ngIf="field.Variant1Price<0">{{field.Variant1Price}} is
                                                    substracted from Total.</span></b>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr [attr.colspan]="field.colspan2" *ngIf="field.colspan2!=0">
                                        <td></td>
                                        <td [attr.colspan]="field.colspan2" *ngIf="field.colspan2!=0"
                                            class="text-center">
                                            <div [attr.colspan]="field.colspan2" *ngIf="field.colspan2!=0"
                                                class="text-center lblhd" style="background-color:#ededed">
                                                {{field.Variant2Label}}
                                            </div>

                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant2?.length!=0 && !(field.Variant2Label == null || field.Variant2Label == null || field.Variant2Label == '')"
                                                [(ngModel)]="field.Variant2" #var2="ngModel" name="var2{{i}}"
                                                placeholder="Select.." (change)="onVariant2Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient2 of field.selectedVariant2"
                                                    value="{{varient2.Id}}">
                                                    {{varient2.Name}}
                                                </option>
                                            </select>
                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant2?.length==0 && !(field.Variant2Label == null || field.Variant2Label == null || field.Variant2Label == '')"
                                                [(ngModel)]="field.Variant2" #var2="ngModel" name="var2{{i}}"
                                                placeholder="Select.." (change)="onVariant2Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient2 of productDrpdnDetails.Variant2"
                                                    value="{{varient2.Id}}">
                                                    {{varient2.Name}}
                                                </option>
                                            </select>
                                            <div *ngIf="(field.Variant2Validation)">
                                                <span class="validationmsg">
                                                    {{field.Variant2Label}} is required</span>
                                            </div>
                                            <div *ngIf="field.IsUnits_Variant2"
                                                style="text-align: left;margin-top: -9px;">
                                                <label style="margin-bottom: 0px;">Units:</label>
                                                <input type="number" class="form-control m-input" min="1"
                                                    style="text-align:right;"
                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var2_Unit_Validation }"
                                                    (change)="var2UnitsChange(i)" value="0" name="var2Units{{i}}"
                                                    [(ngModel)]="field.Units_Variant2">
                                            </div>
                                        </td>
                                        <!---->
                                        <td [attr.colspan]="field.colspan2" *ngIf="field.colspan2!=0" class="fldlbl">
                                            <span style="color: #af0a25;font-size: 7pt; "
                                                *ngIf="field.IsPriceOnRequest_Variant2">
                                                Price on Request
                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                    [disabled]="priceonRequestDisabled" style="text-align:right;"
                                                    class="form-control m-input" min="0" (change)="var2PORChange(i)"
                                                    value="0" (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var2_POR_Validation }"
                                                    name="Variant2Price{{i}}" [(ngModel)]="field.Variant2Price">
                                            </span>
                                            <div class="divnotEditable" *ngIf="!field.IsPriceOnRequest_Variant2">
                                                ₹ {{field.Variant2Price == undefined ? 0: field.Variant2Price |
                                                indianCurrency}}/-
                                            </div>
                                        </td>
                                        <td></td>
                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan2"
                                            *ngIf="field.colspan2!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient2_Discount{{i}}" 
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Varient2_Discount" [disabled]="isfetchfromRFQ">
                                        </td>
                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan2"
                                            *ngIf="field.colspan2!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient2_FinalPrice{{i}}"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Varient2_FinalPrice" disabled>
                                            <b><span *ngIf="field.Variant2Price<0">{{field.Variant2Price}} is
                                                    substracted from Total.</span></b>
                                        </td>
                                        <td></td>

                                    </tr>
                                    <tr [attr.colspan]="field.colspan3" *ngIf="field.colspan3!=0">
                                        <td></td>
                                        <td [attr.colspan]="field.colspan3" *ngIf="field.colspan3!=0"
                                            class="text-center">
                                            <div [attr.colspan]="field.colspan3" *ngIf="field.colspan3!=0"
                                                class="text-center lblhd" style="background-color:#ededed">
                                                {{field.Variant3Label}}
                                            </div>
                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant3?.length!=0 && !(field.Variant3Label == null || field.Variant3Label == null || field.Variant3Label == '')"
                                                [(ngModel)]="field.Variant3" #var3="ngModel" name="var3{{i}}"
                                                placeholder="Select.." (change)="onVariant3Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient3 of field.selectedVariant3"
                                                    value="{{varient3.Id}}">
                                                    {{varient3.Name}}
                                                </option>
                                            </select>
                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant3?.length==0 && !(field.Variant3Label == null || field.Variant3Label == null || field.Variant3Label == '')"
                                                [(ngModel)]="field.Variant3" #var3="ngModel" name="var3{{i}}"
                                                placeholder="Select.." (change)="onVariant3Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient3 of productDrpdnDetails.Variant3"
                                                    value="{{varient3.Id}}">
                                                    {{varient3.Name}}
                                                </option>
                                            </select>
                                            <div *ngIf="(field.Variant3Validation)">
                                                <span class="validationmsg">
                                                    {{field.Variant3Label}} is required</span>
                                            </div>
                                            <div *ngIf="field.IsUnits_Variant3"
                                                style="text-align: left;margin-top: -9px;">
                                                <label style="margin-bottom: 0px;">Units:</label>
                                                <input type="number" class="form-control m-input" min="1"
                                                    style="text-align:right;" (change)="var3UnitsChange(i)" value="0"
                                                    name="var3Units{{i}}"
                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var3_Unit_Validation }"
                                                    [(ngModel)]="field.Units_Variant3">
                                            </div>
                                        </td>

                                        <td [attr.colspan]="field.colspan3" *ngIf="field.colspan3!=0" class="fldlbl">
                                            <span style="color: #af0a25;font-size: 7pt;"
                                                *ngIf="field.IsPriceOnRequest_Variant3">
                                                Price on Request
                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                    [disabled]="priceonRequestDisabled" style="text-align:right;"
                                                    class="form-control m-input" min="0" (change)="var3PORChange(i)"
                                                    value="0" (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var3_POR_Validation }"
                                                    name="Variant3Price{{i}}" [(ngModel)]="field.Variant3Price">
                                            </span>
                                            <div class="divnotEditable" *ngIf="!field.IsPriceOnRequest_Variant3">
                                                ₹ {{field.Variant3Price == undefined ? 0: field.Variant3Price |
                                                indianCurrency}}/-
                                            </div>
                                        </td>
                                        <td></td>
                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan3"
                                            *ngIf="field.colspan3!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient3_Discount{{i}}" 
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Varient3_Discount" [disabled]="isfetchfromRFQ">
                                        </td>

                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan3"
                                            *ngIf="field.colspan3!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient3_FinalPrice{{i}}"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Varient3_FinalPrice" disabled>
                                            <b><span *ngIf="field.Variant3Price<0">{{field.Variant3Price}} is
                                                    substracted from Total.</span></b>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr [attr.colspan]="field.colspan4" *ngIf="field.colspan4!=0">
                                        <td></td>
                                        <td [attr.colspan]="field.colspan4" *ngIf="field.colspan4!=0"
                                            class="text-center">
                                            <div [attr.colspan]="field.colspan4" *ngIf="field.colspan4!=0"
                                                class="text-center lblhd" style="background-color:#ededed">
                                                {{field.Variant4Label}}
                                            </div>
                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant4?.length!=0 && !(field.Variant4Label == null || field.Variant4Label == null || field.Variant4Label == '')"
                                                [(ngModel)]="field.Variant4" #var4="ngModel" name="var4{{i}}"
                                                placeholder="Select.." (change)="onVariant4Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient4 of field.selectedVariant4"
                                                    value="{{varient4.Id}}">
                                                    {{varient4.Name}}
                                                </option>
                                            </select>
                                            <select class="form-control p-0" style="border: none;"
                                                *ngIf="field.selectedVariant4?.length==0 && !(field.Variant4Label == null || field.Variant4Label == null || field.Variant4Label == '')"
                                                [(ngModel)]="field.Variant4" #var4="ngModel" name="var4{{i}}"
                                                placeholder="Select.." (change)="onVariant4Change(i)">
                                                <option value=null selected disabled>Select</option>
                                                <option *ngFor="let varient4 of productDrpdnDetails.Variant4"
                                                    value="{{varient4.Id}}">
                                                    {{varient4.Name}}
                                                </option>
                                            </select>
                                            <div *ngIf="(field.Variant4Validation)">
                                                <span class="validationmsg">
                                                    {{field.Variant4Label}} is required</span>
                                            </div>
                                            <div *ngIf="field.IsUnits_Variant4"
                                                style="text-align: left;margin-top: -9px;">
                                                <label style="margin-bottom: 0px;">Units:</label>
                                                <input type="number" class="form-control m-input" min="1"
                                                    style="text-align:right;"
                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var4_Unit_Validation }"
                                                    (change)="var4UnitsChange(i)" value="0" name="var4Units{{i}}"
                                                    [(ngModel)]="field.Units_Variant4">
                                            </div>
                                        </td>

                                        <td [attr.colspan]="field.colspan4" *ngIf="field.colspan4!=0" class="fldlbl">
                                            <span style="color: #af0a25;font-size: 7pt;"
                                                *ngIf="field.IsPriceOnRequest_Variant4">
                                                Price on Request
                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                    [disabled]="priceonRequestDisabled" style="text-align:right;"
                                                    class="form-control m-input" min="0" (change)="var4PORChange(i)"
                                                    value="0" (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                    [ngClass]="{ 'is-invalid': field.var4_POR_Validation }"
                                                    name="Variant4Price{{i}}" [(ngModel)]="field.Variant4Price">
                                            </span>
                                            <div class="divnotEditable" *ngIf="!field.IsPriceOnRequest_Variant4">
                                                ₹ {{field.Variant4Price == undefined ? 0: field.Variant4Price |
                                                indianCurrency}}/-
                                            </div>
                                        </td>
                                        <td></td>
                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan4"
                                            *ngIf="field.colspan4!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient4_Discount{{i}}" 
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Varient4_Discount" [disabled]="isfetchfromRFQ">
                                        </td>

                                        <td style="vertical-align: bottom;" [attr.colspan]="field.colspan4"
                                            *ngIf="field.colspan4!=0">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Varient4_FinalPrice{{i}}"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Varient4_FinalPrice" disabled>
                                            <b><span *ngIf="field.Variant4Price<0">{{field.Variant4Price}} is
                                                    substracted from Total.</span></b>
                                        </td>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td class="text-center"
                                            style="padding: 10px!important; border-radius: 0px!important;">
                                            <div class="text-center lblhd" style="background-color:#ededed">
                                                Select Options
                 </div>

                                            <mat-select class="fa fa-tasks" name="opt{{i}}" multiple
                                                [(ngModel)]="field.OptionSelected" placeholder=""
                                                *ngIf="field.selectedOptions?.length==0" style="border: none;">
                                                <mat-option *ngFor="let item of Options" value="{{item.Id}}"
                                                    (onSelectionChange)="onOptionSelect($event,i)"
                                                    title="{{item.Name}}">
                                                    {{item.Name}}</mat-option>

                                            </mat-select>
                                            <mat-select class="fa fa-tasks" name="opt{{i}}" placeholder="" multiple
                                                [(ngModel)]="field.OptionSelected"
                                                *ngIf="field.selectedOptions?.length!=0"
                                                style="border: none; height: 30px; color: #fff; margin: 4px; width: 96%; border-radius: 0px;">
                                                <mat-option *ngFor="let item of field.selectedOptions"
                                                    title="{{item.Name}}" value="{{item.Id}}"
                                                    (onSelectionChange)="onOptionSelect($event,i)">
                                                    {{item.Name}}</mat-option>
                                            </mat-select>


                                            <div *ngFor="let j of field.Options;let o = index">
                                                &nbsp;&nbsp;{{o+1}}. {{j.Name}}(₹ {{j.Price == undefined ? 0:
                                                j.Price |indianCurrency}})<br />
                                            </div>
                                            <div *ngIf="(field.OptionsValidation)">
                                                <span class="validationmsg">
                                                    Options are required</span>
                                            </div>

                                        </td>

                                        <td class="fldlbl">
                                            <div *ngIf="field.IsPriceOnRequest_Option">
                                                <span style="color: #af0a25;font-size: 7pt;">Price on Request</span>


                                            </div>
                                            <span class="text-center" *ngIf="btn_PriceOnRequest"
                                                style="padding: 10px!important; border-radius: 0px!important;cursor: pointer; display: block;">
                                                <i class="fa fa-tasks"
                                                    *ngIf="field.IsPriceOnRequest_Option || priceonRequestDisabled"
                                                    (click)="ShowOptionPriceOnRequestGrid(i)"
                                                    title="Open option price on request grid"></i>

                                            </span>
                                            <div class="divnotEditable" *ngIf="!field.IsPriceOnRequest_Option">
                                                ₹ {{field.OptionsPrice == undefined ? 0:
                                                field.OptionsPrice |indianCurrency}}/-
                                            </div>
                                        </td>
                                        <td></td>
                                        <td style="vertical-align: bottom;">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Option_Discount{{i}}" 
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Option_Discount" [disabled]="isfetchfromRFQ">
                                        </td>

                                        <td style="vertical-align: bottom;">
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="Option_FinalDiscount{{i}}"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Option_FinalDiscount" disabled>
                                            <b><span *ngIf="field.optionprice<0">{{field.optionprice}} is substracted
                                                    from Total.</span></b>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr *ngIf="field.ShowOptionPriceOnRequestGrid">
                                        <td colspan="14">
                                            <div style="overflow-x:auto;">
                                                <table class="table table-bordered table-edit"
                                                    style="width: 57%;margin-top: 20px;">
                                                    <thead>
                                                        <tr>
                                                            <th>Product Options</th>
                                                            <th style="width: 20%;">Price (₹)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let t1 of field.Options;let j = index; ">
                                                            <td *ngIf="t1.IsPriceOnRequest">{{t1.Name}}</td>
                                                            <td *ngIf="t1.IsPriceOnRequest">
                                                                <input type="number" *ngIf="btn_PriceOnRequest"
                                                                    [disabled]="priceonRequestDisabled"
                                                                    id="Price{{i}}{{j}}" style="text-align:right;"
                                                                    class="form-control m-input" min="0"
                                                                    (change)="varOptionPORChange(i,j)" value="0"
                                                                    (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    [ngClass]="{ 'is-invalid': t1.optionValid }"
                                                                    name="Price{{i}}{{j}}" [(ngModel)]="t1.Price">
                                                                <div *ngIf="(t1.optionValid)">

                                                                    <span *ngIf="!priceonRequestDisabled"
                                                                        class="validationmsg">
                                                                        Please enter valid price</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td class="text-center"
                                            style="padding: 10px!important; border-radius: 0px!important;">
                                            <div class="text-center lblhd" style="background-color:#ededed">Technical
                                                Specifications:
                                            </div>
                                            <i class="fa fa-tasks" (click)="showTechnicalSpecifications(i)"
                                                title="Open Technical Specifications"></i>

                                        </td>


                                        <td></td>
                                        <td class="text-center"></td>
                                        <td style="vertical-align: bottom;">

                                            <div class="text-center lblhd" style="background-color:#ededed">Overall Discount
                                            </div>
                                            <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)"
                                                (focusout)="setDiscountType()" value="0" name="Discount{{i}}"
                                                maxlength="2" (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.Discount" [disabled]="isAppEngRoles || showOverallDiscount">
                                        </td>
                                        <td>
                                            <div class="text-center lblhd" style="background-color:#ededed">Total Price (₹)
                                            </div>
                                           
                                                <input type="number" class="form-control m-input" style="text-align:right;"
                                                min="0" max="99.99" step="0.01" (change)="onDiscountChange(i)" value="0"
                                                name="TotalAfterDiscount{{i}}"
                                                (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                [(ngModel)]="field.TotalAfterDiscount" disabled>
    
                                                 
                                        </td>

                                        <td></td>
                                    </tr>
                                    <tr *ngIf="field.TechnicalSpecifications?.length>0">
                                        <td></td>
                                        <td class="text-center"
                                            style="padding: 10px!important; border-radius: 0px!important;">
                                            <div style="display: flex;margin-bottom: 5px;"
                                                *ngFor="let t of field.TechnicalSpecifications;let s = index;">

                                                <textarea class="form-control m-input" id="techspec" maxlength="1000"
                                                    name="{{t.uniqueid}}{{s}}" rows="9" [(ngModel)]="t.value">{{t.value}}
                                                    </textarea>
                                            </div>
                                        </td>
                                        <td></td>

                                        <td></td>
                                        <td></td>
                                       <td></td>
                                        <td></td>
                                    </tr>
                                </ng-container>

                                <tr style="width: 100%;font-weight: bold;">

                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td style="vertical-align: top;">
                                        <div class="text-center lblhd" style="background-color:#ededed">Special
                                            Package Discount
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" style="text-align:right;" class="form-control m-input"
                                            min="0" name="specialpkgdiscount" value="0" [(ngModel)]="specialPkgDiscount"
                                            (change)="setspecialDiscountPrice()" 
                                            (keypress)="AvoidSpace($event);isNumberKey($event);" name="Var"
                                            [disabled]="isQtyDiscountEditable">
                                    </td>

                                    <td class="text-center"></td>
                                    <td class="text-center"></td>

                                </tr>
                                <tr style="background-color:#f6d7d7;width: 100%;font-weight: bold;">

                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-center"></td>
                                    <td class="text-center">Total</td>
                                    <td class="text-center">
                                        <input type="number" style="text-align:right;" class="form-control m-input"
                                            min="0" value="0" [(ngModel)]="this.QuotationData.QuotationInfo.FinalAmount"
                                            (keypress)="AvoidSpace($event);isintNumberKey($event);" disabled="true"
                                            name="Total">
                                    </td>

                                    <td class="text-center"></td>
                                </tr>

                                <!--*********************Comments Section************************** -->
                                <tr>
                                    <td colspan="8">

                                        <div>

                                            <label>Comments:<span style="color:#f4516c;">&nbsp;*</span></label>
                                            <div style="display: flex;margin-bottom: 5px;width: 50%;">

                                                <textarea class="form-control m-input" id="Quotecomments"
                                                    name="Quotecomments" rows="3" maxlength="1500"
                                                    [(ngModel)]="QuotationData.QuotationInfo.QuotationComments"
                                                    (keyup)="limitTextOnKeyUpDown1($event)" #QuoteComm
                                                    (keypress)="limitTextOnKeyUpDown1($event)" #Quotecomments="ngModel"
                                                    [ngClass]="{ 'is-invalid': (QuoteCommentsValidation) }" required>
                                            </textarea>

                                            </div>
                                        </div>
                                        <div>
                                            <span> Limit {{maxLimit}} characters,
                                                {{remLimit}} characters
                                                remaining.</span>

                                            <div *ngIf="QuoteCommentsValidation" style="color: #af0a25;">
                                                Comment is
                                                required
                                            </div>

                                        </div>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <table class="table order-lists table-cust bord mt-5">
                            <tbody>
                                <tr>
                                    <td
                                        style="text-align:center;border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2; max-width: 25% !important;">
                                        <span
                                            style="background:linear-gradient(to right, #484c4d 0%, #818589 50%, #787b7c 100%); color:#fff; padding:5px; margin:-3px; display:block;">
                                            <strong>Show Variant and Option Price on Quotation</strong>
                                        </span>
                                        <input (change)="checkVarientPrice($event)"
                                            [checked]="showVarientandOptionPrice" type="checkbox" id="check1"
                                            name="check1" style="margin-top: 20px">
                                    </td>
                                    <td
                                        style="text-align:center;border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2; max-width: 25% !important;">
                                        <span
                                            style="background:linear-gradient(to right, #484c4d 0%, #818589 50%, #787b7c 100%); color:#fff; padding:5px; margin:-3px; display:block;">
                                            <strong>Show Variant and Option Discount On Quotation</strong>
                                        </span>
                                        <input (change)="checkVarientDiscount($event)" [checked]="showVarientDiscount"
                                            type="checkbox" id="check2" name="check2" style="margin-top: 20px">
                                    </td>

                                    <td
                                        style="text-align:center;border-top:1px solid #a2a2a2; border-bottom:1px solid #a2a2a2; max-width: 25% !important;">
                                        <span
                                            style="background:linear-gradient(to right, #484c4d 0%, #818589 50%, #787b7c 100%); color:#fff; padding:5px; margin:-3px; display:block;">
                                            <strong>Show Special Price on Quotation</strong>
                                        </span>
                                        <input (change)="checkSpecialPrice($event)" [checked]="showSpecialPrice"
                                            type="checkbox" id="check3" name="check3" style="margin-top: 20px">
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </form>
            </div>
        </div>

        <div class="modal-footer p-2 pr-4" style="float: right;">
            <div class="button-row text-right mt-4" style="margin:15px;">


                <div class="m-portlet__head-caption">
                    <span *ngFor="let item of workflowActionList;let i = index;">
                        <button class="btn btn-primary ml-auto js-btn-next btnspc" type="{{item.workflowActionType}}"
                            (click)="OnWorkflowAction(QuotationForm,item.workflowActionId)">
                            {{item.workflowActionName}}
                        </button> &nbsp; &nbsp;
                    </span>
                    <button type="button" class="btn btn-metal arrangecancelbutton"
                        (click)="closePopup()">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- </div> -->


<!-- *****************HEADER HTML************************************************************************************************************************************** -->
<div id="ExportQuotationHeader" hidden>
    <html>

    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style>
            body {
                font-size: 10px;
                font-family: Arial, Helvetica, sans-serif;
            }
        </style>
    </head>

    <body class="borderall"
        style="padding: 15px;border: #000000;font-size: 10px; font-family: Arial, Helvetica, sans-serif">
        <div style="padding:12px;">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div style="text-align: end;">
                        <img alt="" style="width: 16%;margin-bottom: 8px;" [src]="WeissLogoPath" />
                    </div>

                    <div class="m-portlet__head-title" style="background: linear-gradient(to right, #484c4d 0%, #818589 50%, #787b7c 100%);
                    border-color: #818589;text-align: center;
                    display: flex;
                    color: white">
                        <h3 style="padding: 4px;width: 100%; font-size:24px;">
                            Quotation
                        </h3>
                    </div>
                </div>
            </div>

            <div style="width: 100%;margin-top: 25px;">
                <thead style="display:table-header-group;">
                    <div style="border:none;margin-bottom:-1px;">
                        <table
                            style="width: 100%;border-bottom:1px solid #a2a2a2 ;border-top:1px solid #a2a2a2 ;border-left:1px solid #a2a2a2 ;border-right:1px solid #a2a2a2 ;"
                            class="">
                            <tr>
                                <td
                                    style="border-right:1px solid #a2a2a2; border-bottom: none;padding-top: 5px !important;text-align:left; width:50%;">
                                    <b>WEISS
                                        AUTOMATION SOLUTIONS INDIA PRIVATE LIMITED</b>
                                </td>
                                <td style="text-align:left;padding: 2px !important; vertical-align: top;">
                                    Quotation&nbsp;No.:

                                </td>
                                <td style="text-align:left; vertical-align: top;">
                                    {{QuotationData.QuotationInfo.QuotationNumber}}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="border-right:1px solid #a2a2a2;text-align:left;padding: 2px !important; vertical-align: top;">
                                    Plot
                                    PAP-S-126/1 , Chakan
                                    Industrial Area, Phase II,</td>
                                <td style="text-align:left;padding: 2px !important; vertical-align: top;">Date:
                                </td>
                                <td style="text-align:left; vertical-align: top;">
                                    {{QuotationData.QuotationInfo.QuotationDate | date: "dd-MMM-yyyy"}}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="border-right:1px solid #a2a2a2; text-align:left;padding: 2px !important; vertical-align: top;">
                                    Village- Bhamboli, Taluka
                                    – Khed, Chakan</td>
                                <td style="text-align:left;padding: 2px !important; vertical-align: top;">Your Ref.:
                                </td>
                                <td style="text-align:left;word-break: break-all; vertical-align: top;">
                                    {{QuotationData.QuotationInfo.YourReference}}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="border-right:1px solid #a2a2a2; text-align:left;padding: 2px !important; vertical-align: top;">
                                    Pune
                                    – 410501</td>
                                <td style="text-align:left;padding: 2px !important; vertical-align: top;">Ref. Date:
                                </td>
                                <td style="text-align:left">
                                    {{QuotationData.QuotationInfo.ReferenceDate | date: "dd-MMM-yyyy"}}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="border-right:1px solid #a2a2a2; text-align:left;padding: 0px !important; vertical-align: top;">
                                    Email:
                                    info@weiss-india.com</td>
                                <!-- <td style="text-align:left;padding: 0px !important;"></td> -->
                                <!-- <td style="text-align:left;padding: 0px !important;"></td> -->
                                <td style="text-align:left;padding: 2px !important; vertical-align: top;">Sales Person
                                    Name:</td>
                                <td style="text-align:left">{{QuotationData.QuotationInfo.InitiatorName}}</td>
                            </tr>
                            <tr>
                                <td style="border-right:1px solid #a2a2a2; text-align:left;padding: 0px !important;">
                                </td>
                                <td style="text-align:left;padding: 2px !important;">Contact No.:</td>
                                <td style="text-align:left">{{QuotationData.QuotationInfo.InitiatorContactNo}}</td>
                            </tr>
                        </table>
                    </div>

                    <table
                        style="width: 100%;border-bottom:1px solid #a2a2a2 ;border-top:1px solid #a2a2a2 ;border-left:1px solid #a2a2a2 ;border-right:1px solid #a2a2a2 ;">
                        <tr>
                            <td colspan="2" style="text-align:left;padding-top: 2px !important;">To,</td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align:left;padding-top: 2px !important;">
                                <div style="margin-left: 60px;">
                                    <b>{{CustomerDetailsObj.CustomerName}}
                                        <br />{{CustomerDetailsObj.City}}, {{CustomerDetailsObj.State}}</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid #a2a2a2;">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><b>Kind Attn:&nbsp;</b></td>
                                            <td>&nbsp;</td>
                                            <td style="word-break: break-all;">
                                                {{QuotationData.QuotationInfo.KindAttention}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>{{QuotationData.QuotationInfo.KindAttContactNo}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td style="word-break: break-all;">
                                                {{QuotationData.QuotationInfo.KindAttEmailId}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>

                        </tr>

                        <tr>
                            <td colspan="2" style="padding: 5px !important;">We thank you for your interest in our
                                Products. Referring to your
                                enquiry and according to our general terms & Conditions, We would like to send you
                                our non-binding offer as below:</td>
                        </tr>

                    </table>
                </thead>
            </div>
        </div>
    </body>

    </html>
</div>
<!-- *****************HEADER HTML************************************************************************************************************************************** -->
<div id="ExportQuotation" hidden>

    <html>

    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style>
            body {
                font-size: 10px;
            }
        </style>
    </head>

    <body class="borderall" style="padding: 15px;border: #000000;font-size: 10px;">
        <style>
            table.print-friendly tr td,
            table.print-friendly tr th {
                page-break-inside: avoid;
            }

            table,
            th,
            td {
                padding: 0px;
            }

            table {
                border-spacing: 0px;
            }
        </style>
        <div style="padding:12px;">

            <div style="width: 100%;margin-top: 2px;border-top:1px solid #a2a2a2 ;">
                <table
                    style="border-collapse: collapse;font-size: 10pt !important; width: 100%;border-bottom:1px solid #a2a2a2 ;border-top:1px solid #a2a2a2 ;border-left:1px solid #a2a2a2 ;border-right:1px solid #a2a2a2 ;"
                    class="print-friendly" style="margin-bottom:-1px;">
                    <thead style="background: #8d8e91;color: #fff;display: table-header-group;">
                        <th style="width: 5%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">Sr. No.
                        </th>
                        <th style="width: 35%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">Product
                            Description - Part Number</th>
                        <th style="width: 5%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">UOM</th>
                        <th style="width: 8%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">Quantity
                        </th>
                        <th style="width: 12%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">Unit
                            Price (₹)</th>
                        <th style="width: 12%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">Total
                            Price (₹)</th>
                        <th style="width: 12%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">Discount
                            (%)
                        </th>
                        <th style="width: 15%;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">Final
                            Price (₹)</th>

                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of QuotationData.QuotationObj;let i = index;">
                            <tr style="padding: 5px;page-break-inside: avoid;">
                                <td
                                    style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                                    {{i+1}}</td>
                                <td
                                    style="text-align: left;padding: 5px;font-weight: bold;border-right: 1px solid #a2a2a2;">
                                    {{item.ProductModelName}}</td>
                                <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;">No.</td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.Quantity}}</td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.PerUnitAmount |indianCurrency}}</td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.TotalAmount |indianCurrency}}</td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.Discount == 0 ? 'Nil' : item.Discount}}</td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.FinalAmount |indianCurrency}}</td>
                            </tr>
                            <tr *ngIf="!(item.Variant1Label==undefined || item.Variant1Label==null || item.Variant1Label=='')"
                                style="padding: 5px;page-break-inside: avoid;">
                                <td
                                    style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                                </td>
                                <td style="text-align: left;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.Variant1Label}}: {{item.Variant1Name}}
                                    <span *ngIf="item.IsUnits_Variant1">- {{item.Units_Variant1}}</span>
                                </td>
                                <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            </tr>
                            <tr *ngIf="!(item.Variant2Label==undefined || item.Variant2Label==null || item.Variant2Label=='')"
                                style="padding: 5px;page-break-inside: avoid;">
                                <td
                                    style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                                </td>
                                <td style="text-align: left;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.Variant2Label}}: {{item.Variant2Name}}
                                    <span *ngIf="item.IsUnits_Variant2">- {{item.Units_Variant2}}</span>
                                </td>
                                <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            </tr>
                            <tr *ngIf="!(item.Variant3Label==undefined || item.Variant3Label==null || item.Variant3Label=='')"
                                style="padding: 5px;page-break-inside: avoid;">
                                <td
                                    style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                                </td>
                                <td style="text-align: left;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.Variant3Label}}: {{item.Variant3Name}}
                                    <span *ngIf="item.IsUnits_Variant3">- {{item.Units_Variant3}}</span>
                                </td>
                                <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            </tr>
                            <tr *ngIf="!(item.Variant4Label==undefined || item.Variant4Label==null || item.Variant4Label=='')"
                                style="padding: 5px;page-break-inside: avoid;">
                                <td
                                    style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                                </td>
                                <td style="text-align: left;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{item.Variant4Label}}: {{item.Variant4Name}}
                                    <span *ngIf="item.IsUnits_Variant4">- {{item.Units_Variant4}}</span>
                                </td>
                                <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            </tr>
                            <tr *ngFor="let opt of item.Options;let i = index;"
                                style="padding: 5px;page-break-inside: avoid;">
                                <td 
                                    style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                                </td>
                                <td style="text-align: left;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{opt.Name}}
                                </td>
                                <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            </tr>
                            <tr *ngFor="let techspec of item.separatedTechSpecs;let i = index;"
                                style="padding: 5px;page-break-inside: avoid;">
                                <td
                                    style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                                </td>
                                <td style="text-align: left;padding: 5px;border-right: 1px solid #a2a2a2;">
                                    {{techspec}}
                                </td>
                                <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                                <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            </tr>
                        </ng-container>
                        <tr style="padding: 5px;background-color:#f6d7d7;page-break-inside: avoid;">
                            <td
                                style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;border-left: 1px solid #a2a2a2;">
                            </td>
                            <td style="text-align: left;padding: 5px;border-right: 1px solid #a2a2a2;">
                                Total
                            </td>
                            <td style="text-align: center;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                {{QuotationData.QuotationInfo.PerUnitAmount |indianCurrency}}</td>
                            <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                {{QuotationData.QuotationInfo.TotalAmount |indianCurrency}}</td>
                            <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;"></td>
                            <td style="text-align: right;padding: 5px;border-right: 1px solid #a2a2a2;">
                                {{QuotationData.QuotationInfo.FinalAmount |indianCurrency}}</td>
                        </tr>
                    </tbody>

                    <tfoot style="display: table-footer-group">
                        <td colspan="8" style="border-top: 1px solid #a2a2a2;"></td>

                    </tfoot>
                </table>
                <br />

                <table class="print-friendly"
                    style="width: 100%;border-bottom:1px solid #a2a2a2 ;border-top:1px solid #a2a2a2 ;border-left:1px solid #a2a2a2 ;border-right:1px solid #a2a2a2 ;">
                    <thead style="background: #8d8e91;height:40px !important;color: #fff;display: table-header-group;">
                        <!-- <th style="page-break-inside: avoid;text-align: center;" colspan="4">
                            <b>Terms &amp; Conditions</b>
                        </th> -->
                    </thead>

                    <tr style="page-break-inside: avoid;">
                        <td style="border: 1px solid #a2a2a2;">
                            <div style="text-align: left;">
                                <b>Terms &amp; Conditions</b>
                            </div>
                            <div *ngIf="isenqTcSelected ; else CustTC">
                                <table>
                                    <tbody>
                                        <tr *ngFor="let item of TermsandConditions;let i = index;">
                                            <td> {{i + 1}}.</td>
                                            <td></td>
                                            <td>{{item.TnCName}}</td>
                                            <td></td>
                                            <td>:</td>
                                            <td></td>
                                            <td>{{item.TnCValue}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <ng-template #CustTC>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr *ngFor="let item of CustomTermsAndConditions;let i = index;">
                                                <td> {{i + 1}}.</td>
                                                <td></td>
                                                <td>{{item.StandardTCName}}</td>
                                                <td></td>
                                                <td>:</td>
                                                <td></td>
                                                <td>{{item.CustValue}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>

                        </td>
                        <td style="border: 1px solid #a2a2a2;width: 30%;;">
                            <div style="text-align:center;padding: 10px !important;">
                                Looking forward to your Valued Purchase Order. <br><br>
                                Thanking you !!<br><br><br>
                                This is system generated quote and valid without signature.
                                <br>
                            </div>

                        </td>

                    </tr>

                </table>
            </div>
        </div>
    </body>

    </html>
</div>