<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Customer Frame Agreement For : {{CustomerNameDetails[0]?.CompanyName}}
                                    </h3>
                                </div>
                            </div>

                        </div>


                        <form class="m-form m-form--fit m-form--label-align-right" name="myform"
                            (ngSubmit)="myform.form.valid" #myform="ngForm" novalidate>

                            <div class="m-portlet__body">
                                    <div style="margin-left: 900px;margin-bottom:10px;" *ngIf="!IsNotApproveRejectButton"><span  *ngFor="let item of workflowActions;let i = index;">
                                        <button  
                                          class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air" (click)="OnWorkflowActionForApproval(myform,item.next_state_id)" >
                                        {{item.actionname}}
                                     </button> &nbsp; &nbsp;
                                    </span></div>
                                <!-- <div class="offset-1" style="margin-left: 1000px;"><p>If you dont want a discount for model then kept field blank.</p></div>
                               -->
                                <div class="form-group m-form__group row">


                                    <div class="table-responsive">
                                        <table
                                            class="table order-list table-striped- table-bordered table-hover table-edit"
                                            id="myTable">

                                            <thead>
                                                <tr>

                                                    <th>
                                                        Product Model
                                                    </th>
                                                    <th>
                                                        Existing Frame Agreement Discount
                                                    </th>
                                                    <th>
                                                        New Frame Agreement Discount
                                                    </th>
                                                    <th>
                                                        Action
                                                    </th>

                                                </tr>
                                                    </thead>

                                            <tbody>

                                                    <!--
                                                        <tr *ngFor="let item of singleFieldObj.customerSpecialDiscountInfo;let i = index" >

                                                            <td>{{item.ModelName}}</td>
                                                            <td><input type="text" name="ProductDiscount{{i+1}}" [(ngModel)]="item.ProductDiscount" (change)="OnDiscountChange(i)" class="form-control m-input" id="fromDiscount" [disabled]="!editFormValues"></td>
                                                           <td></td>
                                                        </tr> -->
                                                <ng-container
                                                    *ngFor="let field of singleFieldObj.customerSpecialDiscountInfo ; let i = index;">
                                                    <tr>
                                                        <td>
                                                            <select class="form-control" id="productmodel{{i+1}}"
                                                                name="productmodel{{i+1}}"
                                                                [(ngModel)]="field.Fk_ProductModel"
                                                                #productmodel="ngModel"
                                                                (change)="OnProductModelChange(i)" required [disabled]="!editFormValues">
                                                                <option value="null" disabled>Select
                                                                    Product Model</option>

                                                                <option *ngIf="showItems!=undefined"
                                                                    value="{{showItems[i]?.value.Id}}">
                                                                    {{showItems[i]?.value.ModelName}}
                                                                </option>
                                                                <option *ngFor="let item of ProductModel"
                                                                    value="{{item.Id}}">
                                                                    {{item.ModelName}}
                                                                </option>

                                                            </select>
                                                            <div *ngIf="(field.ProductModelRequired)">
                                                                <span class="validationmsg">
                                                                    Product Model is required</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" id="approve{{i+1}}" name="approve{{i+1}}"
                                                                class="form-control input-sm responsiveinput"
                                                                [(ngModel)]="field.ProductDiscount" #approve="ngModel"
	                                                            (keypress)="OnDiscountChange(i)"
                                                                placeholder="Enter Discount" required disabled>

                                                        </td>

                                                        <td>
                                                            <input type="text" id="unapprove{{i+1}}"   name="unapprove{{i+1}}"
                                                                class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="field.UnapprovedProductDiscount" #unapprove="ngModel"
                                                                    (keypress)="OnDiscountChange(i)"
                                                                    placeholder="Enter Discount " maxlength="2" (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                   required [disabled]="!editFormValues"> 
                                                                
                                                        </td>

                                                        <td *ngIf="editFormValues">

                                                            <a title="Add"
                                                                *ngIf="(singleFieldObj.customerSpecialDiscountInfo.length>0) && ((singleFieldObj.customerSpecialDiscountInfo.length - 1 ) == i)"
                                                                (click)="AddNewRow(singleFieldObj.customerSpecialDiscountInfo.length+1)"
                                                                style="color:#000000;width: 25px;height: 25px;float: right;"
                                                                class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2">
                                                                <i class="fa fa-plus"></i>
                                                            </a>
                                                            <a title="Delete"
                                                                *ngIf="(singleFieldObj.customerSpecialDiscountInfo.length>1) && ((singleFieldObj.customerSpecialDiscountInfo.length - 1 ) == i)"
                                                                (click)="deleterow(i)"
                                                                style="color:#ffffff;width: 25px;height: 25px;"
                                                                class="btn btn-metal m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                    class="fa fa-minus"></i></a>
                                                        </td>
                                                        <td *ngIf="!editFormValues"></td>



                                                    </tr>
                                                </ng-container>


                                            </tbody>

                                        </table>

                                    </div>

                                    <label class="col-lg-1 col-form-label">Comment:<span
                                            style="color:#f4516c;">&nbsp;</span></label>
                                    <div class="col-lg-5">

                                        <textarea class="form-control m-input" id="comment" maxlength="1500"
                                            name="comment" rows="3" [(ngModel)]="singleFieldObj.Comment"
                                            (keyup)="limitTextOnKeyUpDown1($event)"
                                            (keypress)="limitTextOnKeyUpDown1($event)" #comment="ngModel"
                                            required=""></textarea>
                                            <span> Limit {{maxLimit}} characters,
                                                {{remLimit}} characters
                                                remaining.</span>
                                    </div>
                                    <div class="offset-1"></div>

                            </div>
                            </div>


                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10" >
                                            <span  *ngFor="let item of workflowActionList;let i = index;">
                                                <button [hidden]="!isCustomerFrameAgreementEditable"
                                                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air" 	type="{{item.action_type}}"
                                                    (click)="editForm()" *ngIf="!editFormValues">
                                                {{item.actionname}}
                                                </button> &nbsp; &nbsp;
                                             <button type="submit" class="btn btn-primary m-btn m-btn--air m-btn--custom" *ngIf="editFormValues" (click)="OnWorkflowAction(myform,item.next_state_id)">Save</button>&nbsp;&nbsp;
                                            </span>

                                            <!-- <button type="button" [hidden]="!isConfigureEditable"
                                                class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                *ngIf="!editFormValues" (click)="editForm()">Edit</button>&nbsp;&nbsp;
                                            <button type="submit" class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                *ngIf="editFormValues">Save</button>&nbsp;&nbsp; -->

                                            <button type="button" routerLink="/CustomerFrameAgreementDetails"
                                                routerLinkActive="m-menu__item--active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>


                </div>

            </div>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Workflow Action History
                            </h3>
                        </div>
                    </div>
                </div>
    
                <div class="m-portlet__body">
                    <div class="table-responsive">
                        <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="historydata" matSort
                                    class="table table-striped- table-bordered table-hover dataTable no-footer" role="grid">
    
                                    <!-- <ng-container matColumnDef="CustomerId">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>CustomerId
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">{{row.CustomerId}} </td>
                                                </ng-container> -->
                                    <ng-container matColumnDef="CustomerId">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CustomerId
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.CustomerId}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Action By">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action By
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.userName}} </td>
                                    </ng-container>
    
                                    <ng-container matColumnDef="Action By Role">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action By Role
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{row.userrole}}</td>
                                    </ng-container>
    
    
                                    <ng-container matColumnDef="action_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action
                                        </th>
                                        <td mat-cell *matCellDef="let row"> {{row.action_name}}</td>
                                    </ng-container>
    
                                    <ng-container matColumnDef="Action On">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action On
                                        </th>
                                        <td mat-cell *matCellDef="let row" style="text-align: left;">
                                            {{row.ActionOn | date: "dd-MMM-yyyy h:mm a"}} </td>
                                    </ng-container>
    
    
                                    <ng-container matColumnDef="Comment">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Comment </th>
                                        <td mat-cell *matCellDef="let row"> {{row.Comment}} </td>
                                    </ng-container>
    
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                                </table>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
    
        </div>
    </div>
</div>