export class AlertsModel {
    Pk_Id: number;
    Fk_Status: number;
    EnquiryStatus: string = "";
    First_Rem: number;
    Second_Rem: number;
    Third_Rem: number;
    First_Rem_ToWhom: number;
    Second_Rem_ToWhom: number;
    Third_Rem_ToWhom: number;
    CC_Rem_toWhom: number;
    ModifiedOn: any;
    Status: boolean;
    Escalation:number;

    First_Rem_ToWhomList:string[]=[];
    Second_Rem_ToWhomList:string[]=[];
    Third_Rem_ToWhomList:string[]=[];    
    CC_Rem_toWhomList:string[]=[];
    Escalation_ToWhomList:string[]=[];
}