<loader [showLoader]="showLoader"></loader>
<div
  class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="m-portlet">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    {{FormStatus}} Access and Permission Listing
                  </h3>
                </div>
              </div>
              <div class="m-portlet__head-tools">
                     
                      <select class="form-control" id="accesspermissiontype"
                          name="accesspermissiontype" (change)="OnElementTypechange($event.target.value)">
                          <option value="">Select Element Type</option>
                                                  
                          <option *ngFor="let item of elementTypeObj"
                              value="{{item.ElementType}}">
                              {{item.ElementType}}
                          </option> 
                        
                      </select>
                      <div class="offset-1"></div>
                      <select class="form-control" id="role"
                          name="role" (change)="OnSelectRole($event.target.value)">Roles : 
                          <option value="">Select Role </option>
                                                  
                          <option *ngFor="let item of Roles"
                              value="{{item.Name}}">
                              {{item.Name}}
                          </option> 
                        
                      </select>
              </div>
            
            </div>
            <div class="table-responsive">
                <form class="m-login__form m-form" name="myform" #myform="ngForm"
                (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                <div class="m-portlet__body">
                    
                    <div class="table-responsive" style="padding-left: 10px;">

                      <table class="table">

                        <thead>

                            <tr >

                                <th style="width:15%"><h4>Role Name</h4></th>
                                
                                <th style="width:15%"><h4>Element Type</h4></th>

                                <th style="width:15%"><h4>Element Name</h4></th>

                                <th  class="text-center"><h4>Can List</h4></th>

                                <th style="width:15%" class="text-center"> <h4>Can View</h4></th> 

                                <th  class="text-center"><h4>Can Create</h4></th>

                                <th style="width:15%" class="text-center"> <h4>Can Edit</h4></th> 

                            </tr>

                        </thead>

                        <tbody>

                            <ng-container *ngFor="let accessObj of accesspermissioninfo | keyvalue ;let j=index;" >
                                <tr>
                                       <td colspan="7">
                                        <h4>{{ accessObj.key }}</h4>
                                        </td>
                                       
                                </tr>
                                <tr>
                                  <td colspan="7" class="p-0">
                                <table *ngIf="isMenu" class="table">
                                  <tr>
                                    <td style="width:15%"></td>
                                    <td colspan="4" *ngIf="accesspermissioninfo[accessObj.key]['Menu']">

                                      <h5>Menu</h5>

                                    </td>
                                  
                                  </tr>
                                
                                  <tr *ngFor="let accessObj of accesspermissioninfo[accessObj.key]['Menu'];let i=index;">

                                      <td style="width:15%"></td>
                                      <td style="width:15%"></td>
                                      <td style="width:15% ;font-size: 14px;font-weight: 500;">{{ accessObj.ElementName }}</td>
                                      <td align="center"  class="text-center">
                                        <mat-checkbox name="CanListMenu{{i}}{{j}}" value="{{accessObj.CanList}}" [(ngModel)]="accessObj.CanList" id="CanListMenu{{i}}{{j}}" [disabled]="isEditable" >
                                        </mat-checkbox >
                                      </td>
                                      <td align="center"  style="width:15%" class="text-center">
                                        <mat-checkbox name="IsVisibleMenu{{i}}{{j}}" value="{{accessObj.IsVisible}}" [(ngModel)]="accessObj.IsVisible" id="IsVisibleMenu{{i}}{{j}}" [disabled]="isEditable">
                                        </mat-checkbox >
                                      </td>

                                      <td align="center"  class="text-center">
                                        <mat-checkbox name="CanCreateMenu{{i}}{{j}}" value="{{accessObj.CanCreate}}" [(ngModel)]="accessObj.CanCreate" id="CanCreateMenu{{i}}{{j}}" [disabled]="isEditable">
                                        </mat-checkbox >
                                      </td>
                                      <td align="center"  style="width:15%" class="text-center">
                                        <mat-checkbox name="IsEditableMenu{{i}}{{j}}" value="{{accessObj.IsEditable}}" [(ngModel)]="accessObj.IsEditable" id="IsEditableMenu{{i}}{{j}}" [disabled]="isEditable" >
                                        </mat-checkbox >
                                      </td>
                                  </tr>
                              </table>
                            </td>
                            </tr>
                            <tr>
                              <td colspan="7" class="p-0">
                            <table  *ngIf="isScreen" class="table">
                                <tr *ngIf="accesspermissioninfo[accessObj.key]['WorkFlowScreen']">
                                  <td colspan="1" style="width:15%"></td>
                                    <td colspan="1" style="width:15%">

                                        <h5>WorkFlowScreen</h5>

                                    </td>
                                    <td colspan="1" style="width:15%"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1" style="width:15%"></td>
                                    
                                </tr>

                                <tr *ngFor="let accessObj of accesspermissioninfo[accessObj.key]['WorkFlowScreen'];let i=index;">

                                  <td colspan="1" style="width:15%"></td>
                                  <td colspan="1" style="width:15%"></td>
                                  <td colspan="1"  style="width:15%;font-size: 14px;font-weight: 500;">{{ accessObj.ElementName }}</td>
                                  <td align="center"  class="text-center">
                                    <!-- <mat-checkbox name="CanListWorkFlowScreen{{i}}{{j}}" value="{{accessObj.CanList}}" [(ngModel)]="accessObj.CanList" id="CanListWorkFlowScreen{{i}}{{j}}" [disabled]="isEditable" >
                                    </mat-checkbox > -->
                                  </td>
                                  <td align="center"  style="width:15%" class="text-center">
                                    <mat-checkbox name="IsVisibleWorkFlowScreen{{i}}{{j}}" value="{{accessObj.IsVisible}}" [(ngModel)]="accessObj.IsVisible" id="IsVisibleWorkFlowScreen{{i}}{{j}}" [disabled]="isEditable">
                                    </mat-checkbox >
                                  </td>

                                  <td align="center"  class="text-center">
                                    <!-- <mat-checkbox name="CanCreateWorkFlowScreen{{i}}{{j}}" value="{{accessObj.CanCreate}}" [(ngModel)]="accessObj.CanCreate" id="CanCreateWorkFlowScreen{{i}}{{j}}" [disabled]="isEditable">
                                    </mat-checkbox > -->
                                  </td>
                                  <td align="center"  style="width:15%" class="text-center">
                                    <!-- <mat-checkbox name="IsEditableWorkFlowScreen{{i}}{{j}}" value="{{accessObj.IsEditable}}" [(ngModel)]="accessObj.IsEditable" id="IsEditableWorkFlowScreen{{i}}{{j}}" [disabled]="isEditable" >
                                    </mat-checkbox > -->
                                  </td>
                                </tr>
                              </table>
                              </td>
                              </tr>
                              <tr>
                                <td colspan="7" class="p-0">
                              <table  *ngIf="isButton" class="table">
                                  <tr *ngIf="accesspermissioninfo[accessObj.key]['Button']">
                                    <td colspan="1" style="width:15%"></td>
                                      <td colspan="1" style="width:15%">
  
                                          <h5>Button</h5>
  
                                      </td>
                                      <td colspan="1" style="width:15%"></td>
                                      <td colspan="1"></td>
                                      <td colspan="1" style="width:15%"></td>
                                      
                                  </tr>
  
                                  <tr *ngFor="let accessObj of accesspermissioninfo[accessObj.key]['Button'];let i=index;">
  
                                    <td colspan="1" style="width:15%"></td>
                                    <td colspan="1" style="width:15%"></td>
                                    <td colspan="1"  style="width:15%;font-size: 14px;font-weight: 500;">{{ accessObj.ElementName }}</td>
                                    <td align="center"  class="text-center">
                                      <!-- <mat-checkbox name="CanListButton{{i}}{{j}}" value="{{accessObj.CanList}}" [(ngModel)]="accessObj.CanList" id="CanListButton{{i}}{{j}}" [disabled]="isEditable" >
                                      </mat-checkbox > -->
                                    </td>
                                    <td align="center"  style="width:15%" class="text-center">
                                      <mat-checkbox name="IsVisibleButton{{i}}{{j}}" value="{{accessObj.IsVisible}}" [(ngModel)]="accessObj.IsVisible" id="IsVisibleButton{{i}}{{j}}" [disabled]="isEditable">
                                      </mat-checkbox >
                                    </td>

                                    <td align="center"  class="text-center">
                                      <!-- <mat-checkbox name="CanCreateButton{{i}}{{j}}" value="{{accessObj.CanCreate}}" [(ngModel)]="accessObj.CanCreate" id="CanCreateButton{{i}}{{j}}" [disabled]="isEditable">
                                      </mat-checkbox > -->
                                    </td>
                                    <td align="center"  style="width:15%" class="text-center">
                                      <!-- <mat-checkbox name="IsEditableButton{{i}}{{j}}" value="{{accessObj.IsEditable}}" [(ngModel)]="accessObj.IsEditable" id="IsEditableButton{{i}}{{j}}" [disabled]="isEditable" >
                                      </mat-checkbox > -->
                                    </td>
                                  </tr>
                              </table>
                              </td>
                              </tr>
                              <tr>
                                <td colspan="7" class="p-0">
                              <table *ngIf="isField"class="table">
                                    <tr *ngIf="accesspermissioninfo[accessObj.key]['Field']">
                                      <td colspan="1" style="width:15%"></td>
                                        <td colspan="1" style="width:15%">
    
                                            <h5>Field</h5>
    
                                        </td>
                                        <td colspan="1" style="width:15%"></td>
                                        <td colspan="1" ></td>
                                        <td colspan="1" style="width:15%"></td>
                                        
                                    </tr>
    
                                    <tr *ngFor="let accessObj of accesspermissioninfo[accessObj.key]['Field'];let i=index;">
    
                                      <td colspan="1" style="width:15%"></td>
                                      <td colspan="1" style="width:15%"></td>
                                      <td colspan="1"  style="width:15% ;font-size: 14px;font-weight: 500;">{{ accessObj.ElementName }}</td>
                                      <td align="center"  class="text-center">
                                        <!-- <mat-checkbox name="CanListField{{i}}{{j}}" value="{{accessObj.CanList}}" [(ngModel)]="accessObj.CanList" id="CanListField{{i}}{{j}}" [disabled]="isEditable" >
                                        </mat-checkbox > -->
                                      </td>
                                      <td align="center"  style="width:15%" class="text-center">
                                        <mat-checkbox name="IsVisibleField{{i}}{{j}}" value="{{accessObj.IsVisible}}" [(ngModel)]="accessObj.IsVisible" id="IsVisibleField{{i}}{{j}}" [disabled]="isEditable">
                                        </mat-checkbox >
                                      </td>

                                      <td align="center"  class="text-center">
                                        <!-- <mat-checkbox name="CanCreateField{{i}}{{j}}" value="{{accessObj.CanCreate}}" [(ngModel)]="accessObj.CanCreate" id="CanCreateField{{i}}{{j}}" [disabled]="isEditable">
                                        </mat-checkbox > -->
                                      </td>
                                      <td align="center"  style="width:15%" class="text-center">
                                        <mat-checkbox name="IsEditableField{{i}}{{j}}" value="{{accessObj.IsEditable}}" [(ngModel)]="accessObj.IsEditable" id="IsEditableField{{i}}{{j}}" [disabled]="isEditable" >
                                        </mat-checkbox >
                                      </td>
                                    </tr>
                              </table>
                              </td>
                              </tr>
                            </ng-container>

                        </tbody>

                    </table>

                    </div>
                </div>
                <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                    <div class="m-form__actions m-form__actions--solid">
                        <div class="row">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-10">
                                <button id="m_login_signin_submit" *ngIf="isEditable"
                                    [hidden]="!WorkFlowScreenAccessEditable"
                                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air"
                                    (click)="EditDetails()">Edit</button>&nbsp;&nbsp;
                                <button id="m_login_signin_submit" *ngIf="!isEditable"
                                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                <a (click)="Cancel()" *ngIf="!isEditable" routerLinkActive="m-menu__item--active"
                                    class="btn btn-metal m-btn m-btn--air m-btn--custom" style="color: #fff;">Cancel</a>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

