export class EmployeeGuestModel {
    Id: number;
    RegisterId: number;
    Employee_Id: number;
    Username: string = "";
    Name: string = '';
    EmpCode: string = '';
    Role: number = null;
    Email: string = '';
    ContactNumber: string = '';
    Region: number[] = [];
    Country: number = null;
    CountryName: string = '';
    RoleType: number;
    Password: string;
    ConfirmPassword: string;
    Status: boolean;
    GuestId: string = '';
    Address: string = '';
    RoleName: string = "";
    RegionName: string = "";
    ReportingManager:string="";
    ReportingManagerEmail="";
    
    ListRegion:string[];
    Fk_User_Id : string = '';
    AlternateEmployee : string = '';
    StartDate : any;
    EndDate : any;
    RegionForGuest : any;
}

export class EmployeeRegion {
    FK_RegionId:number=null;
}