<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">

            <div class="row">
                <div class="col-sm-12 table-responsive">
                    <!-- Product Graph -->
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Product Line Data 
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div>
                                <div class="form-group m-form__group row">
                                    <div [ngClass]="{ 'col-sm-4': reportFilterObj.ReportValueType!='amount',
                                    'col-sm-3': reportFilterObj.ReportValueType=='amount' }">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Year:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" #fiterElement class="form-control "
                                                id="supplychaintype" name="supplychaintype"
                                                [(ngModel)]="reportFilterObj.Year" (change)="onYearChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option *ngFor="let item of YearList" value="{{item}}">
                                                    {{item}}
                                                </option>
                                            </select><br />
                                        </div>
                                    </div>
                                    <div [ngClass]="{ 'col-sm-4': reportFilterObj.ReportValueType!='amount',
                                    'col-sm-3': reportFilterObj.ReportValueType=='amount' }">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Enquiry Order Type:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" class="form-control " id="supplychaintype"
                                                name="supplychaintype" [(ngModel)]="reportFilterObj.EnqType"
                                                (change)="onEnqTypeChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option value="quotation">Quotation</option>
                                                <option value="orderwin">Order Won</option>
                                                <option value="orderloss">Order Lost</option>
                                            </select><br />
                                        </div>
                                    </div>
                                    <div [ngClass]="{ 'col-sm-4': reportFilterObj.ReportValueType!='amount',
                                    'col-sm-3': reportFilterObj.ReportValueType=='amount' }">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Report Type:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" class="form-control " id="supplychaintype"
                                                name="supplychaintype" [(ngModel)]="reportFilterObj.ReportValueType"
                                                (change)="onReportValueTypeChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option value="units">No's</option>
                                                <option value="amount">Amount</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-3" *ngIf="reportFilterObj.ReportValueType=='amount'">
                                        <label style="display: flex;" class="col-sm2">
                                            <h6>Currency:</h6><span style="color:#f4516c;">&nbsp;*</span>
                                        </label>
                                        <div>
                                            <select style="width: 250px;" class="form-control " id="currencytype"
                                                name="currencytype" [(ngModel)]="reportFilterObj.curency"
                                                (change)="onReportValueTypeChange()" required>
                                                <option value=undefined selected disabled>
                                                    Select</option>
                                                <option *ngFor="let item of CurrencyList;let i = index;"
                                                    value="{{item.Id}}">{{item.Name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-3">
                                <mat-checkbox name="dep2" #dep2="ngModel" [(ngModel)]="isAllRegions"
                                    (change)="AllRegionChange($event)">
                                    All Products
                                </mat-checkbox>
                            </div>
                            <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                <b>* Click on slice to see data in details.</b></span>

                            <div id="ProductWisePieChart"
                                style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;"></div>
                            <br />
                            <div id="pllegenddiv"></div>

                        </div>
                    </div>
                    <!-- Product Series Graph -->
                    <div *ngIf="isProductLineSelected" class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Product Series Data ({{selectedProductType}}:
                                        {{selectedProductLine == undefined ? 'All': selectedProductLine.category}})
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="row">
                                <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                    <b>* Click on bar to see data in details.</b></span>
                            </div>
                            <div class="row">
                                <div
                                    [ngClass]="{ 'col-sm-12 table-responsive': !isProductSeriesSelected, 
                                                    'col-sm-6 table-responsive': isProductSeriesSelected && !isProductModelSelected,
                                                    'col-sm-4 table-responsive': isProductSeriesSelected && isProductModelSelected }">
                                    <div id="ProductSeriesBarChart"
                                        style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                </div>
                                <div *ngIf="isProductSeriesSelected" [ngClass]="{ 'col-sm-6 table-responsive': isProductSeriesSelected && !isProductModelSelected,
                                    'col-sm-4 table-responsive': isProductSeriesSelected && isProductModelSelected }">
                                    <div id="ProductModelBarChart"
                                        style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                </div>
                                <div *ngIf="isProductSeriesSelected && isProductModelSelected"
                                    class="col-sm-4 table-responsive">
                                    <div id="ProductStationBarChart"
                                        style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Region Graph -->
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Region Data ({{selectedProductType}}:
                                        {{selectedProductLine == undefined ? 'All': selectedProductLine.category}})
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div id="RegionWisePieChart"
                                style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;"></div>
                            <br />
                            <div id="regionlegenddiv"></div>
                        </div>
                    </div>
                    <!--Customer Class Graph -->
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Customer Classification Data ({{selectedProductType}}:
                                        {{selectedProductLine == undefined ? 'All': selectedProductLine.category}})
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">

                            <div id="CustomerClassWisePieChart"
                                style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;"></div>
                            <br />
                            <div id="cclegenddiv"></div>
                        </div>
                    </div>
                    <!--Customer Type Graph -->
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Customer Type Data ({{selectedProductType}}:
                                        {{selectedProductLine == undefined ? 'All': selectedProductLine.category}})
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div id="CustomerTypeWisePieChart"
                                style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;">
                            </div>
                            <br />
                            <div id="ctlegenddiv"></div>
                        </div>
                    </div>
                    
                    <!-- Segment Graph -->
                    <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Segment ({{selectedProductType}}:
                                        {{selectedProductLine == undefined ? 'All': selectedProductLine.category}})
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            <div class="row">
                                <span style="margin-left: 15px;" class="m-portlet__head-text mandatoryHeader">
                                    <b>* Click on slice to see segment data in details.</b></span>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div id="SegmentWisePieChart"
                                        style="width: 90%; height: 300px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                    <br />
                                    <div id="segmentlegenddiv"></div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                     <!-- SubSegment Graph -->
                     <div class="m-portlet m-portlet--success m-portlet--head-solid-bg" *ngIf="isSegmentSelected">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Sub Segment ({{selectedProductType}}:
                                        {{selectedProductLine == undefined ? 'All': selectedProductLine.category}})
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="m-portlet__body">
                            
                            <div class="row">
                                
                                <div *ngIf="isSegmentSelected" class="col-sm-12 table-responsive">
                                    <div id="SubSegmentWiseBarChart"
                                        style="width: 90%; height: 400px; background-color: #FFFFFF;margin-left: 5%;">
                                    </div>
                                    <br />
                                    <!-- <div id="ssglegenddiv"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>

<!-- <app-charts-modal></app-charts-modal> -->