<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">

        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        {{FormStatus}} Lead
                                    </h3>
                                </div>
                            </div>

                        </div>

                        <form class="m-login__form m-form" name="myform" #myform="ngForm" appFocusInvalidInput
                            (ngSubmit)="myform.form.valid &&onSubmit(myform)" novalidate>
                            <div class="m-portlet__body p-5">
                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">

                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer Name:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="customername"
                                                    id="customername" [(ngModel)]="LeadObj.CustomerName"
                                                    #customername="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customername.invalid || customername?.touched && customername.invalid}"
                                                    placeholder="Enter Customer Name" (keypress)="AvoidSpace($event)"
                                                    maxlength="400" [disabled]="isEditable"
                                                    (change)="LeadObj.CustomerName=LeadObj.CustomerName.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && customername.invalid || customername?.touched && customername.invalid">
                                                    <div *ngIf="myform.hasError('required', 'customername')">
                                                        Customer Name is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label"> Customer Address (Plant):<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="customeraddress"
                                                    id="customeraddress" [(ngModel)]="LeadObj.CustomerAddress"
                                                    #customeraddress="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && customername.invalid || customername?.touched && customername.invalid}"
                                                    placeholder="Enter Customer Address" (keypress)="AvoidSpace($event)"
                                                    maxlength="400" [disabled]="isEditable"
                                                    (change)="LeadObj.CustomerAddress=LeadObj.CustomerAddress.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && customeraddress.invalid || customeraddress?.touched && customeraddress.invalid">
                                                    <div *ngIf="myform.hasError('required', 'customeraddress')">
                                                        Customer Address is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Key Management Person Name::<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <input class="form-control m-input" type="text" name="keypersonname"
                                                    id="keypersonname" [(ngModel)]="LeadObj.KeyPersonName"
                                                    #keypersonname="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && keypersonname.invalid || keypersonname?.touched && keypersonname.invalid}"
                                                    placeholder="Enter KeyPerson Name" (keypress)="AvoidSpace($event)"
                                                    maxlength="400" [disabled]="isEditable"
                                                    (change)="LeadObj.KeyPersonName=LeadObj.KeyPersonName.trim()"
                                                    required>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && keypersonname.invalid || keypersonname?.touched && keypersonname.invalid">
                                                    <div *ngIf="myform.hasError('required', 'keypersonname')">
                                                        KeyPerson Name is required</div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Industry Segment: <span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="industrysegment"
                                                    name="industrysegment" [(ngModel)]="LeadObj.IndustrySegment"
                                                    #industrysegment="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid}"
                                                    [disabled]="isEditable" required>
                                                    <option value="null" disabled>Select Industry Segment</option>
                                                    <option *ngFor="let item of IndustrySegment" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="IndustrySegment.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && industrysegment.invalid || industrysegment?.touched && industrysegment.invalid">
                                                    <div *ngIf="myform.hasError('required', 'industrysegment')">
                                                        Industry segment is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Customer Type:
                                                <span style="color:#f4516c;">&nbsp;*</span>
                                            </label>
                                            <div class="col-lg-3">
                                                <select class="form-control" id="customertype" name="customertype"
                                                    [(ngModel)]="LeadObj.CustomerType" #customertype="ngModel"
                                                    [disabled]="isEditable">
                                                    <option value="null" disabled>Select Type of customer</option>
                                                    <option *ngFor="let item of Typeofcustomer" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="Typeofcustomer.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>
                                            </div>
                                            <div class="offset-1"></div>
                                            <label class="col-lg-2 col-form-label">Region:
                                                <span style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="region" name="region"
                                                    [(ngModel)]="LeadObj.Region" #region="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && region.invalid || region?.touched && region.invalid}"
                                                    [disabled]="isEditable" required>>
                                                    <option value="null" disabled>Select Region</option>
                                                    <option *ngFor="let item of Region" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="Region.length <= 0" value="No Data Found">
                                                        No Data Found</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && region.invalid || region?.touched && region.invalid">
                                                    <div *ngIf="myform.hasError('required', 'region')">
                                                        Region is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Lead Date:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <div class="input-group date">
                                                    <input readonly class="form-control" placeholder="DD-MMM-YYYY"
                                                        id="LeadDate" [minDate]="minDate" [maxDate]="maxDate"
                                                        name="LeadDate" ngbDatepicker #ed="ngbDatepicker"
                                                        [(ngModel)]="LeadObj.LeadDate" #LeadDate="ngModel"
                                                        (click)="ed.toggle()"
                                                        [ngClass]="{ 'is-invalid': (myform.submitted && LeadDate.invalid) || (LeadDate?.touched && LeadDate.invalid) }"
                                                        [disabled]="isEditable" required>
                                                    <div class="input-group-append">
                                                        <span (click)="ed.toggle()" class="input-group-text">
                                                            <i class="la la-calendar-check-o"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="offset-1"></div>

                                            <label class="col-lg-2 col-form-label">Enquiry
                                                Source:<span style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <select class="form-control " id="enquirysource" name="enquirysource"
                                                    [(ngModel)]="LeadObj.FK_EnquirySourceId" #enquirysource="ngModel"
                                                    [disabled]="isEditable">
                                                    <option value="null" disabled>Select Enquiry Source </option>
                                                    <option *ngFor="let item of enquirySource" value="{{item.Id}}">
                                                        {{item.Name}}
                                                    </option>
                                                    <option disabled *ngIf="enquirySource.length <= 0"
                                                        value="No Data Found"> No Data Found</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Description:
                                                <span style="color:#f4516c;">&nbsp;*</span>
                                            </label>
                                            <div class="col-lg-3">
                                                <textarea class="form-control m-input" id="description" maxlength="1500"
                                                    name="description" rows="3" [(ngModel)]="LeadObj.Description"
                                                    (keyup)="limitTextOnKeyUpDown1($event)"
                                                    (keypress)="limitTextOnKeyUpDown1($event)" #description="ngModel"
                                                    [ngClass]="{ 'is-invalid':myform.submitted  && description.invalid || description?.touched && description.invalid}"
                                                    placeholder="Enter description" (keypress)="AvoidSpace($event)"
                                                    [disabled]="isEditable"
                                                    (change)="LeadObj.Description=LeadObj.Description.trim()"
                                                    required></textarea>
                                                <span> Limit {{maxLimit}} characters, {{remLimit}}
                                                    characters remaining.</span>
                                                <div class="invalid-feedback"
                                                    *ngIf="myform.submitted && description.invalid || description?.touched && description.invalid">
                                                    <div *ngIf="myform.hasError('required', 'description')">
                                                        Lead Description is required</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Lead Status:<span
                                                    style="color:#f4516c;">&nbsp;*</span></label>
                                            <div class="col-lg-3">
                                                <label class="col-form-label">{{leadStatus}}

                                                </label>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="m-portlet__body" *ngIf="LeadObj.IsConvertToProspect">
                                        <div class="form-group m-form__group row">

                                            <label class="col-lg-2 col-form-label"> Customer:</label>

                                            <div class="col-lg-3">
                                                <a mat-icon-button color="accent" title="Go to customer"
                                                    [routerLink]="['/EditViewCustomer',LeadObj.FK_Customer_Id]"
                                                    routerLinkActive="active" style="color: blue;"><u>
                                                        {{LeadObj.CustomerCode}} </u></a>
                                            </div>

                                            <label class="col-lg-2 col-form-label"> Enquiry:</label>
                                            <div class="col-lg-3">
                                                <a mat-icon-button color="accent" title="Go to enquiry"
                                                    [routerLink]="['/EditViewEnquiry', LeadObj.FK_Enquiry_Id]"
                                                    routerLinkActive="active" style="color: blue;"><u>
                                                        {{LeadObj.EnquiryNumber}} </u></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                        <div class="m-form__actions m-form__actions--solid">
                                            <div class="row">
                                                <div class="col-lg-2"></div>
                                                <div class="col-lg-10">
                                                    <button id="m_login_signin_submit"
                                                        *ngIf="isEditable&&LeadObj.IsActive && !LeadObj.IsConvertToProspect"
                                                        [hidden]="!isLeadEditable"
                                                        class="btn btn-primary ml-auto js-btn-next btnspc"
                                                        (click)="EditDetails()">Edit</button>&nbsp;
                                                    <button id="m_login_signin_submit"
                                                        *ngIf="!isEditable && !LeadObj.IsConvertToProspect"
                                                        class="btn btn-primary ml-auto js-btn-next btnspc">Save</button>&nbsp;&nbsp;
                                                    <button id="m_login_signin_submit"
                                                        *ngIf="LeadObj.IsActive && !LeadObj.IsConvertToProspect"
                                                        class="btn btn-primary ml-auto js-btn-next btnspc"
                                                        [routerLink]="['/AddCustomer',type,Id]"
                                                        routerLinkActive="active">Convert
                                                        to Prospect </button>&nbsp;&nbsp;
                                                    <button *ngIf="LeadObj.IsActive && !LeadObj.IsConvertToProspect"
                                                        class="btn btn-primary ml-auto js-btn-next btnspc" type="button"
                                                        (click)="openLeadReasonPopup()">Close
                                                        Lead
                                                    </button> &nbsp;&nbsp;
                                                    <a (click)="goBack()"
                                                        style="color: #fff;"
                                                        class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>&nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                <div class="m-portlet m-portlet--success m-portlet--head-solid-bg">
                                    <div class="m-portlet__head">
                                        <div class="m-portlet__head-caption">
                                            <div class="m-portlet__head-title">
                                                <h3 class="m-portlet__head-text">
                                                    Engagement Activities
                                                </h3>
                                            </div>
                                        </div>
                                        <div _ngcontent-yjk-c387="" class="m-portlet__head-tools">
                                            <ul _ngcontent-yjk-c387="" class="m-portlet__nav">
                                                <li _ngcontent-yjk-c387="" class="m-portlet__nav-item"><a
                                                        _ngcontent-yjk-c387=""
                                                        class="btn btn-primary m-btn m-btn--air m-btn--custom"
                                                        [routerLink]="['/Add-Activity']"
                                                        [queryParams]="{EntityType: 'Lead', LeadId: Id}">
                                                        Add Activity </a>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="m-portlet__body">
                                        <div class="table-responsive">
                                            <app-activity-list-by-entity
                                                [entityTypeId]="2"></app-activity-list-by-entity>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>