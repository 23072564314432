import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { AppGlobals } from 'src/app/app.global';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SingleFieldComponent, SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { CommonService } from 'src/app/Service/common.service';
import { Location } from '@angular/common';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';

@Component({
  selector: 'app-add-contract-frame-agreement',
  templateUrl: './add-contract-frame-agreement.component.html',
  styleUrls: ['./add-contract-frame-agreement.component.scss']
})
export class AddContractFrameAgreementComponent implements OnInit {

  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  isdropdowninactive: boolean = false;
  Customer: any[] = [];
  tempProductModel: any[] = [];
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];

  @Input()
  showLoader: boolean = false;
  Roles: any[] = [];
 
  ProductModel: any[] = [];
  
  NewArr: any[] = [];
  temp_ProductModel: any[] = [];
  ProductModelDiscountArr: SingleFieldModel[] = [];
  productModelrequired: boolean=false;
  productDiscountrequired: boolean;
  ProductModelDiscount:any[]=[];
  workflowActionList:any[] = [];
  WorkflowStatus:number;
  UserRole: any;
  isConfigureEditable: boolean = false;
  product:any[]=[];
  charCode: any;
  constructor(private router: Router, private toastr: ToastrService, private CommonService: CommonService,
    public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title ,private location: Location,private accessservice: AccessPermissionService) { }

  ngOnInit(): void {
    this.UserRole = localStorage.getItem("UserRole");
    //this.isConfigureEditable = AppGlobals.ConfigureMastesrsEditableAccessArray.filter(x => x.role == this.UserRole)[0]?.isEditable;
    this.AddNewRow(0);
    this.getAccess();
    this.getAllTransitionrules();
    this.getAllCustomer("Customer", "GetAll");
    this.getAllProductModel("ProductModel", "GetAllModel");
    this.singleFieldObj.Fk_Customer = this.route.snapshot.params["id"];
    this.getDetailsById(this.singleFieldObj.Fk_Customer);
   // console.log(this.singleFieldObj.Fk_Customer);
    if (this.singleFieldObj.Fk_Customer != undefined && this.singleFieldObj.Fk_Customer!=0) {
      this.isdropdowninactive = true;
    }
    else this.isdropdowninactive = false
  
  }

  testing:any[]=[];
  showItems: Array<{ id: number, value: any[] }>;
  OnSelectProductModel(selectedValue, index) {
    var selectedItem = this.tempProductModel.filter(x => x.Id != selectedValue);
    this.testing.push(selectedItem);
    this.showItems[index].value = this.tempProductModel.filter(x => x.Id == selectedValue);

  }
  onCompanyselection(data:any)
  {
    var Id=this.singleFieldObj.Fk_Customer;
    this.getDetailsById(Id);
  }

  getDetailsById(Id: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "id=" + Id;

    this.CommonService.GetData('CustomerSpecialDiscount', 'GetById', queryString).subscribe((data: any) => {
      this.singleFieldObj.customerSpecialDiscountInfo= data;
      if(this.singleFieldObj.customerSpecialDiscountInfo.length==0)
      {
        this.AddNewRow(0);
      }
     
    }, error => {
      this.toastr.error("Failed to load data!");

    });
    this.showLoader = false;
  }

  onSubmit(myform: any) {

    this.productModelrequired=false;
    if (this.singleFieldComponent.ismultiplerecords) {
      if (this.singleFieldObj.customerSpecialDiscountInfo.length == 0) {
        this.singleFieldObj.customerSpecialDiscountInfo[0].ProductModelRequired = true;
        this.showLoader = false;
        return;
      }
      else {
        this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
          if (element.Fk_ProductModel == null || element.Fk_ProductModel == undefined || element.Fk_ProductModel == 0 ) {
            element.ProductModelRequired = true;
          }
          else {
            element.ProductModelRequired = false;
          }          
        });
      }
    }
    this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
      if (element.ProductModelRequired) {
        this.productModelrequired=true;
        this.showLoader = false;
        return;
      }
    });
    if (this.productModelrequired == false) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "You want to add this this Customer Frame Agreement ?"
        }
      });
      if (dialogRef) {
      dialogRef.afterClosed().subscribe(dialogResult => {

        if (dialogResult) {
          this.showLoader = true;
          let queryString = "";
          this.singleFieldObj.customerSpecialDiscountInfo.forEach(element=>
            {
              element.Fk_Customer=this.singleFieldObj.Fk_Customer
            });    
            queryString = "id=" +this.WorkflowStatus;
            this.CommonService.PostData('CustomerSpecialDiscount', 'Add', this.singleFieldObj.customerSpecialDiscountInfo, queryString,null).subscribe((data: any) => {
              let result = data;

              this.showLoader = false;
              if (result.ResponseCode == 1) {
                this.toastr.success('Customer Frame Agreement added successfully!');
                this.router.navigate(['/CustomerFrameAgreementDetails']);
              }
              else if (data.ResponseCode == 0) {
                // myform.controls['fieldtitle'].setErrors({ AlreadyPresent: true });
                this.toastr.warning( 'Customer Frame Agreement already exists!');
                this.showLoader = false;
              }
              else {
                this.toastr.error('Failed to add Customer Frame Agreement!');
                this.router.navigate(['/CustomerFrameAgreementDetails']);
              }
            }, error => {
              this.toastr.error('Failed to add Customer Frame Agreement!');
              this.showLoader = false;
              this.router.navigate(['/CustomerFrameAgreementDetails']);
            })
          
        }
      });
    }
  }
  }

  getAllCustomer(ControllerName: string, ActionName: string) {
    this.showLoader = true;
    this.CommonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      this.Customer = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  getAllProductModel(ControllerName: string, ActionName: string) {
    this.showLoader = true;
    this.CommonService.GetData(ControllerName, ActionName, null).subscribe((data: any) => {
      this.ProductModel = data;
      this.tempProductModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  OnDiscountChange(index) {

    if(this.singleFieldObj.customerSpecialDiscountInfo[index].UnapprovedProductDiscount==undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel==null)
    {
      this.singleFieldObj.customerSpecialDiscountInfo[index].UnapprovedProductDiscount=0;
    }

    if (this.singleFieldObj.customerSpecialDiscountInfo[index].UnapprovedProductDiscount < 0) {
      this.toastr.info(' Discount cannot be negative.');
      this.singleFieldObj.customerSpecialDiscountInfo[index].UnapprovedProductDiscount = 0;
      return;
    }
    else if (this.singleFieldObj.customerSpecialDiscountInfo[index].UnapprovedProductDiscount > 100) {
      this.toastr.info('Discount cannot be greater than 100.');
      this.singleFieldObj.customerSpecialDiscountInfo[index].UnapprovedProductDiscount = 0;
      return;
    }
  }

  OnProductModelChange(index)
  {
      
    if(this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=0 || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=null)
    {
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=false;
    }
    else{
      this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=true;
    }

  }
  
 
  AddNewRow(index) {
    if (this.singleFieldObj.Fk_ProductModel != undefined
      && this.singleFieldObj.UnapprovedProductDiscount != undefined) {

      this.singleFieldObj.IsRowDeleted = true;
      var obj = new SingleFieldModel();
      obj.Fk_ProductModel = this.singleFieldObj.Fk_ProductModel;
      obj.UnapprovedProductDiscount = this.singleFieldObj.UnapprovedProductDiscount

      this.singleFieldObj.UnapprovedProductDiscount = 0;
      this.singleFieldObj.Fk_ProductModel = 0;
      this.singleFieldObj.customerSpecialDiscountInfo.push(obj);
    }
    else{
      if(this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=0 || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=undefined || this.singleFieldObj.customerSpecialDiscountInfo[index].Fk_ProductModel!=null)
      {
        this.singleFieldObj.customerSpecialDiscountInfo[index].ProductModelRequired=false;
      }
      
    }
  }

  deleterow(index: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to delete this row?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (index != null) {
          this.singleFieldObj.customerSpecialDiscountInfo.splice(index, 1);
        } else {
          this.singleFieldObj.customerSpecialDiscountInfo.pop();
        }
      }
    });
  }

  getAllTransitionrules()
  {
    this.showLoader = true;
    this.CommonService.GetData('WorkFlowTransitionRule', 'GetAll', null).subscribe((data: any) => {
      this.singleFieldObj.tranisitionrulesobj = data;
      this.singleFieldObj.tranisitionrulesobj.forEach(element=>
      {
        if(element.condition_code=='DiscountSaveAsDraft'&&element.action_by_role_id==this.UserRole)
        {
          this.workflowActionList.push(element);
          var rule= this.workflowActionList.filter(x=>x.action_id==element.action_id);
          console.log(rule);
        }

      });

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  OnWorkflowAction(myform:NgForm,nextstateid:number){  
    
    var rule= this.workflowActionList.filter(x=>x.next_state_id==nextstateid);
    this.WorkflowStatus=rule[0].next_state_id;
    this.singleFieldObj.customerSpecialDiscountInfo.forEach(element => {
      element.action_id=rule[0].action_id;
      
    });

    // this.EnquiryObj.EnquiryDetails.FK_transitionrule_id=rule[0].transition_rule_id;
    // this.SetStatus(rule[0].next_state_id,myform2);  
     // this.onSubmit(myform);
  } 
  
  /* getWorkflowActions()
    {    
      let queryString = "";
       this.showLoader = true;
      this.CommonService.GetData("WorkFlowTransitionRule","GetActionsforFrameAgreement",queryString).subscribe((data: any) => {
        this.workflowActionList=data;
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
      });
    }
 */
  goBack() {
      this.location.back();
    }

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isConfigureEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.UserRole && x.ElementKey=='IsCustomerFrameAgreement')[0]?.IsEditable;
      console.log("IsConfigure-",this.isConfigureEditable);
  
    });
 
  }
     
  isintNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if
      (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;

    }
    else {
      return true;
    }
  }
  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }
}
