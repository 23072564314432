import { Component, OnInit, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductLine } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-product-line',
  templateUrl: './add-product-line.component.html',
  styleUrls: ['./add-product-line.component.scss']
})
export class AddProductLineComponent implements OnInit {
  ProductLineobj: ProductLine = new ProductLine();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  constructor(private router: Router, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
  }


  /*******************************************************************************
   * Form validation method
   * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this product line?"
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;
        this.CommonService.PostData('ProductLine', 'Add', this.ProductLineobj, null).subscribe((data: any) => {
          let result = data;
          if (result.ResponseCode == 1) {
            this.toastr.success('Product line added successfully!');
            this.router.navigate(['/ProductLine']);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('Product line already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add product line!');
            this.router.navigate(['/ProductLine']);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add product line!');
          this.showLoader = false;
          this.router.navigate(['/ProductLine']);
        });
      }
    });

  }

}
