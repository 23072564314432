<mat-card fxLayoutGap>

    <p mat-dialog-title style="text-align: center;">{{data.title}}</p>

    <p display-2>{{data.message}}</p>


    <div mat-dialog-actions align="center">
        <button (click)="onConfirm()" class="btn btn-primary m-btn m-btn--air m-btn--custom">Yes</button>&nbsp;&nbsp;
        <button (click)="onDismiss()" class="btn btn-metal m-btn m-btn--air m-btn--custom">No</button>
    </div>
</mat-card>