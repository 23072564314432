import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Service/common.service';
import { SlowBuffer } from 'buffer';
import { ReportFilterViewModel } from 'src/app/Model/ReportFilterModel';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppGlobals } from 'src/app/app.global';

@Component({
  selector: 'app-segmentwise-report',
  templateUrl: './segmentwise-report.component.html',
  styleUrls: ['./segmentwise-report.component.scss']
})
export class SegmentwiseReportComponent implements OnInit {
  @Input()
  showLoader: boolean = false;
  regionList: any[] = [];
  SelectedRegion: any = undefined;

  reportFilterObj: ReportFilterViewModel = new ReportFilterViewModel();

  isProductLineSelected: boolean = false;
  isProductSeriesSelected: boolean = false;
  isProductModelSelected: boolean = false;
  chartData: { category: string; value: number; }[];
  selectedProductLine: any;
  selectedProductSeries: any;
  productlineData: any;
  regionData: any;
  customerclassdata: any;
  customertypedata: any;
  segmentData: any;
  YearList: any[]=[];
  currYear: number = new Date().getFullYear();
  productseriesData: { category: string; value: number; }[];
  producModeleData: { category: string; value: number; }[];
  selectedSegment: any;
  isSegmentSelected: boolean = false;
  isAllSegments: boolean = true;
  CurrencyList: any;
  CurrencyLabel: string = "";
  VariantLabel: string = "";
  @ViewChild('fiterElement') fiterElement: ElementRef;


  constructor(private toastr: ToastrService, private commonService: CommonService
    , private titleService: Title, private route: ActivatedRoute) { }
  ngOnInit() {
    this.commonService.RefreshHeaderFun();
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.showLoader = true;
    this.reportFilterObj = new ReportFilterViewModel();
    this.reportFilterObj.EnqType = "quotation";
    this.reportFilterObj.ReportValueType = "amount";
    this.reportFilterObj.Segment = "0";
    this.reportFilterObj.reporttype = "Segment";
    this.reportFilterObj.Year = new Date().getFullYear();
   // this.YearList = AppGlobals.YearsConstant;
   let k = 1;
   for (let i = 2018; i <= this.currYear; i++) {
       let batch =i;
       this.YearList.push(batch);
       k++;
   }
    this.getCurrencyList();
  }
  onYearChange() {
    this.SegmentWisePieChart();
  }
  // getYearList() {
  //   this.commonService.GetData("Dashboard", "GetYearList", null).subscribe((data: any) => {
  //     this.YearList = data;
  //     var check = this.YearList.filter(x=>x==new Date().getFullYear())[0];
  //     if (check.length==0) this.YearList.push(new Date().getFullYear());
  //   },
  //     error => {
  //       this.toastr.error("Failed to load year list!");
  //       this.showLoader = false;
  //     });
  // }
  getCurrencyList() {
    this.commonService.GetData("Currency", "GetAll", null).subscribe((data: any) => {
      this.CurrencyList = data;
      this.reportFilterObj.curency = this.CurrencyList.filter(x => x.Name == 'INR (₹)')[0]?.Id;
      if (this.reportFilterObj.ReportValueType == "amount") {
        if (this.reportFilterObj.curency == 1) this.CurrencyLabel = "MUSD";
        if (this.reportFilterObj.curency == 2) this.CurrencyLabel = "MEURO";
        if (this.reportFilterObj.curency == 3) this.CurrencyLabel = "MINR";
      }
      else {
        this.CurrencyLabel = "No's";
      }
      this.SegmentWisePieChart();
      setTimeout(() => {
        this.fiterElement.nativeElement.focus();
      }, 0);
    },
      error => {
        this.toastr.error("Failed to load currency list!");
        this.showLoader = false;
      });
  }
  SegmentWisePieChart() {
    const that = this;
    this.showLoader = true;
    this.isSegmentSelected = false;
    this.isProductLineSelected = false;
    this.isProductSeriesSelected = false;
    let queryString = "";
    queryString += "EnqType=" + this.reportFilterObj.EnqType;
    queryString += "&ReportValueType=" + this.reportFilterObj.ReportValueType;
    queryString += "&Year=" + this.reportFilterObj.Year;
    queryString += "&Region=" + this.reportFilterObj.Region;
    queryString += "&ProductLine=" + this.reportFilterObj.ProductLine;
    queryString += "&ProductSeries=" + this.reportFilterObj.ProductSeries;
    queryString += "&Productmodal=" + this.reportFilterObj.Productmodal;
    queryString += "&CustomerClass=" + this.reportFilterObj.CustomerClass;
    queryString += "&CustomerType=" + this.reportFilterObj.CustomerType;
    queryString += "&Segment=" + this.reportFilterObj.Segment;
    queryString += "&reporttype=" + this.reportFilterObj.reporttype;
    queryString += "&currency=" + this.reportFilterObj.curency;
    this.commonService.GetData("Report", "GetRegionReport", queryString).subscribe((data: any) => {
      let chart = am4core.create("SegmentWisePieChart", am4charts.PieChart);
      // chart.exporting.menu = new am4core.ExportMenu();
      chart.legend = new am4charts.Legend();
      chart.legend.labels.template.text = "{category}: {value.value}";
      chart.hiddenState.properties.opacity = 0;

      setTimeout(() => {
        this.ProductWisePieChart();
        this.CustomerClassWisePieChart();
        this.CustomerTypeWisePieChart();
        this.RegionWisePieChart();
        this.showLoader = false;
      }, 3000);

      this.regionData = data.Region;
      this.productlineData = data.ProductLine;
      this.customerclassdata = data.CustomerClass;
      this.customertypedata = data.CustomerType;
      this.segmentData = data.Segment;
      chart.data = this.segmentData;
      // //*************************************************************************** */

      chart.events.on("beforedatavalidated", function (ev) {
        // check if there's data
        var IsDataEmpty = false;
        var emptyUnits;
        if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
        if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
        if (emptyUnits.length == chart.data.length) {
          chart.hidden = true;
          IsDataEmpty = true;
        }
        var indicator;
        if (IsDataEmpty) {
          that.showIndicator(chart, indicator);
        }
        else if (indicator) {
          that.hideIndicator(indicator);
        }
        else {
          chart.exporting.menu = new am4core.ExportMenu();
          let options = chart.exporting.getFormatOptions("pdf");
          options.addURL = false;
          chart.exporting.setFormatOptions("pdf", options);
          chart.exporting.filePrefix = "SegmentReport";
          /**************Custom Export**************** */
          chart.exporting.menu.items = [
            {
              "label": "...",
              "menu": [
                {
                  "label": "Image",
                  "menu": [
                    { "type": "png", "label": "PNG" },
                    { "type": "jpg", "label": "JPG" },
                    // { "type": "svg", "label": "SVG" },
                    { "type": "pdf", "label": "PDF" }
                  ]
                }, {
                  "label": "Data",
                  "menu": [
                    { "type": "xlsx", "label": "XLSX" },
                  ]
                }, {
                  "label": "Print", "type": "print"
                }
              ]
            }
          ];
          chart.exporting.extraSprites.push({
            "sprite": legendContainer,
            "position": "bottom",
            "marginTop": 30
          });
          if (that.reportFilterObj.ReportValueType == "amount") {
            let title = chart.titles.create();
            title.text = "[bold]All values are in " + that.CurrencyLabel;
            title.fill = am4core.color("red");
            title.fontSize = '14px';
            title.disabled = false;
            chart.exporting.dataFields = {
              "category": "Segment",
              "amountvalue": "Amount(" + that.CurrencyLabel + ")",
            }
          }
          else {
            chart.exporting.dataFields = {
              "category": "Segment",
              "unitvalue": that.CurrencyLabel,
            }
          }

          /**************End Custom Export**************** */

        }
      });
      //  //*************************************************************************** */
      chart.legend = new am4charts.Legend();
      chart.legend.itemContainers.template.tooltipText = "{category}";
      let legendContainer = am4core.create("legenddiv", am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      chart.legend.parent = legendContainer;
      chart.events.on("datavalidated", resizeLegend);
      chart.events.on("maxsizechanged", resizeLegend);

      chart.legend.events.on("datavalidated", resizeLegend);
      chart.legend.events.on("maxsizechanged", resizeLegend);
      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
      function resizeLegend(ev) {
        document.getElementById("legenddiv").style.height = chart.legend.contentHeight + "px";
      }
      var series = chart.series.push(new am4charts.PieSeries());

      series.dataFields.category = "category";
      if (this.reportFilterObj.ReportValueType == "units") {
        series.dataFields.value = "unitvalue";
      } else {
        series.dataFields.value = "amountvalue";
      }

      chart.legend.labels.template.text = "[normal]{category}: [bold]{value.value}";
      // chart.legend.scrollable = true;
      chart.legend.valueLabels.template.disabled = true;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 20;
      markerTemplate.height = 20;
      chart.legend.valueLabels.template.align = "left"
      chart.legend.valueLabels.template.textAlign = "start"
      chart.legend.labels.template.maxWidth = 150;
      chart.legend.labels.template.wrap = true;
      chart.legend.labels.template.truncate = false;
      series.labels.template.text = "{category}: {value.value}";
      series.slices.template.tooltipText = "{category}: {value.value}";
      chart.legend.valueLabels.template.text = "{value.value}";
      series.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      //<TO HIDE THE LABELS WHICH HAS VALUE 0
      series.ticks.template.length = 2;
      series.ticks.template.events.on("ready", hideSmall);
      series.ticks.template.events.on("visibilitychanged", hideSmall);
      series.labels.template.events.on("ready", hideSmall);
      series.labels.template.events.on("visibilitychanged", hideSmall);

      ///////////////////////////////////////////////////////////////
      /**************For mobile UI************* */
      let screenWidth = window.innerWidth;
      if (screenWidth < 750) {
        series.ticks.template.disabled = true;
        series.alignLabels = false;
        series.labels.template.text = "[bold]{category}: {value.value}";
        series.labels.template.radius = am4core.percent(-80);
        series.labels.template.fill = am4core.color("black");
        series.labels.template.relativeRotation = 90;
        series.labels.template.disabled = true;
        series.slices.template.tooltipText = "{category}: {value.value}";
      }
      series.labels.template.maxWidth = 130;
      series.labels.template.wrap = true;
      ////////////////////////////////////////////////////////

      function hideSmall(ev) {
        if (ev.target.dataItem && (ev.target.dataItem.values.value.percent == 0)) {
          ev.target.hide();
        }
        else {
          ev.target.show();
        }
      }
      //>
      series.slices.template.events.on("hit", function (ev) {
        that.onSegmentSliceClick(ev.target.dataItem.dataContext);
        series.slices.each(function (item) {
          if (item.isActive && item != ev.target) {
            item.isActive = false;
          }
        })
      });
    }, error => {
      this.showLoader = false;
    });
  }
  onSegmentSliceClick(sliceObj) {
    const that = this;
    this.showLoader = true;
    this.reportFilterObj.Segment = sliceObj.Id;
    this.selectedSegment = sliceObj;
    this.isSegmentSelected = true;
    this.isAllSegments = false;
    this.isProductSeriesSelected = false;
    this.SubSegmentWisePieChart();
    this.callSegmentChart();
  }
  callSegmentChart() {
    this.showLoader = true;
    let queryString = "";
    queryString += "EnqType=" + this.reportFilterObj.EnqType;
    queryString += "&ReportValueType=" + this.reportFilterObj.ReportValueType;
    queryString += "&Year=" + this.reportFilterObj.Year;
    queryString += "&Region=" + this.reportFilterObj.Region;
    queryString += "&ProductLine=" + this.reportFilterObj.ProductLine;
    queryString += "&ProductSeries=" + this.reportFilterObj.ProductSeries;
    queryString += "&Productmodal=" + this.reportFilterObj.Productmodal;
    queryString += "&CustomerClass=" + this.reportFilterObj.CustomerClass;
    queryString += "&CustomerType=" + this.reportFilterObj.CustomerType;
    queryString += "&Segment=" + this.reportFilterObj.Segment;
    queryString += "&reporttype=" + this.reportFilterObj.reporttype;
    queryString += "&currency=" + this.reportFilterObj.curency;
    this.commonService.GetData("Report", "GetRegionReport", queryString).subscribe((data: any) => {
      this.regionData = data.Region;
      this.productlineData = data.ProductLine;
      this.customerclassdata = data.CustomerClass;
      this.customertypedata = data.CustomerType;
      this.segmentData = data.Segment;
      setTimeout(() => {
        this.ProductWisePieChart();
        this.CustomerClassWisePieChart();
        this.CustomerTypeWisePieChart();
        this.RegionWisePieChart();
        this.showLoader = false;
      }, 3000);

    }, error => {
      this.showLoader = false;
    });

  }
  SubSegmentWisePieChart() {
    this.showLoader = true;
    let queryString = "";
    queryString += "EnqType=" + this.reportFilterObj.EnqType;
    queryString += "&ReportValueType=" + this.reportFilterObj.ReportValueType;
    queryString += "&Year=" + this.reportFilterObj.Year;
    queryString += "&Region=" + this.reportFilterObj.Region;
    queryString += "&ProductLine=" + this.reportFilterObj.ProductLine;
    queryString += "&ProductSeries=" + this.reportFilterObj.ProductSeries;
    queryString += "&Productmodal=" + this.reportFilterObj.Productmodal;
    queryString += "&CustomerClass=" + this.reportFilterObj.CustomerClass;
    queryString += "&CustomerType=" + this.reportFilterObj.CustomerType;
    queryString += "&Segment=" + this.reportFilterObj.Segment;
    queryString += "&reporttype=" + this.reportFilterObj.reporttype;
    queryString += "&currency=" + this.reportFilterObj.curency;
    this.commonService.GetData("Report", "GetRegionSubSegmentReport", queryString).subscribe((data: any) => {
      let chart = am4core.create("SubSegmentWiseBarChart", am4charts.XYChart);
      // chart.exporting.menu = new am4core.ExportMenu();

      let title = chart.titles.create();
      title.text = this.selectedSegment.category;
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;


      chart.data = data.SubSegment;
      // //*************************************************************************** */
      const that = this;
      chart.events.on("beforedatavalidated", function (ev) {
        // check if there's data
        var IsDataEmpty = false;
        var emptyUnits;
        if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
        if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
        if (emptyUnits.length == chart.data.length) {
          chart.hidden = true;
          IsDataEmpty = true;
        }
        var indicator;
        if (IsDataEmpty) {
          that.showIndicator(chart, indicator);
        }
        else if (indicator) {
          that.hideIndicator(indicator);
        }
        else {
          chart.scrollbarX = new am4core.Scrollbar();
          chart.scrollbarY = new am4core.Scrollbar();
         // chart.cursor = new am4charts.XYCursor();
          chart.scrollbarX.exportable = false;
          chart.scrollbarY.exportable = false;
          chart.exporting.menu = new am4core.ExportMenu();
          let options = chart.exporting.getFormatOptions("pdf");
          options.addURL = false;
          chart.exporting.setFormatOptions("pdf", options);
          chart.exporting.filePrefix = "SubSegmentReport";
          /**************Custom Export**************** */
          chart.exporting.menu.items = [
            {
              "label": "...",
              "menu": [
                {
                  "label": "Image",
                  "menu": [
                    { "type": "png", "label": "PNG" },
                    { "type": "jpg", "label": "JPG" },
                    // { "type": "svg", "label": "SVG" },
                    { "type": "pdf", "label": "PDF" }
                  ]
                }, {
                  "label": "Data",
                  "menu": [
                    { "type": "xlsx", "label": "XLSX" },
                  ]
                }, {
                  "label": "Print", "type": "print"
                }
              ]
            }
          ];
          if (that.reportFilterObj.ReportValueType == "amount") {
            let title = chart.titles.create();
            title.text = "[bold]All values are in " + that.CurrencyLabel;
            title.fill = am4core.color("red");
            title.fontSize = '14px';
            title.disabled = false;
            chart.exporting.dataFields = {
              "category": "SubSegment",
              "amountvalue": "Amount(" + that.CurrencyLabel + ")",
            }
          }
          else {
            chart.exporting.dataFields = {
              "category": "SubSegment",
              "unitvalue": that.CurrencyLabel,
            }
          }

          /**************End Custom Export**************** */
        }
      });
      //  //*************************************************************************** */
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      //categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.labels.template.maxWidth = 100;
      categoryAxis.renderer.labels.template.wrap = true;
     // categoryAxis.renderer.labels.template.truncate = true;
      categoryAxis.renderer.labels.template.tooltipText = '{category}';
       /**************Scrolling Start End Point**************** */
       categoryAxis.start=0;
       categoryAxis.end=0.5;
      /**************End Scrolling Start End Point**************** */
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.extraMax = 0.5;
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.000" ? "0" : text;
        return val;
      });
      if (this.reportFilterObj.ReportValueType == "units") {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        if (this.reportFilterObj.curency == 1) valueAxis.title.text = "MUSD";
        if (this.reportFilterObj.curency == 2) valueAxis.title.text = "MEURO";
        if (this.reportFilterObj.curency == 3) valueAxis.title.text = "MINR";
       // chart.numberFormatter.numberFormat = "0.000";
      }

      // Create series
      var columnSeries = chart.series.push(new am4charts.ColumnSeries());
      if (this.reportFilterObj.ReportValueType == "units") {
        columnSeries.dataFields.valueY = "unitvalue";
      } else {
        columnSeries.dataFields.valueY = "amountvalue";
      }
      columnSeries.dataFields.categoryX = "category";
      columnSeries.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      colLabel.label.text = "{valueY}";
      //colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;

      columnSeries.columns.template.interactionsEnabled = true;

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });

  }
  CustomerClassWisePieChart() {
    let chart = am4core.create("CustomerClassWisePieChart", am4charts.PieChart);
    let title = chart.titles.create();
    title.text = "Customer Classification";
    title.fontSize = 15;
    title.fontWeight = "bold";
    title.marginBottom = 30;
    // chart.exporting.menu = new am4core.ExportMenu();
    chart.legend = new am4charts.Legend();
    chart.hiddenState.properties.opacity = 0;
    chart.data = this.customerclassdata;
    // //*************************************************************************** */
    const that = this;
    chart.events.on("beforedatavalidated", function (ev) {
      // check if there's data
      var IsDataEmpty = false;
      var emptyUnits;
      if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
      if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
      if (emptyUnits.length == chart.data.length) {
        chart.hidden = true;
        IsDataEmpty = true;
      }
      var indicator;
      if (IsDataEmpty) {
        that.showIndicator(chart, indicator);
      }
      else if (indicator) {
        that.hideIndicator(indicator);
      }
      else {
        chart.exporting.menu = new am4core.ExportMenu();
        let options = chart.exporting.getFormatOptions("pdf");
        options.addURL = false;
        chart.exporting.setFormatOptions("pdf", options);
        chart.exporting.filePrefix = "CustomerClassificationReport";
        /**************Custom Export**************** */
        chart.exporting.menu.items = [
          {
            "label": "...",
            "menu": [
              {
                "label": "Image",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "jpg", "label": "JPG" },
                  // { "type": "svg", "label": "SVG" },
                  { "type": "pdf", "label": "PDF" }
                ]
              }, {
                "label": "Data",
                "menu": [
                  { "type": "xlsx", "label": "XLSX" },
                ]
              }, {
                "label": "Print", "type": "print"
              }
            ]
          }
        ];
        chart.exporting.extraSprites.push({
          "sprite": legendContainer,
          "position": "bottom",
          "marginTop": 30
        });
        if (that.reportFilterObj.ReportValueType == "amount") {
          let title = chart.titles.create();
          title.text = "[bold]All values are in " + that.CurrencyLabel;
          title.fill = am4core.color("red");
          title.fontSize = '14px';
          title.disabled = false;
          chart.exporting.dataFields = {
            "category": "CustomerClassification",
            "amountvalue": "Amount(" + that.CurrencyLabel + ")",
          }
        }
        else {
          chart.exporting.dataFields = {
            "category": "CustomerClassification",
            "unitvalue": that.CurrencyLabel,
          }
        }

        /**************End Custom Export**************** */

      }
    });
    //  //*************************************************************************** */
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.tooltipText = "{category}";
    let legendContainer = am4core.create("cclegenddiv", am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart.legend.parent = legendContainer;
    chart.events.on("datavalidated", resizeLegend);
    chart.events.on("maxsizechanged", resizeLegend);

    chart.legend.events.on("datavalidated", resizeLegend);
    chart.legend.events.on("maxsizechanged", resizeLegend);
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
    function resizeLegend(ev) {
      document.getElementById("cclegenddiv").style.height = chart.legend.contentHeight + "px";
    }
    chart.legend.itemContainers.template.tooltipText = "{category}";
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.category = "category";
    if (this.reportFilterObj.ReportValueType == "units") {
      series.dataFields.value = "unitvalue";
    } else {
      series.dataFields.value = "amountvalue";
    }
    chart.legend.labels.template.text = "[normal]{category}: [bold]{value.value}";
    // chart.legend.scrollable = true;
    chart.legend.valueLabels.template.disabled = true;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 20;
    chart.legend.valueLabels.template.align = "left"
    chart.legend.valueLabels.template.textAlign = "start"
    chart.legend.labels.template.maxWidth = 150;
    chart.legend.labels.template.wrap = true;
    chart.legend.labels.template.truncate = false;
    series.labels.template.text = "{category}: {value.value}";
    series.slices.template.tooltipText = "{category}: {value.value}";
    chart.legend.valueLabels.template.text = "{value.value}";
    series.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    //<TO HIDE THE LABELS WHICH HAS VALUE 0
    series.ticks.template.length = 2;
    series.ticks.template.events.on("ready", hideSmall);
    series.ticks.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.events.on("ready", hideSmall);
    series.labels.template.events.on("visibilitychanged", hideSmall);

    ///////////////////////////////////////////////////////////////
    /**************For mobile UI************* */
    let screenWidth = window.innerWidth;
    if (screenWidth < 750) {
      series.ticks.template.disabled = true;
      series.alignLabels = false;
      series.labels.template.text = "[bold]{category}: {value.value}";
      series.labels.template.radius = am4core.percent(-80);
      series.labels.template.fill = am4core.color("black");
      series.labels.template.relativeRotation = 90;
      series.labels.template.disabled = true;
      series.slices.template.tooltipText = "{category}: {value.value}";
    }
    series.labels.template.maxWidth = 250;
    series.labels.template.wrap = true;
    ////////////////////////////////////////////////////////

    function hideSmall(ev) {
      if (ev.target.dataItem && (ev.target.dataItem.values.value.percent == 0)) {
        ev.target.hide();
      }
      else {
        ev.target.show();
      }
    }
    //>
  }
  CustomerTypeWisePieChart() {
    let chart = am4core.create("CustomerTypeWisePieChart", am4charts.PieChart);
    let title = chart.titles.create();
    title.text = "Customer Type";
    title.fontSize = 15;
    title.fontWeight = "bold";
    title.marginBottom = 30;
    // chart.exporting.menu = new am4core.ExportMenu();
    chart.legend = new am4charts.Legend();
    chart.hiddenState.properties.opacity = 0;
    chart.data = this.customertypedata;
    // //*************************************************************************** */
    const that = this;
    chart.events.on("beforedatavalidated", function (ev) {
      // check if there's data
      var IsDataEmpty = false;
      var emptyUnits;
      if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
      if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
      if (emptyUnits.length == chart.data.length) {
        chart.hidden = true;
        IsDataEmpty = true;
      }
      var indicator;
      if (IsDataEmpty) {
        that.showIndicator(chart, indicator);
      }
      else if (indicator) {
        that.hideIndicator(indicator);
      }
      else {
        chart.exporting.menu = new am4core.ExportMenu();
        let options = chart.exporting.getFormatOptions("pdf");
        options.addURL = false;
        chart.exporting.setFormatOptions("pdf", options);
        chart.exporting.filePrefix = "CustomerTypeReport";
        /**************Custom Export**************** */
        chart.exporting.menu.items = [
          {
            "label": "...",
            "menu": [
              {
                "label": "Image",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "jpg", "label": "JPG" },
                  // { "type": "svg", "label": "SVG" },
                  { "type": "pdf", "label": "PDF" }
                ]
              }, {
                "label": "Data",
                "menu": [
                  { "type": "xlsx", "label": "XLSX" },
                ]
              }, {
                "label": "Print", "type": "print"
              }
            ]
          }
        ];
        chart.exporting.extraSprites.push({
          "sprite": legendContainer,
          "position": "bottom",
          "marginTop": 30
        });
        if (that.reportFilterObj.ReportValueType == "amount") {
          let title = chart.titles.create();
          title.text = "[bold]All values are in " + that.CurrencyLabel;
          title.fill = am4core.color("red");
          title.fontSize = '14px';
          title.disabled = false;
          chart.exporting.dataFields = {
            "category": "CustomerType",
            "amountvalue": "Amount(" + that.CurrencyLabel + ")",
          }
        }
        else {
          chart.exporting.dataFields = {
            "category": "CustomerType",
            "unitvalue": that.CurrencyLabel,
          }
        }

        /**************End Custom Export**************** */

      }
    });
    //  //*************************************************************************** */
    chart.legend = new am4charts.Legend();

    let legendContainer = am4core.create("ctlegenddiv", am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart.legend.parent = legendContainer;
    chart.events.on("datavalidated", resizeLegend);
    chart.events.on("maxsizechanged", resizeLegend);

    chart.legend.events.on("datavalidated", resizeLegend);
    chart.legend.events.on("maxsizechanged", resizeLegend);
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
    function resizeLegend(ev) {
      document.getElementById("ctlegenddiv").style.height = chart.legend.contentHeight + "px";
    }
    chart.legend.itemContainers.template.tooltipText = "{category}";
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.category = "category";
    if (this.reportFilterObj.ReportValueType == "units") {
      series.dataFields.value = "unitvalue";
    } else {
      series.dataFields.value = "amountvalue";
    }
    chart.legend.labels.template.text = "[normal]{category}: [bold]{value.value}";
    // chart.legend.scrollable = true;
    chart.legend.valueLabels.template.disabled = true;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 20;
    chart.legend.valueLabels.template.align = "left"
    chart.legend.valueLabels.template.textAlign = "start"
    chart.legend.labels.template.maxWidth = 150;
    chart.legend.labels.template.wrap = true;
    chart.legend.labels.template.truncate = false;
    series.labels.template.text = "{category}: {value.value}";
    series.slices.template.tooltipText = "{category}: {value.value}";
    chart.legend.valueLabels.template.text = "{value.value}";
    series.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    //<TO HIDE THE LABELS WHICH HAS VALUE 0
    series.ticks.template.length = 2;
    series.ticks.template.events.on("ready", hideSmall);
    series.ticks.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.events.on("ready", hideSmall);
    series.labels.template.events.on("visibilitychanged", hideSmall);

    ///////////////////////////////////////////////////////////////
    /**************For mobile UI************* */
    let screenWidth = window.innerWidth;
    if (screenWidth < 750) {
      series.ticks.template.disabled = true;
      series.alignLabels = false;
      series.labels.template.text = "[bold]{category}: {value.value}";
      series.labels.template.radius = am4core.percent(-80);
      series.labels.template.fill = am4core.color("black");
      series.labels.template.relativeRotation = 90;
      series.labels.template.disabled = true;
      series.slices.template.tooltipText = "{category}: {value.value}";
    }
    series.labels.template.maxWidth = 250;
    series.labels.template.wrap = true;
    ////////////////////////////////////////////////////////

    function hideSmall(ev) {
      if (ev.target.dataItem && (ev.target.dataItem.values.value.percent == 0)) {
        ev.target.hide();
      }
      else {
        ev.target.show();
      }
    }
    //>
  }
  ProductWisePieChart() {
    const that = this;
    let chart = am4core.create("ProductWisePieChart", am4charts.PieChart);
    // chart.exporting.menu = new am4core.ExportMenu();
    let title = chart.titles.create();
    chart.legend = new am4charts.Legend();
    let legendContainer = am4core.create("productlinelegenddiv", am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart.legend.parent = legendContainer;
    chart.events.on("datavalidated", resizeLegend);
    chart.events.on("maxsizechanged", resizeLegend);

    chart.legend.events.on("datavalidated", resizeLegend);
    chart.legend.events.on("maxsizechanged", resizeLegend);
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
    function resizeLegend(ev) {
      document.getElementById("productlinelegenddiv").style.height = chart.legend.contentHeight + "px";
    }
    chart.data = this.productlineData;
    // //*************************************************************************** */
    chart.events.on("beforedatavalidated", function (ev) {
      // check if there's data
      var IsDataEmpty = false;
      var emptyUnits;
      if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
      if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
      if (emptyUnits.length == chart.data.length) {
        chart.hidden = true;
        IsDataEmpty = true;
      }
      var indicator;
      if (IsDataEmpty) {
        that.showIndicator(chart, indicator);
      }
      else if (indicator) {
        that.hideIndicator(indicator);
      }
      else {
        chart.exporting.menu = new am4core.ExportMenu();
        let options = chart.exporting.getFormatOptions("pdf");
        options.addURL = false;
        chart.exporting.setFormatOptions("pdf", options);
        chart.exporting.filePrefix = "ProductLineReport";
        /**************Custom Export**************** */
        chart.exporting.menu.items = [
          {
            "label": "...",
            "menu": [
              {
                "label": "Image",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "jpg", "label": "JPG" },
                  // { "type": "svg", "label": "SVG" },
                  { "type": "pdf", "label": "PDF" }
                ]
              }, {
                "label": "Data",
                "menu": [
                  { "type": "xlsx", "label": "XLSX" },
                ]
              }, {
                "label": "Print", "type": "print"
              }
            ]
          }
        ];
        chart.exporting.extraSprites.push({
          "sprite": legendContainer,
          "position": "bottom",
          "marginTop": 30
        });
        if (that.reportFilterObj.ReportValueType == "amount") {
          let title = chart.titles.create();
          title.text = "[bold]All values are in " + that.CurrencyLabel;
          title.fill = am4core.color("red");
          title.fontSize = '14px';
          title.disabled = false;
          chart.exporting.dataFields = {
            "category": "ProductLine",
            "amountvalue": "Amount(" + that.CurrencyLabel + ")",
          }
        }
        else {
          chart.exporting.dataFields = {
            "category": "ProductLine",
            "unitvalue": that.CurrencyLabel,
          }
        }

        /**************End Custom Export**************** */

      }
    });
    //  //*************************************************************************** */
    chart.legend.itemContainers.template.tooltipText = "{category}";
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.category = "category";
    if (this.reportFilterObj.ReportValueType == "units") {
      series.dataFields.value = "unitvalue";
    } else {
      series.dataFields.value = "amountvalue";
    }
    chart.legend.labels.template.text = "[normal]{category}: [bold]{value.value}";
    // chart.legend.scrollable = true;
    chart.legend.valueLabels.template.disabled = true;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 20;
    chart.legend.valueLabels.template.align = "left"
    chart.legend.valueLabels.template.textAlign = "start"
    chart.legend.labels.template.maxWidth = 150;
    chart.legend.labels.template.wrap = true;
    chart.legend.labels.template.truncate = false;
    series.labels.template.text = "{category}: {value.value}";
    series.slices.template.tooltipText = "{category}: {value.value}";
    chart.legend.valueLabels.template.text = "{value.value}";
    series.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    //<TO HIDE THE LABELS WHICH HAS VALUE 0
    series.ticks.template.length = 2;
    series.ticks.template.events.on("ready", hideSmall);
    series.ticks.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.events.on("ready", hideSmall);
    series.labels.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.maxWidth = 250;
    series.labels.template.wrap = true;
    ///////////////////////////////////////////////////////////////
    /**************For mobile UI************* */
    let screenWidth = window.innerWidth;
    if (screenWidth < 750) {
      series.ticks.template.disabled = true;
      series.alignLabels = false;
      series.labels.template.text = "[bold]{category}: {value.value}";
      series.labels.template.radius = am4core.percent(-80);
      series.labels.template.fill = am4core.color("black");
      series.labels.template.relativeRotation = 90;
      series.labels.template.disabled = true;
      series.slices.template.tooltipText = "{category}: {value.value}";
    }

    ////////////////////////////////////////////////////////

    function hideSmall(ev) {
      if (ev.target.dataItem && (ev.target.dataItem.values.value.percent == 0)) {
        ev.target.hide();
      }
      else {
        ev.target.show();
      }
    }
    //>
    series.slices.template.events.on("hit", function (ev) {
      that.onProductSliceClick(ev.target.dataItem.dataContext);
    });

  }
  onProductSliceClick(sliceObj) {
    this.isProductLineSelected = true;
    this.isProductSeriesSelected = false;
    this.selectedProductLine = sliceObj;
    this.reportFilterObj.ProductSeries = 0;
    this.reportFilterObj.Productmodal = 0;
    this.reportFilterObj.ProductLine = sliceObj.Id;
    this.ProductSeriesWisePieChart();
  }
  ProductSeriesWisePieChart() {

    this.showLoader = true;
    let queryString = "";
    queryString += "EnqType=" + this.reportFilterObj.EnqType;
    queryString += "&ReportValueType=" + this.reportFilterObj.ReportValueType;
    queryString += "&Year=" + this.reportFilterObj.Year;
    queryString += "&Region=" + this.reportFilterObj.Region;
    queryString += "&ProductLine=" + this.reportFilterObj.ProductLine;
    queryString += "&ProductSeries=" + this.reportFilterObj.ProductSeries;
    queryString += "&Productmodal=" + this.reportFilterObj.Productmodal;
    queryString += "&CustomerClass=" + this.reportFilterObj.CustomerClass;
    queryString += "&CustomerType=" + this.reportFilterObj.CustomerType;
    queryString += "&Segment=" + this.reportFilterObj.Segment;
    queryString += "&reporttype=" + this.reportFilterObj.reporttype;
    queryString += "&currency=" + this.reportFilterObj.curency;
    this.commonService.GetData("Report", "GetRegionSeriesReport", queryString).subscribe((data: any) => {
      let chart = am4core.create("ProductSeriesBarChart", am4charts.XYChart);
      // chart.exporting.menu = new am4core.ExportMenu();

      let title = chart.titles.create();
      title.text = this.selectedProductLine.category;
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;
      chart.hiddenState.properties.opacity = 0;
      this.chartData = this.productseriesData;
      chart.data = data.ProductSeries;
      // //*************************************************************************** */
      const that = this;
      chart.events.on("beforedatavalidated", function (ev) {
        // check if there's data
        var IsDataEmpty = false;
        var emptyUnits;
        if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
        if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
        if (emptyUnits.length == chart.data.length) {
          chart.hidden = true;
          IsDataEmpty = true;
        }
        var indicator;
        if (IsDataEmpty) {
          that.showIndicator(chart, indicator);
        }
        else if (indicator) {
          that.hideIndicator(indicator);
        }
        else {
          chart.scrollbarX = new am4core.Scrollbar();
          chart.scrollbarY = new am4core.Scrollbar();
         // chart.cursor = new am4charts.XYCursor();
          chart.scrollbarX.exportable = false;
          chart.scrollbarY.exportable = false;
          chart.exporting.menu = new am4core.ExportMenu();
          let options = chart.exporting.getFormatOptions("pdf");
          options.addURL = false;
          chart.exporting.setFormatOptions("pdf", options);
          chart.exporting.filePrefix = "ProductSeriesReport";
          /**************Custom Export**************** */
          chart.exporting.menu.items = [
            {
              "label": "...",
              "menu": [
                {
                  "label": "Image",
                  "menu": [
                    { "type": "png", "label": "PNG" },
                    { "type": "jpg", "label": "JPG" },
                    // { "type": "svg", "label": "SVG" },
                    { "type": "pdf", "label": "PDF" }
                  ]
                }, {
                  "label": "Data",
                  "menu": [
                    { "type": "xlsx", "label": "XLSX" },
                  ]
                }, {
                  "label": "Print", "type": "print"
                }
              ]
            }
          ];
          if (that.reportFilterObj.ReportValueType == "amount") {
            let title = chart.titles.create();
            title.text = "[bold]All values are in " + that.CurrencyLabel;
            title.fill = am4core.color("red");
            title.fontSize = '14px';
            title.disabled = false;
            chart.exporting.dataFields = {
              "category": "ProductSeries",
              "amountvalue": "Amount(" + that.CurrencyLabel + ")",
            }
          }
          else {
            chart.exporting.dataFields = {
              "category": "ProductSeries",
              "unitvalue": that.CurrencyLabel,
            }
          }

          /**************End Custom Export**************** */

        }
      });
      //  //*************************************************************************** */
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
     // categoryAxis.renderer.labels.template.rotation = -45;
     categoryAxis.renderer.labels.template.wrap = true;
      categoryAxis.renderer.labels.template.maxWidth = 100;
    //  categoryAxis.renderer.labels.template.truncate = true;
      categoryAxis.renderer.labels.template.tooltipText = '{category}';
       /**************Scrolling Start End Point**************** */
       categoryAxis.start=0;
       categoryAxis.end=0.5;
      /**************End Scrolling Start End Point**************** */
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.extraMax = 0.5;
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.000" ? "0" : text;
        return val;
      });
      if (this.reportFilterObj.ReportValueType == "units") {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        if (this.reportFilterObj.curency == 1) valueAxis.title.text = "MUSD";
        if (this.reportFilterObj.curency == 2) valueAxis.title.text = "MEURO";
        if (this.reportFilterObj.curency == 3) valueAxis.title.text = "MINR";
       // chart.numberFormatter.numberFormat = "0.000";
      }

      // Create series
      var columnSeries = chart.series.push(new am4charts.ColumnSeries());
      if (this.reportFilterObj.ReportValueType == "units") {
        columnSeries.dataFields.valueY = "unitvalue";
      } else {
        columnSeries.dataFields.valueY = "amountvalue";
      }
      columnSeries.dataFields.categoryX = "category";
      columnSeries.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      colLabel.label.text = "{valueY}";
      //colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;

      columnSeries.columns.template.interactionsEnabled = true;
      columnSeries.columns.template.events.on("hit", ev => {
        var item: any = ev.target.dataItem.dataContext;
        this.isProductSeriesSelected = true;
        this.isProductModelSelected = false;
        this.selectedProductSeries = item;
        this.reportFilterObj.Productmodal = 0;
        this.reportFilterObj.ProductLine = 0;
        this.reportFilterObj.ProductSeries = item.Id;
        this.ProductModelWisePieChart();
      },
      );
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });

  }
  ProductModelWisePieChart() {

    this.showLoader = true;
    let queryString = "";
    queryString += "EnqType=" + this.reportFilterObj.EnqType;
    queryString += "&ReportValueType=" + this.reportFilterObj.ReportValueType;
    queryString += "&Year=" + this.reportFilterObj.Year;
    queryString += "&Region=" + this.reportFilterObj.Region;
    queryString += "&ProductLine=" + this.reportFilterObj.ProductLine;
    queryString += "&ProductSeries=" + this.reportFilterObj.ProductSeries;
    queryString += "&Productmodal=" + this.reportFilterObj.Productmodal;
    queryString += "&CustomerClass=" + this.reportFilterObj.CustomerClass;
    queryString += "&CustomerType=" + this.reportFilterObj.CustomerType;
    queryString += "&Segment=" + this.reportFilterObj.Segment;
    queryString += "&reporttype=" + this.reportFilterObj.reporttype;
    queryString += "&currency=" + this.reportFilterObj.curency;
    this.commonService.GetData("Report", "GetRegionProductModelReport", queryString).subscribe((data: any) => {
      let chart = am4core.create("ProductModelBarChart", am4charts.XYChart);
      // chart.exporting.menu = new am4core.ExportMenu();

      let title = chart.titles.create();
      title.text = this.selectedProductSeries.category;
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;
      title.wrap = true;
      title.maxWidth=350;

      chart.hiddenState.properties.opacity = 0;
      this.chartData = this.producModeleData;
      chart.data = data.ProductModel;
      // //*************************************************************************** */
      const that = this;
      chart.events.on("beforedatavalidated", function (ev) {
        // check if there's data
        var IsDataEmpty = false;
        var emptyUnits;
        if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
        if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
        if (emptyUnits.length == chart.data.length) {
          chart.hidden = true;
          IsDataEmpty = true;
        }
        var indicator;
        if (IsDataEmpty) {
          that.showIndicator(chart, indicator);
        }
        else if (indicator) {
          that.hideIndicator(indicator);
        }
        else {
          chart.scrollbarX = new am4core.Scrollbar();
          chart.scrollbarY = new am4core.Scrollbar();
         // chart.cursor = new am4charts.XYCursor();
          chart.scrollbarX.exportable = false;
          chart.scrollbarY.exportable = false;
          chart.exporting.menu = new am4core.ExportMenu();
          let options = chart.exporting.getFormatOptions("pdf");
          options.addURL = false;
          chart.exporting.setFormatOptions("pdf", options);
          chart.exporting.filePrefix = "ProductModelReport";
          /**************Custom Export**************** */
          chart.exporting.menu.items = [
            {
              "label": "...",
              "menu": [
                {
                  "label": "Image",
                  "menu": [
                    { "type": "png", "label": "PNG" },
                    { "type": "jpg", "label": "JPG" },
                    // { "type": "svg", "label": "SVG" },
                    { "type": "pdf", "label": "PDF" }
                  ]
                }, {
                  "label": "Data",
                  "menu": [
                    { "type": "xlsx", "label": "XLSX" },
                  ]
                }, {
                  "label": "Print", "type": "print"
                }
              ]
            }
          ];
          if (that.reportFilterObj.ReportValueType == "amount") {
            let title = chart.titles.create();
            title.text = "[bold]All values are in " + that.CurrencyLabel;
            title.fill = am4core.color("red");
            title.fontSize = '14px';
            title.disabled = false;
            chart.exporting.dataFields = {
              "category": "ProductModel",
              "amountvalue": "Amount(" + that.CurrencyLabel + ")",
            }
          }
          else {
            chart.exporting.dataFields = {
              "category": "ProductModel",
              "unitvalue": that.CurrencyLabel,
            }
          }

          /**************End Custom Export**************** */

        }
      });
      //  //*************************************************************************** */
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
     // categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.labels.template.maxWidth = 100;
      categoryAxis.renderer.labels.template.wrap = true;
     // categoryAxis.renderer.labels.template.truncate = true;
      categoryAxis.renderer.labels.template.tooltipText = '{category}';
       /**************Scrolling Start End Point**************** */
       categoryAxis.start=0;
       categoryAxis.end=0.5;
      /**************End Scrolling Start End Point**************** */
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.extraMax = 0.5;
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.000" ? "0" : text;
        return val;
      });
      if (this.reportFilterObj.ReportValueType == "units") {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        if (this.reportFilterObj.curency == 1) valueAxis.title.text = "MUSD";
        if (this.reportFilterObj.curency == 2) valueAxis.title.text = "MEURO";
        if (this.reportFilterObj.curency == 3) valueAxis.title.text = "MINR";
       // chart.numberFormatter.numberFormat = "0.000";
      }

      // Create series
      var columnSeries = chart.series.push(new am4charts.ColumnSeries());
      if (this.reportFilterObj.ReportValueType == "units") {
        columnSeries.dataFields.valueY = "unitvalue";
      } else {
        columnSeries.dataFields.valueY = "amountvalue";
      }
      columnSeries.dataFields.categoryX = "category";
      columnSeries.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      colLabel.label.text = "{valueY}";
      //colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;
      columnSeries.columns.template.events.on("hit", ev => {
        var item: any = ev.target.dataItem.dataContext;
        this.isProductSeriesSelected = true;
        this.isProductModelSelected = true;
        this.selectedProductLine = item;
        this.reportFilterObj.ProductSeries = 0;
        this.reportFilterObj.ProductLine = 0;
        this.reportFilterObj.Productmodal = item.Id;
        this.ProductStationWisePieChart();
      });
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }
  ProductStationWisePieChart() {
    this.showLoader = true;
    let queryString = "";
    queryString += "EnqType=" + this.reportFilterObj.EnqType;
    queryString += "&ReportValueType=" + this.reportFilterObj.ReportValueType;
    queryString += "&Year=" + this.reportFilterObj.Year;
    queryString += "&Region=" + this.reportFilterObj.Region;
    queryString += "&ProductLine=" + this.reportFilterObj.ProductLine;
    queryString += "&ProductSeries=" + this.reportFilterObj.ProductSeries;
    queryString += "&Productmodal=" + this.reportFilterObj.Productmodal;
    queryString += "&CustomerClass=" + this.reportFilterObj.CustomerClass;
    queryString += "&CustomerType=" + this.reportFilterObj.CustomerType;
    queryString += "&Segment=" + this.reportFilterObj.Segment;
    queryString += "&reporttype=" + this.reportFilterObj.reporttype;
    queryString += "&currency=" + this.reportFilterObj.curency;
    this.commonService.GetData("Report", "GetRegionProductStationReport", queryString).subscribe((data: any) => {
      let chart = am4core.create("ProductStationBarChart", am4charts.XYChart);
      this.VariantLabel = data.ProductModelStation_label;
      // chart.exporting.menu = new am4core.ExportMenu();

      let title = chart.titles.create();
      title.text = this.selectedProductLine.category;
      title.fontSize = 15;
      title.fontWeight = "bold";
      title.marginBottom = 30;
      title.wrap = true;
      title.maxWidth=350;
      
      chart.hiddenState.properties.opacity = 0;
      chart.data = data.ProductModelStation;
      // //*************************************************************************** */
      const that = this;
      chart.events.on("beforedatavalidated", function (ev) {
        // check if there's data
        var IsDataEmpty = false;
        var emptyUnits;
        if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
        if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
        if (emptyUnits.length == chart.data.length) {
          chart.hidden = true;
          IsDataEmpty = true;
        }
        var indicator;
        if (IsDataEmpty) {
          that.showIndicator(chart, indicator);
        }
        else if (indicator) {
          that.hideIndicator(indicator);
        }
        else {
          chart.scrollbarX = new am4core.Scrollbar();
          chart.scrollbarY = new am4core.Scrollbar();
         // chart.cursor = new am4charts.XYCursor();
          chart.scrollbarX.exportable = false;
          chart.scrollbarY.exportable = false;
          chart.exporting.menu = new am4core.ExportMenu();
          let options = chart.exporting.getFormatOptions("pdf");
          options.addURL = false;
          chart.exporting.setFormatOptions("pdf", options);
          chart.exporting.filePrefix = that.VariantLabel + "Report";
          /**************Custom Export**************** */
          chart.exporting.menu.items = [
            {
              "label": "...",
              "menu": [
                {
                  "label": "Image",
                  "menu": [
                    { "type": "png", "label": "PNG" },
                    { "type": "jpg", "label": "JPG" },
                    // { "type": "svg", "label": "SVG" },
                    { "type": "pdf", "label": "PDF" }
                  ]
                }, {
                  "label": "Data",
                  "menu": [
                    { "type": "xlsx", "label": "XLSX" },
                  ]
                }, {
                  "label": "Print", "type": "print"
                }
              ]
            }
          ];
          if (that.reportFilterObj.ReportValueType == "amount") {
            let title = chart.titles.create();
            title.text = "[bold]All values are in " + that.CurrencyLabel;
            title.fill = am4core.color("red");
            title.fontSize = '14px';
            title.disabled = false;
            chart.exporting.dataFields = {
              "category": that.VariantLabel,
              "amountvalue": "Amount(" + that.CurrencyLabel + ")",
            }
          }
          else {
            chart.exporting.dataFields = {
              "category": that.VariantLabel,
              "unitvalue": that.CurrencyLabel,
            }
          }

          /**************End Custom Export**************** */

        }
      });
      //  //*************************************************************************** */
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
     // categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.labels.template.maxWidth = 100;
      categoryAxis.renderer.labels.template.wrap = true;
     // categoryAxis.renderer.labels.template.truncate = true;
      categoryAxis.renderer.labels.template.tooltipText = '{category}';
       /**************Scrolling Start End Point**************** */
       categoryAxis.start=0;
       categoryAxis.end=0.5;
      /**************End Scrolling Start End Point**************** */
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.extraMax = 0.5;
      valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
        var val = text == "0.000" ? "0" : text;
        return val;
      });
      if (this.reportFilterObj.ReportValueType == "units") {
        valueAxis.title.text = "No.";
        chart.numberFormatter.numberFormat = "0";
        valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
          var val = parseFloat(text) % 1 != 0 ? "" : parseInt(text).toString();
          return val;
        });
      }
      else {
        if (this.reportFilterObj.curency == 1) valueAxis.title.text = "MUSD";
        if (this.reportFilterObj.curency == 2) valueAxis.title.text = "MEURO";
        if (this.reportFilterObj.curency == 3) valueAxis.title.text = "MINR";
       // chart.numberFormatter.numberFormat = "0.000";
      }
      // Create series
      var columnSeries = chart.series.push(new am4charts.ColumnSeries());
      if (this.reportFilterObj.ReportValueType == "units") {
        columnSeries.dataFields.valueY = "unitvalue";
      } else {
        columnSeries.dataFields.valueY = "amountvalue";
      }
      columnSeries.dataFields.categoryX = "category";
      columnSeries.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#4a463c");
      let colLabel = columnSeries.bullets.push(new am4charts.LabelBullet);
      colLabel.label.text = "{valueY}";
      //colLabel.label.rotation = 0;
      colLabel.label.fontSize = 9;
      colLabel.label.padding(0, 0, 20, 0);
      colLabel.label.truncate = false;
      colLabel.label.hideOversized = false;

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }
  RegionWisePieChart() {
    const that = this;
    let chart = am4core.create("RegionWisePieChart", am4charts.PieChart);
    // chart.exporting.menu = new am4core.ExportMenu();
    chart.hiddenState.properties.opacity = 0;
    // chart.data = this.segmentData;
    chart.data = this.regionData;
    // //*************************************************************************** */
    chart.events.on("beforedatavalidated", function (ev) {
      // check if there's data
      var IsDataEmpty = false;
      var emptyUnits;
      if (that.reportFilterObj.ReportValueType == 'units') emptyUnits = chart.data.filter(x => x.unitvalue == 0);
      if (that.reportFilterObj.ReportValueType == 'amount') emptyUnits = chart.data.filter(x => x.amountvalue == 0);
      if (emptyUnits.length == chart.data.length) {
        chart.hidden = true;
        IsDataEmpty = true;
      }
      var indicator;
      if (IsDataEmpty) {
        that.showIndicator(chart, indicator);
      }
      else if (indicator) {
        that.hideIndicator(indicator);
      }
      else {
        chart.exporting.menu = new am4core.ExportMenu();
        let options = chart.exporting.getFormatOptions("pdf");
        options.addURL = false;
        chart.exporting.setFormatOptions("pdf", options);
        chart.exporting.filePrefix = "RegionReport";
        /**************Custom Export**************** */
        chart.exporting.menu.items = [
          {
            "label": "...",
            "menu": [
              {
                "label": "Image",
                "menu": [
                  { "type": "png", "label": "PNG" },
                  { "type": "jpg", "label": "JPG" },
                  // { "type": "svg", "label": "SVG" },
                  { "type": "pdf", "label": "PDF" }
                ]
              }, {
                "label": "Data",
                "menu": [
                  { "type": "xlsx", "label": "XLSX" },
                ]
              }, {
                "label": "Print", "type": "print"
              }
            ]
          }
        ];
        chart.exporting.extraSprites.push({
          "sprite": legendContainer,
          "position": "bottom",
          "marginTop": 30
        });
        if (that.reportFilterObj.ReportValueType == "amount") {
          let title = chart.titles.create();
          title.text = "[bold]All values are in " + that.CurrencyLabel;
          title.fill = am4core.color("red");
          title.fontSize = '14px';
          title.disabled = false;
          chart.exporting.dataFields = {
            "category": "Region",
            "amountvalue": "Amount(" + that.CurrencyLabel + ")",
          }
        }
        else {
          chart.exporting.dataFields = {
            "category": "Region",
            "unitvalue": that.CurrencyLabel,
          }
        }

        /**************End Custom Export**************** */

      }
    });
    //  //*************************************************************************** */
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.tooltipText = "{category}";
    let legendContainer = am4core.create("regionlegenddiv", am4core.Container);
    legendContainer.width = am4core.percent(100);
    legendContainer.height =300;
    chart.legend.parent = legendContainer;
    chart.events.on("datavalidated", resizeLegend);
    chart.events.on("maxsizechanged", resizeLegend);

    chart.legend.events.on("datavalidated", resizeLegend);
    chart.legend.events.on("maxsizechanged", resizeLegend);
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    function resizeLegend(ev) {
      document.getElementById("regionlegenddiv").style.height = chart.legend.contentHeight + "px";
    }
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 20;
    chart.legend.valueLabels.template.align = "left"
    chart.legend.valueLabels.template.textAlign = "start";
    chart.legend.labels.template.maxWidth = 150;
    chart.legend.labels.template.wrap = true;
    chart.legend.labels.template.truncate = false;
    chart.legend.itemContainers.template.tooltipText = "{category}";

    var series = chart.series.push(new am4charts.PieSeries());

    series.dataFields.category = "category";
    if (this.reportFilterObj.ReportValueType == "units") {
      series.dataFields.value = "unitvalue";
    } else {
      series.dataFields.value = "amountvalue";
    }
    chart.legend.labels.template.text = "[normal]{category}: [bold]{value.value}";
    // chart.legend.scrollable = true;
    chart.legend.valueLabels.template.disabled = true;
    series.labels.template.text = "{category}: {value.value}";
    series.slices.template.tooltipText = "{category}: {value.value}";
    chart.legend.valueLabels.template.text = "{value.value}";
    series.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    //<TO HIDE THE LABELS WHICH HAS VALUE 0
    series.ticks.template.length = 2;
    series.ticks.template.events.on("ready", hideSmall);
    series.ticks.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.events.on("ready", hideSmall);
    series.labels.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.maxWidth = 250;
    series.labels.template.wrap = true;
    ///////////////////////////////////////////////////////////////
    /**************For mobile UI************* */
    let screenWidth = window.innerWidth;
    if (screenWidth < 750) {
      series.ticks.template.disabled = true;
      series.alignLabels = false;
      series.labels.template.text = "[bold]{category}: {value.value}";
      series.labels.template.radius = am4core.percent(-80);
      series.labels.template.fill = am4core.color("black");
      series.labels.template.relativeRotation = 90;
      series.labels.template.disabled = true;
      series.slices.template.tooltipText = "{category}: {value.value}";
    }

    ////////////////////////////////////////////////////////

    function hideSmall(ev) {
      if (ev.target.dataItem && (ev.target.dataItem.values.value.percent == 0)) {
        ev.target.hide();
      }
      else {
        ev.target.show();
      }
    }
    //>
    series.slices.template.events.on("hit", function (ev) {
      // that.onSegmentSliceClick(ev.target.dataItem.dataContext);
      series.slices.each(function (item) {
        if (item.isActive && item != ev.target) {
          item.isActive = false;
        }
      })
    });
    this.showLoader = false;
  }
  onEnqTypeChange() {
    this.SegmentWisePieChart();
  }
  onReportValueTypeChange() {
    if (this.reportFilterObj.ReportValueType == "amount") {
      if (this.reportFilterObj.curency == 1) this.CurrencyLabel = "MUSD";
      if (this.reportFilterObj.curency == 2) this.CurrencyLabel = "MEURO";
      if (this.reportFilterObj.curency == 3) this.CurrencyLabel = "MINR";
    }
    else {
      this.CurrencyLabel = "No's";
    }
    this.SegmentWisePieChart();
  }
  AllRegionChange(event: any) {
    if (event.checked) {
      this.reportFilterObj.Segment = 0;
      this.selectedSegment = undefined;
      this.isSegmentSelected = false;
      this.SegmentWisePieChart();
    }
  }
  showIndicator(chart, indicator) {
    if (indicator) {
      indicator.show();
    }
    else {
      indicator = chart.tooltipContainer.createChild(am4core.Container);
      indicator.background.fill = am4core.color("#fff");
      indicator.background.fillOpacity = 0.8;
      indicator.width = am4core.percent(100);
      indicator.height = am4core.percent(100);

      var indicatorLabel = indicator.createChild(am4core.Label);
      indicatorLabel.text = "No data available for the selection...";
      indicatorLabel.align = "center";
      indicatorLabel.valign = "middle";
      indicatorLabel.fontSize = 20;
    }
  }

  hideIndicator(indicator) {
    indicator.hide();
  }
}
