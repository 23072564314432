
<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add Customer Frame Agreement
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                      <form class="m-login__form m-form" name="form" #myform="ngForm"
                                (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                                <div class="m-portlet__body">
    
                                    <div class="form-group m-form__group row">
                                        <label
                                            class="col-lg-2 col-form-label">Company Name:<span
                                                style="color:#f4516c;">&nbsp;*</span></label>
                                        <div class="col-lg-3">
                                            <select class="form-control " id="sel1" name="customer" 
                                                [(ngModel)]="singleFieldObj.Fk_Customer" #customer="ngModel" 
                                                (change)="onCompanyselection($event)"
                                                [ngClass]="{'is-invalid':myform.submitted  && customer.invalid || customer?.touched && customer.invalid}"
                                                [disabled]="isdropdowninactive" required>
                                                <option  value="" disabled selected hidden>Select
                                                    Company Name</option>
    
                                                <option *ngFor="let item of Customer" value="{{item.Id}}">
                                                    {{item.CompanyName}}
                                                </option>
    
                                            </select>
                                            <div class="invalid-feedback"
                                                *ngIf="myform.submitted && customer.invalid || customer?.touched && customer.invalid">
                                                <div *ngIf="myform.hasError('required', 'customer')">
                                                    Company Name is required</div>
                                            </div>
    
                                        </div>
    
                                        <div class="offset-1"></div>
    
                                       
                                    </div>
    
                                    <div class="form-group m-form__group row">
                                        <!-- <label
                                            class="col-lg-2 col-form-label">{{singleFieldComponent.firstFiledLabel}}:<span
                                                style="color:#f4516c;">&nbsp;</span></label> -->
    
                                        <div class="table-responsive">
                                            <table
                                                class="table order-list table-striped- table-bordered table-hover table-edit"
                                                id="myTable">
                                                <thead>
                                                    <tr>
    
                                                        <th>
                                                            Product Model
                                                        </th>
                                                        <th>
                                                            Existing Frame Agreement Discount
                                                        </th>
                                                        <th>
                                                            New Frame Agreement Discount
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
    
                                                    <ng-container
                                                        *ngFor="let field of singleFieldObj.customerSpecialDiscountInfo ; let i = index;">
                                                        <tr>
                                                            <td>
                                                                <select class="form-control" id="productmodel{{i+1}}"
                                                                    name="productmodel{{i+1}}"
                                                                    [(ngModel)]="field.Fk_ProductModel"
                                                                    #productmodel="ngModel"
                                                                    (change)="OnProductModelChange(i)" required>
                                                                    <option  value="" disabled selected hidden >Select
                                                                        Product Model</option>
    
                                                                    <option *ngIf="showItems!=undefined"
                                                                        value="{{showItems[i]?.value.Id}}">
                                                                        {{showItems[i]?.value.ModelName}}
                                                                    </option>
                                                                    <option *ngFor="let item of ProductModel"
                                                                        value="{{item.Id}}">
                                                                        {{item.ModelName}}
                                                                    </option>
    
                                                                </select>
                                                                <div *ngIf="(field.ProductModelRequired)">
                                                                    <span class="validationmsg">
                                                                        Product Model is required</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" id="approve{{i+1}}" name="approve{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="field.ProductDiscount" #approve="ngModel"
                                                                    (keypress)="OnDiscountChange(i)" 
                                                                    placeholder="Enter Discount"
                                                                    disabled>
                                        
                                                            </td>

                                                            
                                                            <td>
                                                                <input type="text" id="from{{i+1}}" name="from{{i+1}}"
                                                                    class="form-control input-sm responsiveinput"
                                                                    [(ngModel)]="field.UnapprovedProductDiscount" #from="ngModel"
                                                                    (keypress)="OnDiscountChange(i)" maxlength="2" (keypress)="AvoidSpace($event);isintNumberKey($event);"
                                                                    placeholder="Enter Discount ">
    
                                                                <!-- <div *ngIf="(field.productDiscountrequired)">
                                                                    <span class="validationmsg">
                                                                        Discount is
                                                                        required</span>
                                                                </div> -->
                                                            </td>
                                                            <td>
    
                                                                <a title="Add"
                                                                    *ngIf="(singleFieldObj.customerSpecialDiscountInfo.length>0) && ((singleFieldObj.customerSpecialDiscountInfo.length - 1 ) == i)"
                                                                    (click)="AddNewRow(singleFieldObj.customerSpecialDiscountInfo.length+1)"
                                                                    style="color:#000000;width: 25px;height: 25px;float: right;"
                                                                    class="btn btn-primary m-btn m-btn--icon m-btn--icon-only mr-2">
                                                                    <i class="fa fa-plus"></i>
                                                                </a>
                                                                <a title="Delete"
                                                                    *ngIf="(singleFieldObj.customerSpecialDiscountInfo.length>1) && ((singleFieldObj.customerSpecialDiscountInfo.length - 1 ) == i)"
                                                                    (click)="deleterow(i)"
                                                                    style="color:#ffffff;width: 25px;height: 25px;"
                                                                    class="btn btn-metal m-btn m-btn--icon m-btn--icon-only mr-2"><i
                                                                        class="fa fa-minus"></i></a>
                                                            </td>
    
    
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
    
                                    </div>
    
                                
                                </div>
                                <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                    <div class="m-form__actions m-form__actions--solid">
                                        <div class="row">
                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-10">
                                                                                                   
                                                    <span  *ngFor="let item of workflowActionList;let i = index;">
                                                        <button 
                                                            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air" type="{{item.action_type}}"
                                                            (click)="OnWorkflowAction(myform,item.next_state_id)" >
                                                        {{item.actionname}} 
                                                     </button> &nbsp; &nbsp;
                                                    </span>
                                                    
            
                                                <!-- <a routerLink="/CustomerFrameAgreementDetails"
                                                    routerLinkActive="m-menu__item--active"
                                                    class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a> -->
                                                    <a (click)="goBack()"
                                                    routerLinkActive="m-menu__item--active"
                                                    class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
    
                </div>
    
            </div>
        </div>
    </div>
