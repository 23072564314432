import { UserRoles } from './UserRoles';
import { EnquiryStatus } from './EnquiryStatus';

export const AppGlobals = {

    //<Local URLs>  

    //  baseAppUrl: 'http://localhost:64591/',
    //  baseFileDownloadUrl: 'http://localhost:64591/Uploads/',
    //  QuotationFileDownloadUrl: 'http://localhost:64591/Uploads/pdf/',
    //  ZipDownloadUrl: 'http://localhost:64591/Uploads/Zip/',
    //  catlogDownloadPath: 'http://localhost:64591/Uploads/Catlog/',
    //  OADownloadPath:'http://localhost:64591/Uploads/OAFiles/',
    //  loginImagePath:"http://localhost:64591/Uploads/LoginPageImage/",


    //Dev URLs

    // baseAppUrl: 'http://devweiss.ecotechservices.com/api/',
    // baseFileDownloadUrl: 'http://devweiss.ecotechservices.com/api/Uploads/',
    // QuotationFileDownloadUrl: 'http://devweiss.ecotechservices.com/api/Uploads/pdf/',
    // ZipDownloadUrl: 'http://devweiss.ecotechservices.com/api/Uploads/Zip/',
    // catlogDownloadPath: 'http://devweiss.ecotechservices.com/api/Uploads/Catlog/',
    // OADownloadPath:'http://devweiss.ecotechservices.com/api/Uploads/OAFiles/',
    // loginImagePath:"http://devweiss.ecotechservices.com/api/Uploads/LoginPageImage/",
  
  
     //<Client UAT Urls>
    baseAppUrl: 'https://uatcrm.weiss-india.com/api/',
    baseFileDownloadUrl: 'https://uatcrm.weiss-india.com/api/Uploads/',
    QuotationFileDownloadUrl: 'https://uatcrm.weiss-india.com/api/Uploads/pdf/',
    ZipDownloadUrl: 'https://uatcrm.weiss-india.com/api/Uploads/Zip/',
    catlogDownloadPath: 'https://uatcrm.weiss-india.com/api/Uploads/Catalog/',
    OADownloadPath:'https://uatcrm.weiss-india.com/api/Uploads/OAFiles/',
    loginImagePath:"https://uatcrm.weiss-india.com/api/Uploads/LoginPageImage/",

    //<Client LIVE Urls>
    
    // baseAppUrl: 'https://crm.weiss-india.com/api/',
    // baseFileDownloadUrl: 'https://crm.weiss-india.com/api/Uploads/',
    // QuotationFileDownloadUrl: 'https://crm.weiss-india.com/api/Uploads/pdf/',
    // ZipDownloadUrl: 'https://crm.weiss-india.com/api/Uploads/Zip/',
    // catlogDownloadPath: 'https://crm.weiss-india.com/api//Uploads/Catlog/',
    // OADownloadPath:'https://crm.weiss-india.com/api/Uploads/OAFiles/',
    // loginImagePath:"https://crm.weiss-india.com/api/Uploads/LoginPageImage/",
 
    title: 'Global Title',
    supportedFileFormatForImage: '[Image Format: .jpeg, .bmp, .jpg, .png] [Max 5 MB]',
    supportedFileFormatsForOtherAttachments: '[File Format: .bmp, .jpeg, .jpg, .png, .xls, .xlsx, .pdf, .xml, .txt, .doc, .docx, .zip, .csv] [Max 5 MB]',
    supportedFileFormatsForOtherMultipleAttachments: '[File Format: .bmp, .jpeg, .jpg, .png, .xls, .xlsx, .pdf, .xml, .txt, .doc, .docx, .zip, .csv] [Max 5 MB]',
    YearsConstant: ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030",
        "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040",
        "2041", "2042", "2043", "2044", "2045", "2046", "2047", "2048", "2049", "2050"],
    AllRolesArray: [
        UserRoles.SalesEngineer
        , UserRoles.RegionalSalesManager
        , UserRoles.AreaSalesManager
        , UserRoles.L1
        , UserRoles.L2
        , UserRoles.L3
        , UserRoles.CustomerService
        , UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.ExecutiveBusinessAssistant
        , UserRoles.SupplyChain
        , UserRoles.FinanceAccounts
        , UserRoles.BusinessDevelopment
        , UserRoles.Marketing
        , UserRoles.ChannelPartner
        , UserRoles.Distributor
        , UserRoles.Agent
        , UserRoles.Admin
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],

    //<VisibleAccess
    ProductMasterVisibleArray: [
        UserRoles.SalesEngineer
        , UserRoles.RegionalSalesManager
        , UserRoles.AreaSalesManager
        , UserRoles.L1
        , UserRoles.L2
        , UserRoles.L3
        , UserRoles.CustomerService
        , UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.ExecutiveBusinessAssistant
        , UserRoles.SupplyChain
        , UserRoles.FinanceAccounts
        , UserRoles.Agent
        , UserRoles.Admin
        , UserRoles.BusinessDevelopment
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    CustomerVisibleArray: [
        UserRoles.SalesEngineer
        , UserRoles.RegionalSalesManager
        , UserRoles.AreaSalesManager
        , UserRoles.L1
        , UserRoles.L2
        , UserRoles.L3
        , UserRoles.CustomerService
        , UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.ExecutiveBusinessAssistant
        , UserRoles.SupplyChain
        , UserRoles.FinanceAccounts
        , UserRoles.BusinessDevelopment
        , UserRoles.Marketing
        , UserRoles.ChannelPartner
        , UserRoles.Distributor
        , UserRoles.Agent
        , UserRoles.Admin
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    GuestVisibleArray: [
        UserRoles.Admin
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    EmployeeVisibleArray: [
        UserRoles.SalesEngineer
        , UserRoles.RegionalSalesManager
        , UserRoles.AreaSalesManager
        , UserRoles.L1
        , UserRoles.L2
        , UserRoles.L3
        , UserRoles.CustomerService
        , UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.ExecutiveBusinessAssistant
        , UserRoles.SupplyChain
        , UserRoles.FinanceAccounts
        , UserRoles.Admin
        , UserRoles.BusinessDevelopment
        , UserRoles.Marketing
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    COnfigureVisibleArray: [
        UserRoles.Admin,
        , UserRoles.ManagerSolutionsFactoryAndLocalization
        ],
    TnCVisibleArray: [UserRoles.Admin
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    BusinessPlanVisibleArray: [
        UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.Admin
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    StockInventoryVisibleArray: [
        UserRoles.SalesEngineer
        , UserRoles.RegionalSalesManager
        , UserRoles.AreaSalesManager
        , UserRoles.L1
        , UserRoles.L2
        , UserRoles.L3
        , UserRoles.CustomerService
        , UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.ExecutiveBusinessAssistant
        , UserRoles.SupplyChain
        , UserRoles.FinanceAccounts
        , UserRoles.BusinessDevelopment
        , UserRoles.Marketing
        , UserRoles.ChannelPartner
        , UserRoles.Distributor
        , UserRoles.Agent
        , UserRoles.Admin
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    WorkFlowScreenAccessVisibleArray: [
        UserRoles.Admin
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    AlertsAccessVisibleArray: [
        UserRoles.Admin
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    //>
    //<EditableAccess
    ProductMasterEditArray: [
        UserRoles.Admin
        , UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.BusinessDevelopment
        , UserRoles.Marketing
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    CustomerEditArray: [
        UserRoles.SalesEngineer
        , UserRoles.RegionalSalesManager
        , UserRoles.AreaSalesManager
        , UserRoles.L1
        , UserRoles.L2
        , UserRoles.L3
        , UserRoles.CustomerService
        , UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.ExecutiveBusinessAssistant
        , UserRoles.SupplyChain
        , UserRoles.FinanceAccounts
        , UserRoles.BusinessDevelopment
        , UserRoles.Marketing
        , UserRoles.ChannelPartner
        , UserRoles.Distributor
        , UserRoles.Agent
        , UserRoles.Admin
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],
    CustomerMastersDataDownloadAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: true, isVisible: true },
        { role: UserRoles.Marketing, isEditable: true, isVisible: true },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: true, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: true, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    CustomerMastersKYCAttachmentAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: true, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: true, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: true, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: true, isVisible: true },
        { role: UserRoles.Marketing, isEditable: true, isVisible: true },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: true, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: true, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    GuestEditArray: [UserRoles.Admin,UserRoles.ManagerSolutionsFactoryAndLocalization],
    EmployeeEditArray: [UserRoles.Admin,UserRoles.ManagerSolutionsFactoryAndLocalization],
    COnfigureEditArray: [UserRoles.Admin,UserRoles.ManagerSolutionsFactoryAndLocalization],
    TnCEditArray: [UserRoles.Admin,UserRoles.ManagerSolutionsFactoryAndLocalization],
    WinLossEditArray: [UserRoles.Admin,UserRoles.ManagerSolutionsFactoryAndLocalization],
    BusinessPlanEditArray: [
        UserRoles.SalesDirector
        , UserRoles.CEO
        , UserRoles.BusinessDevelopment
        , UserRoles.Marketing
        , UserRoles.ProductManager
        , UserRoles.ManagerSolutionsFactoryAndLocalization
    ],

    EnquiryDataExportAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: true, isVisible: true },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: true, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: true, isVisible: true },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true },
    ],
    //>

    //<
    ProductMastersEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: true },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: true },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: true },
        { role: UserRoles.L1, isEditable: false, isVisible: true },
        { role: UserRoles.L2, isEditable: false, isVisible: true },
        { role: UserRoles.L3, isEditable: false, isVisible: true },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: true },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: true },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: true },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: true },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: true },
        { role: UserRoles.ProductManager, isEditable: true, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    CustomerMastersEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: true, isVisible: true },
        { role: UserRoles.RegionalSalesManager, isEditable: true, isVisible: true },
        { role: UserRoles.AreaSalesManager, isEditable: true, isVisible: true },
        { role: UserRoles.L1, isEditable: true, isVisible: true },
        { role: UserRoles.L2, isEditable: true, isVisible: true },
        { role: UserRoles.L3, isEditable: true, isVisible: true },
        { role: UserRoles.BusinessDevelopment, isEditable: true, isVisible: true },
        { role: UserRoles.Marketing, isEditable: true, isVisible: true },
        { role: UserRoles.SalesDirector, isEditable: true, isVisible: true },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: true, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: true, isVisible: true },
        { role: UserRoles.SupplyChain, isEditable: true, isVisible: true },
        { role: UserRoles.ChannelPartner, isEditable: true, isVisible: true },
        { role: UserRoles.Distributor, isEditable: true, isVisible: true },
        { role: UserRoles.Agent, isEditable: true, isVisible: true },
        { role: UserRoles.CustomerService, isEditable: true, isVisible: true },
        { role: UserRoles.ProductManager, isEditable: true, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    EmployeeMastersEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: true },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: true },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: true },
        { role: UserRoles.L1, isEditable: false, isVisible: true },
        { role: UserRoles.L2, isEditable: false, isVisible: true },
        { role: UserRoles.L3, isEditable: false, isVisible: true },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: true },
        { role: UserRoles.Marketing, isEditable: false, isVisible: true },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: true },
        { role: UserRoles.CEO, isEditable: false, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: true },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: true },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: true },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    GuestMastersEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    ConfigureMastersEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],

    CustomerFrameAgreementWorkFlowEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector,isEditable: true, isVisible: true },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    BusinessPlanEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: true, isVisible: true },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    StockInventoryEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: false, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: true },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: true },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: true },
        { role: UserRoles.L1, isEditable: false, isVisible: true },
        { role: UserRoles.L2, isEditable: false, isVisible: true },
        { role: UserRoles.L3, isEditable: false, isVisible: true },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: true },
        { role: UserRoles.Marketing, isEditable: false, isVisible: true },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: true },
        { role: UserRoles.CEO, isEditable: false, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: true },
        { role: UserRoles.SupplyChain, isEditable: true, isVisible: true },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: true },
        { role: UserRoles.Distributor, isEditable: false, isVisible: true },
        { role: UserRoles.Agent, isEditable: false, isVisible: true },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: true },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    WorkFlowScreenAccessEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    AlertsAccessEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],

    PriceConfiguratorEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: false, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: true, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: false, isVisible: true }
    ],
    
     LeadMasterEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: true, isVisible: true },
        { role: UserRoles.RegionalSalesManager, isEditable: true, isVisible: true },
        { role: UserRoles.AreaSalesManager, isEditable: true, isVisible: true },
        { role: UserRoles.L1, isEditable: true, isVisible: true },
        { role: UserRoles.L2, isEditable: true, isVisible: true },
        { role: UserRoles.L3, isEditable: true, isVisible: true },
        { role: UserRoles.BusinessDevelopment, isEditable: true, isVisible: true },
        { role: UserRoles.Marketing, isEditable: true, isVisible: true },
        { role: UserRoles.SalesDirector, isEditable: true, isVisible: true },
        { role: UserRoles.CEO, isEditable: true, isVisible: true },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: true, isVisible: true },
        { role: UserRoles.FinanceAccounts, isEditable: true, isVisible: true },
        { role: UserRoles.SupplyChain, isEditable: true, isVisible: true },
        { role: UserRoles.ChannelPartner, isEditable: true, isVisible: true },
        { role: UserRoles.Distributor, isEditable: true, isVisible: true },
        { role: UserRoles.Agent, isEditable: true, isVisible: true },
        { role: UserRoles.CustomerService, isEditable: true, isVisible: true },
        { role: UserRoles.ProductManager, isEditable: true, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],

    SiteActivityEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    
    QuoteGenearation_Enquiry_TechnicalDiscardArray: [
        { role: UserRoles.Admin, isEditable: true,isVisible: true },
        { role: UserRoles.SalesEngineer,isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: true, isVisible: true },
        { role: UserRoles.L2, isEditable: true, isVisible: true },
        { role: UserRoles.L3, isEditable: true, isVisible: true },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: false, isVisible: false },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: false, isVisible: false },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],

       ProductPriceEditableAccessArray: [
        { role: UserRoles.Admin, isEditable: true, isVisible: true },
        { role: UserRoles.SalesEngineer, isEditable: false, isVisible: false },
        { role: UserRoles.RegionalSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.AreaSalesManager, isEditable: false, isVisible: false },
        { role: UserRoles.L1, isEditable: false, isVisible: false },
        { role: UserRoles.L2, isEditable: false, isVisible: false },
        { role: UserRoles.L3, isEditable: false, isVisible: false },
        { role: UserRoles.BusinessDevelopment, isEditable: false, isVisible: false },
        { role: UserRoles.SalesDirector, isEditable: false, isVisible: false },
        { role: UserRoles.CEO, isEditable: false, isVisible: false },
        { role: UserRoles.ExecutiveBusinessAssistant, isEditable: false, isVisible: false },
        { role: UserRoles.FinanceAccounts, isEditable: true, isVisible: true },
        { role: UserRoles.SupplyChain, isEditable: false, isVisible: false },
        { role: UserRoles.ChannelPartner, isEditable: false, isVisible: false },
        { role: UserRoles.Distributor, isEditable: false, isVisible: false },
        { role: UserRoles.Marketing, isEditable: false, isVisible: false },
        { role: UserRoles.Agent, isEditable: false, isVisible: false },
        { role: UserRoles.CustomerService, isEditable: false, isVisible: false },
        { role: UserRoles.ProductManager, isEditable: true, isVisible: true },
        { role: UserRoles.ManagerSolutionsFactoryAndLocalization, isEditable: true, isVisible: true }
    ],
    //>

    ////#region Variable for Screens to Visible
    EnquiryForm_SubmitBySalesEngg: false,
    RFQ_SaveasDraftBySalesEngg: false,
    RFQ_SubmitBySalesEngg: false,
    QuoteGenearationAcceptByL1: false,
    QuoteGenearation_RemorkByL1: false,
    QuoteGenearation_EditRFQ: false,
    QuoteGenearation_GenerateQuote: false,
    QuoteGenearation_ForwardtoL2ByL1: false,
    QuoteGenearation_ForwardtoL3ByL2: false,
    QuoteGenearation_SaveasDraftByL1: false,
    QuoteGenearation_SaveasDraftByL2: false,
    QuoteGenearation_SaveasDraftByL3: false,
    QuoteGenearation_GenerateQuoteByL1: false,
    QuoteGenearation_GenerateQuoteByL2: false,
    QuoteGenearation_GenerateQuoteByL3: false,
    QuoteGenearation_SendtoL2ByL3: false,
    QuoteGenearation_SendtoL1ByL2: false,
    QuoteGenearation_SendtoCustomerServiceByL1: false,
    QuoteGenearation_SendforApprovalByCustomerService: false,
    ManagementApproval_RejectBySalesDirector: false,
    ManagementApproval_ApproveBySalesDirector: false,
    ManagementApproval_HoldBySalesDirector: false,
    ManagementApproval_SendQuotationforApprovalBySalesDirector: false,
    ManagementApproval_SendOrderforApprovalBySalesDirector: false,
    ManagementApproval_RejectByCEO: false,
    ManagementApproval_ApproveByCEO: false,
    ManagementApproval_HoldByCEO: false,
    QuoteGenearation_SendBOMProposalByL1: false,
    EnquiryConfirmation_QuoteRevision: false,
    EnquiryConfirmation_ConfimrOrderBySalesEngg: false,
    EnquiryConfirmation_SbmitBySalesEngg: false,
    ManagementApproval_SendOrderforApprovalByCustomerService: false,
    ManagementApproval_ReworkBySalesDirector: false,
    ManagementApproval_Order_ApproveBySalesDirector: false,
    ManagementApproval_Order_RejectBySalesDirector: false,
    ManagementApproval_SendforApprovalBySalesDirector: false,
    ManagementApproval_ReworkByCEO: false,
    ManagementApproval_Order_ApproveByCEO: false,
    ManagementApproval_Order_RejectByCEO: false,
    SO_SendtoSupplyChainByCustomerService: false,
    OrderAcknowledmentNo: false,
    MaterialReadyForDispatch: false,
    SupplyChain_SubmitBySupplyChain: false,
    Accounts_ConfimrOrderByAccounts: false,
    QuoteGenearation_Enquiry_TechnicalDiscard: false,
    QuoteGenearation_Enquiry_TechnicalDiscardbyL1: false,
    QuoteGenearation_Enquiry_TechnicalDiscardbyL2: false,
    QuoteGenearation_Enquiry_TechnicalDiscardbyL3: false,
    //#endregion
    ////#region Variables for Buttons to be Enable/Disable
    disable_EnquiryForm_SubmitBySalesEngg: false,
    disable_RFQ_SaveasDraftBySalesEngg: false,
    disable_RFQ_SubmitBySalesEngg: false,
    disable_QuoteGenearationAcceptByL1: false,
    disable_QuoteGenearation_RemorkByL1: false,
    disable_QuoteGenearation_EditRFQ: false,
    disable_QuoteGenearation_GenerateQuote: false,
    disable_QuoteGenearation_ForwardtoL2ByL1: false,
    disable_QuoteGenearation_ForwardtoL3ByL2: false,
    disable_QuoteGenearation_SaveasDraftByL1: false,
    disable_QuoteGenearation_SaveasDraftByL2: false,
    disable_QuoteGenearation_SaveasDraftByL3: false,
    disable_QuoteGenearation_GenerateQuoteByL1: false,
    disable_QuoteGenearation_GenerateQuoteByL2: false,
    disable_QuoteGenearation_GenerateQuoteByL3: false,
    disable_QuoteGenearation_SendtoL2ByL3: false,
    disable_QuoteGenearation_SendtoL1ByL2: false,
    disable_QuoteGenearation_SendtoCustomerServiceByL1: false,
    disable_QuoteGenearation_SendforApprovalByCustomerService: false,
    disable_ManagementApproval_RejectBySalesDirector: false,
    disable_ManagementApproval_ApproveBySalesDirector: false,
    disable_ManagementApproval_HoldBySalesDirector: false,
    disable_ManagementApproval_SendQuotationforApprovalBySalesDirector: false,
    disable_ManagementApproval_SendOrderforApprovalBySalesDirector: false,
    disable_ManagementApproval_RejectByCEO: false,
    disable_ManagementApproval_ApproveByCEO: false,
    disable_ManagementApproval_HoldByCEO: false,
    disable_QuoteGenearation_SendBOMProposalByL1: false,
    disable_EnquiryConfirmation_ConfimrOrderBySalesEngg: false,
    disable_EnquiryConfirmation_QuoteRevision: false,
    disable_EnquiryConfirmation_SbmitBySalesEngg: false,
    disable_ManagementApproval_SendOrderforApprovalByCustomerService: false,
    disable_ManagementApproval_ReworkBySalesDirector: false,
    disable_ManagementApproval_Order_ApproveBySalesDirector: false,
    disable_ManagementApproval_Order_RejectBySalesDirector: false,
    disable_ManagementApproval_SendforApprovalBySalesDirector: false,
    disable_ManagementApproval_ReworkByCEO: false,
    disable_ManagementApproval_Order_ApproveByCEO: false,
    disable_ManagementApproval_Order_RejectByCEO: false,
    disable_SO_SendtoSupplyChainByCustomerService: false,
    disable_OrderAcknowledmentNo: false,
    disable_SupplyChain_SubmitBySupplyChain: false,
    disable_Accounts_ConfimrOrderByAccounts: false,
    disable_QuoteGenearation_Enquiry_TechnicalDiscard: false,
    disable_QuoteGenearation_Enquiry_TechnicalDiscardbyL1: false,
    disable_QuoteGenearation_Enquiry_TechnicalDiscardbyL2: false,
    disable_QuoteGenearation_Enquiry_TechnicalDiscardbyL3: false,

    //#endregion

    isEnquiryFormEditable: false,
    isRFQEditable: false,
    isQuoteGenerationEditable: false,
    isManagementApprovalEditable: false,
    isCEOApprovalEditable: false,
    isOrderAcknowledgementEditable: false,
    isEnquiryConfirmationEditable: false,
    isSOEditable: false,
    isSupplychainEditable: false,
    isAccountsEditable: false,
    isOAGenerateEditable: false,


    appRoles: ['Admin', 'Supply Chain', 'Channel Partner', 'Distributor', 'Agent', 'Sales Engineer', 'Customer Service',
        'L1', 'L2', 'L3', 'Business Development', 'Sales Director', 'CEO', 'Finance/Accounts',
    ],

    screensVisibleAccordingtoEnquiryStatus: [
        //Admin
        {
            role: UserRoles.Admin, 
            screensForStatus: [

                /****************************************Add Edit Access to Admin for all screens************************************************* */
                //Sales Engg and Guest
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },

                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },

                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                
                //--------- changes ------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                ///L1

                {
                    status: EnquiryStatus.RFQSubmittedbySalesEngg,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQSubmittedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQAcceptedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ',],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                   
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL2', 'QuoteGenearation_RemorkByL2', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
               
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL3', 'QuoteGenearation_RemorkByL3', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationsentbyL2toL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoCustomerServiceByL1'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQsentbyL1toL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                // --------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form','RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
               
                //L2
                {
                    status: EnquiryStatus.RFQSubmittedbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationsentbyL3toL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL1ByL2'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQsentbyL2toL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: [, 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                
                // ----------- changes --------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                
                ///L3
                {
                    status: EnquiryStatus.RFQSubmittedbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ'],
                    selectedIndex: 2
                },
                
                // ------------------------ changes ------------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form','RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                
                ///CustomerService
                {
                    status: EnquiryStatus.RFQSubmittedbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                // UPdate for workflow new screens
                {
                    status: EnquiryStatus.QuotationSentForPriceDiscountApprovalToLevel1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountApprovedByL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountApprovedByL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountApprovedByL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSentForPriceDiscountApprovalToLevel3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSentForPriceDiscountApprovalToLevel2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountRejectedByL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountRejectedByL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },  
                {
                    status: EnquiryStatus.PriceDiscountRejectedByL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
// ============================================TILL HERE ========================
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                   
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL2', 'QuoteGenearation_RemorkByL2', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
               
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL3', 'QuoteGenearation_RemorkByL3', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSenttoCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendBOMProposalByL1'],
                    visibleButtons: ['QuoteGenearation_SendBOMProposalByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendBOMProposalByL1'],
                    visibleButtons: ['QuoteGenearation_SendBOMProposalByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: ['ManagementApproval_SendOrderforApprovalByCustomerService', 'SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 4
                },
                {
                    status: EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderApprovedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                /////Sales SalesDirector
                {
                    status: EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.QuotationOnHoldbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    buttons: ['ManagementApproval_Order_ApproveBySalesDirector', 'ManagementApproval_Order_RejectBySalesDirector', 'ManagementApproval_SendforApprovalBySalesDirector', 'ManagementApproval_SendOrderforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_Order_ApproveBySalesDirector', 'ManagementApproval_Order_RejectBySalesDirector', 'ManagementApproval_SendforApprovalBySalesDirector', 'ManagementApproval_SendOrderforApprovalBySalesDirector'],
                    selectedIndex: 5
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 3
                },

                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrdersentforMngmtApproval_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 5
                },
                ////CEO
                {
                    status: EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval', 'CEO Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    visibleButtons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    selectedIndex: 4
                },
                {
                    status: EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval', 'CEO Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO'],
                    visibleButtons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    selectedIndex: 4
                },
                // {
                //     status: EnquiryStatus.OrdersentforMngmtApproval_CEO,
                //     screens: ['Enquiry Form', 'RFQ', 'Quote Generation','Enquiry Confirmation','Sales Order','Management Approval' , 'CEO Approval'],
                //     disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation','Enquiry Confirmation','Sales Order','Management Approval'],
                //     buttons: ['ManagementApproval_Order_ApproveByCEO', 'ManagementApproval_Order_RejectByCEO'],
                //     visibleButtons: ['ManagementApproval_Order_ApproveByCEO', 'ManagementApproval_Order_RejectByCEO'],
                //     selectedIndex: 6
                // }



                ////FINANCE ACCOUNTS
                {
                    status: EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    buttons: ['OrderAcknowledmentNo'],
                    visibleButtons: ['OrderAcknowledmentNo'],
                    selectedIndex: 5
                },

                {
                    status: EnquiryStatus.MaterialReadyForDispatch,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain', 'Accounts'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain'],
                    buttons: ['Accounts_ConfimrOrderByAccounts'],
                    visibleButtons: ['Accounts_ConfimrOrderByAccounts'],
                    selectedIndex: 7
                },


                //SUPPLY CHAIN

                // {
                //     status: EnquiryStatus.OANoGenerated,
                //     screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation','Sales Order', 'OAGenerate','Supply Chain'],
                //     disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation','Sales Order', 'OAGenerate'],
                //     buttons: ['SupplyChain_SubmitBySupplyChain'],
                //     visibleButtons: ['SupplyChain_SubmitBySupplyChain'],
                //     selectedIndex: 6
                // },
                {
                    status: EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate'],
                    buttons: ['MaterialReadyForDispatch'],
                    visibleButtons: ['MaterialReadyForDispatch'],
                    selectedIndex: 6
                },

                /***************************************************************************************** */
            ]
        },
        //SALES ENGINEER
        {
            role: UserRoles.SalesEngineer,
            screensForStatus: [
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },

                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },

                {
                    status: EnquiryStatus.EnquiryLost,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: [],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },

                //---------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                //------- completed ----------------
            ]
        },

        //REGIONAL SALES MANAGER
        {
            role: UserRoles.RegionalSalesManager,
            screensForStatus: [
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryLost,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: [],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                //---------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                //------- completed ----------------

            ]
        },
        //AREA SALES MANAGER
        {
            role: UserRoles.AreaSalesManager,
            screensForStatus: [
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryLost,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: [],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },

                //---------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                //------- completed ----------------
            ]
        },
        // Executive Business Assistant
        {
            role: UserRoles.ExecutiveBusinessAssistant,
            screensForStatus: [
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },

                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },

                {
                    status: EnquiryStatus.EnquiryLost,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: [],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },

                //---------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                //------- completed ----------------
            ]
        },

        //CHANNEL PARTNER
        {
            role: UserRoles.ChannelPartner,
            screensForStatus: [
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryLost,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: [],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                //---------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                //------- completed ----------------
            ]
        },
        //DISTRIBUTOR
        {
            role: UserRoles.Distributor,
            screensForStatus: [
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryLost,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: [],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                //---------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                //------- completed ----------------
            ]
        },
        //AGENT
        {
            role: UserRoles.Agent,
            screensForStatus: [
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryLost,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: [],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                //---------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                //------- completed ----------------
            ]
        },
        //L1
        {
            role: UserRoles.L1,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                //----------------- changes --------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                /////////////////////////////////////////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.RFQSubmittedbySalesEngg,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQSubmittedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQAcceptedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ',],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                   
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL2', 'QuoteGenearation_RemorkByL2', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
               
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL3', 'QuoteGenearation_RemorkByL3', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
               
                {
                    status: EnquiryStatus.QuotationsentbyL2toL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoCustomerServiceByL1'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1'],
                    selectedIndex: 2
                },


            ]
        },
        //L2
        {
            role: UserRoles.L2,
            screensForStatus: [

                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                // --------------- changes ---------------------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                ///////////////////////////////////////////////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.RFQsentbyL1toL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQSubmittedbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationsentbyL3toL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL1ByL2'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2'],
                    selectedIndex: 2
                }
            ]
        },
        //L3
        {
            role: UserRoles.L3,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                // -------------------- changes ---------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------

                /////////////////////////////////////////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.RFQsentbyL2toL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: [, 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQSubmittedbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ'],
                    selectedIndex: 2
                }
            ]
        },
        //CUSTOMER SERVICE
        {
            role: UserRoles.CustomerService,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                /////////////////////////////////////////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.RFQSubmittedbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSenttoCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                   
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL2', 'QuoteGenearation_RemorkByL2', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
               
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL3', 'QuoteGenearation_RemorkByL3', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendBOMProposalByL1'],
                    visibleButtons: ['QuoteGenearation_SendBOMProposalByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendBOMProposalByL1'],
                    visibleButtons: ['QuoteGenearation_SendBOMProposalByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: ['ManagementApproval_SendOrderforApprovalByCustomerService', 'SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 4
                },
                {
                    status: EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderApprovedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.QuotationSentForPriceDiscountApprovalToLevel1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountApprovedByL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountApprovedByL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountApprovedByL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSentForPriceDiscountApprovalToLevel3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSentForPriceDiscountApprovalToLevel2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountRejectedByL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.PriceDiscountRejectedByL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },  
                {
                    status: EnquiryStatus.PriceDiscountRejectedByL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
            ]
        },
        //SALES DIRECTOR
        {
            role: UserRoles.SalesDirector,
            screensForStatus: [
              
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                
                {
                    status: EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.QuotationOnHoldbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    buttons: ['ManagementApproval_Order_ApproveBySalesDirector', 'ManagementApproval_Order_RejectBySalesDirector', 'ManagementApproval_SendforApprovalBySalesDirector', 'ManagementApproval_SendOrderforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_Order_ApproveBySalesDirector', 'ManagementApproval_Order_RejectBySalesDirector', 'ManagementApproval_SendforApprovalBySalesDirector', 'ManagementApproval_SendOrderforApprovalBySalesDirector'],
                    selectedIndex: 5
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 3
                },

                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrdersentforMngmtApproval_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 5
                }

            ]
        },
        //CEO
        {
            role: UserRoles.CEO,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                /////////////////////////////////////////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval', 'CEO Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    visibleButtons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    selectedIndex: 4
                },
                {
                    status: EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval', 'CEO Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO'],
                    visibleButtons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    selectedIndex: 4
                },
                {
                    status: EnquiryStatus.OrdersentforMngmtApproval_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval', 'CEO Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    buttons: ['ManagementApproval_Order_ApproveByCEO', 'ManagementApproval_Order_RejectByCEO'],
                    visibleButtons: ['ManagementApproval_Order_ApproveByCEO', 'ManagementApproval_Order_RejectByCEO'],
                    selectedIndex: 6
                }
            ]
        },
        //SUPPLY CHAIN
        {
            role: UserRoles.SupplyChain,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                /////////////////////////////////////////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.OANoGenerated,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate'],
                    buttons: ['SupplyChain_SubmitBySupplyChain'],
                    visibleButtons: ['SupplyChain_SubmitBySupplyChain'],
                    selectedIndex: 6
                },
                {
                    status: EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate'],
                    buttons: ['MaterialReadyForDispatch'],
                    visibleButtons: ['MaterialReadyForDispatch'],
                    selectedIndex: 6
                },

            ]
        },
        //FINANCE ACCOUNTS
        {
            role: UserRoles.FinanceAccounts,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                /////////////////////////////////////////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    buttons: ['OrderAcknowledmentNo'],
                    visibleButtons: ['OrderAcknowledmentNo'],
                    selectedIndex: 5
                },

                {
                    status: EnquiryStatus.MaterialReadyForDispatch,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain', 'Accounts'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain'],
                    buttons: ['Accounts_ConfimrOrderByAccounts'],
                    visibleButtons: ['Accounts_ConfimrOrderByAccounts'],
                    selectedIndex: 7
                },

            ]
        },

        //Business Development
        {
            role: UserRoles.BusinessDevelopment,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                /////////////////////////////////////////////////////////////////////////////////////////////

            ]
        },

        //Product Manager
        {
            role: UserRoles.ProductManager,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                /////////////////////////////////////////////////////////////////////////////////////////////

            ]
        },

        //Marketing
        {
            role: UserRoles.Marketing,
            screensForStatus: [
                //////////////////////////Changes//////////////////////////////////////////////////////////
                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 2
                },
                /////////////////////////////////////////////////////////////////////////////////////////////

            ]
        },

         //Manager Solutions Factory & Localization
         {
            role: UserRoles.ManagerSolutionsFactoryAndLocalization, 
            screensForStatus: [

                /****************************************Add Edit Access to Admin for all screens************************************************* */
                //Sales Engg and Guest
                {
                    status: EnquiryStatus.EnquiryAdded,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQSaveAsDraft,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },

                {
                    status: EnquiryStatus.QuotationRevision,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.RFQReworkbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.SendBOMandProposal,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },

                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg', 'EnquiryConfirmation_ConfimrOrderBySalesEngg', 'EnquiryConfirmation_QuoteRevision'],
                    selectedIndex: 3
                },
                
                //--------- changes ------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                ///L1

                {
                    status: EnquiryStatus.RFQSubmittedbySalesEngg,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQSubmittedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQAcceptedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ',],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                   
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL2', 'QuoteGenearation_RemorkByL2', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
               
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL3', 'QuoteGenearation_RemorkByL3', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationsentbyL2toL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoCustomerServiceByL1'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                        'QuoteGenearation_SendtoCustomerServiceByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQsentbyL1toL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                // --------- changes ----------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
               
                //L2
                {
                    status: EnquiryStatus.RFQSubmittedbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2', 'QuoteGenearation_EditRFQ'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationsentbyL3toL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL1ByL2'],
                    visibleButtons: ['QuoteGenearation_SendtoL1ByL2', 'QuoteGenearation_ForwardtoL3ByL2'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.RFQsentbyL2toL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: [, 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                
                // ----------- changes --------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                
                ///L3
                {
                    status: EnquiryStatus.RFQSubmittedbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote', 'QuoteGenearation_Enquiry_TechnicalDiscard'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendtoL2ByL3', 'QuoteGenearation_EditRFQ'],
                    selectedIndex: 2
                },
                
                // ------------------------ changes ------------
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                
                ///CustomerService
                {
                    status: EnquiryStatus.RFQSubmittedbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSaveAsDraftbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationPriceOnRequestbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationGeneratedbyCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL1,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL1', 'QuoteGenearation_RemorkByL1', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL1', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                   
                },
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL2,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL2', 'QuoteGenearation_RemorkByL2', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL2', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
               
                {
                    status: EnquiryStatus. QuotationSendtoCustomerServicebyL3,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form'],
                    buttons: [ 'QuoteGenearation_EditRFQ','QuoteGenearation_GenerateQuote'],
                    visibleButtons: ['QuoteGenearationAcceptByL3', 'QuoteGenearation_RemorkByL3', 'QuoteGenearation_ForwardtoL2ByL1',
                    'QuoteGenearation_SendtoCustomerServiceByL3', 'QuoteGenearation_EditRFQ', 'QuoteGenearation_GenerateQuote',
                       ],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationSenttoCustomerService,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1',
                        'ManagementApproval_SendOrderforApprovalByCustomerService', 'QuoteGenearation_SendBOMProposalByL1', 'QuoteGenearation_EditRFQ',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendBOMProposalByL1'],
                    visibleButtons: ['QuoteGenearation_SendBOMProposalByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    disableScreen: ['Enquiry Form', 'RFQ'],
                    buttons: ['QuoteGenearation_SendBOMProposalByL1'],
                    visibleButtons: ['QuoteGenearation_SendBOMProposalByL1'],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation'],
                    buttons: ['ManagementApproval_SendOrderforApprovalByCustomerService', 'SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 4
                },
                {
                    status: EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrderApprovedbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Sales Order'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['SO_SendtoSupplyChainByCustomerService'],
                    visibleButtons: ['QuoteGenearation_SendforApprovalByCustomerService',
                        'ManagementApproval_SendOrderforApprovalByCustomerService',
                        'SO_SendtoSupplyChainByCustomerService'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL1,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL2,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                {
                    status: EnquiryStatus.EnquiryTechnicalDiscardbyL3,
                    screens: ['Enquiry Form', 'RFQ'],
                    disableScreen: ['Enquiry Form'],
                    buttons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    visibleButtons: ['RFQ_SaveasDraftBySalesEngg', 'RFQ_SubmitBySalesEngg'],
                    selectedIndex: 1
                },
                // ----------------------------------
                /////Sales SalesDirector
                {
                    status: EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.QuotationOnHoldbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation'],
                    buttons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_RejectBySalesDirector', 'ManagementApproval_ApproveBySalesDirector', 'ManagementApproval_HoldBySalesDirector', 'ManagementApproval_SendQuotationforApprovalBySalesDirector'],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    buttons: ['ManagementApproval_Order_ApproveBySalesDirector', 'ManagementApproval_Order_RejectBySalesDirector', 'ManagementApproval_SendforApprovalBySalesDirector', 'ManagementApproval_SendOrderforApprovalBySalesDirector'],
                    visibleButtons: ['ManagementApproval_Order_ApproveBySalesDirector', 'ManagementApproval_Order_RejectBySalesDirector', 'ManagementApproval_SendforApprovalBySalesDirector', 'ManagementApproval_SendOrderforApprovalBySalesDirector'],
                    selectedIndex: 5
                },
                {
                    status: EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 2
                },
                {
                    status: EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 3
                },

                {
                    status: EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 3
                },
                {
                    status: EnquiryStatus.OrdersentforMngmtApproval_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'Management Approval'],
                    buttons: [],
                    visibleButtons: [],
                    selectedIndex: 5
                },
                ////CEO
                {
                    status: EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval', 'CEO Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    visibleButtons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    selectedIndex: 4
                },
                {
                    status: EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval', 'CEO Approval'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Management Approval'],
                    buttons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO'],
                    visibleButtons: ['ManagementApproval_RejectByCEO', 'ManagementApproval_ApproveByCEO', 'ManagementApproval_HoldByCEO'],
                    selectedIndex: 4
                },
                // {
                //     status: EnquiryStatus.OrdersentforMngmtApproval_CEO,
                //     screens: ['Enquiry Form', 'RFQ', 'Quote Generation','Enquiry Confirmation','Sales Order','Management Approval' , 'CEO Approval'],
                //     disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation','Enquiry Confirmation','Sales Order','Management Approval'],
                //     buttons: ['ManagementApproval_Order_ApproveByCEO', 'ManagementApproval_Order_RejectByCEO'],
                //     visibleButtons: ['ManagementApproval_Order_ApproveByCEO', 'ManagementApproval_Order_RejectByCEO'],
                //     selectedIndex: 6
                // }



                ////FINANCE ACCOUNTS
                {
                    status: EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order'],
                    buttons: ['OrderAcknowledmentNo'],
                    visibleButtons: ['OrderAcknowledmentNo'],
                    selectedIndex: 5
                },

                {
                    status: EnquiryStatus.MaterialReadyForDispatch,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain', 'Accounts'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain'],
                    buttons: ['Accounts_ConfimrOrderByAccounts'],
                    visibleButtons: ['Accounts_ConfimrOrderByAccounts'],
                    selectedIndex: 7
                },


                //SUPPLY CHAIN

                // {
                //     status: EnquiryStatus.OANoGenerated,
                //     screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation','Sales Order', 'OAGenerate','Supply Chain'],
                //     disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation','Sales Order', 'OAGenerate'],
                //     buttons: ['SupplyChain_SubmitBySupplyChain'],
                //     visibleButtons: ['SupplyChain_SubmitBySupplyChain'],
                //     selectedIndex: 6
                // },
                {
                    status: EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                    screens: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate', 'Supply Chain'],
                    disableScreen: ['Enquiry Form', 'RFQ', 'Quote Generation', 'Enquiry Confirmation', 'Sales Order', 'OAGenerate'],
                    buttons: ['MaterialReadyForDispatch'],
                    visibleButtons: ['MaterialReadyForDispatch'],
                    selectedIndex: 6
                },

                /***************************************************************************************** */
            ]
        },

    ],

    screensVisibleAfterPassed: [
        ///Admin
        {
            role: UserRoles.Admin,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //SalesEngineer
        {
            role: UserRoles.SalesEngineer,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },

        //AreaSalesManager
        {
            role: UserRoles.AreaSalesManager,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //RegionalSalesManager
        {
            role: UserRoles.RegionalSalesManager,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //Executive Business Assistant
        {
            role: UserRoles.ExecutiveBusinessAssistant,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //Agent
        {
            role: UserRoles.Agent,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },


                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //Distributor
        {
            role: UserRoles.Distributor,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },


                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //ChannelPartner
        {
            role: UserRoles.ChannelPartner,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },


                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //L1
        {
            role: UserRoles.L1,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [
                            EnquiryStatus.RFQsentbyL1toL2,
                            EnquiryStatus.RFQsentbyL2toL3,
                            EnquiryStatus.QuotationSaveAsDraftbyL2,
                            EnquiryStatus.QuotationSaveAsDraftbyL3,
                            EnquiryStatus.QuotationSaveAsDraftbyCustomerService,
                            EnquiryStatus.QuotationPriceOnRequestbyL2,
                            EnquiryStatus.QuotationPriceOnRequestbyL3,
                            EnquiryStatus.QuotationPriceOnRequestbyCustomerService,
                            EnquiryStatus.QuotationGeneratedbyL2,
                            EnquiryStatus.QuotationGeneratedbyL3,
                            EnquiryStatus.QuotationGeneratedbyCustomerService,
                            EnquiryStatus.QuotationsentbyL3toL2,
                            EnquiryStatus.QuotationSenttoCustomerService,
                            EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationOnHoldbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }
                ]
        },
        //L2
        {
            role: UserRoles.L2,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [
                            EnquiryStatus.RFQsentbyL2toL3,
                            EnquiryStatus.QuotationSaveAsDraftbyL1,
                            EnquiryStatus.QuotationSaveAsDraftbyL3,
                            EnquiryStatus.QuotationSaveAsDraftbyCustomerService,
                            EnquiryStatus.QuotationPriceOnRequestbyL1,
                            EnquiryStatus.QuotationPriceOnRequestbyL3,
                            EnquiryStatus.QuotationPriceOnRequestbyCustomerService,
                            EnquiryStatus.QuotationGeneratedbyL1,
                            EnquiryStatus.QuotationGeneratedbyL3,
                            EnquiryStatus.QuotationGeneratedbyCustomerService,
                            EnquiryStatus.QuotationsentbyL2toL1,
                            EnquiryStatus.QuotationSenttoCustomerService,
                            EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationOnHoldbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },


                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }
                ]
        },
        //L3
        {
            role: UserRoles.L3,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [
                            EnquiryStatus.QuotationsentbyL3toL2,
                            EnquiryStatus.QuotationsentbyL2toL1,
                            EnquiryStatus.QuotationSaveAsDraftbyCustomerService,
                            EnquiryStatus.QuotationPriceOnRequestbyCustomerService,
                            EnquiryStatus.QuotationGeneratedbyCustomerService,
                            EnquiryStatus.QuotationSenttoCustomerService,
                            EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationOnHoldbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },


                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }
                ]
        },
        //CustomerService
        {
            role: UserRoles.CustomerService,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [
                            EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                            EnquiryStatus.QuotationOnHoldbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.QuotationSentforMngtApproval_SalesDirector,
                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Sales Order',
                        status: [
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }
                ]
        },
        //SalesDirector
        {
            role: UserRoles.SalesDirector,
            screens:
                [
                    {
                        screenName: 'Management Approval',
                        status: [
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },


                    {
                        screenName: 'Sales Order',
                        status: [
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //CEO
        {
            role: UserRoles.CEO,
            screens:
                [
                    {
                        screenName: 'CEO Approval',
                        status: [
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.QuotationRejectedbyMngmt_CEO,
                            EnquiryStatus.QuotationRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,


                        ]
                    },
                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.QuotationOnHoldbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },


                    {
                        screenName: 'Sales Order',
                        status: [
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }
                ]
        },
        //SupplyChain
        {
            role: UserRoles.SupplyChain,
            screens:
                [

                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //FinanceAccounts
        {
            role: UserRoles.FinanceAccounts,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]

        },
        //BusinessDevelopment
        {
            role: UserRoles.BusinessDevelopment,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //Marketing
        {
            role: UserRoles.Marketing,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
        //ProductManager
        {
            role: UserRoles.ProductManager,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [

                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },

         //Manager Solutions Factory & Localization
         {
            role: UserRoles.ManagerSolutionsFactoryAndLocalization,
            screens:
                [
                    {
                        screenName: 'Quote Generation',
                        status: [

                            EnquiryStatus.SendBOMandProposal,
                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrderRejectedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderRejectedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Management Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.QuotationSentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'CEO Approval',
                        status: [

                            EnquiryStatus.QuotationApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Enquiry Confirmation',
                        status: [

                            EnquiryStatus.EnquiryOrderConfirmedbySalesEngg,
                            EnquiryStatus.EnquiryLost,
                            EnquiryStatus.OrdersentforMngmtApproval_SalesDirector,
                            EnquiryStatus.OrderApprovedbyMngmt_SalesDirector,
                            EnquiryStatus.OrdersentforMngmtApproval_CEO,
                            EnquiryStatus.OrderApprovedbyMngmt_CEO,
                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },

                    {
                        screenName: 'Sales Order',
                        status: [

                            EnquiryStatus.OrderConfirmedbyCustomerServiceSendSO,
                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'OAGenerate',
                        status: [

                            EnquiryStatus.OANoGenerated,
                            EnquiryStatus.SupplyChainConfirmedDeliveryDate,
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Supply Chain',
                        status: [
                            EnquiryStatus.MaterialReadyForDispatch,
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    },
                    {
                        screenName: 'Accounts',
                        status: [
                            EnquiryStatus.AccountsConfirmInvoiceNo,
                        ]
                    }

                ]
        },
    ],

    successMessages: [
        { id: 1, status: 'EnquiryAdded', confirmmsg: '', successmsg: 'Enquiry details added successfully!', errormsg: 'Failed to add the Enquiry details!' },
        { id: 2, status: 'RFQSaveAsDraft', confirmmsg: 'Do you want to save RFQ as Draft?', successmsg: 'RFQ details saved successfully!', errormsg: 'Failed to saved the RFQ Details!' },
        { id: 3, status: 'RFQSubmittedbySalesEngg', confirmmsg: 'Do you want to submit RFQ details?', successmsg: 'RFQ Details submitted successfully!', errormsg: 'Failed to submit the RFQ Details!' },
        { id: 4, status: 'RFQAcceptedbyL1', confirmmsg: 'Do you want to accept the RFQ?', successmsg: 'RFQ Accepted successfully!', errormsg: 'Failed to Accept the RFQ!' },
        { id: 5, status: 'RFQReworkbyL1', confirmmsg: 'Do you want to send the Enquiry for Rework?', successmsg: 'Enquiry sent for rework successfully!', errormsg: 'Failed to send the enquiry for rework!' },
        { id: 6, status: 'RFQSubmittedbyL1', confirmmsg: 'Do you want to submit RFQ details?', successmsg: 'RFQ Details submitted successfully!', errormsg: 'Failed to submit the RFQ Details!' },
        { id: 7, status: 'RFQsentbyL1toL2', confirmmsg: 'Do you want to send the Enquiry to L2?', successmsg: 'Enquiry sent to L2 successfully!', errormsg: 'Failed to send the Enquiry to L2!' },
        { id: 8, status: 'RFQSubmittedbyL2', confirmmsg: 'Do you want to submit RFQ details?', successmsg: 'RFQ Details submitted successfully!', errormsg: 'Failed to submit the RFQ Details!' },
        { id: 9, status: 'RFQsentbyL2toL3 ', confirmmsg: 'Do you want to send the Enquiry to L3?', successmsg: 'Enquiry sent to L3 successfully!', errormsg: 'Failed to send the Enquiry to L3!' },
        { id: 10, status: 'RFQSubmittedbyL3', confirmmsg: 'Do you want to submit RFQ details?', successmsg: 'RFQ Details submitted successfully!', errormsg: 'Failed to submit the RFQ Details!' },
        { id: 11, status: 'QuotationSaveAsDraftbyL1', confirmmsg: 'Do you want to save Quotation as draft?', successmsg: 'Quotation saved successfully!', errormsg: 'Failed to save the quotation!' },
        { id: 12, status: 'QuotationSaveAsDraftbyL2', confirmmsg: 'Do you want to save Quotation as draft?', successmsg: 'Quotation saved successfully!', errormsg: 'Failed to save the quotation!' },
        { id: 13, status: 'QuotationSaveAsDraftbyL3', confirmmsg: 'Do you want to save Quotation as draft?', successmsg: 'Quotation saved successfully!', errormsg: 'Failed to save the quotation!' },
        { id: 14, status: 'QuotationPriceOnRequestbyL1 ', confirmmsg: 'Do you want to proceed for Price on Request?', successmsg: 'Prices are requested successfully!', errormsg: 'Failed to request the Prices!' },
        { id: 15, status: 'QuotationPriceOnRequestbyL2', confirmmsg: 'Do you want to proceed for Price on Request?', successmsg: 'Prices are requested successfully!', errormsg: 'Failed to request the Prices!' },
        { id: 16, status: 'QuotationPriceOnRequestbyL3', confirmmsg: 'Do you want to proceed for Price on Request?', successmsg: 'Prices are requested successfully!', errormsg: 'Failed to request the Prices!' },
        { id: 17, status: 'QuotationGeneratedbyL1', confirmmsg: 'Do you want to Generate the Quotation?', successmsg: 'Quotation Generated successfully!', errormsg: 'Failed to Generate the Quotation!' },
        { id: 18, status: 'QuotationGeneratedbyL2', confirmmsg: 'Do you want to Generate the Quotation?', successmsg: 'Quotation Generated successfully!', errormsg: 'Failed to Generate the Quotation!' },
        { id: 19, status: 'QuotationGeneratedbyL3', confirmmsg: 'Do you want to Generate the Quotation?', successmsg: 'Quotation Generated successfully!', errormsg: 'Failed to Generate the Quotation!' },
        { id: 20, status: 'QuotationsentbyL3toL2', confirmmsg: 'Do you want to send the Quotation to L2?', successmsg: 'Quotation sent to L2 successfully!', errormsg: 'Failed to send the Quotation to L2!' },
        { id: 21, status: 'QuotationsentbyL2toL1', confirmmsg: 'Do you want to send the Quotation to L1?', successmsg: 'Quotation sent to L1 successfully!', errormsg: 'Failed to send the Quotation to L1!' },
        { id: 22, status: 'QuotationSenttoCustomerService', confirmmsg: 'Do you want to send the Quotation to Customer Service?', successmsg: 'Quotation sent to Customer Service successfully!', errormsg: 'Failed to send the Quotation to Customer Service!' },
        { id: 23, status: 'RFQSubmittedbyCustomerService', confirmmsg: 'Do you want to submit RFQ details?', successmsg: 'RFQ Details submitted successfully!', errormsg: 'Failed to submit the RFQ Details!' },
        { id: 24, status: 'QuotationSaveAsDraftbyCustomerService', confirmmsg: 'Do you want to save Quotation as draft?', successmsg: 'Quotation saved successfully!', errormsg: 'Failed to save the quotation!' },
        { id: 25, status: 'QuotationPriceOnRequestbyCustomerService', confirmmsg: 'Do you want to proceed for Price on Request?', successmsg: 'Prices are requested successfully!', errormsg: 'Failed to request the Prices!' },
        { id: 26, status: 'QuotationGeneratedbyCustomerService', confirmmsg: 'Do you want to Generate the Quotation?', successmsg: 'Quotation Generated successfully!', errormsg: 'Failed to Generate the Quotation!' },
        { id: 27, status: 'QuotationSentforMngtApproval_SalesDirector', confirmmsg: 'Do you want to send the Quotation for management approval?', successmsg: 'Quotation sent for Approval successfully!', errormsg: 'Failed to send the Quotation for Approval!' },
        { id: 28, status: 'QuotationRejectedbyMngmt_SalesDirector', confirmmsg: 'Do you want to Reject this Quotation?', successmsg: 'Quotation rejected successfully!', errormsg: 'Failed to reject the Quotation!' },
        { id: 29, status: 'QuotationApprovedbyMngmt_SalesDirector', confirmmsg: 'Do you want to Approve this Quotation?', successmsg: 'Quotation Approved successfully!', errormsg: 'Failed to Approve the Quotation!' },
        { id: 30, status: 'QuotationOnHoldbyMngmt_SalesDirector', confirmmsg: 'Do you want to Hold this Quotation?', successmsg: 'Quotation put on Hold successfully!', errormsg: 'Failed to put the Quotation on Hold!' },
        { id: 31, status: 'QuotationSentforMngmtApproval_CEO ', confirmmsg: 'Do you want to send the Quotation for CEO approval?', successmsg: 'Quotation sent for Approval successfully!', errormsg: 'Failed to send the Quotation for Approval!' },
        { id: 32, status: 'QuotationRejectedbyMngmt_CEO', confirmmsg: 'Do you want to Reject this Quotation?', successmsg: 'Quotation rejected successfully!', errormsg: 'Failed to reject the Quotation!' },
        { id: 33, status: 'QuotationApprovedbyMngmt_CEO', confirmmsg: 'Do you want to Approve this Quotation?', successmsg: 'Quotation Approved successfully!', errormsg: 'Failed to Approve the Quotation!' },
        { id: 34, status: 'QuotationOnHoldbyMngmt_CEO', confirmmsg: 'Do you want to Hold this Quotation?', successmsg: 'Quotation put on Hold successfully!', errormsg: 'Failed to put the Quotation on Hold!' },
        { id: 35, status: 'SendBOMandProposal', confirmmsg: 'Do you want to send the BOM & Proposal?', successmsg: 'BOM & Proposal sent successfully!', errormsg: 'Failed to send the BOM & Proposal!' },
        { id: 36, status: 'QuotationRevision', confirmmsg: 'Do you want to proceed for quotation revision?', successmsg: 'Details saved successfully!', errormsg: 'Failed to saved teh details!' },
        { id: 37, status: 'EnquiryLost', confirmmsg: 'Do you want to Confirm the Enquiry as Lost?', successmsg: 'Enquiry Confirmed as Lost successfully!', errormsg: 'Failed to confirm the Enquiry!' },
        { id: 38, status: 'EnquiryOrderConfirmedbySalesEngg', confirmmsg: 'Do you want to Confirm the Enquiry?', successmsg: 'Enquiry Confirmed as Win successfully!', errormsg: 'Failed to confirm the Enquiry!' },
        { id: 39, status: 'OrdersentforMngmtApproval_SalesDirector', confirmmsg: 'Do you want to send the Order for management approval?', successmsg: 'Order sent for Approval successfully!', errormsg: 'Failed to send the order for Approval!' },
        { id: 40, status: 'OrderApprovedbyMngmt_SalesDirector', confirmmsg: 'Do you want to Approve this Order?', successmsg: 'Order Approved successfully!', errormsg: 'Failed to Approve the Order!' },
        { id: 41, status: 'OrderRejectedbyMngmt_SalesDirector ', confirmmsg: 'Do you want to Reject this Order?', successmsg: 'Order Rejected successfully!', errormsg: 'Failed to Reject the Order!' },
        { id: 42, status: 'OrdersentforMngmtApproval_CEO', confirmmsg: 'Do you want to send the Order for CEO approval?', successmsg: 'Order sent for Approval successfully!', errormsg: 'Failed to send the order for Approval!' },
        { id: 43, status: 'OrderApprovedbyMngmt_CEO', confirmmsg: 'Do you want to Approve this Order?', successmsg: 'Order Approved successfully!', errormsg: 'Failed to Approve the Order!' },
        { id: 44, status: 'OrderRejectedbyMngmt_CEO', confirmmsg: 'Do you want to Reject this Order?', successmsg: 'Order Rejected successfully!', errormsg: 'Failed to Reject the Order!' },
        { id: 45, status: 'OrderConfirmedbyCustomerServiceSendSO', confirmmsg: 'Do you want to send for Order Acknowledgement?', successmsg: 'Order Confirmed successfully!', errormsg: 'Failed to Confirm the Order' },
        { id: 46, status: 'OANoGenerated', confirmmsg: 'Once OA Number Generated, can not be altered. Do you want to continue?', successmsg: 'Order Acknowledgement No. successfully!', errormsg: 'Failed to generate the Order Acknowledgement No.!' },
        { id: 47, status: 'SupplyChainConfirmedDeliveryDate', confirmmsg: 'Do you want to Confirm the Delivery date?', successmsg: 'Delivery Date confirmed successfully!', errormsg: 'Failed to confirm Delivery Date!' },
        { id: 48, status: 'MaterialReadyForDispatch', confirmmsg: 'Do you want to proceed?', successmsg: 'Details saved Successfully!', errormsg: 'Failed to save the details!' },
        { id: 49, status: 'AccountsConfirmInvoiceNo', confirmmsg: 'Do you want to confirm the Invoice?', successmsg: 'Invoice confirmed Successfully!', errormsg: 'Failed to Cofirm the Invoice!' },
        { id: 51, status: 'EnquiryTechnicalDiscardbyL1', confirmmsg: 'Do you want to confirm the enquiry as technical discarded?', successmsg: 'Enquiry confirmed as technical discarded Successfully!', errormsg: 'Failed to confirm the enquiry as technical discarded!' },
        { id: 52, status: 'EnquiryTechnicalDiscardbyL2', confirmmsg: 'Do you want to confirm the enquiry as technical discarded?', successmsg: 'Enquiry confirmed as technical discarded Successfully!', errormsg: 'Failed to confirm the enquiry as technical discarded!' },
        { id: 53, status: 'EnquiryTechnicalDiscardbyL3', confirmmsg: 'Do you want to confirm the enquiry as technical discarded?', successmsg: 'Enquiry confirmed as technical discarded Successfully!', errormsg: 'Failed to confirm the enquiry as technical discarded!' },
        { id: 50, status: 'CloseEnquiry', confirmmsg: 'You want to close the Enquiry?', successmsg: 'Enquiry closed Successfully!', errormsg: 'Failed to close the Enquiry!'},
	 {id: 54, status: 'QuotationsendtoCustomerServicebyL1', confirmmsg: 'Do you want to send this Quotation for review to Customer Service?', successmsg: 'Quotation is send to Customer Service Successfully!', errormsg: ' Failed to send the Quotation to Customer Service' },
        {id: 55, status: 'QuotationsendtoCustomerServicebyL2', confirmmsg: 'Do you want to send this Quotation for review to Customer Service?', successmsg: 'Quotation is send to Customer Service Successfully!', errormsg: ' Failed to send the Quotation to Customer Service' },
        {id: 56, status: 'QuotationsendtoCustomerServicebyL3', confirmmsg: 'Do you want to send this Quotation for review to Customer Service?', successmsg: 'Quotation is send to Customer Service Successfully!', errormsg: ' Failed to send the Quotation to Customer Service' },
        { id: 57, status: 'DeleteEnquiry', confirmmsg: 'You want to delete the enquiry?', successmsg: 'Enquiry deleted Successfully!', errormsg: 'Failed to delete the enquiry!'},
         { id: 58, status: 'CloseLead', confirmmsg: 'You want to close the Lead?', successmsg: 'Lead closed Successfully!', errormsg: 'Failed to close the Lead!'}

    
    ],

    excelCoulumns: ['Region', 'Product Line', 'series', 'model', 'station', 'units', 'amount'],

    dummyReportData: [
        { Region: 'North', ProductLine: 'Indexres', ProductSeries: 'TC', ProductModel: 'TC120G', ProductStation: '2', Price: 129, Amount: 1541 },
        { Region: 'North', ProductLine: 'Indexres', ProductSeries: 'TC', ProductModel: 'TC120G', ProductStation: '4', Price: 129, Amount: 1541 },
        { Region: 'North', ProductLine: 'Indexres', ProductSeries: 'TC', ProductModel: 'TC150G', ProductStation: '2', Price: 129, Amount: 1541 },
        { Region: 'North', ProductLine: 'Indexres', ProductSeries: 'TC', ProductModel: 'TC150G', ProductStation: '5', Price: 129, Amount: 1541 },

    ]


}

export enum EnquiryStatus_old {
    EnquiryAdded = 1,
    RFQSaveAsDraft = 2,
    RFQSubmittedbySalesEngg = 3,
    RFQAcceptedbyL1 = 4,
    RFQReworkbyL1 = 5,
    RFQsentbyL1toL2 = 6,
    RFQsentbyL2toL3 = 7,
    QuotationSaveAsDraftbyL1 = 8,
    QuotationSaveAsDraftbyL2 = 9,
    QuotationSaveAsDraftbyL3 = 10,
    QuotationGeneratedbyL1 = 11,
    QuotationsendtoCustomerServicebyL1=54,
    QuotationsendtoCustomerServicebyL2=55,
    QuotationsendtoCustomerServicebyL3=56,

    QuotationGeneratedbyL2 = 12,
    QuotationGeneratedbyL3 = 13,
    QuotationsentbyL3toL2 = 14,
    QuotationsentbyL2toL1 = 15,
    QuotationSenttoCustomerService = 16,
    QuotationSentforMngtApproval_SalesDirector = 17,
    QuotationRejectedbyMngmt_SalesDirector = 18,
    QuotationApprovedbyMngmt_SalesDirector = 19,
    QuotationOnHoldbyMngmt_SalesDirector = 20,
    QuotationSentforMngmtApproval_CEO = 21,
    QuotationRejectedbyMngmt_CEO = 22,
    QuotationApprovedbyMngmt_CEO = 23,
    QuotationOnHoldbyMngmt_CEO = 24,
    SendBOMandProposal = 25,
    EnquiryOrderConfirmedbySalesEngg = 26,
    EnquiryLost = 27,
    OrdersentforMngmtApproval_SalesDirector = 28,
    OrderReworkMngmt_SalesDirector = 29,
    OrderApprovedbyMngmt_SalesDirector = 30,
    OrderRejectedbyMngmt_SalesDirector = 31,
    OrdersentforMngmtApproval_CEO = 32,
    OrderReworkMngmt_CEO = 33,
    OrderApprovedbyMngmt_CEO = 34,
    OrderRejectedbyMngmt_CEO = 35,
    OrderConfirmedbyL1 = 36,
    SupplyChainConfirmedDeliveryDate = 37,
    AccountsConfirmInvoiceNo = 38
}
