import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, NgZone, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SingleFieldComponent, SingleFieldModel } from 'src/app/Model/SingleFieldModel';
import { AccessPermissionModel } from 'src/app/Model/WorkFlowScreenAccessModel';
import { AccessPermissionService } from 'src/app/Service/access-permission.service';
import { CommonService } from 'src/app/Service/common.service';
import { UserRoles } from 'src/app/UserRoles';

@Component({
  selector: 'app-details-login-page-dynamic-image',
  templateUrl: './details-login-page-dynamic-image.component.html',
  styleUrls: ['./details-login-page-dynamic-image.component.scss']
})
export class DetailsLoginPageDynamicImageComponent implements OnInit {
  displayedColumns: string[] = ['ImageName', 'Action'];
  dataSource: MatTableDataSource<SingleFieldModel>;
  singleFieldComponent: SingleFieldComponent = new SingleFieldComponent();
  singleFieldObj: SingleFieldModel = new SingleFieldModel();
  Role: any;
  UserRoles: any;
  disabled: boolean = false;
  isImageEditable: boolean = false;
  accesspermissionobj:AccessPermissionModel[]=Array<AccessPermissionModel>();
  AccesspermissionForRole:any[]=[];


  /*************************************************************************
        Server side Pagination purpose
    *************************************************************************/
        pageNumber: number = 1;
        @Input() pageSize: number = 5;
        @Input() length: number;
        @Input() pageIndex: number = this.pageNumber - 1;
        direction: string = 'desc';
        ColumnName: string = 'ModifiedOn';
        SearchText: string = "";
        @Output('matSortChange') sortChange: EventEmitter<Sort>;
        @ViewChild(MatPaginator) paginator: MatPaginator;
        
        /*************************************************************************
           End here
        *************************************************************************/
  
  @Input()
  showLoader: boolean = false;
  data: any[] = [];

  ngOnInit(): void {
    this.singleFieldObj.Id = this.route.snapshot.params["id"];
    this.Role = localStorage.getItem("UserRole");
    this.getAccess();

    //this.isImageEditable = AppGlobals.CustomerMastersEditableAccessArray.filter(x => x.role == this.Role)[0]?.isEditable;
    //this.isDownloadCustomerData = AppGlobals.CustomerMastersDataDownloadAccessArray.filter(x => x.role == this.Role)[0]?.isEditable;
    this.GetAllImageDetails();
  }

  constructor(private toastr: ToastrService, private Service: CommonService, public dialog: MatDialog,
    private http: HttpClient, private NgZone: NgZone, private router: Router, private route: ActivatedRoute, private titleService: Title,private accessservice: AccessPermissionService
    ) {
    this.UserRoles = UserRoles;
  }

  /*************************************************************************
   * Below function used for Server side Pagination,Sorting and Searching
   * (1)GetAllCustomerDetails() - To get data for grid
   * (2)SearchParam() -  searching
   * (3)sortData() - Sorting
   * (4)pageEvent() - Pagination
   * 
   * ***********************************************************************/

    // showimg(){
 
    //   this.Service.GetData('LoginPageImage', 'GetActiveImage',null).subscribe((data: any) => {
    //     console.log('Data from api',data)
    //     this.data = data.Data;
    //     this.length = data.Total;
  
    //     this.dataSource = new MatTableDataSource(this.data);
    //     this.showLoader = false;
    //   }, error => {
    //     this.toastr.error("Failed to load data!");
    //     this.showLoader = false;
    //   });
  

    // }


   GetAllImageDetails() {
    this.showLoader = true;
    let queryString = "";
    queryString = "pageNumber=" + this.pageNumber;
    queryString += "&pageSize=" + this.pageSize;
    queryString += "&searchText=" + this.SearchText;
    queryString += "&direction=" + this.direction;
    queryString += "&columnName=" + this.ColumnName;

    this.Service.GetData('LoginPageImage', 'GetAllPagination', queryString).subscribe((data: any) => {
      this.data = data.Data;
      this.length = data.Total;

      this.dataSource = new MatTableDataSource(this.data);
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });


  }

  SearchParam(event: any) {
    this.SearchText = event.target.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    this.GetAllImageDetails();
  }

  sortData(event: any) {
    if (this.dataSource.filteredData != null) {
      this.direction = event.direction;
      this.ColumnName = event.active;
      this.GetAllImageDetails();
    }
  }

  pageEvent(event: any) {
    
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
     
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.GetAllImageDetails();
  }
  /************************************************************************
   * Grid Code End 
   * **********************************************************************/

  /*******************************************************************************
 * Form validation method
 * ******************************************************************************/

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

   
   UpdateImageById(Id: number, CurrentStatus: boolean) {

    if (CurrentStatus == true) {
      //Create ConfirmDialogRef 
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to deactivate this as login image?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "Id=" + Id;

          this.Service.PostData('LoginPageImage', 'UpdateStatus', null,queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("LoginPageImage deactivated successfully!");
            }
            else {
              this.toastr.info("Failed to deactivate LoginPageImage!");
            }
            this.GetAllImageDetails();
          }, error => {
            this.toastr.error("Failed to deactivate LoginPageImage!");
            this.GetAllImageDetails();
            this.showLoader = false;
          });
        }
        else {
          this.GetAllImageDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });

    }
    else {
      //Create ConfirmDialogRef
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "Do you want to activate this as login image?"
        }
      });
      // listen to response from ConfirmDialogRef
      dialogRef.afterClosed().subscribe(dialogResult => {
        // if user pressed yes dialogResult will be true, 
        // if he pressed no - it will be false
        if (dialogResult) {
          this.pageNumber = 1;
          this.paginator.pageIndex = 0;
          this.length = 0;
          this.direction = 'desc';
          this.ColumnName = 'ModifiedOn';
          this.showLoader = true;

          let queryString = "";
          queryString = "id=" + Id;

          this.Service.PostData('LoginPageImage', 'UpdateStatus', null, queryString).subscribe((data: any) => {
            this.showLoader = false;
            if (data.ResponseCode == 1) {
              this.toastr.success("LoginPageImage activated successfully!");
            }
            else {
              this.toastr.info("Failed to activate LoginPageImage!");
            }
            this.GetAllImageDetails();
          }, error => {
            this.toastr.error("Failed to activate LoginPageImage!");
            this.GetAllImageDetails();
            this.showLoader = false;
          });

        }
        else {
          this.GetAllImageDetails();
          this.showLoader = false;
          let element: HTMLElement = document.getElementsByClassName('slideToogle')[0] as HTMLElement;
          element.click();
        }
      });
    }
 
  }

  defaultchek = false; 

  getAccess()
  {
    this.accessservice.GetAll().subscribe((data:any)=> {
      this.accesspermissionobj=data;
      this.isImageEditable=this.accesspermissionobj.filter(x => x.Fk_RoleId == this.Role && x.ElementKey=='isLoginPageImage')[0]?.IsEditable;
      
    });
 
  }

}
