import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { CommonService } from "./common.service";
@Injectable({
  providedIn: 'root'
})
export class EngagementReportServiceService {
  constructor(private commonService: CommonService) {}

  dataSet = [
    {
      Id: 1,
      currentWorkflowId:4,
      previousWorkflowId:3,      
      Status:1 
    },
    {
      Id: 2,
      currentWorkflowId:7,
      previousWorkflowId:4,      
      Status:3
    },
    {
      Id: 3,
      currentWorkflowId:9,
      previousWorkflowId:7,      
      Status:35
    },
    {
      Id: 4,
      currentWorkflowId:10,
      previousWorkflowId:9,      
      Status:38
    },
  ]
  //   {
  //     type: "Call",
  //     "Not Started": 10,
  //     "In progress": 16,
  //     Completed: 24,
  //   },
  //   {
  //     type: "Visit",
  //     "Not Started": 12,
  //     "In progress": 9,
  //     Completed: 15,
  //   },
  // ];

  // dataSet2 = [
  //   {
  //     month: "January",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "February",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "March",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "April",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "May",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "June",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "July",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "August",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "September",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "October",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "November",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  //   {
  //     month: "December",
  //     meeting: 20,
  //     call: 60,
  //     visit: 15,
  //   },
  // ];

  // getReportByTypeStatus() {
  //   return of(this.dataSet1);
  // }
  // getReportByTypeMonth() {
  //   return of(this.dataSet2);
  // }

  getSendbackRules() {
   // return this.commonService.PostData("Activity", "GetChartData", data, null)
    return this.dataSet;
  }
}
