import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Activity, ActivityLinkageEntityType, ActivityOutcome, ActivityPurpose, ActivityStatus, ActivityStatusByType, ActivityType, EntityRecord, User } from 'src/app/Model/ActivityModel';
import { ActivityService } from 'src/app/Service/activity.service';
import { ListActivityRowComponent } from '../list-activity-row/list-activity-row.component';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-bulk-edit-activity',
  templateUrl: './bulk-edit-activity.component.html',
  styleUrls: ['./bulk-edit-activity.component.scss']
})
export class BulkEditActivityComponent implements OnInit {


  isbulkEdit: boolean;
  pageNumber: number = 1;
  pageIndex: number = this.pageNumber - 1;
  pageSize: number = 5;
  length: number;
  activities: Activity[];
  filteredActivities: Activity[];
  activityStatusFilter: number;
  priorityFilter: string;
  activityTypeFilter: number;
  activityTypes: ActivityType[];
  activityStatuses: ActivityStatus[];
  activityPurposes: ActivityPurpose[];
  activityOutcomes: ActivityOutcome[];
  filterActivityForm: any = {};
  activityTypeMaster: ActivityType[] = [];
  activityStatusByTypeMaster: ActivityStatusByType[] = [];
  allEntityTypesMaster: ActivityLinkageEntityType[] = [];
  entityTypeFilterArray: any = [];
  allUserMaster: User[] = [];
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private activityService: ActivityService, private cd: ChangeDetectorRef) { }


  ngOnInit(): void {

    this.GetActivityTypeMaster();
    this.GetEntityTypesMaster();
    this.filterActivityForm.ActivityTypeId = null;
    this.filterActivityForm.ActivityStatusId = null;
    this.filterActivityForm.Subject = null;
    this.filterActivityForm.StartDate = null;
    this.filterActivityForm.EntityTypeId = null;
    this.filterActivityForm.EntityId = null;
    this.filterActivityForm.PageNumber = 1;
    this.filterActivityForm.PageSize = 2000;
    this.filterActivityForm.IsOwner = true;
    this.refreshGrid(this.filterActivityForm);
    this.getAllStatus();
    this.getallParticipant();
  }

  getallParticipant() {
    this.activityService.getallUserMaster().subscribe((data: User[]) => {
      this.allUserMaster = data;

    })
  }
  GetActivityTypeMaster() {
    this.activityService.getActivityTypeMaster().subscribe((data) => {
      this.activityTypeMaster = data;
    })
  }

  GetEntityTypesMaster() {
    this.activityService.getallEntityTypesMaster().subscribe((data: ActivityLinkageEntityType[]) => {
      this.allEntityTypesMaster = data;
    })
  }

  getAllStatus() {
    this.activityService.getActivityStatusMaster(null).subscribe((data: ActivityStatusByType[]) => {
      this.activityStatusByTypeMaster = data;
    });
  }

  addRow() {
    let newActivity = new Activity();
    newActivity.InEditMode = true;
    this.filteredActivities.unshift(newActivity);
    this.cd.detectChanges();
  }

  removeByIndex(index: any) {
    if ('ActivityId' in this.filteredActivities[index]) {
    } else {
      this.filteredActivities.splice(index, 1);
    }
  }

  refreshGrid(filterActivityForm) {
    this.activityService.getActivityByFilter(filterActivityForm).subscribe((data: any) => {
      this.activities = data.Data;
      this.filteredActivities = data.Data;
      this.length = this.filteredActivities.length
      this.cd.detectChanges();

    });
  }

  bulkEdit() {
    if (this.isbulkEdit) {
      var newArr = this.filteredActivities.map(obj => {
        return { ...obj, InEditMode: true };
      });
    } else {

      var newArr = this.filteredActivities.map(obj => {
        return { ...obj, InEditMode: false };
      });
    }
    this.filteredActivities = newArr;
  }

  pageEvent(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    let reqBody = { ActivityTypeId: null, ActivityStatusId: null, Subject: null, StartDate: null, EntityTypeId: null, EntityId: null, PageNumber: this.pageNumber, PageSize: this.pageSize, IsOwner: this.filterActivityForm.IsOwner }
    this.activityService.getActivityByFilter(reqBody).subscribe((res: any) => {
      this.filteredActivities = res
    })

  }

  onEntityTypeChange(EntityTypeId: any) {
    this.activityService.getrelatedEntityRecords(EntityTypeId,'').subscribe((data: EntityRecord[]) => {
      this.entityTypeFilterArray = data;
    });
  }


  removeFilter() {
    let reqBody = { ActivityTypeId: null, ActivityStatusId: null, Subject: null, StartDate: null, EntityTypeId: null, EntityId: null, PageNumber: 1, PageSize: 2000, IsOwner: this.filterActivityForm.IsOwner }
    this.EnquiryngbDateFromFiter = null;
    this.EnquiryngbDateToFiter = null;
    this.activityService.getActivityByFilter(reqBody).subscribe((res: any) => {
      this.filteredActivities = res

      this.entityTypeFilterArray = [];

      this.filterActivityForm = { ActivityTypeId: null, ActivityStatusId: null, Subject: null, StartDate: null, EntityTypeId: null, EntityId: null, PageNumber: 1, PageSize: 2000, IsOwner: true }
    });
  }

  onSubmit(filterActivityForm: any) {


    if (this.EnquiryngbDateFromFiter == undefined) {
      filterActivityForm.StartDate = null;
      filterActivityForm.EndDate = null;
    } else {
      filterActivityForm.StartDate = this.convertDate(new NgbDate(this.EnquiryngbDateFromFiter.year, this.EnquiryngbDateFromFiter.month, this.EnquiryngbDateFromFiter.day))
      filterActivityForm.EndDate = this.convertDate(new NgbDate(this.EnquiryngbDateToFiter.year, this.EnquiryngbDateToFiter.month, this.EnquiryngbDateToFiter.day))
    }


    filterActivityForm.PageNumber = 1;
    filterActivityForm.PageSize = 2000;
    this.activityService.getActivityByFilter(filterActivityForm).subscribe((res: any) => {
      this.filteredActivities = res
    });
  }



  convertDate(date: any) {
    let year = date.year;
    let month = date.month;
    let day = date.day;
    return year + "-" + month + "-" + day;
  }


  onKeyDown(event: KeyboardEvent) {
    // Check if the pressed key is a space and if the input field is empty
    if (event.key === ' ' && event.target['value'].trim() === '') {
      event.preventDefault(); // prevent the space from being entered
    }
  }



  EnquiryngbDateFromFiter: NgbDate | null;
  EnquiryngbDateToFiter: NgbDate | null;
  hoveredDate: NgbDate | null = null;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  // datePipe = new DatePipe('en-US');
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  onDateSelection(date: NgbDate) {
    if (!this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter) {
      this.EnquiryngbDateFromFiter = date;
    } else if (this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && date && date.after(this.EnquiryngbDateFromFiter)) {
      this.EnquiryngbDateToFiter = date;
    } else {
      this.EnquiryngbDateToFiter = null;
      this.EnquiryngbDateFromFiter = date;
    }
  }
  isRange(date: NgbDate) {
    return date.equals(this.EnquiryngbDateFromFiter) || (this.EnquiryngbDateToFiter && date.equals(this.EnquiryngbDateToFiter)) || this.isInside(date) || this.isHovered(date);
  }
  isHovered(date: NgbDate) {
    return this.EnquiryngbDateFromFiter && !this.EnquiryngbDateToFiter && this.hoveredDate && date.after(this.EnquiryngbDateFromFiter) && date.before(this.hoveredDate);
  }
  isInside(date: NgbDate) {
    return this.EnquiryngbDateToFiter && date.after(this.EnquiryngbDateFromFiter) && date.before(this.EnquiryngbDateToFiter);
  }

  OnFromDateFilterChange() {
    let EnquiryDateFromFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateFromFiter)
    let EnquiryDateToFiter = this.ConvertNgbDateToDate(this.EnquiryngbDateToFiter)
    if (EnquiryDateToFiter < EnquiryDateFromFiter) {
      this.EnquiryngbDateToFiter = this.EnquiryngbDateFromFiter;
    }
  }

  ConvertNgbDateToDate(ngbdate: any) {
    if (ngbdate != null) {
      return new Date(ngbdate.year, ngbdate.month - 1, ngbdate.day)
    }
  }


  OnToDateFilterChange() {
  }



}
