<loader [showLoader]="showLoader"></loader>
<div
    class="m-grid__item m-grid__item--fluid  m-grid m-grid--ver-desktop m-grid--desktop 	m-container m-container--responsive m-container--xxl m-page__container m-body">
    <div class="m-grid__item m-grid__item--fluid m-wrapper">


        <div class="m-content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="m-portlet">
                        <div class="m-portlet__head">
                            <div class="m-portlet__head-caption">
                                <div class="m-portlet__head-title">
                                    <h3 class="m-portlet__head-text">
                                        Add Business Plan for Sales and Order
                                    </h3>
                                </div>
                            </div>

                        </div>
                        <div class="markedFields">
                            <span style="margin-left:10px;">* Marked fields are
                                mandatory</span>
                        </div>
                        <form class="m-login__form m-form" name="myform" #myform="ngForm"
                            (ngSubmit)="myform.form.valid && onSubmit(myform)" novalidate>
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Employee Name:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="username"
                                            (change)="onselectChange()" [(ngModel)]="BusinessPlanobje.EmployeeId"
                                            #username="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && username.invalid || username?.touched && username.invalid}"
                                            required>
                                            <option value="" disabled>Select Name</option>
                                            <option (click)="setName($event)" *ngFor="let item of allEmployees"
                                                value="{{item.Fk_User_Id}}">{{item.Name}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && username.invalid || username?.touched && username.invalid">
                                            <div *ngIf="myform.hasError('required', 'username')">
                                                Employee name is required</div>
                                        </div>
                                    </div>


                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Employee Role:</label>
                                    <div class="col-lg-3">

                                        <input class="form-control m-input" type="text" name="valuechaininformation"
                                            id="valuechaininformation" [(ngModel)]="BusinessPlanobje.RoleName"
                                            #valuechaininformation="ngModel" placeholder="Employee Role" disabled>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Target Year:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="targetyear"
                                            (change)="onselectChange()" [(ngModel)]="BusinessPlanobje.TargetYear"
                                            #targetyear="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && targetyear.invalid || targetyear?.touched && targetyear.invalid}"
                                            required>
                                            <option value="" disabled>Select Year</option>
                                            <option *ngFor="let item of targetYears" value="{{item}}">{{item}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && targetyear.invalid || targetyear?.touched && targetyear.invalid">
                                            <div *ngIf="myform.hasError('required', 'targetyear')">
                                                Target year is required</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Target For:<span
                                            style="color:#f4516c;">&nbsp;*</span></label>
                                    <div class="col-lg-3">
                                        <select class="form-control " id="sel1" name="targetFor"
                                            (change)="onselectChange()" [(ngModel)]="BusinessPlanobje.TargetFor"
                                            #targetFor="ngModel"
                                            [ngClass]="{ 'is-invalid':myform.submitted  && targetFor.invalid || targetFor?.touched && targetFor.invalid}"
                                            required>
                                            <option value="" disabled>Select Name</option>
                                            <option *ngFor="let item of targets" value="{{item}}">{{item}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="myform.submitted && targetFor.invalid || targetFor?.touched && targetFor.invalid">
                                            <div *ngIf="myform.hasError('required', 'targetFor')">
                                                Target for is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row" style="padding-left: 17px;">
                                    <span style="font-weight: bold;font-size: 14px;">Instructions:</span><br>
                                </div>
                                <div class="form-group m-form__group row">
                                    <ol type="1">
                                        <li>Amount, FC1, FC2 and FC3 must be an integer.</li>
                                        <li>Amount, FC1, FC2 and FC3 must be greater than or equal to zero.</li>
                                        <li>Business Plan should be entered for at least one month.</li>
                                        <li>Amount, FC1, FC2 and FC3 must not exceed 10 digits.</li>
                                        <!-- <li>Units and amount must be an integer.</li>
                                        <li>Units and amount must be greater than or equal to zero.</li>
                                        <li>Business Plan should be entered for at least one month.</li>
                                        <li>Units must not exceed 5 digits.</li>
                                        <li>Amount must not exceed 7 digits.</li>
                                        <li>Atleast one month business plan should be filled.</li> -->
                                    </ol>
                                </div>
                                <div class="form-group m-form__group row table-responsive" style="padding-left: 10px;">

                                    <table class="table order-list table-striped- table-bordered table-hover table-edit"
                                        id="myTable">
                                        <thead>
                                            <tr>
                                                <th style="padding: 5px !important;"></th>
                                                <th *ngFor="let item of BusinessPlanobje.PlanList"
                                                    style="padding: 5px !important;">
                                                    {{item.MonthName}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- <tr>
                                                <td scope="row"> <label class="col-lg-2.5 col-form-label">Units<span
                                                            style="color:#f4516c;">&nbsp;*</span></label></td>
                                                <td *ngFor="let item of BusinessPlanobje.PlanList;let i = index;"
                                                    style="padding: 5px !important;">
                                                    <input type="text" pattern="[0-9]+" class="form-control m-input"
                                                        [(ngModel)]="item.Units" style="width: 84px;text-align:right;"
                                                        name="unit{{i}}" id="unit" maxlength="0" maxlength="5"
                                                        [ngClass]="{ 'is-invalid': item.UnitsValidation }"
                                                        (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                        (change)="onUnitsChange(i)" required>
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="item.UnitsValidation">
                                                            Units must be an Interger</div>
                                                    </div>

                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td scope="row"><label class="col-lg-2.5 col-form-label">Amount<span
                                                            style="color:#f4516c;">&nbsp;*</span></label></td>
                                                <td *ngFor="let item of BusinessPlanobje.PlanList; let i = index;"
                                                    style="padding: 5px !important;">
                                                    <input type="text" class="form-control m-input"
                                                        [ngClass]="{ 'is-invalid': item.AmountValidation }"
                                                        [(ngModel)]="item.Amount" style="width: 105px;text-align:right;"
                                                        name="amt{{i}}" pattern="[0-9]+" maxlength="0" maxlength="10"
                                                        [ngClass]="{ 'is-invalid': item.AmountValidation }"
                                                        (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                        (change)="onAmountChange(i)" required>
                                                    <div class="invalid-feedback">

                                                        <div *ngIf="item.AmountValidation">
                                                            Amount must be an Interger</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row"> <label class="col-lg-2.5 col-form-label">FC1</label></td>
                                                <td *ngFor="let item of BusinessPlanobje.PlanList;let i = index;"
                                                    style="padding: 5px !important;">
                                                    <input type="text" pattern="[0-9]+" class="form-control m-input"
                                                        [(ngModel)]="item.FC1Amount" style="width: 105px;text-align:right;"
                                                        name="fc1{{i}}" id="unit" maxlength="0" maxlength="10"
                                                        [ngClass]="{ 'is-invalid': item.FC1Validation }"
                                                        (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                        (change)="onFC1Change(i)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="item.FC1Validation">
                                                            FC1 must be an Interger</div>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row"> <label class="col-lg-2.5 col-form-label">FC2</label></td>
                                                <td *ngFor="let item of BusinessPlanobje.PlanList;let i = index;"
                                                    style="padding: 5px !important;">
                                                    <input type="text" pattern="[0-9]+" class="form-control m-input"
                                                        [(ngModel)]="item.FC2Amount" style="width: 105px;text-align:right;"
                                                        name="fc2{{i}}" id="unit" maxlength="0" maxlength="10"
                                                        [ngClass]="{ 'is-invalid': item.FC2Validation }"
                                                        (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                        (change)="onFC2Change(i)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="item.FC2Validation">
                                                            FC2 must be an Interger</div>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row"> <label class="col-lg-2.5 col-form-label">FC3</label></td>
                                                <td *ngFor="let item of BusinessPlanobje.PlanList;let i = index;"
                                                    style="padding: 5px !important;">
                                                    <input type="text" pattern="[0-9]+" class="form-control m-input"
                                                        [(ngModel)]="item.FC3Amount" style="width: 105px;text-align:right;"
                                                        name="fc3{{i}}" id="unit" maxlength="0" maxlength="10"
                                                        [ngClass]="{ 'is-invalid': item.FC3Validation }"
                                                        (keypress)="AvoidSpace($event);isNumberKey($event);"
                                                        (change)="onFC3Change(i)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="item.FC3Validation">
                                                            FC3 must be an Interger</div>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>

                                </div>
                            </div>
                            <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                                <div class="m-form__actions m-form__actions--solid">
                                    <div class="row">
                                        <div class="col-lg-2"></div>
                                        <div class="col-lg-10">
                                            <button id="m_login_signin_submit"
                                                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Save</button>&nbsp;&nbsp;
                                            <a [routerLink]="['/BusinessPlanDetails']" routerLinkActive="active"
                                                class="btn btn-metal m-btn m-btn--air m-btn--custom">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>