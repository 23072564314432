<!-- main app container -->
<div class="card m-3">
    <h5 class="card-header">Register</h5>
    <div class="card-body">
        <form name="form" (ngSubmit)="myform.form.valid && onSubmit()" #myform="ngForm" [mustMatch]="['password', 'confirmPassword']" novalidate>
            <div class="form-row">
                
                <div class="form-group col">
                    <label>First Name</label>
                    <input type="text" name="firstName" class="form-control" [(ngModel)]="register.FirstName" 
                    #firstName="ngModel" [ngClass]="{ 'is-invalid': myform.submitted && firstName.invalid }"
                    (change)="register.FirstName=register.FirstName.trim()" required>
                    <div *ngIf="myform.submitted && firstName.invalid" class="invalid-feedback">
                        <div *ngIf="firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label>Last Name</label>
                    <input type="text" name="lastName" class="form-control" [(ngModel)]="register.LastName"
                    #lastName="ngModel" [ngClass]="{ 'is-invalid': myform.submitted && lastName.invalid }" 
                    (change)="register.LastName=register.LastName.trim()" required>
                    <div *ngIf="myform.submitted && lastName.invalid" class="invalid-feedback">
                        <div *ngIf="lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="text" name="email" class="form-control" [(ngModel)]="register.Email" 
                #email="ngModel" [ngClass]="{ 'is-invalid': myform.submitted && email.invalid }" 
                (change)="register.Email=register.Email.trim()" required email>
                <div *ngIf="myform.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.errors.email">Email must be a valid email address</div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label>Password</label>
                    <input type="password" name="password" class="form-control" [(ngModel)]="register.Password"
                     #password="ngModel" [ngClass]="{ 'is-invalid': myform.submitted && password.invalid }" 
                     (change)="register.Password=register.Password.trim()" required minlength="6">
                    <div *ngIf="myform.submitted && password.invalid" class="invalid-feedback">
                        <div *ngIf="password.errors.required">Password is required</div>
                        <div *ngIf="password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label>Confirm Password</label>
                    <input type="password" name="confirmPassword" class="form-control" [(ngModel)]="register.ConfirmPassword" 
                    #confirmPassword="ngModel" [ngClass]="{ 'is-invalid': myform.submitted && confirmPassword.invalid }" 
                    (change)="register.ConfirmPassword=register.ConfirmPassword.trim()" required>
                    <div *ngIf="myform.submitted && confirmPassword.invalid" class="invalid-feedback">
                        <div *ngIf="confirmPassword.errors.required">Confirm Password is required</div>
                        <div *ngIf="confirmPassword.errors.mustMatch">Passwords must match</div>
                    </div>
                </div>
            </div>
           
            <div class="text-center">
                <button class="btn btn-primary mr-1">Register</button>
                <button class="btn btn-metal m-btn m-btn--air m-btn--custom" type="reset">Cancel</button>
            </div>
        </form>
    </div>
</div>