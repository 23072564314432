import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/Service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Level1Model, ProductSeries, Station, VariantLevel4 } from 'src/app/Model/ProductModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-add-variant-level4',
  templateUrl: './add-variant-level4.component.html',
  styleUrls: ['./add-variant-level4.component.scss']
})
export class AddVariantLevel4Component implements OnInit {
  VariantLevel4obj: VariantLevel4 = new VariantLevel4();
  charCode: any;
  @Input()
  showLoader: boolean = false;
  ProductSeries: ProductSeries = new ProductSeries();
  ProductModel: any[] = [];
  //To Stored Api data of VariantLevel1 and VariantLevel2 
  Level3arr: Level1Model[] = [];
  Level4arr: Level1Model[] = []; // only for TC and TR
  Variant1arr: any[] = [];
  Variant2arr: any[] = [];
  //To bind current VariantLevel4 data
  Level4Currdata: any[] = [];
  MountingPositionName: string="";
  @ViewChild('closebutton') closebutton;

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private CommonService: CommonService, public dialog: MatDialog, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title'] + " - WEISS");
    this.VariantLevel4obj.ProductSeries = this.route.snapshot.params["Seriesid"];
    this.getAllProductSeries();
    this.GetProductModelById(this.VariantLevel4obj.ProductSeries);
  }

  /*******************************************************************************
   * Get data for Variant Level 3
   * ******************************************************************************/
  getAllProductSeries() {
    this.showLoader = true;

    let queryString = "";
    queryString = "Id=" + this.VariantLevel4obj.ProductSeries;

    this.CommonService.GetData("ProductSeries", "GetById", queryString).subscribe((data: any) => {
      this.VariantLevel4obj.SeriesName = data?.SeriesName;
      this.VariantLevel4obj.ProductSeries = data?.Id;
      this.VariantLevel4obj.IsLevel2DepOnLevel1 = data.IsLevel2DepOnLevel1;
      this.VariantLevel4obj.IsLevel3DepOnLevel2 = data.IsLevel3DepOnLevel2;
      this.VariantLevel4obj.IsLevel4DepOnLevel3 = data.IsLevel4DepOnLevel3;
      this.VariantLevel4obj.Label_Variant1 =data.Label_Variant1;
      this.VariantLevel4obj.Label_Variant2 =data.Label_Variant2;
      this.VariantLevel4obj.Label_Variant3 = data.Label_Variant3;
      this.VariantLevel4obj.Label_Variant4 = data.Label_Variant4;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetProductModelById(SeriesId: number) {
    this.showLoader = true;
    let queryString = "";
    queryString = "SeriesId=" + SeriesId;
    this.CommonService.GetData("ProductModel", "GetProductModelById", queryString).subscribe((data: any) => {
      this.ProductModel = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetAllMountingPosition(ModelId: number)//For TC and TC
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel4", "GetAllMountingPosition", queryString).subscribe((data: any) => {
      this.Level4arr = data;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }

  GetVariant3ByModelId(ModelId: number)// other than TC and TR
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel3", "GetVariant3ByModelId", queryString).subscribe((data: any) => {
      this.Level3arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }

  GetVariant1ByModelId(ModelId: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel1", "GetVariant1ByModelId", queryString).subscribe((data: any) => {
      this.Variant1arr = data;
      this.Variant1arr = this.Variant1arr.sort((a, b) => a.Level1.localeCompare(b.Level1));
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  
  GetVariant2ByVariant1Id(Variant1: number) {
    this.showLoader = true;

    let queryString = "";
    queryString = "Variant1=" + Variant1;
    queryString += "&ProductSeriesId=" + this.VariantLevel4obj.ProductSeries;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByVariant1Id", queryString).subscribe((data: any) => {
      this.Variant2arr = data;

      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  GetVariant3ByVariant2Id(Variant2: number)
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "Variant2=" + Variant2;
    queryString += "&ProductSeriesId=" + this.VariantLevel4obj.ProductSeries;
    this.CommonService.GetData("ProductVariantLevel3", "GetVariant3ByVariant2Id", queryString).subscribe((data: any) => {
      this.Level3arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  GetVariant2ByModelId(ModelId: number)// other than TC and TR
  {
    this.showLoader = true;

    let queryString = "";
    queryString = "ProductModel=" + ModelId;
    this.CommonService.GetData("ProductVariantLevel2", "GetVariant2ByModelId", queryString).subscribe((data: any) => {
      this.Variant2arr = data;
      this.showLoader = false;
    }, error => {
      this.toastr.error("Failed to load data!");
      this.showLoader = false;
    });
  }
  /*******************************************************************************
  * End get data for Variant Level 3
  * ******************************************************************************/

  /*******************************************************************************
  * Form validation method
  * ******************************************************************************/

  isNumberKey(evt: any) {
    this.charCode = (evt.which) ? evt.which : evt.keyCode;
    if (this.charCode != 46 && this.charCode > 31
      && (this.charCode < 48 || this.charCode > 57)) {
      return false;
    }
    else {
      return true;
    }
  }

  AvoidSpace(event: any) {
    if (event.which === 32 && !event.target.value.length) {
      event.preventDefault();
    }
  }

  /*******************************************************************************
   * End Form validation method 
   * ******************************************************************************/

  onModelSelected(event: any) {
    if (this.VariantLevel4obj.SeriesName == 'TC' || this.VariantLevel4obj.SeriesName == 'TR') {
      this.Level4Currdata = [];
      this.Level4arr = [];
      this.GetAllMountingPosition(event.target.value);
    }
    else {
      this.VariantLevel4obj.Level3Id = null;
      this.Level3arr = [];
      if(this.VariantLevel4obj.IsLevel4DepOnLevel3 && this.VariantLevel4obj.IsLevel3DepOnLevel2 && this.VariantLevel4obj.IsLevel2DepOnLevel1)
      {
        this.VariantLevel4obj.Level1Id = null;
        this.VariantLevel4obj.Level2Id = null;
        this.GetVariant1ByModelId(event.target.value);
      }
      else if(this.VariantLevel4obj.IsLevel4DepOnLevel3 && this.VariantLevel4obj.IsLevel3DepOnLevel2 && !this.VariantLevel4obj.IsLevel2DepOnLevel1)
      {
        this.GetVariant2ByModelId(event.target.value);
      }
      else{
        this.GetVariant3ByModelId(event.target.value);
      }
     
    }

  }

  onVariant1Selected(event: any)
  {
    this.VariantLevel4obj.Level2Id = null;
    this.VariantLevel4obj.Level3Id = null;
    this.GetVariant2ByVariant1Id(event.target.value);
  }

  onVariant2Selected(event: any)
  {
    this.VariantLevel4obj.Level3Id = null;
    this.GetVariant3ByVariant2Id(event.target.value);
  }

  onIsPriceOnRequest(event: any) {
    if (event.checked) {
      this.VariantLevel4obj.Level4Price = 0;
    }
  }

  onSubmit(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this " + this.VariantLevel4obj.Label_Variant4 + " ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        for (var i = 0; i < this.Level4Currdata.length; i++) {
          var obj = { Id: null, Level1: this.Level4Currdata[i] }
          this.VariantLevel4obj.Level4.push(obj);
        }
        this.CommonService.PostData('ProductVariantLevel4', 'Add', this.VariantLevel4obj, null).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success(this.VariantLevel4obj.Label_Variant4 + ' added successfully!');
            this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning(this.VariantLevel4obj.Label_Variant4 + ' already exists!');
            this.VariantLevel4obj.Level4 = [];
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to ' + this.VariantLevel4obj.Label_Variant4 + '!');
            this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add ' + this.VariantLevel4obj.Label_Variant4 + '!');
          this.showLoader = false;
          this.router.navigate(['/VariantLevel4', this.VariantLevel4obj.ProductSeries, this.VariantLevel4obj.Label_Variant3, this.VariantLevel4obj.Label_Variant4]);
        });
      }
    });

  }
  OpenPopupAddMountingPosition()
  {
    this.MountingPositionName="";
  }

  AddMountingPosition(myform: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "Do you want to add this Mounting Position ?",
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {

        this.showLoader = true;

        let queryString = "";
        queryString = "MountingPositionName=" + this.MountingPositionName;
        this.CommonService.PostData('ProductVariantLevel4', 'AddMountingPosition', null, queryString).subscribe((data: any) => {
          let result = data;

          if (result.ResponseCode == 1) {
            this.toastr.success('MountingPosition added successfully!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel4obj.ProductModel != null && this.VariantLevel4obj.ProductModel != undefined
              && this.VariantLevel4obj.ProductModel != 0)) {

              this.GetAllMountingPosition(this.VariantLevel4obj.ProductModel );
            }
            this.showLoader = false;
          }
          else if (result.ResponseCode == 0) {
            this.toastr.warning('MountingPosition already exists!');
            this.showLoader = false;
          }
          else {
            this.toastr.error('Failed to add MountingPosition!');
            this.closebutton.nativeElement.click();
            if ((this.VariantLevel4obj.ProductModel != null && this.VariantLevel4obj.ProductModel != undefined
              && this.VariantLevel4obj.ProductModel != 0) ) {

              this.GetAllMountingPosition(this.VariantLevel4obj.ProductModel );
            }
            this.showLoader = false;
          }

        }, error => {
          this.toastr.error('Failed to add MountingPosition!');
          this.closebutton.nativeElement.click();
          this.showLoader = false;
          if ((this.VariantLevel4obj.ProductModel != null && this.VariantLevel4obj.ProductModel != undefined
            && this.VariantLevel4obj.ProductModel != 0)) {

            this.GetAllMountingPosition(this.VariantLevel4obj.ProductModel );
          }
        });
      }
    });


  }

}
