<loader [showLoader]="showLoader"></loader>

<div class="modal-content">
  <div class="modal-header">
      <h5 class="modal-title">Enquiry Listing ({{CustomerDetails.CustomerName}})</h5>
      <button type="button" class="btn btn-primary" (click)="closePopup()">
          <span>&times;</span>
      </button>
  </div>
  <div class="modal-body">
    
      <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="row">
          <div class="col-sm-12 col-md-6"></div>
          <div class="col-sm-12 col-md-6 right">
            <mat-form-field style="width: 50%;" class="pull-right">

              <mat-label>Search</mat-label>
              <input matInput (keyup)="SearchParam($event)" (keypress)="AvoidSpace($event)" maxlength="50"
                placeholder="Search" #input>
            </mat-form-field>

          </div>

        </div>

        <div class="mat-elevation-z8">
          <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            class="table table-striped- table-bordered table-hover dataTable no-footer" role="grid">

            <ng-container matColumnDef="EnquiryAutoIncrId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry Number </th>
              <td mat-cell *matCellDef="let row"> {{row.EnquiryNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="EnquiryDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry Date/Time</th>
              <td mat-cell *matCellDef="let row"> {{row.EnquiryDate | date: "dd-MMM-yyyy"}}<br> {{ row.EnquiryDate |date :"h:mm a"}} </td>
            </ng-container>

            <ng-container matColumnDef="QuotationNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Quotation Number </th>
              <td mat-cell *matCellDef="let row"> <a target="_blank"
                href="{{QuotationDownloadPath}}{{row.QuotationPdf_filename}}">
                {{row.QuotationNumber}}
            </a> </td>
            </ng-container>

            <ng-container matColumnDef="QuotationAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Quotation Amount ({{reportFilterObj.currencyLable=="No's"?"MINR":reportFilterObj.currencyLable}}) </th>
              <td mat-cell *matCellDef="let row" > {{row.QuotationAmount}} </td>
            </ng-container>

            <ng-container matColumnDef="POValue">
              <th [hidden]="reportFilterObj.EnqType != 'orderwin'" mat-header-cell *matHeaderCellDef mat-sort-header> PO Value ({{reportFilterObj.currencyLable=="No's"?"MINR":reportFilterObj.currencyLable}})</th>
              <td [hidden]="reportFilterObj.EnqType != 'orderwin'" mat-cell *matCellDef="let row" > {{row.POValue}} </td>
            </ng-container>

            <ng-container matColumnDef="OrderWonDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Date/Time </th>
              <td mat-cell *matCellDef="let row"> {{row.OrderWonDate| date: "dd-MMM-yyyy"}} <br> {{ row.OrderWonDate |date :"h:mm a"}}</td>
            </ng-container>

            <ng-container matColumnDef="EnquiryStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Enquiry Status </th>
              <td mat-cell *matCellDef="let row"> {{row.EnquiryStatus}} </td>
            </ng-container>

            <ng-container matColumnDef="OrderStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Status </th>
              <td mat-cell *matCellDef="let row"> {{row.OrderStatus}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>
          <div
            *ngIf="dataSource != undefined && (dataSource.filteredData == null || dataSource.filteredData?.length === 0)"
            class="text-center">No records found</div>
          </div>
          <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent($event)">
          </mat-paginator>
        </div>
      </div>
    
  </div>
  <div class="modal-footer">
      <button type="button" (click)="closePopup()" class="btn btn-primary">Close</button>
  </div>
</div>