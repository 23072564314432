

export class LeadModel {
    // LeadDetails: LeadModel = new LeadModel();
     Id: number;
     Lead_Id:number;
     CustomerName: string = "";
     CustomerAddress:string = "";
     KeyPersonName: string = "";
     IndustrySegment: string = "";
     CustomerType: string = "";
     Region: string = "";
     LeadDate:any;
     Description:string="";
     Fk_LeadCloseReason: any;
     Status: number;
     IsActive:boolean = false;
     IsConvertToProspect:boolean=false;
     closeReason:any;
     FK_EnquirySourceId: string="";
     FK_Enquiry_Id: string="";
     FK_Customer_Id: string="";
     Comments: any;
     CustomerCode: string="";
     EnquiryNumber:string="";
 }
 