export class HeaderMenuModel{
    
    menuaccessinfo:Menuelement[]=Array<Menuelement>();
    ElementKey:string;
    ElementValue:boolean;
  }

 export class Menuelement{
    ElementKey:string;
    ElementValue:boolean;
  }